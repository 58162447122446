import React from 'react'
import { bool, func, number } from 'prop-types'

const styles = {
  btn: {
    font: '16px/30px sans-serif',
    bottom: '5px',
  },
  left: {
    left: '15px',
  },
  right: {
    right: '15px',
  },
}

export default function Buttons(props) {
  const nextBtnStyle = Object.assign({}, styles.btn, styles.right)
  const { index, total, loop, nextHandler, final } = props
  return (
    <div className={'w-full z-100 bottom-0 text-center'}>
      {(final || loop || index !== total - 1) && (
        <div
          style={{
            ...nextBtnStyle,
            fontSize: 16,
            fontWeight: 600,
            color: '#008180',
            margin: '0 auto',
            marginTop: -60,
          }}
          className="cursor-pointer w-20 h-20 select-none"
          onClick={nextHandler}>
          Skip
        </div>
      )}
    </div>
  )
}

Buttons.propTypes = {
  index: number.isRequired,
  total: number.isRequired,
  prevHandler: func,
  nextHandler: func,
  loop: bool,
  final: bool,
}
