export const StoryboardIcon = () => {
  return (
    <svg
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M24.0127 32.7957H9.0127C4.8772 32.7957 1.5127 29.7818 1.5127 26.0772V7.26566C1.5127 3.56112 4.8772 0.547237 9.0127 0.547237H24.0127C28.1482 0.547237 31.5127 3.56112 31.5127 7.26566V26.0772C31.5127 29.7818 28.1482 32.7957 24.0127 32.7957ZM13.5127 7.26566C13.5127 6.52394 12.8407 5.92197 12.0127 5.92197H9.0127C8.1847 5.92197 7.5127 6.52394 7.5127 7.26566V9.95303C7.5127 10.6947 8.1847 11.2967 9.0127 11.2967H12.0127C12.8407 11.2967 13.5127 10.6947 13.5127 9.95303V7.26566ZM13.5127 15.3278C13.5127 14.586 12.8407 13.9841 12.0127 13.9841H9.0127C8.1847 13.9841 7.5127 14.586 7.5127 15.3278V18.0151C7.5127 18.7568 8.1847 19.3588 9.0127 19.3588H12.0127C12.8407 19.3588 13.5127 18.7568 13.5127 18.0151V15.3278ZM13.5127 23.3899C13.5127 22.6482 12.8407 22.0462 12.0127 22.0462H9.0127C8.1847 22.0462 7.5127 22.6482 7.5127 23.3899V26.0772C7.5127 26.8189 8.1847 27.4209 9.0127 27.4209H12.0127C12.8407 27.4209 13.5127 26.8189 13.5127 26.0772V23.3899ZM24.0127 7.26566H18.0127C16.0507 7.27372 16.0522 9.94496 18.0127 9.95303H24.0127C25.9747 9.94496 25.9732 7.27372 24.0127 7.26566ZM24.0127 15.3278H18.0127C16.0507 15.3358 16.0522 18.0071 18.0127 18.0151H24.0127C25.9747 18.0071 25.9732 15.3358 24.0127 15.3278ZM24.0127 23.3899H18.0127C16.0507 23.3979 16.0522 26.0692 18.0127 26.0772H24.0127C25.9747 26.0692 25.9732 23.3979 24.0127 23.3899Z"
        fill="#4B5563"
      />
    </svg>
  )
}
