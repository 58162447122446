import { RATIO } from './AspectRatios'

const DIMENSIONS = {
  1080: {
    [RATIO.LANDSCAPE]: { width: 1920, height: 1080 },
    [RATIO.FULL_VERTICAL]: { width: 1080, height: 1920 },
    [RATIO.SQUARE]: { width: 1080, height: 1080 },
    [RATIO.SEMI_VERTICAL]: { width: 1080, height: 1350 },
  },
  720: {
    [RATIO.LANDSCAPE]: { width: 1280, height: 720 },
    [RATIO.FULL_VERTICAL]: { width: 720, height: 1280 },
    [RATIO.SQUARE]: { width: 720, height: 720 },
    [RATIO.SEMI_VERTICAL]: { width: 720, height: 900 },
  },
}

export default DIMENSIONS
