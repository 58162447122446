import _ from 'lodash'
import RATIO from '../constants/AspectRatios'
import { TEXT, VIDEO } from '../constants/Channels'

class ProjectItemUtils {
  isBetweenTimelineItems(currentTime, project, itemId, projectPath) {
    const itemsExceptSelected = _.reject(
      project[projectPath],
      (item) => item.$id === itemId,
    )
    return _.some(itemsExceptSelected, (item) => {
      return currentTime === 0
        ? this.isTimeAroundItem(currentTime, item)
        : this.isTimeBetweenItem(currentTime, item)
    })
  }

  isAroundTimelineItems(currentTime, project, itemId, projectPath) {
    const itemsExceptSelected = _.reject(
      project[projectPath],
      (item) => item.$id === itemId,
    )
    return _.some(itemsExceptSelected, (item) => {
      return this.isTimeAroundItem(currentTime, item)
    })
  }

  isAroundUnrecordableArea(currentTime, project, itemId, projectPath) {
    const itemsExceptSelected = _.reject(
      project[projectPath],
      (item) => item.$id === itemId,
    )
    return _.some(itemsExceptSelected, (item) => {
      return this.isTimeAroundUnrecordableArea(currentTime, item)
    })
  }

  isTimeBetweenItem(currentTime, item) {
    return currentTime > item.start_time && currentTime < item.end_time
  }

  isTimeAroundItem(currentTime, item) {
    return currentTime >= item?.start_time && currentTime <= item?.end_time
  }

  isTimeAroundItemMoveable(currentTime, item) {
    const fadeTime = item?.fadeTime || 0
    const startTimeWithFadeTime = item?.start_time + fadeTime
    const endTimeWithFadeTime = item?.end_time - fadeTime
    return (
      currentTime >= startTimeWithFadeTime && currentTime <= endTimeWithFadeTime
    )
  }

  isTimeAroundUnrecordableArea(currentTime, item) {
    return currentTime >= item.start_time && currentTime < item.end_time
  }

  getItemByCurrentTime(
    currentTime,
    project,
    projectPath,
    currentRatio = RATIO.LANDSCAPE,
  ) {
    const isChannelWithAspectRatioVariants = _.includes(
      [VIDEO.projectPath, TEXT.projectPath],
      projectPath,
    )
    const channelPath = isChannelWithAspectRatioVariants
      ? `${projectPath}.${currentRatio}`
      : `${projectPath}`
    const items = _.get(project, channelPath, [])
    const matchedItem = _.find(items, (item) =>
      this.isTimeAroundItem(currentTime, item),
    )
    const matchedItemIndex = _.findIndex(items, (item) =>
      this.isTimeAroundItem(currentTime, item),
    )
    return matchedItem ? { item: matchedItem, index: matchedItemIndex } : null
  }
}
export default new ProjectItemUtils()
