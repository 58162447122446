export const EditorRewindIcon = (props) => {
  return (
    <svg
      {...props}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle opacity="0.5" cx="12" cy="12" r="12" fill="#E5E7EB" />
      <path
        d="M7.41905 11.079L11.9996 8.02679V10.1529L15 8V16L11.9996 13.8471V16L7.41872 12.7141C7.28921 12.6211 7.18365 12.4984 7.11082 12.3562C7.03799 12.214 7 12.0564 7 11.8965C7 11.7366 7.03799 11.579 7.11082 11.4368C7.18365 11.2946 7.28954 11.1719 7.41905 11.079Z"
        fill="#6B7280"
      />
    </svg>
  )
}
