import { useEffect, useRef, useState } from 'react'
import PlayBtn from './../../assets/images/landing-page/play_button.png'
import PauseBtn from './../../assets/images/landing-page/pause_button.png'
import BannerVideo from './../../assets/video/banner_CerebriamStudio.mp4'
const videoDuration = 60

const Banner = ({ setIsSignUpOpen }) => {
  const [isVideoPlaying, setIsVideoPlaying] = useState(false)
  const videoRef = useRef(null)
  const playRef = useRef(null)
  let videoTimerRef = useRef(null)

  const togglePlay = () => {
    if (videoRef.current.paused) {
      videoRef.current.play()
      setIsVideoPlaying(true)
      videoTimerRef.current = setTimeout(() => {
        setIsVideoPlaying(false)
      }, (videoDuration - videoRef.current.currentTime) * 1000)
    } else {
      videoRef.current.pause()
      setIsVideoPlaying(false)
    }
  }
  useEffect(() => {
    if (videoRef) {
      videoRef.current.currentTime = 1
    }
  }, [])

  return (
    <section className="cerebriam-banner">
      <div className="cerebriam-row">
        <div className="cerebriam-col-4">
          <div className="cerebriam-content">
            <h1>Be free to create </h1>
            <br></br>
            <p>
              Let your creativity run wild with custom built tools for composing
              and making videos in multiple aspect ratios, all done in half the
              time.
            </p>
            <p>No credit card required.</p>
            <br />
            <br />
            <button onClick={() => setIsSignUpOpen(true)}>
              START FREE TRIAL
            </button>
          </div>
        </div>
        <div className="cerebriam-col-6">
          <div className="cerebriam-content">
            <div className="video-content animate__animated animate__fadeIn">
              <video
                onClick={togglePlay}
                ref={videoRef}
                id="video1"
                width="540"
                height="427"
                muted={false}
                // controls
              >
                <source src={BannerVideo} type="video/mp4" />
                <source src={BannerVideo} type="video/ogg" />
                Your browser does not support HTML video.
              </video>
              {isVideoPlaying ? (
                <img
                  onClick={togglePlay}
                  className="toggle-play"
                  src={PauseBtn}
                  alt="toggle-btn"
                />
              ) : (
                <img
                  ref={playRef}
                  onClick={togglePlay}
                  className="toggle-play"
                  src={PlayBtn}
                  alt="toggle-btn"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Banner
