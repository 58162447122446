// import { isMobile } from 'react-device-detect'
import _ from 'lodash'
import * as TimelineSet from '../../utils/staging-preview/TimelineSet'
import * as TimelineTextItemService from '../../services/TimelineTextItemService'
import { ONE_SECOND } from '../../constants/Timeline'
import * as Channels from '../../constants/Channels'
import * as TextItemAdder from '../../services/project-items/TextItemAdder'
import * as TimelineService from '../../services/time/TimelineService'
import ProjectItemUtils from '../../utils/ProjectItemUtils'
import {
  FLOAT_RATE,
  SEC_WIDTH,
  SLIDER_PRO,
} from '../../constants/TimelineItems'
/**
 * Click scene
 * @param item
 * @param ind
 * @param flag
 */
export function selectScene(item, i, flag, newCurrentTime) {
  const {
    checkAddItem,
    setSelectedScene,
    setSelectedSceneIndex,
    setSelectedSound,
    setSelectedSoundIndex,
    selectedScene,
    selectedSceneIndex,
    checkedChannels,
    addItem,
    project,
  } = this.props
  const isAudioChannelEnabled = _.get(
    checkedChannels,
    Channels.AUDIO.code,
    false,
  )
  const isLinkedWithAudioActivated = item?.id !== selectedScene?.id
  setSelectedScene(item, isLinkedWithAudioActivated)
  if (isAudioChannelEnabled) {
    const audioItemOnCurrentTime = _.find(
      project[Channels.AUDIO.projectPath],
      (item) => ProjectItemUtils.isTimeAroundItem(newCurrentTime, item),
    )
    setSelectedSound(audioItemOnCurrentTime)
  }
  if (selectedScene?.id !== item?.id || selectedSceneIndex !== i) {
    setSelectedSceneIndex(i)
    const audioItemIndexOnCurrentTime = _.findIndex(
      project[Channels.AUDIO.projectPath],
      (item) => ProjectItemUtils.isTimeAroundItem(newCurrentTime, item),
    )
    setSelectedSoundIndex(audioItemIndexOnCurrentTime)
  }
  if (flag) {
    this.moveTimelineByCurrentTime(newCurrentTime)
    if (addItem !== Channels.VIDEO.code) checkAddItem(Channels.VIDEO.code)
  }
}

export function moveTimelineByCurrentTime(sliderStartTime, updatedProject) {
  const { updateTimeSlider, project } = this.props
  const sliderPosition = TimelineService.convertMillisecondsToSliderPosition(
    sliderStartTime,
    updatedProject || project,
    SLIDER_PRO,
    FLOAT_RATE,
  )
  updateTimeSlider(sliderPosition)
}

export function selectMusicItem(item, i, flag, newCurrentTime) {
  const {
    setSelectedMusic,
    setSelectedMusicIndex,
    checkAddItem,
    selectedMusic,
    addItem,
  } = this.props

  this.setState({
    selectedMusic: item,
  })
  setSelectedMusic(item)
  if (selectedMusic?.id !== item.id) {
    setSelectedMusicIndex(i)
  }
  if (flag) {
    this.moveTimelineByCurrentTime(newCurrentTime)
    if (addItem !== Channels.MUSIC.code) checkAddItem(Channels.MUSIC.code)
  }
}

export function selectSoundItem(item, i, flag, newCurrentTime) {
  const {
    setSelectedSound,
    setSelectedSoundIndex,
    setSelectedScene,
    setSelectedSceneIndex,
    checkAddItem,
    selectedSound,
    checkedChannels,
    project,
    addItem,
  } = this.props
  setSelectedSound(item)
  setSelectedSoundIndex(i)
  const videoItems = _.get(
    project,
    `${Channels.VIDEO.projectPath}.${project.resolution}`,
    [],
  )
  const videoItemOnCurrentTime = _.find(videoItems, (item) =>
    ProjectItemUtils.isTimeAroundItem(newCurrentTime, item),
  )
  setSelectedScene(videoItemOnCurrentTime)

  const isVideoChannelEnabled = _.get(
    checkedChannels,
    Channels.VIDEO.code,
    false,
  )
  if (selectedSound?.id !== item.id && isVideoChannelEnabled) {
    const videoItemIndexOnCurrentTime = _.findIndex(videoItems, (item) =>
      ProjectItemUtils.isTimeAroundItem(newCurrentTime, item),
    )
    setSelectedSceneIndex(videoItemIndexOnCurrentTime)
  }

  if (flag) {
    this.moveTimelineByCurrentTime(newCurrentTime)
    if (addItem !== Channels.AUDIO.code) checkAddItem(Channels.AUDIO.code)
  }
}

export function selectVoiceoverItem(item, i, flag, newCurrentTime) {
  const {
    setSelectedVoiceover,
    setSelectedVoiceoverIndex,
    checkAddItem,
    selectedVoiceover,
    addItem,
  } = this.props
  setSelectedVoiceover && setSelectedVoiceover(item)

  if (selectedVoiceover?.id !== item.id) {
    setSelectedVoiceoverIndex && setSelectedVoiceoverIndex(i)
  }
  if (flag) {
    this.moveTimelineByCurrentTime(newCurrentTime)
    if (addItem !== Channels.VOICEOVER.code)
      checkAddItem(Channels.VOICEOVER.code)
  }
}
export function selectTextItem(item, i, flag, newCurrentTime) {
  const {
    setSelectedText,
    setSelectedTextIndex,
    checkAddItem,
    addItem,
    selectedText,
  } = this.props
  setSelectedText(item)
  if (selectedText?.id !== item.id) {
    setSelectedTextIndex(i)
  }
  if (flag) {
    setTimeout(() => {
      this.moveTimelineByCurrentTime(newCurrentTime)
      if (addItem !== Channels.TEXT.code) checkAddItem(Channels.TEXT.code)
    }, 0.2 * ONE_SECOND)
  }
}

export function setUpTextTimeline() {
  this.setState({
    showTextModal: false,
  })
}

export function textDragHandle(left, i) {
  const { setSelectedText, setSelectedTextIndex, project } = this.props

  if (!project.scenes[project.resolution].length) {
    return false
  }

  if (left < 0) {
    left = 0
  }

  let tItems = JSON.parse(JSON.stringify(this.state.textItems))
  const lastRight =
    project.scenes[project.resolution][
      project.scenes[project.resolution].length - 1
    ].size.sLeft +
    project.scenes[project.resolution][
      project.scenes[project.resolution].length - 1
    ].size.sliderW

  if (left + tItems[i].sliderW > lastRight) {
    left = lastRight - tItems[i].sliderW
  }

  const incDu =
    Math.round(((left - tItems[i].sliderL) / SEC_WIDTH) * 1000 * FLOAT_RATE) /
    FLOAT_RATE
  const incWid = left - tItems[i].sliderL

  tItems[i].sliderL = left
  tItems[i].start_time = incDu
  tItems[i].end_time = incDu
  tItems[i].updated = true

  if (i < tItems.length - 1) {
    for (let j = i + 1; j < tItems.length; j++) {
      tItems[j].start_time += incDu
      tItems[j].end_time += incDu
      tItems[j].sliderL += incWid
      tItems[j].updated = true
    }
  }

  setSelectedText(tItems[i])
  setSelectedTextIndex(i)

  this.setState({
    textItems: tItems,
  })

  let pVal = project
  pVal.texts[project.resolution] = tItems

  this.localUpdateProject(pVal)
}

export function textResizeHandle(width, i) {
  if (width <= 50) {
    width = 50
  }

  this.textRef[i].style.width = `${width}px`
  let tItems = JSON.parse(JSON.stringify(this.state.textItems))
  tItems[i].sliderW = width

  this.setState({
    textItems: tItems,
  })
}

export function textResizeEnd(width, currentTextItemIndex) {
  const { setSelectedText, setSelectedTextIndex, updateProject, project } =
    this.props
  const textItems = TimelineTextItemService.updateTextItemWidths(
    this.state.textItems,
    currentTextItemIndex,
    width,
    SEC_WIDTH,
    FLOAT_RATE,
  )
  setSelectedText(textItems[currentTextItemIndex])
  setSelectedTextIndex(currentTextItemIndex)

  this.setState({ textItems })

  let updatedProject = _.cloneDeep(project)
  updatedProject.texts[project.resolution] = textItems

  updateProject(updatedProject)
  TimelineSet.createEndInTimeline(
    this.props.previewTimeLine,
    updatedProject.duration / ONE_SECOND,
  )
}

/**
 * Scenes sort rearrange
 * @param items
 */
export function onScenesSortEnd(items) {
  const { project } = this.props

  let orderAry = []
  let updatedItems = JSON.parse(JSON.stringify(items))

  for (let i = 0; i < items.length; i++) {
    orderAry.push(i)

    updatedItems[i].order = i
  }

  let pVal = project

  for (let s = 0; s < 4; s++) {
    if (project.selectLayout[s]) {
      continue
    }

    if (s !== project.resolution) {
      let uItems = []
      orderAry.map((el, i) => {
        uItems[i] = project.scenes[s][el]

        return el
      })

      _.map(uItems, (el1, i1) => {
        el1.order = i1
        return el1
      })

      pVal.scenes[s] = uItems
    }
  }

  let uSoundItems = []
  for (let j = 0; j < updatedItems.length; j++) {
    const uEl = updatedItems[j]

    if (uEl.type === 'video' && uEl.sound) {
      const sAry = project.sound.filter((e) => e.id === `${uEl.id}_sound`)

      if (sAry && sAry.length) {
        uSoundItems.push(sAry[0])
      }
    }
  }

  this.setState({
    items: updatedItems,
    soundItems: uSoundItems,
    selItem: updatedItems[0],
    refreshSound: true,
  })

  pVal.scenes[project.resolution] = updatedItems

  this.localUpdateProject(pVal)

  setTimeout(() => {
    this.setState({
      refreshSound: false,
    })
  }, 200)
}

export function onMusicSortEnd(items) {
  const { project } = this.props

  let updatedItems = items.map((el, i) => {
    el.order = i

    return el
  })

  this.setState({
    audioItems: updatedItems,
  })

  let pVal = project
  pVal.music = updatedItems

  this.localUpdateProject(pVal)
}

export function onTextSortEnd(items) {
  const { project } = this.props
  let pVal = project

  let orderAry = []

  let updatedItems = items.map((el, i) => {
    orderAry.push(el.order)

    el.order = i

    return el
  })

  this.setState({
    textItems: updatedItems,
  })

  for (let s = 0; s < 4; s++) {
    if (s !== project.resolution && project.selectLayout[s]) {
      let uItems = []
      orderAry.map((el, i) => {
        uItems[i] = project.texts[s][el]

        return el
      })

      uItems.map((el1, i1) => {
        el1.order = i1

        return el1
      })

      pVal.texts[s] = uItems
    }
  }

  pVal.texts[project.resolution] = updatedItems

  this.localUpdateProject(pVal)
}

/**
 * set timeline header
 */
export function setTimeLineHeader() {
  if (this.previewRef.current) {
    const { project, SetTimeSlider, timeSlider } = this.props

    this.previewRef.current.style.width = '100%'
    let sW = Math.round(project.duration / 1000) * 20

    if (this.previewRef.current.offsetWidth < sW + 260) {
      this.previewRef.current.style.width = `${sW + 260}px`
    }

    let tsW =
      Math.round(
        (this.previewRef.current.offsetWidth / SEC_WIDTH) * FLOAT_RATE,
      ) / FLOAT_RATE
    let tlS = []
    for (let i = 0; i < tsW; i++) {
      tlS.push(i)
    }

    this.setState({
      tlLength: tlS,
      slideTotalW: sW,
    })

    SetTimeSlider({
      width: sW,
      curSec: timeSlider.curSec,
      value: timeSlider.value,
      show: timeSlider.show,
    })

    // this.toggleSlider(false)

    setTimeout(() => {
      this.toggleSlider(true)
    }, 100)
  }
}

/**
 * project data update
 * @param project
 */
export function localUpdateProject(project) {
  let totalTime = 0

  if (project) {
    for (let s = 0; s < 4; s++) {
      if (!project.selectLayout[s]) {
        continue
      }

      if (project.scenes[s] && project.scenes[s].length) {
        // eslint-disable-next-line no-loop-func
        project.scenes[s].map((el, i) => {
          let scenesDuration = el.clip.duration

          if (s === 0) {
            totalTime += scenesDuration
          }

          let sLeft = 0

          if (i > 0) {
            let w_w = 0

            for (let j = 0; j < i; j++) {
              w_w += project.scenes[s][j].size.sliderW
            }

            sLeft = w_w
          }

          const startTime =
            Math.round((sLeft / SEC_WIDTH) * 1000 * FLOAT_RATE) / FLOAT_RATE
          const endTime =
            Math.round(
              ((sLeft + el.size.sliderW) / SEC_WIDTH) * 1000 * FLOAT_RATE,
            ) / FLOAT_RATE

          el.start_time = startTime
          el.end_time = endTime

          el.size = {
            width: el.size.width,
            height: el.size.height,
            sLeft: sLeft,
            sliderW: el.size.sliderW,
          }

          if (el.type === 'video' && el.crop[s]) {
            const uVidOld = `_${el.media_layout}.mp4`
            const uVid = `_${s}.mp4`
            const tVidOld = `_${el.media_layout}.jpg`
            const tVid = `_${s}.jpg`

            if (el.assetUrl.indexOf(uVid) < 0) {
              el.assetUrl = el.assetUrl.replace(uVidOld, uVid)
            }

            if (el.thumbUrl.indexOf(tVid) < 0) {
              el.thumbUrl = el.thumbUrl.replace(tVidOld, tVid)
            }

            if (el.sound) {
              const sAry = project.sound.filter(
                (e) => e.id === `${el.id}_sound`,
              )

              if (sAry && sAry.length) {
                const sIndex = project.sound.indexOf(sAry[0])

                project.sound[sIndex].start_time = startTime
                project.sound[sIndex].end_time = endTime
                project.sound[sIndex].size = {
                  sLeft: sLeft,
                  sliderW: el.size.sliderW,
                }
              }
            }
          }

          el.media_layout = s

          return el
        })
      }

      if (project.texts[s] && project.texts[s].length) {
        project.texts[s].map((el, i) => {
          let sLeft = el.sliderL

          if (!el.updated) {
            if (i > 0) {
              sLeft =
                project.texts[s][i - 1].sliderL +
                project.texts[s][i - 1].sliderW
            }

            el.sliderL = sLeft
          }

          el.start_time =
            Math.round((sLeft / SEC_WIDTH) * 1000 * FLOAT_RATE) / FLOAT_RATE
          el.end_time =
            Math.round(((sLeft + el.sliderW) / SEC_WIDTH) * 1000 * FLOAT_RATE) /
            FLOAT_RATE

          return el
        })
      }
    }

    if (project.music && project.music.length) {
      project.music.map((el, i) => {
        let sLeft = 0

        if (i > 0) {
          let w_w = 0

          for (let j = 0; j < i; j++) {
            w_w += project.music[j].size.sliderW
          }

          sLeft = w_w
        }

        el.start_time =
          Math.round((sLeft / SEC_WIDTH) * 1000 * FLOAT_RATE) / FLOAT_RATE
        el.end_time =
          Math.round(
            ((sLeft + el.size.sliderW) / SEC_WIDTH) * 1000 * FLOAT_RATE,
          ) / FLOAT_RATE

        el.size = {
          sLeft: sLeft,
          sliderW: el.size.sliderW,
        }

        el.order = i

        return el
      })
    }
  }

  project.duration = totalTime

  this.props.updateProject(project)

  this.props.toggleWatchProject(true)

  setTimeout(() => {
    this.props.toggleWatchProject(false)
  }, 200)
}

export function updateTimelineSlider(value) {
  const { timeSlider, SetTimeSlider, project, previewTimeLine } = this.props

  let curPro = Math.round((value / SLIDER_PRO) * FLOAT_RATE) / FLOAT_RATE

  if (curPro > 1) {
    curPro = 1
  }

  const curSec = Math.round(curPro * project.duration * FLOAT_RATE) / FLOAT_RATE

  if (timeSlider.value !== value) {
    previewTimeLine.pause(value, true)
    SetTimeSlider({
      curSec: curSec,
      value: value,
      width: timeSlider.width,
      show: timeSlider.show,
    })
  }
  return curSec
}

/**
 * Timeline slider update
 */
export function handleProgressChange(value, isPeeking = false) {
  this.setState({
    slideV: value,
  })

  const { timeSlider, updateTimeSlider, updateSlideV } = this.props

  const curSec = this.updateTimelineSlider(value)

  if (timeSlider.value !== value) {
    this.onChangeTimeSlider(curSec)
  }

  if (updateSlideV !== value) {
    updateTimeSlider(value)
  }
}

/**
 * auto update timeline slider when playing
 * @param value
 */
export function autoUpdateSelectSlide(value) {
  const { project } = this.props

  let curPro = Math.round((value / SLIDER_PRO) * FLOAT_RATE) / FLOAT_RATE

  const curSec = Math.round(curPro * project.duration * FLOAT_RATE) / FLOAT_RATE

  this.onChangeTimeSlider(curSec)
}

/**
 * pick select slide
 * @param currentTime
 */
export function onChangeTimeSlider() {
  const { project } = this.props
  if (!project) {
    return
  }

  /*
  const currentSceneResult = ProjectItemUtils.getItemByCurrentTime(currentTime, project, Channels.VIDEO.projectPath, project.resolution)
  if (currentSceneResult) {
    this.props.setSelectedScene(currentSceneResult.item)
    this.props.setSelectedSceneIndex(currentSceneResult.index)
  }
  
    const currentAudioResult  = ProjectItemUtils.getItemByCurrentTime(currentTime, project, Channels.AUDIO.projectPath)
    if (currentAudioResult) {
      this.props.setSelectedSound(currentAudioResult.item)
      this.props.setSelectedSoundIndex(currentAudioResult.index)
    }
  
    const currentTextResult  = ProjectItemUtils.getItemByCurrentTime(currentTime, project, Channels.TEXT.projectPath)
    if (currentTextResult) {
      this.props.setSelectedText(currentTextResult.item)
      this.props.setSelectedTextIndex(currentTextResult.index)
    }
  
    const currentMusicResult  = ProjectItemUtils.getItemByCurrentTime(currentTime, project, Channels.MUSIC.projectPath)
    if (currentMusicResult) {
      this.props.setSelectedMusic(currentMusicResult.item)
      this.props.setSelectedMusicIndex(currentMusicResult.index)
    }
  
    const currentVoiceoverResult  = ProjectItemUtils.getItemByCurrentTime(currentTime, project, Channels.VOICEOVER.projectPath)
    if (currentVoiceoverResult) {
      this.props.setSelectedVoiceover(currentVoiceoverResult.item)
      this.props.setSelectedVoiceoverIndex(currentVoiceoverResult.index)
    }
  
    */
}

/**
 * update timeline slider when updated scenes
 * @param flag
 */
export function toggleSlider(flag) {
  this.setState({
    sliderShow: flag,
  })

  this.props.SetTimeSlider({
    curSec: this.props.timeSlider.curSec,
    value: this.props.timeSlider.value,
    width: this.props.timeSlider.width,
    show: flag,
  })
}

/**
 * handle show add item
 */
export function toggleShowAddItem(channelCode) {
  this.props.checkAddItem(channelCode)
  switch (channelCode) {
    case Channels.VIDEO.code:
      this.props.toggleShowImport(true, channelCode)
      this.props.toggleShowTextPanel(false)
      break
    case Channels.AUDIO.code:
      this.props.toggleShowImport(true, channelCode)
      this.props.toggleShowTextPanel(false)
      break
    case Channels.VOICEOVER.code:
      this.props.toggleVoiceoverModal(true)
      this.props.toggleShowImport(false)
      this.props.toggleShowTextPanel(false)
      break
    case Channels.MUSIC.code:
      this.props.toggleShowImport(true, channelCode)
      this.props.toggleShowTextPanel(false)
      break
    case Channels.TEXT.code: {
      this.props.storeProjectSteps({ channel: [channelCode], type: 'ADD' })
      const { selectedText, updatedProject } = createNewTextItemInProject(
        this.props,
      )
      this.props.toggleShowImport(false)
      this.props.openEditTextModal(true, selectedText)
      const sliderStartTime = selectedText.start_time + 0.5 * ONE_SECOND
      this.moveTimelineByCurrentTime(sliderStartTime, updatedProject)
      break
    }
    default:
      return
  }
}

function createNewTextItemInProject(props) {
  const { project, setSelectedText, setSelectedTextIndex, updateProject } =
    props
  let { updatingProject, newTextItem } =
    TextItemAdder.addNewTextItemInProject(project)
  const textItemsInDefaultResolution = _.get(
    updatingProject,
    `texts.${updatingProject.resolution}`,
    [],
  )
  const defaultTextItemIndex = _.findIndex(
    textItemsInDefaultResolution,
    (t) => t.id === newTextItem.id,
  )
  const selectedText = textItemsInDefaultResolution[defaultTextItemIndex]
  updateProject(updatingProject)
  setSelectedText(selectedText)
  setSelectedTextIndex(defaultTextItemIndex)
  return { selectedText, updatedProject: updatingProject }
}
