export const BrandingIcon = () => {
  return (
    <svg
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.512695 16.5471V23.2138H13.846V16.5471H0.512695ZM13.846 25.8805V27.8805C13.846 30.4578 11.7567 32.5471 9.17936 32.5471H5.17936C2.60203 32.5471 0.512695 30.4578 0.512695 27.8805V25.8805H13.846ZM13.846 13.8805V7.21379C13.846 3.53245 10.8607 0.547119 7.17936 0.547119C3.49803 0.547119 0.512695 3.53245 0.512695 7.21379V13.8805H13.846ZM9.17936 7.21379C9.17936 8.31779 8.28336 9.21379 7.17936 9.21379C6.07536 9.21379 5.17936 8.31779 5.17936 7.21379C5.17936 6.10979 6.07536 5.21379 7.17936 5.21379C8.28336 5.21379 9.17936 6.10979 9.17936 7.21379ZM13.846 25.8805V27.8805C13.846 30.4578 11.7567 32.5471 9.17936 32.5471H5.17936C2.60203 32.5471 0.512695 30.4578 0.512695 27.8805V25.8805H13.846ZM13.846 13.8805V7.21379C13.846 3.53245 10.8607 0.547119 7.17936 0.547119C3.49803 0.547119 0.512695 3.53245 0.512695 7.21379V13.8805H13.846ZM9.17936 7.21379C9.17936 8.31779 8.28336 9.21379 7.17936 9.21379C6.07536 9.21379 5.17936 8.31779 5.17936 7.21379C5.17936 6.10979 6.07536 5.21379 7.17936 5.21379C8.28336 5.21379 9.17936 6.10979 9.17936 7.21379ZM16.5114 7.11778L27.0967 17.7031C28.9194 19.5258 28.9194 22.4805 27.0967 24.3031L24.2687 27.1311C22.446 28.9538 19.4914 28.9538 17.6687 27.1311L16.5127 25.9751C16.5127 25.9751 16.5127 7.14978 16.5127 7.11778H16.5114ZM13.7114 0.547119H27.846C30.4234 0.547119 32.5127 2.63645 32.5127 5.21379V9.21379C32.5127 11.7911 30.4234 13.8805 27.846 13.8805H27.046L13.7114 0.547119Z"
        fill="#4B5563"
      />
    </svg>
  )
}
