import React from 'react'
import AppModal from '../modals/AppModal'
import Iframe from 'react-iframe'

const FeedBackModal = ({ open, onClose, title }) => {
  return (
    <AppModal open={open} onClose={onClose} title={title} hideHeader>
      <>
        <div className="shareApp">
          <Iframe
            url="https://us19.list-manage.com/survey?u=9d759cfb6ebf5cbb2a5a3b4ce&id=0e0bdb5b63"
            width="100%"
            height="450px"
            id="myId"
            className="myClassname"
            display="initial"
            position="relative"
          />
        </div>
      </>
    </AppModal>
  )
}

export default FeedBackModal
