export const EditorTextIcon = (props) => {
  return (
    <svg
      {...props}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.0541 5.38473C15.0541 4.53857 14.227 3.84627 13.2163 3.84627H11.3785V17.3078C11.3785 17.9463 10.7628 18.4616 10.0001 18.4616C9.23742 18.4616 8.62175 17.9463 8.62175 17.3078V3.84627H6.78395C5.77316 3.84627 4.94615 4.53857 4.94615 5.38473V5.76934C4.94615 6.4078 4.33049 6.92319 3.5678 6.92319C2.80512 6.92319 2.18945 6.4078 2.18945 5.76934V5.38473C2.18945 3.26165 4.24779 1.53857 6.78395 1.53857H13.2163C15.7524 1.53857 17.8108 3.26165 17.8108 5.38473V5.76934C17.8108 6.4078 17.1951 6.92319 16.4324 6.92319C15.6697 6.92319 15.0541 6.4078 15.0541 5.76934V5.38473Z"
        fill="#6B7280"
      />
    </svg>
  )
}
