const BillingCycle = ({ activeCycle, changeActiveCycle, plan }) => {
  return (
    <div className="cerebriam-column cerebriam-billing-cycle">
      <>
        <p className="cerebriam-title">Billing Cycle</p>
        <div className="cerebriam-plan-option">
          <label className={`${activeCycle === 1 ? 'active' : ''}`}>
            <input
              checked={activeCycle === 1}
              type="radio"
              name="planCycle"
              onChange={() => changeActiveCycle(1)}
            />{' '}
            Annual
            <span className="radio-title"> (Save 30%)</span>
          </label>
          <label className={`${activeCycle === 2 ? 'active' : ''}`}>
            <input
              checked={activeCycle === 2}
              type="radio"
              name="planCycle"
              onChange={() => changeActiveCycle(2)}
            />{' '}
            Monthly
            <span className="radio-title"> (Save 19%)</span>
          </label>
        </div>
        {activeCycle === 1 ? (
          <p className="discount-info">
            Pay <strong>{plan?.price_annually}</strong> now, receive your full
            30% discount, and 12 months uninterrupted use of{' '}
            <strong>Cerebriam {plan?.type}</strong>
          </p>
        ) : activeCycle === 2 ? (
          <p className="discount-info">
            Split your payments into <strong>{plan?.price_monthly}</strong>
            /month instalments and spread the cost over a year. Receive 12
            months uninterrupted use of <strong>Cerebriam {plan?.type}</strong>
          </p>
        ) : activeCycle === 3 ? (
          <p className="discount-info">
            Pay <strong>{plan?.price_pay_as_you_go}</strong>/month for use of{' '}
            <strong>Cerebriam {plan?.type}</strong> and cancel when you wish
          </p>
        ) : (
          ''
        )}
        <label className={`other-label ${activeCycle === 3 ? 'active' : ''}`}>
          <input
            checked={activeCycle === 3}
            onChange={() => changeActiveCycle(3)}
            type="radio"
            name="planCycle"
          />{' '}
          Pay-as-you-go only {plan?.price_pay_as_you_go} for one month (Pay{' '}
          {`${plan?.price_pay_as_you_go[0]}${
            Number(plan?.price_pay_as_you_go?.substring(1)) * 12
          }`}{' '}
          in a year if you decide to continue){' '}
        </label>
      </>

      {/* {!isAuthenticated && !trialRegistered && (
        <div className="bottom-button previous">
          <button onClick={toggleTrialPayAsYouGo} className="cerebriam-btn">
            {isUserPaying ? 'Free Trial' : 'Pay as you go'}
          </button>
        </div>
      )} */}
    </div>
  )
}

export default BillingCycle
