import React, { useEffect, useLayoutEffect } from 'react'
import { getRgbColor } from '../../../../utils/Helper'
import useScaleText from '../../../../hooks/useScaleText'
import { DEFAULT_TEXT } from '../../../../constants/TimelineItems'

const getBoundingBox = (animationWrapper, props) => {
  const currentWrapper = animationWrapper?.current
  const stageBoundingBox =
    currentWrapper?.parentElement?.getBoundingClientRect()

  if (stageBoundingBox) {
    const widthRatio = stageBoundingBox.width / props.text.p_width
    const heightRatio = stageBoundingBox.height / props.text.p_height
    return {
      width: `${props.text.width * widthRatio}px`,
      height: `${props.text.height * heightRatio}px`,
      top: `${props.text.top * heightRatio}px`,
      left: `${props.text.left * widthRatio}px`,
    }
  } else {
    return {
      width: `${props.text.width * props.scale}px`,
      height: `${props.text.height * props.h_scale}px`,
      top: `${props.text.top * props.h_scale}px`,
      left: `${props.text.left * props.scale}px`,
    }
  }
}

const TextClip = React.forwardRef((props, ref) => {
  const {
    contRef,
    onFontResize,
    isTextMoveableDisplayable = false,
    indexValue,
    resolution,
    ...otherProps
  } = props

  const {
    fontSize,
    fontSizeInt,
    ref: scaleRef,
  } = useScaleText({
    hasParent: true,
    minFontSize: 1,
  })
  const boundingBox = getBoundingBox(contRef, props)
  const divClassName = `text-content ${
    props.text.height === DEFAULT_TEXT.height &&
    props.text.width === DEFAULT_TEXT.width
      ? `cerebriam-extra_${DEFAULT_TEXT.height}_${DEFAULT_TEXT.width}`
      : 'cerebriam-extra'
  }`
  // ${isTextMoveableDisplayable ? 'moveable-text-resize' : ''}

  useLayoutEffect(() => {
    onFontResize(
      props.text.id,
      fontSizeInt,
      scaleRef.current.parentElement.offsetWidth,
      scaleRef.current.parentElement.offsetHeight,
    )
  }, [onFontResize, props.text.id, fontSizeInt])

  return (
    <div
      ref={ref}
      className="text-clip"
      {...otherProps}
      style={{
        color: getRgbColor(props.text.color),
        backgroundColor: getRgbColor(props.text.backgroundColor),
        fontFamily: props.text.fontFamily,
        fontWeight: props.text.fontWeight,
        fontStyle: props.text.fontStyle,
        padding: '0 2.5%',
        textDecoration: props.text.textDecoration,
        textAlign: 'center',
        flexDirection: 'column',
        minWidth: '2.5rem',
        minHeight: '2.5rem',
        visibility: 'none',
        opacity: 0,
        ...boundingBox,
      }}>
      <div
        ref={scaleRef}
        style={{ fontSize }}
        className={divClassName}
        dangerouslySetInnerHTML={{ __html: props.text.content }}
      />
    </div>
  )
})

export default TextClip
