import facebook from '../../../assets/images/png/socialMedia/facebook.png'
import instagram from '../../../assets/images/png/socialMedia/instagram.png'
import linkedIn from '../../../assets/images/png/socialMedia/linkedIn.png'
import snapChat from '../../../assets/images/png/socialMedia/snapchat.png'
import pinterest from '../../../assets/images/png/socialMedia/pinterest.png'
import twitter from '../../../assets/images/png/socialMedia/twitter.png'
import tiktok from '../../../assets/images/png/socialMedia/tiktok.png'
import youTube from '../../../assets/images/png/socialMedia/youtube.png'
import twitch from '../../../assets/images/png/socialMedia/twitch.png'

export const Platforms = {
  F: facebook,
  I: instagram,
  L: linkedIn,
  S: snapChat,
  P: pinterest,
  Y: youTube,
  TK: tiktok,
  T: twitter,
  TWC: twitch,
}
