import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faSpinner,
  faTimes,
  faCheck,
  faEdit,
  faSquare, // eslint-disable-line
  faCheckSquare, // eslint-disable-line
} from '@fortawesome/free-solid-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'
import { renameProject } from '../../services/EditorServices'

library.add(faEdit)
library.add(faSpinner)
library.add(faCheck)
library.add(faSquare)
library.add(faCheckSquare)
const ProjectGridItem = ({
  project,
  isActive,
  onClick,
  aspectRatios,
  selectAspect,
  renameProjectAction,
}) => {
  const { _id, projectName, duration } = project

  const [formOpen, setForm] = useState(false)
  const [name, setName] = useState(projectName)
  const [loading, setLoading] = useState(false)

  const openForm = () => setForm(true)
  const closeForm = () => {
    setName(projectName)
    setForm(false)
  }

  const changeName = ({ target }) => {
    setName(target.value)
  }

  const updateName = async () => {
    if (!name.trim() || !_id) return false

    setLoading(true)
    const res = await renameProject(_id, name)
    if (res) renameProjectAction(_id, name)

    setLoading(false)
    setForm(false)
  }
  return (
    <>
      <div className="flex flex-col mb-2" key={_id}>
        <div
          className={`media relative cursor-pointer ${isActive && ' active'}`}
          style={{
            marginLeft: '15px',
            backgroundSize: 'cover',
            backgroundImage: `url(${
              project.scenes[0]?.data?.length > 0
                ? project.scenes[0].data[0].thumbUrl
                : '/project-bannar.jpg'
            })`,
          }}
          onClick={() => {
            onClick(_id, projectName)
          }}>
          <span className="text-white absolute left-0 bottom-0 pl-1">
            {new Date(duration).toISOString().substr(11, 8)}
          </span>
        </div>
        <div
          className="left"
          style={{
            marginTop: '-10px',
            fontSize: '12px',
          }}>
          {formOpen ? (
            <>
              <button
                disabled={loading}
                style={{
                  padding: '3px 6px',
                  border: '1px solid #999',
                  borderRadius: '3px 0 0 3px',
                }}
                onClick={closeForm}>
                <FontAwesomeIcon icon={faTimes} size="sm" />
              </button>
              <input
                value={name}
                onChange={changeName}
                style={{
                  border: '1px solid #999',
                  padding: '3px 6px',
                  borderLeft: 'none',
                  borderRight: 'none',
                }}
                placeholder={projectName}
                readOnly={loading}
              />
              <button
                disabled={loading}
                style={{
                  padding: '3px 6px',
                  border: '1px solid #999',
                  borderRadius: '0 3px 3px 0',
                }}
                onClick={updateName}>
                <FontAwesomeIcon
                  icon={loading ? faSpinner : faCheck}
                  size="sm"
                  className={`${loading && 'fa-spin'}`}
                />
              </button>
            </>
          ) : (
            <>
              <button style={{ padding: '3px 6px' }} onClick={openForm}>
                <FontAwesomeIcon icon={faEdit} size="sm" />
              </button>
              <a
                href={`/editor/${_id}`}
                target="blank"
                className="text-blue-500 underline">
                {name}
              </a>
            </>
          )}
        </div>
      </div>

      {project.status === 'publish' && (
        <div style={styles.cbxWrap}>
          <div
            style={styles.cbxItem}
            onClick={() => (isActive ? selectAspect('16:9') : () => {})}>
            <FontAwesomeIcon
              icon={
                isActive && aspectRatios.includes('16:9')
                  ? faCheckSquare
                  : faSquare
              }
              size="sm"
              style={{ margin: '0 3px' }}
            />
            <div>16:9</div>
          </div>

          <div
            style={styles.cbxItem}
            onClick={() => (isActive ? selectAspect('1:1') : () => {})}>
            <FontAwesomeIcon
              icon={
                isActive && aspectRatios.includes('1:1')
                  ? faCheckSquare
                  : faSquare
              }
              size="sm"
              style={{ margin: '0 3px' }}
            />
            <div>1:1</div>
          </div>

          <div
            style={styles.cbxItem}
            onClick={() => (isActive ? selectAspect('4:5') : () => {})}>
            <FontAwesomeIcon
              icon={
                isActive && aspectRatios.includes('4:5')
                  ? faCheckSquare
                  : faSquare
              }
              size="sm"
              style={{ margin: '0 3px' }}
            />
            <div>4:5</div>
          </div>

          <div
            style={styles.cbxItem}
            onClick={() => (isActive ? selectAspect('9:16') : () => {})}>
            <FontAwesomeIcon
              icon={
                isActive && aspectRatios.includes('9:16')
                  ? faCheckSquare
                  : faSquare
              }
              size="sm"
              style={{ margin: '0 3px' }}
            />
            <div>9:16</div>
          </div>
        </div>
      )}
    </>
  )
}

export default ProjectGridItem

const styles = {
  cbxWrap: {
    fontSize: 12,
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'flex-start',
    alignContent: 'flex-start',
    marginLeft: '-15px',
    width: '60px',
    minWidth: '60px',
  },

  cbxItem: {
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    cursor: 'pointer',
    height: '20px',
    color: '#6d7173',
  },
}
