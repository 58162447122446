import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import SelectItem from './timeline-items/SelectItem'
import {
  onUndo,
  toggleWatchProject,
  updateProject,
  storeProjectSteps,
} from '../../../redux/actions/EditorActions'
import * as MediaPlayerActions from '../../../redux/actions/MediaPlayerActions'
import * as SelectedItemActions from '../../../redux/actions/SelectedItemActions'
import ZoomoutImg from '../../../assets/images/editor/zoomout.png'
import ZoominImg from '../../../assets/images/editor/zoomin.png'
import {
  changeRatio,
  cutSelectedItems,
  deleteSelectedItems,
  getCurrSec,
  handleUndo,
  getSelectedItemByChannel,
  setSelectItemByChannelToBeEmpty,
} from '../../../methods/editor/SceneCutDelMethods'
import * as MainContainerActions from '../../../redux/actions/MainContainerActions'
import { EditorCutIcon } from '../icons/EditorCutIcon'
import { EditorDeleteIcon } from '../icons/EditorDeleteIcon'
import { EditorUndoIcon } from '../icons/EditorUndoIcon'
import StageRatioDropDown from '../common-items/StageRatioDropDown'
import MainTimeline from './timeline-items/MainTimeline'

class Timeline extends Component {
  constructor(props) {
    super(props)

    this.state = {
      zoomValue: 95,
    }

    this.tempSetRef = React.createRef()
    this.deleteSelectedItems = deleteSelectedItems.bind(this)
    this.cutSelectedItems = cutSelectedItems.bind(this)
    this.changeRatio = changeRatio.bind(this)
    this.getCurrSec = getCurrSec.bind(this)
    this.handleUndo = handleUndo.bind(this)
    this.getSelectedItemByChannel = getSelectedItemByChannel.bind(this)
    this.setSelectItemByChannelToBeEmpty =
      setSelectItemByChannelToBeEmpty.bind(this)
  }

  changeZoom = (e) => {
    const newZoomValue = e.target.value
    this.setState({ zoomValue: newZoomValue })
  }

  nextZoom = () => {
    const zommValue = this.state.zoomValue
    if (zommValue < 100) {
      this.setState({ zoomValue: this.state.zoomValue + 1 })
    }
  }

  previousZoom = () => {
    const zommValue = this.state.zoomValue
    if (zommValue > 0) {
      this.setState({ zoomValue: this.state.zoomValue - 1 })
    }
  }

  render() {
    const {
      updateTimeSlider,
      updateSlideV,
      toggleShowImport,
      toggleShowTextPanel,
      scenePlaying,
      showTextPanel,
      projectSteps,
    } = this.props

    return (
      <div className="timeline updated">
        {/* Timeline Buttons */}
        <div className="timeline-buttons">
          <div className="timeline-buttons__medias">
            <div className="media-btn cut">
              <EditorCutIcon
                onClick={() => {
                  this.cutSelectedItems()
                }}
              />
            </div>
            <div className="media-btn delete">
              <EditorDeleteIcon
                onClick={() => {
                  this.deleteSelectedItems()
                }}
              />
            </div>
            <div
              className={`media-btn undo${
                projectSteps?.length > 0 ? '' : ' disabled'
              }`}>
              <EditorUndoIcon
                onClick={() => {
                  this.handleUndo()
                }}
              />
            </div>
          </div>
          <div className="timeline-buttons__editor">
            <StageRatioDropDown
              updateSlideV={updateSlideV}
              updateTimeSlider={updateTimeSlider}
            />
            <div
              className="zoom-input-container"
              style={{
                padding: '0px 15px',
              }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <img
                  className={`${this.state.zoomValue <= 0 ? 'disabled' : ''}`}
                  src={ZoomoutImg}
                  alt="zoom-out"
                  onClick={this.previousZoom}
                />
                <input
                  className="zoom-control"
                  type="range"
                  id="vol"
                  name="vol"
                  min="0"
                  max="100"
                  value={this.state.zoomValue}
                  onChange={this.changeZoom}
                />
                <img
                  className={`${this.state.zoomValue >= 100 ? 'disabled' : ''}`}
                  src={ZoominImg}
                  alt="zoom-in"
                  onClick={this.nextZoom}
                />
                <span
                  style={{
                    width: 50,
                    height: 25,
                    background: '#374151',
                    color: 'white',
                    marginLeft: 12,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontWeight: '600',
                    fontSize: 12,
                    borderRadius: 4,
                  }}>
                  {this.state.zoomValue}%
                </span>
              </div>
            </div>
          </div>
        </div>

        {/* Timeline items */}
        <div className="timeline-items-wrap">
          {/* Select timeline items */}
          <SelectItem
            toggleShowTextPanel={toggleShowTextPanel}
            toggleShowImport={toggleShowImport}
            zoomValue={this.state.zoomValue / 100}
          />

          {/* Main timeline */}
          {/* // This component when commented out reduces the memory usage */}
          <MainTimeline
            updateShowSidebarMedia={this.props.updateShowSidebarMedia}
            updateSlideV={updateSlideV}
            updateTimeSlider={updateTimeSlider}
            toggleShowImport={toggleShowImport}
            toggleShowTextPanel={toggleShowTextPanel}
            pauseTimeline={() => this.props.pauseTimeline()}
            scenePlaying={scenePlaying}
            showTextPanel={showTextPanel}
            zoomValue={this.state.zoomValue / 100}
            deleteSelectedItems={this.deleteSelectedItems}
            handleDisplayAudioDuckModal={this.props.handleDisplayAudioDuckModal}
            isAudioDuckModalDisplayed={this.props.isAudioDuckModalDisplayed}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  authUser: state.auth.user,
  checkedChannels: state.selectedItems.checkedChannels,
  project: state.editor.project,
  selectedMusic: state.selectedItems.selectedMusic,
  selectedMusicIndex: state.selectedItems.selectedMusicIndex,
  selectedScene: state.selectedItems.selectedScene,
  selectedSceneIndex: state.selectedItems.selectedSceneIndex,
  selectedSound: state.selectedItems.selectedSound,
  selectedSoundIndex: state.selectedItems.selectedSoundIndex,
  selectedText: state.selectedItems.selectedText,
  selectedTextIndex: state.selectedItems.selectedTextIndex,
  selectedVoiceover: state.selectedItems.selectedVoiceover,
  selectedVoiceoverIndex: state.selectedItems.selectedVoiceoverIndex,
  addItem: state.editor.addItem,
  timeSlider: state.timeline.timeSlider,
  projectSteps: state.editor.projectSteps,
  watchProject: state.editor.watchProject,
})

const mapDispatchToProps = (dispatch) => ({
  toggleWatchProject: (...args) => dispatch(toggleWatchProject(...args)),
  reloadStageByProject: (...args) =>
    dispatch(MainContainerActions.reloadStageByProject(...args)),
  resetAllSelected: (...args) =>
    dispatch(SelectedItemActions.resetAllSelected(...args)),
  setSelectedMusic: (...any) =>
    dispatch(SelectedItemActions.setSelectedMusic(...any)),
  setSelectedMusicIndex: (...any) =>
    dispatch(SelectedItemActions.setSelectedMusicIndex(...any)),
  setSelectedScene: (...any) =>
    dispatch(SelectedItemActions.setSelectedScene(...any)),
  setSelectedSceneIndex: (...any) =>
    dispatch(SelectedItemActions.setSelectedSceneIndex(...any)),
  setSelectedSound: (...any) =>
    dispatch(SelectedItemActions.setSelectedSound(...any)),
  setSelectedSoundIndex: (...any) =>
    dispatch(SelectedItemActions.setSelectedSoundIndex(...any)),
  setSelectedText: (...any) =>
    dispatch(SelectedItemActions.setSelectedText(...any)),
  setSelectedTextIndex: (...any) =>
    dispatch(SelectedItemActions.setSelectedTextIndex(...any)),
  setSelectedVoiceover: (...any) =>
    dispatch(SelectedItemActions.setSelectedVoiceover(...any)),
  setSelectedVoiceoverIndex: (...any) =>
    dispatch(SelectedItemActions.setSelectedVoiceoverIndex(...any)),
  onUndo: (...args) => dispatch(onUndo(...args)),
  storeProjectSteps: (...any) => dispatch(storeProjectSteps(...any)),
  updateProject: (...args) => dispatch(updateProject(...args)),
  pauseTimeline: (...args) =>
    dispatch(MediaPlayerActions.pauseTimeline(...args)),
})
export default connect(mapStateToProps, mapDispatchToProps)(Timeline)
