import { FLOAT_RATE, SLIDER_PRO } from '../../constants/TimelineItems'

export function SetButtonAble() {
  const { project, selectedSceneIndex } = this.props

  if (
    project &&
    project.scenes[project.resolution] &&
    project.scenes[project.resolution].length
  ) {
    if (project.scenes[project.resolution].length > 1) {
      if (selectedSceneIndex === 0) {
        this.setState({
          backAble: false,
          forwardAble: true,
        })
      } else if (
        selectedSceneIndex ===
        project.scenes[project.resolution].length - 1
      ) {
        this.setState({
          backAble: true,
          forwardAble: false,
        })
      } else {
        this.setState({
          backAble: true,
          forwardAble: true,
        })
      }
    } else {
      this.setState({
        backAble: true,
        forwardAble: true,
      })
    }
  }
}

/**
 * play, pause video
 * @param flag
 */
export function togglePlayVideo(flag) {
  const { project } = this.props

  if (project.duration === 0) {
    return
  }

  this.props.setPlayCompleted(!flag)

  this.setState({
    playing: flag,
  })

  this.props.togglePlay(flag)
}

/**
 * back scene
 * @param flag
 */
export function toggleBackHover(flag) {
  const { project, selectedSceneIndex, setSelectedSceneIndex } = this.props

  if (
    project &&
    project.scenes[project.resolution] &&
    project.scenes[project.resolution].length
  ) {
    if (
      project.scenes[project.resolution].length === 1 ||
      !this.state.backAble
    ) {
      return
    }
  } else {
    return
  }

  this.setState({
    backHover: flag,
  })

  setSelectedSceneIndex(selectedSceneIndex - 1)

  const item = project.scenes[project.resolution][selectedSceneIndex - 1]

  this.updateSliderValue(item)
}

export function updateSliderValue(item) {
  const {
    setSelectedScene,
    updateTimeSlider,
    timeSlider,
    project,
    SetTimeSlider,
  } = this.props

  setSelectedScene(item)

  const pro =
    Math.round(
      ((item.start_time + 500) / project.duration) * SLIDER_PRO * FLOAT_RATE,
    ) / FLOAT_RATE

  let curPro = Math.round((pro / SLIDER_PRO) * FLOAT_RATE) / FLOAT_RATE
  if (curPro > 1) {
    curPro = 1
  }

  updateTimeSlider(pro)

  SetTimeSlider({
    curSec: Math.round(curPro * project.duration * SLIDER_PRO) / SLIDER_PRO,
    value: pro,
    width: timeSlider.width,
    show: true,
  })
}

/**
 * forward hover
 * @param flag
 */
export function toggleForwardHover(flag) {
  const { project, selectedSceneIndex, setSelectedSceneIndex } = this.props

  if (
    project &&
    project.scenes[project.resolution] &&
    project.scenes[project.resolution].length
  ) {
    if (
      project.scenes[project.resolution].length === 1 ||
      !this.state.forwardAble
    ) {
      return
    }
  } else {
    return
  }

  this.setState({
    forwardHover: flag,
  })

  setSelectedSceneIndex(selectedSceneIndex + 1)

  const item = project.scenes[project.resolution][selectedSceneIndex + 1]

  this.updateSliderValue(item)
}
