import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

function GoogleAnalytics() {
  const location = useLocation()

  useEffect(() => {
    window.gtag('config', 'G-RMM0LG95HR', {
      page_path: location.pathname,
    })
  }, [location])

  return null
}

export default GoogleAnalytics
