import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import process from 'process'
import { Tooltip } from 'react-tippy'
import { connect } from 'react-redux'
import LogoText from '../../assets/images/Cerebriam_Studio_Logo_rgb2_300.webp'
import { EditorHeaderHamburger } from './icons/EditorHeaderHamburger'
import {
  SubscriptionManager,
  alertSubscriptionMessage,
  triggerGoogleAnalyticsEvent,
} from '../../utils/Helper'
import * as EditorActions from '../../redux/actions/EditorActions'
import * as MediaPlayerActions from '../../redux/actions/MediaPlayerActions'
import * as RecorderActions from '../../redux/actions/RecorderActions'
import { logoutUser } from '../../redux/actions/AuthActions'
import ProjectsModal from '../modals/ProjectsModal'
import SettingsSubBar from '../sidebar/SettingsBar'
import { EditorInfoIcon } from './icons/EditorInfoIcon'
import { Link } from 'react-router-dom'
import { HOME } from '../../constants/Routes'

class EditorHeader extends Component {
  constructor(props) {
    super(props)

    this.state = {
      openHamburger: false,
      isProjectsModalOpen: false,
      showSettingsBar: false,
      openSettingsOverlay: false,
    }
  }

  get isProjectPlayable() {
    const { project } = this.props
    return project.duration > 0
  }

  openProjectsModal = () => {
    triggerGoogleAnalyticsEvent('open_project_modal', {
      userId: this.props.authUser?.id,
    })
    this.setState({
      isProjectsModalOpen: true,
    })
  }

  closeProjectsModal = () => {
    this.setState({
      isProjectsModalOpen: false,
    })
  }

  /**
   * show toggle button
   */
  toggleSettingsBarOpen = () => {
    triggerGoogleAnalyticsEvent('toggle_settings_bar', {
      value: !this.state.showSettingsBar,
      userId: this.props.authUser?.id,
    })
    this.setState({
      showSettingsBar: !this.state.showSettingsBar,
      openHamburger: true,
    })
  }

  closeHamburgerMenu = () => {
    this.setState({ openHamburger: false, showSettingsBar: false })
  }

  openHamburgerMenu = () => {
    this.setState({ openHamburger: true })
  }

  initiateNewProject = () => {
    triggerGoogleAnalyticsEvent('initiate_new_project', {
      userId: this.props.authUser?.id,
    })
    window.location.href = '/editor/0'
  }

  handleReview = (authUser) => {
    if (this.props.project.duration > 0) {
      if (SubscriptionManager(authUser)?.isFreePlan) {
        alertSubscriptionMessage(
          'Nice project, want to upgrade to remove our watermark, and save it in your library. Otherwise it’ll be deleted after 24 hours.',
        )
      }
      this.props.pauseTimeline()
      this.props.setPlaying(false)
      process.nextTick(() => {
        this.props.toggleShowReview(true, authUser)
      })
    }
  }
  render() {
    const { openHamburger, isProjectsModalOpen, showSettingsBar } = this.state
    return (
      <div className="editor-header w-full">
        <div className="editor-header__content w-full flex">
          <div className="menu-side">
            <div className={`hamburger${openHamburger ? ' open' : ''}`}>
              <div className="hamburger-icon" onClick={this.openHamburgerMenu}>
                <EditorHeaderHamburger />
              </div>
              <div className={`content${openHamburger ? ' open' : ''}`}>
                <ul>
                  <li onClick={this.openProjectsModal}>My Projects</li>
                  <li onClick={this.toggleSettingsBarOpen}>Settings</li>
                  <li onClick={this.props.logoutUser}>Sign Out</li>
                </ul>
              </div>
              <div
                onClick={this.closeHamburgerMenu}
                className={`content-overlay${
                  openHamburger ? ' active' : ''
                }`}></div>
            </div>
            <div className="logo">
              <Link to={HOME}>
                <img src={LogoText} alt="editor-header-logo" />
              </Link>
            </div>
          </div>

          <div className="btns">
            <Tooltip
              title="Activate steps guide"
              position="bottom"
              trigger="mouseenter focus">
              <EditorInfoIcon
                className="info-icon"
                onClick={this.props.reactivateTooltipSteps}
              />
            </Tooltip>
            <Tooltip
              title="Start new project"
              position="bottom"
              trigger="mouseenter focus">
              <button
                className="btn new-project"
                onClick={this.initiateNewProject}>
                New Project
              </button>
            </Tooltip>

            <Tooltip
              title="There should be a least one asset in the project to enable preview"
              position="bottom"
              trigger="mouseenter focus"
              disabled={this.isProjectPlayable}>
              <button
                onClick={() => this.handleReview(this.props.authUser)}
                className={`btn preview${
                  !this.isProjectPlayable ? ' disabled' : ''
                }`}>
                Preview
              </button>
            </Tooltip>
          </div>
        </div>
        <div className="menu-area overflow-hidden" style={{ height: 500 }}>
          {isProjectsModalOpen && (
            <ProjectsModal closeModal={this.closeProjectsModal} />
          )}
          {showSettingsBar && (
            <SettingsSubBar
              toggleSnapGrid={this.props.toggleSnapGrid}
              toggleSettingsBarOpen={this.toggleSettingsBarOpen}
            />
          )}
        </div>
      </div>
    )
  }
}

EditorHeader.defaultProps = {
  toggleSnapGrid: () => {},
  MenuPage: 'record',
  ProjectId: '0',
}

const mapStateToProps = (state) => ({
  authUser: state.auth.user,
  project: state.editor.project,
})

const mapDispatchToProps = (dispatch) => ({
  setPlaying: (...args) => dispatch(MediaPlayerActions.setPlaying(...args)),
  toggleWatchProject: (...args) =>
    dispatch(EditorActions.toggleWatchProject(...args)),
  pauseTimeline: (...args) =>
    dispatch(MediaPlayerActions.pauseTimeline(...args)),
  toggleSideBar: (...args) => dispatch(RecorderActions.toggleSideBar(...args)),
  logoutUser: (...args) => dispatch(logoutUser(...args)),
})
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(EditorHeader))
