import React from 'react'
import _ from 'lodash'
import moment from 'moment'
import AspectRatio from 'react-aspect-ratio'
import RATIO, { ASPECT_RATIO } from '../../constants/AspectRatios'
import * as Channels from '../../constants/Channels'
import AudioPlayer from './AudioPlayer'
import VideoPlayer from './VideoPlayer'
import ProjectItemUtils from './../../utils/ProjectItemUtils'
import MoviePlayerControls from './MoviePlayerControls'
import { TICK_IN_MILLISECONDS, TimeTickerContext } from './TimeTickerContext'
import Loader from 'react-loader-spinner'
import { ConvertDuration } from '../../utils/TimeManage'
import { EditorRewindIcon } from '../video-editor/icons/EditorRewindIcon'
import { EditorPlayIcon } from '../video-editor/icons/EditorPlayIcon'
import { EditorForwardIcon } from '../video-editor/icons/EditorForwardIcon'
import { ReactMediaRecorder } from 'react-media-recorder'
import { VoiceRecorderTimeline } from '../voiceover/VoiceRecorderTimeline'

class RecordButton extends React.Component {
  handleStartRecord = () => {
    const { disabled, onStartRecording } = this.props
    onStartRecording && !disabled && onStartRecording()
  }

  handleEndRecord = () => {
    const { disabled, onStopRecording } = this.props
    onStopRecording && !disabled && onStopRecording()
  }
  render() {
    return (
      <div
        onClick={() =>
          !this.props.isRecording
            ? this.handleStartRecord()
            : this.handleEndRecord()
        }
        className="record-btn"
        style={{
          background: '#D14747',
          color: '#fff',
          width: 115,
          borderRadius: 8,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: 39,
          cursor: 'pointer',
        }}>
        <div
          className="record-circle"
          style={{
            width: 20,
            height: 20,
            background: '#fff',
            borderRadius: this.props.isRecording ? '5%' : '50%',
            marginRight: 8,
          }}></div>
        <span>{this.props.isRecording ? 'Stop' : 'Record'}</span>
      </div>
    )
  }
}

class MovieProjectPlayer extends React.Component {
  videoObject = React.createRef()
  canvasObject = React.createRef()
  viewportContainer = React.createRef()

  static contextType = TimeTickerContext

  getTopPosition(streamSizeBox, aspectSizeBox) {
    const originVideoSize = this.props.originVideoSize
    const heightRatio = originVideoSize.height / streamSizeBox.height
    return aspectSizeBox.top * heightRatio
  }

  getLeftPosition(streamSizeBox, aspectSizeBox) {
    const originVideoSize = this.props.originVideoSize
    const widthRatio = originVideoSize.width / streamSizeBox.width
    return aspectSizeBox.left * widthRatio
  }

  getWidthPosition(streamSizeBox, aspectSizeBox) {
    const originVideoSize = this.props.originVideoSize
    const widthRatio = originVideoSize.width / streamSizeBox.width
    return aspectSizeBox.width * widthRatio
  }

  getHeightPosition(streamSizeBox, aspectSizeBox) {
    const originVideoSize = this.props.originVideoSize
    const heightRatio = originVideoSize.height / streamSizeBox.height
    return aspectSizeBox.height * heightRatio
  }

  triggerLoop = (video, context) => {
    const { aspectSizes, selectedLayout, streamSize, shootValue } = this.props
    const aspectSizeBox = aspectSizes[selectedLayout]
    const streamSizeBox = streamSize[selectedLayout]
    const selectedShootValue = shootValue[selectedLayout]
    if (!video.paused && !video.ended) {
      if (aspectSizeBox) {
        context.drawImage(
          video,
          this.getLeftPosition(streamSizeBox, aspectSizeBox),
          this.getTopPosition(streamSizeBox, aspectSizeBox),
          this.getWidthPosition(streamSizeBox, aspectSizeBox),
          this.getHeightPosition(streamSizeBox, aspectSizeBox),
          0,
          0,
          aspectSizeBox.width,
          aspectSizeBox.height,
        )
      } else {
        context.drawImage(video, 0, 0)
      }
      setTimeout(() => this.triggerLoop(video, context), 1000 / 30)
    }
  }

  // componentDidMount() {
  //   const video = this.videoObject.current
  //   const canvas = this.canvasObject.current
  //   video.addEventListener('play', () => {
  //     const context = canvas.getContext('2d')
  //     this.triggerLoop(video, context)
  //   })
  // }

  renderAudioPlayers() {
    const { project, currentTime, isPlaying, isRecording } = this.props
    return (
      <div>
        {_.map(project[Channels.VOICEOVER.projectPath], (item, index) => (
          <AudioPlayer
            item={item}
            key={`vp-${index}`}
            isBuffering={this.props.isBuffering}
            onBufferingStarted={() => {
              const { onBufferingStarted } = this.props
              onBufferingStarted && onBufferingStarted()
            }}
            onBufferingFinished={() => {
              const { onBufferingFinished } = this.props
              onBufferingFinished && onBufferingFinished()
            }}
            isPlaying={isPlaying}
            isRecording={isRecording}
            currentTime={currentTime}
            tickInMilliseconds={this.context.tickInMilliseconds}
          />
        ))}
        {_.map(project[Channels.AUDIO.projectPath], (item, index) => (
          <AudioPlayer
            item={item}
            key={`ap-${index}`}
            isBuffering={this.props.isBuffering}
            onBufferingStarted={() => {
              const { onBufferingStarted } = this.props
              onBufferingStarted && onBufferingStarted()
            }}
            onBufferingFinished={() => {
              const { onBufferingFinished } = this.props
              onBufferingFinished && onBufferingFinished()
            }}
            isPlaying={isPlaying}
            isRecording={isRecording}
            currentTime={currentTime}
            tickInMilliseconds={this.context.tickInMilliseconds}
          />
        ))}
        {_.map(project[Channels.MUSIC.projectPath], (item, index) => (
          <AudioPlayer
            item={item}
            key={`mp-${index}`}
            isBuffering={this.props.isBuffering}
            onBufferingStarted={() => {
              const { onBufferingStarted } = this.props
              onBufferingStarted && onBufferingStarted()
            }}
            onBufferingFinished={() => {
              const { onBufferingFinished } = this.props
              onBufferingFinished && onBufferingFinished()
            }}
            isPlaying={isPlaying}
            isRecording={isRecording}
            currentTime={currentTime}
            tickInMilliseconds={this.context.tickInMilliseconds}
          />
        ))}
      </div>
    )
  }

  get currentVideoItem() {
    const { aspectRatio, currentTime, project } = this.props
    const currentVideoItem = _.find(
      project[Channels.VIDEO.projectPath][aspectRatio],
      (item) => {
        return (
          ProjectItemUtils.isTimeAroundUnrecordableArea(currentTime, item) &&
          item.type === 'video'
        )
      },
    )
    return currentVideoItem
  }

  getCurrentAudioItem(channel) {
    const { currentTime, project } = this.props
    const currenItem = _.find(project[channel.projectPath], (item) => {
      return ProjectItemUtils.isTimeAroundUnrecordableArea(currentTime, item)
    })
    return currenItem
  }

  renderStage() {
    const { aspectRatio, isPlaying, currentTime, isBuffering } = this.props
    const selectedAspectRatio =
      ASPECT_RATIO[aspectRatio] || ASPECT_RATIO[RATIO.LANDSCAPE]
    const currentVideoItem = this.currentVideoItem
    // const { src, aspectSizes, rectangleSizes, selectedLayout } = this.props
    // const selectedCropBox = aspectSizes[selectedLayout];
    // const selectedRect = rectangleSizes[selectedLayout];
    return (
      <AspectRatio ratio="16/9" style={{ background: 'rgb(30, 30, 30)' }}>
        <div className="flex justify-center items-center">
          {/* <video
            style={{ visibility: 'hidden', position: 'absolute' }}
            // width={selectedRect.width}
            muted
            // height={selectedRect.height}
            ref={this.videoObject}
            loop
            playsInline
            autoPlay
            id="hidden-preview" src={src} controls="false"
          /> */}
          {/* <canvas
            style={{
              height: '100%'
            }}
            // width={selectedCropBox.width}
            // height={selectedCropBox.height}
            ref={this.canvasObject}
            id="preview" /> */}
          <AspectRatio
            ratio={selectedAspectRatio}
            className="percent-height-aspect-ratio"
            style={{
              '--height': '100%',
            }}>
            <div
              ref={this.viewportContainer}
              style={{ background: 'black', overflow: 'hidden' }}>
              {currentVideoItem && (
                <VideoPlayer
                  project={this.props.project}
                  item={currentVideoItem}
                  viewport={this.viewportContainer.current}
                  currentTime={currentTime}
                  isPlaying={isPlaying}
                  tickInMilliseconds={this.context.tickInMilliseconds}
                  onBufferingStarted={() => {
                    const { onBufferingStarted } = this.props
                    onBufferingStarted && onBufferingStarted()
                  }}
                  onBufferingFinished={() => {
                    const { onBufferingFinished } = this.props
                    onBufferingFinished && onBufferingFinished()
                  }}
                />
              )}
              {isBuffering && (
                <div className="flex absolute top-0 w-full h-full items-center justify-center">
                  <Loader type="Puff" color="gray" height={50} width={50} />
                </div>
              )}
            </div>
          </AspectRatio>
        </div>
      </AspectRatio>
    )
  }

  renderTimer(time) {
    return moment('2000-01-01 00:00:00')
      .millisecond(time || 0)
      .format('mm:ss')
  }

  handleStartRecording = (nativeStartRecording, recorderProps) => {
    const { onStartRecording } = this.props
    onStartRecording && onStartRecording(recorderProps.mediaBlobUrl)
    nativeStartRecording && nativeStartRecording()
  }

  handleStopRecording = (blobUrl, blob) => {
    const { onStopRecording } = this.props
    onStopRecording && onStopRecording(blobUrl, blob)
  }

  renderRecorder = (recorderProps) => {
    const { disabled, isRecording } = this.props
    const { startRecording, stopRecording } = recorderProps
    return (
      <RecordButton
        ref={this.props.recordButton}
        disabled={disabled}
        isRecording={isRecording}
        showRecord
        onStartRecording={() =>
          this.handleStartRecording(startRecording, recorderProps)
        }
        onStopRecording={stopRecording}
      />
    )
  }

  render() {
    const {
      currentTime,
      project,
      withControls,
      isPlaying,
      isRecording,
      currentRecordId,
      currentRecordItem,
      onChangeCurrentTime,
    } = this.props
    return (
      <div>
        <div>{this.renderStage()}</div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: 10,
            marginBottom: 10,
            alignItems: 'center',
          }}>
          <div
            className="flex flex-start w-max font-bold"
            style={{
              height: 17,
              width: 87,
              backgroundColor: '#E5E7EB',
              fontSize: 11,
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
              borderRadius: 4,
            }}>
            <span>
              {ConvertDuration(currentTime)} /{' '}
              {this.renderTimer(project.duration)}
            </span>
          </div>
          <div
            className="video-control"
            style={{ display: 'flex', alignItems: 'center' }}>
            <EditorRewindIcon
              className={
                'editor-rewind-icon' +
                (!isPlaying &&
                project &&
                project.scenes[project.resolution].length &&
                project.scenes[project.resolution].length > 1
                  ? ''
                  : ' disabled')
              }
              onClick={this.props.handleRewindScene}
            />

            {!isPlaying && (
              <EditorPlayIcon
                className={`editor-play-icon${
                  project?.duration !== 0 ? '' : ' disabled'
                }`}
                style={{ marginLeft: 5, marginRight: 5 }}
                onClick={() => {
                  this.props.onStartPlaying()
                }}
              />
            )}

            {isPlaying && (
              <div
                className={`editor-pause-icon${
                  project?.duration !== 0 ? '' : ' disabled'
                }`}
                style={{
                  marginLeft: 5,
                  marginRight: 5,
                }}
                onClick={() => {
                  this.props.onStopPlaying()
                }}>
                ||
              </div>
            )}
            <EditorForwardIcon
              className={
                'editor-forward-icon' +
                (!isPlaying &&
                project &&
                project.scenes[project.resolution].length &&
                project.scenes[project.resolution].length > 1
                  ? ''
                  : ' disabled')
              }
              onClick={this.props.handleForwardScene}
            />
          </div>

          <ReactMediaRecorder
            audio
            onStop={this.handleStopRecording}
            render={(recorderProps) => this.renderRecorder(recorderProps)}
          />
        </div>

        <VoiceRecorderTimeline
          project={project}
          isPlaying={isPlaying}
          isRecording={isRecording}
          currentRecordId={currentRecordId}
          currentRecordItem={currentRecordItem}
          currentTime={currentTime}
          tickInMilliseconds={TICK_IN_MILLISECONDS}
          onChangeCurrentTime={onChangeCurrentTime}
        />

        <div className="record-voiceover__btns">
          <button
            className="undo-btn"
            onClick={this.props.handleRevertLastItem}>
            Undo
          </button>
          <button
            className="keep-btn"
            onClick={this.props.handleSaveRecordedAudio}
            disabled={this.props.isSaveButtonDisabled}>
            <span> Keep</span>
            <span>
              {this.props.saving && (
                <Loader type="Puff" color="white" height={20} width={20} />
              )}
            </span>
          </button>
        </div>
        {withControls && (
          <MoviePlayerControls currentTime={currentTime} project={project} />
        )}
        {this.renderAudioPlayers()}
      </div>
    )
  }
}

export default MovieProjectPlayer
