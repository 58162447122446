//Main Container Reducers
import _ from 'lodash'
import * as Constants from '../constants/MediaPlayerConstants'
import * as TimelineService from '../../services/time/TimelineService'

const initialState = {
  showStage: false,
  tweenObjectBuilder: null,
  assets: {},
  isVoiceoverModalDisplayed: false,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case Constants.SET_PLAY_COMPLETED:
      return {
        ...state,
        isPlayCompleted: action.isPlayCompleted,
      }
    case Constants.SET_PAUSE_TIMELINE:
      TimelineService.pauseAllHtmlAssets(state.assets)
      return {
        ...state,
        isTimelinePaused: action.isTimelinePaused,
      }
    case Constants.SET_PLAYING:
      TimelineService.pauseAllHtmlAssets(state.assets)
      return {
        ...state,
        scenePlaying: action.scenePlaying,
      }
    case Constants.SET_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      }
    case Constants.CHANGE_ASPECT_RATIO:
      return {
        ...state,
        aspectRatio: action.aspectRatio,
      }
    default:
      return state
  }
}
