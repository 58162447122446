import { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { loginUser } from '../../redux/actions/AuthActions'
import { PaymentConstants } from '../../constants/Payments'
import { CardElement, ElementsConsumer } from '@stripe/react-stripe-js'

const emptyFormData = {
  email: '',
  password: '',
  passwordTwo: '',
  name: '',
}
const emptyFormErrors = {
  email: false,
  password: false,
  passwordTwo: false,
  name: false,
}

const RegisterUserForm = ({
  activeCycle,
  currentPlan,
  setRegisterFormData,
  changeActive,
}) => {
  const dispatch = useDispatch()
  const cardRef = useRef()
  const {
    errors,
    auth: { signUpLoading },
  } = useSelector((state) => state)
  const [formData, setFormData] = useState(emptyFormData)
  const [formErrors, setFormErrors] = useState(emptyFormErrors)
  const [cardToken, setCardToken] = useState('')
  const [cardVerificationHappened, setCardVerificationHappened] =
    useState(false)

  const changeElement = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    })
    if (e.target.value) {
      setFormErrors({
        ...formErrors,
        [e.target.name]: false,
      })
    } else {
      setFormErrors({
        ...formErrors,
        [e.target.name]: true,
      })
    }
    if (e.target.name === 'passwordTwo') {
      if (e.target.value !== formData.password) {
        setFormErrors({
          ...formErrors,
          passwordTwo: true,
        })
      }
    }
  }

  const signUp = (e) => {
    e.preventDefault()
    if (!validateForm()) {
      return
    }

    const newUser = {
      name: formData.name,
      email: formData.email,
      password: formData.password,
      password2: formData.password,
      cardToken,
      duration:
        activeCycle === 1
          ? PaymentConstants.subscriptionDuration.annually
          : activeCycle === 2
          ? PaymentConstants.subscriptionDuration.monthly
          : PaymentConstants.subscriptionDuration.pay_as_you_go,
      planId: currentPlan._id,
      price:
        activeCycle === 1
          ? currentPlan?.stripe_price_annually
          : activeCycle === 2
          ? currentPlan?.stripe_price_monthly
          : currentPlan?.stripe_price_pay_as_you_go,
    }
    setRegisterFormData(newUser)
    changeActive(3)
  }

  const signIn = (e) => {
    e.preventDefault()
    const data = { email: formData.email, password: formData.password }
    dispatch(loginUser(data))
  }

  const validateForm = () => {
    const data = formData
    let validated = true
    const newErrors = { ...formErrors }
    Object.keys(data).forEach((key) => {
      if (!data[key]) {
        validated = false
        newErrors[key] = true
      }
      if (key === 'passwordTwo') {
        if (data[key] !== data.password) {
          validated = false
          newErrors[key] = true
        }
      }
    })
    if (!cardToken) {
      newErrors.cardElement = true
      validated = false
    }
    setFormErrors(newErrors)
    return validated
  }
  const handleSubmit = async (event, stripe, elements) => {
    event.preventDefault()

    if (!stripe || !elements) {
      return
    }

    const card = elements.getElement(CardElement)
    const result = await stripe.createToken(card)
    setCardVerificationHappened(true)

    if (result.error) {
      setFormErrors({
        ...formErrors,
        cardElement: true,
      })
    } else {
      setCardToken(result.token.id)
    }
  }
  const stripeElementChange = (el) => {
    if (cardVerificationHappened) {
      setCardVerificationHappened(false)
      setCardToken('')
    }
    setFormErrors({
      ...formErrors,
      cardElement: !el.complete,
    })

    if (el.complete) {
      cardRef.current.requestSubmit()
    }
  }

  return (
    <div className="register-new-user cerebriam-column cerebriam-form signup">
      <p className="cerebriam-title signup">
        Especially for you: Its absolutely free for 14 days! You will not be
        charged until after your 14 day trial. We collect card details to stop
        spam on our application
      </p>
      <p className="cerebriam-title signup">
        Let us know your name and email address so we can sign you up for an
        account
      </p>

      <ElementsConsumer>
        {({ stripe, elements }) => (
          <form
            ref={cardRef}
            onSubmit={(event) => handleSubmit(event, stripe, elements)}
            style={{ marginBottom: 5 }}>
            {cardVerificationHappened && (
              <span
                style={{
                  fontSize: 10,
                  color: cardToken ? 'green' : 'red',
                  fontWeight: 'bold',
                }}>
                Card {cardToken ? 'verified' : 'failed'}
              </span>
            )}
            <CardElement
              className="stripe__card-element"
              id="card-element"
              options={{
                hidePostalCode: true,
                style: {
                  base: {
                    fontSize: '13px',
                    fontFamily: 'sans-serif',
                  },
                },
              }}
              onChange={(el) => stripeElementChange(el, 'card_number')}
            />{' '}
            {formErrors.cardElement && (
              <label className="error">Provide a valid card number</label>
            )}
          </form>
        )}
      </ElementsConsumer>

      <form onSubmit={signUp} autoComplete="off">
        <div className="cerebriam-row-1">
          <div className="cerebriam-input">
            <input
              type="text"
              name="name"
              className="form-control"
              placeholder="Full Name"
              onChange={changeElement}
            />
            {formErrors.name && (
              <label className="error">Name is required</label>
            )}
          </div>
        </div>
        <div className="cerebriam-row-1">
          <div className="cerebriam-input">
            <input
              type="email"
              name="email"
              className="form-control"
              placeholder="Your Email"
              onChange={changeElement}
            />
            {formErrors.email && (
              <label className="error">Email is required</label>
            )}
          </div>
        </div>
        <div className="cerebriam-row-1">
          <div className="cerebriam-input">
            <input
              type="password"
              name="password"
              className="form-control"
              placeholder="Your Password"
              onChange={changeElement}
            />
            {formErrors.password && (
              <label className="error">Password is required</label>
            )}
          </div>
        </div>
        <div className="cerebriam-row-1">
          <div className="cerebriam-input">
            <input
              type="password"
              name="passwordTwo"
              className="form-control"
              placeholder="Confirm your password"
              onChange={changeElement}
            />
            {formErrors.passwordTwo && (
              <label className="error">Password does not match</label>
            )}
          </div>
        </div>
        <p className="terms">
          By signing-up to an account you are agreeing to our{' '}
          <a
            href="https://web.cerebriam.com/terms-conditions/"
            target="_blank"
            rel="noreferrer">
            terms and conditions
          </a>{' '}
          and{' '}
          <a
            href="https://web.cerebriam.com/privacy-policy/"
            target="_blank"
            rel="noreferrer">
            privacy policy
          </a>
        </p>
        <button className="pay-button" type="submit" disabled={signUpLoading}>
          {'Subscribe'}
          {signUpLoading && (
            <FontAwesomeIcon className="spinner" icon={faSpinner} />
          )}
        </button>
        {errors && (
          <p className="text-red-500 text-xs italic mt-5">
            {errors.name} {errors.email} {errors.password} {errors.password2}
          </p>
        )}
      </form>
    </div>
  )
}

export default RegisterUserForm
