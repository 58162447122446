import {
  START_ANIMATION,
  textFade,
  textPulse,
  textSlide,
  transitionFade,
} from './ScenesAni'
import { gsap, TweenLite } from 'gsap'
import { CSSPlugin } from 'gsap/CSSPlugin'
import { ONE_SECOND } from '../../constants/Timeline'
import _, { update } from 'lodash'
import TimelineService from '../../services/time/TimelineService'
import { AUDIO, MUSIC, TEXT, VIDEO, VOICEOVER } from '../../constants/Channels'
import { TRANSITIONS } from '../../constants/TimelineItems'

gsap.registerPlugin(CSSPlugin)

const basicProps =
  'transform, transformOrigin, rotationX, rotationY, rotationZ, filter, opacity, x, y, autoAlpha, clip, transformStyle, skewX, skewY, scaleX, scaleY, scaleZ, scale, boxShadow, repeat, yoyo'

// const kill = ({ previewTimeLine, basicProps }) => {
//   previewTimeLine.kill()
//   previewTimeLine.set(
//     [
//       document.querySelector('.slides'),
//       document.querySelector('.element-animated'),
//     ],
//     { clearProps: basicProps },
//     0.05,
//   )
// }

const getTransitionIn = (value) => {
  let animateIn = Object.assign({}, START_ANIMATION),
    animate = {},
    animateInEnding

  if (value === 'fadeIn') {
    animate = transitionFade(animateIn, true)
  } else {
    animate = {
      animate: animateIn,
      animateEnding: animateIn,
    }
  }

  animateIn = animate.animate
  animateInEnding = animate.animateEnding

  return { animateIn, animateInEnding }
}

const getTransitionOut = (value) => {
  let animateOut = Object.assign({}, START_ANIMATION),
    animate = {},
    animateOutEnding

  if (value === 'fadeOut') {
    animate = transitionFade(animateOut, false)
  } else {
    animate = {
      animate: animateOut,
      animateEnding: animateOut,
    }
  }

  animateOut = animate.animate
  animateOutEnding = animate.animateEnding

  return { animateOut, animateOutEnding }
}

const getTextAniIn = (value, text, project) => {
  let animateIn = Object.assign({}, START_ANIMATION),
    animate = {},
    animateInEnding

  if (value === 'fade') {
    animate = textFade(animateIn, true)
  } else if (value === 'slide-right' || value === 'slide-left') {
    animate = textSlide(animateIn, text)
  } else if (value === 'pulse') {
    animate = textPulse(animateIn, true)
  } else {
    animate = {
      animate: animateIn,
      animateEnding: animateIn,
    }
  }

  animateIn = animate.animate
  animateInEnding = animate.animateEnding

  return { animateIn, animateInEnding }
}

const getTextAniOut = (value, text, project) => {
  let animateOut = Object.assign({}, START_ANIMATION),
    animate = {},
    animateOutEnding

  if (value === 'fade') {
    animate = textFade(animateOut, false)
  } else if (value === 'pulse') {
    animate = textPulse(animateOut, false)
  } else if (value === 'slide-right' || value === 'slide-left') {
    animate = textSlide(animateOut, text)
  } else {
    animate = {
      animate: animateOut,
      animateEnding: animateOut,
    }
  }

  animateOut = animate.animate
  animateOutEnding = animate.animateEnding

  return { animateOut, animateOutEnding }
}

function handleUpdate(updateSlider, previewTimeLine) {
  const progress = previewTimeLine?.progress()
  const duration = previewTimeLine?.duration()
  const currentTime = _.round(progress * duration * ONE_SECOND, -1)
  return updateSlider(progress, currentTime, duration)
}

export function SlideRender(
  { previewTimeLine, slides, texts, updateSlider, complete, t_slider },
  play,
  project,
  tweenWrapper,
  htmlElements,
) {
  const projectDurationInSeconds = TimelineService.convertMillisecondsToSeconds(
    project.duration,
  )

  if (previewTimeLine === null) {
    previewTimeLine = gsap.timeline({
      id: tweenWrapper.id,
      onUpdate() {
        return handleUpdate(updateSlider, this)
      },
      onComplete: complete,
    })
  }

  let currentSec = TimelineService.convertMillisecondsToSeconds(t_slider.curSec)

  TweenLite.set(tweenWrapper, { perspective: 'none' })

  // kill({ previewTimeLine: previewTimeLine, basicProps: basicProps })

  previewTimeLine.restart()
  previewTimeLine.addLabel('Start', 0)

  if (texts.length) {
    texts.map((text, textIndex) => {
      const textHtmlElement = _.get(
        htmlElements,
        `${TEXT.code}.${textIndex}`,
        null,
      )

      if (!textHtmlElement) {
        return text
      }

      let startingTime = TimelineService.convertMillisecondsToSeconds(
        text.start_time,
      )
      let endingTime = TimelineService.convertMillisecondsToSeconds(
        text.end_time,
      )

      if (startingTime < 0.5) {
        startingTime = 0.05
      }

      if (endingTime >= projectDurationInSeconds) {
        endingTime = projectDurationInSeconds
      }

      previewTimeLine.set(
        textHtmlElement,
        { autoAlpha: 0, x: 0, y: 0, clearProps: basicProps },
        0,
      )

      let tSTime = text.fadeTime
      if (text.aniValue === 'pulse') {
        tSTime = text.pulseTime
      } else if (text.aniValue === 'slide-right') {
        tSTime = text.slideRTime
      } else if (text.aniValue === 'slide-left') {
        tSTime = text.slideLTime
      }

      // If animation is greater than text clip duration, use clip duration
      if (tSTime + 100 > text.clip.duration) {
        tSTime = text.clip.duration - 100
      }

      let t_transIn = getTextAniIn(text.aniValue, text),
        t_t_AniIn = t_transIn.animateIn,
        t_tAniInEnd = t_transIn.animateInEnding

      previewTimeLine.set(textHtmlElement, t_t_AniIn, 'Start+=' + startingTime)
      previewTimeLine.to(
        textHtmlElement,
        Math.round((tSTime / 1000) * 100) / 100,
        t_tAniInEnd,
        'Start+=' + startingTime,
      )

      const aniEndingTime = endingTime - Math.round((tSTime / 1000) * 100) / 100
      const t_transOut = getTextAniOut(text.aniValue, text)
      const t_t_transAniOut = t_transOut.animateOut

      previewTimeLine.to(
        textHtmlElement,
        Math.round((tSTime / 1000) * 100) / 100,
        t_t_transAniOut,
        'Start+=' + aniEndingTime,
      )

      previewTimeLine.set(
        textHtmlElement,
        { autoAlpha: 0, x: 0, y: 0, clearProps: basicProps },
        'Start+=' + endingTime,
      )

      return text
    })
  }

  if (slides.length) {
    slides.map((slide, sceneIndex) => {
      const target = _.get(htmlElements, `${VIDEO.code}.${sceneIndex}`, null)
      if (!target) {
        return slide
      }

      const { endAni, end_time, start_time, startAni, transition = [] } = slide

      const isFadingFromBlack = transition.includes(TRANSITIONS.FadeFromBlack)
      const isFadingToBlack = transition.includes(TRANSITIONS.FadeToBlack)

      let startingTime =
        TimelineService.convertMillisecondsToSeconds(start_time)
      let endingTime = TimelineService.convertMillisecondsToSeconds(end_time)
      let startAnimationDuration = isFadingFromBlack
        ? TimelineService.convertMillisecondsToSeconds(startAni.time)
        : 0.001
      let endAnimationDuration = isFadingToBlack
        ? TimelineService.convertMillisecondsToSeconds(endAni.time)
        : 0.001 // Set animation duration as small as possible, to prevent black screen when transitioning to a new scene
      let endAnimationStartTime = endingTime - endAnimationDuration

      // previewTimeLine.addLabel(`Slide_Cut_${i}`, `Start+=${startingTime}`)

      const hiddenCss = { opacity: 0 }
      const visibleCss = { opacity: 1 }

      previewTimeLine.fromTo(
        target,
        hiddenCss,
        {
          ...visibleCss,
          duration: startAnimationDuration,
          immediateRender: true,
        },
        startingTime,
      )
      previewTimeLine.fromTo(
        target,
        { ...visibleCss, immediateRender: false },
        {
          ...hiddenCss,
          duration: endAnimationDuration,
          immediateRender: false,
        },
        endAnimationStartTime,
      )

      // if (i > 0) {
      //   const old_slide_effect = slides[i - 1].startAni.value
      //   const old_slide_effect_time =
      //     Math.round((slides[i - 1].startAni.time / 1000) * 100) / 100
      //   if (old_slide_effect === '') {
      //     previewTimeLine.set(
      //       target,
      //       { autoAlpha: 1, x: 0, y: 0, opacity: 1 },
      //       `Start+=${startingTime - old_slide_effect_time}`,
      //     )
      //   } else {
      //     let s_transIn = getTransitionIn(old_slide_effect),
      //       s_t_AniIn = s_transIn.animateIn,
      //       s_tAniInEnd = s_transIn.animateInEnding,
      //       aniInDuration = Math.round((slide.startAni.time / 1000) * 100) / 100

      //     previewTimeLine.set(target, s_t_AniIn, 'Start+=' + startingTime)
      //     previewTimeLine.to(
      //       target,
      //       aniInDuration,
      //       s_tAniInEnd,
      //       'Start+=' + startingTime,
      //     )
      //   }
      // } else {
      //   previewTimeLine.set(
      //     target,
      //     { autoAlpha: 1, x: 0, y: 0, opacity: 1 },
      //     `Start+=${startingTime}`,
      //   )
      // }

      // if (slide.endAni.value === '') {
      //   previewTimeLine.set(
      //     target,
      //     { autoAlpha: 0, x: 0, y: 0, opacity: 0 },
      //     'Start+=' + endingTime,
      //   )
      // } else {
      //   // TweenLite.set(tweenWrapper, { perspective: 700 });
      //   let aniOutDuration = Math.round((slide.endAni.time / 1000) * 100) / 100,
      //     aniEndingTime = endingTime - aniOutDuration
      //   const s_transOut = getTransitionOut(slide.endAni.value)
      //   const s_t_transAniOut = s_transOut.animateOut

      //   previewTimeLine.to(
      //     target,
      //     aniOutDuration,
      //     s_t_transAniOut,
      //     'Start+=' + aniEndingTime,
      //   )

      //   previewTimeLine.set(
      //     target,
      //     { autoAlpha: 0, x: 0, y: 0 },
      //     'Start+=' + endingTime,
      //   )
      // }

      return slide
    })
  }

  const audioItems = _.get(project, AUDIO.projectPath, [])
  if (!_.isEmpty(audioItems)) {
    previewTimeLine = addVolumeAnimationToChannel(
      previewTimeLine,
      audioItems,
      htmlElements[AUDIO.code],
    )
  }

  const voiceoverItems = _.get(project, VOICEOVER.projectPath, [])
  if (!_.isEmpty(voiceoverItems)) {
    previewTimeLine = addVolumeAnimationToChannel(
      previewTimeLine,
      voiceoverItems,
      htmlElements[VOICEOVER.code],
    )
  }

  const musicItems = _.get(project, MUSIC.projectPath, [])
  if (!_.isEmpty(musicItems)) {
    previewTimeLine = addVolumeAnimationToChannel(
      previewTimeLine,
      musicItems,
      htmlElements[MUSIC.code],
    )
  }

  previewTimeLine = createEndInTimeline(
    previewTimeLine,
    projectDurationInSeconds,
  )

  if (play === true) {
    previewTimeLine.play()
  } else {
    previewTimeLine.pause()
  }

  if (currentSec <= 0) {
    currentSec = 0
  }

  previewTimeLine.seek(currentSec)

  return { previewTimeLine, t_slider }
}

function addVolumeAnimationToChannel(
  previewTimeLine,
  audioItems,
  audioHtmlElements,
) {
  _.forEach(audioItems, (audioItem, itemIndex) => {
    const volumeDataPoints = _.get(audioItem, 'volumeData', [])
    const currentHtmlElement = audioHtmlElements[itemIndex]
    _.forEach(volumeDataPoints, (point, pointIndex) => {
      const nextPoint = _.get(volumeDataPoints, pointIndex + 1, null)
      if (nextPoint) {
        const duration = TimelineService.convertMillisecondsToSeconds(
          nextPoint.time - point.time,
        )
        previewTimeLine = previewTimeLine.fromTo(
          currentHtmlElement,
          { immediateRender: true, attr: { 'data-volume': point.volume } },
          { duration, attr: { 'data-volume': nextPoint.volume } },
          TimelineService.convertMillisecondsToSeconds(
            point.time + audioItem.start_time,
          ),
        )
      }
    })

    if (currentHtmlElement) {
      currentHtmlElement.ontimeupdate = (event) => {
        const datasetVolume = event.target?.dataset?.volume
        currentHtmlElement.volume = !_.isUndefined(datasetVolume)
          ? datasetVolume
          : 0.5
      }
    }
  })
  return previewTimeLine
}

export function createEndInTimeline(previewTimeLine, projectDurationInSeconds) {
  previewTimeLine.addLabel('End', `Start+=${projectDurationInSeconds}`)
  previewTimeLine.to({}, projectDurationInSeconds, {}, 'Start')
  previewTimeLine.from({}, 0, {}, 'Start')
  previewTimeLine.duration(projectDurationInSeconds)
  return previewTimeLine
}
