import RENDER_CRITERIAS from './constants/RenderCriterias'
import _ from 'lodash'

class VideoFormatListGetter {
  getVideoFormats(selectedRenderCriterias) {
    return this.getVideoFormatsFromCriterias(selectedRenderCriterias)
  }

  getVideoFormatsFromCriterias(criterias) {
    const videoFormats = _.groupBy(criterias, (criteria) => {
      return `${criteria.ratio}_${criteria.resolution}_${criteria.fps}`
    })
    return videoFormats
  }

  getPossibleVideoOutputs(selectedRenderCriterias) {
    const videoFormats = this.getVideoFormats(selectedRenderCriterias)
    const videoOutputs = _.map(videoFormats, (formats, formatKey) => {
      const [ratio, resolution, fps] = _.split(formatKey, '_')
      const platforms = _.map(formats, (format) => format.platform)
      const dimensions = _.first(formats).dimensions
      return { ratio, fps, resolution, platforms, dimensions }
    })
    return videoOutputs
  }
}

export default new VideoFormatListGetter()
