import _ from 'lodash'
import axios from 'axios'
import { assetsUpload } from '../../redux/actions/RecorderActions'
import { isIOS, isMobile } from 'react-device-detect'
import RATIO from '../../constants/AspectRatios'
import S3BucketUploader from '../library-assets/S3BucketUploader'
import LibraryAssetUploader from '../library-assets/LibraryAssetUploader'

export async function uploadVideoBlob(
  authorId,
  videoBlob,
  videoFilename,
  extension,
  aspectSize,
) {
  const { presignedUrl, Key } = await LibraryAssetUploader.presignUrl(
    'medias',
    authorId,
    videoFilename,
    extension,
  )
  const response = await S3BucketUploader.manageUploadPresignedAssetToS3Bucket(
    videoBlob,
    Key,
    presignedUrl,
  )
  return response
}

export async function saveVideoAsAsset(
  authUser,
  videoFilename,
  videoFileLocation,
  videoFileKey,
  duration,
  extension,
  resolution,
  saveLayout,
  aspectSize,
  streamSize,
  originVideoSize,
  clientRectSize,
  cameraDimension,
  zoomAbleState,
  shootValue,
  defaultLayout,
  isTrackingVideo,
  isAudioEnabled,
) {
  const parameters = {
    userId: authUser.id,
    fileName: videoFilename,
    fileUrl: videoFileLocation,
    fileKey: videoFileKey,
    type: 'video',
    isMobile,
    isIOS,
    duration,
    extension,
    isCropped: [false, false, false, false],
    croppingStarted: false,
    resolution,
    selectLayout: saveLayout,
    aspectSize,
    streamSize,
    originVideoSize,
    clientRectSize,
    cameraDimension,
    zoomAbleState,
    shootValue: shootValue,
    mediaLayout: defaultLayout,
    isTrackingVideo: isTrackingVideo,
    isAudioEnabled: isAudioEnabled,
  }
  return await assetsUpload(parameters)
}

export function isAllSaveLayoutEnabled(saveLayout) {
  return _.every(RATIO, (resolution) => saveLayout[resolution])
}

export function isSomeLayoutEnabled(layout) {
  return _.some(RATIO, (resolution) => layout[resolution])
}

export function getVideoBlob(recordedBlobs, mimeType) {
  if (recordedBlobs instanceof Blob) {
    return recordedBlobs
  }

  if (_.isArray(recordedBlobs)) {
    return _.first(recordedBlobs)
  }

  return new window.Blob(recordedBlobs, {
    type: mimeType,
  })
}

// TODO: Remove after python changes made
export async function getVideoBlobByUrl(videoUrl) {
  const blob = await fetch(videoUrl, { cache: 'no-store' }).then((response) =>
    response.blob(),
  )
  return blob
}

export async function getVideoBlobsByUrl(videoUrls, videoUrl) {
  const promises = []
  const blobs = {}
  Object.entries(videoUrls).forEach(([aspectSize, url]) => {
    promises.push(
      fetch(url, { cache: 'no-store' })
        .then((response) => response.blob())
        .then((blob) => (blobs[aspectSize] = blob)),
    )
  })

  await Promise.all(promises).then(() => console.log('blobs', blobs))
  return blobs
}

export async function uploadFaceTrackingVideo(
  filename,
  // recordedBlobs,
  fileUrl,
  // mimeType,
  scale,
  direction,
  aspectSize,
  aspectRatio,
  streamSize,
  aspectSizes = [],
  videoCropTime = null,
  userId = null,
) {
  /**
   * Get a path to a file in a S3 bucket from the file URL
   */
  const filePath = getPathFromUrl(fileUrl)

  const bodyParams = {
    zoomLevel: scale,
    alignment: direction,
    aspectSize: JSON.stringify(aspectSize),
    aspectSizes: JSON.stringify(aspectSizes),
    videoCropTime: videoCropTime,
    streamSize: JSON.stringify(streamSize),
    aspectRatio: aspectRatio,
    fileUrl: filePath,
    filename: filename,
    userId: userId,
  }
  const currentHost = `${window.location.protocol}//${window.location.host}`
  const response = await axios.post(
    `${process.env.REACT_APP_FACETRACK_URL}/upload`,
    JSON.stringify(bodyParams),
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  )

  // Ask for tracker
  // The tracker returns urls for the generated files uploaded to AWS S3
  // For each aspect ratio it returns JPG, then MP4 files
  // Audio files goes last

  var urls = {}
  let i = 1
  aspectSizes.forEach((size, index) => {
    if (size.supported) {
      urls[index] = response.data[i]
      i += 2
    }
  })
  const audioUrl = response.data[i - 1]

  /**
   * return correction for audio
   *
   * return {
   *   urls,
   *   audioUrl: response?.data?.data?.audio,
   * }
   */
  return {
    urls,
    audioUrl,
  }
}

function getPathFromUrl(fileUrl) {
  var url = require('url')
  var filepath = url.parse(fileUrl)
  return filepath['path'].substring(1)
}
