import React from 'react'
import _ from 'lodash'
import Loader from 'react-loader-spinner'
import { toast } from 'react-toastify'
import {
  Modal,
  ModalContent,
  ModalOverlay,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
} from '@chakra-ui/modal'
import { Button, Card } from '@material-tailwind/react'
// import Button from '@material-tailwind/react/components/Button'
import { connect } from 'react-redux'

// Components
import * as MainContainerActions from '../../redux/actions/MainContainerActions'
import * as VoiceoverActions from '../../redux/actions/VoiceoverActions'
import * as EditorActions from '../../redux/actions/EditorActions'
import LibraryAssetUploader from '../../services/library-assets/LibraryAssetUploader'
import VoiceRecordButton from './VoiceRecordButton'
import { VoiceRecorderTimeline } from './VoiceRecorderTimeline'
import MovieProjectPlayer from '../projects/MovieProjectPlayer'
import FileUtils from '../../utils/FileUtils'
import { ASSET_LIBRARY } from '../../constants/AssetLibraries'
import * as ProjectEditService from '../../services/ProjectEditService'
import { VOICEOVER } from '../../constants/Channels'
import UploadServices from '../../services/UploadServices'
import PlayButton from './PlayButton'
import DurationGetter from '../../services/time/DurationGetter'
import ProjectItemUtils from '../../utils/ProjectItemUtils'
import UndoButton from './UndoButton'
import {
  TICK_IN_MILLISECONDS,
  TimeTickerContext,
} from '../projects/TimeTickerContext'
import TimelineService from '../../services/time/TimelineService'
import { FLOAT_RATE, SLIDER_PRO } from '../../constants/TimelineItems'

const TEMPORARY_VOICEOVER_PREFIX = 'TEMP_VO'

class VoiceRecorder extends React.Component {
  state = {
    project: null,
    saving: false,
    isPlaying: false,
    isRecording: false,
    currentTime: 0,
    currentRecordId: null,
    currentRecordItem: null,
  }

  voiceRecorder = React.createRef()

  static contextType = TimeTickerContext

  static getDerivedStateFromProps(props, prevState) {
    return { project: prevState?.project || props.project }
  }

  componentDidMount() {
    this.context.attachTimeTickListener(this.onCurrentTimeTick.bind(this))
  }

  async componentDidUpdate(prevProps) {
    const isModalEnabled =
      !prevProps.isVoiceoverModalDisplayed &&
      this.props.isVoiceoverModalDisplayed
    if (isModalEnabled) {
      const voiceoverDuration = DurationGetter.getMaxDurationByAssets(
        this.props.project.voiceover,
      )
      this.setState({
        currentTime: voiceoverDuration,
        project: this.props.project,
      })
    }
  }

  onCurrentTimeTick = (currentTime) => {
    const { project, currentRecordId, isRecording, currentRecordItem } =
      this.state
    const { isPlaying } = this.context
    const nextTick = currentTime + TICK_IN_MILLISECONDS
    const isAroundUnrecordableArea = ProjectItemUtils.isAroundUnrecordableArea(
      nextTick,
      project,
      currentRecordId,
      VOICEOVER.projectPath,
    )
    const isRecordingExceedingLimit = isRecording && isAroundUnrecordableArea

    const isBeyondProjectDurationWhilePlaying =
      !isRecording && isPlaying && currentTime > project.duration

    if (isRecording && currentRecordItem) {
      this.updateItemWithEndTime(currentRecordItem, currentTime)
      this.setState({ currentRecordItem })
    }

    try {
      if (isBeyondProjectDurationWhilePlaying) {
        return false
      }
    } finally {
      if (isBeyondProjectDurationWhilePlaying) {
        this.context.changeCurrentTime(0)
      }
    }

    try {
      return !isRecordingExceedingLimit
    } finally {
      if (isRecordingExceedingLimit) {
        this.voiceRecorder.current.handleEndRecord()
      }
    }
  }

  updateItemWithEndTime = (item, newEndTime) => {
    const newDuration = newEndTime - item.start_time
    item.duration = newDuration
    item.clip.duration = newDuration
    item.clip.end = newDuration
    return item
  }

  handleShowModal = (value) => {
    if (!value) {
      this.context.stopPlaying()
      this.setState({ project: null })
    }
    this.props.toggleVoiceoverModal(value)
  }

  handleStartRecording = (url) => {
    const { currentTime } = this.context
    const project = _.cloneDeep(this.state.project)
    const newId = FileUtils.createFilenameByTimestamp(
      TEMPORARY_VOICEOVER_PREFIX,
    )
    const currentRecordItem = {
      start_time: currentTime,
      end_time: currentTime,
      url: null,
      $id: newId,
      clip: { start: 0, end: 0 },
    }
    this.setState(
      { project, currentRecordId: newId, currentRecordItem, isRecording: true },
      () => {
        this.context.startPlaying()
      },
    )
  }

  handleRevertLastItem = () => {
    let project = _.cloneDeep(this.state.project)
    const sortedItems = _.sortBy(
      project[VOICEOVER.projectPath],
      (item) => item.$id,
    )
    const itemToRevert = _.findLast(sortedItems, (item) =>
      _.startsWith(item.$id, TEMPORARY_VOICEOVER_PREFIX),
    )

    if (itemToRevert) {
      const itemsRemaining = _.reject(
        project[VOICEOVER.projectPath],
        (item) => item.$id === itemToRevert.$id,
      )
      project[VOICEOVER.projectPath] = itemsRemaining
    }

    this.context.changeCurrentTime(itemToRevert?.start_time || 0)
    project.duration = DurationGetter.getDurationFromProject(project)
    this.setState({ project })
  }

  getCurrentTimeScene = (resScenes, currentTime) => {
    let currentTimeScene =
      resScenes.find((scene) => {
        if (scene.start_time === currentTime) {
          return scene
        }
      }) || resScenes[0]
    if (!currentTimeScene) {
      currentTimeScene =
        resScenes.find((scene) => {
          if (currentTime >= scene.start_time && currentTime < scene.end_time) {
            return scene
          }
        }) || resScenes[0]
    }
    return currentTimeScene
  }

  handleForwardScene = () => {
    const { currentTime, isPlaying } = this.context
    let project = _.cloneDeep(this.state.project)
    const resScenes = project.scenes[project.resolution].sort(
      (a, b) => a.start_time - b.start_time,
    )
    const currentTimeScene = this.getCurrentTimeScene(resScenes, currentTime)
    if (currentTimeScene) {
      // Check if there's a scene after this
      const findNextScene = resScenes
        .filter((scene) => scene.end_time > currentTimeScene.end_time)
        .sort((a, b) => a.start_time - b.start_time)[0]
      if (findNextScene) {
        return this.context.changeCurrentTime(findNextScene.start_time)
      }
      return this.context.changeCurrentTime(currentTimeScene.start_time)
    }
  }

  handleRewindScene = () => {
    const { currentTime, isPlaying } = this.context
    let project = _.cloneDeep(this.state.project)
    const resScenes = project.scenes[project.resolution].sort(
      (a, b) => a.start_time - b.start_time,
    )

    const currentTimeScene = this.getCurrentTimeScene(resScenes, currentTime)
    if (currentTimeScene) {
      // Check if there's a scene after this
      const findPrevScene = resScenes
        .filter((scene) => scene.start_time < currentTimeScene.start_time)
        .sort((a, b) => b.start_time - a.start_time)[0]
      if (findPrevScene) {
        return this.context.changeCurrentTime(findPrevScene.start_time)
      }
      return this.context.changeCurrentTime(currentTimeScene.start_time)
    }
  }

  handleStopRecording = async (blobUrl, blob) => {
    const { currentRecordItem } = this.state
    const endTime = this.context.stopPlaying()
    this.setState({ isRecording: false })
    let clonedProject = _.cloneDeep(this.state.project)

    if (currentRecordItem) {
      const blobDuration = endTime - currentRecordItem.start_time
      currentRecordItem.end_time = endTime
      currentRecordItem.clip.duration = blobDuration
      currentRecordItem.clip.end = blobDuration
      currentRecordItem.duration = blobDuration
      currentRecordItem.url = blobUrl
      currentRecordItem.blob = blob
      clonedProject[VOICEOVER.projectPath] = [
        ...clonedProject[VOICEOVER.projectPath],
        currentRecordItem,
      ]
      clonedProject.duration =
        DurationGetter.getDurationFromProject(clonedProject)
      this.context.changeCurrentTime(endTime)
      this.setState({
        project: clonedProject,
        currentRecordId: null,
        currentRecordItem: null,
      })
    }
  }

  handleStartPlaying = () => {
    this.context.startPlaying()
  }

  handleStopPlaying = () => {
    this.context.stopPlaying()
  }

  handleSaveRecordedAudio = async () => {
    try {
      this.setState({ saving: true })
      const {
        authUser,
        project,
        storeProjectSteps,
        getLibFiles,
        updateTimeSlider,
      } = this.props
      const { project: temporaryCopiedProject } = this.state
      let updatingProject = _.cloneDeep(project)
      const savingVoiceoverItems = _.filter(
        temporaryCopiedProject.voiceover,
        (item) => _.startsWith(item.$id, TEMPORARY_VOICEOVER_PREFIX),
      )

      for (let item of savingVoiceoverItems) {
        updatingProject = await this.saveVoiceoverItem(
          authUser,
          updatingProject,
          item,
        )
      }
      storeProjectSteps({ channel: ['voiceover'], type: 'ADD' })
      getLibFiles && getLibFiles()
      this.props.updateProject(updatingProject)
      // Move timeline to the start time of last added item
      if (savingVoiceoverItems.length) {
        const sliderPosition =
          TimelineService.convertMillisecondsToSliderPosition(
            savingVoiceoverItems[savingVoiceoverItems.length - 1].start_time,
            updatingProject,
            SLIDER_PRO,
            FLOAT_RATE,
          )
        updateTimeSlider(sliderPosition)
      }
      toast.success('Voiceover successfully added to project')
      this.handleShowModal(false)
    } catch (error) {
      toast.error(`Error saving voiceover: ${error.message}`)
    } finally {
      toast.dismiss()
      this.setState({ saving: false })
    }
  }

  async saveVoiceoverItem(authUser, project, item) {
    const mergedBlob = await UploadServices.createMergedMp3Recording([
      item.blob,
    ])
    const voiceoverFilename = `${FileUtils.createFilenameByTimestamp('VO')}.mp3`
    const libraryAsset = await LibraryAssetUploader.saveAsLibraryAsset(
      mergedBlob,
      voiceoverFilename,
      ASSET_LIBRARY.VOICEOVER.folderPath,
      authUser.id,
    )
    const updatedProject = ProjectEditService.addItemToProject(
      project,
      libraryAsset,
      VOICEOVER.code,
      item.start_time,
    )
    return updatedProject
  }

  clearTimer() {
    clearInterval(this.timer)
    this.timer = null
  }

  isRecordedAudioEmpty() {
    return (
      _.filter(this.state.project.voiceover, (item) =>
        _.startsWith(item.$id, TEMPORARY_VOICEOVER_PREFIX),
      ).length <= 0
    )
  }

  render() {
    const { isVoiceoverModalDisplayed } = this.props
    const { currentRecordId, saving, isRecording, project, currentRecordItem } =
      this.state
    const { currentTime, isPlaying } = this.context
    const isSaveButtonDisabled = saving || this.isRecordedAudioEmpty()
    const isAroundUnrecordableArea = ProjectItemUtils.isAroundUnrecordableArea(
      currentTime,
      project,
      currentRecordId,
      VOICEOVER.projectPath,
    )
    return (
      <>
        <Modal
          size="xl"
          isOpen={isVoiceoverModalDisplayed}
          isCenter
          onClose={() => this.handleShowModal(false)}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader style={{ textAlign: 'center', fontWeight: 600 }}>
              Record Audio
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <div className="flex flex-col gap-5">
                <MovieProjectPlayer
                  project={project}
                  isPlaying={isPlaying}
                  isRecording={isRecording}
                  aspectRatio={this.props.currentLayout}
                  isBuffering={this.context.isBuffering}
                  onBufferingStarted={() => this.context.setBuffering(true)}
                  onBufferingFinished={() => this.context.setBuffering(false)}
                  currentTime={currentTime}
                  disabled={
                    (isPlaying && !isRecording) ||
                    (!isPlaying && !isRecording && isAroundUnrecordableArea)
                  }
                  onStartPlaying={this.handleStartPlaying}
                  onStopPlaying={this.handleStopPlaying}
                  onStartRecording={this.handleStartRecording}
                  onStopRecording={this.handleStopRecording}
                  handleRevertLastItem={this.handleRevertLastItem.bind(this)}
                  handleSaveRecordedAudio={this.handleSaveRecordedAudio.bind(
                    this,
                  )}
                  isSaveButtonDisabled={isSaveButtonDisabled}
                  recordButton={this.voiceRecorder}
                  duration={project.duration}
                  saving={saving}
                  handleForwardScene={this.handleForwardScene.bind(this)}
                  handleRewindScene={this.handleRewindScene.bind(this)}
                  currentRecordId={this.state.currentRecordId}
                  currentRecordItem={currentRecordItem}
                  onChangeCurrentTime={(newCurrentTime) =>
                    this.context.changeCurrentTime(newCurrentTime)
                  }
                />
              </div>
            </ModalBody>
            <ModalFooter
              style={{ justifyContent: 'center', background: '#fff' }}>
              {/* <Card
                className="flex flex-row gap-5 items-center justify-center"
                style={{ width: '97%', padding: 15 }}> */}
              {/* <VoiceRecordButton
                  recordButton={this.voiceRecorder}
                  disabled={
                    (isPlaying && !isRecording) ||
                    (!isPlaying && !isRecording && isAroundUnrecordableArea)
                  }
                  duration={project.duration}
                  onStartRecording={this.handleStartRecording}
                  onStopRecording={this.handleStopRecording}
                /> */}
              {/* <PlayButton
                  disabled={isRecording}
                  isPlaying={isPlaying}
                  onStartPlaying={this.handleStartPlaying}
                  onStopPlaying={this.handleStopPlaying}
                /> */}
              {/* <VoiceRecorderTimeline
                project={project}
                isPlaying={isPlaying}
                isRecording={isRecording}
                currentRecordId={this.state.currentRecordId}
                currentRecordItem={currentRecordItem}
                currentTime={currentTime}
                tickInMilliseconds={TICK_IN_MILLISECONDS}
                onChangeCurrentTime={(newCurrentTime) =>
                  this.context.changeCurrentTime(newCurrentTime)
                }
              /> */}
              {/* <UndoButton onClick={() => this.handleRevertLastItem()} /> */}
              {/* <Button
                  onClick={() => this.handleSaveRecordedAudio()}
                  color={isSaveButtonDisabled ? 'gray' : 'blue'}
                  ripple={!isSaveButtonDisabled}
                  disabled={isSaveButtonDisabled}>
                  {saving && (
                    <Loader type="Puff" color="white" height={20} width={20} />
                  )}
                  Keep
                </Button> */}
              {/* </Card> */}
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  authUser: state.auth.user,
  currentLayout: state.editor.project?.resolution,
  isVoiceoverModalDisplayed: state.mainContainer.isVoiceoverModalDisplayed,
  project: state.editor.project,
  recordedAudio: state.voiceover.recordedAudio,
})

const mapDispatchToProps = (dispatch) => ({
  toggleVoiceoverModal: (value) =>
    dispatch(MainContainerActions.toggleVoiceoverModal(value)),
  setRecordedAudio: (audio) =>
    dispatch(VoiceoverActions.setRecordedAudio(audio)),
  updateProject: (...any) => dispatch(EditorActions.updateProject(...any)),
  storeProjectSteps: (...any) =>
    dispatch(EditorActions.storeProjectSteps(...any)),
})

export default connect(mapStateToProps, mapDispatchToProps)(VoiceRecorder)
