import React, { Component } from 'react'
import { connect } from 'react-redux'
import { isInteger } from 'lodash'
import RecordActions from '../../components/video-capture/desktop-items/RecordActions'
import {
  setAppearedLowerNotify,
  setCameraDimension,
  setMediaOriginSize,
  setMediaResolution,
  setZoomAbleState,
  setRecording,
} from '../../redux/actions/RecorderActions'
import LowerNotifyModal from '../../components/modals/LowerNotifyModal'
import CameraWrapper from '../../components/video-capture/desktop-items/CameraWrapper'
import * as VideoCropActions from '../../redux/actions/VideoCropActions'
import * as SnapGridBoxActions from '../../redux/actions/SnapGridBoxActions'
import * as FaceTrackActions from '../../redux/actions/FaceTrackActions'
import * as RecorderActions from '../../redux/actions/RecorderActions'
import {
  closeNotifyModal,
  getMimeType,
  handleDataAvailable,
  handleDataIssue,
  handleError,
  handleStartRecording,
  handleStop,
  handleStopRecording,
  handlePause,
  handlePauseRecording,
  handleResume,
  handleResumeRecording,
  updateRecordingDuration,
  handleSuccess,
  isDataHealthOK,
  loadedCameraVideo,
  loadedReplayVideo,
  recordComplete,
  setScaleValue,
  startRecording,
  turnOffCamera,
  turnOnCamera,
  uploadRecordedVideo,
} from '../../methods/recorder/DesktopRecorderMethods'
import CropperAspectRatioSelection from '../../components/video-cropper/CropperAspectRatioSelection'
import CropperTooltip from '../../components/cropper-tooltip/CropperTooltip'
import CropperTopButtons from '../../components/video-cropper/CropperTopButtons'
import { RATIOS } from '../../constants/AspectRatios'

/**
 * Recorder Component
 */
class DesktopRecorder extends Component {
  cropperActionWrapper = React.createRef()
  constructor(props) {
    super(props)
    this.moveRef = React.createRef()

    this.state = {
      isReplayingVideo: false,
      isReplayVideoMuted: false,
      isCameraOn: false,
      isConnecting: false,
      error: null,
      isRunningCountdown: false,
      videoUrl: '',
      thereWasAnError: false,
      isLoadedReplayVideo: false,
      showLowerNotify: false,
      zoomAble: false,
      scaleVal: 'medium',

      layoutChangedFromDropDown: false,
      selectedRatios: [false, false, false, false],
      selectedPlatforms: [],
      selectedPlatform: 0,
      selectedRatio: -1,
      cropperInstructionStep: 1,
      isCropperInstructionStepActive: !localStorage.getItem(
        'tooltip_instruction',
      ),
      selNextPopup: false,
      ratioToEdit: null,
      pauseRecorder: false,
    }

    this.MIME_TYPES = [
      'video/webm;codecs="vp8,opus"',
      'video/webm;codecs=h264',
      'video/webm;codecs=vp9',
      'video/x-matroska;codecs=avc1',
      'video/webm',
    ]

    this.setScaleValue = setScaleValue.bind(this)
    this.turnOnCamera = turnOnCamera.bind(this)
    this.turnOffCamera = turnOffCamera.bind(this)
    this.handleSuccess = handleSuccess.bind(this)
    this.loadedCameraVideo = loadedCameraVideo.bind(this)
    this.loadedReplayVideo = loadedReplayVideo.bind(this)
    this.handleError = handleError.bind(this)
    this.getMimeType = getMimeType.bind(this)
    this.handleStartRecording = handleStartRecording.bind(this)
    this.startRecording = startRecording.bind(this)
    this.handleStopRecording = handleStopRecording.bind(this)
    this.handleStop = handleStop.bind(this)
    this.handlePauseRecording = handlePauseRecording.bind(this)
    this.handlePause = handlePause.bind(this)
    this.handleResumeRecording = handleResumeRecording.bind(this)
    this.handleResume = handleResume.bind(this)
    this.handlePauseRecorder = this.handlePauseRecorder.bind(this)
    this.updateRecordingDuration = updateRecordingDuration.bind(this)
    this.recordComplete = recordComplete.bind(this)
    this.handleDataIssue = handleDataIssue.bind(this)
    this.isDataHealthOK = isDataHealthOK.bind(this)
    this.handleDataAvailable = handleDataAvailable.bind(this)
    this.uploadRecordedVideo = uploadRecordedVideo.bind(this)
    this.closeNotifyModal = closeNotifyModal.bind(this)

    this.selectRatio = this.selectRatio.bind(this)
    this.changeLayoutFromDropdown = this.changeLayoutFromDropdown.bind(this)
    this.resetSelectedAspectRatios = this.resetSelectedAspectRatios.bind(this)
    this.updateSelectedPlatform = this.updateSelectedPlatform.bind(this)
    this.updateSelectedRatio = this.updateSelectedRatio.bind(this)

    this.changeCropperInstructionStep =
      this.changeCropperInstructionStep.bind(this)
    this.closeCropperInstructionStep =
      this.closeCropperInstructionStep.bind(this)
    this.retriggerCropperTooltip = this.retriggerCropperTooltip.bind(this)
    this.uploadVideoRecorded = this.uploadVideoRecorded.bind(this)
    this.wantToEditRatio = this.wantToEditRatio.bind(this)
    this.clearEditRatio = this.clearEditRatio.bind(this)
  }

  handlePauseRecorder = (value) => {
    this.setState({ pauseRecorder: value })
  }

  clearEditRatio = () => {
    this.setState({ ratioToEdit: null })
  }

  wantToEditRatio = (ratio) => {
    if (isInteger(ratio) && ratio >= 0 && ratio < RATIOS.length) {
      const platform = this.state.selectedPlatforms.find(
        (x) => x.ratio === ratio,
      ).platform
      this.updateSelectedPlatform(platform)
      this.setState({ ratioToEdit: ratio })
    }
  }

  removeRatio(ratio) {
    if (isInteger(ratio) && ratio >= 0 && ratio < RATIOS.length) {
      let selectedPlatforms = [...this.state.selectedPlatforms].filter(
        (p) => p.ratio !== ratio,
      )
      this.setState({
        selectedPlatforms,
      })
    }
  }

  selectRatio = (ratio) => {
    if (isInteger(ratio) && ratio >= 0 && ratio < RATIOS.length) {
      const ratios = this.state.selectedRatios
      ratios[ratio] = true
      let selectedPlatforms = [...this.state.selectedPlatforms].filter(
        (p) => p.ratio !== ratio,
      )
      selectedPlatforms.push({ platform: this.state.selectedPlatform, ratio })
      this.setState({
        selectedRatios: ratios,
        selNextPopup: true,
        selectedPlatforms,
      })

      setTimeout(() => {
        this.setState({ selNextPopup: false })
      }, 2000)
    }
  }

  changeLayoutFromDropdown = (value) => {
    this.setState({
      layoutChangedFromDropDown: value,
    })
  }

  resetCropperSelectionsOnUpload(s) {
    this.resetSelectedAspectRatios()
    this.changeLayoutFromDropdown(true)
    this.props.setCurrentLayout(s)
    this.props.setFaceTrackingRatio(s)
    this.props.setSelectedMediaLayout(true, s)
  }

  resetSelectedAspectRatios() {
    this.setState({
      selectedPlatform: 0,
      selectedRatio: -1,
    })
  }

  updateSelectedPlatform(platform) {
    this.setState({
      selectedPlatform: platform,
    })
  }

  updateSelectedRatio(ratio) {
    this.setState({
      selectedRatio: ratio,
    })
  }

  changeCropperInstructionStep() {
    if (this.state.cropperInstructionStep < 4) {
      const currentStep = this.state.cropperInstructionStep
      this.setState({
        cropperInstructionStep: currentStep + 1,
      })
    } else {
      this.setState({
        isCropperInstructionStepActive: false,
      })
    }
  }

  closeCropperInstructionStep() {
    this.setState({
      isCropperInstructionStepActive: false,
    })
    localStorage.setItem('tooltip_instruction', 'true')
  }

  retriggerCropperTooltip() {
    this.setState({
      cropperInstructionStep: 1,
      isCropperInstructionStepActive: true,
    })
  }

  uploadVideoRecorded() {
    this.resetCropperSelectionsOnUpload(0)
    this.uploadRecordedVideo()
  }

  componentDidMount() {
    this.setState({
      scaleVal: this.props.shoot_value[this.props.media_layout],
      pauseRecorder: false,
    })

    if (this.props.isOnInitially) {
      this.turnOnCamera()
    }
    this.resetCropperSelectionsOnUpload(0)
  }

  componentWillUnmount() {
    if (this.state.isCameraOn) {
      this.turnOffCamera()
    }
  }

  render() {
    const {
      isCameraOn,
      isConnecting,
      isRunningCountdown,
      isReplayingVideo,
      showLowerNotify,
    } = this.state
    const {
      isRecording,
      isUploadingVideo,
      isVideoFaceTracking,
      isFaceTrackingEnabled,
      faceTrackingVideo,
    } = this.props
    const isFaceTrackingPreviewEnabled =
      isFaceTrackingEnabled && faceTrackingVideo

    return (
      <>
        <div className="recorder-wrapper relative flex items-center justify-center flex-col overflow-hidden bg-black text-white box-border">
          {showLowerNotify && (
            <LowerNotifyModal closeNotifyModal={this.closeNotifyModal} />
          )}
          <CameraWrapper
            cameraVideo={(cameraVideo) => (this.cameraVideo = cameraVideo)}
            loadedCameraVideo={this.loadedCameraVideo}
            {...this.state}
            {...this.props}
          />
          <CropperTooltip
            changeCropperInstructionStep={this.changeCropperInstructionStep}
            cropperInstructionStep={this.state.cropperInstructionStep}
            isCropperInstructionStepActive={
              this.state.isCropperInstructionStepActive
            }
            closeCropperInstructionStep={this.closeCropperInstructionStep}
          />
          <RecordActions
            ratioToEdit={this.state.ratioToEdit}
            clearEditRatio={this.clearEditRatio}
            isConnecting={isConnecting}
            isCameraOn={isCameraOn}
            MediaStream={this.stream}
            cameraVideo={isCameraOn ? this.cameraVideo : null}
            isRecording={isRecording}
            isLoading={isUploadingVideo || isVideoFaceTracking}
            isRunningCountdown={isRunningCountdown}
            isReplayingVideo={isReplayingVideo}
            onTurnOnCamera={this.turnOnCamera}
            onStartRecording={this.handleStartRecording}
            onStopRecording={this.handleStopRecording}
            onPauseRecording={this.handlePauseRecording}
            onResumeRecording={this.handleResumeRecording}
            onUploadRecordVideo={this.uploadVideoRecorded}
            onUploadFacetrackedVideo={this.props.onUploadFacetrackedVideo}
            setScaleValue={this.setScaleValue}
            layoutChangedFromDropDown={this.state.layoutChangedFromDropDown}
            changeLayoutFromDropdown={this.changeLayoutFromDropdown}
            moveRef={this.moveRef}
            selNextPopup={this.state.selNextPopup}
            cropperActionWrapper={this.cropperActionWrapper}
            retriggerCropperTooltip={this.retriggerCropperTooltip}
            isFaceTrackingPreviewEnabled={isFaceTrackingPreviewEnabled}
            resetSelectedAspectRatios={this.resetSelectedAspectRatios}
            selectedPlatform={this.state.selectedPlatform}
            selectRatio={this.selectRatio}
            selectedRatio={this.state.selectedRatio}
            selectedRatios={this.state.selectedRatios}
            videoActionWrapper={this.cropperActionWrapper}
            pauseRecorder={this.state.pauseRecorder}
          />

          {isCameraOn && (
            <>
              <CropperAspectRatioSelection
                ratioToEdit={this.state.ratioToEdit}
                clearEditRatio={this.clearEditRatio}
                selectRatio={this.selectRatio}
                changeLayoutFromDropdown={this.changeLayoutFromDropdown}
                cameraVideo={this.cameraVideo}
                videoActionWrapper={this.cropperActionWrapper}
                moveRef={this.moveRef}
                resetSelectedAspectRatios={this.resetSelectedAspectRatios}
                updateSelectedPlatform={this.updateSelectedPlatform}
                updateSelectedRatio={this.updateSelectedRatio}
                selectedPlatform={this.state.selectedPlatform}
                selectedRatio={this.state.selectedRatio}
                selectedRatios={this.state.selectedRatios}
                hidePlayback={true}
                selectedPlatforms={this.state.selectedPlatforms}
              />
              <CropperTopButtons
                wantToEditRatio={this.wantToEditRatio}
                clearEditRatio={this.clearEditRatio}
                ratioToEdit={this.state.ratioToEdit}
                removeRatio={this.removeRatio.bind(this)}
                selectRatio={this.selectRatio}
                changeLayoutFromDropdown={this.changeLayoutFromDropdown}
                cameraVideo={this.cameraVideo}
                videoActionWrapper={this.cropperActionWrapper}
                moveRef={this.moveRef}
                resetSelectedAspectRatios={this.resetSelectedAspectRatios}
                updateSelectedRatio={this.updateSelectedRatio}
                selectedPlatform={this.state.selectedPlatform}
                selectedRatio={this.state.selectedRatio}
                selectedRatios={this.state.selectedRatios}
                customStyles={{ justifyContent: 'center' }}
                showOnlyRatios={true}
              />
            </>
          )}
        </div>
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  streamSize: state.recorder.streamSize,
  shoot_value: state.recorder.shoot_value,
  aspectSize: state.recorder.aspectSize,
  clientRectSize: state.recorder.clientRectSize,
  runningCountTimer: state.recorder.runningCountTimer,
  defaultLayout: state.projectEdit.defaultLayout,
  isRecording: state.recorder.isRecording,
  direction: state.recorder.direction,
  appearedLowerMediaNotify: state.recorder.appearedLowerMediaNotify,
  faceTrackingVideo: state.faceTrack.faceTrackingVideo,
  isFaceTrackingEnabled: state.faceTrack.isFaceTrackingEnabled,
  faceTrackingVideos: state.faceTrack.faceTrackingVideos,
  zoomAbleState: state.recorder.zoomAbleState,
  mySubscription: state.subscriptions.user,
  products: state.subscriptions.products,
})

const mapDispatchToProps = (dispatch) => ({
  setMediaResolution: (...args) => dispatch(setMediaResolution(...args)),
  setCameraDimension: (...args) =>
    dispatch(SnapGridBoxActions.setCameraDimension(...args)),
  setMediaOriginSize: (...args) =>
    dispatch(SnapGridBoxActions.setMediaOriginSize(...args)),
  setAppearedLowerNotify: (...args) =>
    dispatch(setAppearedLowerNotify(...args)),
  setZoomAbleState: (...args) => dispatch(setZoomAbleState(...args)),
  setRecording: (...args) => dispatch(setRecording(...args)),
  setScaleValue: (...args) => dispatch(VideoCropActions.setScaleValue(...args)),
  setCurrentLayout: (...args) =>
    dispatch(VideoCropActions.setCurrentLayout(...args)),
  setFaceTrackingRatio: (...args) =>
    dispatch(FaceTrackActions.setFaceTrackingRatio(...args)),
  setSelectedMediaLayout: (...args) =>
    dispatch(RecorderActions.setSelectedMediaLayout(...args)),
})

export default connect(mapStateToProps, mapDispatchToProps)(DesktopRecorder)
