export const YoutubeIcon = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 13.4167C0 8.67091 0 6.29803 0.938221 4.49188C1.72885 2.96985 2.96985 1.72885 4.49188 0.938221C6.29803 0 8.67091 0 13.4167 0H26.5833C31.3291 0 33.702 0 35.5081 0.938221C37.0301 1.72885 38.2711 2.96985 39.0618 4.49188C40 6.29803 40 8.67091 40 13.4167V26.5833C40 31.3291 40 33.702 39.0618 35.5081C38.2711 37.0301 37.0301 38.2711 35.5081 39.0618C33.702 40 31.3291 40 26.5833 40H13.4167C8.67091 40 6.29803 40 4.49188 39.0618C2.96985 38.2711 1.72885 37.0301 0.938221 35.5081C0 33.702 0 31.3291 0 26.5833V13.4167Z"
        fill="#FF0000"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M31.7379 9.80917C33.0229 10.1551 34.0361 11.1683 34.3821 12.4533C35.0246 14.8009 34.9999 19.6938 34.9999 19.6938C34.9999 19.6938 34.9999 24.562 34.3821 26.9096C34.0361 28.1946 33.0229 29.2078 31.7379 29.5537C29.3903 30.1715 19.9999 30.1715 19.9999 30.1715C19.9999 30.1715 10.6342 30.1715 8.26193 29.529C6.97693 29.1831 5.96375 28.1699 5.61779 26.8849C5 24.562 5 19.6691 5 19.6691C5 19.6691 5 14.8009 5.61779 12.4533C5.96375 11.1683 7.00164 10.1304 8.26193 9.78446C10.6095 9.16667 19.9999 9.16667 19.9999 9.16667C19.9999 9.16667 29.3903 9.16667 31.7379 9.80917ZM24.8187 19.4975L17.0098 23.995V15L24.8187 19.4975Z"
        fill="white"
      />
    </svg>
  )
}
