import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { registerUser, removeAuthErrors } from '../../redux/actions/AuthActions'
import { getAllSubscriptionProducts } from '../../redux/actions/SubscriptionActions'
import { checkCoupon } from '../../services/SubscriptionService'

import AuthFooter from './AuthFooter'
import { SignInLink } from './SignIn'
import { HOME, SIGN_UP } from '../../constants/Routes'
import NavigationHeader from '../navigation/NavigationHeader'
import { toast } from 'react-toastify'
import {
  getLocation,
  getRegion,
  triggerGoogleAnalyticsEvent,
} from '../../utils/Helper'
import { PaymentConstants } from '../../constants/Payments'

const ErrorPill = ({ message }) => {
  return <span style={{ color: '#d9534f', fontSize: 12 }}>{message}</span>
}

const SignUpPage = () => (
  <>
    <div className={'container mx-auto signInWraper'}>
      <NavigationHeader />
      <div className="w-full max-w-xs signInContent">
        <div className={'text-2xl mb-3 font-bold text-center'}>
          Register an Account
        </div>
        <SignUpForm />

        <SignInLink />
      </div>
    </div>
    <AuthFooter />
  </>
)

class SignUpFormBase extends Component {
  constructor(props) {
    super(props)

    this.state = {
      username: '',
      email: '',
      passwordOne: '',
      passwordTwo: '',
      coupon: this.props.auth.coupon,
      errors: {
        username: '',
        email: '',
        passwordOne: '',
        passwordTwo: '',
      },
      location: '',
      region: '',
    }
  }

  getLocation = async () => {
    const location = await getLocation()

    const region = await getRegion(location)
    this.setState({ location, region })
  }

  onChange = (event) => {
    let err = ''
    if (!event.target.value) {
      err = `${event.target.name} is required`
    } else if (event.target.name === 'passwordOne') {
      if (event.target.value.length < 6) {
        err = 'Your password should be at least six characters'
      } else {
        err = ''
      }
    } else {
      if (event.target.name === 'passwordTwo') {
        if (event.target.value !== this.state.passwordOne) {
          err = 'Your passwords should be the same'
        } else {
          err = ''
        }
      } else {
        err = ''
      }
    }
    this.setState({
      errors: {
        ...this.state.errors,
        [event.target.name]: err,
      },
    })
    this.setState({ [event.target.name]: event.target.value })
  }

  onChangeCheckbox = (event) => {
    this.setState({ [event.target.name]: event.target.checked })
  }

  async componentDidMount() {
    // If logged in and user navigates to Register page, should redirect them to dashboard
    if (this.props.auth.isAuthenticated) {
      this.props.history.push(HOME)
      return
    }
    const params = new URLSearchParams(this.props.location.search)
    let coupon = params.get('coupon')
    if (coupon) {
      const id = await checkCoupon(coupon)
      if (id) {
        this.setState({ coupon })
      }
    }
    this.getLocation()
    this.props.getAllSubscriptionProducts()
  }

  componentDidUpdate() {
    const registerError = this.props.auth.registerError
    if (registerError) {
      toast.dismiss()
      toast.error(registerError)

      this.props.removeAuthErrors()
    }
  }

  onSubmit = (e) => {
    e.preventDefault()
    triggerGoogleAnalyticsEvent('sign_up', { method: 'Form' })

    const { username, email, passwordOne, passwordTwo, coupon, region } =
      this.state

    const params = new URLSearchParams(this.props.location.search)
    let duration = params.get('duration')
    let planId = params.get('planId')
    let product
    if (planId) {
      product = this.props.subscriptions.products.find((x) => x._id === planId)
    }

    if (!product) {
      product = this.props.subscriptions.products.find((x) => {
        return (
          x.location === region &&
          x.type === PaymentConstants.subscriptionProduct.pro
        )
      })
      planId = product._id
    }
    if (!duration) duration = PaymentConstants.subscriptionDuration.annually

    const newUser = {
      name: username,
      email: email,
      password: passwordOne,
      password2: passwordTwo,
      coupon: coupon,
      duration,
      planId,
      price: product[PaymentConstants.priceKeys[duration]],
    }

    this.props.registerUser(newUser, this.props.history)
  }

  render() {
    const {
      username,
      email,
      passwordOne,
      passwordTwo,
      errors: {
        username: usernameErr,
        email: emailErr,
        passwordOne: passwordOneErr,
        passwordTwo: passwordTwoErr,
      },
    } = this.state

    const isInvalid =
      passwordOne !== passwordTwo ||
      passwordOne.length < 6 ||
      passwordOne === '' ||
      email === '' ||
      username === ''

    return (
      <form
        onSubmit={this.onSubmit}
        className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        <div className="mb-4">
          <input
            type="text"
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            value={username}
            name="username"
            onChange={this.onChange}
            placeholder="Full Name"
          />
          {usernameErr && <ErrorPill message={usernameErr} />}
        </div>

        <div className="mb-4">
          <input
            type="email"
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            value={email}
            name="email"
            onChange={this.onChange}
            placeholder="Email Address"
          />
          {emailErr && <ErrorPill message={emailErr} />}
        </div>

        <div className="mb-4">
          <input
            name="passwordOne"
            value={passwordOne}
            onChange={this.onChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="password"
            placeholder="Password"
          />
          {passwordOneErr && <ErrorPill message={passwordOneErr} />}
        </div>

        <div className="mb-4">
          <input
            name="passwordTwo"
            value={passwordTwo}
            onChange={this.onChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
            type="password"
            placeholder="Confirm Password"
          />
          {passwordTwoErr && <ErrorPill message={passwordTwoErr} />}
        </div>

        <div className="flex items-center justify-between">
          <button
            className={
              (isInvalid
                ? 'bg-gray-500 cursor-not-allowed '
                : 'bg-blue-500 hover:bg-blue-700 ') +
              'block w-full text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'
            }
            disabled={isInvalid}
            type="submit">
            Sign Up
          </button>
        </div>
      </form>
    )
  }
}

SignUpFormBase.propTypes = {
  registerUser: PropTypes.func.isRequired,
  removeAuthErrors: PropTypes.func.isRequired,
  getAllSubscriptionProducts: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  subscriptions: state.subscriptions,
})

const SignUpLink = () => (
  <p className="text-center w-full" style={{ marginBottom: 40 }}>
    Don't have an account?{' '}
    <Link
      className="inline-block align-baseline font-bold text-sm text-blue-500 hover:text-blue-800"
      to={SIGN_UP}>
      Sign Up
    </Link>
  </p>
)

const SignUpForm = connect(mapStateToProps, {
  registerUser,
  removeAuthErrors,
  getAllSubscriptionProducts,
})(withRouter(SignUpFormBase))

export default SignUpPage

export { SignUpForm, SignUpLink }
