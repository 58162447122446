//Main Container Reducers
import _ from 'lodash'
import { AUDIO, MUSIC, TEXT, VIDEO, VOICEOVER } from '../../constants/Channels'
import * as Constants from '../constants/SelectedItemConstants'

const DEFAULT_CHECKED_CHANNELS = { video: true, audio: true }
const emptySelections = {
  selectedSceneIndex: -1, // selected scene index
  selectedScene: null, // selected scene on timeline
  selectedTextIndex: -1, // selected text index
  selectedText: null,
  selectedMusicIndex: -1,
  selectedMusic: null,
  selectedSoundIndex: -1,
  selectedSound: null,
  selectedVoiceoverIndex: -1,
  selectedVoiceover: null,
}
const initialState = {
  ...emptySelections,
  checkedChannels: _.cloneDeep(DEFAULT_CHECKED_CHANNELS),
}

export default function (state = initialState, action) {
  switch (action.type) {
    case Constants.SET_SELECTED_MUSIC:
      if (action.selectedMusic) {
        _.set(state.checkedChannels, MUSIC.code, true)
      }
      return {
        ...state,
        selectedMusic: action.selectedMusic,
      }
    case Constants.SET_SELECTED_MUSIC_INDEX:
      return {
        ...state,
        selectedMusicIndex: action.selectedMusicIndex,
      }
    case Constants.SET_SELECTED_SCENE:
      if (action.selectedScene) {
        _.set(state.checkedChannels, VIDEO.code, true)
      }
      return {
        ...state,
        selectedScene: action.selectedScene,
      }
    case Constants.SET_SELECTED_SCENE_INDEX:
      return {
        ...state,
        selectedSceneIndex: action.selectedSceneIndex,
      }
    case Constants.SET_SELECTED_SOUND:
      if (action.selectedSound) {
        _.set(state.checkedChannels, AUDIO.code, true)
      }
      return {
        ...state,
        selectedSound: action.selectedSound,
      }
    case Constants.SET_SELECTED_SOUND_INDEX:
      return {
        ...state,
        selectedSoundIndex: action.selectedSoundIndex,
      }
    case Constants.SET_SELECTED_TEXT:
      if (action.selectedText) {
        _.set(state.checkedChannels, TEXT.code, true)
      }
      return {
        ...state,
        ...emptySelections,
        selectedText: action.selectedText,
      }
    case Constants.SET_SELECTED_TEXT_INDEX:
      return {
        ...state,
        selectedTextIndex: action.selectedTextIndex,
      }
    case Constants.SET_SELECTED_VOICEOVER:
      if (action.selectedVoiceover) {
        _.set(state.checkedChannels, VOICEOVER.code, true)
      }
      return {
        ...state,
        selectedVoiceover: action.selectedVoiceover,
      }
    case Constants.SET_SELECTED_VOICEOVER_INDEX:
      return {
        ...state,
        selectedVoiceoverIndex: action.selectedVoiceoverIndex,
      }
    case Constants.SET_CHECKED_CHANNELS:
      return {
        ...state,
        checkedChannels: action.checkedChannels,
      }
    case Constants.SET_CHECKED_CHANNEL: {
      const checkedChannels = _.cloneDeep(
        _.set(state.checkedChannels, action.channelCode, action.isEnabled),
      )
      return {
        ...state,
        checkedChannels: checkedChannels,
      }
    }
    case Constants.RESET_ALL:
      return _.cloneDeep(initialState)
    default:
      return state
  }
}
