import _ from 'lodash'
import { AUDIO, MUSIC, TEXT, VIDEO, VOICEOVER } from '../../constants/Channels'
import store from '..'
import * as Constants from '../constants/SelectedItemConstants'

/**
 * selected music clip index
 * @param selectedMusicIndex
 * @returns {{type: string, selectedMusicIndex}}
 * @constructor
 */
export const setSelectedMusicIndex = (selectedMusicIndex) => {
  return {
    type: Constants.SET_SELECTED_MUSIC_INDEX,
    selectedMusicIndex,
  }
}

/**
 * selected music clip data
 * @param selectedMusic
 * @returns {{type: string, selectedMusic}}
 * @constructor
 */
export const setSelectedMusic = (selectedMusic) => {
  return (dispatch) => {
    // dispatch(resetAllSelected())
    return dispatch({
      type: Constants.SET_SELECTED_MUSIC,
      selectedMusic,
    })
  }
}

/**
 * selected clip index
 * @param selectedSceneIndex
 * @returns {{selectedSceneIndex, type: string}}
 * @constructor
 */
export const setSelectedSceneIndex = (selectedSceneIndex) => {
  return {
    type: Constants.SET_SELECTED_SCENE_INDEX,
    selectedSceneIndex,
  }
}

/**
 * selected clip
 * @param selectedScene
 * @returns {{selectedScene, type: string}}
 * @constructor
 */
export const setSelectedScene = (selectedScene) => {
  return (dispatch) => {
    return dispatch({
      type: Constants.SET_SELECTED_SCENE,
      selectedScene,
    })
  }
}

/**
 * selected video's sound index
 * @param selectedSoundIndex
 * @returns {{selectedSoundIndex, type: string}}
 * @constructor
 */
export const setSelectedSoundIndex = (selectedSoundIndex) => {
  return {
    type: Constants.SET_SELECTED_SOUND_INDEX,
    selectedSoundIndex,
  }
}

/**
 * selected video's sound data
 * @param selectedSound
 * @returns {{selectedSound, type: string}}
 * @constructor
 */
export const setSelectedSound = (selectedSound) => {
  return (dispatch) => {
    return dispatch({
      type: Constants.SET_SELECTED_SOUND,
      selectedSound,
    })
  }
}

/**
 * selected text clip index
 * @param selectedTextIndex
 * @returns {{selectedTextIndex, type: string}}
 * @constructor
 */
export const setSelectedTextIndex = (selectedTextIndex) => {
  return {
    type: Constants.SET_SELECTED_TEXT_INDEX,
    selectedTextIndex,
  }
}

/**
 * selected text clip data
 * @param selectedText
 * @returns {{selectedText, type: string}}
 * @constructor
 */
export const setSelectedText = (selectedText) => {
  return (dispatch) => {
    // dispatch(resetAllSelected())
    return dispatch({
      type: Constants.SET_SELECTED_TEXT,
      selectedText,
    })
  }
}

/**
 * selected text clip index
 * @param selectedVoiceoverIndex
 * @returns {{selectedVoiceoverIndex, type: string}}
 * @constructor
 */
export const setSelectedVoiceoverIndex = (selectedVoiceoverIndex) => {
  return {
    type: Constants.SET_SELECTED_VOICEOVER_INDEX,
    selectedVoiceoverIndex,
  }
}

/**
 * selected text clip data
 * @param selectedVoiceover
 * @returns {{selectedVoiceover, type: string}}
 * @constructor
 */
export const setSelectedVoiceover = (selectedVoiceover) => {
  return (dispatch) => {
    // dispatch(resetAllSelected())
    return dispatch({
      type: Constants.SET_SELECTED_VOICEOVER,
      selectedVoiceover,
    })
  }
}

export const resetAllSelected = () => {
  return (dispatch) => {
    dispatch({
      type: Constants.RESET_ALL,
    })
  }
}

export const setCheckedChannels = (checkedChannels) => {
  return (dispatch) => {
    dispatch({
      type: Constants.SET_CHECKED_CHANNELS,
      checkedChannels,
    })
  }
}

export const setSelectedItemByChannel = (projectItem, channelCode) => {
  return (dispatch) => {
    const project = store.getState().editor.project
    const checkedChannels = store.getState().selectedItems.checkedChannels
    const previousCheckedChannelValue = _.get(
      checkedChannels,
      channelCode,
      false,
    )

    switch (channelCode) {
      case VIDEO.code: {
        const ratioProjectItems = _.get(
          project,
          `${VIDEO.projectPath}.${project.resolution}`,
          [],
        )
        const projectItemIndex = _.findIndex(
          ratioProjectItems,
          (item) => item.id === projectItem?.id,
        )
        dispatch(setSelectedScene(projectItem))
        dispatch(setSelectedSceneIndex(projectItemIndex))
        break
      }
      case AUDIO.code: {
        const ratioProjectItems = _.get(project, `${AUDIO.projectPath}`, [])
        const projectItemIndex = _.findIndex(
          ratioProjectItems,
          (item) => item.id === projectItem?.id,
        )
        dispatch(setSelectedSound(projectItem))
        dispatch(setSelectedSoundIndex(projectItemIndex))
        break
      }
      case TEXT.code: {
        const ratioProjectItems = _.get(
          project,
          `${TEXT.projectPath}.${project.resolution}`,
          [],
        )
        const projectItemIndex = _.findIndex(
          ratioProjectItems,
          (item) => item.id === projectItem?.id,
        )
        dispatch(setSelectedText(projectItem))
        dispatch(setSelectedTextIndex(projectItemIndex))
        break
      }
      case VOICEOVER.code: {
        const ratioProjectItems = _.get(project, `${VOICEOVER.projectPath}`, [])
        const projectItemIndex = _.findIndex(
          ratioProjectItems,
          (item) => item.id === projectItem?.id,
        )
        dispatch(setSelectedVoiceover(projectItem))
        dispatch(setSelectedVoiceoverIndex(projectItemIndex))
        break
      }
      case MUSIC.code: {
        const ratioProjectItems = _.get(project, `${MUSIC.projectPath}`, [])
        const projectItemIndex = _.findIndex(
          ratioProjectItems,
          (item) => item.id === projectItem?.id,
        )
        dispatch(setSelectedMusic(projectItem))
        dispatch(setSelectedMusicIndex(projectItemIndex))
        break
      }
    }

    dispatch({
      type: Constants.SET_CHECKED_CHANNEL,
      channelCode,
      isEnabled: !previousCheckedChannelValue,
    })
  }
}
