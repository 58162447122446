export const TwitterIcon = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 13.4167C0 8.67091 0 6.29803 0.938221 4.49188C1.72885 2.96985 2.96985 1.72885 4.49188 0.938221C6.29803 0 8.67091 0 13.4167 0H26.5833C31.3291 0 33.702 0 35.5081 0.938221C37.0301 1.72885 38.2711 2.96985 39.0618 4.49188C40 6.29803 40 8.67091 40 13.4167V26.5833C40 31.3291 40 33.702 39.0618 35.5081C38.2711 37.0301 37.0301 38.2711 35.5081 39.0618C33.702 40 31.3291 40 26.5833 40H13.4167C8.67091 40 6.29803 40 4.49188 39.0618C2.96985 38.2711 1.72885 37.0301 0.938221 35.5081C0 33.702 0 31.3291 0 26.5833V13.4167Z"
        fill="#1EA1F2"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M31.1022 15.1268C31.1119 15.3767 31.1151 15.6264 31.1151 15.8763C31.1151 23.4508 25.7868 32.1952 16.0431 32.1952C13.05 32.1952 10.2668 31.2413 7.92188 29.6173C8.33639 29.6628 8.75736 29.6969 9.18479 29.6969C11.6665 29.6969 13.9522 28.7769 15.7653 27.2324C13.4473 27.1984 11.4899 25.529 10.8149 23.2578C11.1389 23.3259 11.4727 23.3601 11.814 23.3601C12.2952 23.3601 12.7625 23.292 13.2104 23.1557C10.7847 22.6334 8.95762 20.3167 8.95762 17.5345C8.95762 17.5004 8.95762 17.489 8.95762 17.4663C9.67252 17.8865 10.4908 18.1475 11.3596 18.1816C9.93628 17.1482 9.00066 15.388 9.00066 13.4007C9.00066 12.3559 9.26121 11.3679 9.71986 10.5162C12.3318 13.9912 16.2369 16.2738 20.6393 16.5123C20.5489 16.0921 20.5025 15.6493 20.5025 15.2064C20.5025 12.038 22.8744 9.47149 25.8007 9.47149C27.3242 9.47149 28.7002 10.1643 29.6659 11.2772C30.875 11.0274 32.0076 10.5505 33.0326 9.8918C32.6353 11.2318 31.7966 12.3559 30.7006 13.0599C31.7729 12.9237 32.7958 12.6173 33.7443 12.163C33.0326 13.31 32.1368 14.3206 31.1022 15.1268Z"
        fill="white"
      />
    </svg>
  )
}
