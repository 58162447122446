import _ from 'lodash'
import React from 'react'
import { Range } from 'react-range'
import { ONE_SECOND } from '../../constants/Timeline'
import TimelineService from '../../services/time/TimelineService'

class AudioDuckTimelineRangeSlider extends React.Component {
  get currentTimeValue() {
    const { currentTime } = this.props
    return [currentTime || 0]
  }

  get threshold() {
    return this.props.threshold || 10
  }

  get durationInPixels() {
    const { duration } = this.props
    return TimelineService.convertMillisecondsToPixels(duration)
  }

  handleChangeCurrentTime = (values) => {
    const currentTime = _.first(values)
    const { onChangeCurrentTime, verticalGuidelines } = this.props

    if (!_.isEmpty(verticalGuidelines)) {
      return this.handleSnappableChangeCurrentTime(
        currentTime,
        verticalGuidelines,
      )
    }

    onChangeCurrentTime && onChangeCurrentTime(currentTime)
  }

  handleSnappableChangeCurrentTime = (currentTime, verticalGuidelines) => {
    const { onChangeCurrentTime, tickInMilliseconds } = this.props
    const nearestVerticalGuideline = _.first(
      _.sortBy(verticalGuidelines, (time) => Math.abs(time - currentTime)),
    )
    const distance = Math.abs(nearestVerticalGuideline - currentTime)
    if (distance / tickInMilliseconds <= this.threshold) {
      return (
        onChangeCurrentTime && onChangeCurrentTime(nearestVerticalGuideline)
      )
    }

    onChangeCurrentTime && onChangeCurrentTime(currentTime)
  }

  render() {
    const { duration, tickInMilliseconds } = this.props
    return (
      <Range
        step={tickInMilliseconds}
        min={0}
        draggableTrack={true}
        max={duration || 1}
        values={[this.currentTimeValue]}
        onChange={this.handleChangeCurrentTime}
        renderTrack={({ props, children }) => (
          <React.Fragment>
            <div
              {...props}
              style={{
                ...props.style,
                height: '24px',
                width: this.durationInPixels,
                // backgroundColor: 'rgba(255, 0, 255, 0.5)',
              }}></div>
            <div
              onMouseDown={props.onMouseDown}
              onTouchStart={props.onTouchStart}>
              {children}
            </div>
          </React.Fragment>
        )}
        renderThumb={({ props }) => {
          const percent =
            this.getPercentProgress(props) *
            TimelineService.convertMillisecondsToPixels(duration)
          return (
            <div
              {...props}
              className="absolute top-0"
              style={{
                ...props.style,
                zIndex: 100,
                height: '100%',
                left: `${percent}px`,
                width: '5px',
                backgroundColor: '#999',
              }}
            />
          )
        }}
      />
    )
  }

  getPercentProgress(props) {
    return props['aria-valuenow'] / props['aria-valuemax']
  }
}

export default AudioDuckTimelineRangeSlider
