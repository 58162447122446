import React from 'react'
import _ from 'lodash'
import { ONE_SECOND } from '../../constants/Timeline'
import Loader from 'react-loader-spinner'
import { gsap } from 'gsap'

class VideoPlayer extends React.Component {
  videoElement = React.createRef()
  animationTimeline = React.createRef()

  state = {
    loaded: false,
    isBuffering: true,
  }

  handleBuffering = () => {
    const { onBufferingStarted, currentTime, isPlaying } = this.props
    if (isPlaying) {
      this.setState({ isBuffering: true }, () => {
        this.animationTimeline && this.pauseAnimationTimeline()
        onBufferingStarted && onBufferingStarted(currentTime)
      })
    }
  }

  handleOnPlaying = () => {
    const { onBufferingFinished } = this.props
    onBufferingFinished && onBufferingFinished()
    this.setState({ isBuffering: false }, () => {
      this.animationTimeline
        .timeScale(this.animationTimeline.timeScale() || 0.001)
        .play(this.itemCurrentTime / ONE_SECOND)
    })
  }

  handleOnLoaded = () => {
    this.setState({ isBuffering: false })
  }

  handleOnCanPlay = () => {
    const { onBufferingFinished } = this.props
    const { isBuffering, loaded } = this.state

    if (loaded) {
      return
    }

    if (isBuffering) {
      onBufferingFinished && onBufferingFinished()
    }

    this.setState({ loaded: true, isBuffering: false }, () => {
      this.setState({ isBuffering: false })
    })
  }

  pauseAnimationTimeline = () => {
    this.animationTimeline.pause()
    this.animationTimeline.seek(this.itemCurrentTime / ONE_SECOND)
  }

  componentDidMount() {
    const { isPlaying, item } = this.props
    this.animationTimeline = this.createTimeline(item)

    if (this.videoElement?.current && isPlaying) {
      this.videoElement.current.play().then(() => {
        this.animationTimeline
          .timeScale(this.animationTimeline.timeScale() || 0.001)
          .play(this.itemCurrentTime / ONE_SECOND)
      })
      return
    }
  }

  killTimeline() {
    if (this.animationTimeline) {
      this.animationTimeline.invalidate()
      this.animationTimeline.kill()
      this.animationTimeline = React.createRef()
    }
  }

  createTimeline(item) {
    const startDurationInSeconds = item.startAni.time / ONE_SECOND
    const endDurationInSeconds = item.endAni.time / ONE_SECOND
    const durationInSeconds = item.clip.duration / ONE_SECOND
    let timeline = gsap.timeline()
    timeline.fromTo(
      this.videoElement?.current,
      { opacity: 0 },
      { opacity: 1, duration: startDurationInSeconds },
      0,
    )
    timeline.fromTo(
      this.videoElement?.current,
      { opacity: 1 },
      { opacity: 0, duration: endDurationInSeconds },
      durationInSeconds - endDurationInSeconds,
    )
    return timeline
  }

  componentDidUpdate(prevProps) {
    const { tickInMilliseconds, isPlaying, item } = this.props
    const { loaded } = this.state
    const isPlayingStopped =
      this.props.isPlaying !== prevProps.isPlaying && !this.props.isPlaying
    const isPlayingStarted =
      this.props.isPlaying !== prevProps.isPlaying && this.props.isPlaying
    const endOfFrameInMilliseconds = this.props.currentTime + tickInMilliseconds
    const isBetweenFrame =
      this.props.currentTime <= this.props.item.start_time &&
      this.props.item.start_time <= endOfFrameInMilliseconds
    const isBetweenItem =
      this.props.currentTime > this.props.item.start_time &&
      this.props.currentTime < this.props.item.end_time

    const isDurationChanged =
      _.get(this.props.item, 'clip.duration', 0) !==
      _.get(prevProps.item, 'clip.duration', 0)
    const isStartAnimationChanged =
      _.get(this.props.item, 'startAni.time', 0) !==
      _.get(prevProps.item, 'startAni.time', 0)
    const isEndAnimationChanged =
      _.get(this.props.item, 'endAni.time', 0) !==
      _.get(prevProps.item, 'endAni.time', 0)

    if (isDurationChanged || isStartAnimationChanged || isEndAnimationChanged) {
      if (isPlaying) {
        this.videoElement.current.play().then(() => {
          this.killTimeline()
          this.animationTimeline = this.createTimeline(
            item,
            this.animationTimeline,
          )
          this.animationTimeline
            .timeScale(this.animationTimeline.timeScale() || 0.001)
            .play(this.itemCurrentTime / ONE_SECOND)
        })
      }
      return
    }

    // const isNextFrameEndTime = (this.props.currentTime  < this.props.item.end_time) && ((this.props.currentTime + tickInMilliseconds) >= this.props.item.end_time)
    // if (isPlaying && isNextFrameEndTime) {
    //   this.killTimeline()
    //   this.animationTimeline = this.createTimeline(item, this.animationTimeline)
    //   return
    // }

    if (isPlayingStarted && (isBetweenItem || isBetweenFrame) && loaded) {
      this.videoElement.current.play().then(() => {
        this.animationTimeline
          .timeScale(this.animationTimeline.timeScale() || 0.001)
          .play(this.itemCurrentTime / ONE_SECOND)
      })
      return
    }

    const isBothItemExisting = this.props.item && prevProps.item
    const isOriginUrlChanged =
      this.props.item.originUrl !== prevProps.item.originUrl
    const isItemDifferentFromPrevious = this.props.item.id !== prevProps.item.id

    if (
      this.props.isPlaying &&
      isBothItemExisting &&
      (isOriginUrlChanged || isItemDifferentFromPrevious)
    ) {
      this.videoElement.current.load(this.props.item.originUrl)
      this.videoElement.current.play().then(() => {
        this.killTimeline()
        this.animationTimeline = this.createTimeline(
          item,
          this.animationTimeline,
        )
        // this.animationTimeline.timeScale(this.animationTimeline.timeScale() || 0.001).play(this.itemCurrentTime / ONE_SECOND)
      })

      // process.nextTick(() => {
      //   this.animationTimeline = this.createTimeline(item)
      //   this.animationTimeline.timeScale(this.animationTimeline.timeScale() || 0.001).play(this.itemCurrentTime / ONE_SECOND)
      // })
      return
    }

    if (!this.props.isPlaying) {
      this.videoElement.current.currentTime = this.itemCurrentTime / ONE_SECOND
      this.animationTimeline && this.pauseAnimationTimeline()
    }

    if (isPlayingStopped) {
      this.animationTimeline && this.pauseAnimationTimeline()
      return this.videoElement.current.pause()
    }
  }

  get startTime() {
    const { item } = this.props
    const clipStart = item.clip.start
    return clipStart / ONE_SECOND
  }

  get pausedStartTime() {
    const { item, currentTime } = this.props
    const clipStart = item.clip.start
    const startTime = item.start_time

    if (currentTime > startTime) {
      return (this.itemCurrentTime + clipStart) / ONE_SECOND
    }

    return startTime
  }

  get endTime() {
    const { item } = this.props
    return item.clip.end / ONE_SECOND
  }

  get itemUrlWithClippedPositions() {
    const { item } = this.props
    const itemUrl = `${item.assetUrl}#t=${this.startTime},${this.endTime}`
    return itemUrl
  }

  get itemUrlFromPausedPosition() {
    const { item } = this.props
    const newItemUrl = `${item.originUrl}#t=${this.pausedStartTime},${this.endTime}`
    return newItemUrl
  }

  get width() {
    const { item, viewport } = this.props
    const clientRect = viewport?.getBoundingClientRect()
    const zoomRatio = this.zoomRatioFromOriginalCrop(item)
    const displayZoomRatio = this.zoomRatioFromDisplayToAspect(item, clientRect)
    const zoomedWidth =
      zoomRatio * (item.aspectSize?.width || clientRect?.width)
    const zoomedDisplayWidth = zoomedWidth * displayZoomRatio
    return zoomedDisplayWidth
  }

  get height() {
    const { item, viewport } = this.props
    const clientRect = viewport?.getBoundingClientRect()
    const zoomRatio = this.zoomRatioFromOriginalCrop(item)
    const displayZoomRatio = this.zoomRatioFromDisplayToAspect(item, clientRect)
    const zoomedWidth =
      zoomRatio * (item.aspectSize?.height || clientRect?.height)
    const zoomedDisplayWidth = zoomedWidth * displayZoomRatio
    return zoomedDisplayWidth
  }

  get left() {
    const { item, viewport } = this.props
    const clientRect = viewport?.getBoundingClientRect()
    const zoomRatio = this.zoomRatioFromOriginalCrop(item)
    const displayZoomRatio = this.zoomRatioFromDisplayToAspect(item, clientRect)
    const viewportMarginWidth = this.getViewportMarginWidth(item)
    const possibleLeft = item.aspectSize?.left || viewportMarginWidth
    const zoomedLeft = zoomRatio * Math.abs(viewportMarginWidth - possibleLeft)
    const zoomedDisplayLeft = zoomedLeft * displayZoomRatio
    return zoomedDisplayLeft
  }

  get itemCurrentTime() {
    const { currentTime, item } = this.props
    const clipCurrentTime = currentTime - item.start_time
    return clipCurrentTime
  }

  get top() {
    const { item, viewport } = this.props
    const clientRect = viewport?.getBoundingClientRect()
    const zoomRatio = this.zoomRatioFromOriginalCrop(item)
    const displayZoomRatio = this.zoomRatioFromDisplayToAspect(item, clientRect)
    const viewportMarginHeight = this.getViewportMarginHeight(item)
    const possibleHeight = item.aspectSize?.top || viewportMarginHeight
    const zoomedTop =
      zoomRatio * Math.abs(viewportMarginHeight - possibleHeight)
    const zoomedDisplayTop = zoomedTop * displayZoomRatio
    return zoomedDisplayTop
  }

  getViewportMarginWidth(item) {
    const originalWidth = item.p_width
    const isStreamSizeValid =
      item.streamSize?.width && item.aspectSize?.width > 0
    const streamSizeWidth = isStreamSizeValid
      ? item.streamSize?.width
      : originalWidth
    return Math.abs(streamSizeWidth - originalWidth) / 2
  }

  getViewportMarginHeight(item) {
    const originalHeight = item.p_height
    const isStreamSizeValid =
      item.streamSize?.height && item.aspectSize?.height > 0
    const streamSizeHeight = isStreamSizeValid
      ? item.streamSize?.height
      : originalHeight
    return Math.abs(streamSizeHeight - originalHeight) / 2
  }

  zoomRatioFromDisplayToAspect(item, clientRect) {
    const displayVideoPixels = clientRect?.width + clientRect?.height
    const aspectVideoPixels = this.getAspectVideoPixels(
      item,
      displayVideoPixels,
    )
    return displayVideoPixels / aspectVideoPixels
  }

  zoomRatioFromOriginalCrop(item) {
    const originalVideoPixels = item.streamSize?.width + item.streamSize?.height
    const aspectVideoPixels = this.getAspectVideoPixels(
      item,
      originalVideoPixels,
    )
    const zoomRatio = originalVideoPixels / aspectVideoPixels
    return zoomRatio
  }

  getAspectVideoPixels(item, fallbackVideoPixels) {
    let aspectVideoPixels = item.aspectSize?.width + item.aspectSize?.height
    aspectVideoPixels =
      item.aspectSize?.width && item.aspectSize?.height
        ? aspectVideoPixels
        : fallbackVideoPixels
    return aspectVideoPixels
  }

  pixelRatioFromOriginalCrop(item) {
    return 1 % this.zoomRatioFromOriginalCrop(item)
  }

  render() {
    const { isBuffering } = this.state
    return (
      <div
        style={{
          position: 'relative',
          top: 0,
          height: '100%',
          width: '100%',
        }}>
        <video
          style={{
            width: '100%',
            height: '100%',
            left: 0,
            top: 0,
            //width: this.width,
            // minWidth: this.width,
            minWidth: '100%',
            //left: -this.left,
            //top: -this.top / 2,
            objectFit: 'fill',
            //height: this.height,
            // minHeight: this.height,
            minHeight: '100%',
          }}
          className="absolute"
          muted
          ref={this.videoElement}
          src={this.itemUrlWithClippedPositions}
          onCanPlay={this.handleOnCanPlay}
          onWaiting={this.handleBuffering}
          onPlaying={this.handleOnPlaying}
          onLoadedData={this.handleOnLoaded}
        />
      </div>
    )
  }
}

export default VideoPlayer
