export const ZOOM_LEVEL = {
  LONG: 'long',
  MEDIUM: 'medium',
  CLOSE: 'close',
}

export const ZOOM_LEVEL_TRANSFORM = {
  [ZOOM_LEVEL.LONG]: 'scaleX(1) scaleY(1)',
  [ZOOM_LEVEL.MEDIUM]: 'scaleX(1) scaleY(1)',
  [ZOOM_LEVEL.CLOSE]: 'scaleX(2) scaleY(2)',
}

export const ZOOM_LEVEL_OBJECT_FIT = {
  [ZOOM_LEVEL.LONG]: 'scale-down',
  [ZOOM_LEVEL.MEDIUM]: 'contain',
  [ZOOM_LEVEL.CLOSE]: 'contain',
}
