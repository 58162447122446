import _ from 'lodash'
import { DIRECTIONS } from '../constants/SnapGridAlignment'

const realignGridToLeft = (snapGrid, videoDisplayRect) => {
  return snapGrid
}

const realignGridToCenter = (snapGrid, videoDisplayRect) => {
  return snapGrid
}

const realignGridToRight = (snapGrid, videoDisplayRect) => {
  return snapGrid
}

export const realignSnapGrid = (snapGrid, videoDisplayRect, direction) => {
  switch (direction) {
    case DIRECTIONS.LEFT:
      return realignGridToLeft(snapGrid, videoDisplayRect)
    case DIRECTIONS.CENTER:
      return realignGridToCenter(snapGrid, videoDisplayRect)
    case DIRECTIONS.RIGHT:
      return realignGridToRight(snapGrid, videoDisplayRect)
    default:
      return snapGrid
  }
}
