import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  toggleShowImport,
  toggleShowReview,
  toggleShowTextPanel,
  updateTimeSlider,
} from '../../methods/editor/DesktopEditorMethods'
import * as MediaPlayerActions from '../../redux/actions/MediaPlayerActions'
import * as SubscriptionActions from '../../redux/actions/SubscriptionActions'
import * as SelectedItemActions from '../../redux/actions/SelectedItemActions'
import EditorHeader from '../../components/video-editor/EditorHeader'
import EditorMain from '../../components/video-editor/EditorMain'
import TimeTickerContextProvider from '../../components/projects/TimeTickerContext'
import VoiceRecorder from '../../components/voiceover/VoiceRecorder'
import Timeline from '../../components/video-editor/desktop-items/Timeline'
import ReviewPublish from '../../components/publish/ReviewPublish'
import {
  SubscriptionManager,
  alertSubscriptionMessage,
} from '../../utils/Helper'

class DesktopEditor extends Component {
  constructor(props) {
    super(props)

    this.state = {
      updateSlideV: 0,
      showImport: false,
      showTextPanel: false,
      showReview: false,
      channelCode: null,
      showSidebarMedia: '',
      isAudioDuckModalDisplayed: false,
    }

    this.sceneVideos = []

    this.updateTimeSlider = updateTimeSlider.bind(this)
    this.toggleShowImport = toggleShowImport.bind(this)
    this.toggleShowTextPanel = toggleShowTextPanel.bind(this)
    this.toggleShowReview = toggleShowReview.bind(this)
    this.handleDisplayAudioDuckModal =
      this.handleDisplayAudioDuckModal.bind(this)
  }

  updateShowSidebarMedia = (val) => {
    this.setState({ showSidebarMedia: val })
  }

  componentDidMount() {
    window.addEventListener('resize', () => {
      // window.location.reload()
    })
    this.props.getProductFeatures()
  }

  handleDisplayAudioDuckModal = (value = true) => {
    const {
      authUser,
      subscriptions: { user: userSubscription, products },
    } = this.props
    if (!SubscriptionManager(authUser, userSubscription, products)?.isProPlan) {
      return alertSubscriptionMessage(
        'It looks like you’re looking to do some audio ducking. No problem, just upgrade to the Cerebriam Pro package.',
      )
    }
    this.props.resetAllSelected()
    this.setState({ isAudioDuckModalDisplayed: value })
  }

  render() {
    const { updateSlideV, showTextPanel, showReview } = this.state

    const {
      project,
      timeSlider,
      showEditor,
      libraryFiles,
      template,
      scenePlaying,
      getLibFiles,
    } = this.props

    return (
      <div className="desktop-editor">
        <EditorHeader
          toggleShowReview={this.toggleShowReview}
          reactivateTooltipSteps={this.props.reactivateTooltipSteps}
        />
        <EditorMain
          showSidebarMedia={this.state.showSidebarMedia}
          updateShowSidebarMedia={this.updateShowSidebarMedia.bind(this)}
          libraryFiles={libraryFiles}
          toggleShowImport={this.toggleShowImport}
          getLibFiles={getLibFiles}
          toggleShowTextPanel={this.toggleShowTextPanel}
          updateTimeSlider={this.updateTimeSlider}
          id="stage-movie-player"
          height="calc(100% - 35px)"
          scenePlaying={scenePlaying}
          togglePlay={(scenePlaying) => this.props.setPlaying(scenePlaying)}
          updateSlideV={updateSlideV}
          showTextPanel={showTextPanel}
          template={template}
          sceneVideos={this.sceneVideos}
          handleDisplayAudioDuckModal={this.handleDisplayAudioDuckModal}
        />
        {!this.props?.isVoiceRecorderDisplayed && (
          <TimeTickerContextProvider
            project={project}
            curSec={timeSlider.curSec}>
            <VoiceRecorder
              getLibFiles={getLibFiles}
              updateTimeSlider={this.updateTimeSlider}
            />
          </TimeTickerContextProvider>
        )}

        <div
          className="app-layout"
          style={{
            width: !showReview && showTextPanel ? '896.25px' : '1195px',
            marginTop: 25,
          }}>
          {/* Timeline */}
          {showEditor && (
            <Timeline
              updateShowSidebarMedia={this.updateShowSidebarMedia.bind(this)}
              updateSlideV={updateSlideV}
              scenePlaying={scenePlaying}
              template={template}
              showTextPanel={showTextPanel}
              updateTimeSlider={this.updateTimeSlider}
              toggleShowImport={this.toggleShowImport}
              toggleShowTextPanel={this.toggleShowTextPanel}
              getLibFiles={getLibFiles}
              reactivateTooltipSteps={this.props.reactivateTooltipSteps}
              handleDisplayAudioDuckModal={this.handleDisplayAudioDuckModal}
              isAudioDuckModalDisplayed={this.state.isAudioDuckModalDisplayed}
            />
          )}
          {showReview && (
            <ReviewPublish
              template={template}
              scenePlaying={scenePlaying}
              togglePlay={(scenePlaying) => this.props.setPlaying(scenePlaying)}
              updateSlideV={updateSlideV}
              showReview={showReview}
              toggleShowReview={this.toggleShowReview}
              updateTimeSlider={this.updateTimeSlider}
            />
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  watchProject: state.editor.watchProject,
  project: state.editor.project,
  addItem: state.editor.addItem,
  scenePlaying: state.mediaPlayer.scenePlaying,
  productFeatures: state.subscriptions.features,
  timeSlider: state.timeline.timeSlider,
  authUser: state.auth.user,
  subscriptions: state.subscriptions,
})

const mapDispatchToProps = (dispatch) => ({
  setPlaying: (...args) => dispatch(MediaPlayerActions.setPlaying(...args)),
  getProductFeatures: () => dispatch(SubscriptionActions.getProductFeatures()),
  resetAllSelected: (...any) =>
    dispatch(SelectedItemActions.resetAllSelected(...any)),
})

export default connect(mapStateToProps, mapDispatchToProps)(DesktopEditor)
