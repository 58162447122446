import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import ActionMenuBar from '../../sidebar'
import { connect } from 'react-redux'
import DesktopActions from './DesktopActions'
import * as ProjectEditActions from '../../../redux/actions/ProjectEditActions'
import * as RecorderActions from '../../../redux/actions/RecorderActions'
import * as SnapGridBoxActions from '../../../redux/actions/SnapGridBoxActions'

class RecordActions extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showTapNotify: false,
      showSnap: true,
    }

    this.mounted = false
  }

  componentDidMount() {
    this.mounted = true
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.mounted &&
      this.props.cameraVideo &&
      this.props.cameraVideo !== prevProps.cameraVideo
    ) {
      if (!this.props.snapGridPosition.set) {
        this.setState({
          showTapNotify: true,
        })
      }
    }
  }

  componentWillUnmount() {
    this.mounted = false
  }

  closeNotifyModal = () => {
    this.setState({
      showTapNotify: false,
    })
  }

  toggleSnapGrid = (r) => {
    this.setState({
      showSnap: r,
    })
  }

  skipToEditor = () => {
    this.props.history.push(`/editor/${this.props.project?.id || 0}`)
  }

  render() {
    const {
      isRecording,
      isConnecting,
      isCameraOn,
      isRunningCountdown,
      onStartRecording,
      onStopRecording,
      cameraVideo,
      onTurnOnCamera,
      onUploadRecordVideo,
      onUploadFacetrackedVideo,
      MediaStream,
      setScaleValue,
      isFaceTrackingPreviewEnabled,
      selectRatio,
      selectedRatio,
      resetSelectedAspectRatios,
      setDefaultLayout,
      setSelectedMediaLayout,
      setSaveRatioLayout,
      setMediaStreamSize,
      setMediaOriginSize,
      setReviewScreenSize,
      setVideoAspectSize,
      onPauseRecording,
      onResumeRecording,
    } = this.props

    const { showTapNotify, showSnap } = this.state

    return (
      <div className="absolute w-full h-full z-50 top-0 left-0">
        {/* {showTapNotify && (
          <TapSnapPointNotify closeNotifyModal={this.closeNotifyModal} />
        )} */}

        {/*menu bar*/}
        <ActionMenuBar toggleSnapGrid={this.toggleSnapGrid} MenuPage="record" />

        {/*desktop action*/}
        {isCameraOn && !isConnecting && (
          <DesktopActions
            ratioToEdit={this.props.ratioToEdit}
            clearEditRatio={this.props.clearEditRatio}
            MediaStream={MediaStream}
            cameraVideo={cameraVideo}
            isCameraOn={isCameraOn}
            isRunningCountdown={isRunningCountdown}
            isRecording={isRecording}
            onStartRecording={onStartRecording}
            onStopRecording={onStopRecording}
            showSnap={showSnap}
            setScaleValue={setScaleValue}
            layoutChangedFromDropDown={this.props.layoutChangedFromDropDown}
            changeLayoutFromDropdown={this.props.changeLayoutFromDropdown}
            moveRef={this.props.moveRef}
            selNextPopup={this.props.selNextPopup}
            cropperActionWrapper={this.props.cropperActionWrapper}
            videoActionWrapper={this.props.cropperActionWrapper}
            retriggerCropperTooltip={this.props.retriggerCropperTooltip}
            isFaceTrackingPreviewEnabled={isFaceTrackingPreviewEnabled}
            selectRatio={selectRatio}
            selectedRatio={selectedRatio}
            resetSelectedAspectRatios={resetSelectedAspectRatios}
            setDefaultLayout={setDefaultLayout}
            setSelectedMediaLayout={setSelectedMediaLayout}
            setSaveRatioLayout={setSaveRatioLayout}
            setMediaStreamSize={setMediaStreamSize}
            setMediaOriginSize={setMediaOriginSize}
            setReviewScreenSize={setReviewScreenSize}
            setVideoAspectSize={setVideoAspectSize}
            onTurnOnCamera={onTurnOnCamera}
            onPauseRecording={onPauseRecording}
            onResumeRecording={onResumeRecording}
            pauseRecorder={this.props.pauseRecorder}
          />
        )}

        {!this.props.isLoading && !isCameraOn && (
          <div className="w-full h-full relative">
            <div className="absolute flex justify-center w-full replay-action">
              <button
                className="rounded bg-teal-700 hover:bg-teal-600 xs:py-1 sm:py-2 sm:px-3 xs:px-2 mr-2"
                onClick={onTurnOnCamera}>
                Reshoot
              </button>

              <button
                className="rounded bg-teal-700 hover:bg-teal-600 xs:py-1 sm:py-2 sm:px-3 xs:px-2 mr-2"
                onClick={onUploadRecordVideo}>
                Make
              </button>
              {this.props.faceTrackingVideo && (
                <button
                  className="rounded bg-teal-700 hover:bg-teal-600 xs:py-1 sm:py-2 sm:px-3 xs:px-2 mr-2"
                  onClick={onUploadFacetrackedVideo}>
                  Make Tracked Video
                </button>
              )}

              <button
                className="rounded bg-teal-700 hover:bg-teal-600 xs:py-1 sm:py-2 sm:px-3 xs:px-2"
                onClick={this.skipToEditor}>
                Skip to Edit
              </button>
            </div>
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  faceTrackingVideo: state.faceTrack.faceTrackingVideo,
  menu_is_open: state.recorder.menu_is_open,
  snapGridPosition: state.recorder.snapGridPosition,
  zoomAbleState: state.recorder.zoomAbleState,
  project: state.editor.project,
})

const mapDispatchProps = (dispatch) => ({
  setDefaultLayout: (...args) =>
    dispatch(ProjectEditActions.setDefaultLayout(...args)),
  setSelectedMediaLayout: (...args) =>
    dispatch(RecorderActions.setSelectedMediaLayout(...args)),
  setSaveRatioLayout: (...args) =>
    dispatch(RecorderActions.setSaveRatioLayout(...args)),
  setMediaStreamSize: (...args) =>
    dispatch(SnapGridBoxActions.setMediaStreamSize(...args)),
  setMediaOriginSize: (...args) =>
    dispatch(SnapGridBoxActions.setMediaOriginSize(...args)),
  setReviewScreenSize: (...args) =>
    dispatch(SnapGridBoxActions.setReviewScreenSize(...args)),
  setVideoAspectSize: (...args) =>
    dispatch(SnapGridBoxActions.setVideoAspectSize(...args)),
})

export default connect(
  mapStateToProps,
  mapDispatchProps,
)(withRouter(RecordActions))
