/* eslint-disable */
import _ from 'lodash'
import * as TimelineSet from '../../utils/staging-preview/TimelineSet'
import { ONE_SECOND } from '../../constants/Timeline'
import * as TimelineService from '../../services/time/TimelineService'
import { SetPreviewTimeline } from '../../redux/actions/EditorActions'
import ProjectTimeCalibrator from '../../services/project/ProjectTimeCalibrator'
import DurationGetter from '../../services/time/DurationGetter'
import RATIO from '../../constants/AspectRatios'
import { AUDIO, MUSIC, TEXT, VIDEO, VOICEOVER } from '../../constants/Channels'
import { FLOAT_RATE, SLIDER_PRO } from '../../constants/TimelineItems'

/**
 * Set up project scenes slider position of timeline
 * @param projectVal
 * @param totalTime
 * @returns {{totalTime: *, projectVal: *}}
 */
export function setSceneTimeline({ projectVal, totalTime }) {
  _.forEach(RATIO, (ratio) => {
    if (!projectVal.selectLayout[ratio]) {
      return
    }
  })
  totalTime = DurationGetter.getDurationFromProject(projectVal)
  return { projectVal, totalTime }
}

/**
 * get project width
 *
 * @param projectVal
 * @returns {number}
 */
export function getProjectWidth(projectVal) {
  const { resolutions } = this.state
  const stageWidth = this.stageRef.current.getBoundingClientRect().width
  const stageHeight = Math.round((stageWidth * 503) / 896.25)

  const projectRatioResolution = resolutions[projectVal.resolution]
  const projectW = Math.round((stageHeight * projectRatioResolution.w) / projectRatioResolution.h)
  return projectW
}

/**
 * project data update
 * @param projectVal
 * @param totalTime
 * @returns {*}
 */
export function updateProjectReview(projectVal, totalTime) {
  if (
    projectVal &&
    projectVal.scenes[projectVal.resolution] &&
    projectVal.scenes[projectVal.resolution].length
  ) {
    projectVal = projectVal
    totalTime = projectVal.duration
  }

  projectVal.duration = totalTime

  return projectVal
}

/**
 * project update
 * @param projectVal
 * @param totalTime
 * @returns {*}
 */
export function updateProjectValue(projectVal, totalTime) {
  const stageWidth = this.stageRef.current.getBoundingClientRect().width
  const stageHeight = Math.round((stageWidth * 503) / 896.25)

  const { updateProject } = this.props

  if (
    projectVal &&
    projectVal.scenes[projectVal.resolution] &&
    projectVal.scenes[projectVal.resolution].length
  ) {
    const p = this.setSceneTimeline({ projectVal, totalTime })

    projectVal = p.projectVal
    totalTime = p.totalTime
  }

  const projectW = this.getProjectWidth(projectVal)

  for (let s = 0; s < projectVal.scenes[projectVal.resolution].length; s++) {
    projectVal.scenes[projectVal.resolution][s].p_width = projectW
    projectVal.scenes[projectVal.resolution][s].p_height = stageHeight
  }

  for (let t = 0; t < projectVal.texts[projectVal.resolution].length; t++) {
    projectVal.texts[projectVal.resolution][t].p_width = projectW
    projectVal.texts[projectVal.resolution][t].p_height = stageHeight
  }

  projectVal.duration = totalTime

  this.setState({
    textClips: projectVal.texts[projectVal.resolution],
  })

  updateProject(projectVal)

  return projectVal
}

/**
 * handle staging progress bar update
 * @param value
 */
export async function handleProgressChange(value, jump = false) {
  const {
    timeSlider,
    project,
    scenePlaying,
    previewTimeLine,
    SetTimeSlider,
  } = this.props

  this.setState({
    slideV: value,
    selectedText: null,
    selectedTextIndex: -1,
  })

  if (!project) {
    return
  }
//  let currentProgress = _.round(value / SLIDER_PRO, 2)
  let currentProgress = value / SLIDER_PRO
  const currentMilliseconds = _.round(currentProgress * project.duration, 0)
  SetTimeSlider({
    curSec: currentMilliseconds,
    value: value,
    width: timeSlider.width,
    show: true,
  })

  this.props.updateTimeSlider(value)

  if ((!scenePlaying || jump) && previewTimeLine) {
    const currentSeconds = _.round(currentMilliseconds / ONE_SECOND, 2)
    previewTimeLine.seek(currentSeconds)
  }

  if (scenePlaying && previewTimeLine) {
    previewTimeLine.play()
  }

  if (project.scenes[project.resolution] && project.scenes[project.resolution].length) {
    playbackElements(this.sceneVideos, this.playVideo)(
      project.scenes[project.resolution],
      currentMilliseconds,
      scenePlaying,
      FLOAT_RATE,
    )
  }

  if (project.music && project.music.length) {
    playbackElements(this.sceneMusics, this.playAudio)(
      project.music,
      currentMilliseconds,
      scenePlaying,
      FLOAT_RATE,
    )
  }

  if (project.sound && project.sound.length) {
    playbackElements(this.sceneSounds, this.playSound)(
      project.sound,
      currentMilliseconds,
      scenePlaying,
      FLOAT_RATE,
    )
  }

  if (project.voiceover && project.voiceover.length) {
    playbackElements(this.sceneVoiceovers, this.playVoiceover)(
      project.voiceover,
      currentMilliseconds,
      scenePlaying,
      FLOAT_RATE,
    )
  }

  if (project.sound && project.sound.length) {
    playbackElements(this.sceneSounds, this.playSound)(
      project.sound,
      currentMilliseconds,
      scenePlaying,
      FLOAT_RATE,
    )
  }
}




/**
 * play video scene
 * @param scene
 * @param ind
 * @param startTime
 * @param clipStart
 * @param currentSecondsValue
 */
export function playVideo(ind, startTime, clipStart, currentSecondsValue) {
  const { project } = this.props

  const scene = project.scenes[project.resolution][ind]

  let vid = this.sceneVideos[ind]

  if (!vid) {
    return
  }

  vid.muted = false

  if (vid.src !== scene.assetUrl) {
    vid.src = scene.assetUrl
    vid.preload = 'auto'
  }

  if (currentSecondsValue === 0 || currentSecondsValue - startTime <= 0) {
    vid.currentTime = Math.round((clipStart / 1000) * FLOAT_RATE) / FLOAT_RATE
  }

  let curTime =
    Math.round(((currentSecondsValue - startTime) / 1000) * FLOAT_RATE) / FLOAT_RATE

  curTime += Math.round((clipStart / 1000) * FLOAT_RATE) / FLOAT_RATE

  if (!vid.paused && !vid.ended && vid.readyState > 2) {
    vid.pause()
  }

  if (curTime > vid.duration) {
    vid.currentTime = curTime - vid.duration
  } else {
    vid.currentTime = curTime
  }

  vid.pause()
}

// TODO: combine playAudio, playSound and playVoiceover into one method

/**
 * play audio scenes on the music channel
 * @param ind
 * @param startTime
 * @param clipStart
 * @param currentSecondsValue
 */
export function playAudio(ind, startTime, clipStart, currentSecondsValue) {
  const { project } = this.props

  const selAudio = project.music[ind]

  const aud = this.sceneMusics[ind]

  /**
   * Reload all sounds on play since they aren't reloaded automatically like images
   */
  const projectMusics = _.get(project, 'music', [])
  projectMusics.map((music, index) => {
    const sceneMusic = this.sceneMusics[index]
    if (sceneMusic){
      sceneMusic.load()
    }
  })

  if (aud.paused || aud.ended) {
    const aPromise = aud.play()
    if (aPromise !== undefined) {
      aPromise
        .then(() => { })
        .catch((e) => {
          console.log(e)
        })
    }
  }

  if (currentSecondsValue >= selAudio.end_time) {
    aud.currentTime = Math.round((clipStart / 1000) * FLOAT_RATE) / FLOAT_RATE
    aud.pause()

    return
  }

  if (currentSecondsValue === 0 || currentSecondsValue - startTime <= 0) {
    aud.currentTime = Math.round((clipStart / 1000) * FLOAT_RATE) / FLOAT_RATE
  }

  let curTime =
    Math.round(((currentSecondsValue - startTime) / 1000) * FLOAT_RATE) / FLOAT_RATE

  curTime += Math.round((clipStart / 1000) * FLOAT_RATE) / FLOAT_RATE

  if (!aud.paused && !aud.ended && aud.readyState > 2) {
    aud.pause()
  }

  if (curTime > aud.duration) {
    aud.currentTime = curTime - aud.duration
  } else {
    aud.currentTime = curTime
  }

  aud.pause()
}

export function playSound(ind, startTime, clipStart, currentSecondsValue) {
  const { project } = this.props

  const selAudio = project.sound[ind]

  const aud = this.sceneSounds[ind]

  /**
   * Reload all sounds on play since they aren't reloaded automatically like images
   */
  const projectSounds = _.get(project, 'sound', [])
  projectSounds.map((sound, index) => {
    const sceneSound = this.sceneSounds[index]
   /**
     * Check if the audio channel has the correct url
     */
    if (sceneSound){
//      console.log('Audio '+index+' url:'+sceneSound.src)
      if (sceneSound.src !== sound.url) {
        sceneSound.src = sound.url
      }  
      sceneSound.load()
    }
  })

  if (aud.paused || aud.ended) {
    const aPromise = aud.play()
    if (aPromise !== undefined) {
      aPromise
        .then(() => { })
        .catch((e) => {
          console.log(e)
        })
    }
  }

  if (currentSecondsValue >= selAudio.end_time) {
    aud.currentTime = Math.round((clipStart / 1000) * FLOAT_RATE) / FLOAT_RATE
    aud.pause()

    return
  }

  if (currentSecondsValue === 0 || currentSecondsValue - startTime <= 0) {
    aud.currentTime = Math.round((clipStart / 1000) * FLOAT_RATE) / FLOAT_RATE
  }

  let curTime =
    Math.round(((currentSecondsValue - startTime) / 1000) * FLOAT_RATE) / FLOAT_RATE

  curTime += Math.round((clipStart / 1000) * FLOAT_RATE) / FLOAT_RATE

  if (!aud.paused && !aud.ended && aud.readyState > 2) {
    aud.pause()
  }

  if (curTime > aud.duration) {
    aud.currentTime = curTime - aud.duration
  } else {
    aud.currentTime = curTime
  }

  aud.pause()
}

export function playVoiceover(ind, startTime, clipStart, currentSecondsValue) {
  const { project } = this.props

  const selAudio = project.voiceover[ind]

  const aud = this.sceneVoiceovers[ind]

  /**
   * Reload all sounds on play since they aren't reloaded automatically like images
   */
  this.sceneVoiceovers.map((voiceover, index) => {
    if (voiceover) {
      voiceover.load()
    }
  })

  if (aud.paused || aud.ended) {
    const aPromise = aud.play()
    if (aPromise !== undefined) {
      aPromise
        .then(() => { })
        .catch((e) => {
          console.log(e)
        })
    }
  }

  if (currentSecondsValue >= selAudio.end_time) {
    aud.currentTime = Math.round((clipStart / 1000) * FLOAT_RATE) / FLOAT_RATE
    aud.pause()

    return
  }

  if (currentSecondsValue === 0 || currentSecondsValue - startTime <= 0) {
    aud.currentTime = Math.round((clipStart / 1000) * FLOAT_RATE) / FLOAT_RATE
  }

  let curTime =
    Math.round(((currentSecondsValue - startTime) / 1000) * FLOAT_RATE) / FLOAT_RATE

  curTime += Math.round((clipStart / 1000) * FLOAT_RATE) / FLOAT_RATE

  if (!aud.paused && !aud.ended && aud.readyState > 2) {
    aud.pause()
  }

  if (curTime > aud.duration) {
    aud.currentTime = curTime - aud.duration
  } else {
    aud.currentTime = curTime
  }

  aud.pause()
}

export function pauseTimeline() {
  const { previewTimeLine, project, tweenObjectBuilder, timeSlider, setPlayCompleted, setPlaying, scenePlaying } = this.props
  if(!scenePlaying) return
  setPlaying(false)
  setPlayCompleted(true)

  if (previewTimeLine) {
    previewTimeLine.pause()
    SetPreviewTimeline(null)
    const tweenObject = tweenObjectBuilder
      .withTimeSlider(timeSlider)
      .withHTMLAssets(VIDEO.code, this.sceneItems)
      .withHTMLAssets(TEXT.code, this.sceneTexts)
      .withHTMLAssets(AUDIO.code, this.sceneSounds)
      .withHTMLAssets(MUSIC.code, this.sceneMusics)
      .withHTMLAssets(VOICEOVER.code, this.sceneVoiceovers)
      .build(project)
    TimelineSet.createEndInTimeline(tweenObject.previewTimeLine, project.duration / ONE_SECOND)
    SetPreviewTimeline(tweenObject.previewTimeLine)

    if (project) {
      if (
        project.scenes[project.resolution] &&
        project.scenes[project.resolution].length
      ) {
        project.scenes[project.resolution].map((scene, s) => {
          let vid = this.sceneVideos[s]
          if (vid && scene.type === 'video') {
            vid.pause()
          }

          return scene
        })
      }

      if (project.music && project.music.length) {
        project.music.map((m, i) => {
          if (this.sceneMusics[i]) {
            this.sceneMusics[i].pause()
          }

          return m
        })
      }

      if (project.sound && project.sound.length) {
        project.sound.map((m, i) => {
          if (this.sceneSounds[i]) {
            this.sceneSounds[i].pause()
          }

          return m
        })
      }
    }
  }
}

/**
 * toggle preview stage
 */
export function toggleShowStage(flag) {
  this.setState({
    showStage: flag,
  })
}

export function updateSlider() {
  const { scenePlaying, previewTimeLine } = this.props

  this.setState({
    selectedText: null,
    selectedTextIndex: -1,
  })

  if (scenePlaying === true && previewTimeLine) {
    let currentProgress = previewTimeLine.progress()
    const secondsToBePlayed = currentProgress * SLIDER_PRO
    this.handleProgressChange(secondsToBePlayed)
  }
}

/**
 * complete preview playing
 */
export function completePreview() {
  const {
    togglePlay,
    project,
    previewTimeLine,
    setSelectedSceneIndex,
    setSelectedScene,
  } = this.props

  this.props.setPlayCompleted(true)
  togglePlay(false)

  this.handleProgressChange(0)
  setSelectedSceneIndex(project.scenes[project.resolution].length ? 0 : -1)
  setSelectedScene(project.scenes[project.resolution][0])

  if (previewTimeLine) {
    this.pauseTimeline()

    previewTimeLine.pause()
  }

  setTimeout(() => {
    this.SetPreviewStage()
  }, 200)
}

export function SetPreviewStage() {
  if (!this.tweenWrapper) {
    return
  }

  const { project, timeSlider, scenePlaying, SetPreviewTimeline } = this.props

  const onUpdate = this.updateSlider
  const onComplete = this.completePreview
  const tweenWrapper = this.tweenWrapper
  const htmlElements = {
    [VIDEO.code]: this.sceneItems,
    [TEXT.code]: this.sceneTexts,
    [AUDIO.code]: this.sceneSounds,
    [VOICEOVER.code]: this.sceneVoiceovers,
    [MUSIC.code]: this.sceneMusics,
  }
  const tweenObject = TimelineService.createPreviewTimeline(
    project,
    timeSlider,
    scenePlaying,
    onUpdate,
    onComplete,
    tweenWrapper,
    htmlElements)
  SetPreviewTimeline(tweenObject.previewTimeLine)
}

export function setTextItem(text, i) {
  const { setSelectedText, setSelectedTextIndex, toggleShowTextPanel, checkAddItem } =
    this.props

  toggleShowTextPanel(true)

  setSelectedText(text)
  setSelectedTextIndex(i)

  checkAddItem('text')

  this.setState({
    selectedText: text,
    selectedTextIndex: i,
  })
}

export function setProjectVal(selectedText, i) {
  const {
    setSelectedText,
    setSelectedTextIndex,
    project,
    updateProject,
    // toggleWatchProject,
  } = this.props

  let projectVal = JSON.parse(JSON.stringify(project))
  projectVal.texts[projectVal.resolution][i] = selectedText

  this.setState({
    selectedText: selectedText,
    selectedTextIndex: i,
    textClips: projectVal.texts[projectVal.resolution],
  })

  setSelectedText(selectedText)
  setSelectedTextIndex(i)

  updateProject(projectVal)
}

export function onTextResizing(w, h, i) {
  this.sceneTexts[i].style.width = `${w}px`
  this.sceneTexts[i].style.height = `${h}px`
}

export function onTextResizeEnd(width, height, textItemIndex, fontSize = 26, update = true, textObj) {
  const PADDING_LEFT = 0.025
  const PADDING_RIGHT = 0.025
  const paddingOffset = 1 - PADDING_LEFT - PADDING_RIGHT

  const currentWrapper = this.textWrapper?.current
  const stageBoundingBox = currentWrapper?.parentElement?.getBoundingClientRect()

  if (update) {
    const widthRatio = this.props.selectedText.p_width / stageBoundingBox.width
    const heightRatio = this.props.selectedText.p_height / stageBoundingBox.height
    this.props.storeProjectSteps({
      type: 'UPDATE',
      channel: ['text'],
      payload: [{
        width: this.props.selectedText.width,
        height: this.props.selectedText.height,
        fontSize: this.props.selectedText.fontSize,
        id: this.props.selectedText.id
      }],
    })
    this.props.selectedText.width = width * widthRatio
    this.props.selectedText.height = height * heightRatio

    const averageRatio = (heightRatio + widthRatio) / 2

    this.props.selectedText.fontSize = Math.round((fontSize * averageRatio) * paddingOffset)
    this.props.toggleShowTextPanel(false)
    this.setProjectVal(this.props.selectedText, textItemIndex)
  }
  // Update project texts on mount to the DOM
  if (textObj) {
    const cloneTextObj = _.cloneDeep(textObj)

    // Get widthRatio and heightRatio
    const widthRatio = textObj.width / width
    const heightRatio = textObj.height / height

    const averageRatio = (heightRatio + widthRatio) / 2

    cloneTextObj.fontSize = Math.round((fontSize * averageRatio) * paddingOffset)

    if (textObj.fontSize !== cloneTextObj.fontSize) {
      let projectVal = JSON.parse(JSON.stringify(_.cloneDeep(this.props.project)))
      projectVal.texts[projectVal.resolution][textItemIndex] = cloneTextObj

      this.props.updateProject(projectVal)
    }
  }
}

export function onTextDrag(top, left, i) {
  this.sceneTexts[i].style.top = `${top}px`
  this.sceneTexts[i].style.left = `${left}px`
}

export function onTextDragEnd(top, left, i) {
  const stageBoundingBox = this.textWrapper?.current?.parentElement?.getBoundingClientRect()
  const widthRatio = this.props.selectedText.p_width / stageBoundingBox.width
  const heightRatio = this.props.selectedText.p_height / stageBoundingBox.height
  this.props.storeProjectSteps({
    type: 'UPDATE',
    channel: ['text'],
    payload: [{
      top: this.props.selectedText.top,
      left: this.props.selectedText.left,
      id: this.props.selectedText.id
    }],
  })
  this.props.selectedText.top = top * heightRatio
  this.props.selectedText.left = left * widthRatio
  this.props.toggleShowTextPanel(false)



  this.setProjectVal(this.props.selectedText, i)
}

const playbackElements = (sceneElements, playback) => {
  return (projectElements, currentSecondsValue, scenePlaying, floatRate) => {
    projectElements.map((m, index) => {
      const currentSceneElement = sceneElements[index]
      const isTimelineStarted = currentSecondsValue >= m.start_time
      const isTimelineUnfinished = currentSecondsValue < m.end_time
      const isBeyondTimeline = isTimelineStarted && isTimelineUnfinished
      const isCurrentElementPlayable = currentSceneElement && !_.isNaN(currentSceneElement.duration)

      if (isCurrentElementPlayable && isBeyondTimeline && !scenePlaying) {
        playback(index, m.start_time, m?.clip?.start || 0, currentSecondsValue)
      }

      const isCurrentElementStopped =
        currentSceneElement &&
        (currentSceneElement.paused || currentSceneElement.ended)
      if (isBeyondTimeline && scenePlaying && isCurrentElementStopped) {
        let curTime =
          Math.round(
            ((currentSecondsValue - m.start_time) / 1000) * floatRate,
          ) / floatRate

        curTime += Math.round((m.clip.start / 1000) * floatRate) / floatRate

        currentSceneElement.currentTime = curTime

        if (isCurrentElementPlayable) {
          currentSceneElement
            .play()
            .then(() => { })
            .catch((e) => {
              console.log('currentSceneElement play error:', e)
            })
        }
      }

      if (isCurrentElementPlayable && !isBeyondTimeline && sceneElements[index]) {
        currentSceneElement.currentTime =
          Math.round((m.clip.start / 1000) * floatRate) / floatRate
        currentSceneElement.pause()
      }

      return m
    })
  }
}
