import { PIXELS } from '../constants/Resolutions'
import {
  DEFAULT_PROJECT,
  DEFAULT_PROJECT_NAME,
} from '../constants/TimelineItems'
import instance from '../utils/Axios'

/**
 * get library files
 * @param authUser
 * @returns {Promise<unknown>}
 */
export const getLibraryFiles = (authUser) => {
  return new Promise((resolve, reject) => {
    instance
      .post('/user-libraries/get-files', authUser)
      .then((res) => {
        resolve(res)
      })
      .catch((err) => reject(err))
  })
}

/**
 * list projects
 * @returns {Promise<unknown>}
 */
export const listProjects = () => {
  return new Promise((resolve, reject) => {
    instance
      .get('/projects/getall')
      .then((res) => {
        resolve(res)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

/**
 * Get project by ID
 * @param project_key
 * @returns {Promise<unknown>}
 */
export const getProject = (project_key) => {
  return new Promise((resolve, reject) => {
    instance
      .get(`/projects/show?project_key=${project_key}`)
      .then((res) => {
        resolve(res)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

/**
 * Create a new project
 * @param project
 * @returns {Promise<unknown>}
 */
export const createProject = async (
  project,
  userId,
  projectName = DEFAULT_PROJECT_NAME,
  dimension = PIXELS.UHD,
) => {
  const projectParameters = {
    ...project,
    userId,
    projectName: projectName || DEFAULT_PROJECT_NAME,
    dimension: dimension || PIXELS.UHD,
    type: project.type || DEFAULT_PROJECT.type,
  }

  return await instance.post('/projects/store', projectParameters)
}

/**
 * Update project to database
 * @param project
 * @returns {Promise<unknown>}
 */
export const updateProject = async (id, project) => {
  try {
    const response = await instance.put(`/projects/update/${id}`, project)
    const updatedProject = response?.data
    return updatedProject
  } catch (error) {
    console.log(error)
    throw error
  }
}

/**
 * rename project
 * @param id
 * @param name
 * @returns {Promise<unknown>}
 */
export const renameProject = async (id, name) => {
  try {
    const response = await instance.put('/projects/rename', {
      id,
      name,
    })
    return response?.data
  } catch (error) {
    console.log(error)
    throw error
  }
}

/**
 * merge project
 * @param editorProjectId
 * @param selectedProjectId
 * @returns {Promise<unknown>}
 */
export const mergeProject = async (editorProjectId, selectedProjectId) => {
  try {
    const response = await instance.post('/projects/merge', {
      editorProjectId,
      selectedProjectId,
    })
    return response?.data
  } catch (error) {
    console.log(error)
    throw error
  }
}

/**
 * download project
 * @param projectId
 * @param name
 * @returns {Promise<unknown>}
 */
export const downloadProject = async (id, files) => {
  try {
    const response = await instance.post('/projects/download', {
      id,
      files,
    })
    if (response.data.zipFilePath) {
      const link = document.createElement('a')
      link.href = response.data.zipFilePath
      link.setAttribute('_target', 'blank')
      link.setAttribute('download', `project-${id}.zip`)

      // // Append to html link element page
      document.body.appendChild(link)

      // // Start download
      link.click()

      // // Clean up and remove the link
      link.parentNode.removeChild(link)
    }
  } catch (error) {
    console.log(error)
    throw error
  }
}
/**
 * delete project
 * @param id
 * @returns {Promise<unknown>}
 */
export const deleteProject = async (id) => {
  try {
    const response = await instance.delete(`/projects/${id}`)
    return response?.data
  } catch (error) {
    console.log(error)
    throw error
  }
}

/**
 * get project details to execute rendering script
 * @param project_key
 * @param ratio
 * @returns {Promise<unknown>}
 */
export const getProjectDetail = (project_key, ratio) => {
  return new Promise((resolve, reject) => {
    instance
      .get(`/projects/render-data?project_key=${project_key}&ratio=${ratio}`)
      .then((res) => {
        resolve(res)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export default {
  createProject,
  updateProject,
  getProjectDetail,
  getProject,
  renameProject,
}
