import React from 'react'
import _ from 'lodash'
import moment from 'moment'
import { toast } from 'react-toastify'
import { isIOS } from 'react-device-detect'
import { CAMERA_VIEW } from '../../constants/Routes'
import { CROPPER } from '../../constants/Routes'
import { ASSET_LIBRARY } from '../../constants/AssetLibraries'
import LibraryAssetUploader from '../../services/library-assets/LibraryAssetUploader'
import { DEFAULT_PROJECT } from '../../constants/TimelineItems'
import ProjectEditService from '../../services/ProjectEditService'
import DurationGetter from '../../services/time/DurationGetter'
import { updateTransitions } from '../editor/services'

const FILE_UPLOAD_SIZE_LIMIT = 2123138000

export function onCameraOpen() {
  if (
    this.props.publication === 'Advanced' ||
    this.props.location.state?.preRoute === 'editor' ||
    isIOS
  ) {
    this.props.history.push(`/editor/${this.props.project?.id || 0}`)
  } else {
    this.props.history.push(CAMERA_VIEW)
  }
}

export function goToEditor() {
  this.props.history.push('/editor/0')
}

export function onNextCallback(current) {
  if (current === 2) {
    this.props.setBoardingStatus(true)
  }
}

export function onUploadingStatus(flag) {
  this.setState({
    uploadingFile: flag,
  })
}

/**
 * Handle file selected by input tag
 * @param e
 */
export async function handleFileSelected(e, videoFiles = []) {
  const { acceptList, assetsLibs, selAcceptType } = this.state
  const { authUser, updateProject } = this.props
  const acceptFileTypes = acceptList[selAcceptType].replace('*', '').split(',')
  const folderPath = assetsLibs[selAcceptType]
  let files
  if (videoFiles.length) {
    files = videoFiles
  } else {
    files = e.target.files || e.dataTransfer.files
  }
  let project = this.props.project || DEFAULT_PROJECT
  const durationToStart = DurationGetter.getMaxDurationByAssets(
    project[folderPath],
  )
  const authorId = authUser.id
  try {
    this.props.onUploadingStatus(true)
    if (_.isEmpty(files)) {
      return
    }
    const file = _.first(files)
    if (file.size > FILE_UPLOAD_SIZE_LIMIT) {
      return toast.error(
        'Whoa! That is a huge file size. It exceeds our max 2 GB limit. Try something smaller.',
      )
    }
    const isValid = acceptFileTypes.some((type) => file.type.includes(type))
    if (!isValid) {
      return toast.error('Please, upload the selected media type')
    }
    const fileName = file.name
    // const fileNameUrl = `${moment().unix()}-${file.name}`

    const fileExt = fileName.substring(fileName.lastIndexOf('.'))
    const fileNameUrl = `${moment().format('x')}${fileExt}`

    const { segmenting, ...libraryAsset } =
      await LibraryAssetUploader.saveAsLibraryAsset(
        file,
        fileNameUrl,
        folderPath,
        authorId,
        '',
        {},
        false,
        this.state.segementationChecked,
      )
    if (segmenting) {
      return
    }

    if (folderPath !== ASSET_LIBRARY.MEDIA.folderPath) {
      project = ProjectEditService.addLibraryAssetToProject(
        libraryAsset,
        project,
        durationToStart,
        authorId,
      )
      updateProject && updateProject(project)
      updateTransitions(project, updateProject)
      this.props.history.push(`/editor/${project._id || 0}`)
    }

    this.props.onUploadingStatus(false)
    if (folderPath === ASSET_LIBRARY.MEDIA.folderPath) {
      this.props.history.push({
        pathname: CROPPER,
        state: {
          videoUrl: libraryAsset.fileUrl,
          videoId: libraryAsset._id,
          videoDuration: libraryAsset.duration,
        },
      })
    }
  } catch (error) {
    toast.error(error.message)
    this.props.onUploadingStatus(false)
  }
}

/**
 * File input
 * @returns
 */
export function inputWrapper() {
  const { acceptList, selAcceptType } = this.state

  const acceptFileType = acceptList[selAcceptType]

  return (
    <>
      <input
        ref={this.uploaderInput}
        key="uploaderInput"
        type="file"
        accept={acceptFileType}
        style={{ display: 'none' }}
        // multiple={isIOS}
        onChange={this.handleFileSelected}
      />
    </>
  )
}
