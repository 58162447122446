import React from 'react'
import { ReactMediaRecorder } from 'react-media-recorder'
import * as VoiceoverActions from '../../redux/actions/VoiceoverActions'
import { connect } from 'react-redux'
import RecordButton from '../video-capture/desktop-items/RecordButton'

class VoiceRecordButton extends React.Component {
  handleStartRecording = (nativeStartRecording, recorderProps) => {
    const { onStartRecording } = this.props
    onStartRecording && onStartRecording(recorderProps.mediaBlobUrl)
    nativeStartRecording && nativeStartRecording()
  }

  handleStopRecording = (blobUrl, blob) => {
    const { onStopRecording } = this.props
    onStopRecording && onStopRecording(blobUrl, blob)
  }

  renderRecorder = (recorderProps) => {
    const { disabled } = this.props
    const { status, startRecording, stopRecording } = recorderProps
    return (
      <RecordButton
        ref={this.props.recordButton}
        disabled={disabled}
        isRecording={status === 'recording'}
        showRecord
        onStartRecording={() =>
          this.handleStartRecording(startRecording, recorderProps)
        }
        onStopRecording={stopRecording}
      />
    )
  }

  render() {
    return (
      <div className={'flex-shrink'}>
        <ReactMediaRecorder
          audio
          onStop={this.handleStopRecording}
          render={(recorderProps) => this.renderRecorder(recorderProps)}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  recordedAudio: state.voiceover.recordedAudio,
})

const mapDispatchToProps = (dispatch) => ({
  setRecordedAudio: (audio) =>
    dispatch(VoiceoverActions.setRecordedAudio(audio)),
})

export default connect(mapStateToProps, mapDispatchToProps)(VoiceRecordButton)
