import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ActionMenuBar from '../../sidebar'
import { connect } from 'react-redux'
import ReplayActions from './replay-actions'
import TapSnapPointNotify from '../../modals/TapSnapPointNotify'
import * as ProjectEditActions from '../../../redux/actions/ProjectEditActions'
import * as RecorderActions from '../../../redux/actions/RecorderActions'
import * as SnapGridBoxActions from '../../../redux/actions/SnapGridBoxActions'

class MobileRecordActions extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showTapNotify: false,
      showSnap: true,
    }

    this.mounted = false
  }

  componentDidMount() {
    this.mounted = true
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.mounted &&
      this.props.isLoadedReplayVideo &&
      this.props.isReplayingVideo &&
      this.props.replayVideo &&
      this.props.replayVideo !== prevProps.replayVideo
    ) {
      if (!this.props.snapGridPosition.set) {
        this.setState({
          showTapNotify: true,
        })
      }
    }
  }

  componentWillUnmount() {
    this.mounted = false
  }

  handleOpenVideoInput = () => {
    this.props.onOpenVideoInput()
  }

  closeNotifyModal = () => {
    this.setState({
      showTapNotify: false,
    })
  }

  toggleSnapGrid = (r) => {
    this.setState({
      showSnap: r,
    })
  }

  render() {
    const {
      replayVideo,
      isLoadedReplayVideo,
      isReplayingVideo,
      onUploadRecordVideo,
      isUploadingVideo,
      isUploadedVideo,
      MediaStream,
      setScaleValue,
      selectRatio,
      selectedRatio,
      resetSelectedAspectRatios,
      setDefaultLayout,
      setSelectedMediaLayout,
      setSaveRatioLayout,
      setMediaStreamSize,
      setMediaOriginSize,
      setReviewScreenSize,
      setVideoAspectSize,
    } = this.props

    const { showTapNotify, showSnap } = this.state

    return (
      <div className="absolute w-full h-full z-50 top-0 left-0">
        {showTapNotify && (
          <TapSnapPointNotify closeNotifyModal={this.closeNotifyModal} />
        )}

        {/*menu bar*/}
        <ActionMenuBar toggleSnapGrid={this.toggleSnapGrid} MenuPage="record" />

        <ReplayActions
          replayVideo={replayVideo}
          cameraVideo={replayVideo}
          MediaStream={MediaStream}
          onOpenVideoInput={this.handleOpenVideoInput}
          isLoadedReplayVideo={isLoadedReplayVideo}
          isReplayingVideo={isReplayingVideo}
          onUploadRecordVideo={onUploadRecordVideo}
          isUploadingVideo={isUploadingVideo}
          isUploadedVideo={isUploadedVideo}
          showSnap={showSnap}
          setScaleValue={setScaleValue}
          selNextPopup={this.props.selNextPopup}
          moveRef={this.props.moveRef}
          videoActionWrapper={this.props.videoActionWrapper}
          selectRatio={selectRatio}
          selectedRatio={selectedRatio}
          resetSelectedAspectRatios={resetSelectedAspectRatios}
          setDefaultLayout={setDefaultLayout}
          setSelectedMediaLayout={setSelectedMediaLayout}
          setSaveRatioLayout={setSaveRatioLayout}
          setMediaStreamSize={setMediaStreamSize}
          setMediaOriginSize={setMediaOriginSize}
          setReviewScreenSize={setReviewScreenSize}
          setVideoAspectSize={setVideoAspectSize}
          layoutChangedFromDropDown={this.props.layoutChangedFromDropDown}
          changeLayoutFromDropdown={this.props.changeLayoutFromDropdown}
          aspectSize={this.props.aspectSize}
        />
      </div>
    )
  }
}

MobileRecordActions.propTypes = {
  MediaStream: PropTypes.any,
  replayVideo: PropTypes.any,
  onOpenVideoInput: PropTypes.func,
  isLoadedReplayVideo: PropTypes.bool,
  isReplayingVideo: PropTypes.bool,
  onUploadRecordVideo: PropTypes.func,
  setScaleValue: PropTypes.func,
  isUploadingVideo: PropTypes.bool,
  isUploadedVideo: PropTypes.bool,
}

const mapStateToProps = (state) => ({
  snapGridPosition: state.recorder.snapGridPosition,
  aspectSize: state.recorder.aspectSize,
})

const mapDispatchProps = (dispatch) => ({
  setDefaultLayout: (...args) =>
    dispatch(ProjectEditActions.setDefaultLayout(...args)),
  setSelectedMediaLayout: (...args) =>
    dispatch(RecorderActions.setSelectedMediaLayout(...args)),
  setSaveRatioLayout: (...args) =>
    dispatch(RecorderActions.setSaveRatioLayout(...args)),
  setMediaStreamSize: (...args) =>
    dispatch(SnapGridBoxActions.setMediaStreamSize(...args)),
  setMediaOriginSize: (...args) =>
    dispatch(SnapGridBoxActions.setMediaOriginSize(...args)),
  setReviewScreenSize: (...args) =>
    dispatch(SnapGridBoxActions.setReviewScreenSize(...args)),
  setVideoAspectSize: (...args) =>
    dispatch(SnapGridBoxActions.setVideoAspectSize(...args)),
})

export default connect(mapStateToProps, mapDispatchProps)(MobileRecordActions)
