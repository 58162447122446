import { useSelector } from 'react-redux'
import ProductsFeatures from './ProductsFeatures'
import { PaymentConstants } from '../../constants/Payments'

const ChooseLicense = ({
  activeProduct,
  changeActiveProduct,
  paymentTypeText,
  show,
  subscriptionType,
  referredByAffiliate,
  pageOn,
  regionProductPro,
  regionProductStudio,
}) => {
  const {
    auth: { user },
  } = useSelector((state) => state)

  return (
    <>
      <div
        className="cerebriam-order cerebriam-container"
        style={{
          display: show ? 'block' : 'none',
          paddingTop: 0,
          paddingBottom: 0,
        }}>
        <h2>
          {subscriptionType === PaymentConstants.subscriptionProduct.studio
            ? 'Upgrade'
            : 'Choose'}{' '}
          a {referredByAffiliate ? 'discounted' : ''} license
        </h2>
        <h1>Contact us to pay the difference for an upgrade.</h1>
      </div>
      <div
        className="cerebriam-details cerebriam-container"
        style={{ display: show ? 'block' : 'none' }}>
        <ProductsFeatures
          activeProduct={activeProduct}
          changeActiveProduct={changeActiveProduct}
          paymentTypeText={paymentTypeText}
          subscriptionType={subscriptionType}
          referredByAffiliate={referredByAffiliate}
          user={user}
          regionProductPro={regionProductPro}
          regionProductStudio={regionProductStudio}
          show={show}
          pageOn={pageOn}
        />
      </div>
    </>
  )
}

export default ChooseLicense
