import _ from 'lodash'
import instance from '../utils/Axios'

/**
 * publish specific video
 * @param data
 * @returns {Promise<unknown>}
 */
export const publishVideo = async (projectId, videoOutput) => {
  try {
    return await instance.post(`projects/render/video/${projectId}`, {
      format: videoOutput,
    })
  } catch (error) {
    const errorMessage = error.response?.data?.message || error?.message
    console.log(errorMessage)
    throw new Error(errorMessage)
  }
}

/**
 * publish all videos
 * @param data
 * @returns {Promise<unknown>}
 */
export const publishVideos = async (projectId, videoOutputs) => {
  try {
    return await instance.post(`projects/render/all/${projectId}`, {
      videoOutputs,
    })
  } catch (error) {
    const errorMessage = error.response?.data?.message || error?.message
    console.log(errorMessage)
    throw new Error(errorMessage)
  }
}

export default {
  publishVideo,
  publishVideos,
}
