import _ from 'lodash'

export function addScenesData(project, action, layout) {
  return {
    ...project,
    scenes: project.scenes.map((item, index) => {
      if (index !== layout) {
        return item
      }

      return item.splice(action.s_index, 0, action.scene)
    }),
  }
}

export function addTextsData(project, action, layout) {
  return {
    ...project,
    texts: project.texts.map((item, index) => {
      if (index !== layout) {
        return item
      }

      return item.splice(action.t_index, 0, action.texts)
    }),
  }
}

export function addSoundData(project, action) {
  return {
    ...project,
    sound: project.sound.map((item, index) => {
      return item.splice(action.a_index, 0, action.sounds)
    }),
  }
}

export function addMusicData(project, action) {
  return {
    ...project,
    music: project.music.map((item, index) => {
      return item.splice(action.m_index, 0, action.music)
    }),
  }
}
