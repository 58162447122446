import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'
import { loginUser, removeAuthErrors } from '../../redux/actions/AuthActions'
import {
  HOME,
  PASSWORD_FORGET,
  SIGN_IN,
  SUBSCRIBE,
} from '../../constants/Routes'
import { SignUpLink } from './SignUp'
import AuthFooter from './AuthFooter'
import NavigationHeader from '../navigation/NavigationHeader'
import {
  IsReferredUserNeverPaid,
  triggerGoogleAnalyticsEvent,
} from '../../utils/Helper'

const SignInPage = () => (
  <>
    <div className={'container mx-auto signInWraper'}>
      <NavigationHeader />
      <div className="w-full max-w-xs signInContent">
        {/* <NavigationHeader /> */}
        <SignInForm />

        <SignUpLink />
      </div>
    </div>
    <AuthFooter />
  </>
)

class SignInFormBase extends Component {
  constructor(props) {
    super(props)

    this.state = {
      email: '',
      password: '',
      errors: {},
    }
  }

  componentDidMount() {
    const { auth, mySubscription } = this.props
    // If logged in and user navigates to Login page, should redirect them to dashboard
    // If referred users are trying to access the login page, redirect them to the referral page
    if (auth?.isAuthenticated && mySubscription) {
      const referredByAffiliate = IsReferredUserNeverPaid(auth, mySubscription)
      if (referredByAffiliate) {
        this.props.history.push(SUBSCRIBE)
      } else {
        this.props.history.push(HOME)
      }
    }
  }

  componentDidUpdate() {
    const loginError = this.props.auth.loginError
    if (loginError) {
      toast.dismiss()
      toast.error(loginError)

      this.props.removeAuthErrors()
    }
  }

  static getDerivedStateFromProps(nextProps, state) {
    const { auth, mySubscription } = nextProps
    if (auth?.isAuthenticated && mySubscription) {
      const referredByAffiliate = IsReferredUserNeverPaid(auth, mySubscription)
      if (referredByAffiliate) {
        nextProps.history.push(SUBSCRIBE)
      } else {
        nextProps.history.push(HOME)
      }
    }

    return null
  }

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value })
  }

  onSubmit = (e) => {
    e.preventDefault()
    triggerGoogleAnalyticsEvent('login', { method: 'Form' })

    const userData = {
      email: this.state.email,
      password: this.state.password,
    }

    this.props.loginUser(userData)
  }

  render() {
    const { email, password, errors } = this.state

    const isInvalid = password === '' || email === ''

    return (
      <form
        noValidate
        onSubmit={this.onSubmit}
        className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Username
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="username"
            type="email"
            value={email}
            error={errors.email}
            name="email"
            onChange={this.onChange}
            placeholder="Email Address"
          />
        </div>

        <div className="mb-6">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Password
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
            id="password"
            type="password"
            name="password"
            placeholder="password"
            value={password}
            error={errors.password}
            onChange={this.onChange}
          />
        </div>

        <div className="flex items-center justify-between">
          <button
            className={
              (isInvalid
                ? 'bg-gray-500 cursor-not-allowed '
                : 'bg-blue-500 hover:bg-blue-700 ') +
              'text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'
            }
            disabled={isInvalid}
            type="submit">
            Sign In
          </button>
          <Link
            to={PASSWORD_FORGET}
            className="inline-block align-baseline font-bold text-sm text-blue-500 hover:text-blue-800">
            Forgot Password?
          </Link>
        </div>
      </form>
    )
  }
}

// eslint-disable-next-line no-lone-blocks
{
  /* <PasswordForgetLink /> */
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  mySubscription: state.subscriptions.user,
  products: state.subscriptions.products,
})

const SignInForm = connect(mapStateToProps, { loginUser, removeAuthErrors })(
  withRouter(SignInFormBase),
)

const SignInLink = () => (
  <p className="text-center w-full">
    Do have an account?{' '}
    <Link
      className="inline-block align-baseline font-bold text-sm text-blue-500 hover:text-blue-800"
      to={SIGN_IN}>
      Sign In
    </Link>
  </p>
)

export default SignInPage

export { SignInForm, SignInLink }
