export const RATIO = {
  LANDSCAPE: 0,
  FULL_VERTICAL: 1,
  SEMI_VERTICAL: 2,
  SQUARE: 3,
}

export const ASPECT_RATIO = {
  [RATIO.LANDSCAPE]: '16/9',
  [RATIO.SQUARE]: '1/1',
  [RATIO.SEMI_VERTICAL]: '4/5',
  [RATIO.FULL_VERTICAL]: '9/16',
}

export const ASPECT_RATIO_DETAILS = {
  [RATIO.LANDSCAPE]: {
    ratio: RATIO.LANDSCAPE,
    aspectRatio: ASPECT_RATIO[RATIO.LANDSCAPE],
    name: 'landscape',
    css: 'landscape-box',
  },
  [RATIO.SQUARE]: {
    ratio: RATIO.SQUARE,
    aspectRatio: ASPECT_RATIO[RATIO.SQUARE],
    name: 'square',
    css: 'square-box',
  },
  [RATIO.SEMI_VERTICAL]: {
    ratio: RATIO.SEMI_VERTICAL,
    aspectRatio: ASPECT_RATIO[RATIO.SEMI_VERTICAL],
    name: 'semi vertical',
    css: 'portrait-box',
  },
  [RATIO.FULL_VERTICAL]: {
    ratio: RATIO.FULL_VERTICAL,
    aspectRatio: ASPECT_RATIO[RATIO.FULL_VERTICAL],
    name: 'full vertical',
    css: 'vertical-box',
  },
}

export const SCALE_VALUE = {
  MEDIUM: 'medium',
  LONG: 'long',
  SCALE_DOWN: 'scale-down',
  CONTAIN: 'contain',
}

export const ASPECT_RATIO_VIDEO_SUFFIXES = ['_0.', '_1.', '_2.', '_3.']

export const RATIOS = [
  {
    id: RATIO.LANDSCAPE,
    name: 'landscape',
    ratio: '16:9',
  },
  {
    id: RATIO.FULL_VERTICAL,
    name: 'full-vertical',
    ratio: '9:16',
  },
  {
    id: RATIO.SEMI_VERTICAL,
    name: 'semi-vertical',
    ratio: '4:5',
  },
  {
    id: RATIO.SQUARE,
    name: 'square',
    ratio: '1:1',
  },
]

export default RATIO
