import {
  SET_SUBSCRIPTION_PRODUCTS,
  SET_USER_SUBSCRIPTION,
  SET_PRODUCT_FEATURES,
  SET_USER_SUBSCRIPTION_LOADING,
  SIGNOUT_USER,
} from '../actions/Types'

const initialState = {
  user: null,
  products: [],
  loading: false,
}

const SubscriptionReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_SUBSCRIPTION:
      return {
        ...state,
        user: action.payload,
      }
    case SET_USER_SUBSCRIPTION_LOADING:
      return {
        ...state,
        loading: action.payload,
      }
    case SET_SUBSCRIPTION_PRODUCTS:
      return {
        ...state,
        products: action.payload,
      }
    case SET_PRODUCT_FEATURES:
      return {
        ...state,
        features: action.payload,
      }
    case SIGNOUT_USER:
      return initialState
    default:
      return state
  }
}

export default SubscriptionReducer
