import {
  CLEAR_AUTH_ERRORS,
  GET_SIGNIN_ERRORS,
  GET_SIGNUP_ERRORS,
  SET_CURRENT_USER,
  SIGNIN_LOADING,
  SIGNUP_LOADING,
  UPDATE_BOARDING,
  SET_COUPON,
  SET_USER_SUBSCRIPTION_FEATURES,
} from '../../redux/actions/Types'
import { isEmpty } from 'lodash'
import { triggerGoogleAnalyticsEvent } from '../../utils/Helper'

const initialState = {
  isAuthenticated: false,
  user: {},
  onboarding: false,
  loginError: null,
  registerError: null,
  signUpLoading: false,
  signInLoading: false,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_USER_SUBSCRIPTION_FEATURES:
      return {
        ...state,
        user: {
          ...state.user,
          hasFeatures: action.payload,
        },
      }
    case SET_CURRENT_USER:
      return {
        ...state,
        signUpLoading: false,
        signInLoading: false,
        isAuthenticated: !isEmpty(action.payload),
        onboarding: action.payload.onboarding,
        user: action.payload,
      }
    case UPDATE_BOARDING:
      localStorage.setItem('cerebriam_onboarding', action.payload)
      return {
        ...state,
        onboarding: action.payload,
      }
    case GET_SIGNIN_ERRORS:
      triggerGoogleAnalyticsEvent('exception', {
        description:
          action.payload?.passwordincorrect ||
          action.payload?.emailnotfound ||
          'Error signing in',
      })
      return {
        ...state,
        signInLoading: false,
        loginError:
          action.payload?.passwordincorrect ||
          action.payload?.emailnotfound ||
          'Error signing in',
      }
    case GET_SIGNUP_ERRORS:
      triggerGoogleAnalyticsEvent('exception', {
        description: action.payload?.email || 'Error signing up',
      })
      return {
        ...state,
        signUpLoading: false,
        registerError: action.payload?.email || 'Error signing up',
      }
    case CLEAR_AUTH_ERRORS:
      return {
        ...state,
        loginError: null,
        registerError: null,
      }
    case SIGNUP_LOADING:
      return {
        ...state,
        signUpLoading: true,
      }
    case SIGNIN_LOADING:
      return {
        ...state,
        signInLoading: true,
      }
    case SET_COUPON:
      return {
        ...state,
        coupon: action.coupon,
        isCoupon: true,
      }
    default:
      return state
  }
}
