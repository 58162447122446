import _ from 'lodash'
import RATIO, { ASPECT_RATIO_DETAILS } from '../../constants/AspectRatios'
import { TEXT, VIDEO } from '../../constants/Channels'

const reorderItemsByStartTime = (items) => {
  const sortedItems = _.sortBy(items, (item) => {
    return item.start_time
  })
  const reorderedItems = _.map(sortedItems, (item, order) => ({
    ...item,
    order,
  }))
  return reorderedItems
}

const getUpdatedSelectLayout = (
  project,
  projecPaths = [VIDEO.projectPath, TEXT.projectPath],
) => {
  const updatedSelectLayout = _.reduce(
    ASPECT_RATIO_DETAILS,
    (layoutResult, ratio, ratioCode) => {
      const items = _.flatMap(projecPaths, (path) => {
        return project[path][ratioCode]
      })
      layoutResult[ratioCode] = items.length > 0
      return layoutResult
    },
    {},
  )

  const selectLayoutList = _.map(updatedSelectLayout)
  return _.some(selectLayoutList)
    ? selectLayoutList
    : _.set(selectLayoutList, RATIO.LANDSCAPE, true)
}

export default {
  reorderItemsByStartTime,
  getUpdatedSelectLayout,
}
