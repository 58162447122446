export const ReviewLinkedinIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 5.36667C0 3.46836 0 2.51921 0.375289 1.79675C0.69154 1.18794 1.18794 0.69154 1.79675 0.375289C2.51921 0 3.46836 0 5.36667 0H10.6333C12.5316 0 13.4808 0 14.2032 0.375289C14.8121 0.69154 15.3085 1.18794 15.6247 1.79675C16 2.51921 16 3.46836 16 5.36667V10.6333C16 12.5316 16 13.4808 15.6247 14.2032C15.3085 14.8121 14.8121 15.3085 14.2032 15.6247C13.4808 16 12.5316 16 10.6333 16H5.36667C3.46836 16 2.51921 16 1.79675 15.6247C1.18794 15.3085 0.69154 14.8121 0.375289 14.2032C0 13.4808 0 12.5316 0 10.6333V5.36667Z"
        fill="#1265BF"
      />
      <path
        d="M3.59112 4.96886C4.35769 4.96886 4.97912 4.34744 4.97912 3.58087C4.97912 2.8143 4.35769 2.19287 3.59112 2.19287C2.82455 2.19287 2.20312 2.8143 2.20312 3.58087C2.20312 4.34744 2.82455 4.96886 3.59112 4.96886Z"
        fill="white"
      />
      <path
        d="M6.25029 5.99464H8.55065V7.04844C8.55065 7.04844 9.17488 5.79997 10.8733 5.79997C12.3884 5.79997 13.6435 6.54634 13.6435 8.82134V13.6187H11.2597V9.40265C11.2597 8.06061 10.5432 7.91301 9.99724 7.91301C8.86417 7.91301 8.67019 8.89035 8.67019 9.5777V13.6187H6.25029V5.99464Z"
        fill="white"
      />
      <path
        d="M2.38117 5.99464H4.80108V13.6187H2.38117V5.99464Z"
        fill="white"
      />
    </svg>
  )
}
