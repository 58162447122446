import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPause, faPlay } from '@fortawesome/free-solid-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'

library.add(faPlay)
library.add(faPause)
class PlayButton extends React.Component {
  handleStartPlaying = () => {
    const { onStartPlaying } = this.props
    onStartPlaying && onStartPlaying()
  }

  handleStopPlaying = () => {
    const { onStopPlaying } = this.props
    onStopPlaying && onStopPlaying()
  }

  render() {
    const { isPlaying, disabled } = this.props
    if (!isPlaying) {
      return (
        <FontAwesomeIcon
          icon={faPlay}
          size="3x"
          style={{
            color: disabled ? 'gray' : 'black',
          }}
          onClick={() => (!disabled ? this.handleStartPlaying() : {})}
        />
      )
    } else if (isPlaying) {
      return (
        <FontAwesomeIcon
          icon={faPause}
          size="3x"
          style={{
            color: disabled ? 'gray' : 'black',
          }}
          onClick={() => (!disabled ? this.handleStopPlaying() : {})}
        />
      )
    }
  }
}

export default PlayButton
