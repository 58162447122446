import React, { Component } from 'react'
import ReactModal from 'react-modal'
import { connect } from 'react-redux'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  listProjects,
  deleteProject,
  mergeProject,
  downloadProject,
} from '../../services/EditorServices'
import {
  setProjectsAction,
  renameProjectAction,
  deleteProjectAction,
} from '../../redux/actions/ProjectsActions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faSpinner,
  faDownload,
  faTrashAlt,
  faTimes,
} from '@fortawesome/free-solid-svg-icons'
import ProjectGridItem from './ProjectGridItem'
import PromptSaveModal from './PromptSaveModal'
import { toast } from 'react-toastify'
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from '@chakra-ui/modal'
import { TickIcon } from '../home/icons/TickIcon'
import { ConvertDuration } from '../../utils/TimeManage'
import { EditorDeleteIcon } from '../editor/icons/EditorDeleteIcon'
// import {
//   CloseFileImg,
//   ProjectsIcon,
//   VideosIcon,
// } from '../video-editor/common-items/EditorIcons'
import { RATIOS } from '../../constants/AspectRatios'

library.add(faTrashAlt)
library.add(faSpinner)
library.add(faDownload)
library.add(faTimes)
const ratios = ['16:9', '9:16', '4:5', '1:1']

const ProjectTab = {
  Draft: 'draft',
  Completed: 'completed',
}

const ProjectItem = ({
  project,
  selectedProjectId,
  index,
  toggleSelectProject,
  getProjectImageUrl,
}) => {
  return (
    <div
      key={index}
      className="project"
      onClick={() => toggleSelectProject(project._id, project.projectName)}>
      <div className={'project-img'}>
        <img src={getProjectImageUrl(project)} alt="project-screenshot" />
      </div>
      <span className="project-duration">
        {ConvertDuration(project.duration)}
      </span>
      <div
        className={`select-radio project-radio${
          selectedProjectId === project._id ? ' active' : ''
        }`}>
        <TickIcon />
      </div>
    </div>
  )
}

class ProjectsModal extends Component {
  state = {
    loading: true,
    selectedProjectId: null,
    selectedProjectName: null,
    aspectRatios: [],
    subModal: false,
    mergeLoading: false,
    projectTab: ProjectTab.Draft,
    downloading: false,
  }

  constructor(props) {
    super(props)

    ReactModal.setAppElement('#root')
  }

  componentDidMount() {
    if (!this.props.projectsList) {
      this.getProjects()
    }
    this.setState({ loading: false })
    document.addEventListener('click', this.closeClickListener)
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.closeClickListener)
  }

  changeProjectTab = (value) => {
    this.setState({
      projectTab: value,
      selectedProjectId: null,
      selectedProjectName: null,
    })
  }

  getProjects = async () => {
    const response = await listProjects()
    if (response) {
      this.props.setProjectsAction(response.data.projects)
    }
  }

  closeSubModal = () => {
    if (!this.state.mergeLoading) {
      this.setState({
        subModal: false,
      })
    }
  }

  openSubModal = () => {
    this.setState({
      subModal: true,
    })
  }

  closeClickListener = (event) => {
    if (event.target.classList.contains('projects-close-clicker')) {
      this.props.closeModal()
    }
  }

  toggleSelectProject = (selectedProjectId, selectedProjectName) => {
    this.setState({
      selectedProjectId,
      selectedProjectName,
      aspectRatios: [],
    })
  }

  deleteProjectActionHandler = async () => {
    this.setState({ loading: true })
    const res = await deleteProject(this.state.selectedProjectId)
    if (res) this.props.deleteProjectAction(this.state.selectedProjectId)
    this.setState({ loading: false, selectedProjectId: null })
  }

  openProjectHandler = () => {
    this.setState({
      subModal: true,
    })
  }

  getRenderedProjects = () => {
    const { projectsList } = this.props

    return projectsList
      ? projectsList.filter((project) => project.status !== 'draft')
      : []
  }

  discardOpenProject = () => {
    window.location.href = `/editor/${this.state.selectedProjectId}`
  }

  saveOpenProject = async () => {
    window.location.href = `/editor/${this.state.selectedProjectId}`
  }

  mergeOpenProject = async () => {
    const mergedProject = await mergeProject(
      this.props.project.id,
      this.state.selectedProjectId,
    )
    if (mergedProject) {
      this.getProjects()
      window.location.href = `/editor/${mergedProject.projectId}`
    }
  }

  selectAspect = (aspect) => {
    if (this.state.aspectRatios.includes(aspect)) {
      this.setState({
        ...this.state,
        aspectRatios: this.state.aspectRatios.filter((x) => x !== aspect),
      })
    } else {
      this.setState({
        ...this.state,
        aspectRatios: [...this.state.aspectRatios, aspect],
      })
    }
  }

  // downloadSelected = async () => {
  //   toast.success('Please wait for download to begin')
  //   const { project } = this.props
  //   const { selectedProjectId } = this.state
  //   const links = this.state.aspectRatios.map((ratio) =>
  //     `${
  //       process.env.REACT_APP_S3_URL
  //     }/projects/${selectedProjectId}/${`projects_COL_${selectedProjectId}_COL_${ratios.indexOf(
  //       ratio,
  //     )}_COL_${project.dimension}`}.mp4`.replace(/\/\/projects/, '/projects'),
  //   )
  //   await downloadProject(selectedProjectId, links)
  // }

  downloadSelected = async () => {
    this.setState({ downloading: true })
    toast.success('Please wait for download to begin')
    const { project } = this.props
    const { selectedProjectId } = this.state
    const links = RATIOS.map((ratio) =>
      `${
        process.env.REACT_APP_S3_URL
      }/projects/${selectedProjectId}/${`projects_COL_${selectedProjectId}_COL_${ratio.id}_COL_${project.dimension}`}.mp4`.replace(
        /\/\/projects/,
        '/projects',
      ),
    )
    await downloadProject(selectedProjectId, links)
    toast.success('Download completed')
    this.setState({ downloading: false })
  }

  getProjectImageUrl = (project) => {
    return project.scenes[0]?.data?.length > 0
      ? project.scenes[0].data[0].thumbUrl
      : '/project-bannar.jpg'
  }

  renderBackgroundImage = (project) =>
    `url(${
      project.scenes[0]?.data?.length > 0
        ? project.scenes[0].data[0].thumbUrl
        : '/project-bannar.jpg'
    })`

  render() {
    const {
      loading,
      selectedProjectId,
      subModal,
      mergeLoading,
      aspectRatios,
      selectedProjectName,
    } = this.state

    const { closeModal, projectsList, renameProjectAction, project } =
      this.props

    return (
      <Modal
        size="full"
        scrollBehavior="outside"
        blockScrollOnMount={false}
        isOpen={true}
        onClose={this.props.closeModal}>
        {subModal && (
          <PromptSaveModal
            onClose={this.closeSubModal}
            currentProject={project?.projectName}
            selectedProject={selectedProjectName}
            saveAction={this.saveOpenProject}
            discardAction={this.discardOpenProject}
            mergeAction={this.mergeOpenProject}
            loading={mergeLoading}
          />
        )}
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton
            style={{ marginTop: 20, fontSize: 20, color: '#4B5563' }}
          />
          <ModalBody>
            <div className="editor-projects-modal">
              <div className="modal-tabs">
                <div className="tabs">
                  <div
                    className={`modal-tab draft${
                      this.state.projectTab === ProjectTab.Draft
                        ? ' active'
                        : ''
                    }`}
                    onClick={() => this.changeProjectTab(ProjectTab.Draft)}>
                    Draft Projects
                  </div>
                  <div
                    className={`modal-tab completed${
                      this.state.projectTab === ProjectTab.Completed
                        ? ' active'
                        : ''
                    }`}
                    onClick={() => this.changeProjectTab(ProjectTab.Completed)}>
                    Completed Projects
                  </div>
                </div>
                {selectedProjectId && (
                  <div
                    onClick={this.deleteProjectActionHandler}
                    alt="Delete selected"
                    style={{ cursor: 'pointer' }}
                    className="upload-file mr-4">
                    <EditorDeleteIcon />
                  </div>
                )}
              </div>

              <div style={{ textAlign: 'center' }}>
                {' '}
                <span className="type-title font-bold">
                  {loading ? (
                    <FontAwesomeIcon
                      icon={faSpinner}
                      className="mr-4 ml-4 fa-spin"
                    />
                  ) : (
                    projectsList?.length === 0 && ' - Nothing to show'
                  )}
                </span>
              </div>
              {this.state.projectTab === ProjectTab.Draft && (
                <div className="projects completed">
                  {projectsList
                    ?.filter((p) => p.status === 'draft')
                    .sort(
                      (a, b) =>
                        new Date(b.date).getTime() - new Date(a.date).getTime(),
                    )
                    .map((project, index) => {
                      return (
                        <ProjectItem
                          project={project}
                          index={index}
                          selectedProjectId={selectedProjectId}
                          toggleSelectProject={this.toggleSelectProject}
                          getProjectImageUrl={this.getProjectImageUrl}
                        />
                      )
                    })}
                </div>
              )}
              {this.state.projectTab === ProjectTab.Completed && (
                <div className="projects draft">
                  {projectsList
                    ?.filter((p) => p.status === 'publish')
                    .sort(
                      (a, b) =>
                        new Date(b.date).getTime() - new Date(a.date).getTime(),
                    )
                    .map((project, index) => {
                      return (
                        <ProjectItem
                          project={project}
                          index={index}
                          selectedProjectId={selectedProjectId}
                          toggleSelectProject={this.toggleSelectProject}
                          getProjectImageUrl={this.getProjectImageUrl}
                        />
                      )
                    })}
                </div>
              )}

              {selectedProjectId && (
                <div className="buttons">
                  {this.state.projectTab === ProjectTab.Completed && (
                    <button
                      disabled={this.state.downloading}
                      className="btn download"
                      onClick={this.downloadSelected}>
                      Download{' '}
                      {this.state.downloading && (
                        <FontAwesomeIcon
                          icon={faSpinner}
                          className="mr-4 ml-4 fa-spin"
                        />
                      )}
                    </button>
                  )}
                  <button
                    className="btn next"
                    onClick={this.openProjectHandler}>
                    Open
                  </button>
                </div>
              )}
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>

      // <ReactModal
      //   isOpen={true}
      //   contentLabel={'My projects'}
      //   parentSelector={() => document.querySelector('#root')}
      //   style={ModalStyles}>
      //   <div className="import-modal-area absolute top-0 left-0 bottom-0 right-0 block">
      //     <div className="import-modal-bg projects-close-clicker w-full h-full block absolute top-0 left-0 right-0 bottom-0" />

      //     {/* Sub modal to prompt user to save or merge or discard changes before opening another project */}
      //     {subModal && (
      //       <PromptSaveModal
      //         onClose={this.closeSubModal}
      //         currentProject={project?.projectName}
      //         selectedProject={selectedProjectName}
      //         saveAction={this.saveOpenProject}
      //         discardAction={this.discardOpenProject}
      //         mergeAction={this.mergeOpenProject}
      //         loading={mergeLoading}
      //       />
      //     )}

      //     <div
      //       ref={this.modalRef}
      //       className="import-files-modal flex flex-col flex-1 absolute rounded-lg py-4">
      //       {/* Projects list */}
      //       <div className="import-type flex-1 flex flex-col">
      //         <div className="type-header flex justify-between items-center">
      //           <div className="left">
      //             <span className="icon">
      //               <img src={ProjectsIcon} width="50px" alt="" />
      //             </span>

      //             <span className="type-title font-bold">
      //               Draft Projects
      //               {loading ? (
      //                 <FontAwesomeIcon
      //                   icon={faSpinner}
      //                   className="mr-4 ml-4 fa-spin"
      //                 />
      //               ) : (
      //                 projectsList?.length === 0 && ' - Nothing to show'
      //               )}
      //             </span>
      //           </div>

      //           <div className="right flex flex-row">
      //             {selectedProjectId && (
      //               <div
      //                 onClick={this.deleteProjectActionHandler}
      //                 alt="Delete selected"
      //                 style={{
      //                   width: '30px',
      //                   height: '30px',
      //                   display: 'flex',
      //                   justifyContent: 'center',
      //                   alignItems: 'center',
      //                 }}
      //                 className="upload-file mr-4">
      //                 <FontAwesomeIcon
      //                   icon={faTrashAlt}
      //                   size="lg"
      //                   color="#6d7173"
      //                 />
      //               </div>
      //             )}
      //             <div
      //               src={CloseFileImg}
      //               onClick={closeModal}
      //               alt="Close window"
      //               style={{
      //                 width: '30px',
      //                 height: '30px',
      //                 display: 'flex',
      //                 justifyContent: 'center',
      //                 alignItems: 'center',
      //               }}
      //               className="upload-file">
      //               <FontAwesomeIcon icon={faTimes} size="lg" color="#6d7173" />
      //             </div>
      //           </div>
      //         </div>

      //         <div
      //           className="type-content img22 audio flex flex-row justify-start text-center"
      //           style={{
      //             flex: 1,
      //             display: 'flex',
      //             minHeight: 0,
      //             maxHeight: 'calc(40vh - 60px)',
      //             overflow: 'auto',
      //             flexWrap: 'wrap',
      //           }}>
      //           {projectsList
      //             ?.filter((p) => p.status === 'draft')
      //             .sort(
      //               (a, b) =>
      //                 new Date(b.date).getTime() - new Date(a.date).getTime(),
      //             )
      //             .map((project) => {
      //               return (
      //                 <ProjectGridItem
      //                   key={project._id}
      //                   project={project}
      //                   isActive={selectedProjectId === project._id}
      //                   onClick={this.toggleSelectProject}
      //                   renameProjectAction={renameProjectAction}
      //                 />
      //               )
      //             })}
      //         </div>
      //       </div>

      //       {/* Videos list */}
      //       <div className="import-type flex-1 flex flex-col">
      //         <div className="type-header flex justify-between items-center">
      //           <div className="left">
      //             <span className="icon">
      //               <img src={VideosIcon} width="50px" alt="" />
      //             </span>
      //             <span className="type-title font-bold">
      //               Completed Projects
      //             </span>
      //           </div>

      //           <div className="right" />
      //         </div>
      //         {/* ============================================================================================================ */}
      //         <div className="type-content img flex justify-start text-center flex-wrap">
      //           {projectsList
      //             ?.filter((p) => p.status === 'publish')
      //             .sort(
      //               (a, b) =>
      //                 new Date(b.date).getTime() - new Date(a.date).getTime(),
      //             )
      //             .map((project) => {
      //               return (
      //                 <ProjectGridItem
      //                   key={project._id}
      //                   project={project}
      //                   isActive={selectedProjectId === project._id}
      //                   onClick={this.toggleSelectProject}
      //                   aspectRatios={aspectRatios}
      //                   selectAspect={this.selectAspect}
      //                   renameProjectAction={renameProjectAction}
      //                 />
      //               )
      //             })}
      //         </div>
      //       </div>

      //       {/* footer actions */}
      //       <div className="import-type import-footer">
      //         <div className="type-content flex items-center justify-between">
      //           <div className="left">
      //             {selectedProjectId &&
      //               projectsList
      //                 ?.filter((proj) => selectedProjectId === proj._id)
      //                 .sort(
      //                   (a, b) =>
      //                     new Date(b.date).getTime() -
      //                     new Date(a.date).getTime(),
      //                 )
      //                 .map((project) =>
      //                   project.status === 'draft' ? (
      //                     <div
      //                       className="left-item"
      //                       key={project._id}
      //                       style={{
      //                         height: '62px',
      //                         width: (62 * 16) / 9 + 'px',
      //                         backgroundSize: 'cover',
      //                         backgroundImage:
      //                           this.renderBackgroundImage(project),
      //                         border: '1px solid #999',
      //                         borderRadius: 10,
      //                       }}
      //                     />
      //                   ) : (
      //                     aspectRatios.map((aspect) => {
      //                       const [w, h] = aspect.split(':')

      //                       return (
      //                         <div key={aspect}>
      //                           <div
      //                             className="left-item"
      //                             style={{
      //                               height: '62px',
      //                               width: (62 * Number(w)) / Number(h) + 'px',
      //                               backgroundSize: 'cover',
      //                               backgroundImage:
      //                                 this.renderBackgroundImage(project),
      //                               border: '1px solid #999',
      //                               borderRadius: 10,
      //                             }}
      //                           />
      //                           <div
      //                             style={{
      //                               marginTop: '-25px',
      //                               paddingLeft: '5px',
      //                             }}>
      //                             <a
      //                               href={`${
      //                                 process.env.REACT_APP_S3_URL
      //                               }/projects/${project._id}/${`projects_COL_${
      //                                 project._id
      //                               }_COL_${ratios.indexOf(aspect)}_COL_${
      //                                 project.dimension
      //                               }`}.mp4`}
      //                               target="blank"
      //                               style={{
      //                                 fontSize: 12,
      //                               }}>
      //                               <FontAwesomeIcon
      //                                 icon={faDownload}
      //                                 size="sm"
      //                               />
      //                             </a>
      //                           </div>
      //                         </div>
      //                       )
      //                     })
      //                   ),
      //                 )}
      //           </div>

      //           <div className="right" style={{ display: 'flex' }}>
      //             {aspectRatios.length > 0 && (
      //               <div
      //                 style={{
      //                   width: '50px',
      //                   height: '50px',
      //                   display: 'flex',
      //                   justifyContent: 'center',
      //                   alignItems: 'center',
      //                   cursor: 'pointer',
      //                 }}
      //                 onClick={this.downloadSelected}>
      //                 <FontAwesomeIcon
      //                   icon={faDownload}
      //                   size="lg"
      //                   color="#6d7173"
      //                 />
      //               </div>
      //             )}
      //             {selectedProjectId && (
      //               <button
      //                 className="btn-add border-0 cursor-pointer font-bold outline-none shadow-none"
      //                 onClick={this.openProjectHandler}>
      //                 Open
      //               </button>
      //             )}
      //           </div>
      //         </div>
      //       </div>
      //     </div>
      //   </div>
      // </ReactModal>
    )
  }
}

const mapStateToProps = (state) => ({
  project: state.editor.project,
  projectsList: state.projects.projectsList,
})

const mapDispatchToProps = (dispatch) => ({
  setProjectsAction: (...args) => dispatch(setProjectsAction(...args)),
  renameProjectAction: (...args) => dispatch(renameProjectAction(...args)),
  deleteProjectAction: (...args) => dispatch(deleteProjectAction(...args)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ProjectsModal)

const ModalStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  content: {
    border: '1px solid #ccc',
    background: '#fff',
    overflow: 'auto',
    WebkitOverflowScrolling: 'touch',
    borderRadius: '4px',
    outline: 'none',
    padding: '20px',
    position: 'initial',
    width: '90%',
    height: '90vh',
    display: 'flex',
    flexDirection: 'column',
  },
}
