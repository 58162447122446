//Media state reducer
import _ from 'lodash'
import {
  APPEAR_LOWER_NOTIFY,
  CAMERA_SHOOT_VALUE_SET,
  CAMERA_SHOT_DIRECTION_SET,
  CATEGORY_SET,
  CURRENT_SET,
  FEATURE_SET,
  MEDIA_RESOLUTION_SET,
  MENU_IS_OPEN_SET,
  PUBLIC_SET,
  RUNNING_COUNTDOWN_TIMER,
  SELECTED_MEDIA_LAYOUT_SET,
  SAVE_MEDIA_LAYOUT_SET,
  UPDATE_ZOOM_ABLE_STATUS,
  UPLOAD_SET,
  MENU_IS_TEMPLATE_SAVE,
  SET_RECORDING,
  CROPPING_FINISHED,
  REMOVE_ASPECT_SIZE,
  APPLY_RATIO_TO_SEGMENTATIONS,
  SET_ACTIVE_SEGMENTATION_SCENE,
} from '../../redux/actions/Types'
import * as SnapGridBoxConstants from '../../redux/constants/SnapGridBoxConstants'

const initialState = {
  selected: 0, // welcome page carousel
  category: 'Personal', // welcome 1
  feature: 'Demo', // welcome 2
  upload: 'Video', // welcome 3
  publication: 'Quick', // welcome 4
  menu_is_open: false, // menu of camera
  resolution: 1080, // resolution - video quality - shows grid resolution
  media_layout: 0, // selected snap grid format
  direction: 'center', // snap grid direction
  shoot_value: ['medium', 'medium', 'medium', 'medium'], // shoot
  availGridSizes: [
    [
      { width: 1920, height: 1080 },
      { width: 1080, height: 1920 },
      { width: 1080, height: 1350 },
      { width: 1080, height: 1080 },
    ],
    [
      { width: 1280, height: 720 },
      { width: 720, height: 1280 },
      { width: 720, height: 900 },
      { width: 720, height: 720 },
    ],
    [
      { width: 640, height: 360 },
      { width: 360, height: 640 },
      { width: 480, height: 600 },
      { width: 480, height: 480 },
    ],
  ],
  // replay video size
  streamSize: [
    {
      width: document.body.getBoundingClientRect().width,
      height: document.body.getBoundingClientRect().height,
    },
    {
      width: document.body.getBoundingClientRect().width,
      height: document.body.getBoundingClientRect().height,
    },
    {
      width: document.body.getBoundingClientRect().width,
      height: document.body.getBoundingClientRect().height,
    },
    {
      width: document.body.getBoundingClientRect().width,
      height: document.body.getBoundingClientRect().height,
    },
  ],
  originVideoSize: { width: 3840, height: 2160 },
  // browser size
  clientRectSize: [
    {
      width: document.body.getBoundingClientRect().width,
      height: document.body.getBoundingClientRect().height,
    },
    {
      width: document.body.getBoundingClientRect().width,
      height: document.body.getBoundingClientRect().height,
    },
    {
      width: document.body.getBoundingClientRect().width,
      height: document.body.getBoundingClientRect().height,
    },
    {
      width: document.body.getBoundingClientRect().width,
      height: document.body.getBoundingClientRect().height,
    },
  ],
  selectLayout: [true, false, false, false], // selected aspect ratio layout
  saveLayout: [false, false, false, false], // save aspect ratio layout
  saveLayoutSegmentations: [], // save aspect ratio layout segmentations
  aspectTransform: ['none', 'none', 'none', 'none'], // aspect transform(position of snap grid)
  aspectSize: [
    // crop size
    { width: 0, height: 0, top: 0, left: 0 },
    { width: 0, height: 0, top: 0, left: 0 },
    { width: 0, height: 0, top: 0, left: 0 },
    { width: 0, height: 0, top: 0, left: 0 },
  ],
  aspectSizeSegmentations: [],
  snapGridPosition: {
    // tap snap grid first load position
    top: 0,
    left: 0,
    set: false,
  },
  runningCountTimer: true,
  cameraDimension: {
    ratio: 1080,
    orientation: 'landscape',
  },
  appearedLowerMediaNotify: false,
  zoomAbleState: false,
}

export default function (state = _.cloneDeep(initialState), action) {
  switch (action.type) {
    case CURRENT_SET:
      return {
        ...state,
        selected: action.selected,
      }

    case CATEGORY_SET:
      return {
        ...state,
        category: action.category,
      }

    case FEATURE_SET:
      return {
        ...state,
        feature: action.feature,
      }

    case UPLOAD_SET:
      return {
        ...state,
        upload: action.upload,
      }

    case PUBLIC_SET:
      return {
        ...state,
        publication: action.publication,
      }

    case MENU_IS_OPEN_SET:
      return {
        ...state,
        menu_is_open: action.menu_is_open,
      }

    case MEDIA_RESOLUTION_SET:
      return {
        ...state,
        resolution: action.resolution,
      }

    case CAMERA_SHOT_DIRECTION_SET:
      return {
        ...state,
        direction: action.snapAlignment,
      }

    case CAMERA_SHOOT_VALUE_SET:
      return {
        ...state,
        shoot_value: state.shoot_value.map((item, index) => {
          if (index !== action.index) {
            return item
          }

          return action.shootValue
        }),
      }
    case SELECTED_MEDIA_LAYOUT_SET:
      return {
        ...state,
        selectLayout: state.selectLayout.map((item, index) => {
          if (index !== action.index) {
            return item
          }

          return action.selectAspectRatioType
        }),
      }

    case SAVE_MEDIA_LAYOUT_SET: {
      const saveLayout = state.saveLayout.map((item, index) => {
        if (index !== action.index) {
          return item
        }

        return action.saveLayout
      })
      let saveLayoutSegmentations = _.cloneDeep(state.saveLayoutSegmentations)
      if (action.currentVideoId) {
        const existIndex = saveLayoutSegmentations.findIndex(
          (saveLayoutSeg) => saveLayoutSeg.id === action.currentVideoId,
        )
        const segmentationData = {
          id: action.currentVideoId,
          data: saveLayout,
        }
        if (existIndex < 0) {
          saveLayoutSegmentations.push(segmentationData)
        } else {
          saveLayoutSegmentations[existIndex] = segmentationData
        }
      }
      return {
        ...state,
        saveLayoutSegmentations,
        saveLayout,
      }
    }

    case SnapGridBoxConstants.VIDEO_CROP_SIZE_SET: {
      const aspectSizeRatio = {
        width: action.aspectSize.width,
        height: action.aspectSize.height,
        top: action.aspectSize.top,
        left: action.aspectSize.left,
        supported: true,
      }
      let newState = _.set(state, `aspectSize.${action.index}`, aspectSizeRatio)
      const isRecordButtonVisible = _.some(
        state.aspectSize,
        (aspectSize) => aspectSize.supported === true,
      )
      newState = _.set(state, 'snapGridPosition.set', isRecordButtonVisible)

      let aspectSizeSegmentations = _.cloneDeep(state.aspectSizeSegmentations)
      if (action.currentVideoId) {
        const existIndex = aspectSizeSegmentations.findIndex(
          (aspectSizeSeg) => aspectSizeSeg.id === action.currentVideoId,
        )
        const segmentationData = {
          id: action.currentVideoId,
          data: newState.aspectSize,
        }
        if (existIndex < 0) {
          aspectSizeSegmentations.push(segmentationData)
        } else {
          aspectSizeSegmentations[existIndex] = segmentationData
        }
      }
      return { ..._.cloneDeep(newState), aspectSizeSegmentations }
    }
    case SnapGridBoxConstants.MEDIA_LAYOUT_SET:
      return {
        ...state,
        media_layout: action.aspectRatioType,
      }
    case SnapGridBoxConstants.MEDIA_BOX_TRANSFORM_SET:
      return {
        ...state,
        aspectTransform: state.aspectTransform.map((item, index) => {
          if (index !== action.index) {
            return item
          }

          return action.aspectTransform
        }),
      }

    case SnapGridBoxConstants.SET_VIDEO_STREAM_SIZE:
      return {
        ...state,
        streamSize: state.streamSize.map((item, index) => {
          if (index !== action.index) {
            // This isn't the item we care about - keep it as-is
            return item
          }

          return {
            width: action.streamSize.width,
            height: action.streamSize.height,
          }
        }),
      }

    case SnapGridBoxConstants.SET_ORIGIN_SIZE:
      return {
        ...state,
        originVideoSize: {
          width: action.originVideoSize.width,
          height: action.originVideoSize.height,
        },
      }

    case SnapGridBoxConstants.SET_CLIENT_RECTANGLE_SIZE:
      return {
        ...state,
        clientRectSize: state.clientRectSize.map((item, index) => {
          if (index !== action.index) {
            // This isn't the item we care about - keep it as-is
            return item
          }

          return {
            width: action.clientRectSize.width,
            height: action.clientRectSize.height,
          }
        }),
      }

    case SnapGridBoxConstants.SET_SNAP_GRID_POSITION: {
      const newSnapGridPosition = _.merge(
        state.snapGridPosition,
        action.snapGridPosition,
      )
      return {
        ...state,
        snapGridPosition: newSnapGridPosition,
      }
    }

    case SnapGridBoxConstants.CAMERA_DIMENSION:
      return {
        ...state,
        cameraDimension: {
          ratio: action.cameraDimension.ratio,
          orientation: action.cameraDimension.orientation,
        },
      }

    case RUNNING_COUNTDOWN_TIMER:
      return {
        ...state,
        runningCountTimer: action.runningCountTimer,
      }

    case APPEAR_LOWER_NOTIFY:
      return {
        ...state,
        appearedLowerMediaNotify: action.appearedLowerMediaNotify,
      }

    case UPDATE_ZOOM_ABLE_STATUS:
      return {
        ...state,
        zoomAbleState: action.zoomAbleState,
      }
    case MENU_IS_TEMPLATE_SAVE:
      return {
        ...state,
        menu_is_template_save: action.menu_is_template_save,
      }
    case SET_RECORDING:
      return {
        ...state,
        isRecording: action.isRecording,
      }
    case CROPPING_FINISHED:
      return {
        ...state,
        aspectSize: [
          { width: 0, height: 0, top: 0, left: 0 },
          { width: 0, height: 0, top: 0, left: 0 },
          { width: 0, height: 0, top: 0, left: 0 },
          { width: 0, height: 0, top: 0, left: 0 },
        ],
        saveLayout: [false, false, false, false],
        aspectSizeSegmentations: [],
        saveLayoutSegmentations: [],
      }
    case REMOVE_ASPECT_SIZE: {
      const saveLayout = _.cloneDeep(state.saveLayout)
      const aspectSize = _.cloneDeep(state.aspectSize)
      saveLayout[action.index] = false
      aspectSize[action.index] = { width: 0, height: 0, top: 0, left: 0 }

      let saveLayoutSegUpdated = []
      let aspectSizeSegUpdated = []

      if (action.currentVideoId) {
        const saveLayoutSegmentations = _.cloneDeep(
          state.saveLayoutSegmentations,
        )
        saveLayoutSegUpdated = saveLayoutSegmentations.map((saveLayoutSeg) => {
          if (saveLayoutSeg.id === action.currentVideoId) {
            return {
              ...saveLayoutSeg,
              data: saveLayout,
            }
          }
          return saveLayoutSeg
        })
        const aspectSizeSegmentations = _.cloneDeep(
          state.aspectSizeSegmentations,
        )
        aspectSizeSegUpdated = aspectSizeSegmentations.map((aspectSizeSeg) => {
          if (aspectSizeSeg.id === action.currentVideoId) {
            return {
              ...aspectSizeSeg,
              data: aspectSize,
            }
          }
          return aspectSizeSeg
        })
      }
      return {
        ...state,
        aspectSize,
        saveLayout,
        saveLayoutSegmentations: saveLayoutSegUpdated,
        aspectSizeSegmentations: aspectSizeSegUpdated,
      }
    }
    case APPLY_RATIO_TO_SEGMENTATIONS: {
      const videoIds = action.videoIds
      const fromSceneId = action.fromSceneId
      const aspectSize = fromSceneId
        ? state.aspectSizeSegmentations.find(({ id }) => id === fromSceneId)
            ?.data
        : state.aspectSizeSegmentations[0]?.data
      const saveLayout = fromSceneId
        ? state.saveLayoutSegmentations.find(({ id }) => id === fromSceneId)
            ?.data
        : state.saveLayoutSegmentations[0]?.data
      const aspectSizeSegmentations = videoIds.map((videoId) => {
        return {
          id: videoId,
          data: aspectSize,
        }
      })
      const saveLayoutSegmentations = videoIds.map((videoId) => {
        return {
          id: videoId,
          data: saveLayout,
        }
      })
      return {
        ...state,
        aspectSize,
        saveLayout,
        aspectSizeSegmentations,
        saveLayoutSegmentations,
      }
    }
    case SET_ACTIVE_SEGMENTATION_SCENE: {
      const videoId = action.videoId
      const aspectSizeSegmentations = _.cloneDeep(state.aspectSizeSegmentations)
      const aspectSize = aspectSizeSegmentations.find((x) => x.id === videoId)
      const saveLayoutSegmentations = _.cloneDeep(state.saveLayoutSegmentations)
      const saveLayout = saveLayoutSegmentations.find((x) => x.id === videoId)
      return {
        ...state,
        aspectSize: aspectSize?.data || [
          { width: 0, height: 0, top: 0, left: 0 },
          { width: 0, height: 0, top: 0, left: 0 },
          { width: 0, height: 0, top: 0, left: 0 },
          { width: 0, height: 0, top: 0, left: 0 },
        ],
        saveLayout: saveLayout?.data || [false, false, false, false],
      }
    }
    default:
      return state
  }
}
