/* eslint-disable */
let audioCtx
let analyser

const AudioContext = {
    setAudioContext() {
        audioCtx = new (window.AudioContext || window.webkitAudioContext)()
    },

    getAudioContext() {
        return audioCtx
    },

    setAnalyser() {
        analyser = audioCtx.createAnalyser()
    },

    getAnalyser() {
        return analyser
    },

    resetAnalyser() {
        analyser = audioCtx.createAnalyser()
    },

    decodeAudioData(audioData) {
        audioCtx.decodeAudioData(audioData).then(function (decodedData) {
            // use the decoded data here
        })
    },
}

export default AudioContext
