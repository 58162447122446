import React, { Component } from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import CropperRatioButtons from './CropperRatioButtons'
import ReplayWrapper from '../video-capture/camera-wrapper/ReplayWrapper'
import { ONE_SECOND } from '../../constants/Timeline'
import { ASPECT_RATIO_DETAILS } from '../../constants/AspectRatios'

const resizeCropperSize = ({ height, width }, currentLayout) => {
  if (currentLayout === 0) {
    return {
      height: height > 622 ? 622 : height,
      width: height > 622 ? 1105 : width,
    }
  } else if (currentLayout === 1) {
    return {
      height: height > 622 ? 622 : height,
      width: height > 622 ? 350 : width,
    }
  } else if (currentLayout === 2) {
    return {
      height: height > 625 ? 625 : height,
      width: height > 625 ? 500 : width,
    }
  } else if (currentLayout === 3) {
    return {
      height: height > 625 ? 625 : height,
      width: height > 625 ? 625 : width,
    }
  }
  return { height, width }
}

class CropperFacetrackPreview extends Component {
  state = {
    disabledRatio: null,
  }

  get originalVideoUrl() {
    return this.props.videoUrl || ''
  }

  displayDisabledRatioMessage = (ratio) => {
    this.setState({ disabledRatio: ratio }, () => {
      setTimeout(() => {
        this.setState({ disabledRatio: null })
      }, ONE_SECOND)
    })
  }

  render() {
    const { faceTrackingVideos, currentLayout } = this.props
    const ratioName = this.getRatioName(currentLayout)
    return (
      <div className="w-screen h-screen flex flex-row gap-5 justify-center items-center cropper-facetrack-preview">
        <div className="w-screen flex flex-row gap-5 justify-center items-start">
          {this.renderOriginal(
            this.originalVideoUrl,
            `Original (${ratioName})`,
          )}
          {this.renderFaceTracked(faceTrackingVideos, 'Face-tracked Video')}
        </div>
      </div>
    )
  }

  handleUploadOriginalVideo = () => {
    const { onUploadOriginalVideo } = this.props
    onUploadOriginalVideo && onUploadOriginalVideo()
  }

  handleUploadFacetrackedVideo = () => {
    const { onUploadFacetrackedVideo } = this.props
    onUploadFacetrackedVideo && onUploadFacetrackedVideo()
  }

  getRatioName(ratio) {
    return _.capitalize(ASPECT_RATIO_DETAILS[ratio].name)
  }

  renderOriginal(videoUrl, videoType) {
    const { aspectSizes, currentLayout, rectangleSizes, shootValue } =
      this.props
    const selectedCropBox = aspectSizes[currentLayout]
    const { disabledRatio } = this.state
    const disabledRatioName = this.getRatioName(
      disabledRatio?.ratio || currentLayout,
    )
    return (
      <div className="w-6/12 p-5 relative">
        <div
          style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <button
            className="rounded bg-teal-700 hover:bg-teal-600 py-2 px-3 absolute original"
            style={{ background: 'grey', top: -35 }}
            onClick={this.handleUploadOriginalVideo.bind(this)}>
            Keep Original
          </button>
        </div>
        <ReplayWrapper
          src={videoUrl}
          selectedLayout={currentLayout}
          aspectSizes={aspectSizes}
          rectangleSizes={rectangleSizes}
          shootValue="medium"
          style={
            !currentLayout
              ? {}
              : {
                  height: resizeCropperSize(selectedCropBox, currentLayout)
                    ?.height,
                }
          }
        />
        {/* <div
          className="absolute top-0 p-10 w-full video-gradient">
          {videoType}
        </div> */}
        {disabledRatio && (
          <div
            className="absolute flex p-10 w-full justify-center"
            style={{ top: 'calc(50% - 50px - 70px)' }}>
            <div className="p-4 disabled-ratio-box">
              {disabledRatioName} ratio is not available for preview
            </div>
          </div>
        )}
        {/* <CropperRatioButtons
          onDisabledRatioClick={(ratio) =>
            this.displayDisabledRatioMessage(ratio)
          }
        /> */}
      </div>
    )
  }

  renderFaceTracked(videoUrls, videoType) {
    const { aspectSizes, currentLayout, rectangleSizes, shootValue } =
      this.props
    const { disabledRatio } = this.state
    const disabledRatioName = this.getRatioName(
      disabledRatio?.ratio || currentLayout,
    )
    const videoUrl = videoUrls[currentLayout]
    const selectedCropBox = aspectSizes[currentLayout]

    return (
      <div className="flex items-center justify-center w-6/12 p-5 relative">
        <button
          className="rounded bg-teal-700 hover:bg-teal-600 py-2 px-3 absolute tracked"
          style={{ background: '#01C67F', top: -35 }}
          onClick={this.handleUploadFacetrackedVideo.bind(this)}>
          Keep Tracked
        </button>
        <video
          autoPlay
          height={resizeCropperSize(selectedCropBox, currentLayout)?.height}
          loop
          muted
          src={videoUrl}
          width={resizeCropperSize(selectedCropBox, currentLayout)?.width}
        />
        {/* <div
          className="absolute top-0 p-10 w-full video-gradient">
          {videoType}
        </div> */}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  faceTrackingVideo: state.faceTrack.faceTrackingVideo,
  faceTrackingVideos: state.faceTrack.faceTrackingVideos,
  aspectSizes: state.recorder.aspectSize,
  rectangleSizes: state.recorder.clientRectSize,
  currentLayout: state.videoCrop.currentLayout,
})

export default connect(mapStateToProps)(CropperFacetrackPreview)
