import React, { Component } from 'react'
import RangeSlider from 'react-range-slider-input'
import 'react-range-slider-input/dist/style.css'
import { AspectRatio } from 'react-aspect-ratio'
import { AspectRatio as ChakraAspectRatio } from '@chakra-ui/react'
import {
  Slider as ChakraSlider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
} from '@chakra-ui/react'
import VideoControl from '../common-items/preview/VideoControl'
import { gsap } from 'gsap'
import { CSSPlugin } from 'gsap/CSSPlugin'
import { connect } from 'react-redux'
import TextStageMove from '../common-items/TextStageMove'
import { Progress } from '@chakra-ui/react'
import _ from 'lodash'
import {
  addScenesClip,
  checkAddItem,
  SetPreviewTimeline,
  SetStageHeight,
  SetStageWidth,
  SetTimeSlider,
  toggleWatchProject,
  updateProject,
  storeProjectSteps,
} from '../../../redux/actions/EditorActions'
import * as MainContainerActions from '../../../redux/actions/MainContainerActions'
import * as MediaPlayerActions from '../../../redux/actions/MediaPlayerActions'
import * as SelectedItemActions from '../../../redux/actions/SelectedItemActions'

import {
  completePreview,
  getProjectWidth,
  handleProgressChange,
  onTextDrag,
  onTextDragEnd,
  onTextResizeEnd,
  onTextResizing,
  pauseTimeline,
  playAudio,
  playSound,
  playVideo,
  playVoiceover,
  SetPreviewStage,
  setProjectVal,
  setSceneTimeline,
  setTextItem,
  toggleShowStage,
  updateProjectValue,
  updateSlider,
} from '../../../methods/editor/MainContainerMethods'
import TextClip from '../common-items/preview/TextClip'
import BgClip from '../common-items/preview/BgClip'
import VideoTime from '../common-items/preview/VideoTime'
import { ASPECT_RATIO } from '../../../constants/AspectRatios'
import TweenObjectBuilder from '../../../services/time/TweenObjectBuilder'
import {
  AUDIO,
  MUSIC,
  TEXT,
  VIDEO,
  VOICEOVER,
} from '../../../constants/Channels'
import ProjectItemUtils from '../../../utils/ProjectItemUtils'
import { CropperPlayIcon } from '../../cropper/icons/CropperPlayIcon'
import { ConvertDuration } from '../../../utils/TimeManage'

gsap.registerPlugin(CSSPlugin)

class MainContainer extends Component {
  static getDerivedStateFromProps(props, prevState) {
    if (_.isEmpty(prevState.videoLoaded)) {
      const selectedVideos = _.get(
        props.project,
        `scenes.${props.project?.resolution}`,
        [],
      )
      const videoLoaded = _.map(selectedVideos, () => false)
      return { ...prevState, videoLoaded }
    }
    return null
  }

  constructor(props) {
    super(props)

    this.state = {
      slideV: 0,
      resolutions: [
        { w: 16, h: 9 },
        { w: 9, h: 16 },
        { w: 4, h: 5 },
        { w: 1, h: 1 },
      ],
      selectedText: null,
      selectedTextIndex: -1,
      scale: 1,
      hScale: 1,
      fontSizesByTextId: {},
      videoLoaded: [],
      stageAspectRatio: '16/9',
      dragSets: [],
    }

    this.stageRef = React.createRef()
    this.textWrapper = React.createRef()
    this.sceneItems = []
    this.sceneMusics = []
    this.sceneMusics = []
    this.sceneSounds = []
    this.sceneVideos = []
    this.sceneVoiceovers = []
    this.audioLoaded = []
    this.musicLoaded = []
    this.soundLoaded = []
    this.videoLoaded = []
    this.voiceoverLoaded = []
    this.sceneTexts = []
    this.tweenWrapper = null
    this.overlayWrapper = []

    // Methods bind
    this.setSceneTimeline = setSceneTimeline.bind(this)
    this.getProjectWidth = getProjectWidth.bind(this)
    this.handleProgressChange = handleProgressChange.bind(this)
    this.playVideo = playVideo.bind(this)
    this.playVoiceover = playVoiceover.bind(this)
    this.playAudio = playAudio.bind(this)
    this.playSound = playSound.bind(this)
    this.pauseTimeline = pauseTimeline.bind(this)
    this.toggleShowStage = toggleShowStage.bind(this)
    this.updateSlider = updateSlider.bind(this)
    this.completePreview = completePreview.bind(this)
    this.SetPreviewStage = SetPreviewStage.bind(this)
    this.setTextItem = setTextItem.bind(this)
    this.updateProjectValue = updateProjectValue.bind(this)
    this.onTextResizeEnd = onTextResizeEnd.bind(this)
    this.onTextDrag = onTextDrag.bind(this)
    this.onTextDragEnd = onTextDragEnd.bind(this)
    this.onTextResizing = onTextResizing.bind(this)
    this.setProjectVal = setProjectVal.bind(this)
  }

  renderVideoObjects() {
    const { project, SetStageWidth, SetStageHeight, timeSlider } = this.props

    const stageWidth = parseInt(
      this.stageRef.current.getBoundingClientRect().width,
    )
    const stageHeight = Math.round((stageWidth * 503) / 896.25)
    let clonedProject = _.cloneDeep(project)

    this.getCurrentScale(project, stageWidth, stageHeight, clonedProject)

    const stageAspectRatio = this.getStageAspectRatio(this.stageRef.current)
    this.setState({
      slideV: timeSlider.value,
      stageAspectRatio,
    })

    SetStageWidth(stageWidth)
    SetStageHeight(stageHeight)

    const tweenObjectBuilder = new TweenObjectBuilder(
      this.tweenWrapper,
      timeSlider,
    )
      .withEvents(this.updateSlider.bind(this), this.completePreview.bind(this))
      .withHTMLAssets(VIDEO.code, this.sceneItems)
      .withHTMLAssets(TEXT.code, this.sceneTexts)
      .withHTMLAssets(AUDIO.code, this.sceneSounds)
      .withHTMLAssets(MUSIC.code, this.sceneMusics)
      .withHTMLAssets(VOICEOVER.code, this.sceneVoiceovers)
      .withCurrentScene(this.props.scenePlaying)

    const previewTimeLine = tweenObjectBuilder.build(project).previewTimeLine

    this.props.setTweenObjectBuilder &&
      this.props.setTweenObjectBuilder(tweenObjectBuilder)
    this.props.SetPreviewTimeline &&
      this.props.SetPreviewTimeline(previewTimeLine)
  }

  async componentDidMount() {
    this.renderVideoObjects()

    window.addEventListener('resize', this.handleWindowResize.bind(this))
  }

  handleWindowResize = () => {
    const aspectRatioWidth = this.getAspectRatioWidth(
      this.tweenWrapper,
      this.props.project.resolution,
    )
    const stageAspectRatio = this.getStageAspectRatio(this.stageRef.current)
    this.setState({ aspectRatioWidth, isResizing: true, stageAspectRatio })
    clearTimeout(this.resizeDebounce)
    this.resizeDebounce = setTimeout(this.handleDoneResizing.bind(this), 500)
  }

  handleDoneResizing = () => {
    this.setState({ isResizing: false })
  }

  // populate a dictionary of font sizes indexed by text id
  handleFontResize = (id, size, contWidth, contHeight) => {
    const fontSizesByTextId = { ...this.state.fontSizesByTextId }
    fontSizesByTextId[id] = size
    this.setState({ fontSizesByTextId })

    // Update textbox once mounted to the DOM to create the proper resized font size
    if (this.props.project.texts.length && contWidth && contHeight) {
      let getTextIndex
      const getText = _.cloneDeep(this.props.project.texts)[
        this.props.project.resolution
      ].find((text, index) => {
        if (text.id === id) {
          getTextIndex = index
          return text
        }
      })

      if (this.textWrapper?.current && getText) {
        this.onTextResizeEnd(
          contWidth,
          contHeight,
          getTextIndex,
          size,
          false,
          getText,
        )
      }
    }
  }

  getCurrentScale(project, stageWidth, stageHeight, projectVal) {
    const scaleVal =
      project.scenes[project.resolution] &&
      project.scenes[project.resolution].length
        ? Math.round(stageWidth / project.scenes[project.resolution][0].p_width)
        : 1
    const hScaleVal =
      project.scenes[project.resolution] &&
      project.scenes[project.resolution].length
        ? Math.round(
            stageHeight / project.scenes[project.resolution][0].p_height,
          )
        : 1

    if (projectVal.id !== '0') {
      this.setState({
        scale: scaleVal === 0 ? 1 : scaleVal,
        hScale: hScaleVal === 0 ? 1 : hScaleVal,
      })
    }

    if (
      (scaleVal !== 1 && scaleVal > 0) ||
      (hScaleVal !== 1 && hScaleVal > 0)
    ) {
      if (projectVal.scenes[project.resolution].length) {
        for (let p = 0; p < projectVal.scenes[project.resolution].length; p++) {
          projectVal.scenes[project.resolution][p].wScale =
            hScaleVal === 0 ? 1 : hScaleVal
          projectVal.scenes[project.resolution][p].hScale =
            scaleVal === 0 ? 1 : scaleVal
        }
      }

      if (projectVal.texts[project.resolution].length) {
        for (let t = 0; t < projectVal.texts[project.resolution].length; t++) {
          projectVal.texts[project.resolution][t].wScale =
            scaleVal === 0 ? 1 : scaleVal
          projectVal.texts[project.resolution][t].hScale =
            hScaleVal === 0 ? 1 : hScaleVal
        }
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowResize.bind(this))
    this.pauseTimeline()
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      project,
      watchProject,
      scenePlaying,
      previewTimeLine,
      timeSlider,
      updateSlideV,
    } = this.props

    // Reload timeline when the projectSteps length changes
    if (this.props.projectSteps.length < prevProps.projectSteps.length) {
      this.handleProgressChange(1)
      this.props.reloadStageByProject(project)
    }

    const isProjectChanged = prevProps.watchProject !== watchProject
    const isSelectedResolutionChanged =
      prevProps.project?.resolution !== project?.resolution

    if (isProjectChanged && isSelectedResolutionChanged) {
      this.updateProjectValue(project, 0)
    }
    if (isProjectChanged) {
      this.pauseTimeline()
      this.props.reloadStageByProject(project)
      return
    }

    const isScenePlayingChanged = scenePlaying !== prevProps.scenePlaying
    const isSliderVerticalMoved = updateSlideV !== prevProps.updateSlideV
    const isAtTheEndOfProject = project.duration <= updateSlideV

    if (
      isScenePlayingChanged &&
      previewTimeLine &&
      scenePlaying &&
      isAtTheEndOfProject
    ) {
      console.log('Restarted to Beginning')
      this.handleProgressChange(0)
      previewTimeLine.seek(0)
      previewTimeLine.play()
    }

    if (
      isScenePlayingChanged &&
      previewTimeLine &&
      scenePlaying &&
      !isAtTheEndOfProject
    ) {
      previewTimeLine.seek(timeSlider.curSec / 1000)
      previewTimeLine.play()
    }

    if (isScenePlayingChanged && previewTimeLine && !scenePlaying) {
      this.pauseTimeline()
      this.props.reloadStageByProject(project)
    }

    if (
      (isScenePlayingChanged && isScenePlayingChanged) ||
      isSliderVerticalMoved
    ) {
      this.handleProgressChange(
        updateSlideV,
        Math.abs(prevProps.timeSlider.curSec - timeSlider.curSec) > 500, // Move timeline when a user rewind / forward
      )
    }
  }

  getAudioTypeByUrl = (url) => {
    const lowerCased = url.toLowerCase()
    if (_.endsWith(lowerCased, 'wav')) {
      return 'audio/wav'
    }
    if (_.endsWith(lowerCased, 'ogg')) {
      return 'audio/ogg'
    }
    return 'audio/mpeg'
  }

  renderAudio() {
    const { project } = this.props
    const projectSounds = _.get(project, 'sound', [])
    return (
      <div className="">
        {projectSounds.map((el, k) => {
          return (
            <audio
              key={k}
              ref={(e) => (this.sceneSounds[k] = e)}
              controls={false}
              playsInline={true}
              muted={false}
              onLoadedData={() => (this.soundLoaded[k] = true)}
              preload={'auto'}>
              <source src={el.url} type={'video/mp4'} />
            </audio>
          )
        })}
      </div>
    )
  }

  renderMusic() {
    const { project } = this.props
    const projectMusics = _.get(project, 'music', [])
    return (
      <div className="">
        {projectMusics.map((el, k) => {
          return (
            <audio
              key={k}
              ref={(e) => (this.sceneMusics[k] = e)}
              controls={false}
              playsInline={true}
              muted={false}
              onLoadedData={() => (this.audioLoaded[k] = true)}
              preload={'auto'}>
              <source src={el.url} type={this.getAudioTypeByUrl(el.url)} />
            </audio>
          )
        })}
      </div>
    )
  }

  renderVoiceover() {
    const { project } = this.props
    const items = _.get(project, 'voiceover', [])
    return (
      <div className="">
        {items.map((el, k) => {
          return (
            <audio
              key={k}
              ref={(e) => (this.sceneVoiceovers[k] = e)}
              controls={false}
              playsInline={true}
              muted={false}
              onLoadedData={() => (this.voiceoverLoaded[k] = true)}
              preload={'auto'}>
              <source src={el.url} type={this.getAudioTypeByUrl(el.url)} />
            </audio>
          )
        })}
      </div>
    )
  }

  get allAssetsAreLoaded() {
    const allVideosAreLoaded = _.every(this.state.videoLoaded)
    return allVideosAreLoaded
  }

  getStageAspectRatio(htmlElement) {
    const clientRect = htmlElement?.getBoundingClientRect()
    const clientRectAspectRatio = clientRect?.width / clientRect?.height
    return clientRectAspectRatio
  }

  getAspectRatioWidth = (htmlElement, resolution) => {
    const clientRect = htmlElement?.getBoundingClientRect()
    const clientRectAspectRatio = clientRect?.width / clientRect?.height
    const selectedAspectRatio = eval(ASPECT_RATIO[resolution])

    const isLandscape = selectedAspectRatio > 1
    const isPortrait = selectedAspectRatio < 1
    const isSquare = selectedAspectRatio === 1
    if (isLandscape && clientRectAspectRatio > selectedAspectRatio) {
      return clientRect.height * selectedAspectRatio
    }

    if (isLandscape && clientRectAspectRatio <= selectedAspectRatio) {
      return clientRect.width
    }

    if (isSquare && clientRectAspectRatio > 1) {
      return clientRect.height
    }

    if (isSquare && clientRectAspectRatio <= 1) {
      return clientRect.width
    }

    if (isPortrait && clientRectAspectRatio >= selectedAspectRatio) {
      return clientRect.height * selectedAspectRatio
    }

    if (isPortrait && clientRectAspectRatio < selectedAspectRatio) {
      return clientRect.width
    }
  }

  changeCompleteSlider() {
    // Reset progress value to allow re mounting of video scenes, to allow video visaulisation in player
    setTimeout(() => {
      const { slideV } = this.state
      this.handleProgressChange(slideV + 1)
    }, 500)
  }

  togglePlayVideo(flag) {
    const { project } = this.props

    if (project.duration === 0) {
      return
    }

    this.props.setPlayCompleted(!flag)

    this.props.togglePlay(flag)
  }

  renderReviewSlider() {
    const { slideV } = this.state
    const { timeSlider, project, scenePlaying } = this.props
    return (
      <div className="cropper-playback-control editor-review">
        <div className="cropper-playback-control__container">
          <div
            className="cropper-playback-control__container-play"
            onClick={() => this.togglePlayVideo(!scenePlaying)}>
            {scenePlaying ? '| |' : <CropperPlayIcon />}
          </div>
          <div className="cropper-playback-control__container-slider">
            <ChakraSlider
              size="sm"
              aria-label="slider-ex-2"
              onChangeEnd={this.changeCompleteSlider.bind(this)}
              onChange={(val) => {
                this.handleProgressChange(val)
              }}
              defaultValue={slideV || 0}
              value={slideV || 0}
              max={10000}>
              <SliderTrack bg="gray">
                <SliderFilledTrack bg="green" />
              </SliderTrack>
              <SliderThumb />
            </ChakraSlider>
          </div>
          <div className="cropper-playback-control__container-timer">
            <span>
              {ConvertDuration(timeSlider.curSec)} /{' '}
              {ConvertDuration(project.duration)}
            </span>
          </div>
        </div>
      </div>
    )
  }

  renderSlider() {
    const { slideV } = this.state
    return (
      <div className="progress absolute left-0 right-0 bottom-0">
        <RangeSlider
          className="h-full"
          min={0}
          max={10000}
          value={[0, slideV]}
          step={0.001}
          onInput={([, value]) => this.handleProgressChange(value)}
          thumbsDisabled={[true, false]}
          rangeSlideDisabled={true}
          onRangeDragEnd={this.changeCompleteSlider.bind(this)}
        />
      </div>
    )
  }

  renderLoadingSpinner() {
    return <Progress size="xs" isindeterminate="true" />
  }

  renderVideoController() {
    const { scenePlaying, togglePlay, project, timeSlider, updateTimeSlider } =
      this.props

    return (
      <div className="video-controller flex justify-start items-center w-full">
        <VideoTime slider={timeSlider} project={project} />
        <VideoControl
          scenePlaying={scenePlaying}
          togglePlay={togglePlay}
          updateTimeSlider={updateTimeSlider}
          scenevideos={this.sceneVideos}
          videoloaded={this.videoLoaded}
          scenemusics={this.sceneMusics}
          audioloaded={this.audioLoaded}
          scenesounds={this.sceneSounds}
          soundloaded={this.soundLoaded}
          setPlayCompleted={this.props.setPlayCompleted}
        />
      </div>
    )
  }

  renderStage = (height, curSec, resolution) => {
    const { scale, hScale, stageAspectRatio } = this.state
    const { project } = this.props
    const selectedAspectRatio = ASPECT_RATIO[resolution]
    let scenes = _.get(project, `scenes.${resolution}`, [])

    // Preload only two videos starting with the one
    // With time frame within the current time
    const renderScenes = []
    const activeScenes = []
    const sortedScenesEndTime = [...scenes].sort(
      (a, b) => a.end_time - b.end_time,
    )
    const unplayedScenes = sortedScenesEndTime.filter(
      (x) => x.end_time > curSec,
    )
    for (var i = 0; i < unplayedScenes.length; i++) {
      if (activeScenes.length < 2) {
        activeScenes.push(unplayedScenes[i])
      }
    }
    for (var j = 0; j < scenes.length; j++) {
      if (activeScenes.find((x) => x.id == scenes[j].id)) {
        renderScenes.push(scenes[j])
      } else {
        renderScenes.push({})
      }
    }
    return (
      <div
        ref={this.stageRef}
        className="video-wrap relative inline-block w-full"
        style={{ height }}>
        <div className="w-full flex justify-center items-center">
          <div
            className="fixed-height-aspect-ratio"
            style={{
              '--height': height,
              '--aspect-ratio': `(${stageAspectRatio})`,
            }}>
            <div className="flex justify-center items-center">
              <AspectRatio
                ratio={selectedAspectRatio}
                className="percent-height-aspect-ratio"
                style={{
                  '--height': '100%',
                  '--stage-ratio': `calc(${stageAspectRatio})`,
                }}>
                <div
                  ref={(main) => (this.tweenWrapper = main)}
                  className="w-full h-full main-canvas-container flex justify-center items-center select-none overflow-hidden"
                  id={this.props.id}
                  style={
                    this.props.id === 'publish-movie-player'
                      ? {
                          backgroundImage: `url(${
                            renderScenes.find(
                              (x) =>
                                !x?.thumbUrl?.includes('blank_') && x?.thumbUrl,
                            )?.thumbUrl
                          })`,
                          backgroundSize: 'cover',
                        }
                      : {}
                  }>
                  {/* {!selectedScene && (
            <img src={BlankBGImg} alt="Video" className="video" />
          )} */}

                  {project &&
                    _.map(renderScenes, (scene, s) => {
                      return (
                        <BgClip
                          ref={(bg) => (this.sceneItems[s] = bg)}
                          key={`slide-${s}`}
                          scene={scene}
                          s={s}
                          scale={scale}
                          h_scale={hScale}
                          scenevideos={this.sceneVideos}
                          // onBuffering={() => {
                          //   this.props.setPlaying(false)
                          // }}
                        />
                      )
                    })}

                  <div className="w-full h-full absolute top-0 left-0 cursor-pointer" />
                  {this.renderText()}
                </div>
              </AspectRatio>
            </div>
          </div>
          {!this.props.id === 'publish-movie-player' && this.renderSlider()}
        </div>
      </div>
    )
  }

  renderText() {
    const { fontSizesByTextId, scale, hScale } = this.state
    const { disableTextResize, project, selectedText, selectedTextIndex } =
      this.props
    const textItemsByRatio = _.get(
      project,
      `${TEXT.projectPath}.${project.resolution}`,
      [],
    )

    const isTextMoveableDisplayable = this.isTextMoveableDisplayable()
    return (
      !_.isEmpty(textItemsByRatio) &&
      this.tweenWrapper && (
        <div
          className="relative text-ani-wrapper"
          id="cerebriam-text-wrapper"
          ref={this.textWrapper}
          style={{
            width: 'inherit',
            height: 'inherit',
          }}>
          {isTextMoveableDisplayable && (
            <TextStageMove
              moveRef={this.sceneTexts[selectedTextIndex]}
              contRef={this.textWrapper}
              tweenWrapperRef={this.tweenWrapper}
              dragStart={this.handleTextDragStart.bind(this)}
              dragEnd={this.handleTextDragEnd.bind(this)}
              drag={this.handleTextDrag.bind(this)}
              onReSize={this.onTextResizing}
              resizeEnd={this.onTextResizeEnd}
              fontSize={fontSizesByTextId[selectedText.id]}
              ind={selectedTextIndex}
            />
          )}

          {textItemsByRatio &&
            textItemsByRatio.map((text, t) => {
              return (
                <TextClip
                  contRef={this.textWrapper}
                  ref={(textEl) => (this.sceneTexts[t] = textEl)}
                  scale={scale}
                  h_scale={hScale}
                  isTextMoveableDisplayable={isTextMoveableDisplayable}
                  onFontResize={this.handleFontResize}
                  className={
                    'absolute flex justify-center' +
                    (selectedText && selectedText.id === text.id
                      ? ' active-text'
                      : '')
                  }
                  onClick={() => this.reselectText(text)}
                  onDoubleClick={() => this.props.openEditTextModal(true, text)}
                  onTouchStart={this.handleTouchStart.bind(this, text)}
                  onTouchEnd={this.handleTouchEnd.bind(this)}
                  project={project}
                  text={text}
                  key={`text-${t}`}
                  indexValue={t}
                  resolution={project.resolution}
                  ratioTexts={textItemsByRatio}
                />
              )
            })}
        </div>
      )
    )
  }

  isTextMoveableDisplayable() {
    const { disableTextResize, selectedText, scenePlaying, timeSlider } =
      this.props
    const { isResizing } = this.state
    const currentTime = timeSlider.curSec
    const isTimeAround =
      selectedText &&
      ProjectItemUtils.isTimeAroundItemMoveable(currentTime, selectedText)
    return (
      !disableTextResize &&
      selectedText &&
      !isResizing &&
      !scenePlaying &&
      isTimeAround
    )
  }

  clearEventsTimeouts = (timeoutIds) => {
    if (timeoutIds.length) {
      for (let id of timeoutIds) {
        clearTimeout(id)
      }
      this.setState({ dragSets: [] })
    }
  }

  handleTouchStart = (text) => {
    const timeOut = setTimeout(() => {
      this.props.openEditTextModal(true, text)
      this.props.updateShowSidebarMedia(TEXT.code)
    }, 800)
    const existState = this.state.dragSets
    existState.push(timeOut)
    this.setState({
      dragSets: existState,
    })
  }
  handleTouchEnd = () => {
    // prevent short touches from firing doubke click
    const timeoutIds = this.state.dragSets
    this.clearEventsTimeouts(timeoutIds)
  }

  handleTextDragStart = () => {
    const timeOut = setTimeout(() => {
      this.props.openEditTextModal(true, this.props.selectedText)
    }, 800)
    const existState = this.state.dragSets
    existState.push(timeOut)
    this.setState({
      dragSets: existState,
    })
  }
  handleTextDragEnd = (top, left, i) => {
    // prevent short touches from firing doubke click
    const timeoutIds = this.state.dragSets
    this.clearEventsTimeouts(timeoutIds)

    this.onTextDragEnd(top, left, i)
  }
  handleTextDrag = (top, left, i) => {
    // Clear timeout while dragging item
    const timeoutIds = this.state.dragSets
    this.clearEventsTimeouts(timeoutIds)
    this.onTextDrag(top, left, i)
  }

  reselectText(text) {
    const { project } = this.props
    const textItems = _.get(
      project,
      `${TEXT.projectPath}.${project.resolution}`,
      [],
    )
    const newTextIndex = _.findIndex(textItems, (item) => item.id === text?.id)
    this.props.setSelectedText(text)
    this.props.setSelectedTextIndex(newTextIndex)
  }

  render() {
    const {
      showTextPanel,
      isLoading,
      height,
      timeSlider: { curSec },
      project,
      showMedia,
    } = this.props

    return (
      <div
        className={
          // 'main-container updated' + (showTextPanel ? ' w-full' : ' w-3/4')
          `main-container${
            !this.props.id === 'publish-movie-player' ? ' updated' : ''
          } w-full${showMedia ? ' expanded' : ''}`
        }>
        {(!this.allAssetsAreLoaded || isLoading) && this.renderLoadingSpinner()}
        {height && this.renderStage(height, curSec, project.resolution)}
        {!height && (
          <ChakraAspectRatio ratio={16 / 9}>
            {this.renderStage('100%', curSec, project.resolution)}
          </ChakraAspectRatio>
        )}
        {this.props.id !== 'publish-movie-player' &&
          this.renderVideoController()}
        {this.props.id === 'publish-movie-player' && this.renderReviewSlider()}
        <div className="opacity-0 w-0 h-0 overflow-hidden">
          {this.renderAudio()}
          {this.renderMusic()}
          {this.renderVoiceover()}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  watchProject: state.editor.watchProject,
  project: state.editor.project,
  projectSteps: state.editor.projectSteps,
  previewTimeLine: state.timeline.previewTimeLine,
  timeSlider: state.timeline.timeSlider,
  selectedSceneIndex: state.selectedItems.selectedSceneIndex,
  selectedScene: state.selectedItems.selectedScene,
  selectedTextIndex: state.selectedItems.selectedTextIndex,
  selectedText: state.selectedItems.selectedText,
  tweenObjectBuilder: state.mainContainer.tweenObjectBuilder,
  showStage: state.mainContainer.showStage,
  isLoading: state.mediaPlayer.isLoading,
  currentTextItem: state.mainContainer.currentTextItem,
})

const mapDispatchToProps = (dispatch) => ({
  SetTimeSlider: (...any) => dispatch(SetTimeSlider(...any)),
  SetPreviewTimeline: (...any) => dispatch(SetPreviewTimeline(...any)),
  SetStageWidth: (...any) => dispatch(SetStageWidth(...any)),
  SetStageHeight: (...any) => dispatch(SetStageHeight(...any)),
  toggleWatchProject: (...any) => dispatch(toggleWatchProject(...any)),
  setSelectedScene: (...any) =>
    dispatch(SelectedItemActions.setSelectedScene(...any)),
  setSelectedSceneIndex: (...any) =>
    dispatch(SelectedItemActions.setSelectedSceneIndex(...any)),
  setSelectedText: (...any) =>
    dispatch(SelectedItemActions.setSelectedText(...any)),
  setSelectedTextIndex: (...any) =>
    dispatch(SelectedItemActions.setSelectedTextIndex(...any)),
  checkAddItem: (...any) => dispatch(checkAddItem(...any)),
  updateProject: (...any) => dispatch(updateProject(...any)),
  storeProjectSteps: (...any) => dispatch(storeProjectSteps(...any)),
  addScenesClip: (...any) => dispatch(addScenesClip(...any)),
  reloadStageByProject: () =>
    dispatch(MainContainerActions.reloadStageByProject()),
  setTweenObjectBuilder: (...any) =>
    dispatch(MainContainerActions.setTweenObjectBuilder(...any)),
  openEditTextModal: (...any) =>
    dispatch(MainContainerActions.openEditTextModal(...any)),
  pauseTimeline: (...any) => dispatch(MediaPlayerActions.pauseTimeline(...any)),
  setPlayCompleted: (...any) =>
    dispatch(MediaPlayerActions.setPlayCompleted(...any)),
  setPlaying: (...any) => dispatch(MediaPlayerActions.setPlaying(...any)),
})

export default connect(mapStateToProps, mapDispatchToProps)(MainContainer)
