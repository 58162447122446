import React from 'react'
import { number } from 'prop-types'

function Dot(props) {
  return (
    <span
      style={{
        display: 'inline-block',
        margin: '7px 5px',
        opacity: props.selected ? '1' : '0.3',
        transitionDuration: '300ms',
        backgroundColor: props.selected ? '#982ddd' : '#c3cdd5',
      }}
      className={'w-6 h-2 rounded'}
    />
  )
}

export default function IndicatorDots(props) {
  if (props.total < 2) {
    // Hide dots when there is only one dot.
    return <div className={'absolute w-full z-100 bottom-0 text-center'} />
  } else {
    return (
      <div className={'absolute w-full z-100 bottom-0 text-center'}>
        {Array.apply(null, Array(props.total)).map((x, i) => {
          return <Dot key={i} selected={props.index === i} />
        })}
      </div>
    )
  }
}

IndicatorDots.propTypes = {
  index: number.isRequired,
  total: number.isRequired,
}
