import { Link } from 'react-router-dom'
import ClientImage from '../../assets/images/Cerebriam_Studio_Logo_rgb2_300.webp'
import { HOME } from '../../constants/Routes'

const Header = () => {
  return (
    <div className="cerebriam-header cerebriam-container">
      <div className="cerebriam-header__container">
        <Link to={HOME}>
          <img src={ClientImage} alt="site-logo" />
        </Link>
        <Link to={HOME} className="cerebriam-back__btn">
          Free App
        </Link>
      </div>
    </div>
  )
}

export default Header
