import React, { Component } from 'react'
import ReactModal from 'react-modal'

class LowerNotifyModal extends Component {
  constructor(props) {
    super(props)

    ReactModal.setAppElement('#root')
  }

  render() {
    return (
      <ReactModal
        isOpen={true}
        contentLabel={'Low Quality Warning'}
        parentSelector={() => document.querySelector('#root')}
        style={{
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          },
          content: {
            border: '1px solid #ccc',
            background: '#fff',
            overflow: 'auto',
            WebkitOverflowScrolling: 'touch',
            borderRadius: '4px',
            outline: 'none',
            padding: '20px',
            position: 'initial',
            width: '400px',
          },
        }}>
        <p className="mb-4 w-full">
          The quality of video from your camera is low, and we advise that you
          upgrade your device to make the best of Cerebriam Studio.
        </p>

        <div className="flex items-center justify-end">
          <button
            className="rounded bg-blue-600 hover:bg-blue-500 py-2 px-3 mr-2"
            onClick={this.props.closeNotifyModal}>
            CLOSE
          </button>
        </div>
      </ReactModal>
    )
  }
}

export default LowerNotifyModal
