import {
  DownloadIconPng,
  FBIconPng,
  LinkedInIconPng,
  SnapChatIconPng,
  YoutubeIconPng,
  InstagramIconPng,
  TwitterIconPng,
  TikTokIconPng,
  TwitchIconPng,
} from '../../video-editor/common-items/preview/PreviewIcon'

const PLATFORMS = {
  YOUTUBE: 'youtube',
  FACEBOOK: 'facebook',
  LINKEDIN: 'linkedin',
  SNAPCHAT: 'snapchat',
  INSTAGRAM: 'instagram',
  TWITTER: 'twitter',
  TIKTOK: 'tiktok',
  DOWNLOAD: 'download',
  TWITCH: 'twitch',
}

export const ICONS = {
  [PLATFORMS.YOUTUBE]: YoutubeIconPng,
  [PLATFORMS.FACEBOOK]: FBIconPng,
  [PLATFORMS.LINKEDIN]: LinkedInIconPng,
  [PLATFORMS.SNAPCHAT]: SnapChatIconPng,
  [PLATFORMS.INSTAGRAM]: InstagramIconPng,
  [PLATFORMS.TWITTER]: TwitterIconPng,
  [PLATFORMS.TIKTOK]: TikTokIconPng,
  [PLATFORMS.DOWNLOAD]: DownloadIconPng,
  [PLATFORMS.TWITCH]: TwitchIconPng,
}

export default PLATFORMS
