import React from 'react'
import _ from 'lodash'
import Loader from 'react-loader-spinner'
import WaveSurfer from 'wavesurfer.js'
import TimelineMoveable from './TimelineMoveable'
import * as TimelineService from '../../services/time/TimelineService'
import { ONE_SECOND } from '../../constants/Timeline'

const WAVE_HEIGHT = 40

class AudioTimelineItem extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: true,
      dragSets: [],
    }

    this.itemRef = React.createRef()
    this.wavesurfer = React.createRef()
    this.wavesurferCanvas = React.createRef()
  }

  componentDidUpdate(prevProps) {
    const { item } = this.props
    if (item.start_time !== prevProps.item.start_time) {
      this.reload()
    }
  }

  reload = () => {
    this.setState({ loading: true }, () => {
      this.setState({ loading: false })
    })
  }

  getPixelsFromMilliseconds = (duration) => {
    return (duration / ONE_SECOND) * 20
  }

  getHeight = () => {
    const { height } = this.props
    return height || WAVE_HEIGHT
  }

  handleItemClicked = (event) => {
    const { item, itemIndex, zoomValue, getZoomDeltaPx } = this.props
    const clientRect = event.target.getBoundingClientRect()
    const itemBoxLeftPosition = clientRect.left

    const mouseLeftPosition =
      event.inputEvent?.clientX ||
      event.clientX ||
      event?.targetTouches[0]?.clientX
    const deltaPixels = mouseLeftPosition - itemBoxLeftPosition
    const deltaTime = TimelineService.convertPixelToSeconds(
      getZoomDeltaPx(deltaPixels, zoomValue),
    )
    const newCurrentTime = item.start_time + deltaTime
    this.props.onItemSelected &&
      this.props.onItemSelected(item, itemIndex, true, newCurrentTime)
  }

  clearEventsTimeouts = (timeoutIds) => {
    if (timeoutIds.length) {
      for (let id of timeoutIds) {
        clearTimeout(id)
      }
      this.setState({ dragSets: [] })
    }
  }

  handleTouchStart = () => {
    const timeOut = setTimeout(() => {
      this.handleDoubleClick()
    }, 800)
    const existState = this.state.dragSets
    existState.push(timeOut)
    this.setState({
      dragSets: existState,
    })
  }
  handleTouchEnd = () => {
    // prevent short touches from firing doubke click
    const timeoutIds = this.state.dragSets
    this.clearEventsTimeouts(timeoutIds)
  }

  handleItemDragStart = (event) => {
    const timeOut = setTimeout(() => {
      this.handleDoubleClick()
    }, 800)
    const existState = this.state.dragSets
    existState.push(timeOut)
    this.setState({
      dragSets: existState,
    })
  }
  handleItemDragEnd = (newItemLeft, event) => {
    const { onPositionChanged } = this.props

    if (newItemLeft !== 0) {
      onPositionChanged && onPositionChanged(newItemLeft)
    } else {
      // prevent short touches from firing doubke click
      const timeoutIds = this.state.dragSets
      this.clearEventsTimeouts(timeoutIds)

      this.handleItemClicked(event)
    }
  }
  handleItemDrag = () => {
    // Clear timeout while dragging item
    const timeoutIds = this.state.dragSets
    this.clearEventsTimeouts(timeoutIds)
  }

  handleItemResizing = (widthDelta) => {
    const { onWidthChanged } = this.props
    const originalWidth = this.itemRef.current.getBoundingClientRect().width
    const newWidth = originalWidth + widthDelta
    onWidthChanged && onWidthChanged(newWidth)
  }

  handleResizeEnd = (widthDelta) => {
    const { onResizeEnd } = this.props
    onResizeEnd && onResizeEnd(widthDelta)
  }

  isActive = () => {
    const { activeItem, item } = this.props
    return activeItem === item
  }

  handleOnAudioCanPlay = () => {
    let xhr = {
      cache: 'reload',
      mode: 'cors',
      method: 'GET',
      credentials: 'same-origin',
      redirect: 'follow',
      referrer: 'about:client',
      referrerPolicy: 'origin-when-cross-origin',
      headers: [{ key: 'Authorization', value: localStorage.jwtTokenCTEditor }],
    }
    if (!this.wavesurfer.current) {
      this.wavesurfer.current = WaveSurfer.create({
        container: this.wavesurferCanvas.current,
        barHeight: 2,
        fillParent: false,
        mediaControls: true,
        margin: 0,
        cursorWidth: 0,
        height: this.getHeight(),
        hideScrollbar: true,
        progressColor: 'gray',
        responsive: false,
        waveColor: 'gray',
        pixelRatio: 20,
        minPxPerSec: 20,
        xhr,
      })
    }

    this.reloadWaves()
  }

  handleWavesurferLoaded = () => {
    this.setState({ loading: false })
  }

  handleDoubleClick = () => {
    const { onDoubleClick, item } = this.props
    onDoubleClick && onDoubleClick(item)
  }

  reloadWaves = () => {
    const { item } = this.props
    if (this.wavesurfer.current) {
      this.wavesurfer.current.on('ready', () => this.handleWavesurferLoaded())
      this.wavesurfer.current.load(item.url)
    }
    this.forceUpdate()
  }

  reloadWaveSurfer = () => {
    const { item } = this.props
    if (this.wavesurfer.current) {
      this.wavesurfer.current.load(item.url)
    }
  }

  renderDisplayContainer() {
    const { item } = this.props
    const { loading } = this.state
    const duration = item?.clip?.duration
    const pixelWidth = TimelineService.convertMillisecondsToPixels(duration)
    const pixelLeft = TimelineService.convertMillisecondsToPixels(
      item.start_time,
    )
    const clipStart = TimelineService.convertMillisecondsToPixels(
      item.clip.start,
    )
    const clippedLeftPosition = -clipStart
    const additionalProps = this.isActive()
      ? {}
      : {
          onClick: this.handleItemClicked,
          onTouchStart: this.handleTouchStart.bind(this),
          onTouchEnd: this.handleTouchEnd.bind(this),
        }
    return (
      <div
        ref={this.itemRef}
        className={[
          'absolute',
          'border-l',
          'border-r',
          'cursor-pointer',
          'border-teal-500',
          'whitespace-no-wrap',
        ].join(' ')}
        style={{
          width: `${pixelWidth}px`,
          left: `${pixelLeft}px`,
          height: `${this.getHeight()}px`,
          top: 0,
          overflow: 'clip',
        }}
        onDoubleClick={() => this.handleDoubleClick()}
        {...additionalProps}>
        <audio src={item.url} onCanPlayThrough={this.handleOnAudioCanPlay} />
        <div
          className="absolute"
          ref={this.wavesurferCanvas}
          style={{
            zIndex: 0,
            left: `${clippedLeftPosition}px`,
          }}
        />
        {loading && (
          <div className="flex absolute top-0 w-full h-full items-center justify-center">
            <Loader type="Puff" color="gray" height={20} width={20} />
          </div>
        )}
        <div
          className="flex absolute top-0 w-full h-full items-center justify-center"
          style={{
            zIndex: 1,
            height: `${this.getHeight()}px`,
            overflow: 'hidden',
            fontWeight: this.isActive() ? 'bold' : 'normal',
          }}>
          {this.props.displayLabel ? (
            <div className="text-center overflow-hidden whitespace-no-wrap border-0">
              {_.isFunction(this.props.displayLabel)
                ? this.props.displayLabel(item)
                : item.name}
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    )
  }

  renderMoveableWrapper() {
    const {
      item,
      itemTimelineHTML,
      verticalGuidelines,
      channel,
      scrollbarRef,
      scrollableTimelineBox,
      zoomValue,
    } = this.props
    return (
      <TimelineMoveable
        timelineItem={item}
        channel={channel}
        target={this.itemRef.current}
        container={itemTimelineHTML}
        verticalGuidelines={verticalGuidelines}
        draggable={true}
        resizable={this.isActive()}
        throttleDrag={0}
        startDragRotate={0}
        throttleDragRotate={0}
        onDrag={this.handleItemDrag.bind(this)}
        onDragStart={this.handleItemDragStart.bind(this)}
        onDragEnd={this.handleItemDragEnd.bind(this)}
        onResize={this.handleItemResizing.bind(this)}
        onResizeEnd={this.handleResizeEnd.bind(this)}
        zoom={1}
        origin={this.isActive()}
        scrollbarRef={scrollbarRef}
        scrollableTimelineBox={scrollableTimelineBox}
        zoomValue={zoomValue}
      />
    )
  }

  render() {
    const { loading } = this.state
    return (
      <React.Fragment>
        {this.renderDisplayContainer()}
        {!loading &&
          this.isActive() &&
          !this.props.scenePlaying &&
          this.renderMoveableWrapper()}
      </React.Fragment>
    )
  }
}

export default AudioTimelineItem
