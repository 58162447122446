import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import Spinner from '../../common/Spinner'
import { getUsers } from '../../../redux/actions/admin/users/ListUsers'

class ListUsers extends Component {
  componentDidMount() {
    this.props.getUsers()
  }

  render() {
    // TODO: WA: We need pagination mechanism here.
    const { loadStatus } = this.props

    if (loadStatus === 'available') {
      const { users } = this.props
      return (
        <div className="main-area home-area">
          <div className="w-full flex justify-center h-full">
            <table className="table-auto">
              <thead>
                <tr>
                  <th className="px-4 py-2">Email</th>
                  <th className="px-4 py-2">Name</th>
                  <th className="px-4 py-2">Remaining Credits</th>
                  <th className="px-4 py-2">Woocommerce Customer ID</th>
                </tr>
              </thead>
              <tbody>
                {users.map((user, index) => (
                  <tr
                    key={user._id}
                    className={index % 2 === 0 ? '' : 'bg-gray-100'}>
                    <td className="border px-4 py-2">
                      <Link
                        className="text-sm text-blue-500 hover:text-blue-800"
                        to={'/admin/users/' + user._id}>
                        {user.email}
                      </Link>
                    </td>
                    <td className="border px-4 py-2">{user.name}</td>
                    <td className="border px-4 py-2">{user.credits}</td>
                    <td className="border px-4 py-2">{user.wc_customer_id}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )
    }

    if (loadStatus === 'loading') {
      return <Spinner />
    }

    if (loadStatus === 'error') {
      return <div>Something went wrong. Please try again later.</div>
    }
  }
}

export default connect(
  (state) => ({
    loadStatus: state.adminListUsers.loadStatus,
    users: state.adminListUsers.users,
  }),
  {
    getUsers,
  },
)(withRouter(ListUsers))
