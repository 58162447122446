import AudioContext from './AudioContext'
import { isMobile } from 'react-device-detect'

const AudioVisualizer = {
  visualizeSineWave(
    stream,
    canvasCtx,
    canvas,
    width,
    height,
    backgroundColor,
    strokeColor,
  ) {
    AudioContext.setAudioContext()
    AudioContext.setAnalyser()

    let analyser = AudioContext.getAnalyser()
    let audioCtx = AudioContext.getAudioContext()

    audioCtx.resume().then(() => {
      let source = null
      if (!isMobile) {
        source = audioCtx.createMediaStreamSource(stream)
      } else {
        let audio = document.createElement('audio')
        audio.src = window.URL.createObjectURL(stream)
        audio.currentTime = 0
        audio.style.display = 'none'
        audio.onloadedmetadata = () => {
          audio.play().then((r) => {
            audio.loop = true
            audio.muted = false
          })
        }

        source = audioCtx.createMediaElementSource(audio)
      }

      let gainNode = audioCtx.createGain()
      source.connect(analyser)
      source.connect(gainNode)
      // analyser.connect(audioCtx.destination);
      gainNode.connect(audioCtx.destination)
      gainNode.gain.setValueAtTime(0, audioCtx.currentTime)
    })

    const bufferLength = analyser.fftSize
    const dataArray = new Uint8Array(bufferLength)

    canvasCtx.clearRect(0, 0, width, height)

    function draw() {
      requestAnimationFrame(draw)

      canvasCtx.clearRect(0, 0, width, height)

      analyser.getByteTimeDomainData(dataArray)

      // canvasCtx.fillStyle = backgroundColor;
      // canvasCtx.fillRect(0, 0, width, height);

      canvasCtx.lineWidth = 6
      canvasCtx.strokeStyle = strokeColor

      canvasCtx.beginPath()

      const sliceWidth = width / bufferLength
      let x = 0

      for (let i = 0; i < bufferLength; i++) {
        const v = dataArray[i] / 128.0
        const y = (v * height) / 2

        if (i === 0) {
          canvasCtx.moveTo(x, y)
        } else {
          canvasCtx.lineTo(x, y)
        }

        x += sliceWidth
      }

      canvasCtx.lineTo(canvas.width, canvas.height / 2)
      canvasCtx.stroke()
    }

    draw()
  },

  hexToRgb(hex) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null
  },
}

export default AudioVisualizer
