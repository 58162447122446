export const PhotoIcon = () => {
  return (
    <svg
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_283_1522)">
        <path
          d="M32.0127 5.29712V27.7971C31.2077 30.1596 29.6377 31.7571 27.2627 32.5471H4.7627C2.3977 31.7471 0.807695 30.1671 0.0126953 27.7971V5.29712C0.807695 2.92462 2.3952 1.34462 4.7627 0.547119H27.2627C29.6427 1.33462 31.2102 2.93462 32.0127 5.29712ZM4.0152 20.1421L4.3777 20.4196C5.5027 19.2146 6.5977 17.9796 7.7577 16.8121C10.5252 14.0296 13.4802 14.0296 16.2677 16.7971C19.6652 20.1746 23.0402 23.5721 26.4277 26.9596C26.7377 27.2696 27.0627 27.5646 27.5702 28.0471C27.7602 27.3471 27.9902 26.8896 27.9927 26.4296C28.0152 19.8496 28.0202 13.2671 28.0002 6.68712C27.9952 5.35212 27.2227 4.56712 25.9002 4.56212C19.3202 4.53962 12.7377 4.53962 6.1577 4.56212C4.8177 4.56712 4.0352 5.33212 4.0277 6.65462C4.0002 11.1521 4.0177 15.6471 4.0177 20.1446L4.0152 20.1421Z"
          fill="#4B5563"
        />
        <path
          d="M20.6551 7.24956C22.5226 7.23956 24.0076 8.70456 24.0051 10.5521C24.0001 12.4021 22.5126 13.8596 20.6451 13.8471C18.8476 13.8346 17.3901 12.3971 17.3476 10.6046C17.3051 8.78206 18.8076 7.25956 20.6551 7.24956Z"
          fill="#4B5563"
        />
      </g>
      <defs>
        <clipPath id="clip0_283_1522">
          <rect
            width="32"
            height="32"
            fill="white"
            transform="translate(0.0126953 0.547119)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
