import _ from 'lodash'
import { toast } from 'react-toastify'
import instance, { blobInstance } from '../utils/Axios'
/**
 * Save Captured video upload data.
 *
 * @param data
 * @returns {Promise<unknown>}
 */
export const assetsUpload = (data) => {
  return new Promise((resolve, reject) => {
    instance
      .post('/assets/asset-upload', data)
      .then((res) => {
        resolve(res)
      })
      .catch((err) => reject(err))
  })
}

export const libraryFilesUpload = (data) => {
  return new Promise((resolve, reject) => {
    instance
      .post('/user-libraries/files-upload', data)
      .then((res) => {
        resolve(res)
      })
      .catch((err) => {
        toast.error(
          'There is a problem while uploading the file. Please try again later',
        )
        reject(err)
      })
  })
}

export const libraryFilesDelete = (ids) => {
  return new Promise((resolve, reject) => {
    instance
      .delete(`/user-libraries?ids=${ids}`)
      .then((res) => {
        resolve(res)
      })
      .catch((err) => {
        toast.error(
          'There is a problem while deleting file. Please try again later',
        )
        reject(err)
      })
  })
}

/**
 * update library video crop data
 * @param data
 * @returns {Promise<unknown>}
 */
export const libraryCropData = async (data) => {
  try {
    return await instance.post('/user-libraries/crop-data', data)
  } catch (error) {
    const errorMessage = error.response?.data?.message || error?.message
    throw new Error(errorMessage)
  }
}

/**
 *  merge audio and create mp3 version
 * @param data
 * @returns {Promise<unknown>}
 */
export const createMergedMp3Recording = async (blobs) => {
  try {
    const formData = new FormData()
    _.forEach(blobs, (blob, index) => {
      formData.append('files', blob, `audio-${index}.wav`)
    })
    const response = await blobInstance.post('/voiceover/create-mp3', formData)
    return response.data
  } catch (error) {
    const errorMessage = error.response?.data?.message || error?.message
    throw new Error(errorMessage)
  }
}

/**
 * track scenes
 * @param data
 * @returns {Promise<unknown>}
 */
export const trackScenes = async (data) => {
  try {
    return await instance.post('/tracking/track-scenes', data)
  } catch (error) {
    const errorMessage = error.response?.data?.message || error?.message
    throw new Error(errorMessage)
  }
}

export default {
  createMergedMp3Recording,
  libraryCropData,
  libraryFilesUpload,
  trackScenes,
}
