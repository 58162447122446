import React, { Component } from 'react'
import _ from 'lodash'
import process from 'process'
import 'react-perfect-scrollbar/dist/css/styles.css'
import PerfectScrollbar from 'react-perfect-scrollbar'
import TimelineHeader from './TimelineHeader'
import AddScene from './AddScene'
import RangeSlider from 'react-range-slider-input'
import 'react-range-slider-input/dist/style.css'
import { connect } from 'react-redux'
import AudioDuckModal from '../../../audio-duck/AudioDuckModal'
import * as TimelineService from '../../../../services/time/TimelineService'
import * as TimelineSet from '../../../../utils/staging-preview/TimelineSet'
import { updateTransitions } from '../../../../methods/editor/services'
import {
  checkAddItem,
  storeProjectSteps,
  SetPreviewTimeline,
  SetTimeSlider,
  toggleWatchProject,
  updateProject,
} from '../../../../redux/actions/EditorActions'
import * as MainContainerActions from '../../../../redux/actions/MainContainerActions'
import {
  autoUpdateSelectSlide,
  handleProgressChange,
  moveTimelineByCurrentTime,
  onChangeTimeSlider,
  onMusicSortEnd,
  onScenesSortEnd,
  onTextSortEnd,
  selectMusicItem,
  selectSoundItem,
  selectScene,
  selectTextItem,
  selectVoiceoverItem,
  setTimeLineHeader,
  textDragHandle,
  textResizeEnd,
  textResizeHandle,
  toggleShowAddItem,
  toggleSlider,
  localUpdateProject,
  updateTimelineSlider,
  setUpTextTimeline,
} from '../../../../methods/editor/MainTimelineMethods'
import TextTimelineItem from '../../../timeline/TextTimelineItem'
import { ONE_SECOND } from '../../../../constants/Timeline'
import VideoTimelineItem from '../../../timeline/VideoTimelineItem'
import AudioTimelineItem from '../../../timeline/AudioTimelineItem'
import {
  AUDIO,
  MUSIC,
  TEXT,
  VIDEO,
  VOICEOVER,
} from '../../../../constants/Channels'
import * as SelectedItemActions from '../../../../redux/actions/SelectedItemActions'
import RATIO from '../../../../constants/AspectRatios'
import TimelineScroller from '../../../../services/videos/TimelineScroller'
import TimeTickerContextProvider from '../../../projects/TimeTickerContext'
import * as SubscriptionActions from '../../../../redux/actions/SubscriptionActions'

const getZoomDeltaPx = (deltaPixels, zoomValue) => {
  if (zoomValue >= 0.85) {
    return deltaPixels + (1 - zoomValue) * deltaPixels
  } else if (zoomValue >= 0.7) {
    return (
      deltaPixels +
      (1 - zoomValue) * deltaPixels +
      ((1 - zoomValue) / 3) * deltaPixels
    )
  } else if (zoomValue >= 0.65) {
    return (
      deltaPixels +
      (1 - zoomValue) * deltaPixels +
      ((1 - zoomValue) / 2) * deltaPixels
    )
  } else if (zoomValue >= 0.6) {
    return (
      deltaPixels +
      (1 - zoomValue) * deltaPixels +
      ((1 - zoomValue) / 1.5) * deltaPixels
    )
  } else if (zoomValue >= 0.55) {
    return (
      deltaPixels +
      (1 - zoomValue) * deltaPixels +
      ((1 - zoomValue) / 1.3) * deltaPixels
    )
  } else if (zoomValue >= 0.5) {
    return (
      deltaPixels +
      (1 - zoomValue) * deltaPixels +
      ((1 - zoomValue) / 1.05) * deltaPixels
    )
  } else if (zoomValue >= 0.45) {
    return deltaPixels + (1 - zoomValue) * deltaPixels * 2.2
  } else if (zoomValue >= 0.4) {
    return deltaPixels + (1 - zoomValue) * deltaPixels * 2.6
  } else if (zoomValue >= 0.35) {
    return deltaPixels + (1 - zoomValue) * deltaPixels * 3.0
  } else if (zoomValue >= 0.3) {
    return deltaPixels + (1 - zoomValue) * deltaPixels * 3.4
  } else if (zoomValue >= 0.25) {
    return deltaPixels + (1 - zoomValue) * deltaPixels * 4
  } else if (zoomValue >= 0.2) {
    return deltaPixels + (1 - zoomValue) * deltaPixels * 4.8
  } else if (zoomValue >= 0.15) {
    return deltaPixels + (1 - zoomValue) * deltaPixels * 6.5
  } else if (zoomValue >= 0.1) {
    return deltaPixels + (1 - zoomValue) * deltaPixels * 10
  }
  return deltaPixels + (1 - zoomValue) * deltaPixels * 20
}
class MainTimeline extends Component {
  constructor(props) {
    super(props)

    this.previewRef = React.createRef()
    this.scrollbarRef = React.createRef()
    this.scrollableTimelineBox = React.createRef()
    this.textItemTimeline = React.createRef()
    this.musicItemTimeline = React.createRef()
    this.soundItemTimeline = React.createRef()
    this.sceneItemTimeline = React.createRef()
    this.voiceoverItemTimeline = React.createRef()
    this.textItemRefs = {}
    this.selectScene = selectScene.bind(this)
    this.onScenesSortEnd = onScenesSortEnd.bind(this)
    this.onMusicSortEnd = onMusicSortEnd.bind(this)
    this.setTimeLineHeader = setTimeLineHeader.bind(this)
    this.localUpdateProject = localUpdateProject.bind(this)
    this.handleProgressChange = handleProgressChange.bind(this)
    this.onChangeTimeSlider = onChangeTimeSlider.bind(this)
    this.toggleSlider = toggleSlider.bind(this)
    this.toggleShowAddItem = toggleShowAddItem.bind(this)
    this.autoUpdateSelectSlide = autoUpdateSelectSlide.bind(this)
    this.selectMusicItem = selectMusicItem.bind(this)
    this.selectSoundItem = selectSoundItem.bind(this)
    this.onTextSortEnd = onTextSortEnd.bind(this)
    this.selectTextItem = selectTextItem.bind(this)
    this.selectVoiceoverItem = selectVoiceoverItem.bind(this)
    this.updateTimelineSlider = updateTimelineSlider.bind(this)
    this.textDragHandle = textDragHandle.bind(this)
    this.textResizeHandle = textResizeHandle.bind(this)
    this.textResizeEnd = textResizeEnd.bind(this)
    this.setUpTextTimeline = setUpTextTimeline.bind(this)
    this.moveTimelineByCurrentTime = moveTimelineByCurrentTime.bind(this)
    this.textRef = []
  }

  state = {
    checkType: 'video',
    items: null,
    refreshSound: false,
    showTextModal: false,
    sliderShow: false,
    slideV: 0,
    tlLength: [],
    currentTextItem: null,
  }

  get textItems() {
    const { project } = this.props
    const textItems = project.texts[project.resolution]
    return textItems
  }

  get musicItems() {
    const { project } = this.props
    const musicItems = project.music
    return musicItems
  }

  get sceneItems() {
    const { project } = this.props
    const sceneItems = project.scenes[project.resolution]
    return sceneItems
  }

  get soundItems() {
    const { project } = this.props
    return project.sound
  }

  get voiceoverItems() {
    const { project } = this.props
    return project.voiceover
  }

  get totalSlideWidth() {
    const { project } = this.props
    return TimelineService.convertMillisecondsToPixels(project.duration)
  }

  componentDidMount() {
    const { selectedScene, project, timeSlider, addItem } = this.props

    this.setState({
      items:
        project && project.scenes[project.resolution]
          ? project.scenes[project.resolution]
          : [],
      soundItems: project && project.sound ? project.sound : [],
      audioItems: project && project.music ? project.music : [],
      textItems:
        project && project.texts[project.resolution]
          ? project.texts[project.resolution]
          : [],
      selItem:
        project &&
        project.scenes[project.resolution] &&
        project.scenes[project.resolution].length
          ? project.scenes[project.resolution][0]
          : selectedScene,
      slideV: timeSlider.value,
      sliderShow: timeSlider.show,
      checkType: addItem,
    })

    this.setTimeLineHeader()

    this.registerSubscriber = (id, callback) => {
      this.Subscribers = [
        ...(this.Subscribers
          ? this.Subscribers.filter((sb) => sb.id !== id)
          : []),
        {
          id,
          callback,
        },
      ]
    }

    this.unregisterSubscriber = (id) => {
      this.Subscribers = [
        ...(this.Subscribers
          ? this.Subscribers.filter((sb) => sb.id !== id)
          : []),
      ]
    }

    this.props.loadUserSubscriptions()
    this.props.loadSubscriptionProducts()
  }

  componentWillUnmount() {
    if (this.Subscribers) {
      this.Subscribers.forEach(({ callback }) => {
        callback()
      })
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      project,
      watchProject,
      updateSlideV,
      setSelectedSceneIndex,
      setSelectedScene,
      setSelectedTextIndex,
      setSelectedText,
      selectedScene,
      showTextPanel,
      addItem,
    } = this.props

    // Reload timeline when the projectSteps length changes
    if (this.props.projectSteps.length < prevProps.projectSteps.length) {
      setSelectedSceneIndex(0)
      setSelectedScene(project.scenes[project.resolution][0])
      setSelectedTextIndex(-1)
      setSelectedText(null)
    }

    TimelineScroller.scrollTimelinePanel(
      this.scrollableTimelineBox,
      this?.props?.timeSlider?.curSec,
      prevProps?.timeSlider?.curSec,
      this.props.scenePlaying,
      this.scrollbarRef,
    )

    if (watchProject !== prevProps.watchProject) {
      this.setTimeLineHeader()

      this.setState({
        items: project.scenes[project.resolution],
        selItem: project.scenes[project.resolution][0],
        soundItems: project ? project.sound : [],
        audioItems: project ? project.music : [],
        textItems: project ? project.texts[project.resolution] : [],
      })

      if (project.scenes[project.resolution].length) {
        setSelectedSceneIndex(0)
        setSelectedScene(project.scenes[project.resolution][0])
      } else {
        setSelectedSceneIndex(-1)
      }
    }

    if (updateSlideV !== prevProps.updateSlideV) {
      this.setState({
        slideV: updateSlideV,
      })

      this.autoUpdateSelectSlide(updateSlideV)
    }

    if (prevProps.selectedScene !== selectedScene) {
      this.setState({
        selItem: selectedScene,
      })
    }

    if (prevProps.showTextPanel !== showTextPanel) {
      this.setTimeLineHeader()
    }

    if (addItem !== prevProps.addItem) {
      this.setState({
        checkType: addItem,
      })
    }
  }

  isItemBetweenOtherItems = (items, i, newStartTime) => {
    newStartTime = newStartTime - 50 // Add allwoance of 50 for close edges
    const otherTextItems = _.reject(items, (val, index) => index === i)
    return _.some(otherTextItems, (item) => {
      const itemStartTime = item?.start_time || 0
      const itemEndTime = item?.end_time || 0
      return itemStartTime < newStartTime && newStartTime < itemEndTime
    })
  }

  getNearestOtherItemByEndTime = (items, i, newStartTime) => {
    let otherTextItems = _.reject(items, (val, index) => index === i)
    otherTextItems = _.filter(
      otherTextItems,
      (val) => Math.abs(newStartTime - val.end_time) <= 50,
    )
    return _.minBy(otherTextItems, (item) => {
      const itemEndTime = item?.end_time || 0
      return newStartTime - itemEndTime
    })
  }

  isOtherItemsInsideItem = (items, i, newStartTime, newEndTime) => {
    newEndTime = newEndTime - 50 // Add allwoance of 50 for close edges
    const otherTextItems = _.reject(items, (val, index) => index === i)
    return _.some(otherTextItems, (item) => {
      const itemStartTime = item?.start_time || 0
      const itemEndTime = item?.end_time || 0
      return itemStartTime >= newStartTime && newEndTime >= itemEndTime
    })
  }

  handleTextPositionChange = (i, leftPosition) => {
    const project = _.cloneDeep(this.props.project)
    const currentAspectRatio = project.resolution
    const textItems = _.get(project, `texts.${currentAspectRatio}`, [])
    const clipLeftSecondsPosition =
      TimelineService.convertPixelToSeconds(leftPosition)
    const duration = _.get(textItems, `${i}.clip.duration`)
    const startTime = _.get(textItems, `${i}.start_time`)
    const itemId = _.get(textItems, `${i}.id`)
    const newStartTime = Math.round(startTime + clipLeftSecondsPosition)
    const newEndTime = newStartTime + duration
    const isItemBetweenOtherItems = this.isItemBetweenOtherItems(
      textItems,
      i,
      newStartTime,
    )
    const isTextItemEndTimeBetweenOtherItems = this.isItemBetweenOtherItems(
      textItems,
      i,
      newEndTime,
    )
    const isOtherItemsInsideItem = this.isOtherItemsInsideItem(
      textItems,
      i,
      newStartTime,
      newEndTime,
    )

    if (
      !isItemBetweenOtherItems &&
      !isTextItemEndTimeBetweenOtherItems &&
      !isOtherItemsInsideItem
    ) {
      _.forEach(RATIO, (ratio) => {
        this.moveItemByRatio(
          project,
          ratio,
          itemId,
          newStartTime,
          newEndTime,
          TEXT.projectPath,
        )
      })
      this.props.updateProject && this.props.updateProject(project)
      this.moveTimelineByCurrentTime(newStartTime + 0.5 * ONE_SECOND)
    }
  }

  handleFadeCheckboxChange = (index, transition) => {
    const project = _.cloneDeep(this.props.project)
    const sceneItems = _.cloneDeep(project.scenes)
    this.props.storeProjectSteps({
      type: 'UPDATE',
      channel: ['video'],
      payload: [
        {
          transition: sceneItems[0][index].transition,
          selectedTransition: sceneItems[0][index].transition,
          id: sceneItems[0][index].id,
        },
      ],
    })
    sceneItems.map((sceneItem) => {
      sceneItem[index].transition = transition
      sceneItem[index].selectedTransition = transition
      return sceneItem
    })
    project.scenes = sceneItems
    this.props.updateProject && this.props.updateProject(project)
  }

  handleScenePositionChange = (i, leftPosition) => {
    let project = _.cloneDeep(this.props.project)
    const currentAspectRatio = project.resolution
    const items = _.get(project, `scenes.${currentAspectRatio}`, [])
    const clipLeftSecondsPosition = TimelineService.convertPixelToSeconds(
      Math.round(leftPosition),
    )
    const duration = _.get(items, `${i}.clip.duration`)
    const startTime = _.get(items, `${i}.start_time`)
    const itemId = _.get(items, `${i}.id`)
    const newStartTime = TimelineService.getNewStartTime(
      startTime,
      clipLeftSecondsPosition,
    )
    const newEndTime = newStartTime + duration
    const isItemBetweenOtherItems = this.isItemBetweenOtherItems(
      items,
      i,
      newStartTime,
    )
    const isTextItemEndTimeBetweenOtherItems = this.isItemBetweenOtherItems(
      items,
      i,
      newEndTime,
    )
    const isOtherItemsInsideItem = this.isOtherItemsInsideItem(
      items,
      i,
      newStartTime,
      newEndTime,
    )

    if (
      !isItemBetweenOtherItems &&
      !isTextItemEndTimeBetweenOtherItems &&
      !isOtherItemsInsideItem
    ) {
      project = this.moveVideoItem(project, itemId, newStartTime, newEndTime)
      this.moveTimelineByCurrentTime(newStartTime + 0.5 * ONE_SECOND)
    } else if (
      isItemBetweenOtherItems &&
      !isTextItemEndTimeBetweenOtherItems &&
      !isOtherItemsInsideItem
    ) {
      const nearestItem = this.getNearestOtherItemByEndTime(
        items,
        i,
        newStartTime,
      )
      const distance = newStartTime - (nearestItem?.end_time || 0)
      if (Math.abs(distance) <= 50) {
        project = this.moveVideoItem(
          project,
          itemId,
          newStartTime + distance,
          newEndTime + distance,
        )

        this.moveTimelineByCurrentTime(
          newStartTime + distance + 0.5 * ONE_SECOND,
        )
      }
    }
    if (this.props.updateProject) {
      const latestUpdatedProject = updateTransitions(project, false)
      this.props.updateProject(latestUpdatedProject)
    }
  }

  handleAudioPositionChange = (
    i,
    leftPosition,
    projectPath = VOICEOVER.projectPath,
  ) => {
    let project = _.cloneDeep(this.props.project)
    const items = _.get(project, projectPath, [])
    const clipLeftSecondsPosition = TimelineService.convertPixelToSeconds(
      Math.round(leftPosition),
    )
    const duration = _.get(items, `${i}.clip.duration`)
    const startTime = _.get(items, `${i}.start_time`)
    const newStartTime = TimelineService.getNewStartTime(
      startTime,
      clipLeftSecondsPosition,
    )
    const newSliderLeft =
      TimelineService.convertMillisecondsToPixels(newStartTime)
    const newEndTime = newStartTime + duration
    const newSliderWidth = TimelineService.convertMillisecondsToPixels(
      newEndTime - newStartTime,
    )

    const isItemBetweenOtherItems = this.isItemBetweenOtherItems(
      items,
      i,
      newStartTime,
    )
    const isTextItemEndTimeBetweenOtherItems = this.isItemBetweenOtherItems(
      items,
      i,
      newEndTime,
    )
    const isOtherItemsInsideItem = this.isOtherItemsInsideItem(
      items,
      i,
      newStartTime,
      newEndTime,
    )

    if (
      !isItemBetweenOtherItems &&
      !isTextItemEndTimeBetweenOtherItems &&
      !isOtherItemsInsideItem
    ) {
      const { selectedScene } = this.props
      if (selectedScene) {
        project = this.moveVideoItem(
          project,
          selectedScene.id,
          newStartTime,
          newEndTime,
        )
      }

      _.set(project, `${projectPath}.${i}.start_time`, newStartTime)
      _.set(project, `${projectPath}.${i}.end_time`, newEndTime)
      _.set(project, `${projectPath}.${i}.sliderL`, newSliderLeft)
      _.set(project, `${projectPath}.${i}.sliderW`, newSliderWidth)
      const updatedSound = _.get(project, `${projectPath}.${i}`)
      this.props.setSelectedSound(updatedSound)

      this.moveTimelineByCurrentTime(newStartTime + 0.5 * ONE_SECOND)
    } else if (
      isItemBetweenOtherItems &&
      !isTextItemEndTimeBetweenOtherItems &&
      !isOtherItemsInsideItem
    ) {
      const nearestItem = this.getNearestOtherItemByEndTime(
        items,
        i,
        newStartTime,
      )
      const distance = newStartTime - (nearestItem?.end_time || 0)
      if (Math.abs(distance) <= 50) {
        _.set(
          project,
          `${projectPath}.${i}.start_time`,
          newStartTime - distance,
        )
        _.set(project, `${projectPath}.${i}.end_time`, newEndTime - distance)
        _.set(
          project,
          `${projectPath}.${i}.sliderL`,
          TimelineService.convertMillisecondsToPixels(newEndTime - distance),
        )
        this.moveTimelineByCurrentTime(
          newStartTime - distance + 0.5 * ONE_SECOND,
        )
      }
    }
    this.props.updateProject && this.props.updateProject(project)
  }

  handleTextWidthChange = (i, newWidth) => {
    const project = _.cloneDeep(this.props.project)
    const currentAspectRatio = project.resolution
    const textItems = _.get(project, `texts.${currentAspectRatio}`, [])
    const newTextWidthMilliseconds =
      TimelineService.convertPixelToSeconds(newWidth)
    const startTime = _.get(
      project,
      `texts.${currentAspectRatio}.${i}.start_time`,
    )
    const textItemId = _.get(project, `texts.${currentAspectRatio}.${i}.id`)
    const newEndTime = startTime + newTextWidthMilliseconds
    const isItemBetweenOtherItems = this.isItemBetweenOtherItems(
      textItems,
      i,
      newEndTime,
    )

    const currentText = project[TEXT.projectPath][0][i]
    this.props.storeProjectSteps({
      type: 'UPDATE',
      channel: ['text'],
      payload: [
        {
          end_time: currentText.end_time,
          id: textItemId,
          sliderW: currentText.sliderW,
          clip: { duration: currentText.clip.duration },
        },
      ],
    })
    if (!isItemBetweenOtherItems) {
      _.forEach(RATIO, (ratio) => {
        this.resizeItemByRatio(
          project,
          ratio,
          textItemId,
          newEndTime,
          newWidth,
          newTextWidthMilliseconds,
          TEXT.projectPath,
        )
      })
      this.props.updateProject && this.props.updateProject(project)
      process.nextTick(() => {
        this.moveTimelineByCurrentTime(startTime + 0.5 * ONE_SECOND)
      })
    }
  }

  handleTimelineItemResizeEnd = () => {
    const { previewTimeLine, project, pauseTimeline, reloadStageByProject } =
      this.props
    TimelineSet.createEndInTimeline(
      previewTimeLine,
      project.duration / ONE_SECOND,
    )
    pauseTimeline && pauseTimeline()
    reloadStageByProject && reloadStageByProject()
  }

  getVerticalGuidelines = (items) => {
    const verticalGuidelines = _.flatMap(items, (item) => {
      const startTimePixels = TimelineService.convertMillisecondsToPixels(
        item.start_time,
      )
      const endTimePixels = TimelineService.convertMillisecondsToPixels(
        item.end_time,
      )
      return [startTimePixels, endTimePixels]
    })
    const allVerticalGuidelines = [0, ..._.uniq(verticalGuidelines)]
    return allVerticalGuidelines
  }

  moveVideoItem(project, itemId, newStartTime, newEndTime) {
    _.forEach(RATIO, (ratio) => {
      this.moveItemByRatio(
        project,
        ratio,
        itemId,
        newStartTime,
        newEndTime,
        VIDEO.projectPath,
      )
    })

    const { selectedSound } = this.props
    const projectItemSound = _.find(project[AUDIO.projectPath], (sound) =>
      _.startsWith(sound.id, itemId),
    )
    if (selectedSound?.id === projectItemSound?.id) {
      _.set(projectItemSound, 'start_time', newStartTime)
      _.set(projectItemSound, 'end_time', newEndTime)
      this.props.setSelectedSound(projectItemSound)
    }
    return project
  }

  resizeItemByRatio(
    project,
    currentAspectRatio,
    itemId,
    newEndTime,
    newWidth,
    newTextWidthMilliseconds,
    projectPath,
  ) {
    const itemsInRatio = _.get(
      project,
      `${projectPath}.${currentAspectRatio}`,
      [],
    )
    const itemPosition = _.findIndex(itemsInRatio, (item) => item.id === itemId)
    if (itemPosition > -1) {
      _.set(
        project,
        `${projectPath}.${currentAspectRatio}.${itemPosition}.end_time`,
        newEndTime,
      )
      _.set(
        project,
        `${projectPath}.${currentAspectRatio}.${itemPosition}.sliderW`,
        newWidth,
      )
      _.set(
        project,
        `${projectPath}.${currentAspectRatio}.${itemPosition}.clip.duration`,
        newTextWidthMilliseconds,
      )
    }
  }

  moveItemByRatio(
    project,
    currentAspectRatio,
    itemId,
    newStartTime,
    newEndTime,
    projectPath,
  ) {
    const itemsInRatio = _.get(
      project,
      `${projectPath}.${currentAspectRatio}`,
      [],
    )
    const itemPosition = _.findIndex(itemsInRatio, (item) => item.id === itemId)
    const sliderLeft = TimelineService.convertMillisecondsToPixels(newStartTime)
    const sliderWidth = TimelineService.convertMillisecondsToPixels(
      newEndTime - newStartTime,
    )

    if (itemPosition > -1) {
      _.set(
        project,
        `${projectPath}.${currentAspectRatio}.${itemPosition}.start_time`,
        newStartTime,
      )
      _.set(
        project,
        `${projectPath}.${currentAspectRatio}.${itemPosition}.end_time`,
        newEndTime,
      )
      _.set(
        project,
        `${projectPath}.${currentAspectRatio}.${itemPosition}.sliderL`,
        sliderLeft,
      )
      _.set(
        project,
        `${projectPath}.${currentAspectRatio}.${itemPosition}.sliderW`,
        sliderWidth,
      )
    }
  }

  handleEditTextItem = (textItem) => {
    const {
      openEditTextModal,
      resetAllSelected,
      setSelectedText,
      updateShowSidebarMedia,
    } = this.props
    resetAllSelected && resetAllSelected()
    setSelectedText(textItem)
    openEditTextModal && openEditTextModal(true, textItem)
    updateShowSidebarMedia && updateShowSidebarMedia(TEXT.code)
  }

  handleDisplayEditTextModal = (isDisplayed = false) => {
    const { openEditTextModal, textItem } = this.props

    if (isDisplayed) {
      openEditTextModal && openEditTextModal(true, textItem)
    } else {
      openEditTextModal && openEditTextModal(false, null)
    }
  }

  changeCompleteSlider() {
    // Reset progress value to allow re mounting of video scenes, to allow video visaulisation in player
    setTimeout(() => {
      const { slideV } = this.state
      this.handleProgressChange(slideV + 1, true)
    }, 500)
  }

  renderTimelineBox() {
    const { currentTextItem } = this.state
    const { checkType, sliderShow, slideV } = this.state
    const {
      isEditTextModalVisible,
      project,
      selectedSound,
      selectedMusic,
      selectedScene,
      selectedText,
      selectedVoiceover,
      zoomValue,
      deleteSelectedItems,
      isAudioDuckModalDisplayed,
    } = this.props
    return (
      <PerfectScrollbar
        options={{ minScrollbarLength: 2000 }}
        style={{
          transform: `scale(${zoomValue})`,
          transformOrigin: 'left',
          width: `${100 / zoomValue}%`,
        }}
        ref={this.scrollbarRef}>
        <div
          ref={this.previewRef}
          className="timeline-items-flex timeline-main-preview"
          style={{ width: 'auto' }}>
          <TimelineHeader project={project} />
          <div
            className="timeline-slider absolute left-0"
            style={{
              minWidth: `${this.totalSlideWidth}px`,
            }}>
            {sliderShow && (
              <RangeSlider
                className="maintimeline-slider"
                min={0}
                max={10000 / zoomValue}
                tooltip={false}
                value={[0, slideV / zoomValue]}
                step={0.001}
                onInput={([_, value]) => {
                  this.handleProgressChange(value, true)
                }}
                thumbsDisabled={[true, false]}
                rangeSlideDisabled={true}
                onRangeDragEnd={this.changeCompleteSlider.bind(this)}
              />
            )}
          </div>
          {this.renderSceneItems(checkType, selectedScene)}
          {this.renderAudioItems(selectedSound)}
          {this.renderVoiceoverItems(selectedVoiceover)}
          {this.renderTextItems(selectedText)}
          {this.renderMusicItems(selectedMusic)}
          {/* {isEditTextModalVisible && (
            <EditTextModal
              onClose={() => this.handleDisplayEditTextModal(false)}
              textItem={currentTextItem}
              deleteSelectedItems={deleteSelectedItems}
            />
          )} */}
          {isAudioDuckModalDisplayed && (
            <TimeTickerContextProvider>
              <AudioDuckModal
                onModalDisplayChange={this.props.handleDisplayAudioDuckModal}
              />
            </TimeTickerContextProvider>
          )}
        </div>
      </PerfectScrollbar>
    )
  }

  render() {
    const { showTextPanel, zoomValue } = this.props
    return (
      <div className="flex justify-center items-start">
        <div
          className={`flex-shrink timeline-box ${showTextPanel && 'shrinked'}`}
          ref={this.scrollableTimelineBox}>
          {this.renderTimelineBox()}
        </div>
      </div>
    )
  }

  renderMusicItems(selectedMusic) {
    const verticalGuidelines = this.getVerticalGuidelines(this.musicItems)
    return (
      <div className="timeline-item">
        <div className="timeline-inner" ref={this.musicItemTimeline}>
          {_.map(this.musicItems, (item, i) => {
            return (
              <AudioTimelineItem
                getZoomDeltaPx={getZoomDeltaPx}
                zoomValue={this.props.zoomValue}
                scrollableTimelineBox={this.scrollableTimelineBox}
                scrollbarRef={this.scrollbarRef}
                height={27}
                key={`music-${i}`}
                channel={'music'}
                displayLabel
                itemTimelineHTML={this.musicItemTimeline.current}
                verticalGuidelines={verticalGuidelines}
                activeItem={selectedMusic}
                onDoubleClick={() => this.props.handleDisplayAudioDuckModal()}
                onItemSelected={this.selectMusicItem.bind(this)}
                onPositionChanged={(leftPosition) =>
                  this.handleAudioPositionChange(
                    i,
                    leftPosition,
                    MUSIC.projectPath,
                  )
                }
                item={item}
                scenePlaying={this.props.scenePlaying}
              />
            )
          })}
        </div>
      </div>
    )
  }

  renderTextItems(selectedTextItem) {
    const verticalGuidelines = this.getVerticalGuidelines(this.textItems)
    return (
      <div className="timeline-item">
        <div className="timeline-inner" ref={this.textItemTimeline}>
          <ul className="sortable-list relative">
            {_.map(this.textItems, (item, i) => (
              <TextTimelineItem
                getZoomDeltaPx={getZoomDeltaPx}
                zoomValue={this.props.zoomValue}
                scrollableTimelineBox={this.scrollableTimelineBox}
                scrollbarRef={this.scrollbarRef}
                textItemTimelineHTML={this.textItemTimeline.current}
                textItem={item}
                key={`text-${i}`}
                itemIndex={i}
                activeTextItem={selectedTextItem}
                verticalGuidelines={verticalGuidelines}
                onDoubleClick={() => this.handleEditTextItem(item, i, true)}
                onTextItemSelected={this.selectTextItem.bind(this)}
                onPositionChanged={(leftPosition) =>
                  this.handleTextPositionChange(i, leftPosition)
                }
                onWidthChanged={(newWidth) =>
                  this.handleTextWidthChange(i, newWidth)
                }
                onResizeEnd={this.handleTimelineItemResizeEnd}
                scenePlaying={this.props.scenePlaying}
              />
            ))}
          </ul>
        </div>
      </div>
    )
  }

  renderVoiceoverItems(selectedItem) {
    const verticalGuidelines = this.getVerticalGuidelines(this.voiceoverItems)
    return (
      <div className="timeline-item">
        <div className="timeline-inner" ref={this.voiceoverItemTimeline}>
          {_.map(this.voiceoverItems, (item, i) => {
            return (
              <AudioTimelineItem
                getZoomDeltaPx={getZoomDeltaPx}
                zoomValue={this.props.zoomValue}
                scrollableTimelineBox={this.scrollableTimelineBox}
                scrollbarRef={this.scrollbarRef}
                height={27}
                channel={'voiceover'}
                key={`voiceover-${i}`}
                displayLabel
                itemTimelineHTML={this.voiceoverItemTimeline.current}
                verticalGuidelines={verticalGuidelines}
                activeItem={selectedItem}
                onDoubleClick={() => this.props.handleDisplayAudioDuckModal()}
                onItemSelected={this.selectVoiceoverItem.bind(this)}
                onPositionChanged={(leftPosition) =>
                  this.handleAudioPositionChange(
                    i,
                    leftPosition,
                    VOICEOVER.projectPath,
                  )
                }
                item={item}
                scenePlaying={this.props.scenePlaying}
              />
            )
          })}
        </div>
      </div>
    )
  }

  renderAudioItems(selectedItem) {
    const verticalGuidelines = this.getVerticalGuidelines(this.sceneItems)
    return (
      <div className="timeline-item">
        <div className="timeline-inner sound-list" ref={this.soundItemTimeline}>
          <div className="timeline-inner">
            {_.map(this.soundItems, (item, i) => {
              return (
                <AudioTimelineItem
                  getZoomDeltaPx={getZoomDeltaPx}
                  zoomValue={this.props.zoomValue}
                  scrollableTimelineBox={this.scrollableTimelineBox}
                  scrollbarRef={this.scrollbarRef}
                  height={27}
                  key={`audio-${i}`}
                  channel={'audio'}
                  displayLabel={() => `Sound-${i}`}
                  itemTimelineHTML={this.soundItemTimeline.current}
                  verticalGuidelines={verticalGuidelines}
                  activeItem={selectedItem}
                  onDoubleClick={() => this.props.handleDisplayAudioDuckModal()}
                  onItemSelected={this.selectSoundItem.bind(this)}
                  onPositionChanged={(leftPosition) =>
                    this.handleAudioPositionChange(
                      i,
                      leftPosition,
                      AUDIO.projectPath,
                    )
                  }
                  item={item}
                  scenePlaying={this.props.scenePlaying}
                />
              )
            })}
          </div>
        </div>
      </div>
    )
  }

  renderSceneItems(checkType, selItem) {
    const verticalGuidelines = this.getVerticalGuidelines(this.sceneItems)
    return (
      <div className="timeline-item">
        <div
          className="timeline-inner timeline-sel-video"
          ref={this.sceneItemTimeline}>
          {this.sceneItems &&
            this.sceneItems.map((item, i) => {
              return (
                <VideoTimelineItem
                  getZoomDeltaPx={getZoomDeltaPx}
                  zoomValue={this.props.zoomValue}
                  scrollableTimelineBox={this.scrollableTimelineBox}
                  scrollbarRef={this.scrollbarRef}
                  sceneItemTimelineHTML={this.sceneItemTimeline.current}
                  key={`video-${i}`}
                  itemIndex={i}
                  sceneItem={item}
                  activeItem={selItem}
                  onItemSelected={this.selectScene.bind(this)}
                  verticalGuidelines={verticalGuidelines}
                  onPositionChanged={(leftPosition) =>
                    this.handleScenePositionChange(i, leftPosition)
                  }
                  onFadeCheckboxChange={(transition) =>
                    this.handleFadeCheckboxChange(i, transition)
                  }
                  scenePlaying={this.props.scenePlaying}
                />
              )
            })}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  authUser: state.auth.user,
  checkedChannels: state.selectedItems.checkedChannels,
  project: state.editor.project,
  previewTimeLine: state.timeline.previewTimeLine,
  timeSlider: state.timeline.timeSlider,
  watchProject: state.editor.watchProject,
  selectedMusic: state.selectedItems.selectedMusic,
  selectedMusicIndex: state.selectedItems.selectedMusicIndex,
  selectedSceneIndex: state.selectedItems.selectedSceneIndex,
  selectedScene: state.selectedItems.selectedScene,
  selectedSound: state.selectedItems.selectedSound,
  selectedSoundIndex: state.selectedItems.selectedSoundIndex,
  selectedText: state.selectedItems.selectedText,
  selectedTextIndex: state.selectedItems.selectedTextIndex,
  selectedVoiceover: state.selectedItems.selectedVoiceover,
  selectedVoiceoverIndex: state.selectedItems.selectedVoiceoverIndex,
  addItem: state.editor.addItem,
  isEditTextModalVisible: state.mainContainer.isEditTextModalVisible,
  scenePlaying: state.mediaPlayer.scenePlaying,
  projectSteps: state.editor.projectSteps,
  mySubscription: state.subscriptions.user,
  products: state.subscriptions.products,
})

const mapDispatchToProps = (dispatch) => ({
  SetTimeSlider: (...any) => dispatch(SetTimeSlider(...any)),
  SetPreviewTimeline: (...any) => dispatch(SetPreviewTimeline(...any)),
  toggleWatchProject: (...any) => dispatch(toggleWatchProject(...any)),
  resetAllSelected: (...any) =>
    dispatch(SelectedItemActions.resetAllSelected(...any)),
  setSelectedScene: (...any) =>
    dispatch(SelectedItemActions.setSelectedScene(...any)),
  setSelectedSceneIndex: (...any) =>
    dispatch(SelectedItemActions.setSelectedSceneIndex(...any)),
  setSelectedText: (...any) =>
    dispatch(SelectedItemActions.setSelectedText(...any)),
  setSelectedTextIndex: (...any) =>
    dispatch(SelectedItemActions.setSelectedTextIndex(...any)),
  setSelectedMusic: (...any) =>
    dispatch(SelectedItemActions.setSelectedMusic(...any)),
  setSelectedMusicIndex: (...any) =>
    dispatch(SelectedItemActions.setSelectedMusicIndex(...any)),
  setSelectedSound: (...any) =>
    dispatch(SelectedItemActions.setSelectedSound(...any)),
  setSelectedSoundIndex: (...any) =>
    dispatch(SelectedItemActions.setSelectedSoundIndex(...any)),
  setSelectedVoiceover: (...any) =>
    dispatch(SelectedItemActions.setSelectedVoiceover(...any)),
  setSelectedVoiceoverIndex: (...any) =>
    dispatch(SelectedItemActions.setSelectedVoiceoverIndex(...any)),
  checkAddItem: (...any) => dispatch(checkAddItem(...any)),
  storeProjectSteps: (...any) => dispatch(storeProjectSteps(...any)),
  updateProject: (...any) => dispatch(updateProject(...any)),
  openEditTextModal: (...any) =>
    dispatch(MainContainerActions.openEditTextModal(...any)),
  reloadStageByProject: (...any) =>
    dispatch(MainContainerActions.reloadStageByProject(...any)),
  toggleVoiceoverModal: (...any) =>
    dispatch(MainContainerActions.toggleVoiceoverModal(...any)),
  loadUserSubscriptions: () =>
    dispatch(SubscriptionActions.currentUserSubscription()),
  loadSubscriptionProducts: () =>
    dispatch(SubscriptionActions.getAllSubscriptionProducts()),
})
export default connect(mapStateToProps, mapDispatchToProps)(MainTimeline)
