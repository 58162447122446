export const UploadIcon = () => {
  return (
    <svg
      width="39"
      height="39"
      viewBox="0 0 39 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_283_1296)">
        <path
          d="M28.9471 11.963C28.423 11.8585 27.9939 11.5308 27.7723 11.0621C25.3181 5.883 19.6292 2.98709 13.9434 4.03525C8.76278 4.98525 4.70153 9.12092 3.83545 14.3285C3.57895 15.8659 3.59795 17.4049 3.8877 18.9043C3.9827 19.3936 3.77211 19.9383 3.33986 20.3309C1.52695 21.9792 0.488281 24.3273 0.488281 26.7751C0.488281 31.5758 4.39436 35.4834 9.19662 35.4834H26.6133C33.1619 35.4834 38.4883 30.1571 38.4883 23.6084C38.4883 17.9638 34.4761 13.065 28.9471 11.963ZM24.4299 22.3528C24.1211 22.6616 23.7158 22.8168 23.3104 22.8168C22.9051 22.8168 22.4998 22.6616 22.191 22.3528L19.4883 19.6501V27.5668C19.4883 28.4423 18.7789 29.1501 17.9049 29.1501C17.0309 29.1501 16.3216 28.4423 16.3216 27.5668V19.6501L13.6189 22.3528C12.9998 22.9719 11.9991 22.9719 11.38 22.3528C10.7609 21.7338 10.7609 20.7331 11.38 20.114L15.6661 15.8279C16.2773 15.2168 17.08 14.9096 17.8828 14.9048L17.9049 14.9001L17.9271 14.9048C18.7314 14.9096 19.5326 15.2168 20.1438 15.8279L24.4299 20.114C25.0489 20.7331 25.0489 21.7338 24.4299 22.3528Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_283_1296">
          <rect
            width="38"
            height="38"
            fill="white"
            transform="translate(0.488281 0.648438)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
