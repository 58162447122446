import React, { Component } from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import RATIO, { ASPECT_RATIO_DETAILS } from '../../../constants/AspectRatios'
import * as VideoCropActions from '../../../redux/actions/VideoCropActions'
import * as FaceTrackActions from '../../../redux/actions/FaceTrackActions'
import * as RecorderActions from '../../../redux/actions/RecorderActions'
import classNames from 'classnames'

class RatioDropDown extends Component {
  constructor(props) {
    super(props)
    this.state = {
      allRatioShows: false,
    }

    this.wrapperRef = React.createRef()
    this.handleClickOutside = this.handleClickOutside.bind(this)

    this.mounted = false
  }

  componentDidMount() {
    this.mounted = true

    if (this.mounted) {
      this.setState({
        allRatioShows: this.props.allShows,
      })

      document.addEventListener('mousedown', this.handleClickOutside)
      document.addEventListener('touchend', this.handleClickOutside)
    }
  }

  componentWillUnmount() {
    if (this.mounted) {
      document.removeEventListener('mousedown', this.handleClickOutside)
      document.removeEventListener('touchend', this.handleClickOutside)
    }

    this.mounted = false
  }

  /**
   * Alert if clicked on outside of element
   */
  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      if (this.props.toggleSetRatioDropdown) {
        this.props.toggleSetRatioDropdown(false)
      }
    }
  }

  handleChangeCurrentLayout = (s) => {
    this.props.changeLayoutFromDropdown(true)
    this.props.setCurrentLayout(s)
    this.props.setFaceTrackingRatio(s)
    this.props.setSelectedMediaLayout(true, s)
    if (this.props.toggleSetRatioDropdown) {
      this.props.toggleSetRatioDropdown(false)
    }

    this.reloadSnapGrid()
  }

  reloadSnapGrid() {
    if (this.props.toggleShowSnapGrid) {
      this.props.toggleShowSnapGrid(false)

      setTimeout(() => {
        this.props.toggleShowSnapGrid(true)
      }, 500)
    }
  }

  render() {
    const { media_layout } = this.props
    // const { allRatioShows } = this.state;

    return (
      <div
        ref={this.wrapperRef}
        className="absolute ratio-action-dropdown flex items-center">
        {this.renderAspectRatio(
          media_layout,
          ASPECT_RATIO_DETAILS[RATIO.LANDSCAPE],
        )}
        {this.renderAspectRatio(
          media_layout,
          ASPECT_RATIO_DETAILS[RATIO.SQUARE],
        )}
        {this.renderAspectRatio(
          media_layout,
          ASPECT_RATIO_DETAILS[RATIO.SEMI_VERTICAL],
        )}
        {this.renderAspectRatio(
          media_layout,
          ASPECT_RATIO_DETAILS[RATIO.FULL_VERTICAL],
        )}
      </div>
    )
  }

  renderAspectRatio(selectedMediaLayout, aspectRatio) {
    const isCurrentLayoutActive = this.props.currentLayout === aspectRatio.ratio
    const isCroppingEnabled = _.get(
      this.props,
      `savedLayouts.${aspectRatio.ratio}`,
    )
    const isDefaultLayout = this.props.defaultLayout === aspectRatio.ratio
    return (
      <div
        onClick={() => this.handleChangeCurrentLayout(aspectRatio.ratio)}
        className={classNames(
          'ratio-action',
          'relative',
          'cursor-pointer',
          aspectRatio.css,
          'mb-5',
          {
            active: isCurrentLayoutActive && !isDefaultLayout,
            'de-active': !isCroppingEnabled,
          },
        )}>
        <div className="snap-grid-top-border absolute w-full top-0 left-0" />
        <div className="snap-grid-right-border absolute h-full top-0 right-0" />
        <div className="snap-grid-bottom-border absolute w-full bottom-0 left-0" />
        <div className="snap-grid-left-border absolute h-full top-0 left-0" />
        {isDefaultLayout && (
          <div className="absolute w-full h-full flex items-center justify-center">
            Default
          </div>
        )}
        <div className="absolute bottom-0 left-0 pb-1 pl-2 ratio-box-size font-bold font-bold">
          {_.replace(aspectRatio.aspectRatio, '/', ':')}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  media_layout: state.recorder.media_layout,
  resolution: state.recorder.resolution,
  cameraDimension: state.recorder.cameraDimension,
  selectedLayouts: state.recorder.selectLayout,
  savedLayouts: state.recorder.saveLayout,
  isCropped: state.recorder.isCropped,
  project: state.editor.project,
  defaultLayout: state.projectEdit.defaultLayout,
  currentLayout: state.videoCrop.currentLayout,
})

const mapDispatchProps = (dispatch) => ({
  setSelectedMediaLayout: (...args) =>
    dispatch(RecorderActions.setSelectedMediaLayout(...args)),
  setCurrentLayout: (...args) =>
    dispatch(VideoCropActions.setCurrentLayout(...args)),
  setFaceTrackingRatio: (...args) =>
    dispatch(FaceTrackActions.setFaceTrackingRatio(...args)),
})

export default connect(mapStateToProps, mapDispatchProps)(RatioDropDown)
