import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import { Menu, MenuButton, MenuList } from '@chakra-ui/menu'
import { Button } from '@material-tailwind/react'
import RATIO, { ASPECT_RATIO_DETAILS } from '../../../constants/AspectRatios'
import * as MediaPlayerActions from '../../../redux/actions/MediaPlayerActions'
import * as SelectedItemActions from '../../../redux/actions/SelectedItemActions'
import { triggerGoogleAnalyticsEvent } from '../../../utils/Helper'
import { FLOAT_RATE, SLIDER_PRO } from '../../../constants/TimelineItems'
import {
  SetTimeSlider,
  checkAddItem,
} from '../../../redux/actions/EditorActions'
import { selectScene } from '../../../methods/editor/MainTimelineMethods'
import TimelineService from '../../../services/time/TimelineService'

class AspectRatioDropdownOptions extends Component {
  handleClickOutside = (evt) => {
    const { onClickOutside } = this.props
    onClickOutside && onClickOutside(evt)
  }

  render() {
    const { project } = this.props
    return (
      <React.Fragment>
        <div className="p-1">Ratio</div>
        {true &&
          this.renderRatio(project, ASPECT_RATIO_DETAILS[RATIO.LANDSCAPE])}
        {true && this.renderRatio(project, ASPECT_RATIO_DETAILS[RATIO.SQUARE])}
        {true &&
          this.renderRatio(project, ASPECT_RATIO_DETAILS[RATIO.SEMI_VERTICAL])}
        {true &&
          this.renderRatio(project, ASPECT_RATIO_DETAILS[RATIO.FULL_VERTICAL])}
      </React.Fragment>
    )
  }
}

class StageRatioDropDown extends Component {
  menuRef = React.createRef()
  selectScene = selectScene.bind(this)

  state = {
    showRatios: false,
  }

  handleShowRatios(flag) {
    this.setState({
      showRatios: flag,
    })
  }

  handleClickOutside = () => {
    this.handleShowRatios(false)
  }

  handleChangeAspectRatio = (aspectRatio) => {
    const { project, changeAspectRatio, resetAllSelected } = this.props
    triggerGoogleAnalyticsEvent('change_aspect_ratio', {
      aspectRatio,
      projectId: project?.id,
      userId: this.props.authUser?.id,
    })
    resetAllSelected && resetAllSelected()
    changeAspectRatio && changeAspectRatio(project, aspectRatio)
  }

  getActiveClass(isAspectRatioActive) {
    return isAspectRatioActive ? 'bg-teal-500 text-white' : ''
  }

  moveTimelineByCurrentTime(sliderStartTime) {
    const { updateTimeSlider, project } = this.props
    const sliderPosition = TimelineService.convertMillisecondsToSliderPosition(
      sliderStartTime,
      project,
      SLIDER_PRO,
      FLOAT_RATE,
    )
    updateTimeSlider(sliderPosition)
  }

  refreshTimeline() {
    const {
      selectedItems: { selectedScene, selectedSceneIndex },
      timeSlider: { curSec },
      project,
    } = this.props
    if (project.duration) {
      this.selectScene(selectedScene, selectedSceneIndex, true, curSec + 1)
    }
  }

  changeAspectRatio(ratio) {
    this.handleChangeAspectRatio(ratio)
    this.refreshTimeline()

    setTimeout(() => {
      this.refreshTimeline()
    }, 500)
  }

  renderRatio(project, aspectRatioDetail) {
    const aspectRatio = _.replace(aspectRatioDetail.aspectRatio, '/', ':')
    const isAspectRatioActive = project.resolution === aspectRatioDetail.ratio
    const activeClass = this.getActiveClass(isAspectRatioActive)
    return (
      <div
        className={`p-4 hover:bg-teal-500 ${activeClass}`}
        onClick={() => this.changeAspectRatio(aspectRatioDetail.ratio)}>
        {aspectRatio} ({aspectRatioDetail.name})
      </div>
    )
  }

  componentDidMount() {
    const _self = this
    Object.assign(window, {
      resetAllSelected: function (...args) {
        _self.props.resetAllSelected(...args)
      },
      changeAspectRatio: function (...args) {
        _self.props.changeAspectRatio(...args)
      },
    })
  }

  render() {
    const { project } = this.props
    return (
      <Menu className="stage-dropdown-menu">
        {({ isOpen }) => {
          return (
            <>
              <MenuButton style={{ zIndex: 2 }}>
                <div className="text-indigo-700 cursor-pointer relative">
                  <Button
                    type="button"
                    className={'btn-publish'}
                    ripple={true}
                    style={{ display: 'flex', alignItems: 'center' }}>
                    <span>Ratios</span>
                    <svg
                      style={{
                        marginLeft: window.innerWidth > 1024 ? 10 : 4,
                      }}
                      width="9"
                      height="5"
                      viewBox="0 0 9 5"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M1.91427 0.355559C1.52374 -0.0349658 0.969722 -0.114111 0.676829 0.178782C0.383935 0.471675 0.463081 1.02569 0.853605 1.41622L3.68203 4.24465C3.92536 4.48797 4.23216 4.61041 4.49971 4.5973C4.76737 4.61056 5.07436 4.48812 5.31782 4.24467L8.14624 1.41624C8.53677 1.02571 8.61591 0.471695 8.32302 0.178802C8.03013 -0.114091 7.47611 -0.0349455 7.08558 0.355579L4.49993 2.94123L1.91427 0.355559Z"
                        fill="white"
                      />
                    </svg>
                  </Button>
                </div>
              </MenuButton>
              <div style={{ zIndex: 9999 }} bg="teal.500">
                <MenuList isactive={`${isOpen}`} className="menu-list">
                  <div className="p-1 text-center justify-center">Ratio</div>
                  {true &&
                    this.renderRatio(
                      project,
                      ASPECT_RATIO_DETAILS[RATIO.LANDSCAPE],
                    )}
                  {true &&
                    this.renderRatio(
                      project,
                      ASPECT_RATIO_DETAILS[RATIO.SQUARE],
                    )}
                  {true &&
                    this.renderRatio(
                      project,
                      ASPECT_RATIO_DETAILS[RATIO.SEMI_VERTICAL],
                    )}
                  {true &&
                    this.renderRatio(
                      project,
                      ASPECT_RATIO_DETAILS[RATIO.FULL_VERTICAL],
                    )}
                </MenuList>
              </div>
            </>
          )
        }}
      </Menu>
    )
  }
}

const mapStateToProps = (state) => ({
  authUser: state.auth.user,
  project: state.editor.project,
  timeSlider: state.timeline.timeSlider,
  previewTimeLine: state.timeline.previewTimeLine,
  selectedItems: state.selectedItems,
  selectedScene: state.selectedItems.selectedScene,
  selectedSceneIndex: state.selectedItems.selectedSceneIndex,
  checkedChannels: state.selectedItems.checkedChannels,
  addItem: state.editor.addItem,
  tweenObjectBuilder: state.mainContainer.tweenObjectBuilder,
})

const mapDispatchToProps = (dispatch) => ({
  changeAspectRatio: (...args) =>
    dispatch(MediaPlayerActions.changeAspectRatio(...args)),
  resetAllSelected: (...args) =>
    dispatch(SelectedItemActions.resetAllSelected(...args)),
  SetTimeSlider: (...any) => dispatch(SetTimeSlider(...any)),
  checkAddItem: (...any) => dispatch(checkAddItem(...any)),
  setSelectedScene: (...any) =>
    dispatch(SelectedItemActions.setSelectedScene(...any)),
  setSelectedSceneIndex: (...any) =>
    dispatch(SelectedItemActions.setSelectedSceneIndex(...any)),
  setSelectedSound: (...any) =>
    dispatch(SelectedItemActions.setSelectedSound(...any)),
  setSelectedSoundIndex: (...any) =>
    dispatch(SelectedItemActions.setSelectedSoundIndex(...any)),
})

export default connect(mapStateToProps, mapDispatchToProps)(StageRatioDropDown)
