import _ from 'lodash'
import process from 'process'
import * as Constants from '../constants/MediaPlayerConstants'
import * as EditorActions from '../../redux/actions/EditorActions'
import * as MainContainerActions from '../../redux/actions/MainContainerActions'

export const setPlaying = (scenePlaying) => {
  return (dispatch) => {
    dispatch({
      type: Constants.SET_PLAYING,
      scenePlaying,
    })
  }
}

export const setLoading = (isLoading) => {
  return (dispatch) => {
    dispatch({
      type: Constants.SET_LOADING,
      isLoading,
    })
  }
}

export const changeAspectRatio = (project, aspectRatio, updateDB = true) => {
  const updatedProject = _.cloneDeep(project)
  updatedProject.resolution = aspectRatio
  return (dispatch) => {
    dispatch(EditorActions.updateProject(updatedProject, updateDB))
    dispatch({
      type: Constants.CHANGE_ASPECT_RATIO,
      aspectRatio,
    })
    dispatch(setPlaying(false))
    process.nextTick(() => {
      dispatch(MainContainerActions.reloadStageByProject())
    })
  }
}

export const pauseTimeline = () => {
  return (dispatch) => {
    dispatch({
      type: Constants.SET_PLAY_COMPLETED,
      isPlayCompleted: true,
    })
    dispatch({
      type: Constants.PAUSE,
    })
  }
}

export const setPlayCompleted = (isPlayCompleted) => {
  return (dispatch) => {
    dispatch({
      type: Constants.SET_PLAY_COMPLETED,
      isPlayCompleted: isPlayCompleted,
    })
  }
}
