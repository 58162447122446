export const EditorUndoIcon = (props) => {
  return (
    <svg
      {...props}
      width="12"
      height="19"
      viewBox="0 0 12 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.40914 0.277167L0.995114 1.69119C0.620091 2.06621 0.409406 2.57485 0.409406 3.10521C0.409406 3.63558 0.620092 4.14422 0.995115 4.51924L2.40914 5.93326C2.53415 6.05827 2.70369 6.1285 2.88048 6.1285C3.05727 6.1285 3.22681 6.05827 3.35182 5.93326C3.47683 5.80825 3.54706 5.63871 3.54706 5.46192C3.54706 5.28513 3.47683 5.11559 3.35182 4.99058L2.10889 3.74765C3.1369 3.47394 4.21552 3.45048 5.25446 3.67923C6.2934 3.90798 7.26242 4.3823 8.0804 5.06246C8.89838 5.74263 9.54152 6.60885 9.95598 7.58862C10.3704 8.56839 10.5441 9.63319 10.4626 10.6939C10.381 11.7546 10.0465 12.7803 9.48712 13.6852C8.92772 14.59 8.15969 15.3477 7.24733 15.8948C6.33497 16.4419 5.30481 16.7625 4.24312 16.8297C3.18142 16.8969 2.11907 16.7088 1.145 16.2811C0.982992 16.2101 0.799411 16.2063 0.634646 16.2707C0.469879 16.335 0.337426 16.4622 0.266422 16.6242C0.195418 16.7862 0.191679 16.9698 0.25603 17.1346C0.32038 17.2993 0.44755 17.4318 0.609559 17.5028C1.76096 18.008 3.01525 18.2347 4.27065 18.1644C5.52606 18.0941 6.74721 17.7289 7.83503 17.0983C8.92285 16.4677 9.84669 15.5896 10.5316 14.5351C11.2165 13.4807 11.6432 12.2796 11.777 11.0294C11.9109 9.77917 11.7481 8.515 11.3019 7.33947C10.8557 6.16393 10.1387 5.11014 9.20898 4.26359C8.27928 3.41704 7.16311 2.80159 5.95103 2.46717C4.73896 2.13274 3.46511 2.08877 2.23286 2.33881L3.35182 1.21985C3.47683 1.09484 3.54706 0.925296 3.54706 0.748508C3.54706 0.571721 3.47683 0.402174 3.35182 0.277166C3.22681 0.15216 3.05727 0.0819308 2.88048 0.0819307C2.70369 0.0819305 2.53414 0.15216 2.40914 0.277167Z"
        fill="#6B7280"
      />
    </svg>
  )
}
