import React, { Component } from 'react'
import _ from 'lodash'
import { any, bool, func } from 'prop-types'
import RecordButton from './RecordButton'
import CountdownTimer from './CountdownTimer'
import Timer from './Timer'
import AudioMic from '../common-items/audio-mic'
import { connect } from 'react-redux'
import CountdownSwitch from './CountdownSwitch'
import RatioAction from '../common-items/RatioAction'
import * as SnapGridBoxActions from '../../../redux/actions/SnapGridBoxActions'
import {
  checkSaved,
  clearSaved,
  getShowSize,
  setSnapSize,
  toggleShowSaveTip,
} from '../../../methods/recorder/RatioActionMethods'
import { InfoIcon } from '../../cropper/icons/InfoIcon'
import {
  SubscriptionManager,
  alertSubscriptionMessage,
} from '../../../utils/Helper'
import { CloseIcon } from '../../common/icons/CloseIcon'

const hasSelectedAnAspectRatio = (props) => {
  const { selectedLayouts } = props
  return _.some(selectedLayouts)
}

class DesktopActions extends Component {
  constructor(props) {
    super(props)
    this.state = {
      startAudio: false,
      manWalk: false,
      showSetRatioDropdown: false,
    }

    this.getShowSize = getShowSize.bind(this) // get replay video show size(video showing size, browser size)
    this.checkSaved = checkSaved.bind(this)
    this.setSnapSize = setSnapSize.bind(this)
    this.clearSaved = clearSaved.bind(this)
    this.toggleShowSaveTip = toggleShowSaveTip.bind(this)

    this.moveRef = props.moveRef
  }

  mounted = false

  toggleShowSnapGrid = (r) => {
    this.setState({
      showSnapGrid: r,
    })
  }

  static getDerivedStateFromProps(prevProps, prevState) {
    if (
      !_.has(prevState, 'showSnapGrid') &&
      prevProps.isCameraOn &&
      hasSelectedAnAspectRatio(prevProps)
    ) {
      return { showSnapGrid: true }
    } else {
      return prevState
    }
  }

  addNewAspectRatio() {
    const {
      authUser,
      subscriptions: { user: userSubscription, products },
      savedLayouts,
      selectedRatio,
    } = this.props
    if (
      !SubscriptionManager(authUser, userSubscription, products)?.isProPlan &&
      savedLayouts.filter((x, index) => x && index !== selectedRatio).length >=
        2
    ) {
      return alertSubscriptionMessage(
        'Looks like you’re killing it on your socials. To create up to 4 aspect ratios simultaneously, please upgrade to the Cerebriam Pro package.',
      )
    }
    this.props.selectRatio(this.props.selectedRatio)
    this.props.resetSelectedAspectRatios()
    this.checkSaved()
  }

  setChangeRatio = () => {
    this.props.setSnapGridTransform('none', this.props.media_layout)

    this.toggleShowSnapGrid(false)

    setTimeout(() => {
      this.toggleShowSnapGrid(true)
    }, 500)
  }

  toggleSetRatioDropdown = (flag) => {
    this.setState({
      showSetRatioDropdown: flag,
    })
  }

  componentDidMount() {
    this.mounted = true

    //Tap the central point of attention
    setTimeout(() => {
      if (this.mounted) {
        this.setState({
          startAudio: true,
        })
      }
    }, 500)

    window.addEventListener('resize', this.setChangeRatio.bind(this))
  }

  componentWillUnmount() {
    if (this.mounted) {
      window.removeEventListener('resize', this.setChangeRatio.bind(this))

      this.setState({
        showSetRatioDropdown: false,
      })
    }

    this.mounted = false
  }

  tapGridAttentionPosition = (evt) => {
    if (!this.props.snapGridPosition.set) {
      this.props.setSnapGridPosition({
        set: true,
        top: evt.clientY,
        left: evt.clientX,
      })

      this.setChangeRatio()
    }
  }

  get isAspectRatioBoxDisplayed() {
    return true
  }

  render() {
    const {
      cameraVideo,
      isRecording,
      isRunningCountdown,
      onStartRecording,
      onStopRecording,
      MediaStream,
      showSnap,
      setScaleValue,
    } = this.props

    const { showSnapGrid, startAudio, manWalk, showSetRatioDropdown } =
      this.state

    return (
      <div
        ref={this.props.cropperActionWrapper}
        key="desktopActionWrapper"
        className="relative desktop-actions w-screen h-screen">
        <div
          style={{
            paddingTop: '20px',
            paddingLeft: '20px',
            paddingRight: '20px',
            background: 'linear-gradient(180deg , black, transparent)',
          }}
          className="absolute flex flex-row w-screen items-center justify-between">
          <div className="flex gap-4 items-center justify-around">
            {/* <ManWalkAction onClick={this.toggleTracking} manWalk={manWalk} /> */}
            <CloseIcon style={{ zIndex: 10, cursor: 'pointer' }} />
            <InfoIcon
              onClick={() => this.props.retriggerCropperTooltip()}
              style={{ zIndex: 10, cursor: 'pointer' }}
            />
            <CountdownSwitch />
          </div>
          {/* <div className="w-8/12 recorder-timer">
            {isRecording && !isRunningCountdown && (
              <Timer
                isRecording={isRecording}
                isRunningCountdown={isRunningCountdown}
              />
            )}
            &nbsp;
          </div> */}
          <div className="flex gap-4 items-center justify-around">
            {/* <AspectRatios
              toggleSetRatioDropdown={this.toggleSetRatioDropdown}
            /> */}
            {/* {showSetRatioDropdown && (
              <RatioDropDown
                toggleShowSnapGrid={this.toggleShowSnapGrid}
                toggleSetRatioDropdown={this.toggleSetRatioDropdown}
              />
            )} */}

            <RecordButton
              disabled={!_.some(this.props.saveLayout)}
              isRecording={isRecording}
              onStartRecording={onStartRecording}
              onStopRecording={onStopRecording}
              showRecord={this.isAspectRatioBoxDisplayed}
              onTurnOnCamera={this.props.onTurnOnCamera}
              onPauseRecording={this.props.onPauseRecording}
              onResumeRecording={this.props.onResumeRecording}
              pauseRecorder={this.props.pauseRecorder}
            />
          </div>
          <button
            className="add-ratio-btn"
            disabled={this.props.isFaceTrackingPreviewEnabled}
            onClick={() => this.addNewAspectRatio()}
            style={{ zIndex: 50 }}>
            +
          </button>
        </div>
        <div className="absolute bottom-0 w-full justify-between">
          {/* <ShootAction
            stream={MediaStream}
            setChangeRatio={this.setChangeRatio}
            setScaleValue={setScaleValue}
          /> */}

          <div className="absolute mic-action flex items-center justify-center lg:w-1/2 xs:w-full right-0">
            <div className="w-8/12 recorder-timer">
              {isRecording && !isRunningCountdown && (
                <Timer
                  isRecording={isRecording}
                  isRunningCountdown={isRunningCountdown}
                  pauseRecorder={this.props.pauseRecorder}
                />
              )}
              &nbsp;
            </div>
            <div style={{ background: 'rgba(0,0,0, 0.4)', height: 48 }}>
              <div className="mic-wrapper">
                {startAudio && (
                  <AudioMic
                    backgroundColor="#ffffff"
                    width={window.innerWidth < 1200 ? 300 : 600}
                    strokeColor="#fff"
                    stream={MediaStream}
                    className={'w-full'}
                  />
                )}
              </div>
            </div>
            {/* <div className="collaborator-action">
            <CollaboratorAction />
          </div> */}
          </div>
        </div>
        {this.props.cropperActionWrapper?.current &&
          cameraVideo &&
          showSnapGrid &&
          showSnap && (
            <RatioAction
              from="recorder"
              ratioToEdit={this.props.ratioToEdit}
              selectedRatio={this.props.selectedRatio}
              resetSelectedAspectRatios={this.props.resetSelectedAspectRatios}
              selectRatio={this.props.selectRatio}
              clearEditRatio={this.props.clearEditRatio}
              cameraVideo={cameraVideo}
              videoActionWrapper={this.props.cropperActionWrapper}
              direction="center"
              layoutChangedFromDropDown={this.props.layoutChangedFromDropDown}
              changeLayoutFromDropdown={this.props.changeLayoutFromDropdown}
              moveRef={this.props.moveRef}
              selNextPopup={this.props.selNextPopup}
              // Set base transform value
              transform={
                window.innerWidth < 992
                  ? `translate(${window.innerWidth / 2 - 186 / 2}px, ${
                      window.innerHeight / 2 - 105 / 2
                    }px)`
                  : `translate(${window.innerWidth / 2 - 867 / 2}px, ${
                      window.innerHeight / 2 - 488 / 2
                    }px)`
              }
            />
          )}
        {isRunningCountdown && <CountdownTimer />}
      </div>
    )
  }
}

DesktopActions.propTypes = {
  MediaStream: any,
  cameraVideo: any,
  isRunningCountdown: bool,
  isRecording: bool,
  isCameraOn: bool,
  onStartRecording: func,
  onStopRecording: func,
  setScaleValue: func,
  showSnap: bool,
}

const mapStateToProps = (state) => ({
  media_layout: state.recorder.media_layout,
  snapGridPosition: state.recorder.snapGridPosition,
  selectedLayouts: state.recorder.selectLayout,
  saveLayout: state.recorder.saveLayout,
  shoot_value: state.recorder.shoot_value,
  currentLayout: state.videoCrop.currentLayout,
  zoomAbleState: state.recorder.zoomAbleState,
  subscriptions: state.subscriptions,
})

export default connect(mapStateToProps, {
  setSnapGridPosition: SnapGridBoxActions.setSnapGridPosition,
  setSnapGridTransform: SnapGridBoxActions.setSnapGridTransform,
})(DesktopActions)
