import * as SnapGridBoxConstants from './../constants/SnapGridBoxConstants'
import * as FaceTrackConstants from '../../redux/constants/FaceTrackConstants'

/**
 * set up video crop size
 * @param aspectSize
 * @param index
 * @returns {{index, type: string, aspectSize}}
 */
export const setVideoAspectSize = (aspectSize, index, currentVideoId) => {
  return (dispatch) => {
    dispatch({
      type: FaceTrackConstants.SET_FACETRACKING_RATIO,
      faceTrackingRatio: index,
    })
    return dispatch({
      type: SnapGridBoxConstants.VIDEO_CROP_SIZE_SET,
      aspectSize,
      index,
      currentVideoId,
    })
  }
}

/**
 * set up snap grid transform
 * @param aspectTransform
 * @param index
 * @returns {{index, aspectTransform: *, type: string}}
 */
export const setSnapGridTransform = (aspectTransform, index) => {
  return {
    type: SnapGridBoxConstants.MEDIA_BOX_TRANSFORM_SET,
    aspectTransform,
    index,
  }
}

/**
 * set up media recorder stream size
 * @param streamSize
 * @param index
 * @returns {{index, streamSize: *, type: string}}
 */
export const setMediaStreamSize = (streamSize, index) => {
  return {
    type: SnapGridBoxConstants.SET_VIDEO_STREAM_SIZE,
    streamSize,
    index,
  }
}

export const setMediaOriginSize = (originVideoSize) => {
  return {
    type: SnapGridBoxConstants.SET_ORIGIN_SIZE,
    originVideoSize,
  }
}

export const setSnapGridPosition = (snapGridPosition) => {
  return {
    type: SnapGridBoxConstants.SET_SNAP_GRID_POSITION,
    snapGridPosition,
  }
}

/**
 * set up recorded video review screen size
 * @param clientRectSize
 * @param index
 * @returns {{index, clientRectSize, index, type: string}}
 */
export const setReviewScreenSize = (clientRectSize, index) => {
  return {
    type: SnapGridBoxConstants.SET_CLIENT_RECTANGLE_SIZE,
    clientRectSize,
    index,
  }
}

export const setCameraDimension = (cameraDimension) => {
  return {
    type: SnapGridBoxConstants.CAMERA_DIMENSION,
    cameraDimension,
  }
}
