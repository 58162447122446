export const ReviewDownloadIcon = () => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_688_4936)">
        <path
          d="M8.24243 15.1017C8.4746 15.334 8.75027 15.5183 9.05369 15.644C9.35711 15.7698 9.68233 15.8345 10.0108 15.8345C10.3392 15.8345 10.6644 15.7698 10.9678 15.644C11.2713 15.5183 11.5469 15.334 11.7791 15.1017L14.4549 12.4258C14.5984 12.2672 14.6754 12.0594 14.6699 11.8455C14.6644 11.6317 14.5769 11.4282 14.4254 11.2771C14.274 11.126 14.0703 11.0389 13.8564 11.0338C13.6426 11.0288 13.435 11.1062 13.2766 11.25L10.8383 13.6892L10.8441 0.833333C10.8441 0.61232 10.7563 0.400358 10.6 0.244078C10.4437 0.0877974 10.2318 0 10.0108 0V0C9.78975 0 9.57779 0.0877974 9.42151 0.244078C9.26523 0.400358 9.17743 0.61232 9.17743 0.833333L9.16993 13.6733L6.74493 11.25C6.58857 11.0937 6.37653 11.006 6.15547 11.0061C5.93441 11.0062 5.72244 11.0941 5.56618 11.2504C5.40993 11.4068 5.32219 11.6188 5.32227 11.8399C5.32234 12.0609 5.41023 12.2729 5.5666 12.4292L8.24243 15.1017Z"
          fill="#4B5563"
        />
        <path
          d="M19.1774 13.333C18.9564 13.333 18.7444 13.4208 18.5882 13.5771C18.4319 13.7334 18.3441 13.9453 18.3441 14.1663V17.4997C18.3441 17.7207 18.2563 17.9326 18.1 18.0889C17.9437 18.2452 17.7318 18.333 17.5107 18.333H2.51074C2.28973 18.333 2.07777 18.2452 1.92149 18.0889C1.76521 17.9326 1.67741 17.7207 1.67741 17.4997V14.1663C1.67741 13.9453 1.58961 13.7334 1.43333 13.5771C1.27705 13.4208 1.06509 13.333 0.844076 13.333C0.623062 13.333 0.4111 13.4208 0.25482 13.5771C0.0985396 13.7334 0.0107422 13.9453 0.0107422 14.1663L0.0107422 17.4997C0.0107422 18.1627 0.274134 18.7986 0.742975 19.2674C1.21182 19.7363 1.8477 19.9997 2.51074 19.9997H17.5107C18.1738 19.9997 18.8097 19.7363 19.2785 19.2674C19.7474 18.7986 20.0107 18.1627 20.0107 17.4997V14.1663C20.0107 13.9453 19.9229 13.7334 19.7667 13.5771C19.6104 13.4208 19.3984 13.333 19.1774 13.333Z"
          fill="#4B5563"
        />
      </g>
      <defs>
        <clipPath id="clip0_688_4936">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(0.0107422)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
