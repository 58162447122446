/* eslint-disable prettier/prettier */
import './editor-tooltip.scss'
import { useEffect } from 'react'
import { windowCloseTooltipInstr } from '../../services/project/windowCloseTooltipInstr'

const tooltipContent = {
  1: {
    title: 'Multiple aspect ratios',
    items: [
      'Toggle between the different aspect ratios you created.',
      'Edits made in one aspect ratio will automatically be applied to all other aspect ratios in this project',
    ],
  },
}

const EditorTooltip = ({ changeTooltipStep, closeTooltipSteps, tooltipStep, tooltipActive }) => {

  useEffect(() => {
    windowCloseTooltipInstr(closeTooltipSteps)
  }, [])
  
  return (
    <div
      className={`editor-tooltip ${!tooltipActive ? 'hide' : `step-${tooltipStep}`}`}>
      <span className="editor-tooltip-close" onClick={closeTooltipSteps}>
        X
      </span>
      <p className="editor-tooltip-title">{tooltipContent[tooltipStep].title}</p>
      {tooltipContent[tooltipStep].items.map((x, i) => {
        return <p key={i} className="editor-tooltip-content">{x}</p>
      })}
      <div className="editor-tooltip-footer">
        <p className="editor-tooltip-step">Step {tooltipStep} of 1</p>
        <button className="editor-tooltip-btn" onClick={changeTooltipStep}>{ tooltipStep === 1 ? 'Done!' : 'Next'}</button>
      </div>
    </div>
  )
}

export default EditorTooltip