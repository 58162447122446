import instance from '../../utils/Axios'
import setAuthToken from '../../utils/SetAuthToken'
import jwt_decode from 'jwt-decode'
import { HOME } from '../../constants/Routes'
import store from '../index'
import { triggerGoogleAnalyticsEvent } from '../../utils/Helper'

import {
  CLEAR_AUTH_ERRORS,
  GET_SIGNIN_ERRORS,
  GET_SIGNUP_ERRORS,
  SET_CURRENT_USER,
  SIGNIN_LOADING,
  SIGNOUT_USER,
  SIGNUP_LOADING,
  UPDATE_BOARDING,
  SET_COUPON,
} from './Types'
import { initiateProject } from './EditorActions'
import {
  currentUserSubscription,
  getAllSubscriptionProducts,
} from './SubscriptionActions'

const reconcileRegistrationData = (res, dispatch, history) => {
  const { token } = res.data
  localStorage.setItem('jwtTokenCTEditor', token)
  // Set token to Auth header
  setAuthToken(token)
  // Decode token to get user data
  const decoded = jwt_decode(token)
  // Set current user
  dispatch(setCurrentUser(decoded))
  dispatch(initiateProject(decoded.id))

  history.push(HOME, { from: 'signup' })
}

// Register Trial User
export const trialRegisterUser = (userData, history) => (dispatch) => {
  dispatch({
    type: SIGNUP_LOADING,
  })
  instance
    .post('/users/register', userData)
    .then((res) => {
      reconcileRegistrationData(res, dispatch, history)
    })
    .catch((err) =>
      dispatch({
        type: GET_SIGNUP_ERRORS,
        payload: err.response ? err.response.data : err,
      }),
    )
}

// Register User
export const registerUser = (userData, history) => (dispatch) => {
  dispatch({
    type: SIGNUP_LOADING,
  })
  instance
    .post('/users/register', userData)
    .then((res) => {
      reconcileRegistrationData(res, dispatch, history)
    })
    .catch((err) =>
      dispatch({
        type: GET_SIGNUP_ERRORS,
        payload: err.response ? err.response.data : err,
      }),
    )
}

// Login - get user token
export const loginUser = (userData) => (dispatch) => {
  localStorage.removeItem('cerebriam_onboarding')
  dispatch({
    type: SIGNIN_LOADING,
  })
  instance
    .post('/users/login', userData)
    .then(async (res) => {
      // Save to localStorage

      // Set token to localStorage
      const { token } = res.data
      localStorage.setItem('jwtTokenCTEditor', token)
      // Set token to Auth header
      setAuthToken(token)
      // Decode token to get user data
      const decoded = jwt_decode(token)
      // Set current user
      localStorage.setItem('cerebriam_onboarding', decoded.onboarding)
      dispatch(setCurrentUser(decoded))
      dispatch(initiateProject(decoded.id))
      const mySubscription = await currentUserSubscription()(dispatch)
      const products = await getAllSubscriptionProducts()(dispatch)
      return { mySubscription, products, token }
    })
    .catch((err) => {
      dispatch({
        type: GET_SIGNIN_ERRORS,
        payload: err.response ? err.response.data : err,
      })
      return err
    })
}

export const removeAuthErrors = () => (dispatch) => {
  dispatch({
    type: CLEAR_AUTH_ERRORS,
  })
}

// Set logged in user
export const setCurrentUser = (decoded) => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded,
  }
}

// Update boarding
export const setBoardingStatus = (status) => {
  const { category, feature } = store.getState().recorder

  instance.post('/users/onboarding', {
    category,
    feature,
  })
  return {
    type: UPDATE_BOARDING,
    payload: status,
  }
}

// Log user out
export const logoutUser = (history) => (dispatch) => {
  triggerGoogleAnalyticsEvent('sign_out')
  localStorage.removeItem('tooltip_instruction')
  // Remove token from local storage
  localStorage.removeItem('jwtTokenCTEditor')
  // Remove auth header for future requests
  setAuthToken(false)
  // Set current user to empty object {} which will set isAuthenticated to false
  dispatch(setCurrentUser({}))
  dispatch({
    type: SIGNOUT_USER,
  })
}

// Store the user's coupon in the state so it will be used on the sign up page
export const setCoupon = (coupon) => {
  return {
    type: SET_COUPON,
    coupon: coupon,
  }
}
