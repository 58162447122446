import _ from 'lodash'
import * as ResolutionUtils from '../../utils/ResolutionUtils'
import { DEFAULT_SCENE } from '../../constants/TimelineItems'
import TimelineService from '../time/TimelineService'
import { ASSET_LIBRARY } from '../../constants/AssetLibraries'
import FileUtils from '../../utils/FileUtils'
import { ONE_SECOND } from '../../constants/Timeline'

class SceneItemAdder {
  isProjectHasSceneItems(project) {
    const hasSceneItems = _.some(
      project?.scenes,
      (resolutionScenes) => !_.isEmpty(resolutionScenes),
    )
    return hasSceneItems
  }

  getNewStartTime(project) {
    const allSceneItems = _.flatMap(project?.scenes, (resolutionScenes) =>
      _.map(resolutionScenes, (sceneItem) => sceneItem.end_time),
    )
    const maxEndTime = _.max(allSceneItems)
    return maxEndTime
  }

  createSceneItemFromLibrary(
    libraryData,
    assetType,
    authorId,
    defaultLayout,
    isTemplate,
    isCropped,
    dimension,
  ) {
    let sceneItem = _.cloneDeep(DEFAULT_SCENE)
    const userFolderUrl = this.getUserFolderByAssetType(assetType, authorId)
    let durationInMilliseconds = this.getDurationInMilliseconds(
      libraryData.fileType,
      libraryData,
    )
    sceneItem.id = FileUtils.generateTimestampItemId()
    sceneItem.assetId = libraryData._id
    sceneItem.assetType = 'library'
    sceneItem.originUrl = libraryData.fileUrl
    sceneItem.userFolderUrl = userFolderUrl
    sceneItem.assetUrl = `${userFolderUrl}/${libraryData.fileName}_${defaultLayout}.mp4`
    sceneItem.thumbUrl = `${userFolderUrl}/${libraryData.fileName}_${defaultLayout}.jpg`
    sceneItem.originFileName = libraryData.fileName
    sceneItem.clip.start = 0
    sceneItem.clip.end = durationInMilliseconds
    sceneItem.clip.duration = durationInMilliseconds
    sceneItem.size.sliderW = TimelineService.convertMillisecondsToPixels(
      durationInMilliseconds,
    )
    sceneItem.size.sLeft = 0
    sceneItem.media_layout = defaultLayout
    sceneItem.p_width = libraryData?.originVideoSize?.width || 1920
    sceneItem.p_height = libraryData?.originVideoSize?.height || 1920
    sceneItem.wScale = 1
    sceneItem.hScale = 1
    sceneItem.dimension = dimension
    sceneItem.sound = libraryData.audio
    sceneItem.template = isTemplate
    sceneItem.crop = isCropped
    return sceneItem
  }

  getDurationInMilliseconds(folderPath, libraryData) {
    const durationInMilliseconds =
      folderPath === ASSET_LIBRARY.MEDIA.folderPath
        ? libraryData.duration * ONE_SECOND
        : libraryData.duration
    return _.round(durationInMilliseconds, -1)
  }

  getUserFolderByAssetType(assetType, authorId) {
    switch (assetType) {
      case ASSET_LIBRARY.CAPTURE.assetType: {
        return `${process.env.REACT_APP_S3_URL}assets/${ASSET_LIBRARY.CAPTURE.folderPath}/${authorId}`
      }
      case ASSET_LIBRARY.MEDIA.assetType: {
        return `${process.env.REACT_APP_S3_URL}assets/${ASSET_LIBRARY.MEDIA.folderPath}/${authorId}`
      }
      default: {
        return `${process.env.REACT_APP_S3_URL}assets/${ASSET_LIBRARY.MEDIA.folderPath}/${authorId}`
      }
    }
  }

  calculateSceneItemSize = (
    sceneItem,
    project,
    fileUrl,
    baseClipDuration = 5000,
  ) => {
    let sLeft = 0

    // finds scene with latest end time
    const sceneItems = project.scenes[project.resolution]
    if (sceneItems.length) {
      const latestScene = sceneItems.reduce((max, item) =>
        item.end_time > max.end_time ? item : max,
      )

      sceneItem.start_time = latestScene.end_time
      sceneItem.end_time = latestScene.end_time + baseClipDuration

      sLeft = latestScene.size.sLeft + latestScene.size.sliderW
    } else {
      sceneItem.start_time = 0
      sceneItem.end_time = baseClipDuration
    }

    sceneItem.clip = {
      start: 0,
      end: baseClipDuration,
      duration: baseClipDuration,
    }

    const img = new Image()
    img.src = fileUrl
    img.onload = () => {
      const { width, height } = img
      sceneItem.top = 0
      sceneItem.left = 0

      sceneItem.size = {
        ...sceneItem.size,
        sLeft: sLeft,
        width: width,
        height: height,
      }
    }
    return sceneItem
  }

  createPhotoOrBrandItemFromLibraryAsset = (
    libraryAsset,
    authorId,
    project,
    type = 'photo',
  ) => {
    const userFolderUrl = this.getUserFolderByAssetType('library', authorId)

    const { _id, duration, extension, fileName, fileUrl } = libraryAsset
    // console.debug('libraryAsset', libraryAsset)

    let sceneItem = _.cloneDeep(DEFAULT_SCENE)
    sceneItem.assetId = _id
    sceneItem.assetType = 'library'
    sceneItem.assetUrl = fileUrl
    // sceneItem.assetUrl = `${userFolderUrl}/${fileName}_${defaultLayout}_${extension}`
    sceneItem.ext = extension
    sceneItem.id = FileUtils.generateTimestampItemId()
    sceneItem.originFileName = fileName
    sceneItem.originUrl = fileUrl
    sceneItem.type = type
    sceneItem.thumbUrl = fileUrl
    sceneItem.userFolderUrl = userFolderUrl

    // console.debug('project', project)
    if (project?.scenes?.length > 0)
      this.calculateSceneItemSize(sceneItem, project, fileUrl, duration)

    // console.debug('sceneItem', sceneItem)
    return sceneItem
  }

  createSceneItemByRatio = (
    sceneItem,
    ratio,
    startAtMilliseconds,
    additionalParameters,
  ) => {
    let newSceneItem = _.cloneDeep(sceneItem)
    const { gridSizes, resolution } = additionalParameters
    newSceneItem.assetUrl = `${sceneItem.userFolderUrl}/${newSceneItem.originFileName}_${ratio}.mp4`
    newSceneItem.thumbUrl = `${sceneItem.userFolderUrl}/${newSceneItem.originFileName}_${ratio}.jpg`

    if (gridSizes) {
      const gridSize = ResolutionUtils.getGridSize(gridSizes, resolution, ratio)
      newSceneItem.size.width = gridSize.width
      newSceneItem.size.height = gridSize.height
    }

    newSceneItem.media_layout = ratio
    newSceneItem.aspectSize = additionalParameters.aspectSize[ratio]
    newSceneItem.streamSize = additionalParameters.streamSize[ratio]
    newSceneItem.start_time = startAtMilliseconds
    newSceneItem.end_time = startAtMilliseconds + newSceneItem.clip?.duration
    return newSceneItem
  }

  createPhotoItemByRatio = (
    sceneItem,
    ratio,
    startAtMilliseconds,
    additionalParameters,
  ) => {
    let newSceneItem = _.cloneDeep(sceneItem)

    const { gridSizes, resolution } = additionalParameters
    if (gridSizes) {
      const gridSize = ResolutionUtils.getGridSize(gridSizes, resolution, ratio)
      newSceneItem.size.width = gridSize.width
      newSceneItem.size.height = gridSize.height
    }

    newSceneItem.media_layout = ratio
    newSceneItem.aspectSize = additionalParameters.aspectSize[ratio]
    newSceneItem.streamSize = additionalParameters.streamSize[ratio]
    newSceneItem.start_time =
      newSceneItem.start_time > startAtMilliseconds
        ? sceneItem.start_time
        : startAtMilliseconds
    newSceneItem.end_time =
      newSceneItem.start_time + newSceneItem.clip?.duration
    return newSceneItem
  }
}

export default new SceneItemAdder()
