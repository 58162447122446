import _ from 'lodash'
import RATIO from '../../../constants/AspectRatios'
import FileUtils from '../../../utils/FileUtils'
import ProjectItemUtils from '../../../utils/ProjectItemUtils'
import DurationGetter from '../../time/DurationGetter'
import TimelineService from '../../time/TimelineService'
import ProjectItemsDecorator from '../ProjectItemsDecorator'

class RatioBasedItemCutter {
  cut(project, selectedItem, currentTime, projectPath, updateSound) {
    let clonedProject = _.cloneDeep(project)
    const nextItemId = FileUtils.generateTimestampItemId()
    _.forEach(RATIO, (ratio) => {
      const items = _.get(project, `${projectPath}.${ratio}`, [])
      const ratioSelectedItem = _.find(
        items,
        (item) => item.id === selectedItem?.id,
      )
      if (ratioSelectedItem) {
        const newRatioItems = this.cutFromItems(
          items,
          ratioSelectedItem,
          currentTime,
          nextItemId,
        )
        _.set(clonedProject, `${projectPath}.${ratio}`, newRatioItems)
      }
    })
    if (updateSound){
      clonedProject.nextItemId = nextItemId
    }
    clonedProject.duration =
      DurationGetter.getDurationFromProject(clonedProject)
    return clonedProject
  }

  cutFromItems(channelItems, selectedItem, currentTime, nextItemId) {
    const isCurrenTimeBeyondEndtime = !ProjectItemUtils.isTimeAroundItem(
      currentTime,
      selectedItem,
    )

    if (!selectedItem || _.isEmpty(channelItems) || isCurrenTimeBeyondEndtime) {
      return channelItems
    }

    const clippedDuration = currentTime - selectedItem.start_time
    const nextClippedDuration = selectedItem.end_time - currentTime

    const clippedStartTime = selectedItem.start_time
    const nextClippedStartTime = selectedItem.start_time + clippedDuration

    const clippedEndTime = selectedItem.start_time + clippedDuration
    const nextClippedEndTime = selectedItem.end_time

    const clippedStart = selectedItem.clip.start
    const nextClippedStart = selectedItem.clip.start + clippedDuration

    const clippedEnd = selectedItem.clip.start + clippedDuration
    const nextClippedEnd =
      selectedItem.clip.start + clippedDuration + nextClippedDuration

    const updatedSelectedItem = this.createRepositionedItem(
      selectedItem,
      clippedDuration,
      clippedStartTime,
      clippedEndTime,
      clippedStart,
      clippedEnd,
    )
    const nextItem = this.createRepositionedItem(
      {
        ...selectedItem,
        id: nextItemId,
      },
      nextClippedDuration,
      nextClippedStartTime,
      nextClippedEndTime,
      nextClippedStart,
      nextClippedEnd,
    )

    const updatedItems = _.reject(
      channelItems || [],
      (item) => item.id === selectedItem.id,
    )
    return ProjectItemsDecorator.reorderItemsByStartTime([
      ...updatedItems,
      updatedSelectedItem,
      nextItem,
    ])
  }

  createRepositionedItem(
    item,
    duration,
    startTime,
    endTime,
    clipStart,
    clipEnd,
  ) {
    const clonedItem = _.cloneDeep(item)
    clonedItem.clip.start = clipStart
    clonedItem.clip.duration = duration
    clonedItem.clip.end = clipEnd
    clonedItem.start_time = startTime
    clonedItem.end_time = endTime
    clonedItem.sliderW = TimelineService.convertMillisecondsToPixels(duration)
    clonedItem.sliderL = TimelineService.convertMillisecondsToPixels(startTime)
    return clonedItem
  }
}

export default new RatioBasedItemCutter()
