import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import { FULL_SCREEN_SET } from '../../redux/actions/Types'
import DesktopRecorder from './DesktopRecorder'
import { isMobile } from 'react-device-detect'
import MobileRecorder from './MobileRecorder'
import { trackScenes, assetsUpload } from '../../services/UploadServices'
import * as FaceTrackActions from '../../redux/actions/FaceTrackActions'
import * as SnapGridBoxActions from '../../redux/actions/SnapGridBoxActions'
import { resetCropAreas } from '../../redux/actions/RecorderActions'
import { updateProject } from '../../redux/actions/EditorActions'
import {
  onUploadToS3Bucket,
  onUploadFacetrackedVideoToS3Bucket,
  uploadFileToS3Bucket,
  addLibraryDataToProject,
} from '../../methods/recorder/VideoCaptureMethods'
import * as VideoUploadService from '../../services/uploads/VideoUploadService'
import * as SubscriptionActions from '../../redux/actions/SubscriptionActions'
import FileUtils from '../../utils/FileUtils'
import { SOCKET, SOCKET_EVENTS } from '../../utils/Socket'

const TrackSessionsIds = {
  SEGMENTATIONS: 'segmentations',
  SINGLE: 'single',
}

class VideoCapture extends Component {
  constructor(props) {
    super(props)

    this.state = {
      videoBlob: null,
      videoUrl: '',
      uploadingFile: false,
      uploadedFile: false,
      startedAt: null,
      duration: null,
      extension: null,
      isInlineRecordingSupported: null,
      isVideoInputSupported: null,
      aspectSize: [
        { width: 0, height: 0, top: 0, left: 0 },
        { width: 0, height: 0, top: 0, left: 0 },
        { width: 0, height: 0, top: 0, left: 0 },
        { width: 0, height: 0, top: 0, left: 0 },
      ],
      saveLayout: [false, false, false, false],
    }

    this.completeRecord = this.completeRecord.bind(this)
    this.onUploadToS3Bucket = onUploadToS3Bucket.bind(this)
    this.onUploadFacetrackedVideoToS3Bucket =
      onUploadFacetrackedVideoToS3Bucket.bind(this)
    this.addLibraryDataToProject = addLibraryDataToProject.bind(this)
    this.assetsUpload = assetsUpload.bind(this)
    this.uploadFileToS3Bucket = uploadFileToS3Bucket.bind(this)
  }

  componentDidMount() {
    const isInlineRecordingSupported =
      !!window.MediaRecorder && !!navigator.mediaDevices && !isMobile

    const isVideoInputSupported =
      document.createElement('input').capture !== undefined && isMobile

    this.props.applySetMainFullScreen(true)
    this.props.loadUserSubscriptions()
    this.props.loadSubscriptionProducts()
    this.props.getProductFeatures()

    this.setState({
      isInlineRecordingSupported,
      isVideoInputSupported,
      videoUrl: this.props.location.state?.videoUrl || '',
    })
    this.props.resetCropAreas()

    this.openSocketConnection()
  }

  componentWillUnmount() {
    if (this.props.fullscreen) {
      this.props.applySetMainFullScreen(false)
    }
    this.props.setFaceTrackingVideos(null)
    this.props.setFaceTrackingVideo(null)
    this.props.setFaceTrackingAudio(null)
    this.props.setFaceTrackingRatio(null)
    this.setState = () => {}

    SOCKET.off(SOCKET_EVENTS.receiveTrackedScenes)
  }

  handleUploadToS3Bucket = async () => {
    try {
      await this.onUploadToS3Bucket()
      return
    } catch (error) {
      console.error(error)
      toast.error(`Error occured: ${error.message}`)
    } finally {
      this.props.resetCropAreas() // saveLayout and aspectSize are reset to false and zeros so next time it doesn't use the previous settings
    }
  }

  handleUploadFacetrackedVideoToS3Bucket = async () => {
    try {
      await this.onUploadFacetrackedVideoToS3Bucket()
      return
    } catch (error) {
      console.error(error)
      toast.error(`Error occured: ${error.message}`)
    } finally {
      this.props.resetCropAreas() // saveLayout and aspectSize are reset to false and zeros so next time it doesn't use the previous settings
    }
  }

  handleReceivedFaceTrackedScene = async (data) => {
    const trackedScenes = data?.results
    if (data.sessionId === TrackSessionsIds.SINGLE) {
      const faceTrackingVideoUrls = trackedScenes.reduce(
        (acc, aspectResults) => {
          // const parseResult = JSON.parse(aspectResults)
          const parseResult = aspectResults
          const result0 = parseResult.result.find((result) => {
            return result.includes(`${this.state.videoName}_0.mp4`)
          })
          const result1 = parseResult.result.find((result) => {
            return result.includes(`${this.state.videoName}_1.mp4`)
          })
          const result2 = parseResult.result.find((result) => {
            return result.includes(`${this.state.videoName}_2.mp4`)
          })
          const result3 = parseResult.result.find((result) => {
            return result.includes(`${this.state.videoName}_3.mp4`)
          })
          if (parseResult.errorcode === 0) {
            acc[0] = result0
            acc[1] = result1
            acc[2] = result2
            acc[3] = result3
          }
          return acc
        },
        {},
      )
      const faceTrackingVideoBlobs =
        await VideoUploadService.getVideoBlobsByUrl(
          faceTrackingVideoUrls,
          null, // TODO: Remove after python changes made
        )
      this.props.setFaceTrackingVideoBlobs(faceTrackingVideoBlobs)
      this.props.setFaceTrackingVideos(faceTrackingVideoUrls)
      this.props.setFaceTrackingVideo(this.state.videoUrl) // TODO: Remove after python changes made

      this.setState({ isVideoFaceTracking: false })
    }
  }

  openSocketConnection = async () => {
    SOCKET.on(SOCKET_EVENTS.receiveTrackedScenes, async (data) => {
      // Check user
      if (data?.userId === this.props.authUser.id) {
        await this.handleReceivedFaceTrackedScene(data)
      }
    })
  }

  trackScenes = async (data, sessionId) => {
    const response = await trackScenes({
      data,
      sessionId,
    })
    return response
  }

  completeRecord = async (videoBlob, startedAt, duration, extension) => {
    const { authUser } = this.props
    // upload the file to S3 and send a link to the tracker for the processing
    if (this.props.isFaceTrackingEnabled) {
      try {
        this.setState({ isVideoFaceTracking: true })
        const { aspectSize, defaultLayout, streamSize } = this.props
        const videoFilename = FileUtils.createFilenameByTimestamp()

        const videoFile = await VideoUploadService.uploadVideoBlob(
          authUser.id,
          videoBlob,
          videoFilename,
          extension,
        )
        const trackData = [
          {
            fileUrl: videoFile.location,
            filename: videoFilename,
            aspectSizes: aspectSize,
            streamSize: streamSize[defaultLayout],
            userId: authUser.id,
          },
        ]

        await this.trackScenes(trackData, TrackSessionsIds.SINGLE)
      } catch (error) {
        toast.error(error.message)
      }
    }
    this.setState({
      startedAt,
      duration,
      extension,
      videoBlob,
    })
  }
  render() {
    const { isInlineRecordingSupported, isVideoInputSupported } = this.state
    return (
      <div className="absolute z-50 w-full h-full overflow-hidden">
        {isInlineRecordingSupported && !isMobile && (
          <DesktopRecorder
            isOnInitially={true}
            isFlipped={false}
            onRecordingComplete={(
              videoBlob,
              startedAt,
              duration,
              extension,
            ) => {
              // Do something with the video...
              this.completeRecord(videoBlob, startedAt, duration, extension)
            }}
            onUploadRecordVideo={() => {
              this.handleUploadToS3Bucket()
            }}
            onUploadFacetrackedVideo={() => {
              this.handleUploadFacetrackedVideoToS3Bucket()
            }}
            isUploadingVideo={this.state.uploadingFile}
            isUploadedVideo={this.state.uploadedFile}
            isVideoFaceTracking={this.state.isVideoFaceTracking}
          />
        )}

        {isVideoInputSupported && isMobile && (
          <MobileRecorder
            isOnInitially={true}
            onRecordingComplete={(
              videoBlob,
              startedAt,
              duration,
              extension,
            ) => {
              // Do something with the video...
              this.completeRecord(
                videoBlob,
                startedAt,
                duration * 1000,
                extension,
              )
            }}
            onUploadRecordVideo={() => {
              this.handleUploadToS3Bucket()
            }}
            isUploadingVideo={this.state.uploadingFile}
            isUploadedVideo={this.state.uploadedFile}
          />
        )}
      </div>
    )
  }
}

/**
 * @param state
 * @returns {{feature: *}}
 */
const mapStateToProps = (state) => ({
  aspectSize: state.recorder.aspectSize,
  authUser: state.auth.user,
  availGridSizes: state.recorder.availGridSizes,
  cameraDimension: state.recorder.cameraDimension,
  clientRectSize: state.recorder.clientRectSize,
  defaultLayout: state.projectEdit.defaultLayout,
  direction: state.recorder.direction,
  faceTrackingRatio: state.faceTrack.faceTrackingRatio,
  faceTrackingVideos: state.faceTrack.faceTrackingVideos,
  faceTrackingVideo: state.faceTrack.faceTrackingVideo,
  faceTrackingAudio: state.faceTrack.faceTrackingAudio,
  isFaceTrackingEnabled: state.faceTrack.isFaceTrackingEnabled,
  media_layout: state.recorder.media_layout,
  originVideoSize: state.recorder.originVideoSize,
  resolution: state.recorder.resolution,
  saveLayout: state.recorder.saveLayout,
  scaleValue: state.videoCrop.scaleValue,
  shoot_value: state.recorder.shoot_value,
  streamSize: state.recorder.streamSize,
  zoomAbleState: state.recorder.zoomAbleState,
  project: state.editor.project,
  mySubscription: state.subscriptions.user,
  productFeatures: state.subscriptions.features,
  products: state.subscriptions.products,
})

const mapDispatchToProps = (dispatch) => ({
  applySetMainFullScreen: (fullscreen) =>
    dispatch({ type: FULL_SCREEN_SET, fullscreen }),
  setVideoAspectSize: (...any) =>
    dispatch(SnapGridBoxActions.setVideoAspectSize(...any)),
  setFaceTrackingVideos: (...args) =>
    dispatch(FaceTrackActions.setFaceTrackingVideos(...args)),
  setFaceTrackingVideo: (...args) =>
    dispatch(FaceTrackActions.setFaceTrackingVideo(...args)),
  setFaceTrackingVideoBlobs: (...args) =>
    dispatch(FaceTrackActions.setFaceTrackingVideoBlobs(...args)),
  setFaceTrackingAudio: (...args) =>
    dispatch(FaceTrackActions.setFaceTrackingAudio(...args)),
  setFaceTrackingRatio: (...args) =>
    dispatch(FaceTrackActions.setFaceTrackingRatio(...args)),
  updateProject: (...any) => dispatch(updateProject(...any)),
  resetCropAreas: (...args) => dispatch(resetCropAreas(...args)),
  loadUserSubscriptions: () =>
    dispatch(SubscriptionActions.currentUserSubscription()),
  loadSubscriptionProducts: () =>
    dispatch(SubscriptionActions.getAllSubscriptionProducts()),
  getProductFeatures: () => dispatch(SubscriptionActions.getProductFeatures()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(VideoCapture))
