export const DeleteIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="9.75" fill="white" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12 21.75C13.9284 21.75 15.8134 21.1782 17.4168 20.1068C19.0202 19.0355 20.2699 17.5127 21.0078 15.7312C21.7458 13.9496 21.9389 11.9892 21.5627 10.0979C21.1864 8.20656 20.2579 6.46927 18.8943 5.10571C17.5307 3.74215 15.7934 2.81355 13.9021 2.43735C12.0108 2.06114 10.0504 2.25422 8.26884 2.99218C6.48726 3.73013 4.96451 4.97982 3.89317 6.58319C2.82183 8.18657 2.25 10.0716 2.25 12C2.25292 14.585 3.28108 17.0632 5.10893 18.8911C6.93678 20.7189 9.41503 21.7471 12 21.75ZM14.7875 8.33284H16.2083C16.4615 8.33284 16.6667 8.53806 16.6667 8.79119C16.6667 9.04431 16.4615 9.24951 16.2083 9.24951H15.75V15.2078C15.7485 16.4729 14.7233 17.498 13.4583 17.4995H10.7083C9.44331 17.498 8.41818 16.4729 8.41665 15.2078V9.24951H7.95833C7.7052 9.24951 7.5 9.04429 7.5 8.79116C7.5 8.53804 7.7052 8.33284 7.95833 8.33284H9.37915C9.59842 7.26657 10.5364 6.50089 11.625 6.49951H12.5417C13.6302 6.50089 14.5682 7.26657 14.7875 8.33284ZM11.1667 14.7495C11.4198 14.7495 11.625 14.5443 11.625 14.2912H11.625V11.5412C11.625 11.2881 11.4198 11.0829 11.1667 11.0829C10.9135 11.0829 10.7083 11.2881 10.7083 11.5412V14.2912C10.7083 14.5443 10.9135 14.7495 11.1667 14.7495ZM13 14.7495C13.2531 14.7495 13.4583 14.5443 13.4583 14.2912V11.5412C13.4583 11.2881 13.2531 11.0829 13 11.0829C12.7469 11.0829 12.5417 11.2881 12.5417 11.5412V14.2912C12.5417 14.5443 12.7469 14.7495 13 14.7495ZM11.625 7.41616C11.0425 7.41687 10.5233 7.78389 10.3284 8.33284H13.8383C13.6433 7.78389 13.1242 7.41687 12.5417 7.41616H11.625Z"
        fill="#DA403E"
      />
    </svg>
  )
}
