import React from 'react'
import 'react-responsive-modal/styles.css'
import AppModal from '../modals/AppModal'
import FeedbackModal from '../modals/FeedbackModal'
import UserProfileModal from '../modals/UserProfileModal'
import { FacebookIcon } from '../common/icons/FacebookIcon'
import { YoutubeIcon } from '../common/icons/YoutubeIcon'
import { TwitterIcon } from '../common/icons/TwitterIcon'
import { TiktokIcon } from '../common/icons/TikTokIcon'
import { LinkedinIcon } from '../common/icons/LinkedinIcon'
import { InstagramIcon } from '../common/icons/InstagramIcon'

export const AccountModal = ({ open, onClose, title }) => {
  return (
    <AppModal open={open} onClose={onClose} title={title} hideHeader={true}>
      <UserProfileModal />
    </AppModal>
  )
}

export const ContactModal = ({ open, onClose, title }) => {
  return (
    <AppModal open={open} onClose={onClose} title={title} hideHeader>
      <>
        <p className="sub-title">
          Help us make Cerebriam Studio better! Let us know your thoughts:
        </p>

        <FeedbackModal closeModal={onClose} />
      </>
    </AppModal>
  )
}

export const FollowModal = ({ open, onClose, title }) => {
  return (
    <AppModal open={open} onClose={onClose} title={title} hideHeader>
      <>
        <div className="shareIcons">
          <a href="https://www.facebook.com/cerebriam" target="blank">
            <FacebookIcon />
          </a>
          <a href="https://www.youtube.com/user/Cerebriam" target="blank">
            <YoutubeIcon />
          </a>
          <a href="https://twitter.com/cerebriam" target="blank">
            <TwitterIcon />
          </a>
          <a href="https://www.tiktok.com/cerebriam" target="blank">
            <TiktokIcon />
          </a>
          <a href="https://www.instagram.com/cerebriam" target="blank">
            <InstagramIcon />
          </a>
          <a href="https://www.linkedin.com/company/cerebriam" target="blank">
            <LinkedinIcon />
          </a>
        </div>
      </>
    </AppModal>
  )
}

export const InfoModal = ({ open, onClose, title }) => {
  return (
    <AppModal open={open} onClose={onClose} title={title}>
      <>
        <p className="infoLines">
          Terms and Conditions of Use<br></br>
          Last updated: 1 September 2021<br></br>
          PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY. This terms of use
          agreement (this “agreement”) (together with the documents referred to
          in it) is a legal agreement between you and Cerebriam Studio Ltd a
          limited company registered in England and Wales under company number
          11298954. Our registered address is 20-22 Wenlock Road, London, N1
          7GU, UK (“we”, “us” or “our”) for the use of our website:
          www.web.cerebriam.com and www.cerebriam.com/blog (Our Site), and web
          application (Platform): www.cerebriam.com (collectively ‘Our Studio’.)
          By using and/or registering to use Our Studio, you must read, agree
          with and accept all of the terms and conditions contained in this
          agreement. This agreement is provided to you and concluded in English.
          You agree that any use by you of Our Studio shall constitute your
          acceptance of the agreement. We recommend that you store or print-off
          a copy of the agreement (including all policies) for your records. If
          you do not agree to the terms of this agreement, please refrain from
          using the Our Studio.<br></br>
          1. APPLICABLE TERMS<br></br>
          1.1 This agreement refers to the following additional terms, which
          also apply to your use of Our Studio: (a) our privacy policy, which
          sets out the terms on which we process any personal data we collect
          from you, or that you provide to us. By using Our Studio, you consent
          to such processing and you warrant that all data provided by you is
          accurate; and (b) our cookie policy, which sets out information about
          the cookies on Our Studio.
          <br></br>
          2. ACCESSING THE OUR STUDIO AND USING THE SERVICES<br></br>
          2.1 We have developed Our Studio, using AI technology to automate
          certain video production techniques requiring only minimal user input,
          thereby simplifying the technical process of video production,
          improving the quality and speed of video production, and reducing the
          cost of the production (together the “Services”). 2.2 You may receive
          access and direct links to third party services for your benefit and
          ease of service via Our Studio. You acknowledge and agree that when
          you click on a link to a third party website on Our Studio you are
          leaving Our Studio and entering a third party website which is subject
          to its own terms and conditions. Please read all third party terms and
          conditions carefully before using such sites. We accept no
          responsibility or liability in respect of any events that occur whilst
          you are using third party platforms and services. 2.3 In consideration
          of you agreeing to abide by the terms of this agreement, we hereby
          grant to you a non-exclusive, non-transferable, licence to use Our
          Studio and the Services on the terms of this agreement. To access and
          use the Services you must sign up with your name, email address and
          password to create an account (“Account”). We discourage generic
          account names and we actively monitor concurrent Account usage. You
          may log into your Account on multiple devices but you shall not be
          able to access and use the Services via the same Account from multiple
          devices at the same time. Dependent on the tier of your Account (as
          detailed at clause 3.3) you may be authorized to have more than one
          user (“Seat”) per your Account. The number of Seats per Account shall
          be determined by your tier and if there are multiple Seats allowed via
          your Account, each Seat shall need to be a named individual. The
          original Account holder shall be the lead Seat and shall be able to
          add a specified number of other Seats (in accordance with the selected
          tier). Seats may be transferred to new users at any time but shall
          always be for named individuals and not a generic Seat used by
          multiple individuals concurrently. Sharing of log-in details with any
          other user is strictly prohibited in accordance with clause 2.6. 2.4
          Access to Our Studio is permitted on a temporary basis and we reserve
          the right to withdraw or amend the services or functionality that we
          provide on Our Studio without notice. We will not be liable if for any
          reason if Our Studio is unavailable at any time or for any period. 2.5
          From time to time, we may restrict access to some parts of Our Studio,
          or the entire Studio, to users who have registered with us. We will
          use reasonable endeavours to fix issues and bugs in Our Studio as
          quickly as we can. 2.6 If you choose, or you are provided with, a user
          identification code, password or any other piece of information as
          part of our security procedures, you must treat such information as
          confidential, and you must not disclose it to any third party. We have
          the right to disable any user identification code or password, whether
          chosen by you or allocated by us, at any time, if in our opinion you
          have failed to comply with any of the provisions of this agreement.
          2.7 You must not misuse Our Studio by knowingly introducing viruses,
          Trojans, worms, logic bombs or other material which is malicious or
          technologically harmful. You must not attempt to gain unauthorized
          access to Our Studio, the server on which Our Studio is stored or any
          server, computer or database connected to Our Studio. You must not
          attack Our Studio via a denial-of-service attack or a distributed
          denial-of service attack. 2.8 If you breach clause 2.7, you will be
          committing a criminal offence under the Computer Misuse Act 1990. We
          will report any such breach to the relevant law enforcement
          authorities and we will cooperate with those authorities by disclosing
          your identity to them. In the event of such a breach, we reserve the
          right to immediately cease your right to use Our Studio.
          <br></br>
          3. FREE TRIAL, SUBSCRIPTIONS AND FEES<br></br>
          3.1 Free trial for new users. If you are a new user, signing up to Our
          Studio to use the Services for the first time, once you have created
          an Account you shall have the option to sign up for a free 14-day
          trial. A free trial shall consist of free video creation services for
          up to six files of any supported duration which may be used during the
          next fourteen (14) days after you have signed up (“Free Trial”). In
          order to enjoy the Free Trial you may need to enter your payment
          details but you shall not be charged until after the Free Trial has
          ended. 3.2 Unless you choose to opt out prior to the Free Trial period
          ending (i.e. before day fifteen (15)), after your Free Trial ends you
          shall be automatically subscribed to the Services as further detailed
          below. 3.3 Subscriptions. If you are migrating from the Free Trial to
          a subscription plan, you shall be automatically signed up for a
          monthly subscription where payment is taken upfront at the beginning
          of each month (the “Monthly Subscription”), or you may choose to
          upgrade (as detailed below) to an annual subscription with the benefit
          of a discount and payment upfront annually (the “Annual
          Subscription”). You can also opt for an Annual Subscription during the
          Free Trial period (in which case it shall commence automatically after
          the Free Trial has ended) or at any time thereafter (in which case
          your upgrade shall commence as soon as you have selected it within in
          your Account) For the avoidance of doubt there shall be no pro rata
          fee deductions for upgrades during the middle of the month. Users on
          Pay As You Go payment plans may elect to sign up for a Monthly
          Subscription or an Annual Subscription plan. For the avoidance of
          doubt, if you are migrating from the Pay As You Go payment plan to a
          subscription plan you are not a new user and therefore will not be
          eligible for a free trial. 3.4 Fair use. You will receive unlimited,
          day-to-day fair use (“Fair Use”) (Fair Use is subject to our
          discretion) of the Services on specific Monthly Subscription and the
          Annual Subscription (Studio, Pro, and Enterprise plans). We reserve
          the right to monitor usage, and if we determine over-use of the
          Services, going beyond Fair Use, that is equivalent to the High Volume
          or Archive Use (as defined below) thresholds we shall inform you of
          your over-use and give you the option to purchase the High Volume or
          Archive Use add-on(s). Fair use does not accrue, and resets every
          billing period (Monthly or Yearly).<br></br>
          <br></br>
          Subscription Type Fees Monthly Subscription Please refer to the
          Pricing Page Annual Subscription (EU Countries only) Please refer to
          the Pricing Page
          <br></br>
          Add-on Fees<br></br>
          High Volume: means any video created above the Fair Use limit [during
          any month]. Contact us: support [at] cerebriam.co.uk Archive Use:
          means when you know in advance how many videos and/or the number of
          hours that need to be created in advance. This feature is for projects
          with a known volume that is higher than Fair Use. Contact us: support
          [at] cerebriam.co.uk 3.5 If your usage puts system integrity at risk
          i.e. you upload a large volume of files in a short period of time, we
          reserves the right to throttle your access to the service to ensure
          system integrity for all our customers.
          <br></br>
          4. PAYMENT, UPGRADING AND DOWNGRADING <br></br>
          4.1 If you wish to subscribe for a Monthly Subscription or Annual
          Subscription, you: (a) agree to pay to us the relevant Fee as set out
          on the Pricing Page;and (b) agree and undertake to pay to us (without
          any deduction) the relevant Fee in advance in full (in GBP, EUR, USD
          or the equivalent in your local currency based on the conversion rate
          at the time of payment) and authorise us to collect the full fee from
          you each month or at the beginning of each year (as applicable) until
          you cancel your subscription in accordance with clauses 5.3 to 5.6
          below; and (c) acknowledge that, subject to clause 5, that the
          relevant Fees are non-refundable. 4.2 You may upgrade from a Monthly
          Subscription to an Annual Subscription at any time via your Account.
          If you upgrade from a Monthly Subscription to an Annual Subscription,
          we will immediately bill you and you agree to pay to us (without any
          deduction) the relevant Fee in full at the beginning of the next month
          when payment of the Fees is due. You may also downgrade from an Annual
          Subscription to a Monthly Subscription via your Account at the end of
          the year of your Annual Subscription. The downgrade will take effect
          from the end of the annual period. Payment will then be taken monthly
          in accordance with the Monthly Subscription payment plan. 4.3 You may
          also upgrade from a lower tier plan to a higher tier plan i.e. from
          Studio to Pro or Enterprise. If you upgrade your tier via your Account
          you will be immediately charged the pro-rated difference (for the
          month or the year as applicable) between the two tiers and will
          immediately receive the extra benefits of your new tier. 4.4 You may
          also downgrade your tier by selecting to do so via your Account. If
          you choose to do so, the downgrade will come into effect at the end of
          the month or the end of the year as applicable in time for your next
          Fee payment. You shall lose the benefits of the tier from which you
          have downgraded at this time. 4.5 If you are signed up for a Monthly
          Subscription, you may pause your Account at any time. The new Fee rate
          will take effect at the beginning of the next month after you have
          opted to pause your Account. For the avoidance of doubt you cannot
          pause your Account if you are signed up to an Annual Subscription.
          Please contact us if you have problems pausing your account support
          [at] cerebriam.co.uk During the pause the following shall occur: (a)
          we shall keep your files safe for the period of the pause (however you
          shall not be able to view or access them); (b) you shall not be able
          to use the Services. You can reactivate your Account and end the pause
          at any time. For the avoidance of doubt you will not receive a refund
          for any pause Fees paid if you reactivate your Account in the middle
          of the month, and you shall be charged the Monthly Subscription Fee
          rate (with no pro-rating). 4.6 We reserve our rights to amend the fees
          at any time. However, we shall notify you in writing of any change in
          the Fees and any such change shall take effect at the beginning of the
          new month for Monthly Subscriptions and the beginning of the New Year
          for Annual Subscriptions. 4.7 We may offer promotional offers from
          time to time which may include promo codes or Account credits, and
          which may be subject to expiration dates and may only be applicable to
          selected users of Our Studio. We reserve the right to remove Account
          credits from your Account or end a promotional offer without notice if
          we believe, at our sole discretion, you are not acting in good faith
          in relation to the terms of the promotion. 4.8 Payments will be
          processed by a third party payment processor, Stripe, in accordance
          with its standard terms and conditions: https://stripe.com/gb/ssa. You
          hereby give consent: (a) to us sending you electronic communications
          (including via email and text) in relation to upcoming and successful
          payments managed via Our Studio; and (b) for the payment processor to
          access your bank account which is nominated by you from time to time,
          and to either deduct or deposit the applicable amount, less any fee
          which may apply. See the Pricing Page for more information about
          applicable Fees. You acknowledge and agree that when using the Stripe
          service on Our Studio, you will comply with Stripe’s end user licence
          agreement in respect to your use of the Stripe service. You also
          acknowledge that Stripe’s privacy policy at:
          https://stripe.com/gb/privacy shall apply to Stripe’s processing of
          any personal information you submit to Stripe via Our Studio. We shall
          not be liable to any person if Stripe or any other payment processor
          is not able to deduct or deposit any amount due to insufficient funds
          or incorrect bank account details. 4.9 If the credit card details you
          have provided us with via your Account are invalid at the point of us
          charging you for the Fees owed to us, we shall inform you by email. We
          will make every effort to contact you by email (to the address used to
          set up your Account) if your card payment fails. 4.10 If your payment
          fails, after notifying you we shall continue to attempt to take
          payment up to three (3) times over a period of nine (9) days to allow
          you to update your payment details before we shall suspend your
          Account and your access to Our Platform and Services. 4.11 If the card
          details are not updated after 9 days, we reserve the right to
          permanently delete your Account and all data and files held within.
          <br></br>
          5. CANCELLATION AND REFUNDS <br></br>
          Cooling-off period 5.1 If you are a consumer, you have a legal right
          to cancel a contract under the Consumer Contracts (Information,
          Cancellation and Additional Charges) Regulations 2013 during the
          period set out below in clause 5.2. This means that during the
          relevant period if you change your mind or for any other reason you
          decide you do not want to use the Services, you can notify us of your
          decision to cancel this agreement and receive a refund for any amount
          you have already paid minus a pro-rated amount for any use of the
          Services you have already made during the cooling-off period. 5.2 Your
          legal right to cancel this agreement starts from the date you sign up
          for a Free Trial in accordance with clause 3.1 above. You then have a
          period of fourteen (14) days in which you may cancel (“cooling-off
          period”). 5.3 To cancel this agreement during the cooling-off period,
          please do so at any time during the fourteen (14) day period by
          clicking on the ‘Cancel Subscription’ link on your Account settings
          page or contact us in writing to tell us by sending an email to:
          support [at] cerebriam.co.uk. You may wish to keep a copy of your
          cancellation notification for your own records. If you send us your
          cancellation notice by email or via your Account, then your
          cancellation is effective from the date you sent us the email or
          clicked on the ‘Cancel Subscription’ in your Account settings.
          Cancellation after the cooling-off period 5.4 After the expiry of the
          cooling-off period set out in clause 5.2, you can still cancel your
          Account at any time by clicking on the ‘Cancel Subscription’ link on
          your Account settings page. You are solely responsible for properly
          cancelling your Account. For the avoidance of doubt, the Monthly
          Subscription automatically renews each month and the Annual
          Subscription automatically renews each year. 5.5 If you cancel your
          Monthly Subscription your cancellation will take effect the following
          month and you will still be charged the Fee for the current month. No
          refund will be available for any remaining days in the month. 5.6
          After each auto-renewal of your Annual Subscription you will have
          fourteen (14) days in which to cancel your subscription and receive a
          refund. Please request this in writing [via your Account or] by
          sending an email to support [at] cerebriam.co.uk. If you request to
          cancel during the fourteen (14) day period and there is significant
          use of Our Platform via your Account during this time you may be
          charged the Monthly Subscription Fee for one month. This shall be at
          our sole discretion. For the avoidance of doubt if you are charged a
          Monthly Subscription Fee for one month this shall be deducted from the
          Annual Subscription amount and you shall receive a refund for the
          remaining monies. After the fourteen (14) day period you will no
          longer be eligible for a refund if you choose to cancel your Annual
          Subscription.
          <br></br>
          6. YOUR CONTENT AND CONFIDENTIALITY <br></br>
          6.1 Audio/visual/document content submitted to us by you (and any
          intellectual property rights in it) is owned by you or your licensor.
          You grant us a non-exclusive license to use that audio/visual/document
          content solely for the purpose of providing the Services and improving
          the usability and performance of the Services. Audio/visual content
          created by you using the Platform (and any intellectual property
          rights in them) are owned by you, subject to you having paid the Fees
          required under this agreement. 6.2 We shall have no obligations with
          respect to the audio/visual material which you submit other than to
          perform the Services and improve the usability and performance of the
          Services. You are solely responsible for such material.
          <br></br>
          7. YOUR INDEMNITIES AND UNDERTAKINGS <br></br>
          7.1 You agree that when using Our Studio you will comply with all
          applicable laws and this agreement. In particular, but without
          limitation, you agree not to: (a) use Our Studio in any unlawful
          manner or in a manner which promotes or encourages illegal activity;
          or (b) breach any law, statute, contract, or regulation; (c) act in a
          manner that is obscene, defamatory, libellous, unlawfully threatening
          or unlawfully harassing; (d) provide false, inaccurate or misleading
          information; (e) use an anonymising proxy; or (f) attempt to gain
          unauthorised access to Our Studio or any networks, servers or computer
          systems connected to Our Studio. (together the “Restricted
          Activities”) 7.2 Except as expressly set out in this agreement or as
          permitted by any local law, you undertake: (a) not to rent, lease,
          sub-license, loan, translate, merge, adapt, vary or modify Our Studio
          or your access to Our Studio; (b) not to engage in any of the
          Restricted Activities, nor control an account that is linked to any of
          the Restricted Activities; (c) not to make alterations to, or
          modifications of, the whole or any part of Our Studio nor permit Our
          Studio or any part of it to be combined with, or become incorporated
          in, any other programs or websites; (d) not to disassemble,
          de-compile, reverse engineer or create derivative works based on the
          whole or any part of Our Studio; (e) to include our copyright notice
          on all entire and partial copies of Our Studio in any form; or (f) not
          to provide, or otherwise make available, Our Studio in any form, in
          whole or in part (including, but not limited to, program listings,
          object and source program listings, object code and source code) to
          any person without prior written consent from us. 7.3 Notwithstanding
          clause 7.1 and 7.2, you agree to indemnify us in full and on demand
          from and against any loss, damage, costs or expenses which we suffer
          or incur directly or indirectly as a result of your use of Our Studio
          otherwise than in accordance with this agreement or any applicable
          laws. 7.4 You warrant to us that all the information you provide to us
          is true and accurate to the best of your knowledge.
          <br></br>
          8. INTELLECTUAL PROPERTY RIGHTS <br></br>
          8.1 We are the owner or the licensee of all intellectual property
          rights in Our Studio and in the material published on it. Those works
          are protected by copyright laws and treaties around the world. All
          such rights are reserved. 8.2 The trade marks, service marks, and
          logos (“Trade Marks”) contained on Our Studio are owned by us. Where
          they exist, we recognise the respect the rights of other IP holders
          displayed on our Studio. You cannot use, copy, edit, vary, reproduce,
          publish, display, distribute, store, transmit, commercially exploit or
          disseminate the Trade Marks without the prior written consent of us,
          or the relevant third party. 8.3 You must not modify the paper or
          digital copies of any materials you have printed off or downloaded in
          any way, and you must not use any illustrations, photographs, video or
          audio sequences or any graphics separately from any accompanying text.
          8.4 Our status (and that of any identified contributors) as the
          authors of content on Our Studio must always be acknowledged. 8.5 You
          must not use any part of the content on Our Studio for commercial
          purposes without obtaining a licence to do so from us or our
          licensors. 8.6 If you print off, copy or download any part of Our
          Studio in breach of this agreement, your right to use Our Studio will
          cease immediately and you must, at our option, return or destroy any
          copies of the materials you have made.
          <br></br>
          9. UPLOADING CONTENT TO OUR STUDIO<br></br>
          9.1 Whenever you make use of a feature that allows you to upload
          content to Our Studio, you must comply with the content standards set
          out below. 9.2 You warrant that any such contribution does comply with
          these standards, and you will be liable to us and indemnify us for any
          breach of this warranty. 9.3 Any content you upload to Our Studio in a
          public forum will be considered non-confidential and non-proprietary.
          To the extent it is necessary in order to complete your requests, you
          grant to us a perpetual, worldwide, non-exclusive, royalty free and
          fully paid licence to, without limitation, use, sub-licence, copy,
          repost, transmit or otherwise distribute, publicly display, publicly
          perform, adapt, prepare derivative works of, compile, make available
          and otherwise communicate to the public the content. 9.4 We also have
          the right to disclose your identity to any third party who is claiming
          that any content posted or uploaded by you to the Our Studio
          constitutes a violation of their intellectual property rights, or of
          their right to privacy. 9.5 We have the right to remove any posting
          you make on Our Studio if, in our opinion, your post does not comply
          with the content standards set out herein or with any applicable laws.
          9.6 You acknowledge and agree that Our Studio acts merely as a passive
          conduit and/or host for the uploading storage and distribution of such
          content. Any views expressed by other users on Our Studio do not
          represent our views or values. We cannot and do not review the content
          created or uploaded by users, and neither we nor our subsidiaries,
          affiliates, successors, assigns, employees, agents, directors,
          officers and shareholders has any obligation, and does not undertake
          or assume any duty, to monitor Our Studio for content that is
          inappropriate, that does not or might infringe any third party rights,
          or has otherwise been uploaded in breach of this agreement or
          applicable law. However, you can notify us of any inappropriate or
          infringing content by flagging such content on Our Studio. 9.7 You are
          solely responsible for all of the content that you upload, post or
          distribute to, on or through Our Studio, and to the extent permissible
          by law, we exclude all liability with respect to content and the
          activities of users.
          <br></br>
          10. DIGITAL MILLENNIUM COPYRIGHT ACT (DMCA) POLICY<br></br>
          We care about copyright. We reserve the right to take down public
          content published on the Our Studio or distributed through shared
          links should we find a breach of terms, as stated in our policy:
          https://web.cerebriam.com/dmca.
          <br></br>
          11. CHANGES TO THESE TERMS<br></br>
          We may revise this agreement (or any of the documents referred to in
          this agreement) at any time by amending this page. Please check this
          page from time to time to take notice of any changes we made, as they
          are binding on you.
          <br></br>
          12. DUPLICATE ACCOUNTS<br></br>
          We reserve the right to refuse the creation of duplicate accounts for
          the same user. In a case where duplicate accounts are detected, we
          reserve the right to close or merge these duplicate accounts without
          notification.
          <br></br>
          13. ELIGIBILITY<br></br>
          13.1 To be eligible for Our Studio, you must (a) be at least 16 years
          old and (c) agree to the terms of this agreement. 13.2 You further
          represent and warrant to us that if you are an individual and you open
          an account with us that you are not acting on behalf of an undisclosed
          principal or a third party beneficiary. 13.3 You undertake that your
          login may only be used by you and a login shared by multiple people is
          not permitted.
          <br></br>
          14. NO WARRANTY<br></br>
          14.1 Use of Our Studio is at your own risk. Our Studio is provided on
          an “as is” basis. We do not warrant or guarantee that Our Studio and
          all or part of its contents will be always available or that its use
          will not be interrupted. 14.2 You acknowledge that Our Studio may not
          be free of bugs or errors and you agree that the existence of any
          minor errors shall not constitute a breach of this agreement.
          <br></br>
          15. OUR LIABILITY<br></br>
          15.1 Nothing in this agreement excludes or limits our liability for
          death or personal injury arising from our negligence, or our fraud or
          fraudulent misrepresentation, or any other liability that cannot be
          excluded or limited by English law. 15.2 To the extent permitted by
          law, we exclude all conditions, warranties, representations or other
          terms which may apply to Our Studio or any content on it, whether
          express or implied. 15.3 Subject to clause 15.1, we will not be liable
          to any user for any loss or damage, whether in contract, tort
          (including negligence), breach of statutory duty, or otherwise, even
          if foreseeable, arising under or in connection with: (a) use of, or
          inability to use, Our Studio or the Services; or (b) use of or
          reliance on any content displayed on Our Studio. 15.4 We will not be
          liable for any loss or damage caused by a virus, distributed
          denial-of-service attack, or other technologically harmful material
          that may infect your computer equipment, computer programs, data or
          other proprietary material due to your use of Our Studio or to your
          downloading of any content on it, or on any website linked to it. 15.5
          We assume no responsibility for the content of websites linked to Our
          Studio. Such links should not be interpreted as endorsement by us of
          those linked websites. We will not be liable for any loss or damage
          that may arise from your use of them. 15.6 Subject to clause 15.1, our
          maximum aggregate liability under or in connection with this
          agreement, or any collateral contract, whether in contract, tort
          (including negligence) or otherwise, shall in all circumstances be
          limited to a sum equal to £100 or the amount you paid to us in fees in
          the previous 12 calendar months, whichever is greater.
          <br></br>
          16. TERMINATION<br></br>
          16.1 Either party may terminate this agreement immediately and without
          notice to the other party. You may terminate this agreement by
          deleting your Account and refraining to use Our Studio; however you
          shall still be liable for the Fees owed as detailed in clauses 3 and
          4. 16.2 Upon termination or expiry for any reason: (a) all rights
          granted to you under this agreement shall cease; (b) you must cease
          all activities authorised by this agreement; and (c) you must
          immediately delete your Account and cease using Our Studio and certify
          to us that you have done so. 16.3 Please note once you have deleted
          your Account we shall not be able to recover your files so please
          download all audio/visual files and documents before you delete your
          Account. 16.4 We reserve the right to delete your Account on
          termination of this agreement and for non-payment of the Fees owed in
          accordance with clauses 3 and 4 of this agreement. 16.5 Any provision
          of this agreement that expressly or by implication is intended to come
          into or continue in force on or after termination or expiry of this
          agreement shall remain in full force and effect.
          <br></br>
          17. TRANSFER OF RIGHTS AND OBLIGATIONS<br></br>
          17.1 This agreement is binding on you and us and on our respective
          successors and assignees. 17.2 You may not transfer, assign, charge or
          otherwise dispose of this agreement, or any of your rights or
          obligations arising under it, without our prior written consent. 17.3
          We may assign, charge, novate or otherwise dispose of this agreement.
          If this occurs you will be informed in writing by email.
          <br></br>
          18. GENERAL TERMS<br></br>
          Communications 18.1 We are required to provide certain information to
          you in writing. By accepting this agreement, you agree that we can
          communicate with you electronically either by email or by posting
          notices on Our Studio. Entire Agreement 18.2 This agreement and any
          document expressly referred to in it constitutes the whole agreement
          between us and supersedes any previous discussions, correspondence,
          arrangements or understandings between us. Law and Jurisdiction 18.3
          This agreement and any dispute or claim arising out of or in
          connection with it or its subject matter or formation (including
          non-contractual disputes or claims) shall be governed by and construed
          in accordance with the law of England and Wales. 18.4 Any dispute or
          claim arising out of or in connection with this agreement will be
          subject to the exclusive jurisdiction of the courts of England and
          Wales.
          <br></br>
          19. CONTACT US<br></br>
          To contact us, please email support [at] cerebriam.co.uk<br></br>
          <br></br>
          For Our Privacy Policy, please consult:
          https://web.cerebriam.com/privacy-policy/
        </p>
      </>
    </AppModal>
  )
}
