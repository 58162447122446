import React from 'react'
import _ from 'lodash'
import AudioDuckTimelineItem from './AudioDuckTimelineItem'
import * as EditorActions from '../../redux/actions/EditorActions'
import { connect } from 'react-redux'

class AudioDuckChannel extends React.Component {
  get items() {
    const { channel, project } = this.props
    return _.get(project, channel.projectPath, [])
  }

  renderItem(item, itemIndex) {
    const { channel, storeProjectSteps } = this.props
    return (
      <AudioDuckTimelineItem
        item={item}
        itemIndex={itemIndex}
        channel={channel}
        storeProjectSteps={storeProjectSteps}
      />
    )
  }

  render() {
    const { channel } = this.props
    return (
      <div
        className="flex relative items-center channel-items-box my-1"
        style={{ minWidth: '80vw', backgroundColor: channel.backgroundColor }}>
        {_.map(this.items, (item, itemIndex) =>
          this.renderItem(item, itemIndex),
        )}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
  updateProject: (...any) => dispatch(EditorActions.updateProject(...any)),
  storeProjectSteps: (...any) =>
    dispatch(EditorActions.storeProjectSteps(...any)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AudioDuckChannel)
