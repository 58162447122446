import Spinner from '../../common/Spinner'
import React from 'react'
import ReactPlayer from 'react-player'
import spinner from '../../../assets/images/spinner_without_bg.png'
import ReplayWrapper from '../camera-wrapper/ReplayWrapper'

class CameraWrapper extends React.Component {
  handleAttachCameraVideo = (cameraVideo) => {
    this.props.cameraVideo && this.props.cameraVideo(cameraVideo)
  }

  render() {
    const { isUploadingVideo, isUploadedVideo } = this.props
    const {
      isCameraOn,
      isConnecting,
      isReplayingVideo,
      isVideoFaceTracking,
      zoomAble,
      scaleVal,
      videoUrl,
      faceTrackingVideo,
      faceTrackingRatio,
      defaultLayout,
      saveLayout,
      aspectSize,
      clientRectSize,
    } = this.props
    let faceTrackingVideoFileRatio
    let selectedLayout = defaultLayout
    // correcting the defaultLayout if a user has selected layout that doesn't have a cropped video
    if (saveLayout && aspectSize) {
      if (
        !saveLayout[defaultLayout] ||
        aspectSize[defaultLayout].width == 0 ||
        aspectSize[defaultLayout].height == 0
      ) {
        selectedLayout = 0
        while (
          !saveLayout[selectedLayout] ||
          (aspectSize[selectedLayout].width == 0 &&
            aspectSize[selectedLayout].height == 0)
        ) {
          selectedLayout++
        }
        if (selectedLayout > 3) {
          selectedLayout = 0
        }
      }
    }

    if (isUploadingVideo) {
      return this.renderUploading()
    }

    if (isVideoFaceTracking) {
      return this.renderFaceTracking()
    }

    if (faceTrackingVideo) {
      const parsedUrl = new URL(faceTrackingVideo)
      const faceTrackingVideoKey = parsedUrl.pathname.substring(1) // get the key (relative path in the S3 to the file) from the url
      const path = faceTrackingVideoKey.split('/') // separate folder names and file name
      const faceTrackingVideoFilename = path[path.length - 1] // get the file name from the url (last part after last slash '/')
      let fileNameParts = faceTrackingVideoFilename.split('.') // if the file name contains dots, we will keep them and remove only last part - extension
      const faceTrackingVideoFilenameExtension = fileNameParts.pop() // remove the last element of the array (extension), .pop() works even if it doesn't return anything
      const faceTrackingVideoFilenameWithoutExt = fileNameParts.join('.') // join the parts of the name again
      faceTrackingVideoFileRatio = faceTrackingVideo.replace(
        faceTrackingVideoFilename,
        faceTrackingVideoFilenameWithoutExt + '_' + selectedLayout + '.mp4',
      )
      console.log('faceTrackingVideoFileRatio:' + faceTrackingVideoFileRatio)
    }

    if (isReplayingVideo && !isUploadingVideo) {
      return (
        <div
          onClick={() => alert('is Clicked')}
          className="overflow-hidden relative w-full h-full flex justify-center items-center"
          key="replay-wrapper">
          <div className="flex-1">
            <div>Original</div>
            <ReplayWrapper
              src={videoUrl}
              aspectSizes={aspectSize}
              rectangleSizes={clientRectSize}
              selectedLayout={selectedLayout}
              shootValue={this.props.shoot_value}
              {...this.props}
            />
          </div>
          {this.props.faceTrackingVideo && (
            <div className="flex-1">
              <div>Face-tracked Video</div>
              <ReactPlayer
                className="react-player fixed-bottom"
                // url={this.props.faceTrackingVideo}
                url={faceTrackingVideoFileRatio}
                muted
                width="100%"
                playing={true}
                loop={true}
              />
            </div>
          )}
        </div>
      )
    }

    if (!isReplayingVideo && !isUploadingVideo && isUploadedVideo) {
      return (
        <div className="flex justify-center items-center w-full h-full bg-black text-white">
          Successfully Uploaded!
        </div>
      )
    }

    if (isCameraOn) {
      return (
        <div
          className="w-full h-full flex justify-center items-center"
          key="camera">
          <video
            className="absolute w-full h-full cursor-pointer"
            ref={this.handleAttachCameraVideo}
            autoPlay
            playsInline
            muted="muted"
            onLoadedMetadata={this.props.loadedCameraVideo}
            style={{
              transform: !zoomAble
                ? scaleVal === 'close'
                  ? 'scaleX(2) scaleY(2)'
                  : 'scaleX(1) scaleY(1)'
                : 'scaleX(1) scaleY(1)',
              objectFit: !zoomAble
                ? scaleVal === 'long'
                  ? 'scale-down'
                  : 'contain'
                : 'contain',
              transformOrigin: 'center',
            }}
          />
        </div>
      )
    }

    if (isConnecting) {
      return <Spinner />
    }

    return (
      <div className="absolute left-0 top-0 w-full h-full flex justify-center items-center">
        Your Webcam is already used by another app or your browser unsupported
        Webcam. Please check and reload app.
      </div>
    )
  }

  renderUploading() {
    return (
      <div className="flex justify-center items-center w-full h-full bg-black text-white loadingApp">
        <img className="cerebriam-spinner" src={spinner} alt="Loading..." />
      </div>
    )
  }

  renderFaceTracking() {
    return (
      <div className="flex justify-center items-center w-full h-full bg-black text-white loadingApp">
        <p>Rendering video for face tracking</p>
        <p>
          <img className="cerebriam-spinner" src={spinner} alt="Loading..." />
        </p>
      </div>
    )
  }
}

export default CameraWrapper
