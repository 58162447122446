import _ from 'lodash'
import { ONE_SECOND, DEFAULT_ZEROWIDTH } from '../constants/Timeline'

const getTextItemsFromState = (stateTextItems) => {
  return _.cloneDeep(stateTextItems)
}

function updateCurrentTextItem(
  currentTextItem,
  width,
  endTime,
  textItemDuration,
) {
  currentTextItem.updated = true
  currentTextItem.sliderW = width
  currentTextItem.end_time = endTime
  currentTextItem.clip = {
    start: currentTextItem.clip.start,
    end: currentTextItem.clip.end,
    duration: textItemDuration,
  }
}

function reupdateNextTextItems(
  currentTextItemIndex,
  textItems,
  durationAdded,
  widthAdded,
) {
  for (
    let nextItem = currentTextItemIndex + 1;
    nextItem < textItems.length;
    nextItem++
  ) {
    textItems[nextItem].start_time += durationAdded
    textItems[nextItem].end_time += durationAdded
    textItems[nextItem].sliderL += widthAdded
    textItems[nextItem].updated = true
  }
}

function getOldCurrentTextItemWidth(currentTextItem, secondsWidth, floatRate) {
  return (
    Math.round(
      (currentTextItem.clip.duration / ONE_SECOND) * secondsWidth * floatRate,
    ) / floatRate
  )
}

function getTextItemEndTime(currentTextItem, textItemDuration) {
  return currentTextItem.start_time + textItemDuration
}

function getTextItemDuration(width, secondsWidth, floatRate) {
  return Math.round((width / secondsWidth) * ONE_SECOND * floatRate) / floatRate
}

export function updateTextItemWidths(
  stateTextItems = [],
  currentTextItemIndex,
  width,
  secondsWidth,
  floatRate,
) {
  if (width <= DEFAULT_ZEROWIDTH) {
    width = DEFAULT_ZEROWIDTH
  }

  const textItems = getTextItemsFromState(stateTextItems)
  const currentTextItem = textItems[currentTextItemIndex]
  let textItemDuration = getTextItemDuration(width, secondsWidth, floatRate)
  let endTime = getTextItemEndTime(currentTextItem, textItemDuration)

  const durationAdded = textItemDuration - currentTextItem.clip.duration
  const oldWidth = getOldCurrentTextItemWidth(
    currentTextItem,
    secondsWidth,
    floatRate,
  )
  const widthAdded = width - oldWidth

  if (currentTextItemIndex < textItems.length - 1) {
    reupdateNextTextItems(
      currentTextItemIndex,
      textItems,
      durationAdded,
      widthAdded,
    )
  }

  updateCurrentTextItem(currentTextItem, width, endTime, textItemDuration)
  return textItems
}

export const shiftTextItems = (input) => {
  return input
}

export const isTextItemBetweenOtherTextItems = (textItems, i, newStartTime) => {
  const otherTextItems = _.reject(textItems, (val, index) => index === i)
  return _.some(otherTextItems, (textItem) => {
    return (
      textItem.start_time < newStartTime && newStartTime < textItem.end_time
    )
  })
}
