import { Component } from 'react'
import { connect } from 'react-redux'
import EditorSidePanel from './EditorSidePanel'
import {
  moveTimelineByCurrentTime,
  toggleShowAddItem,
} from '../../methods/editor/MainTimelineMethods'
import {
  deleteSelectedItems,
  getSelectedItemByChannel,
} from '../../methods/editor/SceneCutDelMethods'
import {
  checkAddItem,
  storeProjectSteps,
  updateProject,
} from '../../redux/actions/EditorActions'
import * as MainContainerActions from '../../redux/actions/MainContainerActions'
import * as SelectedItemActions from '../../redux/actions/SelectedItemActions'
import MainContainer from './desktop-items/MainContainer'

class EditorMain extends Component {
  constructor(props) {
    super(props)

    this.toggleShowAddItem = toggleShowAddItem.bind(this)
    this.moveTimelineByCurrentTime = moveTimelineByCurrentTime.bind(this)
    this.deleteSelectedItems = deleteSelectedItems.bind(this)
    this.getSelectedItemByChannel = getSelectedItemByChannel.bind(this)
    this.getSelectedItemByChannel = getSelectedItemByChannel.bind(this)
  }

  render() {
    const {
      libraryFiles,
      toggleShowImport,
      getLibFiles,
      id,
      height,
      scenePlaying,
      togglePlay,
      updateSlideV,
      showTextPanel,
      template,
      updateTimeSlider,
      toggleShowTextPanel,
      sceneVideos,
    } = this.props
    return (
      <div className="editor-main">
        <EditorSidePanel
          libraryFiles={libraryFiles}
          toggleShowImport={(channelCode) => {
            return this.toggleShowAddItem(channelCode)
          }}
          getLibFiles={getLibFiles}
          deleteSelectedItems={this.deleteSelectedItems}
          showMedia={this.props.showSidebarMedia}
          updateShowSidebarMedia={this.props.updateShowSidebarMedia}
          handleDisplayAudioDuckModal={this.props.handleDisplayAudioDuckModal}
        />
        {/*Main container*/}
        <MainContainer
          id={id}
          height={height}
          scenePlaying={scenePlaying}
          togglePlay={togglePlay}
          updateSlideV={updateSlideV}
          showTextPanel={showTextPanel}
          template={template}
          updateTimeSlider={updateTimeSlider}
          toggleShowTextPanel={toggleShowTextPanel}
          sceneVideos={sceneVideos}
          showMedia={this.props.showSidebarMedia}
          updateShowSidebarMedia={this.props.updateShowSidebarMedia}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  project: state.editor.project,
  checkedChannels: state.selectedItems.checkedChannels,
})

const mapDispatchToProps = (dispatch) => ({
  checkAddItem: (...any) => dispatch(checkAddItem(...any)),
  toggleVoiceoverModal: (...any) =>
    dispatch(MainContainerActions.toggleVoiceoverModal(...any)),
  storeProjectSteps: (...any) => dispatch(storeProjectSteps(...any)),
  updateProject: (...any) => dispatch(updateProject(...any)),
  setSelectedText: (...any) =>
    dispatch(SelectedItemActions.setSelectedText(...any)),
  setSelectedTextIndex: (...any) =>
    dispatch(SelectedItemActions.setSelectedTextIndex(...any)),
  openEditTextModal: (...any) =>
    dispatch(MainContainerActions.openEditTextModal(...any)),
})
export default connect(mapStateToProps, mapDispatchToProps)(EditorMain)
