import MailchimpFormContainer from './MailchimpFormContainer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import {
  faFacebookF,
  faTwitter,
  faYoutube,
  faLinkedinIn,
  faInstagram,
} from '@fortawesome/free-brands-svg-icons'
import './footer.css'

export const Footer = () => {
  return (
    <footer
      id="colophon"
      className="site-footer cerebriam-footer"
      role="contentinfo"
      itemScope="itemscope"
      itemType="http://schema.org/WPFooter">
      <div className="content">
        <div className="cerebriam-footer__top">
          <div className="container-fluid">
            <div className="cerebriam-row">
              <div className="cerebriam-column">
                <h6>VERTICAL VIDEOS | SQUARE VIDEOS | HORIZONTAL VIDEOS</h6>
                <p>Simultaneously Create Multiple Aspect Ratio Videos</p>
                <ul>
                  <li>
                    <a
                      target="_blank"
                      href="https://web.cerebriam.com/2020/08/30/increase-engagement-with-vertical-video/"
                      rel="noreferrer">
                      <FontAwesomeIcon icon={faChevronRight} />
                      Increase Engagement with Vertical Video
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="https://web.cerebriam.com/2020/08/30/multi-aspect-ratio-matio-video-production/"
                      rel="noreferrer">
                      <FontAwesomeIcon icon={faChevronRight} />
                      Multi-Aspect Ratio (m:atio) Video Production
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="https://web.cerebriam.com/2020/05/28/video-editing-tips-to-boost-your-engagement/"
                      rel="noreferrer">
                      <FontAwesomeIcon icon={faChevronRight} />
                      Video Editing Tips to Boost Your Engagement
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="https://web.cerebriam.com/2020/10/31/our-infographic-guide-to-aspect-ratios/"
                      rel="noreferrer">
                      <FontAwesomeIcon icon={faChevronRight} />
                      Why Vertical Video is King : Aspect Ratio Infographic
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="https://web.cerebriam.com/2020/10/31/our-infographic-guide-to-aspect-ratios/"
                      rel="noreferrer">
                      <FontAwesomeIcon icon={faChevronRight} />
                      Best Socials for Semi-vertical and Square Videos
                    </a>
                  </li>
                </ul>
              </div>
              <div className="cerebriam-column">
                <h6>DELIVERING THE PERFECT PITCH</h6>
                <p>Present Confidently on Camera</p>
                <ul>
                  <li>
                    <a
                      target="_blank"
                      href="https://web.cerebriam.com/2020/06/05/body-language-impactful-video/"
                      rel="noreferrer">
                      <FontAwesomeIcon icon={faChevronRight} />
                      Using Body Language to Make an Impactful Video
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="https://web.cerebriam.com/2020/06/05/vocal-variety-to-make-engaging-vidoes/"
                      rel="noreferrer">
                      <FontAwesomeIcon icon={faChevronRight} />
                      Using Vocal Variety to Make Engaging Videos
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="https://web.cerebriam.com/2020/06/03/pitching-on-camera-intentions/"
                      rel="noreferrer">
                      <FontAwesomeIcon icon={faChevronRight} />
                      Pitching on Camera – What are Your Intentions?
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="https://web.cerebriam.com/2020/06/03/presenting-on-camera-audience-context/"
                      rel="noreferrer">
                      <FontAwesomeIcon icon={faChevronRight} />
                      Presenting on Camera – Audience & Context
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="https://web.cerebriam.com/2020/06/22/how-to-present-on-camera/"
                      rel="noreferrer">
                      <FontAwesomeIcon icon={faChevronRight} />
                      Your Guide on Preparing to Present on Camera
                    </a>
                  </li>
                </ul>
              </div>
              <div className="cerebriam-column">
                <h6>OPTIMISE FOR EVERY SOCIAL PLATFORM</h6>
                <p>Grow All Your Social Channels</p>
                <ul>
                  <li>
                    <a
                      target="_blank"
                      href="https://web.cerebriam.com/2020/10/09/how-to-get-the-best-out-of-your-youtube-video-editor/"
                      rel="noreferrer">
                      <FontAwesomeIcon icon={faChevronRight} />
                      How to Get the Best Out of the YouTube Video Editor
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="https://web.cerebriam.com/2020/11/19/how-to-create-video-for-tiktok/"
                      rel="noreferrer">
                      <FontAwesomeIcon icon={faChevronRight} />
                      How to Create Video for TikTok
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="https://web.cerebriam.com/2022/04/20/how-to-post-videos-on-instagram-to-increase-reach/"
                      rel="noreferrer">
                      <FontAwesomeIcon icon={faChevronRight} />
                      How to Post Videos on Instagram to Increase Reach
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="https://web.cerebriam.com/2022/04/20/how-to-create-video-for-facebook/"
                      rel="noreferrer">
                      <FontAwesomeIcon icon={faChevronRight} />
                      How to Create Video for Facebook
                    </a>
                  </li>
                </ul>
              </div>
              <div className="cerebriam-column">
                <h6>NEWSLETTER SIGN UP</h6>
                <div
                  id="footer-sidebar-first"
                  className="footer-sidebar-first widget-area"
                  role="complementary">
                  <MailchimpFormContainer />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="cerebriam-footer__middle"></div>

        <div className="cerebriam-footer__bottom">
          <div className="container-fluid">
            <div className="cerebriam-row">
              <div className="cerebriam-column">
                <ul className="cerebriam_social_links">
                  <li>
                    <a
                      href="https://twitter.com/cerebriam"
                      target="_blank"
                      rel="noreferrer">
                      <FontAwesomeIcon icon={faTwitter} />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.facebook.com/cerebriam/"
                      target="_blank"
                      rel="noreferrer">
                      <FontAwesomeIcon icon={faFacebookF} />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/cerebriam/"
                      target="_blank"
                      rel="noreferrer">
                      <FontAwesomeIcon icon={faInstagram} />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/company/cerebriam"
                      target="_blank"
                      rel="noreferrer">
                      <FontAwesomeIcon icon={faLinkedinIn} />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.youtube.com/user/Cerebriam"
                      target="_blank"
                      rel="noreferrer">
                      <FontAwesomeIcon icon={faYoutube} />
                    </a>
                  </li>
                </ul>
              </div>
              <div className="cerebriam-column">
                <ul>
                  <li>
                    <a
                      target="_blank"
                      href="https://web.cerebriam.com/terms-conditions/"
                      rel="noreferrer">
                      Ts & Cs
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="https://web.cerebriam.com/privacy-policy/"
                      rel="noreferrer">
                      Privacy
                    </a>
                  </li>
                </ul>
              </div>
              <div className="cerebriam-column">
                <ul>
                  <li>
                    <a
                      target="_blank"
                      href="https://web.cerebriam.com/cookies/"
                      rel="noreferrer">
                      Cookies
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="https://web.cerebriam.com/dmca/"
                      rel="noreferrer">
                      DMCA
                    </a>
                  </li>
                </ul>
              </div>
              <div className="cerebriam-column">
                <div className="cerebriam-copyright">
                  © Copyright 2022 Cerebriam Studio
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}
