import _ from 'lodash'
import FileUtils from '../../../utils/FileUtils'
import DurationGetter from '../../time/DurationGetter'
import TimelineService from '../../time/TimelineService'
import ProjectItemsDecorator from './../ProjectItemsDecorator'

class AudioItemCutter {
  cut(project, selectedItem, currentTime, projectPath, sceneId) {
    const channelItems = _.get(project, projectPath)
    const isCurrenTimeBeyondEndtime = selectedItem?.end_time < currentTime

    if (!selectedItem || _.isEmpty(channelItems) || isCurrenTimeBeyondEndtime) {
      return project
    }

    const clippedDuration = currentTime - selectedItem.start_time
    const nextClippedDuration = selectedItem.end_time - currentTime

    const clippedStartTime = selectedItem.start_time
    const nextClippedStartTime = selectedItem.start_time + clippedDuration

    const clippedEndTime = selectedItem.start_time + clippedDuration
    const nextClippedEndTime = selectedItem.end_time

    const clippedStart = selectedItem.clip.start
    const nextClippedStart = selectedItem.clip.start + clippedDuration

    const clippedEnd = selectedItem.clip.start + clippedDuration
    const nextClippedEnd =
      selectedItem.clip.start + clippedDuration + nextClippedDuration

    const updatedSelectedItem = this.createRepositionedItem(
      selectedItem,
      clippedDuration,
      clippedStartTime,
      clippedEndTime,
      clippedStart,
      clippedEnd,
      selectedItem.volumeData,
    )
    var nextItemId=''
    if (sceneId) {
      nextItemId = `${sceneId}_sound`
    } else {
      nextItemId = FileUtils.generateTimestampItemId()
    }
    const nextItem = this.createRepositionedItem(
      {
        ...selectedItem,
        id: nextItemId,
      },
      nextClippedDuration,
      nextClippedStartTime,
      nextClippedEndTime,
      nextClippedStart,
      nextClippedEnd,
      [],
    )

    const updatedProject = _.cloneDeep(project)
    const items = _.reject(
      updatedProject[projectPath] || [],
      (item) => item.id === selectedItem.id,
    )
    updatedProject[projectPath] = ProjectItemsDecorator.reorderItemsByStartTime(
      [...items, updatedSelectedItem, nextItem],
    )
    updatedProject.duration = DurationGetter.getDurationFromProject(project)
    return updatedProject
  }

  createRepositionedItem(
    item,
    duration,
    startTime,
    endTime,
    clipStart,
    clipEnd,
    volumeData,
  ) {
    const clonedItem = _.cloneDeep(item)
    clonedItem.clip.start = clipStart
    clonedItem.clip.duration = duration
    clonedItem.duration = duration
    clonedItem.clip.end = clipEnd
    clonedItem.start_time = startTime
    clonedItem.end_time = endTime
    clonedItem.sliderW = TimelineService.convertMillisecondsToPixels(duration)
    clonedItem.sliderL = TimelineService.convertMillisecondsToPixels(startTime)
    let lastInsertedIndex = null
    clonedItem.volumeData = []
    volumeData?.map((vol, i) => {
      if (vol.time + startTime <= endTime) {
        clonedItem.volumeData.push(vol)
        lastInsertedIndex = i
      }
    })

    if (lastInsertedIndex && lastInsertedIndex < volumeData?.length - 1)
      clonedItem.volumeData.push(volumeData[lastInsertedIndex + 1])

    return clonedItem
  }
}

export default new AudioItemCutter()
