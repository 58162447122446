import React from 'react'
import { ChromePicker } from 'react-color'
import './ColorPicker.scss'
class ColorPicker extends React.Component {
  render() {
    const popover = {
      position: 'absolute',
      zIndex: '2',
    }

    const cover = {
      position: 'fixed',
      top: '0px',
      right: '0px',
      bottom: '0px',
      left: '0px',
    }

    return (
      <div style={{ marginRight: 0, marginTop: 42 }}>
        {this.props.displayColorPicker ? (
          <div style={popover}>
            <div style={cover} onClick={this.props.handleClose} />

            <ChromePicker
              color={this.props.color}
              onChange={this.props.onChange}
              onChangeComplete={this.props.onChangeComplete}
            />
          </div>
        ) : null}
      </div>
    )
  }
}

export default ColorPicker
