import _ from 'lodash'

class LibraryFilesUtils {
  getResponseAudioFiles(responseAudioFiles) {
    return responseAudioFiles.map((audioElement) => {
      if (!audioElement.duration || audioElement.duration === 0) {
        let audio = new Audio()
        audio.src = audioElement.fileUrl
        audio.onloadedmetadata = () => {
          audioElement.duration = audio.duration
        }
      }

      return audioElement
    })
  }

  getSortedListByNearestUploadDate(files) {
    const orderedList = _.orderBy(files, ['date'], ['desc'])
    return orderedList
  }
}

export default new LibraryFilesUtils()
