import _ from 'lodash'
import * as VideoUploadService from '../../services/uploads/VideoUploadService'
import * as UploadServices from '../../services/UploadServices'
import SceneItemAdder from '../../services/project-items/SceneItemAdder'
import { toast } from 'react-toastify'
import DurationGetter from '../../services/time/DurationGetter'
import { ASPECT_RATIO_DETAILS } from '../../constants/AspectRatios'
import * as FileUtils from '../../utils/FileUtils'
import { ASSET_LIBRARY } from '../../constants/AssetLibraries'
import { DEFAULT_PROJECT } from '../../constants/TimelineItems'
import ProjectEditService from '../../services/ProjectEditService'
import { VIDEO } from '../../constants/Channels'
import { updateTransitions } from '../editor/services'

export function loadedReplayVideo() {
  this.setState({
    isLoadedReplayVideo: true,
  })
}

export function closeNotifyModal() {
  this.setState({
    showTapNotify: false,
  })
}

export function toggleSnapGrid(r) {
  this.setState({
    showSnap: r,
  })
}

/**
 * track scenes
 */
export async function trackScenes(data, sessionId) {
  const response = await UploadServices.trackScenes({
    data,
    sessionId,
  })
  return response
}

/**
 * cropped video data update
 */
export async function uploadCropVideo(
  uploadVideoId,
  layoutProperties,
  isTrackingVideo,
  didTrackingVideo,
  partOfSegmentations = false,
) {
  const {
    aspectSize,
    aspectSizeIndices,
    defaultLayout,
    saveLayout,
    streamSize,
    videoCropTime,
  } = layoutProperties
  const someSavedLayoutEnabled =
    VideoUploadService.isSomeLayoutEnabled(saveLayout)

  if (uploadVideoId === '') {
    throw new Error('Video Upload ID is empty')
  }
  if (!someSavedLayoutEnabled) {
    throw new Error('Please tick at least one resolution layout')
  }

  this.setState({ uploading: true })

  const response = await UploadServices.libraryCropData({
    videoCropTime,
    aspectSize,
    aspectSizeIndices,
    isTrackingVideo,
    didTrackingVideo,
    libId: uploadVideoId,
    saveLayout,
    selectedRatio: defaultLayout,
    streamSize,
    partOfSegmentations,
  })
  const libraryData = _.get(response, 'data.reData', null)
  return libraryData
}

let intervalId
// Check if all assets are ready before mounting the editor page
async function checkIfAssetReady(
  project,
  isLastVideo,
  endUploadLoading,
  props,
) {
  return Promise.all([
    ...project.scenes
      .filter(
        (item) => !item[item.length - 1]?.thumbUrl.includes('/blanks/blank'),
      )
      .map((scene, index) => {
        const assetUrl = scene[scene.length - 1]?.assetUrl
        return fetch(assetUrl)
      }),
  ])
    .then((responses) => {
      const errorResource = responses.find((response) => !response.ok)
      if (errorResource) {
        throw new Error(errorResource.statusText)
      }
      return Promise.all(responses.map((res) => res.blob()))
    })
    .then(() => {
      // Clear interval when all resources are available and move to the editor page
      clearInterval(intervalId)
      // endUploadLoading()
      // if (isLastVideo) {
      endUploadLoading()
      props.history.push(`/editor/${project?.id || 0}`)
      // }
    })
    .catch((error) => {
      return error
    })
}

export async function updateProjectFromCroppedVideo(
  croppedVideoLibraryData,
  aspectSizeIndex = null,
  segmentations = false,
) {
  const {
    updateProject,
    defaultLayout,
    authUser,
    resolution,
    availGridSizes,
    project,
  } = this.props
  let projectData = project || DEFAULT_PROJECT
  for (var i = 0; i < croppedVideoLibraryData.length; i++) {
    const croppedData = croppedVideoLibraryData[i]
    const saveLayout = croppedData.selectedLayout

    const someSavedLayoutEnabled =
      VideoUploadService.isSomeLayoutEnabled(saveLayout)

    let sceneItem = SceneItemAdder.createSceneItemFromLibrary(
      croppedData,
      ASSET_LIBRARY.MEDIA.assetType,
      authUser.id,
      defaultLayout,
      someSavedLayoutEnabled,
      saveLayout,
      resolution,
    )

    const durationInMilliseconds = DurationGetter.getMaxDurationByChannel(
      projectData,
      VIDEO.code,
    )

    projectData = ProjectEditService.addItemToProject(
      projectData,
      sceneItem,
      VIDEO.code,
      durationInMilliseconds,
      {
        gridSizes: availGridSizes,
        resolution: resolution,
        selectedLayout: saveLayout,
        aspectSize: croppedData.aspectSize,
        streamSize: croppedData.streamSize,
      },
    )
  }

  const newProjectData = updateTransitions(projectData)

  updateProject && updateProject(newProjectData, false)

  const endUploadLoading = () => {
    this.setState({ uploading: false })
  }

  if (segmentations) {
    endUploadLoading.bind(this)
    this.props.history.push(`/editor/${project?.id || 0}`)
  } else {
    // Check for cropped resources every 5 seconds and terminate when all resources are available
    intervalId = setInterval(() => {
      checkIfAssetReady(
        newProjectData,
        true,
        endUploadLoading.bind(this),
        this.props,
      )
    }, 5000)
  }

  // if (isLastVideo) this.props.history.push(`/editor/${project?.id || 0}`)

  const isAspectRatioSupported =
    croppedVideoLibraryData[0].aspectSize[newProjectData.resolution].width >
      0 &&
    croppedVideoLibraryData[0].aspectSize[newProjectData.resolution].height > 0
  if (!isAspectRatioSupported) {
    let aspectRatio = 1
    if (
      croppedVideoLibraryData[0].aspectSize[0].width > 0 &&
      croppedVideoLibraryData[0].aspectSize[0].height > 0
    )
      aspectRatio = 0
    else if (
      croppedVideoLibraryData[0].aspectSize[3].width > 0 &&
      croppedVideoLibraryData[0].aspectSize[3].height > 0
    )
      aspectRatio = 3
    else if (
      croppedVideoLibraryData[0].aspectSize[2].width > 0 &&
      croppedVideoLibraryData[0].aspectSize[2].height > 0
    )
      aspectRatio = 2
    this.changeDefaultResolution(newProjectData, aspectRatio)
    // window['resetAllSelected']()
    // window['changeAspectRatio'](newProjectData, aspectRatio)
  }
}

export async function uploadCropVideoToFaceTrack(
  videoUrl,
  defaultLayout,
  scaleValue,
  direction,
  aspectSizes,
  streamSize,
  videoCropTime,
) {
  try {
    const someSavedLayoutEnabled = VideoUploadService.isSomeLayoutEnabled(
      this.props.saveLayout,
    )

    if (!someSavedLayoutEnabled) {
      throw new Error('Please tick at least one resolution layout')
    }

    const { authUser } = this.props
    this.setState({ uploading: true })
    const videoFilename = FileUtils.getFilename(videoUrl)
    const selectedAspectSize = aspectSizes[defaultLayout]
    const selectedStreamSize = streamSize[defaultLayout]
    const selectedAspectRatio = ASPECT_RATIO_DETAILS[defaultLayout].aspectRatio
    /**
     * Change from sending a blob for tracking to sending a file URL
     *
     *   const videoBlob = await VideoUploadService.getVideoBlobByUrl(
     *     videoUrl,
     *     videoFilename,
     *   )
     */
    const { urls: faceTrackingVideoUrls, audioUrl: faceTrackingAudio } =
      await VideoUploadService.uploadFaceTrackingVideo(
        videoFilename,
        // videoBlob,
        videoUrl,
        // videoBlob.type,
        scaleValue,
        direction,
        selectedAspectSize,
        selectedAspectRatio,
        selectedStreamSize,
        aspectSizes,
        videoCropTime,
        authUser.id,
      )
    const faceTrackingVideoBlobs = await VideoUploadService.getVideoBlobsByUrl(
      faceTrackingVideoUrls,
      null, // TODO: Remove after python changes made
    )
    this.props.setFaceTrackingVideoBlobs(faceTrackingVideoBlobs)
    this.props.setFaceTrackingVideos(faceTrackingVideoUrls)
    this.props.setFaceTrackingVideo(videoUrl) // TODO: Remove after python changes made
    this.props.setFaceTrackingAudio(faceTrackingAudio)
  } catch (error) {
    const errorMessage = _.get(error, 'response.data.message', error.message)
    toast.error(`There's an error while tracking your video: ${errorMessage}`)
  } finally {
    this.setState({ uploading: false })
  }
}
