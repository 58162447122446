import React, { Component } from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import {
  SetPreviewTimeline,
  SetTimeSlider,
  toggleWatchProject,
  updateProject,
} from '../../redux/actions/EditorActions'
import instance from '../../utils/Axios'
import {
  completePreview,
  getProjectWidth,
  handleProgressChange,
  pauseTimeline,
  playAudio,
  playSound,
  playVideo,
  playVoiceover,
  SetPreviewStage,
  setSceneTimeline,
  toggleShowStage,
  updateProjectReview,
  updateSlider,
} from '../../methods/editor/MainContainerMethods'
import { changeRatio } from '../../methods/editor/SceneCutDelMethods'
import * as MainContainerActions from '../../redux/actions/MainContainerActions'
import * as MediaPlayerActions from '../../redux/actions/MediaPlayerActions'
import * as VideoPublishActions from '../../redux/actions/VideoPublishActions'
import * as SelectedItemActions from '../../redux/actions/SelectedItemActions'
import * as SubscriptionActions from '../../redux/actions/SubscriptionActions'
import {
  Modal,
  ModalCloseButton,
  ModalBody,
  ModalContent,
  ModalOverlay,
} from '@chakra-ui/modal'
import { Button, useBreakpointValue } from '@chakra-ui/react'
import useWindowDimensions from '../../hooks/useWindowDimensions'
import { Tooltip } from '@chakra-ui/react'
import VideoFormatListGetter from './VideoFormatListGetter'
import { toast } from 'react-toastify'
import { withRouter } from 'react-router'
import {
  SubscriptionManager,
  alertSubscriptionMessage,
  triggerGoogleAnalyticsEvent,
} from '../../utils/Helper'
import { SUBSCRIBE } from '../../constants/Routes'
import { ReviewFacebookIcon } from '../video-editor/icons/ReviewFacebookIcon'
import { ReviewYoutubeIcon } from '../video-editor/icons/ReviewYoutubeIcon'
import { ReviewTwitterIcon } from '../video-editor/icons/ReviewTwitterIcon'
import { ReviewTwitchIcon } from '../video-editor/icons/ReviewTwitchIcon'
import { ReviewTiktokIcon } from '../video-editor/icons/ReviewTiktokIcon'
import { ReviewLinkedinIcon } from '../video-editor/icons/ReviewLinkedinIcon'
import RENDER_CRITERIAS from './constants/RenderCriterias'
import RATIO, { ASPECT_RATIO_DETAILS } from '../../constants/AspectRatios'
import PLATFORMS from './constants/Platforms'
import { ReviewShareIcon } from '../video-editor/icons/ReviewShareIcon'
import { ReviewDownloadIcon } from '../video-editor/icons/ReviewDownloadIcon'
import MainContainer from '../video-editor/desktop-items/MainContainer'

const PREVIEW_PAGE = 0
const PUBLISH_PAGE = 1

class ReviewPublish extends Component {
  state = {
    rendering: false,
    slideV: 0,
    showStage: true,
    resolutions: [
      { w: 16, h: 9 },
      { w: 9, h: 16 },
      { w: 4, h: 5 },
      { w: 1, h: 1 },
    ],
    selectedText: null,
    selectedTextIndex: -1,
    tabIndex: 0,
    textClips: null,
    scale: 1,
    hScale: 1,
    ratios: ['landscape', 'vertical', 'portrait', 'square'],
    selectedPlatforms: [],
    selectedRenderCriterias: [],
  }

  get isRenderCriteriasSelected() {
    return !_.isEmpty(this.state.selectedRenderCriterias)
  }

  constructor(props) {
    super(props)
    this.stageRef = React.createRef()
    this.textWrapper = React.createRef()

    this.sceneMusics = []
    this.sceneItems = []
    this.sceneSounds = []
    this.sceneTexts = []
    this.sceneVideos = []
    this.sceneVoiceovers = []

    this.videoLoaded = []
    this.audioLoaded = []
    this.soundLoaded = []
    this.voiceoverLoaded = []

    this.tweenWrapper = null
    this.overlayWrapper = []

    this.handleProgressChange = handleProgressChange.bind(this)
    this.updateSlider = updateSlider.bind(this)
    this.completePreview = completePreview.bind(this)
    this.SetPreviewStage = SetPreviewStage.bind(this)
    this.playVideo = playVideo.bind(this)
    this.playAudio = playAudio.bind(this)
    this.playSound = playSound.bind(this)
    this.playVoiceover = playVoiceover.bind(this)
    this.pauseTimeline = pauseTimeline.bind(this)
    this.getProjectWidth = getProjectWidth.bind(this)
    this.toggleShowStage = toggleShowStage.bind(this)
    this.setSceneTimeline = setSceneTimeline.bind(this)
    this.updateProjectReview = updateProjectReview.bind(this)
    this.changeRatio = changeRatio.bind(this)
  }

  isRenderCriteriaExisting(platform, ratio) {
    return (criteria) => {
      const isSamePlatform = criteria.platform === platform
      const isSameRatio = criteria.ratio === ratio
      return isSamePlatform && isSameRatio
    }
  }

  onChangeSelectedRenderCriterias = (newSelectedRenderCriterias) => {
    this.setState({ selectedRenderCriterias: newSelectedRenderCriterias })
  }

  selectAllRenderCriterias = () => {
    if (this.state.selectedRenderCriterias.length === 4) {
      return this.onChangeSelectedRenderCriterias([])
    }
    const newRenderCriterias = RENDER_CRITERIAS.filter(
      (criteria) => criteria.platform === PLATFORMS.DOWNLOAD,
    )
    this.onChangeSelectedRenderCriterias([...newRenderCriterias])
  }

  handleSelectedRenderCriteriasChange = (ratio, isChecked) => {
    const selectedRenderCriterias = this.state.selectedRenderCriterias
    if (isChecked) {
      const newRenderCriteria = _.cloneDeep(
        _.find(
          RENDER_CRITERIAS,
          this.isRenderCriteriaExisting('download', ratio),
        ),
      )
      this.onChangeSelectedRenderCriterias([
        ...selectedRenderCriterias,
        newRenderCriteria,
      ])
    } else {
      const filteredCriterias = _.reject(
        selectedRenderCriterias,
        this.isRenderCriteriaExisting('download', ratio),
      )
      this.onChangeSelectedRenderCriterias(filteredCriterias)
    }
  }

  onChangeAspectRatio = (ratio) => {
    this.props.setPlaying(false)
    this.changeRatio(ratio)
  }

  onCheckRatio = (ratioDetail, isCheckedNewValue) => {
    this.handleSelectedRenderCriteriasChange(
      ratioDetail.ratio,
      isCheckedNewValue,
    )
  }

  handleRatioClick = (isRatioChecked, ratioDetail) => {
    const { project } = this.props
    const { selectedRenderCriterias } = this.state
    this.onCheckRatio(ratioDetail, isRatioChecked)
    if (isRatioChecked) {
      this.onChangeAspectRatio(ratioDetail.ratio)
    } else {
      // If current resolution is unchecked, check for an actively selected resolution criteria
      // and set as active project resolution
      if (project.resolution === ratioDetail.ratio) {
        const activeSelectedCriteria = selectedRenderCriterias.find(
          (criteria) => criteria.ratio !== ratioDetail.ratio,
        )
        if (activeSelectedCriteria) {
          this.onChangeAspectRatio(activeSelectedCriteria.ratio)
        }
      }
    }
  }

  getProjectsMinutes = async () => {
    const { project } = this.props
    const projectsMinutes = await instance.get('/projects/minutes')
    return projectsMinutes?.data + project.duration / 60000
  }

  handleMoveToPublish = async () => {
    const { authUser } = this.props
    triggerGoogleAnalyticsEvent('generate_videos_outputs', {
      userId: this.props.authUser?.id,
    })
    if (SubscriptionManager(authUser)?.isFreePlan) {
      alertSubscriptionMessage(
        'Nice project, want to upgrade to remove our watermark, and save it in your library. Otherwise it’ll be deleted after 24 hours.',
      )
    }
    this.isRenderCriteriasSelected && this.setState({ tabIndex: PUBLISH_PAGE })
  }

  handlePublishAll = async () => {
    const { selectedRenderCriterias } = this.state
    const {
      updateProjectBeforePublishing,
      publishVideoOutputs,
      project,
      authUser,
    } = this.props
    if (SubscriptionManager(authUser)?.isFreePlan) {
      alertSubscriptionMessage(
        'Nice project, want to upgrade to remove our watermark, and save it in your library. Otherwise it’ll be deleted after 24 hours.',
      )
      const projectsMinutes = await this.getProjectsMinutes()
      if (projectsMinutes >= 45) {
        return alertSubscriptionMessage(
          'It looks like you\'ve reached the 45 min cap for making free videos, upgrade to continue making this project',
        )
      }
    }
    try {
      triggerGoogleAnalyticsEvent('publish_videos', {
        projectId: project?.id,
        userId: authUser?.id,
        output: this.state.selectedRenderCriterias.length,
      })
      this.setState({ rendering: true })
      let clonedProject = _.cloneDeep(project)

      const updatedProject = await updateProjectBeforePublishing(
        clonedProject,
        authUser,
      )
      const videoOutputs = VideoFormatListGetter.getPossibleVideoOutputs(
        selectedRenderCriterias,
      )
      publishVideoOutputs &&
        (await publishVideoOutputs(updatedProject._id, videoOutputs))
      toast.success(
        'All done! You will get an e-mail notification once each video is rendered',
      )
      this.props.toggleShowReview(false, authUser)
      this.props.history.push(`/editor/${this.props.project.id}`)
    } catch (error) {
      console.error(error)

      triggerGoogleAnalyticsEvent('exception', {
        description: `There's an error while rendering the project: ${error.message}`,
      })
      toast.error(
        `There's an error while rendering the project: ${error.message}`,
      )
    } finally {
      this.setState({ rendering: false })
    }
  }

  componentDidMount() {
    this.props.loadUserSubscriptions()
    this.props.loadSubscriptionProducts()
    this.props.getProductFeatures()
  }

  render() {
    const {
      showReview,
      toggleShowReview,
      panelWidth,
      project,
      scenePlaying,
      template,
      updateTimeSlider,
      updateSlideV,
      authUser,
    } = this.props
    const { tabIndex, rendering, selectedRenderCriterias } = this.state
    const isLandscapeChecked = !!selectedRenderCriterias.find(
      ({ ratio }) => ratio === 0,
    )
    const isSquareChecked = !!selectedRenderCriterias.find(
      ({ ratio }) => ratio === 3,
    )
    const isSemiVerticalChecked = !!selectedRenderCriterias.find(
      ({ ratio }) => ratio === 2,
    )
    const isFullVerticalChecked = !!selectedRenderCriterias.find(
      ({ ratio }) => ratio === 1,
    )
    return (
      <Modal
        size="full"
        scrollBehavior="outside"
        blockScrollOnMount={false}
        isOpen={showReview}
        onClose={() => toggleShowReview(false, authUser)}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            <div className="publish-screen review-publish-page review-publish-modal">
              <h2 className="screen-title">Review and Publish</h2>

              <div className="loaders">
                <div className="loader">
                  <p className="title">1. Preview</p>
                  <div className="progress-bar active"></div>
                </div>
                <div className="loader">
                  <p className="title">2. Review & Publish</p>
                  <div
                    className={`progress-bar${
                      tabIndex === 1 ? ' active' : ''
                    }`}></div>
                </div>
              </div>

              {tabIndex === 0 ? (
                <div className="preview">
                  <div className="project-review">
                    <div className={'relative w-full'}>
                      <MainContainer
                        id="publish-movie-player"
                        isUsingHeight={false}
                        scenePlaying={scenePlaying}
                        togglePlay={(scenePlaying) =>
                          this.props.setPlaying(scenePlaying)
                        }
                        updateSlideV={updateSlideV}
                        showTextPanel={true}
                        disableTextResize
                        template={template}
                        updateTimeSlider={updateTimeSlider}
                      />
                    </div>
                  </div>
                  <div className="aspect-selections">
                    <div
                      className="select-all"
                      onClick={this.selectAllRenderCriterias}>
                      <div
                        className={`select-box${
                          selectedRenderCriterias.length === 4
                            ? ' selected'
                            : ''
                        }`}>
                        <span>&#10003;</span>
                      </div>
                      <span className="title">Select all</span>
                    </div>
                    <div className="select-aspect-ratios">
                      <div
                        className={`select-aspect-ratio${
                          isLandscapeChecked ? ' selected' : ''
                        }`}
                        onClick={() =>
                          this.handleRatioClick(
                            !isLandscapeChecked,
                            ASPECT_RATIO_DETAILS[RATIO.LANDSCAPE],
                          )
                        }>
                        <div className="details">
                          <div
                            className={`select-box${
                              isLandscapeChecked ? ' selected' : ''
                            }`}>
                            <span>&#10003;</span>
                          </div>
                          <div className="ratio landscape">
                            <span>16:9</span>
                          </div>
                          <p>16/9 - 25fps - 1080p</p>
                        </div>
                        <div className="supported-socials">
                          <p>Supported ratios:</p>
                          <div className="icons">
                            <ReviewFacebookIcon />
                            <ReviewYoutubeIcon />
                            <ReviewTwitterIcon />
                            <ReviewTiktokIcon />
                            <ReviewTwitchIcon />
                            <ReviewLinkedinIcon />
                          </div>
                        </div>
                      </div>
                      <div
                        className={`select-aspect-ratio${
                          isSquareChecked ? ' selected' : ''
                        }`}
                        onClick={() =>
                          this.handleRatioClick(
                            !isSquareChecked,
                            ASPECT_RATIO_DETAILS[RATIO.SQUARE],
                          )
                        }>
                        <div className="details">
                          <div
                            className={`select-box${
                              isSquareChecked ? ' selected' : ''
                            }`}>
                            <span>&#10003;</span>
                          </div>
                          <div className="ratio square">
                            <span>1:1</span>
                          </div>
                          <p>1/1 - 25fps - 1080p</p>
                        </div>
                        <div className="supported-socials">
                          <p>Supported ratios:</p>
                          <div className="icons">
                            <ReviewFacebookIcon />
                            <ReviewYoutubeIcon />
                            <ReviewTwitterIcon />
                            <ReviewTiktokIcon />
                            <ReviewLinkedinIcon />
                          </div>
                        </div>
                      </div>
                      <div
                        className={`select-aspect-ratio${
                          isSemiVerticalChecked ? ' selected' : ''
                        }`}
                        onClick={() =>
                          this.handleRatioClick(
                            !isSemiVerticalChecked,
                            ASPECT_RATIO_DETAILS[RATIO.SEMI_VERTICAL],
                          )
                        }>
                        <div className="details">
                          <div
                            className={`select-box${
                              isSemiVerticalChecked ? ' selected' : ''
                            }`}>
                            <span>&#10003;</span>
                          </div>
                          <div className="ratio semi-vertical">
                            <span>4:5</span>
                          </div>
                          <p>4/5 - 25fps - 1080p</p>
                        </div>
                        <div className="supported-socials">
                          <p>Supported ratios:</p>
                          <div className="icons">
                            <ReviewFacebookIcon />
                            <ReviewLinkedinIcon />
                          </div>
                        </div>
                      </div>
                      <div
                        className={`select-aspect-ratio${
                          isFullVerticalChecked ? ' selected' : ''
                        }`}
                        onClick={() =>
                          this.handleRatioClick(
                            !isFullVerticalChecked,
                            ASPECT_RATIO_DETAILS[RATIO.FULL_VERTICAL],
                          )
                        }>
                        <div className="details">
                          <div
                            className={`select-box${
                              isFullVerticalChecked ? ' selected' : ''
                            }`}>
                            <span>&#10003;</span>
                          </div>
                          <div className="ratio full-vertical">
                            <span>9:16</span>
                          </div>
                          <p>9/16 - 25fps - 1080p</p>
                        </div>
                        <div className="supported-socials">
                          <p>Supported ratios:</p>
                          <div className="icons">
                            <ReviewFacebookIcon />
                            <ReviewYoutubeIcon />
                            <ReviewTiktokIcon />
                            <ReviewLinkedinIcon />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="btns">
                      <Tooltip
                        placement="top-end"
                        label="Please select at least one(1) Sharing Destination below so you can proceed publishing the project"
                        isDisabled={this.isRenderCriteriasSelected}>
                        <button
                          className={`btn generate${
                            !this.isRenderCriteriasSelected ? ' disabled' : ''
                          }`}
                          onClick={this.handleMoveToPublish}>
                          Generate Video Outputs
                        </button>
                      </Tooltip>
                      <button
                        className="btn back"
                        onClick={() => toggleShowReview(false, authUser)}>
                        Back to Editor
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="review-publish">
                  <div className="review-publish__content">
                    <h4>Download or share</h4>
                    <div className="aspect-selections">
                      <div className="select-aspect-ratios">
                        <div
                          className={`select-aspect-ratio${
                            !isLandscapeChecked ? ' hide' : ''
                          }`}>
                          <div className="details">
                            <div className="ratio landscape">
                              <span>16:9</span>
                            </div>
                            <p>16/9 - 25fps - 1080p</p>
                          </div>
                          <div className="exports">
                            <div className="export share">
                              <ReviewShareIcon />
                            </div>
                            <div className="export download">
                              <ReviewDownloadIcon />
                            </div>
                          </div>
                        </div>
                        <div
                          className={`select-aspect-ratio${
                            !isSquareChecked ? ' hide' : ''
                          }`}>
                          <div className="details">
                            <div className="ratio square">
                              <span>1:1</span>
                            </div>
                            <p>1/1 - 25fps - 1080p</p>
                          </div>
                          <div className="exports">
                            <div className="export share">
                              <ReviewShareIcon />
                            </div>
                            <div className="export download">
                              <ReviewDownloadIcon />
                            </div>
                          </div>
                        </div>
                        <div
                          className={`select-aspect-ratio${
                            !isSemiVerticalChecked ? ' hide' : ''
                          }`}>
                          <div className="details">
                            <div className="ratio semi-vertical">
                              <span>4:5</span>
                            </div>
                            <p>4/5 - 25fps - 1080p</p>
                          </div>
                          <div className="exports">
                            <div className="export share">
                              <ReviewShareIcon />
                            </div>
                            <div className="export download">
                              <ReviewDownloadIcon />
                            </div>
                          </div>
                        </div>
                        <div
                          className={`select-aspect-ratio${
                            !isFullVerticalChecked ? ' hide' : ''
                          }`}>
                          <div className="details">
                            <div className="ratio full-vertical">
                              <span>9:16</span>
                            </div>
                            <p>9/16 - 25fps - 1080p</p>
                          </div>
                          <div className="exports">
                            <div className="export share">
                              <ReviewShareIcon />
                            </div>
                            <div className="export download">
                              <ReviewDownloadIcon />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="btns">
                        <Button
                          isLoading={rendering}
                          loadingText="Publishing"
                          onClick={() => this.handlePublishAll()}
                          className="w-72 publish-all"
                          colorScheme="teal"
                          size="lg">
                          Publish All
                        </Button>
                        <Button
                          onClick={() =>
                            this.setState({ tabIndex: PREVIEW_PAGE })
                          }
                          className="w-72 back"
                          variant="outline"
                          colorScheme="teal"
                          size="lg">
                          Back
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    )
  }
}

const mapStateToProps = (state) => ({
  authUser: state.auth.user,
  loading: state.videoPublish.loading,
  watchProject: state.editor.watchProject,
  tweenObjectBuilder: state.mainContainer.tweenObjectBuilder,
  timeSlider: state.timeline.timeSlider,
  project: state.editor.project,
  mySubscription: state.subscriptions.user,
  productFeatures: state.subscriptions.features,
  products: state.subscriptions.products,
})

const mapDispatchToProps = (dispatch) => ({
  SetTimeSlider: (...args) => dispatch(SetTimeSlider(...args)),
  SetPreviewTimeline: (...args) => dispatch(SetPreviewTimeline(...args)),
  resetAllSelected: (...args) =>
    dispatch(SelectedItemActions.resetAllSelected(...args)),
  setSelectedSceneIndex: (...args) =>
    dispatch(SelectedItemActions.setSelectedSceneIndex(...args)),
  setSelectedScene: (...args) =>
    dispatch(SelectedItemActions.setSelectedScene(...args)),
  setSelectedText: (...args) =>
    dispatch(SelectedItemActions.setSelectedText(...args)),
  setSelectedTextIndex: (...args) =>
    dispatch(SelectedItemActions.setSelectedTextIndex(...args)),
  toggleWatchProject: (...args) => dispatch(toggleWatchProject(...args)),
  updateProject: (...args) => dispatch(updateProject(...args)),
  reloadStageByProject: () =>
    dispatch(MainContainerActions.reloadStageByProject()),
  setTweenObjectBuilder: (...args) =>
    dispatch(MainContainerActions.setTweenObjectBuilder(...args)),
  setPlayCompleted: (...args) =>
    dispatch(MediaPlayerActions.setPlayCompleted(...args)),
  setPlaying: (...args) => dispatch(MediaPlayerActions.setPlaying(...args)),
  publishVideoOutputs: (...args) =>
    dispatch(VideoPublishActions.publishVideoOutputs(...args)),
  updateProjectBeforePublishing: (...args) =>
    dispatch(VideoPublishActions.updateProjectBeforePublishing(...args)),
  loadUserSubscriptions: () =>
    dispatch(SubscriptionActions.currentUserSubscription()),
  loadSubscriptionProducts: () =>
    dispatch(SubscriptionActions.getAllSubscriptionProducts()),
  getProductFeatures: () => dispatch(SubscriptionActions.getProductFeatures()),
})

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )((props) => {
    const { width, height } = useWindowDimensions()
    const ratio = width / height
    const panelWidth = useBreakpointValue({
      md: 'w-3/5',
      sm: 'w-3/5',
      lg: 'w-2/5',
      xl: 'w-2/5',
      '2xl': 'w-1/5',
      base: 'w-1/5',
    })
    return (
      <ReviewPublish panelWidth={panelWidth} windowRatio={ratio} {...props} />
    )
  }),
)
