export const SceneRightIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.9953 13.005L10.4023 17.598C10.3107 17.6915 10.2015 17.7658 10.081 17.8168C9.9605 17.8678 9.83108 17.8944 9.70023 17.8951C9.56938 17.8957 9.4397 17.8704 9.31868 17.8207C9.19767 17.7709 9.08772 17.6976 8.99519 17.6051C8.90267 17.5126 8.8294 17.4026 8.77963 17.2816C8.72986 17.1606 8.70458 17.0309 8.70524 16.9001C8.7059 16.7692 8.7325 16.6398 8.78348 16.5193C8.83447 16.3988 8.90884 16.2896 9.0023 16.198L12.9023 12.298L9.0023 8.39801C8.81974 8.21172 8.71807 7.96091 8.71938 7.70008C8.7207 7.43925 8.8249 7.18948 9.00934 7.00504C9.19377 6.82061 9.44354 6.71641 9.70437 6.71509C9.9652 6.71378 10.216 6.81544 10.4023 6.99801L14.9953 11.59C15.1828 11.7775 15.2881 12.0318 15.2881 12.297C15.2881 12.5622 15.1828 12.8165 14.9953 13.004L14.9953 13.005Z"
        fill="#D9D9D9"
      />
    </svg>
  )
}
