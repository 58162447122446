export const SIGN_UP = '/signup'
export const CONFIRM_USER = '/confirm-user/:token'
export const WOOCOMMERCE_SIGN_UP = '/wc/:orderKey'
export const SIGN_IN = '/signin'
export const PASSWORD_FORGET = '/pw-forget'
export const PASSWORD_RESET = '/pw-reset/:token'
export const LANDING = '/'
export const HOME = '/home'
export const CAMERA_VIEW = '/recorder'
export const MEDIA_EDITOR = '/editor/:id'
export const MEDIA_PUBLISHER = '/publisher/:id'
export const ACCOUNT = '/account'
export const CROPPER = '/cropper'
export const COUPON = '/code/:coupon'
export const SUBSCRIBE = '/subscribe'
export const PRICING = '/pricing'
export const COUPON_CODE = '/code/:coupon'

export const CHECKOUT_CANCEL = '/checkout/cancel'

// admin routes
export const ADMIN = '/admin'
export const ADMIN_LIST_USERS = '/admin/users'
export const ADMIN_SHOW_USER = '/admin/users/:id'
