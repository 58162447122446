import RATIO, { ASPECT_RATIO } from '../constants/AspectRatios'

export const getAspectRatio = (w, h) => {
  const gRate = w / h
  const fullVertical = eval(ASPECT_RATIO[RATIO.FULL_VERTICAL])
  const semiVertical = eval(ASPECT_RATIO[RATIO.SEMI_VERTICAL])
  const landscape = eval(ASPECT_RATIO[RATIO.LANDSCAPE])
  const square = eval(ASPECT_RATIO[RATIO.SQUARE])
  let s = 0
  //1.3889 r0 > x -> 0, r0 <= x && r2 > x -> 3
  const r0 = (landscape - square) / 2 + square
  //0.68125 1
  const r1 = (semiVertical - fullVertical) / 2 + fullVertical
  //0.9 2
  const r2 = (square - semiVertical) / 2 + semiVertical

  // if (cameraDimension.ratio > 1080) {
  if (gRate <= r1) {
    s = RATIO.FULL_VERTICAL
  } else if (gRate > r0) {
    s = RATIO.LANDSCAPE
  } else if (gRate > r1 && gRate <= r2) {
    s = RATIO.SEMI_VERTICAL
  } else if (gRate > r2 && gRate <= r0) {
    s = RATIO.SQUARE
  }
  return s
}
