export const EditorSoundIcon = (props) => {
  return (
    <svg
      {...props}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.7734 18.4609C12.7319 18.4609 12.6896 18.4574 12.6481 18.4488C10.5718 18.0541 8.66573 16.8021 7.41813 15.0151H6.53817C4.63007 15.0151 3.07715 13.4085 3.07715 11.4338V8.56523C3.07715 6.59053 4.63007 4.98398 6.53886 4.98398H7.41883C8.66435 3.19909 10.5704 1.94708 12.6481 1.55028C12.8516 1.51232 13.0586 1.56747 13.2165 1.70427C13.375 1.84036 13.4657 2.04234 13.4657 2.25507V17.7454C13.4657 17.9581 13.3744 18.1601 13.2165 18.2962C13.0912 18.4044 12.934 18.4617 12.7734 18.4617V18.4609ZM15.5566 13.4171C17.3789 11.5319 17.3789 8.46495 15.5566 6.57979C15.2859 6.29973 14.8484 6.29973 14.5776 6.57979C14.3069 6.85984 14.3069 7.31251 14.5776 7.59256C15.8599 8.91906 15.8599 11.0778 14.5776 12.4043C14.3069 12.6844 14.3069 13.137 14.5776 13.4171C14.7127 13.5568 14.8899 13.627 15.0671 13.627C15.2444 13.627 15.4216 13.5568 15.5566 13.4171Z"
        fill="#6B7280"
      />
    </svg>
  )
}
