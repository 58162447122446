export const ReviewTwitterIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 5.36667C0 3.46836 0 2.51921 0.375289 1.79675C0.69154 1.18794 1.18794 0.69154 1.79675 0.375289C2.51921 0 3.46836 0 5.36667 0H10.6333C12.5316 0 13.4808 0 14.2032 0.375289C14.8121 0.69154 15.3085 1.18794 15.6247 1.79675C16 2.51921 16 3.46836 16 5.36667V10.6333C16 12.5316 16 13.4808 15.6247 14.2032C15.3085 14.8121 14.8121 15.3085 14.2032 15.6247C13.4808 16 12.5316 16 10.6333 16H5.36667C3.46836 16 2.51921 16 1.79675 15.6247C1.18794 15.3085 0.69154 14.8121 0.375289 14.2032C0 13.4808 0 12.5316 0 10.6333V5.36667Z"
        fill="#1EA1F2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.4411 6.05072C12.4449 6.15065 12.4462 6.25055 12.4462 6.35048C12.4462 9.38031 10.3149 12.8781 6.41742 12.8781C5.22018 12.8781 4.10692 12.4965 3.16895 11.8469C3.33475 11.8651 3.50314 11.8787 3.67411 11.8787C4.66678 11.8787 5.58108 11.5107 6.30631 10.893C5.3791 10.8793 4.59616 10.2116 4.32614 9.30309C4.45576 9.33034 4.58926 9.34401 4.72578 9.34401C4.91828 9.34401 5.10519 9.31679 5.28434 9.26228C4.31407 9.05332 3.58324 8.12668 3.58324 7.01377C3.58324 7.00015 3.58324 6.99558 3.58324 6.98649C3.8692 7.15456 4.1965 7.25899 4.54404 7.27261C3.97471 6.85925 3.60046 6.15518 3.60046 5.36025C3.60046 4.94234 3.70468 4.54713 3.88814 4.20645C4.93292 5.59644 6.49494 6.50949 8.25591 6.60488C8.21974 6.43681 8.20121 6.2597 8.20121 6.08255C8.20121 4.8152 9.14995 3.78857 10.3205 3.78857C10.9299 3.78857 11.4803 4.0657 11.8666 4.51087C12.3502 4.41093 12.8033 4.22016 13.2132 3.9567C13.0543 4.49271 12.7188 4.94232 12.2804 5.22396C12.7094 5.16945 13.1185 5.04689 13.4979 4.86519C13.2132 5.32398 12.8549 5.7282 12.4411 6.05072Z"
        fill="white"
      />
    </svg>
  )
}
