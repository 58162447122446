import { useEffect, useState } from 'react'
import './cropper-mask.scss'

const CropperMask = ({ left, top, real_show_h, real_show_w }) => {
  const [leftMask, setLeftMask] = useState({})
  const [topMask, setTopMask] = useState({})
  const [rightMask, setRightMask] = useState({})
  const [bottomMask, setBottomMask] = useState({})

  useEffect(() => {
    const leftMaskWidth = (left || 0) - real_show_w / 2
    const leftMaskHeight = '100%'
    const topMaskWidth = '100%'
    const topMaskHeight = (top || 0) - real_show_h / 2
    const topMaskLeft = leftMaskWidth
    const rightMaskLeft = leftMaskWidth + real_show_w
    const rightMaskHeight = real_show_h
    const rightMaskWidth = `calc(100% - ${real_show_w + leftMaskWidth}px)`
    const rightMaskTop = topMaskHeight
    const bottomMaskLeft = leftMaskWidth
    const bottomMaskWidth = '100%'
    const bottomMaskHeight = `calc(100% - ${real_show_h + topMaskHeight}px)`
    const bottomMaskTop = topMaskHeight + real_show_h
    setLeftMask({ height: leftMaskHeight, width: leftMaskWidth })
    setTopMask({
      height: topMaskHeight,
      width: topMaskWidth,
      left: topMaskLeft,
    })
    setRightMask({
      left: rightMaskLeft,
      height: rightMaskHeight,
      width: rightMaskWidth,
      top: rightMaskTop,
    })
    setBottomMask({
      left: bottomMaskLeft,
      width: bottomMaskWidth,
      height: bottomMaskHeight,
      top: bottomMaskTop,
    })
  }, [left, top, real_show_h, real_show_w])

  return (
    <>
      <div
        className="cropper-mask cropper-mask__left"
        style={{
          ...leftMask,
        }}></div>
      <div
        className="cropper-mask cropper-mask__top"
        style={{
          ...topMask,
        }}></div>
      <div
        className="cropper-mask cropper-mask__right"
        style={{ ...rightMask }}></div>
      <div
        className="cropper-mask cropper-mask__bottom"
        style={{
          ...bottomMask,
        }}></div>
    </>
  )
}
export default CropperMask
