import { toast } from 'react-toastify'
import { PaymentConstants } from '../constants/Payments'

export const getRgbColor = (color) => {
  if (typeof color === 'string' && color.indexOf('#') > -1) {
    return color
  }

  return `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`
}

export const triggerGoogleAnalyticsEvent = (eventName, metaData) => {
  window.gtag('event', eventName, metaData)
}

export const isValidUploadUrl = (url) => {
  const regex = /^(https?:\/\/)?(www\.)?(vimeo\.com|youtu\.?be)\/.+$/
  return url.match(regex)
}

export const isValidUrl = (str) => {
  const pattern = new RegExp(
    '^([a-zA-Z]+:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR IP (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', // fragment locator
    'i',
  )
  return pattern.test(str)
}

export const getLocation = async () => {
  const location = await fetch(PaymentConstants.locationURL).then((r) =>
    r.json(),
  )
  return location
}

export const getRegion = (location) => {
  const countryCode = location?.country_code?.toLowerCase()
  const continent = location?.continent?.toLowerCase()
  if (countryCode?.includes(PaymentConstants.countryCodes.gb)) {
    return PaymentConstants.regions.uk
  } else if (continent?.includes(PaymentConstants.regions.europe)) {
    return PaymentConstants.regions.europe
  }
  return PaymentConstants.regions.common
}

export const alertSubscriptionMessage = (message, autoClose = false) => {
  return toast.warn(
    <p>
      {message}
      <br />
      <a
        target="_blank"
        href="/subscribe"
        style={{ textDecoration: 'underline' }}>
        Click here to access the goodies
      </a>
    </p>,
    { autoClose },
  )
}

export const getProduct = (sub, products) => {
  return products.find((p) => p._id === sub?.product_id)
}

export const SubscriptionManager = (authUser, sub, products) => {
  return {
    isFreePlan: authUser?.hasFeatures?.watermark,
    isProPlan: sub && sub.active && getProduct(sub, products)?.type === 'Pro',
    isTrialPlan: sub && sub.is_trial_active,
  }
}

export const IsReferredUserNeverPaid = (auth, mySubscription) => {
  return !!(
    (auth?.user?.referredBy && mySubscription?.is_trial_active) ||
    (auth?.user?.referredBy && !mySubscription?.last_renewed_date)
  )
}
