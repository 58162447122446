export const EditorAudioDuckingIcon = (props) => {
  return (
    <svg
      {...props}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_864_4279)">
        <g clip-path="url(#clip1_864_4279)">
          <path
            d="M15.577 1.53941L15.577 3.64381C14.0488 4.05915 13.1467 5.63468 13.562 7.16287C13.8288 8.14437 14.5955 8.9111 15.577 9.17786L15.577 18.4608C15.577 18.8856 15.9214 19.2299 16.3462 19.2299C16.771 19.2299 17.1153 18.8856 17.1153 18.4608L17.1153 9.17786C18.6435 8.76252 19.5457 7.18699 19.1303 5.6588C18.8636 4.6773 18.0969 3.91058 17.1154 3.64381L17.1154 1.53941C17.1154 1.11462 16.771 0.770264 16.3462 0.770264C15.9214 0.770264 15.577 1.11462 15.577 1.53941Z"
            fill="#6B7280"
          />
          <path
            d="M10.7699 18.4608L10.7699 16.3564C12.2978 15.9419 13.2005 14.3672 12.786 12.8392C12.5195 11.8569 11.7522 11.0896 10.7699 10.8231L10.7699 1.53941C10.7699 1.11462 10.4255 0.770264 10.0007 0.770264C9.57594 0.770264 9.23159 1.11462 9.23159 1.53941L9.23159 10.8231C7.70362 11.2376 6.80097 12.8123 7.21544 14.3402C7.48192 15.3225 8.24926 16.0899 9.23159 16.3564L9.23159 18.4608C9.23159 18.8856 9.57594 19.2299 10.0007 19.2299C10.4255 19.2299 10.7699 18.8856 10.7699 18.4608Z"
            fill="#6B7280"
          />
          <path
            d="M4.42395 18.4608L4.42395 9.17786C5.95214 8.76252 6.85429 7.18699 6.43894 5.6588C6.17218 4.6773 5.40545 3.91058 4.42395 3.64381L4.42395 1.53941C4.42395 1.11462 4.0796 0.770264 3.65481 0.770264C3.23002 0.770264 2.88567 1.11462 2.88567 1.53941L2.88567 3.64381C1.35748 4.05915 0.455333 5.63468 0.870676 7.16287C1.13744 8.14437 1.90417 8.9111 2.88567 9.17786L2.88567 18.4608C2.88567 18.8856 3.23002 19.2299 3.65481 19.2299C4.0796 19.2299 4.42395 18.8856 4.42395 18.4608Z"
            fill="#6B7280"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_864_4279">
          <rect width="20" height="20" fill="white" />
        </clipPath>
        <clipPath id="clip1_864_4279">
          <rect
            width="18.4615"
            height="18.4615"
            fill="white"
            transform="translate(0.769531 0.769287)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
