import React from 'react'
import _ from 'lodash'
import Loader from 'react-loader-spinner'
import WaveSurfer from 'wavesurfer.js'
import TimelineMoveable from '../timeline/TimelineMoveable'
import * as TimelineService from '../../services/time/TimelineService'
import { ONE_SECOND } from '../../constants/Timeline'
import AudioDuckVolumeOverlay from './AudioDuckVolumeOverlay'

const WAVE_HEIGHT = '4rem'

class AudioDuckTimelineItem extends React.Component {
  state = {
    loading: true,
  }

  itemRef = React.createRef()
  wavesurfer = React.createRef()
  wavesurferCanvas = React.createRef()

  componentDidUpdate(prevProps) {
    const { item } = this.props
    if (item.start_time !== prevProps.item.start_time) {
      this.reload()
    }
  }

  reload = () => {
    this.setState({ loading: true }, () => {
      this.setState({ loading: false })
    })
  }

  getPixelsFromMilliseconds = (duration) => {
    return (duration / ONE_SECOND) * 20
  }

  getHeight = () => {
    const { height } = this.props
    return height || WAVE_HEIGHT
  }

  getItemBoundingBoxHeight = () => {
    const height = this.itemRef?.current?.getBoundingClientRect()?.height || 0
    return height
  }

  handleItemClicked = () => {
    const { item, itemIndex } = this.props
    this.props.onItemSelected &&
      this.props.onItemSelected(item, itemIndex, true)
  }

  handleItemDragEnd = (newItemLeft) => {
    const { onPositionChanged } = this.props
    if (newItemLeft !== 0) {
      onPositionChanged && onPositionChanged(newItemLeft)
    } else {
      this.handleItemClicked()
    }
  }

  handleItemResizing = (widthDelta) => {
    const { onWidthChanged } = this.props
    const originalWidth = this.itemRef.current.getBoundingClientRect().width
    const newWidth = originalWidth + widthDelta
    onWidthChanged && onWidthChanged(newWidth)
  }

  handleResizeEnd = (widthDelta) => {
    const { onResizeEnd } = this.props
    onResizeEnd && onResizeEnd(widthDelta)
  }

  isActive = () => {
    const { activeItem, item } = this.props
    const isActive = activeItem && activeItem.id === item.id
    return isActive
  }

  handleOnAudioCanPlay = () => {
    const { item } = this.props
    let xhr = {
      cache: 'reload',
      mode: 'cors',
      method: 'GET',
      credentials: 'same-origin',
      redirect: 'follow',
      referrer: 'about:client',
      referrerPolicy: 'origin-when-cross-origin',
      headers: [{ key: 'Authorization', value: localStorage.jwtTokenCTEditor }],
    }
    if (!this.wavesurfer.current) {
      this.wavesurfer.current = WaveSurfer.create({
        container: this.wavesurferCanvas.current,
        barHeight: 2,
        fillParent: false,
        mediaControls: true,
        margin: 0,
        cursorWidth: 0,
        height: this.getItemBoundingBoxHeight(),
        hideScrollbar: true,
        progressColor: 'gray',
        responsive: false,
        waveColor: 'gray',
        pixelRatio: 20,
        minPxPerSec: 20,
        xhr,
      })
    }

    this.reloadWaves()
  }

  handleWavesurferLoaded = () => {
    this.setState({ loading: false })
  }

  handleDoubleClick = () => {
    const { onDoubleClick, item } = this.props
    onDoubleClick && onDoubleClick(item)
  }

  reloadWaves = () => {
    const { item } = this.props
    if (this.wavesurfer.current) {
      this.wavesurfer.current.on('ready', () => this.handleWavesurferLoaded())
      this.wavesurfer.current.load(item.url)
    }
    this.forceUpdate()
  }

  reloadWaveSurfer = () => {
    const { item } = this.props
    if (this.wavesurfer.current) {
      this.wavesurfer.current.load(item.url)
    }
  }

  renderDisplayContainer() {
    const { channel, item, activeItem, itemIndex, storeProjectSteps } =
      this.props
    const { loading } = this.state
    const duration = item?.clip?.duration
    const pixelWidth = TimelineService.convertMillisecondsToPixels(duration)
    const pixelLeft = TimelineService.convertMillisecondsToPixels(
      item.start_time,
    )
    const clipStart = TimelineService.convertMillisecondsToPixels(
      item.clip.start,
    )
    const clippedLeftPosition = -clipStart
    const additionalProps = this.isActive()
      ? {}
      : {
          onClick: this.handleItemClicked,
          onTouchStart: this.handleItemClicked,
        }
    return (
      <div
        ref={this.itemRef}
        className={[
          'absolute',
          'border-l',
          'border-r',
          'cursor-pointer',
          'border-teal-500',
          'whitespace-no-wrap',
        ].join(' ')}
        style={{
          width: `${pixelWidth}px`,
          left: `${pixelLeft}px`,
          height: `${this.getHeight()}`,
          top: 0,
          overflow: 'clip',
        }}
        onDoubleClick={() => this.handleDoubleClick()}
        {...additionalProps}>
        <audio src={item.url} onCanPlayThrough={this.handleOnAudioCanPlay} />
        <div
          className="absolute"
          ref={this.wavesurferCanvas}
          style={{
            zIndex: 0,
            left: `${clippedLeftPosition}px`,
          }}
        />
        {loading && (
          <div className="flex absolute top-0 w-full h-full items-center justify-center">
            <Loader type="Puff" color="gray" height={20} width={20} />
          </div>
        )}
        <div
          className="flex absolute top-0 w-full h-full items-center justify-center"
          style={{
            zIndex: 0,
            height: `${this.getHeight()}`,
            overflow: 'hidden',
            fontWeight: activeItem === item ? 'bold' : 'normal',
          }}>
          {this.props.displayLabel ? (
            <div>
              {_.isFunction(this.props.displayLabel)
                ? this.props.displayLabel(item)
                : item.name}
            </div>
          ) : (
            ''
          )}
        </div>
        <AudioDuckVolumeOverlay
          height={this.getItemBoundingBoxHeight()}
          itemIndex={itemIndex}
          channel={channel}
          storeProjectSteps={storeProjectSteps}
          item={item}
        />
      </div>
    )
  }

  renderMoveableWrapper() {
    const { item, itemTimelineHTML, verticalGuidelines } = this.props
    return (
      <TimelineMoveable
        timelineItem={item}
        target={this.itemRef.current}
        container={itemTimelineHTML}
        verticalGuidelines={verticalGuidelines}
        draggable={true}
        resizable={this.isActive()}
        throttleDrag={0}
        startDragRotate={0}
        throttleDragRotate={0}
        onDragEnd={this.handleItemDragEnd.bind(this)}
        onResize={this.handleItemResizing.bind(this)}
        onResizeEnd={this.handleResizeEnd.bind(this)}
        zoom={1}
        origin={this.isActive()}
      />
    )
  }

  render() {
    const { loading } = this.state
    const { item } = this.props
    return (
      <React.Fragment>
        {this.renderDisplayContainer()}
        {!loading && this.isActive() && this.renderMoveableWrapper()}
      </React.Fragment>
    )
  }
}

export default AudioDuckTimelineItem
