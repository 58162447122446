import * as Constants from '../constants/ProjectEditConstants'

export const setDefaultLayout = (aspectRatioType) => {
  return (dispatch) => {
    dispatch({
      type: Constants.SET_DEFAULT_LAYOUT,
      defaultLayout: aspectRatioType,
    })
  }
}
