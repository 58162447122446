import { useEffect, useState } from 'react'
import { useParams, Link } from 'react-router-dom'
import instance from '../../utils/Axios'
import { Footer } from '../footer/Footer'

const ConfirmUser = () => {
  const { token } = useParams()
  const [verified, setVerified] = useState(undefined)

  const verifyUser = () => {
    instance
      .get(`/users/confirm/${token}`)
      .then((response) => {
        setVerified(true)
      })
      .catch((error) => {
        setVerified(false)
      })
  }

  useEffect(() => {
    verifyUser()
  }, [])

  return (
    <>
      <div
        className="cerebriam-confirm-email"
        style={{
          backgroundColor: '#fff',
          borderRadius: 16,
          boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.05)',
          overflow: 'hidden',
          maxWidth: 500,
          padding: 20,
          margin: '20px auto',
          height: 200,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <div className="content" style={{ textAlign: 'center' }}>
          {verified === undefined ? (
            <h3 style={{ fontSize: 40, color: '#6c757d' }}>Verifying...</h3>
          ) : verified ? (
            <>
              <h3 style={{ fontSize: 40, color: '#28a745' }}>
                <strong>Account confirmed!</strong>
              </h3>
              <p>
                Please,{' '}
                <Link to="/" style={{ textDecoration: 'underline' }}>
                  login
                </Link>{' '}
                to begin your journey!
              </p>
            </>
          ) : (
            <>
              <h3 style={{ fontSize: 40, color: '#dc3545' }}>
                Failed verification!
              </h3>
              <p>
                Go back{' '}
                <Link to="/" style={{ textDecoration: 'underline' }}>
                  home
                </Link>
              </p>
            </>
          )}
        </div>
      </div>
      <Footer />
    </>
  )
}

export default ConfirmUser
