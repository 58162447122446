import _ from 'lodash'
import { MUSIC, VIDEO, VOICEOVER, AUDIO } from '../../constants/Channels'
import * as ROUTES from '../../constants/Routes'
import SceneItemAdder from '../../services/project-items/SceneItemAdder'
import * as ProjectEditService from '../../services/ProjectEditService'
import DurationGetter from '../../services/time/DurationGetter'
import { updateTransitions } from './services'
import { libraryFilesDelete } from '../../services/UploadServices'
import {
  BASE_CLIP_DURATION,
  DEFAULT_SCENE,
  FLOAT_RATE,
  SEC_WIDTH,
} from '../../constants/TimelineItems'
/**
 * Alert if clicked on outside of element
 */
export function handleClickOutside(event) {
  if (this.modalRef && !this.modalRef.current.contains(event.target)) {
    if (this.props.toggleShowImport) {
      this.props.toggleShowImport(false)
    }
  }
}

export function goToUploadFiles() {
  this.props.welcomeSetStep(2)

  this.props.history.push({
    pathname: ROUTES.HOME,
    state: { preRoute: 'editor' },
  })
}

export function toggleSelectItem(item, flag) {
  const { selMedias, selAudios, selPhotos, selBrands, selVoiceovers } =
    this.state

  if (flag === 'medias') {
    if (selMedias.includes(item)) {
      this.setState({
        selMedias: selMedias.filter((el) => {
          return el.fileUrl !== item.fileUrl
        }),
      })
    } else {
      this.setState({
        selMedias: [...selMedias, item],
      })
    }
  } else if (flag === 'audios') {
    if (selAudios.includes(item)) {
      this.setState({
        selAudios: selAudios.filter((el) => {
          return el.fileUrl !== item.fileUrl
        }),
      })
    } else {
      this.setState({
        selAudios: [...selAudios, item],
      })
    }
  } else if (flag === 'voiceovers') {
    if (selVoiceovers.includes(item)) {
      this.setState({
        selVoiceovers: selVoiceovers.filter((el) => {
          return el.fileUrl !== item.fileUrl
        }),
      })
    } else {
      this.setState({
        selVoiceovers: [...selVoiceovers, item],
      })
    }
  } else if (flag === 'photos') {
    if (selPhotos.includes(item)) {
      this.setState({
        selPhotos: selPhotos.filter((el) => {
          return el.fileUrl !== item.fileUrl
        }),
      })
    } else {
      this.setState({
        selPhotos: [...selPhotos, item],
      })
    }
  } else if (flag === 'brands') {
    if (selBrands.includes(item)) {
      this.setState({
        selBrands: selBrands.filter((el) => {
          return el.fileUrl !== item.fileUrl
        }),
      })
    } else {
      this.setState({
        selBrands: [...selBrands, item],
      })
    }
  }
}

export function getImgSize(img, flag) {
  const { stageW, stageH } = this.props

  let width = img.width
  let height = img.height
  let top = 0
  let left = 0
  let checkSize = false

  if (!flag) {
    if (img.width > stageW || img.height > stageH) {
      checkSize = true
    } else {
      top = (stageH - height) / 2
      left = (stageW - width) / 2
    }
  } else {
    checkSize = true
  }

  if (checkSize) {
    const wR = stageW / img.width
    const wH = stageH / img.height
    if (wR > wH) {
      width = (stageH * img.width) / img.height
      height = (width * img.height) / img.width
      left = (stageW - width) / 2
    } else {
      height = (stageW * img.height) / img.width
      width = (height * img.width) / img.height
      top = (stageH - height) / 2
    }
  }

  return { width, height, top, left }
}

export function setImageScene(projectVal, photo, flag) {
  let sceneVal = Object.assign({}, DEFAULT_SCENE)

  // console.debug('setImageScene iim')

  sceneVal.id = `${photo._id}_${new Date().getTime()}`
  sceneVal.type = 'photo'
  sceneVal.assetId = photo._id
  sceneVal.assetType = 'library'
  sceneVal.ext = photo.extension
  sceneVal.originUrl = photo.fileUrl
  sceneVal.assetUrl = photo.fileUrl
  sceneVal.thumbUrl = photo.fileUrl
  sceneVal.originFileName = photo.fileName
  sceneVal.order = projectVal.scenes[projectVal.resolution].length
  sceneVal.crop = photo.isCropped
  sceneVal.sound = false

  let sLeft = 0

  // finds end time of last scene item
  const sceneItems = projectVal.scenes[projectVal.resolution]
  if (sceneItems.length) {
    const latestScene = sceneItems.reduce((max, item) =>
      item.end_time > max.end_time ? item : max,
    )

    sceneVal.start_time = latestScene.end_time
    sceneVal.end_time = latestScene.end_time + BASE_CLIP_DURATION

    sLeft = latestScene.size.sLeft + latestScene.size.sliderW
  } else {
    sceneVal.start_time = 0
    sceneVal.end_time = BASE_CLIP_DURATION
  }

  sceneVal.clip = {
    start: 0,
    end: BASE_CLIP_DURATION,
    duration: BASE_CLIP_DURATION,
  }

  let img = new Image()
  img.src = photo.fileUrl
  img.onload = () => {
    const { width, height, top, left } = this.getImgSize(img, flag)
    sceneVal.top = top
    sceneVal.left = left

    sceneVal.size = {
      sLeft: sLeft,
      sliderW:
        Math.round((BASE_CLIP_DURATION / 1000) * SEC_WIDTH * FLOAT_RATE) /
        FLOAT_RATE,
      width: width,
      height: height,
    }
  }

  projectVal.duration += BASE_CLIP_DURATION

  for (let j = 0; j < 4; j++) {
    if (projectVal.selectLayout[j]) {
      projectVal.scenes[j].push(sceneVal)
    }
  }

  return projectVal
}

export function addFilesToScene() {
  const { selMedias, selAudios, selPhotos, selBrands, selVoiceovers } =
    this.state

  // console.debug('add file to scene props', this.props)
  // console.debug('add file to scene state', this.state)

  const { project, authUser, currentLayout, storeProjectSteps } = this.props

  let addedFiles = false
  let projectVal = _.cloneDeep(project)
  if (!_.isEmpty(selMedias)) {
    projectVal = _.reduce(
      selMedias,
      (updatedProject, media) => {
        const durationToStart = DurationGetter.getMaxDurationByChannel(
          updatedProject,
          VIDEO.code,
        )
        const defaultSceneItem = SceneItemAdder.createSceneItemFromLibrary(
          media,
          media.libType,
          authUser.id,
          currentLayout,
          true,
          true,
          1080,
        )
        if (defaultSceneItem.sound)
          storeProjectSteps({ channel: ['audio', 'video'], type: 'ADD' })
        else storeProjectSteps({ channel: ['video'], type: 'ADD' })
        let selectedLayout = [true, true, true, true]
        if (media.libType === 'library') {
          selectedLayout = media.selectedLayout
        } else if (media.libType === 'capture') {
          selectedLayout = media.selectLayout
        }
        updatedProject = ProjectEditService.addItemToProject(
          updatedProject,
          defaultSceneItem,
          VIDEO.code,
          durationToStart,
          {
            selectedLayout: selectedLayout,
            aspectSize: media.aspectSize,
            streamSize: media.streamSize,
          },
        )
        return updatedProject
      },
      projectVal,
    )

    addedFiles = true
  }

  if (selPhotos.length) {
    for (let sP = 0; sP < selPhotos.length; sP++) {
      const photo = selPhotos[sP]
      storeProjectSteps({ channel: ['video'], type: 'ADD' })

      projectVal = this.setImageScene(projectVal, photo, true)
    }

    addedFiles = true
  }

  // TODO: consolidate this condition with the above condition
  if (selBrands.length) {
    for (let sB = 0; sB < selBrands.length; sB++) {
      const brand = selBrands[sB]
      storeProjectSteps({ channel: ['video'], type: 'ADD' })
      projectVal = this.setImageScene(projectVal, brand, false)
    }

    addedFiles = true
  }

  if (!_.isEmpty(selAudios)) {
    projectVal = _.reduce(
      selAudios,
      (previousProject, musicItem) => {
        storeProjectSteps({ channel: ['music'], type: 'ADD' })
        return ProjectEditService.addItemToProject(
          previousProject,
          musicItem,
          MUSIC.code,
        )
      },
      projectVal,
    )
    addedFiles = true
  }

  if (!_.isEmpty(selVoiceovers)) {
    projectVal = _.reduce(
      selVoiceovers,
      (previousProject, voiceoverItem) => {
        storeProjectSteps({ channel: ['voiceover'], type: 'ADD' })
        return ProjectEditService.addItemToProject(
          previousProject,
          voiceoverItem,
          VOICEOVER.code,
        )
      },
      projectVal,
    )
    addedFiles = true
  }

  if (!addedFiles) {
    return
  }

  projectVal = updateTransitions(projectVal, false)
  this.props.updateProject(projectVal)
  // this.props.reloadStageByProject(projectVal)
  this.props.toggleShowImport(false)
}

export async function deleteMedia() {
  const { selMedias, selAudios, selPhotos, selBrands, selVoiceovers } =
    this.state
  const ids = [
    ...selMedias,
    ...selAudios,
    ...selPhotos,
    ...selBrands,
    ...selVoiceovers,
  ].map((item) => item._id)
  await libraryFilesDelete(ids.toString())
  this.props.getLibFiles()
  this.props.toggleShowImport(false)
}
