export const CopyIcon = () => {
  return (
    <svg
      width="40"
      height="41"
      viewBox="0 0 40 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect y="0.195312" width="40" height="40" rx="7" fill="#E5E7EB" />
      <g clip-path="url(#clip0_852_18184)">
        <path
          d="M24.6667 24.4714H12V14.138C12 13.5192 12.2458 12.9257 12.6834 12.4881C13.121 12.0505 13.7145 11.8047 14.3333 11.8047H22.3333C22.9522 11.8047 23.5457 12.0505 23.9832 12.4881C24.4208 12.9257 24.6667 13.5192 24.6667 14.138V24.4714ZM14 22.4714H22.6667V14.138C22.6655 14.05 22.63 13.9659 22.5677 13.9037C22.5054 13.8414 22.4214 13.8059 22.3333 13.8047H14.3333C14.2449 13.8047 14.1601 13.8398 14.0976 13.9023C14.0351 13.9648 14 14.0496 14 14.138V22.4714ZM26 15.172V25.8047H15.3333V27.8047H28V17.4714C27.9984 16.9118 27.7954 16.3715 27.4282 15.9492C27.0609 15.527 26.554 15.2511 26 15.172Z"
          fill="#008180"
        />
      </g>
      <defs>
        <clipPath id="clip0_852_18184">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(12 11.8047)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
