import React, { Component } from 'react'
import _, { isInteger } from 'lodash'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { toast } from 'react-toastify'
import {
  loadedReplayVideo,
  closeNotifyModal,
  toggleSnapGrid,
  uploadCropVideo,
  uploadCropVideoToFaceTrack,
  updateProjectFromCroppedVideo,
  trackScenes,
} from '../../methods/home/CropperMethods'
import TextSpinner from '../../components/common/TextSpinner'
import CropperPreview from '../../components/video-cropper/CropperPreview'
import * as FaceTrackActions from '../../redux/actions/FaceTrackActions'
import * as VideoCropActions from '../../redux/actions/VideoCropActions'
import * as RecorderActions from '../../redux/actions/RecorderActions'
import { resetCropAreas } from '../../redux/actions/RecorderActions'
import CropperFacetrackPreview from '../../components/video-cropper/CropperFacetrackPreview'
import * as VideoUploadService from '../../services/uploads/VideoUploadService'
import * as EditorActions from '../../redux/actions/EditorActions'
import * as MediaPlayerActions from '../../redux/actions/MediaPlayerActions'
import * as SelectedItemActions from '../../redux/actions/SelectedItemActions'
import RATIO, { RATIOS } from '../../constants/AspectRatios'
import CropperTooltip from '../../components/cropper-tooltip/CropperTooltip'
import { triggerGoogleAnalyticsEvent } from '../../utils/Helper'
import CropperAspectRatioSelection from '../../components/video-cropper/CropperAspectRatioSelection'
import CropperTopButtons from '../../components/video-cropper/CropperTopButtons'
import * as SubscriptionActions from '../../redux/actions/SubscriptionActions'
import CropperScenes from '../../components/video-cropper/CropperScenes'
import ApplySelectedRatiosImg from '../../assets/images/cropper/apply-selected-ratios.png'

import {
  Modal,
  ModalCloseButton,
  ModalBody,
  ModalContent,
  ModalOverlay,
} from '@chakra-ui/modal'
import { AspectRatiosPlatforms } from '../../constants/AspectRatiosPlatforms'
import { SOCKET, SOCKET_EVENTS } from '../../utils/Socket'
import * as FileUtils from '../../utils/FileUtils'

const TrackSessionsIds = {
  SEGMENTATIONS: 'segmentations',
  SINGLE: 'single',
}

const aspectRatioExist = (aspectRatioArr) => {
  let valid = false
  aspectRatioArr.map((x) => {
    if (x.height && x.width) {
      valid = true
    }
  })
  return valid
}

export const aspectRatioCount = (aspectRatioArr) => {
  return aspectRatioArr.reduce((accumulator, currentValue) => {
    return (currentValue.height && currentValue.width ? 1 : 0) + accumulator
  }, 0)
}

export const getProduct = (sub, products) => {
  return products.find((p) => p._id === sub?.product_id)
}

export const showBasicToastForUpload = () => {
  return toast.warning(
    <>
      Looks like you’re killing it on your socials. To create up to 4 aspect
      ratios simultaneously, please upgrade to the Cerebriam Pro package
      <br />
      <a target="_blank" href="/" style={{ textDecoration: 'underline' }}>
        Click here to access the goodies
      </a>
    </>,
  )
}

class Cropper extends Component {
  replayVideo = React.createRef()
  cropperActionWrapper = React.createRef()
  constructor(props) {
    super(props)

    this.moveRef = React.createRef()
    this.intervalRef = React.createRef()
    this.resolvingCroppedScenesToProject = React.createRef()

    this.state = {
      aspectSize: [
        { width: 0, height: 0, top: 0, left: 0 },
        { width: 0, height: 0, top: 0, left: 0 },
        { width: 0, height: 0, top: 0, left: 0 },
        { width: 0, height: 0, top: 0, left: 0 },
      ],
      streamSize: [
        { width: 0, height: 0 },
        { width: 0, height: 0 },
        { width: 0, height: 0 },
        { width: 0, height: 0 },
      ],
      selectedLayout: [false, false, false, false],
      saveLayout: [false, false, false, false],
      selAspect: 0,
      videoUrl: '',
      videoId: '',
      videoName: '',
      segmentedVideos: [],
      isLoadedReplayVideo: false,
      videoDuration: 0,
      showSnap: true,
      uploading: false,
      videoCropTime: null,
      layoutChangedFromDropDown: false,
      selectedRatios: [false, false, false, false],
      selectedPlatforms: [],
      selectedPlatform: 0,
      selectedRatio: -1,
      cropperInstructionStep: 1,
      isCropperInstructionStepActive: !localStorage.getItem(
        'tooltip_instruction',
      ),
      selNextPopup: false,

      cropperCurrentScene: {
        totalTime: 0,
        currTime: 0,
        scenePlaying: false,
      },
      openFullSegmentationsTab: false,
      applySelectedRatiosModal: false,
      notShownApplySelectedRatiosModal: true,
      sceneIdApplyingRatiosFrom: null,
      ratioToEdit: null,
    }

    this.loadedReplayVideo = loadedReplayVideo.bind(this)
    this.closeNotifyModal = closeNotifyModal.bind(this)
    this.toggleSnapGrid = toggleSnapGrid.bind(this)
    this.uploadCropVideo = uploadCropVideo.bind(this)
    this.uploadCropVideoToFaceTrack = uploadCropVideoToFaceTrack.bind(this)
    this.updateProjectFromCroppedVideo =
      updateProjectFromCroppedVideo.bind(this)
    this.changeLayoutFromDropdown = this.changeLayoutFromDropdown.bind(this)
    this.changeDefaultResolution = this.changeDefaultResolution.bind(this)
    this.selectRatio = this.selectRatio.bind(this)
    this.updateReplayVideoRef = this.updateReplayVideoRef.bind(this)
    this.resetSelectedAspectRatios = this.resetSelectedAspectRatios.bind(this)
    this.updateSelectedPlatform = this.updateSelectedPlatform.bind(this)
    this.updateSelectedRatio = this.updateSelectedRatio.bind(this)
    this.retriggerCropperTooltip = this.retriggerCropperTooltip.bind(this)
    this.changeCropperInstructionStep =
      this.changeCropperInstructionStep.bind(this)
    this.closeCropperInstructionStep =
      this.closeCropperInstructionStep.bind(this)
    this.wantToEditRatio = this.wantToEditRatio.bind(this)
    this.clearEditRatio = this.clearEditRatio.bind(this)
    this.resetApplySelectedRatios = this.resetApplySelectedRatios.bind(this)
  }

  clearEditRatio = () => {
    this.setState({ ratioToEdit: null })
  }

  wantToEditRatio = (ratio) => {
    if (isInteger(ratio) && ratio >= 0 && ratio < RATIOS.length) {
      const platform = this.state.selectedPlatforms.find(
        (x) => x.ratio === ratio,
      ).platform
      this.updateSelectedPlatform(platform)
      this.setState({ ratioToEdit: ratio })
    }
  }

  resetApplySelectedRatios = () => {
    this.setState({
      notShownApplySelectedRatiosModal: true,
    })
  }

  applyCurrentRatioSelectionsToSegementations = () => {
    this.setState({
      notShownApplySelectedRatiosModal: false,
      applySelectedRatiosModal: false,
    })
    const videoIds = this.state.segmentedVideos.map(({ _id }) => _id)
    this.props.applyRatioSelectionsToSegmentations(
      videoIds,
      this.state.sceneIdApplyingRatiosFrom,
    )
    // this.props.applySetFaceTrackingSegmentations(videoIds)
    toast.success('Selected ratios applied to all segmentations')
  }

  toggleApplySelectRatiosModal = () => {
    const currentState = this.state.applySelectedRatiosModal
    const notShownApplySelectedRatiosModal =
      this.state.notShownApplySelectedRatiosModal
    if (notShownApplySelectedRatiosModal || currentState) {
      this.setState({
        applySelectedRatiosModal: !currentState,
      })

      this.setState({
        notShownApplySelectedRatiosModal: false,
      })
    }
  }

  toggleFullSegmentationsTab = () => {
    const prevState = this.state.openFullSegmentationsTab
    this.setState({
      openFullSegmentationsTab: !prevState,
    })
  }

  setActiveSegmentedScene = (fileName) => {
    const currentState = this.state.videoId
    this.setState({ sceneIdApplyingRatiosFrom: currentState })
    const scene = this.state.segmentedVideos.find(
      (segVideo) => segVideo.fileName === fileName,
    )
    this.setState({
      videoUrl: scene.fileUrl,
      videoId: scene._id,
      videoDuration: scene.duration,
      layoutChangedFromDropDown: true,
      cropperCurrentScene: {
        ...this.state.cropperCurrentScene,
        totalTime: scene.duration * 1000,
        currTime: 0,
        scenePlaying: false,
      },
    })
    this.props.setActiveSegmentationScene(scene._id)
  }

  setSceneTime(sliderPos, totalTime) {
    const newTime = (sliderPos * totalTime) / 100
    const prevState = this.state.cropperCurrentScene
    this.replayVideo.currentTime = newTime / 1000
    this.setState({
      cropperCurrentScene: {
        ...prevState,
        currTime: newTime,
      },
    })
  }

  playCropperScene(scenePlaying) {
    const prevState = this.state.cropperCurrentScene
    if (scenePlaying) {
      this.replayVideo.play()
    } else {
      this.replayVideo.pause()
    }
    this.setState({
      cropperCurrentScene: {
        ...prevState,
        scenePlaying,
      },
    })
  }

  retriggerCropperTooltip() {
    this.setState({
      cropperInstructionStep: 1,
      isCropperInstructionStepActive: true,
    })
  }

  changeCropperInstructionStep() {
    if (this.state.cropperInstructionStep < 4) {
      const currentStep = this.state.cropperInstructionStep
      this.setState({
        cropperInstructionStep: currentStep + 1,
      })
    } else {
      this.setState({
        isCropperInstructionStepActive: false,
      })
    }
  }

  closeCropperInstructionStep() {
    this.setState({
      isCropperInstructionStepActive: false,
    })
    localStorage.setItem('tooltip_instruction', 'true')
  }

  updateSelectedPlatform(platform) {
    this.setState({
      selectedPlatform: platform,
    })
  }

  updateSelectedRatio(ratio) {
    this.setState({
      selectedRatio: ratio,
    })
  }

  updateReplayVideoRef(value) {
    this.replayVideo = value
  }

  removeRatio(ratio) {
    if (isInteger(ratio) && ratio >= 0 && ratio < RATIOS.length) {
      let selectedPlatforms = [...this.state.selectedPlatforms].filter(
        (p) => p.ratio !== ratio,
      )
      this.setState({
        selectedPlatforms,
      })
    }
  }

  selectRatio = (ratio) => {
    if (isInteger(ratio) && ratio >= 0 && ratio < RATIOS.length) {
      const ratios = this.state.selectedRatios
      ratios[ratio] = true
      let selectedPlatforms = [...this.state.selectedPlatforms].filter(
        (p) => p.ratio !== ratio,
      )
      selectedPlatforms.push({ platform: this.state.selectedPlatform, ratio })
      this.setState({
        selectedRatios: ratios,
        selNextPopup: true,
        selectedPlatforms,
      })

      setTimeout(() => {
        this.setState({ selNextPopup: false })
      }, 2000)
    }
  }

  resetSelectedAspectRatios() {
    this.setState({
      selectedPlatform: 0,
      selectedRatio: -1,
    })
  }

  changeLayoutFromDropdown = (value) => {
    this.setState({
      layoutChangedFromDropDown: value,
    })
  }

  // Function change default aspect resolution from '0' to something else if no '0' aspect selected while cropping
  changeDefaultResolution = async (newProjectData, aspectRatio) => {
    this.props.resetAllSelected()
    this.props.changeAspectRatio(newProjectData, aspectRatio, false)
  }

  resetCropperSelectionsOnUpload(s) {
    this.resetSelectedAspectRatios()
    this.changeLayoutFromDropdown(true)
    this.props.setCurrentLayout(s)
    this.props.setFaceTrackingRatio(s)
    this.props.setSelectedMediaLayout(true, s)
  }

  handleUploadVideo = async () => {
    const { authUser } = this.props
    this.uploadFinalVideo(false)
  }

  handleUploadVideoFromFaceTrack = async () => {
    this.uploadFinalVideo(true)
  }

  handleTrackSegmentations = async () => {
    const {
      aspectSizeSegmentations,
      authUser,
      streamSize,
      isFaceTrackingEnabledSegmentations,
    } = this.props
    const { segmentedVideos } = this.state
    this.setState({ uploading: true })
    const trackData = aspectSizeSegmentations.reduce((acc, sceneAspectSize) => {
      const isTrackSet = _.find(isFaceTrackingEnabledSegmentations, {
        id: sceneAspectSize.id,
      })?.isFaceTrackingEnabled

      if (!isTrackSet) {
        return acc
      }
      const segmentedVideo = _.find(segmentedVideos, {
        _id: sceneAspectSize.id,
      })

      acc.push({
        fileUrl: segmentedVideo?.fileUrl,
        filename: segmentedVideo?.fileName,
        aspectSizes: sceneAspectSize.data,
        streamSize: streamSize[0],
        userId: authUser.id,
      })
      return acc
    }, [])
    await trackScenes(trackData, TrackSessionsIds.SEGMENTATIONS)
  }

  checkSegmentationCroppedAssetsAvailable = async (croppedData) => {
    const fetches = []
    for (var i = 0; i < croppedData.length; i++) {
      const data = croppedData[i]

      const splitUrl = data.fileUrl.split('.')
      const fileExt = splitUrl[splitUrl.length - 1]
      splitUrl.pop()
      const expectedCroppedScenes = data.selectedLayout.reduce(
        (acc, truthy, index) => {
          if (truthy) {
            acc.push(fetch(`${splitUrl.join('.')}_${index}.${fileExt}`))
          }
          return acc
        },
        [],
      )
      fetches.push(...expectedCroppedScenes)
    }

    Promise.all(fetches)
      .then((responses) => {
        const errorResource = responses.find((response) => !response.ok)
        if (errorResource) {
          throw new Error(errorResource.statusText)
        }
        // return Promise.all(responses.map((res) => res.blob()))
      })
      .then(async () => {
        clearInterval(this.intervalRef?.current)
        if (this.resolvingCroppedScenesToProject?.current !== 'true') {
          this.resolvingCroppedScenesToProject.current = 'true'
          this.setState({ uploading: false })
          await this.updateProjectFromCroppedVideo(croppedData, null, true)
        }
      })
      .catch((error) => {
        return error
      })
  }

  uploadFinalVideo = async (didTrackingVideo) => {
    try {
      triggerGoogleAnalyticsEvent('uploading_cropped_video', {
        projectId: this.state?.videoId || '',
        userId: this.props.authUser?.id,
      })
      if (!aspectRatioExist(this.props.aspectSize)) {
        triggerGoogleAnalyticsEvent('exception', {
          description:
            'Whoa there! Select your target social platform, then hit the "Add another aspect ratio" button once before pressing "Make"',
          userId: this.props.authUser?.id,
        })
        return toast.warning(
          'Whoa there! Select your target social platform, then hit the "Add another aspect ratio" button once before pressing "Make"',
        )
      }
      this.resetCropperSelectionsOnUpload(0)
      this.setState({ uploading: true })
      const uploadVideoId = this.state?.videoId || ''
      const layoutProperties = {
        ...this.props,
        defaultLayout: this.props.currentLayout,
      }
      let segmentedVideos = this.state.segmentedVideos
      let project
      if (segmentedVideos.length) {
        const croppedVideoLibrariesData = []
        for (var i = 0; i < segmentedVideos.length; i++) {
          const segVid = segmentedVideos[i]
          const { layProperties, isTrackSet } =
            this.getSegmentedSceneLayProperties(segVid)
          if (!isTrackSet) {
            const croppedVideoLibraryData = await this.uploadCropVideo(
              segVid._id,
              layProperties,
              false, // isTrackingVideo
              didTrackingVideo, // didTrackingVideo - user tried FaceTracking but has chosen to add original videos instead of tracked ones
              true,
            )
            croppedVideoLibrariesData.push(croppedVideoLibraryData)
          }
        }

        // Check media availaibity
        this.intervalRef.current = setInterval(() => {
          this.checkSegmentationCroppedAssetsAvailable(
            croppedVideoLibrariesData,
          )
        }, 5000)
      } else {
        const croppedVideoLibraryData = await this.uploadCropVideo(
          uploadVideoId,
          layoutProperties,
          false, // isTrackingVideo
          didTrackingVideo, // didTrackingVideo - user tried FaceTracking but has chosen to add original videos instead of tracked ones
        )
        project = await this.updateProjectFromCroppedVideo([
          croppedVideoLibraryData,
        ])
      }
      this.props.resetCropAreas() // saveLayout and aspectSize are reset to false and zeros so next time it doesn't use the previous settings
      return project
    } catch (error) {
      this.setState({ uploading: false })
      toast.error(`Error uploading cropped video: ${error.message}`)
    } finally {
      // toast.dismiss()
      // this.setState({
      //   uploading: false,
      // })
    }
  }

  getSegmentedSceneLayProperties = (segVid) => {
    const isTrackSet = _.find(this.props.isFaceTrackingEnabledSegmentations, {
      id: segVid._id,
    })?.isFaceTrackingEnabled
    const aspectSize = _.find(this.props.aspectSizeSegmentations, {
      id: segVid._id,
    })?.data
    const saveLayout = _.find(this.props.saveLayoutSegmentations, {
      id: segVid._id,
    })?.data
    const layoutProperties = {
      ...this.props,
      defaultLayout: saveLayout.findIndex((x) => x),
    }
    const layProperties = {
      ...layoutProperties,
      aspectSize,
      saveLayout,
    }
    return { layProperties, isTrackSet }
  }

  handleReceivedFaceTrackedScenes = async (data) => {
    const trackedScenes = data?.results
    let segmentedVideos = this.state.segmentedVideos
    try {
      if (
        segmentedVideos.length &&
        data.sessionId === TrackSessionsIds.SEGMENTATIONS
      ) {
        const croppedVideoLibrariesData = []
        for (var i = 0; i < segmentedVideos.length; i++) {
          const segVid = segmentedVideos[i]
          const { layProperties, isTrackSet } =
            this.getSegmentedSceneLayProperties(segVid)

          const sceneResult =
            isTrackSet &&
            trackedScenes.find((aspectResults) => {
              // const parseResult = JSON.parse(aspectResults)
              const parseResult = aspectResults
              const result = parseResult.result.find((result) =>
                result.includes(segVid.fileName),
              )
              return !!result && parseResult.errorcode === 0
            })

          const croppedVideoLibraryData = await this.uploadCropVideo(
            segVid._id,
            layProperties,
            !!sceneResult, // isTrackingVideo
            !!isTrackSet, // didTrackingVideo - user tried FaceTracking but has chosen to add original videos instead of tracked ones
            true,
          )
          croppedVideoLibrariesData.push(croppedVideoLibraryData)
        }

        // Check media availaibity
        this.intervalRef.current = setInterval(() => {
          this.checkSegmentationCroppedAssetsAvailable(
            croppedVideoLibrariesData,
          )
        }, 5000)
      } else if (data.sessionId === TrackSessionsIds.SINGLE) {
        // videoName
        const faceTrackingVideoUrls = trackedScenes.reduce(
          (acc, aspectResults) => {
            // const parseResult = JSON.parse(aspectResults)
            const parseResult = aspectResults
            const result0 = parseResult.result.find((result) => {
              return result.includes(`${this.state.videoName}_0.mp4`)
            })
            const result1 = parseResult.result.find((result) => {
              return result.includes(`${this.state.videoName}_1.mp4`)
            })
            const result2 = parseResult.result.find((result) => {
              return result.includes(`${this.state.videoName}_2.mp4`)
            })
            const result3 = parseResult.result.find((result) => {
              return result.includes(`${this.state.videoName}_3.mp4`)
            })
            if (parseResult.errorcode === 0) {
              if (result0) acc[0] = result0
              if (result1) acc[1] = result1
              if (result2) acc[2] = result2
              if (result3) acc[3] = result3
            }
            return acc
          },
          {},
        )

        const isEmpty =
          Object.keys(faceTrackingVideoUrls).length === 0 &&
          faceTrackingVideoUrls.constructor === Object
        if (isEmpty) {
          toast.error('Invalid tracked scenes!')
          return this.setState({ uploading: false })
        }

        const faceTrackingVideoBlobs =
          await VideoUploadService.getVideoBlobsByUrl(
            faceTrackingVideoUrls,
            null, // TODO: Remove after python changes made
          )
        this.props.setFaceTrackingVideoBlobs(faceTrackingVideoBlobs)
        this.props.setFaceTrackingVideos(faceTrackingVideoUrls)
        this.props.setFaceTrackingVideo(this.state.videoUrl) // TODO: Remove after python changes made
        // this.props.setFaceTrackingAudio(faceTrackingAudio)
        this.setState({ uploading: false })
      }
    } catch (error) {
      this.setState({ uploading: false })
      toast.error(`Error handling received tracked scene(s): ${error.message}`)
    }
  }

  openSocketConnection = async () => {
    SOCKET.on(SOCKET_EVENTS.receiveTrackedScenes, async (data) => {
      console.log('RECEIVED TRACKED SCENES', data)
      // Check user
      if (data?.userId === this.props.authUser.id) {
        await this.handleReceivedFaceTrackedScenes(data)
      }
    })
  }

  componentDidMount() {
    this.props.loadUserSubscriptions()
    this.props.loadSubscriptionProducts()
    this.props.getProductFeatures()
    this.setState({
      videoUrl: this.props.location.state?.videoUrl || '',
      videoId: this.props.location.state?.videoId || '',
      videoDuration: this.props.location.state?.videoDuration || 0,
      segmentedVideos: this.props.location.state?.segmentedVideos || [],
      cropperCurrentScene: {
        totalTime: this.props.location.state?.videoDuration * 1000,
        currTime: 0,
        scenePlaying: false,
      },
    })
    this.resetCropperSelectionsOnUpload(0)
    this.props.resetCropAreas()

    this.openSocketConnection()
  }

  componentWillUnmount() {
    this.props.setFaceTrackingVideos(null)
    this.props.setFaceTrackingVideoBlobs(null)
    this.props.setFaceTrackingVideo(null) // TODO: Remove after python changes made
    this.props.setFaceTrackingVideoBlob(null) // TODO: Remove after python changes made
    this.props.setFaceTrackingAudio(null)
    this.setState = () => {}

    SOCKET.off(SOCKET_EVENTS.receiveTrackedScenes)
  }

  handleFaceTrackingUploadStart = async () => {
    const { authUser, streamSize, aspectSize } = this.props
    if (!aspectRatioExist(this.props.aspectSize)) {
      triggerGoogleAnalyticsEvent('exception', {
        description:
          'Whoa there! Select your target social platform, then hit the "Add another aspect ratio" button once before pressing "Make"',
        userId: this.props.authUser?.id,
      })
      return toast.warning(
        'Whoa there! Select your target social platform, then hit the "Add another aspect ratio" button once before pressing "Make"',
      )
    }
    this.setState({ uploading: true })
    const filename = FileUtils.getFilename(this.state.videoUrl)
    const trackData = [
      {
        fileUrl: this.state.videoUrl,
        filename,
        aspectSizes: aspectSize,
        streamSize: streamSize[0],
        userId: authUser.id,
      },
    ]
    this.setState({ videoName: filename })
    await trackScenes(trackData, TrackSessionsIds.SINGLE)
    // const { videoCropTime, videoUrl } = this.state
    // const {
    //   currentLayout,
    //   direction,
    //   aspectSize,
    //   scaleValue,
    //   streamSize,
    //   authUser,
    // } = this.props

    // triggerGoogleAnalyticsEvent('make_tracked_video', {
    //   selectedRatios: this.state.selectedRatios,
    //   userId: authUser?.id,
    // })
    // this.uploadCropVideoToFaceTrack(
    //   videoUrl,
    //   currentLayout,
    //   scaleValue,
    //   direction,
    //   aspectSize,
    //   streamSize,
    //   videoCropTime,
    // )
  }

  handleUpdateVideoCropTime = (videoCropTime) => {
    this.setState({ videoCropTime: videoCropTime })
  }

  handleAddFacetrackedVideosToProject = async () => {
    try {
      const {
        faceTrackingRatio,
        faceTrackingVideoBlobs,
        faceTrackingVideos = {},
      } = this.props
      this.setState({ uploading: true })
      const aspectSizeIndices = Object.keys(faceTrackingVideos)
      const uploadVideoId = this.state?.videoId || ''

      const layoutProperties = {
        aspectSize: _.cloneDeep(this.state.aspectSize),
        aspectSizeIndices,
        streamSize: _.cloneDeep(this.state.streamSize),
        saveLayout: _.map(RATIO, (ratio) =>
          aspectSizeIndices.includes(ratio.toString()),
        ),
        defaultLayout: faceTrackingRatio,
        videoCropTime: this.state.videoCropTime,
      }

      const croppedVideoLibraryData = await this.uploadCropVideo(
        uploadVideoId,
        layoutProperties,
        true, // isTrackingVideo
        true, // didTrackingVideo
      )
      return this.updateProjectFromCroppedVideo([croppedVideoLibraryData])
    } catch (error) {
      this.setState({ uploading: false })
      console.debug('error:', error)
      toast.error(
        `Error while saving facetracked video to project: ${error.message}`,
      )
    } finally {
      // this.setState({
      //   uploading: false,
      // })
    }
    this.props.resetCropAreas() // saveLayout and aspectSize are reset to false and zeros so next time it doesn't use the previous settings
  }

  onUploadRecordVideo = async () => {
    const segmentations = this.state.segmentedVideos
    const { isFaceTrackingEnabledSegmentations } = this.props
    if (segmentations.length) {
      if (this.props.aspectSizeSegmentations.length !== segmentations.length) {
        return toast.warning('Select aspect ratios for segmentations')
      }
      const isTrackedAnyScene = isFaceTrackingEnabledSegmentations.some(
        (x) => x.isFaceTrackingEnabled,
      )
      if (isTrackedAnyScene) {
        return await this.handleTrackSegmentations()
      } else {
        return await this.handleUploadVideo()
      }
    }
    if (this.props.isFaceTrackingEnabled) {
      return this.handleFaceTrackingUploadStart()
    }
    return this.handleUploadVideo()
  }

  render() {
    const {
      isFaceTrackingEnabled,
      faceTrackingVideo,
      setScaleValue,
      scaleValue,
      authUser,
    } = this.props
    const { videoUrl, showSnap, uploading, layoutChangedFromDropDown } =
      this.state

    const isFaceTrackingPreviewEnabled =
      isFaceTrackingEnabled && faceTrackingVideo
    return (
      <div className="recorder-wrapper relative flex-row items-center justify-center flex-col overflow-hidden bg-black text-white box-border">
        <CropperTooltip
          changeCropperInstructionStep={this.changeCropperInstructionStep}
          cropperInstructionStep={this.state.cropperInstructionStep}
          isCropperInstructionStepActive={
            this.state.isCropperInstructionStepActive
          }
          closeCropperInstructionStep={this.closeCropperInstructionStep}
        />
        {/* <div class="tooltip-mask"></div> */}
        {!faceTrackingVideo && (
          <CropperPreview
            currentVideoId={this.state.videoId}
            ratioToEdit={this.state.ratioToEdit}
            updateVideoCropTime={this.handleUpdateVideoCropTime}
            videoUrl={videoUrl}
            scaleValue={scaleValue}
            showSnapGrid={showSnap}
            layoutChangedFromDropDown={layoutChangedFromDropDown}
            changeLayoutFromDropdown={this.changeLayoutFromDropdown}
            replayVideo={this.replayVideo}
            cropperActionWrapper={this.cropperActionWrapper}
            updateReplayVideoRef={this.updateReplayVideoRef}
            moveRef={this.moveRef}
            selNextPopup={this.state.selNextPopup}
            cropperScene={this.state.cropperCurrentScene}
            selectedRatio={this.state.selectedRatio}
            resetSelectedAspectRatios={this.resetSelectedAspectRatios}
            selectRatio={this.selectRatio}
            clearEditRatio={this.clearEditRatio}
          />
        )}
        {isFaceTrackingPreviewEnabled && (
          <CropperFacetrackPreview
            videoUrl={videoUrl}
            showSnapGrid={showSnap}
            onUploadOriginalVideo={this.handleUploadVideoFromFaceTrack}
            onUploadFacetrackedVideo={this.handleAddFacetrackedVideosToProject}
          />
        )}

        {/* {!isFaceTrackingPreviewEnabled && (
          <CropperButtons
            onFaceTrackingUploadStart={this.handleFaceTrackingUploadStart}
            onUploadRecordVideo={this.handleUploadVideo}
            changeLayoutFromDropdown={this.changeLayoutFromDropdown}
          />
        )} */}
        {/* {isFaceTrackingPreviewEnabled && (
          <CropperUploadButtons
            onUploadOriginalVideo={this.handleUploadVideoFromFaceTrack}
            onUploadFacetrackedVideo={this.handleAddFacetrackedVideosToProject}
          />
        )} */}
        {/* <ShootAction
          setChangeRatio={this.setChangeRatio}
          setScaleValue={setScaleValue}
        /> */}
        <CropperAspectRatioSelection
          ratioToEdit={this.state.ratioToEdit}
          clearEditRatio={this.clearEditRatio}
          segmentedVideos={this.state.segmentedVideos}
          selectRatio={this.selectRatio}
          changeLayoutFromDropdown={this.changeLayoutFromDropdown}
          cameraVideo={this.replayVideo}
          videoActionWrapper={this.cropperActionWrapper}
          moveRef={this.moveRef}
          resetSelectedAspectRatios={this.resetSelectedAspectRatios}
          updateSelectedPlatform={this.updateSelectedPlatform}
          updateSelectedRatio={this.updateSelectedRatio}
          selectedPlatform={this.state.selectedPlatform}
          selectedRatio={this.state.selectedRatio}
          isFaceTrackingPreviewEnabled={isFaceTrackingPreviewEnabled}
          selectedPlatforms={this.state.selectedPlatforms}
          selectedRatios={this.state.selectedRatios}
          playCropperScene={this.playCropperScene.bind(this)}
          cropperScene={this.state.cropperCurrentScene}
          setSceneTime={this.setSceneTime.bind(this)}
          currentVideoId={this.state.videoId}
          // segmentedVideos={this.state.segmentedVideos}
          // openFullSegmentationsTab={this.state.openFullSegmentationsTab}
          // segmentedVideos={this.state.segmentedVideos}
          classNames={
            this.state.openFullSegmentationsTab
              ? 'bottom-270'
              : this.state.segmentedVideos.length
              ? 'bottom-150'
              : ''
          }
        />
        <CropperTopButtons
          wantToEditRatio={this.wantToEditRatio}
          clearEditRatio={this.clearEditRatio}
          ratioToEdit={this.state.ratioToEdit}
          segmentedVideos={this.state.segmentedVideos}
          removeRatio={this.removeRatio.bind(this)}
          selectRatio={this.selectRatio}
          changeLayoutFromDropdown={this.changeLayoutFromDropdown}
          cameraVideo={this.replayVideo}
          videoActionWrapper={this.cropperActionWrapper}
          moveRef={this.moveRef}
          resetSelectedAspectRatios={this.resetSelectedAspectRatios}
          selectedPlatform={this.state.selectedPlatform}
          selectedRatio={this.state.selectedRatio}
          selectedRatios={this.state.selectedRatios}
          updateSelectedRatio={this.updateSelectedRatio}
          // onUploadRecordVideo={this.handleUploadVideo}
          onUploadRecordVideo={this.onUploadRecordVideo}
          retriggerCropperTooltip={this.retriggerCropperTooltip}
          isFaceTrackingPreviewEnabled={isFaceTrackingPreviewEnabled}
          currentVideoId={this.state.videoId}
          resetApplySelectedRatios={this.resetApplySelectedRatios}
          notShownApplySelectedRatiosModal={
            this.state.notShownApplySelectedRatiosModal
          }
        />
        {this.state.segmentedVideos.length && (
          <CropperScenes
            cropperVideos={this.state.segmentedVideos}
            setActiveSegmentedScene={this.setActiveSegmentedScene.bind(this)}
            toggleFullSegmentationsTab={this.toggleFullSegmentationsTab.bind(
              this,
            )}
            openFullSegmentationsTab={this.state.openFullSegmentationsTab}
            toggleApplySelectRatiosModal={this.toggleApplySelectRatiosModal.bind(
              this,
            )}
          />
        )}
        {uploading && <TextSpinner />}

        <Modal
          size="xl"
          scrollBehavior="outside"
          blockScrollOnMount={false}
          isOpen={this.state.applySelectedRatiosModal}
          // isOpen={true}
          onClose={this.toggleApplySelectRatiosModal}>
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton />
            <ModalBody>
              <div className="apply-selected-ratios-modal">
                <img src={ApplySelectedRatiosImg} alt="apply-selected-ratios" />
                <h3 className="modal-title">Apply selected ratios</h3>
                <p>
                  Do you wish to apply the selected ratios to all the screens?
                </p>
                <p>
                  Applying these selections will override all previous scenes
                  selected ratios.
                </p>

                <div className="apply-selected-ratios-modal__selections">
                  <div className="social-platforms">
                    {/* {AspectRatiosPlatforms.map((platform, index) => { */}
                    {this.state.selectedPlatforms.map(
                      (selectedPlatform, index) => {
                        const platform = AspectRatiosPlatforms.find(
                          (x) => x.id === selectedPlatform.platform,
                        )
                        return (
                          <div
                            key={index}
                            className={`social-platform ${platform.name}`}>
                            {' '}
                            {platform.renderIcon()}
                          </div>
                        )
                      },
                    )}
                  </div>
                  <div className="ratios">
                    {this.state.selectedRatios
                      .filter((x) => x)
                      .map((_, index) => {
                        const ratio = RATIOS[index]
                        return (
                          <div className={`ratio ${ratio.name}`} key={index}>
                            <span>{ratio.ratio}</span>
                          </div>
                        )
                      })}
                  </div>
                </div>

                <div className="apply-selected-ratios-modal__btns">
                  <button
                    className="btn btn-default cancel"
                    onClick={this.toggleApplySelectRatiosModal}>
                    Cancel
                  </button>
                  <button
                    className="btn btn-default apply"
                    onClick={this.applyCurrentRatioSelectionsToSegementations}>
                    Yes, Apply
                  </button>
                </div>
              </div>
            </ModalBody>
          </ModalContent>
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    aspectSize: state.recorder.aspectSize,
    availGridSizes: state.recorder.availGridSizes,
    authUser: state.auth.user,
    currentLayout: state.videoCrop.currentLayout,
    direction: state.recorder.direction,
    isFaceTrackingEnabled: state.faceTrack.isFaceTrackingEnabled,
    isFaceTrackingEnabledSegmentations:
      state.faceTrack.isFaceTrackingEnabledSegmentations,
    faceTrackingRatio: state.faceTrack.faceTrackingRatio,
    faceTrackingVideos: state.faceTrack.faceTrackingVideos,
    faceTrackingVideoBlobs: state.faceTrack.faceTrackingVideoBlobs,
    faceTrackingVideo: state.faceTrack.faceTrackingVideo, // TODO: Remove after python changes made
    faceTrackingVideoBlob: state.faceTrack.faceTrackingVideoBlob, // TODO: Remove after python changes made
    faceTrackingAudio: state.faceTrack.faceTrackingAudio, // missed state
    resolution: state.recorder.resolution,
    saveLayout: state.recorder.saveLayout,
    scaleValue: state.videoCrop.scaleValue,
    snapGridPosition: state.recorder.snapGridPosition,
    saveLayoutSegmentations: state.recorder.saveLayoutSegmentations,
    aspectSizeSegmentations: state.recorder.aspectSizeSegmentations,
    streamSize: state.recorder.streamSize,
    project: state.editor.project,
    mySubscription: state.subscriptions.user,
    productFeatures: state.subscriptions.features,
    products: state.subscriptions.products,
  }
}

const mapDispatchToProps = (dispatch) => ({
  setFaceTrackingVideos: (...args) =>
    dispatch(FaceTrackActions.setFaceTrackingVideos(...args)),
  setFaceTrackingVideoBlobs: (...args) =>
    dispatch(FaceTrackActions.setFaceTrackingVideoBlobs(...args)),
  setFaceTrackingVideo: (...args) =>
    dispatch(FaceTrackActions.setFaceTrackingVideo(...args)), // TODO: Remove after python changes made
  setFaceTrackingVideoBlob: (...args) =>
    dispatch(FaceTrackActions.setFaceTrackingVideoBlob(...args)), // TODO: Remove after python changes made
  setFaceTrackingAudio: (...args) =>
    dispatch(FaceTrackActions.setFaceTrackingAudio(...args)),
  updateProject: (...args) => dispatch(EditorActions.updateProject(...args)),
  setScaleValue: (...args) => dispatch(VideoCropActions.setScaleValue(...args)),
  resetCropAreas: (...args) => dispatch(resetCropAreas(...args)),
  changeAspectRatio: (...args) =>
    dispatch(MediaPlayerActions.changeAspectRatio(...args)),
  resetAllSelected: (...any) =>
    dispatch(SelectedItemActions.resetAllSelected(...any)),
  setCurrentLayout: (...args) =>
    dispatch(VideoCropActions.setCurrentLayout(...args)),
  setFaceTrackingRatio: (...args) =>
    dispatch(FaceTrackActions.setFaceTrackingRatio(...args)),
  applySetFaceTrackingSegmentations: (...args) =>
    dispatch(FaceTrackActions.applySetFaceTrackingSegmentations(...args)),
  setSelectedMediaLayout: (...args) =>
    dispatch(RecorderActions.setSelectedMediaLayout(...args)),
  applyRatioSelectionsToSegmentations: (...args) =>
    dispatch(RecorderActions.applyRatioSelectionsToSegmentations(...args)),
  setActiveSegmentationScene: (...args) =>
    dispatch(RecorderActions.setActiveSegmentationScene(...args)),
  loadUserSubscriptions: () =>
    dispatch(SubscriptionActions.currentUserSubscription()),
  loadSubscriptionProducts: () =>
    dispatch(SubscriptionActions.getAllSubscriptionProducts()),
  getProductFeatures: () => dispatch(SubscriptionActions.getProductFeatures()),
})
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Cropper))
