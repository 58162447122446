import React from 'react'
import _ from 'lodash'
import { gsap } from 'gsap'
import DurationGetter from '../../services/time/DurationGetter'
import { ONE_SECOND } from '../../constants/Timeline'

export const TICK_IN_MILLISECONDS = 100

export const TimeTickerContext = React.createContext()

class TimeTickerContextProvider extends React.Component {
  state = {
    isBuffering: false,
    isPlaying: false,
    currentTime: 0,
    tickInMilliseconds: 0,
    timeTickListener: () => true,
  }

  handleTickViaGsap = (time, deltaTime) => {
    const currentTime = _.round(time * ONE_SECOND, -2)
    const roundedDeltaTime = _.round(deltaTime, -1)

    if (this.state.isPlaying) {
      this.tickTimer(currentTime, roundedDeltaTime)
    }
  }

  setTimer() {
    const { project } = this.props
    if (!this.timer) {
      this.timer = gsap.ticker
      const DEFAULT_FPS = 30
      this.timer.fps(project.fps || DEFAULT_FPS)
      this.timer.lagSmoothing(1000, 16)
      this.timer.add(this.handleTickViaGsap)
    }
  }

  clearTimer() {
    this.timer && this.timer.remove(this.handleTickViaGsap)
    this.setState({ tickInMilliseconds: 0 })
    this.timer = null
  }

  attachTimeTickListener(timeTickListener) {
    this.setState({ timeTickListener })
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.curSec !== this.props.curSec) {
      this.setState({ currentTime: this.props.curSec })
    }
    const isModalEnabled =
      !prevProps.isVoiceoverModalDisplayed &&
      this.props.isVoiceoverModalDisplayed
    if (isModalEnabled) {
      const voiceoverDuration = DurationGetter.getMaxDurationByAssets(
        this.props.project.voiceover,
      )
      this.setState({
        currentTime: voiceoverDuration,
        project: this.props.project,
      })
    }
  }

  tickTimer = (globalCurrentTime, deltaTime) => {
    const {
      currentTime,
      isBuffering,
      isPlaying,
      timeTickListener,
      tickInMilliseconds,
    } = this.state
    const newCurrentTime =
      tickInMilliseconds <= 0 ? currentTime : currentTime + deltaTime
    const isTickValid = timeTickListener(currentTime)

    if (isBuffering && isPlaying) {
      return
    }

    if (isPlaying && isTickValid) {
      this.setState({
        currentTime: newCurrentTime,
        tickInMilliseconds: deltaTime,
      })
    } else {
      this.setState({ isPlaying: false }, () => {
        this.clearTimer()
      })
    }
  }

  changeCurrentTime = (newCurrentTime) => {
    this.setState({ isPlaying: false }, () => {
      this.setState({ currentTime: newCurrentTime })
    })
  }

  restartTimer = () => {
    this.setState({ isPlaying: false, currentTime: 0 })
  }

  startPlaying = () => {
    this.setState({ isPlaying: true }, () => {
      this.setTimer()
    })
    return this.state.currentTime
  }

  stopPlaying = () => {
    const { currentTime } = this.state
    this.setState({ isPlaying: false }, () => this.clearTimer())
    return currentTime
  }

  setBuffering = (isBuffering = true) => {
    this.setState({ isBuffering })
  }

  render() {
    return (
      <TimeTickerContext.Provider
        value={{
          ...this.state,
          startPlaying: this.startPlaying.bind(this),
          stopPlaying: this.stopPlaying.bind(this),
          changeCurrentTime: this.changeCurrentTime.bind(this),
          restartTimer: this.restartTimer.bind(this),
          attachTimeTickListener: this.attachTimeTickListener.bind(this),
          setBuffering: this.setBuffering.bind(this),
        }}>
        {this.props.children}
      </TimeTickerContext.Provider>
    )
  }
}

export default TimeTickerContextProvider
