export const InfoIcon = (props) => {
  return (
    <svg
      {...props}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_187_11435)">
        <circle opacity="0.5" cx="16" cy="16" r="16" fill="black" />
        <path
          d="M16 32C12.8355 32 9.74207 31.0616 7.11088 29.3035C4.4797 27.5454 2.42894 25.0466 1.21793 22.1229C0.00693258 19.1993 -0.309921 15.9823 0.307443 12.8786C0.924806 9.77486 2.44866 6.92393 4.6863 4.68629C6.92394 2.44865 9.77487 0.924801 12.8786 0.307437C15.9823 -0.309926 19.1993 0.00692721 22.1229 1.21793C25.0466 2.42893 27.5454 4.47969 29.3035 7.11088C31.0616 9.74206 32 12.8355 32 16C31.9954 20.2421 30.3082 24.3091 27.3087 27.3086C24.3091 30.3082 20.2421 31.9954 16 32ZM16 2.66667C13.3629 2.66667 10.7851 3.44866 8.5924 4.91374C6.39975 6.37883 4.69078 8.46121 3.68161 10.8976C2.67245 13.3339 2.4084 16.0148 2.92287 18.6012C3.43734 21.1876 4.70722 23.5634 6.57192 25.4281C8.43662 27.2928 10.8124 28.5627 13.3988 29.0771C15.9852 29.5916 18.6661 29.3276 21.1025 28.3184C23.5388 27.3092 25.6212 25.6003 27.0863 23.4076C28.5514 21.215 29.3333 18.6371 29.3333 16C29.3295 12.465 27.9235 9.07584 25.4238 6.5762C22.9242 4.07656 19.535 2.67055 16 2.66667Z"
          fill="white"
        />
        <path
          d="M18.6663 25.3337H15.9997V16.0004H13.333V13.3337H15.9997C16.7069 13.3337 17.3852 13.6147 17.8853 14.1148C18.3854 14.6149 18.6663 15.2932 18.6663 16.0004V25.3337Z"
          fill="white"
        />
        <path
          d="M16 10.6662C17.1046 10.6662 18 9.77082 18 8.66625C18 7.56168 17.1046 6.66625 16 6.66625C14.8954 6.66625 14 7.56168 14 8.66625C14 9.77082 14.8954 10.6662 16 10.6662Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_187_11435">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
