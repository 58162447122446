import React from 'react'
import Moveable from 'react-moveable'
import _ from 'lodash'
import { connect } from 'react-redux'
import { storeProjectSteps } from '../../redux/actions/EditorActions'
import { AUDIO, VIDEO } from '../../constants/Channels'
import TimelineService from '../../services/time/TimelineService'

const ORIGINAL_FRAME = {
  translate: [0, 0],
}

class TimelineMoveable extends React.Component {
  constructor(props) {
    super(props)

    this.intervalRef = React.createRef()
  }
  state = {
    frame: _.cloneDeep(ORIGINAL_FRAME),
  }

  moveable = React.createRef()
  autoMoveItemRef = React.createRef()
  autoMoveItemLeftRef = React.createRef()
  moveItemTranslateLeft = React.createRef()

  scrollTimelinePanel = (
    timelinePanel,
    currentTime,
    previousTime,
    scrollbar,
  ) => {
    const currentTimePixels =
      TimelineService.convertMillisecondsToPixels(currentTime)
    const computedTimePanelWidth =
      timelinePanel?.current?.getBoundingClientRect()?.width
    const isCurrenTiimeChanged = currentTime !== previousTime
    const isBeyondRightViewportWhenPaused =
      currentTimePixels >= (3 / 4) * computedTimePanelWidth

    if (
      isCurrenTiimeChanged &&
      computedTimePanelWidth &&
      isBeyondRightViewportWhenPaused
    ) {
      scrollbar.current._container.scrollLeft =
        currentTimePixels - (3 / 4) * computedTimePanelWidth
    }

    if (currentTimePixels < (3 / 4) * computedTimePanelWidth) {
      scrollbar.current._container.scrollLeft = 0
    }
  }

  // Clear auto move item when at the extreme end of the display
  clearAutoMoveItemIntervals = () => {
    clearInterval(this.autoMoveItemRef.current)
    this.autoMoveItemRef.current = null
    clearInterval(this.autoMoveItemLeftRef.current)
    this.autoMoveItemLeftRef.current = null
  }

  handleDrag = (dragEvent) => {
    const AUTO_PX = 8
    const AUTO_MOVE_OFFSET = 50
    const { target, translate, left } = dragEvent
    const { scrollableTimelineBox, scrollbarRef, onDrag, zoomValue } =
      this.props
    let leftPxSec
    if (
      dragEvent.clientX > window.innerWidth - AUTO_MOVE_OFFSET ||
      dragEvent.clientX < AUTO_MOVE_OFFSET
    ) {
      if (!this.autoMoveItemRef.current) {
        this.autoMoveItemRef.current = setInterval(() => {
          // Hide drag trace on auto scropping and item movement
          this.toggleMoveableVisibilityOnDrag('hidden')
          const moveItemTranslateLeft = this.moveItemTranslateLeft.current
          let newLeft = this.autoMoveItemLeftRef.current
          if (!newLeft) {
            newLeft = left
          }
          leftPxSec = TimelineService.convertPixelToSeconds(newLeft)

          target.style.transform = `translate(${moveItemTranslateLeft}px, 0px)`
          this.setState({
            beforeTranslate: [moveItemTranslateLeft, 0, 0],
          })

          this.moveItemTranslateLeft.current =
            newLeft < 0
              ? moveItemTranslateLeft
              : dragEvent.clientX < AUTO_MOVE_OFFSET
              ? moveItemTranslateLeft - AUTO_PX
              : moveItemTranslateLeft + AUTO_PX
          this.autoMoveItemLeftRef.current =
            dragEvent.clientX < AUTO_MOVE_OFFSET
              ? newLeft - AUTO_PX
              : newLeft + AUTO_PX

          onDrag && onDrag()
          this.scrollTimelinePanel(
            scrollableTimelineBox,
            leftPxSec,
            leftPxSec - 1,
            scrollbarRef,
          )
        }, 100)
      }
    } else {
      this.toggleMoveableVisibilityOnDrag('visible')
      this.clearAutoMoveItemIntervals()

      leftPxSec = TimelineService.convertPixelToSeconds(left)

      const transLeft =
        zoomValue === 1
          ? translate[0]
          : translate[0] +
            translate[0] * (1 - zoomValue) * (4 * (1 - zoomValue))
      const newTranslate =
        zoomValue === 1 ? translate : [transLeft, translate[1]]

      target.style.transform = `translate(${transLeft}px, 0px)`
      this.setState({ beforeTranslate: newTranslate })
      onDrag && onDrag()
      this.scrollTimelinePanel(
        scrollableTimelineBox,
        leftPxSec,
        leftPxSec - 1,
        scrollbarRef,
      )
      this.moveItemTranslateLeft.current = transLeft
    }
  }

  handleResize = (resizeEvent) => {
    const { onResize } = this.props
    onResize && onResize(resizeEvent.delta[0], resizeEvent.width)
  }

  handleDragEnd = (dragEvent) => {
    const { target } = dragEvent
    this.toggleMoveableVisibilityOnDrag('visible')
    const {
      channel,
      timelineItem,
      storeProjectSteps,
      selectedSound,
      onDragEnd,
    } = this.props
    const beforeTranslate = this.state.beforeTranslate

    // Clear auto move timeline interval when done dragging
    this.clearAutoMoveItemIntervals()

    const { frame } = this.state
    if (beforeTranslate) {
      frame.translate = beforeTranslate
      this.setState(
        { frame: _.cloneDeep(ORIGINAL_FRAME), beforeTranslate: null },
        () => {
          target.style.transform = 'translate(0px, 0px)'
        },
      )
      onDragEnd && onDragEnd(frame.translate[0], dragEvent)

      if (frame.translate[0] !== 0) {
        const items = [
          {
            start_time: timelineItem.start_time,
            end_time: timelineItem.end_time,
            sliderL: timelineItem.sliderL,
            id: timelineItem.id,
          },
        ]
        const checkedChannels = [channel]
        if (channel === VIDEO.code) {
          if (selectedSound) {
            checkedChannels.push(AUDIO.path)
            items.push({
              start_time: selectedSound.start_time,
              end_time: selectedSound.end_time,
              sliderL: selectedSound.sliderL,
              id: selectedSound.id,
            })
          }
        }
        storeProjectSteps({
          channel: checkedChannels,
          type: 'UPDATE',
          updateType: 'position',
          payload: items,
        })
      }
    }
  }

  handleDragStart = (e) => {
    const { frame } = this.state
    e.set(frame.translate)
    this.setState({ beforeTranslate: frame.translate })
    const { onDragStart } = this.props
    onDragStart && onDragStart(e)
  }

  toggleMoveableVisibilityOnDrag = (visiblity) => {
    const getMoveables = document.getElementsByClassName('moveable-control-box')
    for (let i = 0; i < getMoveables.length; i++) {
      const moveable = getMoveables[i]
      moveable.style.visibility = visiblity
    }
  }

  render() {
    const {
      onDragEnd,
      onDrag,
      onDragStart,
      onResize,
      verticalGuidelines,
      ...other
    } = this.props
    return (
      <Moveable
        ref={this.moveable}
        onDrag={this.handleDrag.bind(this)}
        onDragStart={this.handleDragStart.bind(this)}
        renderDirections={['e']}
        onDragEnd={this.handleDragEnd.bind(this)}
        onResize={this.handleResize.bind(this)}
        {...other}
        snappable={true}
        bounds={{ left: 0, top: 0, bottom: 0 }}
        verticalGuidelines={verticalGuidelines}
        snapVertical={true}
        snapContainer={this.props.container}
        snapThreshold={5}
        isDisplaySnapDigit={true}
        snapGap={true}
        snapElement={false}
        snapHorizontal={false}
        snapCenter={false}
        snapDigit={0}
      />
    )
  }
}

const mapStateToProps = (state) => ({
  timelinePanel: state.mainContainer?.timelinePanel,
  selectedSound: state.selectedItems.selectedSound,
})
const mapDispatchToProps = (dispatch) => ({
  storeProjectSteps: (...any) => dispatch(storeProjectSteps(...any)),
})

export default connect(mapStateToProps, mapDispatchToProps)(TimelineMoveable)
