import { SET_USER_PROFILE } from '../../redux/actions/Types'

export const actionCreators = {
  setUserProfile: (profile) => ({ type: SET_USER_PROFILE, profile: profile }),
}

const initialState = { user: null }

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_USER_PROFILE:
      return {
        ...state,
        user: action.profile,
      }
    default:
      return state
  }
}
