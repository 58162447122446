import React from 'react'
import _ from 'lodash'
import TimelineHeader from '../video-editor/desktop-items/timeline-items/TimelineHeader'
import { ONE_MINUTE, ONE_SECOND } from '../../constants/Timeline'
import VoiceTimelineRangeSlider from './VoiceTimelineRangeSlider'
import TimelineService from '../../services/time/TimelineService'
import VoiceRecordTimelineItem from './VoiceRecordTimelineItem'
import { VOICEOVER } from '../../constants/Channels'

const TIMELINE_HEIGHT = 55

export class VoiceRecorderTimeline extends React.Component {
  state = {
    isRecording: false,
  }

  timelinePanel = React.createRef()
  timelineContainer = React.createRef()
  timelineSecsRef = React.createRef()

  componentDidMount() {
    this.timelineSecsRef.current = this.props.project.duration + ONE_MINUTE
  }

  componentDidUpdate(prevProps) {
    const currentTimePixels = TimelineService.convertMillisecondsToPixels(
      this.props.currentTime,
    )
    const computedTimePanelWidth =
      this.timelinePanel?.current?.getBoundingClientRect()?.width
    const computedScrollLeft = this.timelinePanel?.current?.scrollLeft
    const isCurrenTiimeChanged =
      this.props.currentTime !== prevProps.currentTime
    const isBeyondViewportWhenPlaying =
      currentTimePixels >= computedTimePanelWidth / 2
    const isBeyondRightViewportWhenPaused =
      (currentTimePixels - computedScrollLeft) % computedTimePanelWidth >=
      (3 / 4) * computedTimePanelWidth
    const isBeyondLeftViewportWhenPaused =
      (currentTimePixels - computedScrollLeft) % computedTimePanelWidth <
      (1 / 4) * computedTimePanelWidth
    const isShiftingToRight = this.props.currentTime > prevProps.currentTime

    if (
      this.props.isPlaying &&
      isCurrenTiimeChanged &&
      computedTimePanelWidth &&
      isBeyondViewportWhenPlaying
    ) {
      this.timelinePanel.current.scrollLeft =
        currentTimePixels - computedTimePanelWidth / 2
    }

    if (
      this.props.isPlaying &&
      isCurrenTiimeChanged &&
      computedTimePanelWidth &&
      !isBeyondViewportWhenPlaying
    ) {
      this.timelinePanel.current.scrollLeft = 0
    }

    if (
      !this.props.isPlaying &&
      isCurrenTiimeChanged &&
      computedTimePanelWidth &&
      isBeyondRightViewportWhenPaused &&
      isShiftingToRight
    ) {
      this.timelinePanel.current.scrollLeft =
        currentTimePixels - (3 / 4) * computedTimePanelWidth
    }

    if (
      !this.props.isPlaying &&
      isCurrenTiimeChanged &&
      computedTimePanelWidth &&
      isBeyondLeftViewportWhenPaused &&
      !isShiftingToRight
    ) {
      this.timelinePanel.current.scrollLeft =
        currentTimePixels - (1 / 4) * computedTimePanelWidth
    }
  }

  handleStartRecording = () => {
    this.setState({ isRecording: true })
  }

  handleStopRecording = () => {
    this.setState({ isRecording: false })
  }

  handleChangeCurrentTime = (newCurrentTime) => {
    const { onChangeCurrentTime } = this.props
    onChangeCurrentTime && onChangeCurrentTime(newCurrentTime)
  }

  getSecondsMarkers(milliseconds) {
    const seconds = milliseconds / ONE_SECOND
    return _.times(seconds, (n) => n)
  }

  getAvailableMilliseconds(durationMilliseconds) {
    const numberOfMinutesToDisplay =
      (_.ceil(durationMilliseconds / ONE_MINUTE) || 1) * ONE_MINUTE
    const availableMilliseconds =
      durationMilliseconds < ONE_MINUTE ? ONE_MINUTE : numberOfMinutesToDisplay
    return availableMilliseconds
  }

  getVerticalGuidelines() {
    const { project } = this.props
    const verticalGuidelines = _.uniq(
      _.flatMap(project[VOICEOVER.projectPath], (item) => [
        item.start_time,
        item.end_time,
      ]),
    )
    return verticalGuidelines
  }

  render() {
    const {
      project,
      currentTime,
      currentRecordId,
      isPlaying,
      isRecording,
      currentRecordItem,
    } = this.props
    const timelineSecs = this.timelineSecsRef.current
    const projectDurationPixels = TimelineService.convertMillisecondsToPixels(
      project.duration,
    )
    let displayableMilliseconds =
      !isPlaying || isRecording ? timelineSecs : project.duration
    // Add more time voice over recording time scale when 10 seconds away from the maximum displayed time
    if (
      (!isPlaying || isRecording) &&
      currentTime + ONE_SECOND * 10 > displayableMilliseconds
    ) {
      displayableMilliseconds = displayableMilliseconds + ONE_MINUTE
      this.timelineSecsRef.current = displayableMilliseconds
    }
    const displayablePixels = TimelineService.convertMillisecondsToPixels(
      displayableMilliseconds,
    )
    const secondsMarkers = this.getSecondsMarkers(displayableMilliseconds)
    const displayableItems = isRecording
      ? [...project.voiceover, currentRecordItem]
      : project.voiceover
    return (
      <div
        className="flex flex-col items-start justify-start relative"
        ref={this.timelinePanel}
        style={{ overflowX: 'auto', width: '100%', scrollbarWidth: 'none' }}>
        <TimelineHeader
          timeLength={secondsMarkers}
          timelineMarkers={secondsMarkers}
        />
        <VoiceTimelineRangeSlider
          currentTime={currentTime}
          verticalGuidelines={
            !isRecording && !isPlaying ? this.getVerticalGuidelines() : []
          }
          onChangeCurrentTime={this.handleChangeCurrentTime}
          duration={
            isPlaying && !isRecording
              ? project.duration
              : displayableMilliseconds
          }
          tickInMilliseconds={this.props.tickInMilliseconds}
        />
        <div
          ref={this.timelineContainer}
          className="flex flex-row justify-start relative"
          style={{
            height: `${TIMELINE_HEIGHT}px`,
            backgroundColor: 'lightgray',
            width: displayablePixels,
          }}>
          <div
            className="absolute h-full top-0 left-0"
            style={{ width: projectDurationPixels, backgroundColor: '#E5E7EB' }}
          />
          {_.map(displayableItems, (item, index) => {
            return (
              <VoiceRecordTimelineItem
                isRecording={isRecording}
                key={`vt-${index}`}
                currentTime={currentTime}
                currentRecordId={currentRecordId}
                itemTimelineHTML={this.timelineContainer.current}
                height={TIMELINE_HEIGHT}
                item={item}
              />
            )
          })}
        </div>
      </div>
    )
  }
}
