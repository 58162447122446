export const PresentationIcon = () => {
  return (
    <svg
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_283_1187)">
        <path
          d="M25.8456 28.121H17.8456V29.8231H22.1789C22.5325 29.8231 22.8717 29.9665 23.1217 30.2219C23.3718 30.4773 23.5122 30.8236 23.5122 31.1847C23.5122 31.5459 23.3718 31.8922 23.1217 32.1476C22.8717 32.4029 22.5325 32.5464 22.1789 32.5464H10.8456C10.492 32.5464 10.1528 32.4029 9.90276 32.1476C9.65272 31.8922 9.51224 31.5459 9.51224 31.1847C9.51224 30.8236 9.65272 30.4773 9.90276 30.2219C10.1528 29.9665 10.492 29.8231 10.8456 29.8231H15.1789V28.121H7.17891C5.64263 28.119 4.154 27.5762 2.96391 26.584C1.77381 25.5919 0.955034 24.2111 0.645573 22.6743H32.3789C32.0694 24.2111 31.2507 25.5919 30.0606 26.584C28.8705 27.5762 27.3818 28.119 25.8456 28.121Z"
          fill="#4B5563"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25.846 0.547119H7.17936C5.4119 0.549281 3.71744 1.26728 2.46765 2.54362C1.21787 3.81997 0.514812 5.55044 0.512695 7.35546V20.9721H32.5127V7.35546C32.5106 5.55044 31.8075 3.81997 30.5577 2.54362C29.308 1.26728 27.6135 0.549281 25.846 0.547119ZM15.8795 15.1552L21.1221 11.9126C21.5126 11.522 21.5126 10.8889 21.1221 10.4983L15.8795 7.2557C15.2495 6.62573 14.1724 7.0719 14.1724 7.9628V11.2054V14.4481C14.1724 15.339 15.2495 15.7852 15.8795 15.1552Z"
          fill="#4B5563"
        />
      </g>
      <defs>
        <clipPath id="clip0_283_1187">
          <rect
            width="32"
            height="32"
            fill="white"
            transform="translate(0.512695 0.547119)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
