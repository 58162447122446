import BillingCycle from './BillingCycle'
import RegisterUserForm from './RegisterUserForm'

const OrderSummary = ({
  activeCycle,
  changeActiveCycle,
  activeProduct,
  changeActive,
  currentPlan,
  paymentTypeText,
  show,
  setRegisterFormData,
}) => {
  return (
    <>
      <div
        className="cerebriam-order cerebriam-container"
        style={{ display: show ? 'block' : 'none' }}>
        <h2>Order Summary</h2>
        <p>
          {activeProduct === 1
            ? paymentTypeText.productOne
            : activeProduct === 2
            ? paymentTypeText.productTwo
            : ''}
          {activeCycle === 1
            ? paymentTypeText.cycleOne
            : activeCycle === 2
            ? paymentTypeText.cycleTwo
            : activeCycle === 3
            ? paymentTypeText.cycleThree
            : ''}{' '}
          (
          {activeCycle === 1
            ? currentPlan?.price_annually
            : activeCycle === 2
            ? currentPlan?.price_monthly
            : activeCycle === 3
            ? currentPlan?.price_pay_as_you_go
            : ''}
          )
        </p>
      </div>
      <div
        className="cerebriam-details cerebriam-container"
        style={{ display: show ? 'block' : 'none' }}>
        <div className="cerebriam-row">
          <RegisterUserForm
            activeCycle={activeCycle}
            currentPlan={currentPlan}
            setRegisterFormData={setRegisterFormData}
            changeActive={changeActive}
          />

          <BillingCycle
            activeCycle={activeCycle}
            changeActiveCycle={changeActiveCycle}
            plan={currentPlan}
          />
        </div>
      </div>
    </>
  )
}

export default OrderSummary
