import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import AuthFooter from '../../components/auth/AuthFooter'
//import './App.css';
import videoSource from '../../assets/video/Cerebriam_Studio_SnapGrid_Aspect_Ratio_Demo.mp4'
import { SignInForm } from '../../components/auth/SignIn'
import { SIGN_IN, SIGN_UP } from '../../constants/Routes'
import { setCoupon } from '../../redux/actions/AuthActions'

const BackgroundVideo = () => {
  return (
    <div className="">
      <video autoPlay="autoplay" loop="loop" muted={true} className="">
        <source src={videoSource} type="video/mp4"></source>
      </video>
    </div>
  )
}
class CouponPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      coupon: null,
      isCoupon: true,
    }
  }
  componentDidMount() {
    const couponCode = this.props.match.params.coupon
    this.props.setCoupon(couponCode)
  }

  render() {
    return (
      <>
        <div className="home landing container mx-auto">
          <div className="masthead py-12">
            <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3 xl:grid-cols-3">
              <div className="site-logo flex justify-center sm:flex justify-center md:flex justify-center lg:flex justify-end">
                <a
                  href="https://web.cerebriam.com/"
                  className="site-logo-link"
                  rel="home"
                  itemProp="url">
                  <img
                    width="300"
                    height="96"
                    src="https://web.cerebriam.com/wp-content/uploads/2020/03/Cerebriam_Studio_Logo_rgb2_300.jpg"
                    className="site-logo attachment-dicot-site-logo"
                    alt=""
                    data-size="dicot-site-logo"
                    itemProp="logo"
                  />
                </a>
              </div>
              <div
                className="flex justify-center uppercase text-xl font-normal items-center "
                style={{ fontFamily: 'Oswald,sans-serif', display: 'none' }}>
                <span className="px-4 hover:text-green-700 cursor-pointer">
                  <a href="https://web.cerebriam.com/blog/">BLOG</a>
                </span>
                <span className="px-4 hover:text-green-700 cursor-pointer">
                  <a href="https://web.cerebriam.com/pro-social-media-video-content-creation/">
                    PRO
                  </a>
                </span>
                <span className="px-4 hover:text-green-700 cursor-pointer">
                  <a href="https://web.cerebriam.com/pricing/">PRICING</a>
                </span>
              </div>
              <div className="flex justify-center items-center sm:justify-center mt-4 md:justify-center mt-4 lg:justify-end xl:justify-end">
                <Link to={SIGN_IN}>
                  <button
                    className="border-gray-400 border-solid border-2 bg-white hover:bg-gray-100 text-gray-600 font-bold py-2 px-4 rounded shadow mr-4  h-16"
                    style={{ width: '7rem' }}>
                    <span className="text-xl hover:text-gray-800 ">Log in</span>
                  </button>
                </Link>
                <Link to={SIGN_UP}>
                  <button
                    className="text-xl bg-teal-700 hover:bg-teal-400 text-white font-bold py-2 px-4 rounded h-16"
                    style={{ width: '7rem' }}>
                    Sign up
                  </button>
                </Link>
              </div>
            </div>
          </div>

          <div className="home-content">
            <div className="flex flex-col md:flex-col lg:flex-row xl:flex-row">
              <div className="w-full relative sm:w-full md:w-full lg:w-2/3 xl:w-2/3 pl-4">
                <span className="absolute top-0 text-xl text-green-700 lg:text-4xl xl:text-4xl">
                  Superpowers for{' '}
                </span>
                <span className="absolute mt-10 text-xl text-green-700 lg:text-4xl xl:text-4xl">
                  video content creators
                </span>
                <BackgroundVideo />
              </div>

              <div className="w-full md:w-full lg:w-1/3 xl:w-1/3">
                <div className="w-full float-right">
                  <SignInForm />
                  <div className="flex justify-center items-center">
                    <span className="font-medium text-gray-600">
                      Don't have an account?
                    </span>
                    <Link
                      className="inline-block align-baseline font-bold text-sm text-blue-500 hover:text-blue-800 cursor-pointer"
                      to={SIGN_UP}>
                      Sign up
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-col mt-20 mb-20 font-normal text-normal pl-4 md:text-xl lg:text-2xl xl:text-2xl">
            <p className="infoText">
                Try our content creator for free. Go ahead, just sign-up!
                </p>
              <p className="infoText">
                Vertical video for Instagram, TikTok and Facebook? Landscape video for Linkedin and YouTube?
                Or something in-between like a square for Instagram and Snapchat ? Don’t worry, whatever
                aspect ratios you’re working with, we’ve got you covered - we'll make them simultaneously!
              </p>
              <p className="infoText">
                Yes, that's right; you'll get: vertical (9:16, 4:5), landscape (16:9), and
                square (1:1) videos all at the same time. Just click to make your selection, and we’ll do the rest.
                You’re welcome :-)
             </p>
            </div>
          </div>
        </div>
        <AuthFooter />
      </>
    )
  }
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
  setCoupon: (...args) => dispatch(setCoupon(...args)),
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CouponPage))
