import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
// Routers
import { LANDING } from '../../constants/Routes'

const AdminRoute = ({ component: Component, auth, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      auth.isAuthenticated === true && auth.user.isAdmin === true ? (
        <Component {...props} />
      ) : (
        <Redirect to={LANDING} />
      )
    }
  />
)

AdminRoute.propTypes = {
  auth: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => ({
  auth: state.auth,
})

export default connect(mapStateToProps)(AdminRoute)
