import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import _ from 'lodash'
import { connect } from 'react-redux'
import ActionMenuBar from '../../components/sidebar'
import {
  toggleWatchProject,
  updateProject,
} from '../../redux/actions/EditorActions'
import { setBoardingStatus } from '../../redux/actions/AuthActions'
import { getProject, createProject } from '../../services/EditorServices'
import {
  getLibFiles,
  handleOrientationChange,
  toggleShowEditor,
  initialProjectData,
} from '../../methods/editor/MediaEditorMethods'
import { toast } from 'react-toastify'
import { DEFAULT_PROJECT } from '../../constants/TimelineItems'
import * as SelectedItemActions from '../../redux/actions/SelectedItemActions'
import * as SubscriptionActions from '../../redux/actions/SubscriptionActions'
import EditorTooltip from '../../components/editor-tooltip/EditorTooltip'
import DesktopEditor from './DesktopEditor'

class MediaEditor extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showEditor: false,
      libraryFiles: {
        videos: [],
        photos: [],
        audios: [],
        brands: [],
        templates: [],
        voiceovers: [],
      },
      template: false,
      tooltipStep: 1,
      tooltipActive: !localStorage.getItem('tooltip_instruction'),
    }

    this.mounted = false

    this.getLibFiles = getLibFiles.bind(this)
    this.toggleShowEditor = toggleShowEditor.bind(this)
    this.handleOrientationChange = handleOrientationChange.bind(this)
    this.initialProjectData = initialProjectData.bind(this)
  }

  changeTooltipStep = () => {
    this.setState({ tooltipActive: false })
  }
  closeTooltipSteps = () => {
    this.setState({ tooltipActive: false })
    localStorage.setItem('tooltip_instruction', 'true')
  }
  reactivateTooltipSteps = () => {
    this.setState({ tooltipActive: true, tooltipStep: 1 })
  }

  async componentDidMount() {
    this.props.getProductFeatures()
    this.mounted = true
    const queryStringProjectId = this.props.match.params.id
    const { project, authUser } = this.props
    try {
      let response
      if (queryStringProjectId != 0 && project?.id != queryStringProjectId) {
        response = await getProject(queryStringProjectId)
      } else if (queryStringProjectId == 0) {
        response = await createProject(project || DEFAULT_PROJECT, authUser.id)
        if (response.data.project)
          this.props.history.push(`/editor/${response.data.project.id}`)
      }

      if (response?.data?.result === 'success' && response.data.project) {
        this.initialProjectData(response.data.project)
      }
    } catch (error) {
      console.log(error)
      const errorMessage = error?.data?.message || error.message
      toast.error(`Get Project Data Error: ${errorMessage}`)
      console.error('Get Project Data Error: ', errorMessage)
      // TODO: Must be handler properly, redirect to not found page or somethink like this
      const response = await createProject(DEFAULT_PROJECT, authUser.id)
      if (response.data.project)
        this.props.history.push(`/editor/${response.data.project.id}`)
    } finally {
      toast.dismiss()
      this.getLibFiles()
      this.toggleShowEditor(true)
      window.addEventListener('orientationchange', this.handleOrientationChange)
    }
  }

  componentWillUnmount() {
    if (this.mounted) {
      window.removeEventListener(
        'orientationchange',
        this.handleOrientationChange,
      )
    }

    this.mounted = false
  }

  render() {
    const { showEditor, libraryFiles, template } = this.state
    return (
      <div className="w-full h-screen absolute top-0 left-0 flex justify-center overflow-auto">
        <EditorTooltip
          changeTooltipStep={this.changeTooltipStep.bind(this)}
          closeTooltipSteps={this.closeTooltipSteps.bind(this)}
          tooltipStep={this.state.tooltipStep}
          tooltipActive={this.state.tooltipActive}
        />
        {/* Desktop Editor */}
        {/* {!isMobileOnly && showEditor && ( */}
        {showEditor && (
          <DesktopEditor
            showEditor={showEditor}
            libraryFiles={libraryFiles}
            template={template}
            toggleShowEditor={this.toggleShowEditor}
            getLibFiles={this.getLibFiles}
            reactivateTooltipSteps={this.reactivateTooltipSteps.bind(this)}
          />
        )}

        {/* {isMobileOnly && showEditor && (
          // <MobileEditor
          //   showEditor={showEditor}
          //   libraryFiles={libraryFiles}
          //   template={template}
          //   toggleShowEditor={this.toggleShowEditor}
          // />
          <div>Coming Soon</div>
        )} */}

        {/*menu bar*/}
        <ActionMenuBar MenuPage="editor" />
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  authUser: state.auth.user,
  availGridSizes: state.recorder.availGridSizes,
  resolution: state.recorder.resolution,
  project: state.editor.project,
  timeSlider: state.timeline.timeSlider,
  videoFiles: state.editor.videoFiles,
  photoFiles: state.editor.photoFiles,
  audioFiles: state.editor.audioFiles,
  brandFiles: state.editor.brandFiles,
  templateFiles: state.editor.templateFiles,
  onboarding: state.auth.onboarding,
})

const mapDispatchToProps = (dispatch) => ({
  toggleWatchProject: (...args) => dispatch(toggleWatchProject(...args)),
  setSelectedScene: (...args) =>
    dispatch(SelectedItemActions.setSelectedScene(...args)),
  updateProject: (...args) => dispatch(updateProject(...args)),
  setBoardingStatus: (...args) => dispatch(setBoardingStatus(...args)),
  getProductFeatures: () => dispatch(SubscriptionActions.getProductFeatures()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(MediaEditor))
