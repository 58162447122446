export const LinkedinIcon = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 13.4167C0 8.67091 0 6.29803 0.938221 4.49188C1.72885 2.96985 2.96985 1.72885 4.49188 0.938221C6.29803 0 8.67091 0 13.4167 0H26.5833C31.3291 0 33.702 0 35.5081 0.938221C37.0301 1.72885 38.2711 2.96985 39.0618 4.49188C40 6.29803 40 8.67091 40 13.4167V26.5833C40 31.3291 40 33.702 39.0618 35.5081C38.2711 37.0301 37.0301 38.2711 35.5081 39.0618C33.702 40 31.3291 40 26.5833 40H13.4167C8.67091 40 6.29803 40 4.49188 39.0618C2.96985 38.2711 1.72885 37.0301 0.938221 35.5081C0 33.702 0 31.3291 0 26.5833V13.4167Z"
        fill="#1265BF"
      />
      <path
        d="M8.9778 12.4222C10.8942 12.4222 12.4478 10.8686 12.4478 8.95217C12.4478 7.03575 10.8942 5.48218 8.9778 5.48218C7.06138 5.48218 5.50781 7.03575 5.50781 8.95217C5.50781 10.8686 7.06138 12.4222 8.9778 12.4222Z"
        fill="white"
      />
      <path
        d="M15.6257 14.9866H21.3766V17.6211C21.3766 17.6211 22.9372 14.4999 27.1833 14.4999C30.971 14.4999 34.1088 16.3659 34.1088 22.0533V34.0466H28.1492V23.5066C28.1492 20.1515 26.358 19.7825 24.9931 19.7825C22.1604 19.7825 21.6755 22.2259 21.6755 23.9443V34.0466H15.6257V14.9866Z"
        fill="white"
      />
      <path
        d="M5.95293 14.9866H12.0027V34.0466H5.95293V14.9866Z"
        fill="white"
      />
    </svg>
  )
}
