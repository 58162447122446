import React from 'react'
import _ from 'lodash'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'

// Components
import * as MainContainerActions from '../../redux/actions/MainContainerActions'
import * as VoiceoverActions from '../../redux/actions/VoiceoverActions'
import * as EditorActions from '../../redux/actions/EditorActions'
import LibraryAssetUploader from '../../services/library-assets/LibraryAssetUploader'
import FileUtils from '../../utils/FileUtils'
import { ASSET_LIBRARY } from '../../constants/AssetLibraries'
import * as ProjectEditService from '../../services/ProjectEditService'
import { AUDIO, MUSIC, VOICEOVER } from '../../constants/Channels'
import UploadServices from '../../services/UploadServices'
import DurationGetter from '../../services/time/DurationGetter'
import { TimeTickerContext } from '../projects/TimeTickerContext'
import AudioDuckTimeline from './AudioDuckTimeline'
import {
  Drawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
} from '@chakra-ui/modal'
import VolumePointContextMenu from './VolumePointContextMenu'

const TEMPORARY_VOICEOVER_PREFIX = 'TEMP_VO'

class AudioDuckModal extends React.Component {
  state = {
    project: null,
    saving: false,
    isPlaying: false,
    isRecording: false,
    currentTime: 0,
    currentRecordId: null,
    currentRecordItem: null,
  }

  voiceRecorder = React.createRef()

  static contextType = TimeTickerContext

  static getDerivedStateFromProps(props, prevState) {
    return { project: prevState?.project || props.project }
  }

  componentDidMount() {
    this.context.attachTimeTickListener(this.onCurrentTimeTick.bind(this))
  }

  async componentDidUpdate(prevProps) {
    const isModalEnabled =
      !prevProps.isModalDisplayed && this.props.isModalDisplayed
    if (isModalEnabled) {
      const voiceoverDuration = DurationGetter.getMaxDurationByAssets(
        this.props.project.voiceover,
      )
      this.setState({
        currentTime: voiceoverDuration,
        project: this.props.project,
      })
    }
  }

  onCurrentTimeTick = (currentTime) => {
    const { project } = this.state
    const { isPlaying } = this.context

    const isBeyondProjectDurationWhilePlaying =
      isPlaying && currentTime > project.duration

    try {
      if (isBeyondProjectDurationWhilePlaying) {
        return false
      }
    } finally {
      if (isBeyondProjectDurationWhilePlaying) {
        this.context.changeCurrentTime(0)
      }
    }
  }

  updateItemWithEndTime = (item, newEndTime) => {
    const newDuration = newEndTime - item.start_time
    item.duration = newDuration
    item.clip.duration = newDuration
    item.clip.end = newDuration
    return item
  }

  handleShowModal = (value) => {
    const { onModalDisplayChange } = this.props
    if (!value) {
      this.context.stopPlaying()
      this.setState({ project: null })
    }
    onModalDisplayChange && onModalDisplayChange(value)
  }

  handleStartRecording = () => {
    const { currentTime } = this.context
    const project = _.cloneDeep(this.state.project)
    const newId = FileUtils.createFilenameByTimestamp(
      TEMPORARY_VOICEOVER_PREFIX,
    )
    const currentRecordItem = {
      start_time: currentTime,
      end_time: currentTime,
      url: null,
      $id: newId,
      clip: { start: 0, end: 0 },
    }
    this.setState(
      { project, currentRecordId: newId, currentRecordItem, isRecording: true },
      () => {
        this.context.startPlaying()
      },
    )
  }

  handleRevertLastItem = () => {
    let project = _.cloneDeep(this.state.project)
    const sortedItems = _.sortBy(
      project[VOICEOVER.projectPath],
      (item) => item.$id,
    )
    const itemToRevert = _.findLast(sortedItems, (item) =>
      _.startsWith(item.$id, TEMPORARY_VOICEOVER_PREFIX),
    )

    if (itemToRevert) {
      const itemsRemaining = _.reject(
        project[VOICEOVER.projectPath],
        (item) => item.$id === itemToRevert.$id,
      )
      project[VOICEOVER.projectPath] = itemsRemaining
    }

    this.context.changeCurrentTime(itemToRevert?.start_time || 0)
    project.duration = DurationGetter.getDurationFromProject(project)
    this.setState({ project })
  }

  handleStopRecording = async (blobUrl, blob) => {
    const { currentRecordItem } = this.state
    const endTime = this.context.stopPlaying()
    this.setState({ isRecording: false })
    let clonedProject = _.cloneDeep(this.state.project)

    if (currentRecordItem) {
      const blobDuration = endTime - currentRecordItem.start_time
      currentRecordItem.end_time = endTime
      currentRecordItem.clip.duration = blobDuration
      currentRecordItem.clip.end = blobDuration
      currentRecordItem.duration = blobDuration
      currentRecordItem.url = blobUrl
      currentRecordItem.blob = blob
      clonedProject[VOICEOVER.projectPath] = [
        ...clonedProject[VOICEOVER.projectPath],
        currentRecordItem,
      ]
      clonedProject.duration =
        DurationGetter.getDurationFromProject(clonedProject)
      this.context.changeCurrentTime(endTime)
      this.setState({
        project: clonedProject,
        currentRecordId: null,
        currentRecordItem: null,
      })
    }
  }

  handleStartPlaying = () => {
    this.context.startPlaying()
  }

  handleStopPlaying = () => {
    this.context.stopPlaying()
  }

  handleSaveRecordedAudio = async () => {
    try {
      this.setState({ saving: true })
      const { authUser, project } = this.props
      const { project: temporaryCopiedProject } = this.state
      let updatingProject = _.cloneDeep(project)
      const savingVoiceoverItems = _.filter(
        temporaryCopiedProject.voiceover,
        (item) => _.startsWith(item.$id, TEMPORARY_VOICEOVER_PREFIX),
      )

      for (let item of savingVoiceoverItems) {
        updatingProject = await this.saveVoiceoverItem(
          authUser,
          updatingProject,
          item,
        )
      }

      this.props.updateProject(updatingProject)
      toast.success('Voiceover successfully added to project')
      this.handleShowModal(false)
    } catch (error) {
      toast.error(`Error saving voiceover: ${error.message}`)
    } finally {
      toast.dismiss()
      this.setState({ saving: false })
    }
  }

  async saveVoiceoverItem(authUser, project, item) {
    const mergedBlob = await UploadServices.createMergedMp3Recording([
      item.blob,
    ])
    const voiceoverFilename = `${FileUtils.createFilenameByTimestamp('VO')}.mp3`
    const libraryAsset = await LibraryAssetUploader.saveAsLibraryAsset(
      mergedBlob,
      voiceoverFilename,
      ASSET_LIBRARY.VOICEOVER.folderPath,
      authUser.id,
    )
    const updatedProject = ProjectEditService.addItemToProject(
      project,
      libraryAsset,
      VOICEOVER.code,
      item.start_time,
    )
    return updatedProject
  }

  clearTimer() {
    clearInterval(this.timer)
    this.timer = null
  }

  isRecordedAudioEmpty() {
    return (
      _.filter(this.state.project.voiceover, (item) =>
        _.startsWith(item.$id, TEMPORARY_VOICEOVER_PREFIX),
      ).length <= 0
    )
  }

  handleStartPlaying = () => {
    this.context.startPlaying()
  }

  handleStopPlaying = () => {
    this.context.stopPlaying()
  }

  render() {
    const { currentTime } = this.context
    const { updateProject, project } = this.props
    return (
      <VolumePointContextMenu project={project} updateProject={updateProject}>
        <Drawer
          isOpen
          placement="bottom"
          onClose={() => this.handleShowModal(false)}>
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <AudioDuckTimeline
              channels={[AUDIO, VOICEOVER, MUSIC]}
              currentTime={currentTime}
            />
          </DrawerContent>
        </Drawer>
      </VolumePointContextMenu>
    )
  }
}

const mapStateToProps = (state) => ({
  authUser: state.auth.user,
  currentLayout: state.editor.project?.resolution,
  project: state.editor.project,
  recordedAudio: state.voiceover.recordedAudio,
})

const mapDispatchToProps = (dispatch) => ({
  toggleVoiceoverModal: (value) =>
    dispatch(MainContainerActions.toggleVoiceoverModal(value)),
  setRecordedAudio: (audio) =>
    dispatch(VoiceoverActions.setRecordedAudio(audio)),
  updateProject: (...any) => dispatch(EditorActions.updateProject(...any)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AudioDuckModal)
