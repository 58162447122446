import { Component } from 'react'
import { RATIOS } from '../../constants/AspectRatios'
import { connect } from 'react-redux'

import * as VideoCropActions from '../../redux/actions/VideoCropActions'
import * as FaceTrackActions from '../../redux/actions/FaceTrackActions'
import * as RecorderActions from '../../redux/actions/RecorderActions'
import * as ProjectEditActions from '../../redux/actions/ProjectEditActions'
import * as SnapGridBoxActions from '../../redux/actions/SnapGridBoxActions'
import {
  checkSaved,
  clearSaved,
  getShowSize,
  setSnapSize,
  toggleShowSaveTip,
} from '../../methods/recorder/RatioActionMethods'
import {
  SubscriptionManager,
  alertSubscriptionMessage,
  triggerGoogleAnalyticsEvent,
} from '../../utils/Helper'
import CropperPlaybackControl from './CropperPlaybackControl'
import { AspectRatiosPlatforms } from '../../constants/AspectRatiosPlatforms'

const platforms = AspectRatiosPlatforms

class CropperAspectRatioSelection extends Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.getShowSize = getShowSize.bind(this) // get replay video show size(video showing size, browser size)
    this.setSnapSize = setSnapSize.bind(this)
    this.checkSaved = checkSaved.bind(this)
    this.clearSaved = clearSaved.bind(this)
    this.toggleShowSaveTip = toggleShowSaveTip.bind(this)

    this.moveRef = props.moveRef
    this.classNames = props.classNames
  }

  componentDidMount() {
    this.props.setFaceTrackingEnabled(false)
  }

  componentWillReceiveProps(nextProps) {
    this.classNames = nextProps.classNames
  }

  selectPlatform(value) {
    if (value === this.props.selectedPlatform) {
      this.props.resetSelectedAspectRatios()
    } else {
      this.props.updateSelectedPlatform(value)
    }
  }

  selectRatio(ratio) {
    this.props.clearEditRatio()
    this.handleChangeCurrentLayout(ratio)
    this.props.updateSelectedRatio(ratio)
  }

  handleChangeCurrentLayout(s) {
    this.props.changeLayoutFromDropdown(true)
    this.props.setCurrentLayout(s)
    this.props.setFaceTrackingRatio(s)
    this.props.setSelectedMediaLayout(true, s)
  }

  addNewAspectRatio() {
    const {
      authUser,
      subscriptions: { user: userSubscription, products },
      savedLayouts,
      selectedRatio,
    } = this.props
    if (
      !SubscriptionManager(authUser, userSubscription, products)?.isProPlan &&
      savedLayouts.filter((x, index) => x && index !== selectedRatio).length >=
        2
    ) {
      return alertSubscriptionMessage(
        'Looks like you’re killing it on your socials. To create up to 4 aspect ratios simultaneously, please upgrade to the Cerebriam Pro package.',
      )
    }
    this.props.selectRatio(this.props.selectedRatio)
    this.props.resetSelectedAspectRatios()
    this.checkSaved()
  }

  isSceneTrackEnabled() {
    const {
      isFaceTrackingEnabled,
      isFaceTrackingEnabledSegmentations,
      segmentedVideos,
      currentVideoId,
    } = this.props
    let isSceneTrackEnabled = !segmentedVideos?.length
      ? isFaceTrackingEnabled
      : false
    if (segmentedVideos?.length) {
      isSceneTrackEnabled = isFaceTrackingEnabledSegmentations.find(
        (x) => x.id === currentVideoId,
      )?.isFaceTrackingEnabled
    }
    return isSceneTrackEnabled
  }

  aiTracking() {
    const {
      authUser,
      subscriptions: { user: userSubscription, products },
    } = this.props
    if (!SubscriptionManager(authUser, userSubscription, products)?.isProPlan) {
      return alertSubscriptionMessage(
        'Looking to track the person in the frame? No problem, just upgrade to the Cerebriam Pro package.',
      )
    }
    const userFeatures = this.props.authUser.hasFeatures
    let isUserFeaturesTrackerEnabled = false
    if (userFeatures) {
      if (userFeatures.tracker) {
        isUserFeaturesTrackerEnabled = userFeatures.tracker
      }
    }
    // if (isUserFeaturesTrackerEnabled) {
    const { isFaceTrackingEnabled } = this.props
    triggerGoogleAnalyticsEvent('set_face_tracking', {
      value: !this.isSceneTrackEnabled(),
      userId: this.props.authUser?.id,
    })
    this.props.setFaceTrackingEnabled(
      !this.isSceneTrackEnabled(),
      this.props.currentVideoId,
    )
    // } else {
    //   triggerGoogleAnalyticsEvent('exception', {
    //     description:
    //       'We are upgrading tracking to make it lightning-fast, check back soon.',
    //     userId: this.props.authUser?.id,
    //   })
    //   toast.dismiss()
    //   return toast.warning(
    //     'We are upgrading tracking to make it lightning-fast, check back soon.',
    //   )
    // }
  }

  render() {
    const trackingClassName = this.isSceneTrackEnabled() ? 'on' : 'off'
    return (
      <div className={`cropper-aspect-ratio-selection ${this.classNames}`}>
        <div
          className={`cropper-aspect-ratio-selection__content${
            this.props.isFaceTrackingPreviewEnabled ? ' centralize' : ''
          }`}>
          {!this.props.isFaceTrackingPreviewEnabled && (
            <button
              className={`cropper-tracking-${trackingClassName}`}
              onClick={() => this.aiTracking()}>
              <svg
                width="11"
                height="15"
                viewBox="0 0 11 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M4.55336 4.86L3.33336 5.304V7.66667H2.00003V4.36667H2.01003L5.52203 3.088C5.68469 3.026 5.86203 2.99467 6.04336 3.00067C6.4057 3.0097 6.75622 3.1314 7.04619 3.34886C7.33617 3.56631 7.55119 3.8687 7.66136 4.214C7.78536 4.60267 7.89869 4.86533 8.00136 5.00133C8.31157 5.4152 8.71397 5.75107 9.17663 5.98229C9.63929 6.2135 10.1495 6.3337 10.6667 6.33333V7.66667C9.97822 7.66742 9.29815 7.51552 8.67543 7.22191C8.05271 6.9283 7.50282 6.50028 7.06536 5.96867L6.67803 8.16667L8.00003 9.44667V14.3333H6.66669V10.3427L5.30003 9.018L4.66869 11.8833L0.0726929 11.0733L0.304693 9.76L3.58736 10.3387L4.55336 4.86ZM7.00003 2.66667C6.6464 2.66667 6.30727 2.52619 6.05722 2.27614C5.80717 2.02609 5.66669 1.68696 5.66669 1.33333C5.66669 0.979711 5.80717 0.640573 6.05722 0.390524C6.30727 0.140476 6.6464 0 7.00003 0C7.35365 0 7.69279 0.140476 7.94283 0.390524C8.19288 0.640573 8.33336 0.979711 8.33336 1.33333C8.33336 1.68696 8.19288 2.02609 7.94283 2.27614C7.69279 2.52619 7.35365 2.66667 7.00003 2.66667Z"
                  fill={this.isSceneTrackEnabled() ? 'black' : 'white'}
                />
              </svg>
              <span>AI tracking</span>
            </button>
          )}
          <div className="social-platforms-ratios">
            {!this.props.isFaceTrackingPreviewEnabled && (
              <>
                <div
                  className={`social-platforms${
                    this.props.selectedPlatform > 0 ? ' selected' : ''
                  }`}>
                  {platforms.map((platform, index) => {
                    if (
                      this.props.selectedPlatform === platform.id ||
                      this.props.selectedPlatform === 0
                    ) {
                      return (
                        <div
                          key={index}
                          onClick={() => this.selectPlatform(platform.id)}
                          className={`social-platform ${platform.name}${
                            this.props.selectedPlatform === platform.id
                              ? ' selected'
                              : ''
                          }`}>
                          {platform.renderIcon()}
                        </div>
                      )
                    }
                    return null
                  })}
                </div>
                {this.props.selectedPlatform > 0 && (
                  <div className="cropper-platform-ratios">
                    {RATIOS.filter((ratio) =>
                      platforms
                        .find((p) => p.id === this.props.selectedPlatform)
                        ?.ratios.find((r) => r.ratio === ratio.id),
                    ).map((ratio, index) => {
                      return (
                        <div
                          key={index}
                          onClick={() => this.selectRatio(ratio.id)}
                          className={`ratio ${ratio.name}${
                            this.props.selectedRatio === ratio.id
                              ? ' selected'
                              : ''
                          }`}>
                          <span>{ratio.ratio}</span>
                        </div>
                      )
                    })}
                  </div>
                )}
              </>
            )}
            {this.props.isFaceTrackingPreviewEnabled && (
              <div
                className={`social-platforms${
                  this.props.selectedPlatform > 0 ? ' selected' : ''
                }`}>
                {this.props.selectedPlatforms
                  .reduce((acc, pObj) => {
                    if (!acc.includes(pObj.platform)) {
                      acc.push(pObj.platform)
                    }
                    return acc
                  }, [])
                  .map((platform, index) => {
                    const platformDetails = platforms.find(
                      (p) => p.id === platform,
                    )
                    return (
                      <>
                        <div
                          key={index}
                          className={`social-platform ${platformDetails?.name}${
                            this.props.selectedPlatform === platform
                              ? ' selected'
                              : ''
                          }`}>
                          {platformDetails.renderIcon()}
                        </div>
                        {this.props.selectedPlatforms
                          .filter((sP) => sP.platform === platform)
                          .map((sp, i) => {
                            const getRatio = RATIOS.find(
                              (r) => r.id === sp.ratio,
                            )
                            return (
                              <div
                                key={i}
                                onClick={() => this.selectRatio(getRatio.id)}
                                className={`ratio ${getRatio.name}${
                                  this.props.selectedRatio === getRatio.id
                                    ? ' selected'
                                    : ''
                                }`}>
                                <span>{getRatio.ratio}</span>
                              </div>
                            )
                          })}
                      </>
                    )
                  })}
              </div>
            )}
          </div>
          {!this.props.isFaceTrackingPreviewEnabled && (
            <>
              {!this.props.hidePlayback && (
                <CropperPlaybackControl
                  playCropperScene={this.props.playCropperScene}
                  cropperScene={this.props.cropperScene}
                  setSceneTime={this.props.setSceneTime}
                />
              )}
              <button
                disabled={
                  this.props.selectedRatio === -1 ||
                  this.props.isFaceTrackingPreviewEnabled
                }
                className="add-aspect-ratio"
                onClick={() => this.addNewAspectRatio()}>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_788_1910)">
                    <path
                      d="M10 0C8.02219 0 6.08879 0.58649 4.4443 1.6853C2.79981 2.78412 1.51809 4.3459 0.761209 6.17317C0.00433286 8.00043 -0.193701 10.0111 0.192152 11.9509C0.578004 13.8907 1.53041 15.6725 2.92894 17.0711C4.32746 18.4696 6.10929 19.422 8.0491 19.8079C9.98891 20.1937 11.9996 19.9957 13.8268 19.2388C15.6541 18.4819 17.2159 17.2002 18.3147 15.5557C19.4135 13.9112 20 11.9778 20 10C19.9971 7.34872 18.9426 4.80684 17.0679 2.9321C15.1932 1.05736 12.6513 0.00286757 10 0V0ZM10 18.3333C8.35183 18.3333 6.74066 17.8446 5.37025 16.9289C3.99984 16.0132 2.93174 14.7117 2.30101 13.189C1.67028 11.6663 1.50525 9.99076 1.82679 8.37425C2.14834 6.75774 2.94201 5.27288 4.10745 4.10744C5.27289 2.94201 6.75774 2.14833 8.37425 1.82679C9.99076 1.50525 11.6663 1.67027 13.189 2.301C14.7118 2.93173 16.0132 3.99984 16.9289 5.37025C17.8446 6.74066 18.3333 8.35182 18.3333 10C18.3309 12.2094 17.4522 14.3276 15.8899 15.8899C14.3276 17.4522 12.2094 18.3309 10 18.3333ZM14.1667 10C14.1667 10.221 14.0789 10.433 13.9226 10.5893C13.7663 10.7455 13.5544 10.8333 13.3333 10.8333H10.8333V13.3333C10.8333 13.5543 10.7455 13.7663 10.5893 13.9226C10.433 14.0789 10.221 14.1667 10 14.1667C9.77899 14.1667 9.56703 14.0789 9.41075 13.9226C9.25447 13.7663 9.16667 13.5543 9.16667 13.3333V10.8333H6.66667C6.44566 10.8333 6.2337 10.7455 6.07742 10.5893C5.92113 10.433 5.83334 10.221 5.83334 10C5.83334 9.77899 5.92113 9.56703 6.07742 9.41074C6.2337 9.25447 6.44566 9.16667 6.66667 9.16667H9.16667V6.66667C9.16667 6.44565 9.25447 6.23369 9.41075 6.07741C9.56703 5.92113 9.77899 5.83333 10 5.83333C10.221 5.83333 10.433 5.92113 10.5893 6.07741C10.7455 6.23369 10.8333 6.44565 10.8333 6.66667V9.16667H13.3333C13.5544 9.16667 13.7663 9.25447 13.9226 9.41074C14.0789 9.56703 14.1667 9.77899 14.1667 10Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_788_1910">
                      <rect width="20" height="20" fill="white" />
                    </clipPath>
                  </defs>
                </svg>

                <span>Add another aspect ratio</span>
              </button>
            </>
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  isFaceTrackingEnabled: state.faceTrack.isFaceTrackingEnabled,
  isFaceTrackingEnabledSegmentations:
    state.faceTrack.isFaceTrackingEnabledSegmentations,
  media_layout: state.recorder.media_layout,
  resolution: state.recorder.resolution,
  cameraDimension: state.recorder.cameraDimension,
  selectedLayouts: state.recorder.selectLayout,
  savedLayouts: state.recorder.saveLayout,
  isCropped: state.recorder.isCropped,
  project: state.editor.project,
  defaultLayout: state.projectEdit.defaultLayout,
  currentLayout: state.videoCrop.currentLayout,
  zoomAbleState: state.recorder.zoomAbleState,
  shoot_value: state.recorder.shoot_value,
  authUser: state.auth.user,
  subscriptions: state.subscriptions,
})

const mapDispatchProps = (dispatch) => ({
  setFaceTrackingEnabled: (...args) =>
    dispatch(FaceTrackActions.setFaceTrackingEnabled(...args)),
  setMediaStreamSize: (...args) =>
    dispatch(SnapGridBoxActions.setMediaStreamSize(...args)),
  setMediaOriginSize: (...args) =>
    dispatch(SnapGridBoxActions.setMediaOriginSize(...args)),
  setSelectedMediaLayout: (...args) =>
    dispatch(RecorderActions.setSelectedMediaLayout(...args)),
  setCurrentLayout: (...args) =>
    dispatch(VideoCropActions.setCurrentLayout(...args)),
  setFaceTrackingRatio: (...args) =>
    dispatch(FaceTrackActions.setFaceTrackingRatio(...args)),
  setDefaultLayout: (...args) =>
    dispatch(ProjectEditActions.setDefaultLayout(...args)),
  setSaveRatioLayout: (...args) =>
    dispatch(RecorderActions.setSaveRatioLayout(...args)),
  setReviewScreenSize: (...args) =>
    dispatch(SnapGridBoxActions.setReviewScreenSize(...args)),
  setVideoAspectSize: (...args) =>
    dispatch(SnapGridBoxActions.setVideoAspectSize(...args)),
})

export default connect(
  mapStateToProps,
  mapDispatchProps,
)(CropperAspectRatioSelection)
