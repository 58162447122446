import React from 'react'

import { logoutUser } from '../../redux/actions/AuthActions'
import { connect } from 'react-redux'

const SignOutButton = ({ logoutUser }) => (
  <button
    style={{ width: 100, height: 40 }}
    type="button"
    className="inline-block text-center text-sm px-4 py-2 leading-none border rounded text-white border-white hover:border-transparent hover:text-teal-500 hover:bg-white lg:mt-0 outline-0 xs:w-1/2 sm:w-1/2 md:w-1/2 lg:w-auto"
    onClick={() => {
      logoutUser()
    }}>
    Sign Out
  </button>
)

export default connect(null, { logoutUser })(SignOutButton)
