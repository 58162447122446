import React from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import PerfectScrollbar from 'react-perfect-scrollbar'
import AudioDuckChannel from './AudioDuckChannel'
import {
  TICK_IN_MILLISECONDS,
  TimeTickerContext,
} from '../projects/TimeTickerContext'
import AudioDuckTimelineRangeSlider from './AudioDuckTimelineRangeSlider'
import TimelineHeader from '../video-editor/desktop-items/timeline-items/TimelineHeader'

class AudioDuckTimeline extends React.Component {
  static contextType = TimeTickerContext

  handleCheckItem = () => {
    alert('hi')
  }

  renderChannelName(channel) {
    return (
      <div
        className="my-1 flex items-center channel-name"
        style={{ backgroundColor: channel.backgroundColor }}>
        {channel.name}
      </div>
    )
  }

  renderChannelHeaderIcon(channel) {
    return (
      <div
        className="my-1 flex items-center channel-icon"
        style={{ backgroundColor: channel.backgroundColor }}>
        <div className="timeline-inner">
          <div className="item-icon">
            <img
              src={channel.iconImage}
              alt={`${channel.iconImage} channel`}
              className="block"
              style={{ marginLeft: '3px', objectFit: 'contain' }}
              onChange={() => this.handleCheckItem(channel.code)}
            />
          </div>
        </div>
      </div>
    )
  }

  renderTimelineNames() {
    const { channels } = this.props
    return (
      <div className="flex flex-col">
        {_.map(channels, (channel) => this.renderChannelName(channel))}
      </div>
    )
  }

  renderTimelineChannels() {
    const { channels, project } = this.props
    return (
      <div className="relative flex flex-col">
        {_.map(channels, (channel) => (
          <AudioDuckChannel project={project} channel={channel} />
        ))}
      </div>
    )
  }

  renderTimelineHeaderIcons() {
    const { channels } = this.props
    return (
      <div className="flex flex-col">
        {_.map(channels, (channel) => this.renderChannelHeaderIcon(channel))}
      </div>
    )
  }

  render() {
    const { project } = this.props
    return (
      <div className="flex flex-row audio-duck p-4">
        <div className="flex-shrink" style={{ paddingTop: '24px' }}>
          {this.renderTimelineHeaderIcons()}
        </div>
        <div className="flex-grow" style={{ width: '100vw' }}>
          <PerfectScrollbar className="pb-3" id="audioduck-scrollbar">
            <div className="absolute top-0 left-0">
              <TimelineHeader project={project} />
            </div>
            <AudioDuckTimelineRangeSlider
              duration={project.duration}
              tickInMilliseconds={TICK_IN_MILLISECONDS}
              onChangeCurrentTime={this.context.changeCurrentTime}
              currentTime={this.context.currentTime}
            />
            {this.renderTimelineChannels()}
          </PerfectScrollbar>
        </div>
        <div className="flex-shrink" style={{ paddingTop: '24px' }}>
          {this.renderTimelineNames()}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  project: state.editor.project,
})

export default connect(mapStateToProps)(AudioDuckTimeline)
