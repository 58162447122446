import React from 'react'
import { ONE_SECOND } from '../../../../constants/Timeline'

const getClipStartTime = (scene) => {
  const startTime = (scene?.clip?.start || 0) / ONE_SECOND
  const endTime = (scene?.clip?.end || 0) / ONE_SECOND
  return `${startTime},${endTime}`
}

const BgClip = React.forwardRef((props, ref) => {
  const startingTime = getClipStartTime(props.scene)
  return (
    <div
      ref={ref}
      {...props}
      className="w-full h-full slides flex justify-center">
      {props.scene.type === 'video' && (
        <div
          className="absolute w-full h-full flex justify-center items-center"
          key={props.s}>
          <video
            ref={(video) => (props.scenevideos[props.s] = video)}
            data-dashjs-player="data-dashjs-player"
            className="w-full slide-background-video absolute h-full"
            playsInline={true}
            preload={'auto'}
            autoPlay={false}
            muted={true}
            onWaiting={(event) => {
              const video = event.target
              if (video.networkState === video.NETWORK_LOADING) {
                props.onBuffering && props.onBuffering(event)
              }

              if (video.readyState < video.HAVE_FUTURE_DATA) {
                props.onBuffering && props.onBuffering(event)
              }
            }}
            onLoadedData={() =>
              props.onVideoLoaded && props.onVideoLoaded(props.s)
            }
            // poster={props.scene.thumbUrl}
            poster={'none'}
            src={`${props.scene.assetUrl}#t=${startingTime},`}></video>
        </div>
      )}
      {(props.scene.type === 'brand' || props.scene.type === 'photo') && (
        <div className="absolute w-full h-full" key={props.s}>
          <div
            className="absolute cursor-pointer"
            alt=""
            style={{
              background: `url(${props.scene.assetUrl}) center / cover no-repeat`,
              width: '100%',
              height: '100%',
              // TODO: use the actual sizes
              // width: `${props.scene.size.width * props.scale}px`,
              // height: `${props.scene.size.height * props.h_scale}px`,
              // top: `${props.scene.top * props.h_scale}px`,
              // left: `${props.scene.left * props.scale}px`,
            }}
          />
        </div>
      )}
    </div>
  )
})

export default BgClip
