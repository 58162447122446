import React, { Component } from 'react'
import { connect } from 'react-redux'
import Movable from './Moveable'
import { any } from 'prop-types'
import { Platforms } from './PlatformsIcon'
import {
  getBoxSize,
  getRectSize,
  getShowSize,
  setStyle,
  setSnapSize,
  handleDrag,
  handleResize,
  checkSaved,
  clearSaved,
  toggleShowSaveTip,
} from '../../../methods/recorder/RatioActionMethods'
import * as SnapGridEditService from '../../../services/SnapGridEditService'
import * as ProjectEditActions from '../../../redux/actions/ProjectEditActions'
import * as VideoCropActions from '../../../redux/actions/VideoCropActions'
import * as FaceTrackActions from '../../../redux/actions/FaceTrackActions'
import * as SnapGridBoxActions from '../../../redux/actions/SnapGridBoxActions'
import * as RecorderActions from '../../../redux/actions/RecorderActions'
import { ONE_SECOND } from '../../../constants/Timeline'
import SnapGridTransformGetter from '../../../services/videos/SnapGridTransformGetter'
import CropperMask from '../../cropper-mask.ts/CropperMask'

const sizeMap = [
  { id: 0, ratio: '16:9', platforms: ['L', 'T', 'Y', 'TWC'] },
  { id: 1, ratio: '9:16', platforms: ['TK', 'S', 'I'] },
  { id: 2, ratio: '4:5', platforms: ['F'] },
  { id: 3, ratio: '1:1', platforms: ['I', 'F', 'T', 'TK', 'L'] },
]

class RatioAction extends Component {
  _isMounted = false

  constructor(props) {
    super(props)

    this.moveRef = props.moveRef ? props.moveRef : React.createRef()
    this.moveRef1 = React.createRef()

    this.state = {
      real_show_w: 0, // snap grid width
      real_show_h: 0, // snap grid height
      //boxTransform: 'none', // snap grid position
      // Add a default box transform property on first entry of the cropper page
      // to centralize the RatioAction
      boxTransform: props.transform
        ? props.transform
        : window.innerWidth < 992
        ? 'matrix(1, 0, 0, 1, -95.4652, -58.7945) translate(0.816315px, 3.26532px)'
        : 'matrix(1, 0, 0, 1, -466.975, -235.611) translate(1.16278px, 0px)', // snap grid position
      real_screen_w: 0, // replaying video show width
      real_screen_h: 0, // replaying video show height
      real_client_w: 0, // client(browser) wrapper width
      real_client_h: 0, // client wrapper height
      saveGrid: false, // save aspect status
      showSaveTip: false,
      savedPopup: false,
      mountRatio: false,
    }

    this._isMounted = false

    this.getShowSize = getShowSize.bind(this) // get replay video show size(video showing size, browser size)
    this.getRectSize = getRectSize.bind(this) // get default aspect ratio size
    this.getBoxSize = getBoxSize.bind(this) // get snap grid box default size
    this.setStyle = setStyle.bind(this) // set snap grid position
    this.setSnapSize = setSnapSize.bind(this)
    this.handleDrag = handleDrag.bind(this)
    this.handleResize = handleResize.bind(this)
    this.checkSaved = checkSaved.bind(this)
    this.clearSaved = clearSaved.bind(this)
    this.toggleShowSaveTip = toggleShowSaveTip.bind(this)

    this.registerSubscriber = (id, callback) => {
      this.Subscribers = [
        ...(this.Subscribers
          ? this.Subscribers.filter((sb) => sb.id !== id)
          : []),
        {
          id,
          callback,
        },
      ]
    }

    this.unregisterSubscriber = (id) => {
      this.Subscribers = [
        ...(this.Subscribers
          ? this.Subscribers.filter((sb) => sb.id !== id)
          : []),
      ]
    }
  }

  renderPlatForm = (resolution, sizeStatus) => {
    const ratioObject = sizeMap.find((x) => x.id === sizeStatus)

    return (
      ratioObject && (
        <>
          <p>
            {resolution}p {ratioObject.ratio}
          </p>
          <p className="icons">
            {ratioObject.platforms.map((value, i) => (
              <span key={i}>
                <img src={Platforms[value]} alt="SM" />
              </span>
            ))}
          </p>
        </>
      )
    )
  }

  onMountedEvent = (isTriggeredViaDropDown = false) => {
    this.setState({
      firstLoaded: false,
    })
    const {
      currentLayout,
      direction,
      setMediaStreamSize,
      layoutChangedFromDropDown,
      changeLayoutFromDropdown,
      currentVideoId,
      aspectSize,
      aspectSizeSegmentations,
    } = this.props
    let sceneAspectSizes =
      aspectSizeSegmentations.length > 0 &&
      aspectSizeSegmentations.find((x) => x.id === currentVideoId)?.data
    if (!sceneAspectSizes) {
      sceneAspectSizes = aspectSizeSegmentations.find(
        (x) => x.data[currentLayout]?.width && x.data[currentLayout]?.height,
      )?.data
    }
    if (this.props.from === 'recorder') {
      // If RatioAction on recorder page, we use the root aspect size
      sceneAspectSizes = aspectSize
    }
    const ratioAspectSize = sceneAspectSizes && sceneAspectSizes[currentLayout]
    const ratioSelected =
      ratioAspectSize && !!(ratioAspectSize.height && ratioAspectSize.width)
    const showSize = this.getShowSize(currentLayout)
    const videoStreamSize = { width: showSize.sWidth, height: showSize.sHeight }
    setMediaStreamSize(videoStreamSize, currentLayout)
    const boxSize = this.getBoxSize(
      currentLayout,
      showSize.sWidth,
      showSize.sHeight,
    )
    try {
      if (this._isMounted && !direction) {
        this.setState({
          // Retain selected ratio scale if selected before
          real_show_w: ratioSelected ? ratioAspectSize.width : boxSize.width, // snap grid box width
          real_show_h: ratioSelected ? ratioAspectSize.height : boxSize.height, // snap grid box height
          real_screen_w: showSize.sWidth, // show video width
          real_screen_h: showSize.sHeight, // show video height
          real_client_w: showSize.clientW, // total wrapper(div) width
          real_client_h: showSize.clientH, // total wrapper(div) height
          saveGrid: false,
        })
      }

      if (this._isMounted && direction) {
        const newSnapGridBox = SnapGridEditService.realignSnapGrid(
          boxSize,
          showSize,
          direction,
        )
        // Run the below code when layout is changed from dropdown of layouts
        if (layoutChangedFromDropDown) {
          // Set transform property because aspect ratio changed
          // when an aspect ratio is selected from the aspect ratios dropdown
          const leftV =
            ratioSelected && this.props.from === 'recorder'
              ? ratioAspectSize.left + showSize.left
              : ratioSelected
              ? ratioAspectSize.left - showSize.sWidth / 2
              : this.props.transform
              ? window.innerWidth / 2 - newSnapGridBox.width / 2
              : -(newSnapGridBox.width / 2)
          const topV =
            ratioSelected && this.props.from === 'recorder'
              ? ratioAspectSize.top + showSize.top
              : ratioSelected
              ? ratioAspectSize.top - showSize.sHeight / 2
              : this.props.transform
              ? window.innerHeight / 2 - newSnapGridBox.height / 2
              : -(newSnapGridBox.height / 2)
          this.setStyle(`matrix(1, 0, 0, 1, ${leftV}, ${topV})`)
          this.moveRef.current.style.transform = `matrix(1, 0, 0, 1, ${leftV}, ${topV})`
          this.handleResizeMoveable(newSnapGridBox.width, newSnapGridBox.height)
          this.setResizedRatio()
          changeLayoutFromDropdown(false)
        }
        this.setState({
          // Retain selected ratio scale if selected before
          real_show_w: ratioSelected
            ? ratioAspectSize.width
            : newSnapGridBox.width, // snap grid box width
          real_show_h: ratioSelected
            ? ratioAspectSize.height
            : newSnapGridBox.height, // snap grid box height
          real_screen_w: showSize.sWidth, // show video width
          real_screen_h: showSize.sHeight, // show video height
          real_client_w: showSize.clientW, // total wrapper(div) width
          real_client_h: showSize.clientH, // total wrapper(div) height
          saveGrid: false,
          gridTop:
            this.moveRef.current?.getBoundingClientRect().top +
            this.moveRef.current?.getBoundingClientRect().height / 2,
          gridLeft:
            this.moveRef.current?.getBoundingClientRect().left +
            this.moveRef.current?.getBoundingClientRect().width / 2,
        })
        // Wait for grid box to mount to DOM after which we set the `gridTop` and `gridLeft`
        setTimeout(() => {
          this.setState({
            gridTop:
              this.moveRef.current?.getBoundingClientRect().top +
              this.moveRef.current?.getBoundingClientRect().height / 2,
            gridLeft:
              this.moveRef.current?.getBoundingClientRect().left +
              this.moveRef.current?.getBoundingClientRect().width / 2,
          })
        }, 1000)
      }

      // let transformVal = aspectTransform[currentLayout]
      // if (transformVal === 'none') {
      //   const topV = SnapGridTransformGetter.getTopTransformPosition(snapGridPosition, boxSize, showSize)
      //   const leftV = SnapGridTransformGetter.getLeftTransformPosition(snapGridPosition, boxSize, showSize, direction)
      //   transformVal = `matrix(1, 0, 0, 1, 0, 0) translate(${leftV}px, ${topV}px)`
      //   this.setStyle(transformVal)
      // }

      // if (!isTriggeredViaDropDown) {
      //   setTimeout(() => {
      //     this.setSnapSize(boxSize.width, boxSize.height, currentLayout)
      //   }, 500)
      // }
    } finally {
      if (!this.state.firstLoaded) {
        this.setState({ loading: true })
      }
      this.finalizeDisplayElements(isTriggeredViaDropDown)
    }
  }

  finalizeDisplayElements(isTriggeredViaDropDown) {
    setTimeout(() => {
      this.setState({ loading: false }, () => {
        this.setState({ firstLoaded: true })
        this.displaySaveTip()
      })
      if (this.Subscribers && !isTriggeredViaDropDown) {
        this.Subscribers.forEach(({ id, callback }) => {
          callback()
        })
      }
    }, 0.5 * ONE_SECOND)
  }

  displaySaveTip() {
    this.setState({ showSaveTip: true }, () => {
      setTimeout(() => {
        this.setState({ showSaveTip: false })
      }, 3 * ONE_SECOND)
    })
  }

  componentDidMount() {
    const {
      snapGridPosition: { left, top },
    } = this.props
    this.setState({ gridTop: top, gridLeft: left })
    this._isMounted = true
    this.onMountedEvent()
    this.props.cameraVideo.addEventListener('loadedmetadata', () => {
      this.onMountedEvent()
    })
    window.addEventListener('resize', this.onMountedEvent)
  }

  componentDidUpdate(prevProps) {
    if (this.moveRef.current) {
      this.props.setSnapGridPosition({
        top:
          this.moveRef.current?.getBoundingClientRect().top +
          this.moveRef.current?.getBoundingClientRect().height / 2,
        left:
          this.moveRef.current?.getBoundingClientRect().left +
          this.moveRef.current?.getBoundingClientRect().width / 2,
        set: true,
      })
    }

    if (this.props.currentLayout !== prevProps.currentLayout) {
      if (this.props.layoutChangedFromDropDown) {
        // TODO: For some reason yet to be figured out,
        // the cropper fails to load properly when onMountedEvent is called without the setimeout method
        setTimeout(() => this.onMountedEvent(true), 10)
        // this.onMountedEvent(true)
      } else {
        this.onMountedEvent(true)
      }
    }
  }

  handleResizeMoveable = (width, height) => {
    this.handleResize(width, height, true)
  }

  setResizedRatio = () => {
    if (!this.state.mountRatio) {
      this.setState({ mountRatio: true })
    }
  }

  componentWillUnmount() {
    this._isMounted = false
    window.removeEventListener('resize', this.onMountedEvent)
  }

  renderMoveable() {
    const {
      boxTransform,
      real_show_w,
      real_show_h,
      real_screen_w,
      real_screen_h,
      real_client_w,
      real_client_h,
      saveGrid,
      showSaveTip,
      savedPopup,
      gridLeft,
      gridTop,
      mountRatio,
    } = this.state

    const {
      currentLayout,
      resolution,
      snapGridPosition: { left, top },
    } = this.props

    return (
      <div className="block">
        <CropperMask
          left={gridLeft}
          top={gridTop}
          real_show_h={real_show_h}
          real_show_w={real_show_w}
        />
        <div
          ref={this.moveRef1}
          className="absolute cursor-pointer"
          style={{
            zIndex: 52,
            transform: boxTransform,
          }}>
          {/* <div
            className="snap-grid-save-check border-purple-800 border-solid border-l-2 border-t-2 absolute w-8 h-8 p-0 m-0"
            style={{
              top: mountRatio
                ? real_show_h - 32 + 'px'
                : window.innerWidth < 992
                ? 73
                : 456,
              left: mountRatio
                ? real_show_w - 32 + 'px'
                : window.innerWidth < 992
                ? 154
                : 835,
            }}>
            <label
              className="save-check-container p-0 m-0"
              htmlFor="save-grid-check">
              <input
                type="checkbox"
                checked={saveGrid}
                onChange={() => {}}
                id="save-grid-check"
                onClick={this.checkSaved}
              />
              <span className="checkmark lever absolute top-0 left-0" />
            </label>
            {showSaveTip && (
              <span className="tooltip-text">
                Tick to save the video crop ratio details.
              </span>
            )}
          </div> */}
        </div>
        <div
          ref={this.moveRef}
          className="ratio-action absolute border-purple-800 cursor-pointer"
          style={{
            transform: boxTransform,
            width: mountRatio
              ? real_show_w + 'px'
              : window.innerWidth < 992
              ? 186
              : 867,
            height: mountRatio
              ? real_show_h + 'px'
              : window.innerWidth < 992
              ? 105
              : 488,
          }}>
          {/* <div className="resolution-info border-purple-800 border-dashed border-l-2 border-b-2 text-purple-800 absolute right-0 px-3">
            {this.renderPlatForm(resolution, currentLayout)}
          </div> */}

          <div className="snap-grid-top-border absolute w-full top-0 left-0" />
          <div className="snap-grid-right-border absolute h-full top-0 right-0" />
          <div className="snap-grid-bottom-border absolute w-full bottom-0 left-0" />
          <div className="snap-grid-left-border absolute h-full top-0 left-0" />
          {(this.props.selNextPopup || savedPopup) && (
            <div className="w-full h-full items-center justify-center flex text-white">
              {savedPopup && (
                <div className="bg-black opacity-50 p-5">Saved</div>
              )}
              {this.props.selNextPopup && (
                <div className="bg-black opacity-50 p-5">
                  Select next aspect ratio or hit Make!
                </div>
              )}
            </div>
          )}
        </div>

        <Movable
          currentVideoId={this.props.currentVideoId}
          ratioToEdit={this.props.ratioToEdit}
          checkSaved={this.checkSaved}
          selectedRatio={this.props.selectedRatio}
          resetSelectedAspectRatios={this.props.resetSelectedAspectRatios}
          selectRatio={this.props.selectRatio}
          moveRef={this.moveRef}
          moveRef1={this.moveRef1}
          setStyle={this.handleDrag}
          showSize={{
            sWidth: real_screen_w,
            sHeight: real_screen_h,
            clientW: real_client_w,
            clientH: real_client_h,
          }}
          setResize={this.handleResizeMoveable}
          registerSubscriber={this.registerSubscriber}
          unregisterSubscriber={this.unregisterSubscriber}
          setGridPosition={this.props.setSnapGridPosition}
          setResizedRatio={this.setResizedRatio}
          clearEditRatio={this.props.clearEditRatio}
        />
      </div>
    )
  }

  render() {
    const { loading } = this.state
    return !loading && this.renderMoveable()
  }
}

RatioAction.propTypes = {
  cameraVideo: any,
  videoActionWrapper: any,
}

const mapStateToProps = (state) => ({
  currentLayout: state.videoCrop.currentLayout,
  aspectTransform: state.recorder.aspectTransform,
  aspectSize: state.recorder.aspectSize,
  aspectSizeSegmentations: state.recorder.aspectSizeSegmentations,
  direction: state.recorder.direction,
  resolution: state.recorder.resolution,
  snapGridPosition: state.recorder.snapGridPosition,
  shoot_value: state.recorder.shoot_value,
  zoomAbleState: state.recorder.zoomAbleState,
  availGridSizes: state.recorder.availGridSizes,
  cameraDimension: state.recorder.cameraDimension,
})

const mapDispatchToProps = (dispatch) => ({
  setVideoAspectSize: (...args) =>
    dispatch(SnapGridBoxActions.setVideoAspectSize(...args)),
  setSnapGridTransform: (...args) =>
    dispatch(SnapGridBoxActions.setSnapGridTransform(...args)),
  setMediaStreamSize: (...args) =>
    dispatch(SnapGridBoxActions.setMediaStreamSize(...args)),
  setMediaOriginSize: (...args) =>
    dispatch(SnapGridBoxActions.setMediaOriginSize(...args)),
  setReviewScreenSize: (...args) =>
    dispatch(SnapGridBoxActions.setReviewScreenSize(...args)),
  setSnapGridPosition: (...args) =>
    dispatch(SnapGridBoxActions.setSnapGridPosition(...args)),
  setSelectedMediaLayout: (...args) =>
    dispatch(RecorderActions.setSelectedMediaLayout(...args)),
  setSaveRatioLayout: (...args) =>
    dispatch(RecorderActions.setSaveRatioLayout(...args)),
  setDefaultLayout: (...args) =>
    dispatch(ProjectEditActions.setDefaultLayout(...args)),
  setCurrentLayout: (...args) =>
    dispatch(VideoCropActions.setCurrentLayout(...args)),
  setFaceTrackingRatio: (...args) =>
    dispatch(FaceTrackActions.setFaceTrackingRatio(...args)),
})

export default connect(mapStateToProps, mapDispatchToProps)(RatioAction)
