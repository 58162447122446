export const windowCloseTooltipInstr = (closeSteps) => {
  window.addEventListener('click', (e) => {
    const closeClasses = [
      'cropper-mask__top',
      'cropper-mask',
      'cropper-mask__left',
      'cropper-mask__right',
      'cropper-mask__bottom',
    ]
    const getClasses = e.target.classList

    // Check if contains
    if (getClasses.length > 0) {
      let arr = []
      for (var i = 0; i < getClasses.length; i++) {
        if (closeClasses.includes(getClasses[i])) {
          arr.push(true)
        }
      }

      if (arr.length === getClasses.length) {
        closeSteps()
      }
    }
  })
}