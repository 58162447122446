import {
  REMOTE_USER_LOADED,
  UPDATE_REMOTE_USER_LOAD_STATUS,
} from '../../redux/actions/Types'

export default (state = { loadStatus: 'loading' }, action) => {
  switch (action.type) {
    case UPDATE_REMOTE_USER_LOAD_STATUS: {
      return {
        ...state,
        loadStatus: action.status,
      }
    }
    case REMOTE_USER_LOADED: {
      return {
        ...state,
        loadStatus: 'available',
      }
    }
    default:
      return state
  }
}
