import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { setPassword } from '../../redux/actions/WoocommerceActions'

class WooCommerceSignUp extends Component {
  constructor(props) {
    super(props)
    this.state = {
      passwordOne: '',
      passwordTwo: '',
    }
  }

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value })
  }

  onSubmit = (event) => {
    event.preventDefault()
    const userData = {
      orderKey: this.props.orderKey,
      password: this.state.passwordOne,
      password2: this.state.passwordTwo,
    }

    this.props.setPassword(userData, this.props.history)
  }

  render() {
    const { name, email, errors } = this.props
    const { passwordOne, passwordTwo } = this.state

    const isInvalid = passwordOne !== passwordTwo || passwordOne === ''
    return (
      <form
        onSubmit={this.onSubmit}
        className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        <div className="mb-4">
          <input
            type="text"
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            value={name}
            name="name"
            placeholder="Full Name"
            readOnly={true}
            disabled={true}
          />
        </div>

        <div className="mb-4">
          <input
            type="email"
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            value={email}
            name="email"
            placeholder="Email Address"
            readOnly={true}
            disabled={true}
          />
        </div>

        <div className="mb-4">
          <input
            name="passwordOne"
            value={passwordOne}
            onChange={this.onChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="password"
            placeholder="Password"
          />
        </div>

        <div className="mb-6">
          <input
            name="passwordTwo"
            value={passwordTwo}
            onChange={this.onChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
            type="password"
            placeholder="Confirm Password"
          />
        </div>

        <div className="flex items-center justify-between">
          <button
            className={
              (isInvalid
                ? 'bg-gray-500 cursor-not-allowed '
                : 'bg-blue-500 hover:bg-blue-700 ') +
              'block w-full text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'
            }
            disabled={isInvalid}
            type="submit">
            Sign Up
          </button>
        </div>

        {errors && (
          <p className="text-red-500 text-xs italic mt-5">
            {errors.password} {errors.password2} {errors.order_key}
          </p>
        )}
      </form>
    )
  }
}

export default connect(
  (state) => ({
    name: state.woocommerceSignUp.name,
    email: state.woocommerceSignUp.email,
    orderKey: state.woocommerceSignUp.orderKey,
    errors: state.woocommerceSignUp.errors,
  }),
  { setPassword },
)(withRouter(WooCommerceSignUp))
