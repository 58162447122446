import { toast } from 'react-toastify'
import instance from '../utils/Axios'

// Check the promo code
export function checkCoupon(coupon) {
  return instance
    .post('/stripe/checkcoupon', { coupon })
    .then((res) => {
      const body = res.data
      // Check if the request result contains data for the promo code
      if (body) {
        const data = body.data
        if (data) {
          if (
            (!data.max_redemptions ||
              data.times_redeemed < data.max_redemptions) &&
            (!data.expires_at || Date.now() / 1000 < data.expires_at)
          ) {
            return data.id
          }
        }
      }
      throw new Error('Invalid referral code')
    })
    .catch((error) => {
      toast.error('Invalid referral code')
    })
}
