import React from 'react'
import _ from 'lodash'
import TimelineMoveable from './TimelineMoveable'
import * as TimelineService from '../../services/time/TimelineService'
import Loader from 'react-loader-spinner'
import DipBlackOn from '../../assets/images/editor/icons/dip-black-on.png'
import DipBlackOff from '../../assets/images/editor/icons/dip-black-off.png'

import { Flex, CheckboxGroup, Checkbox, Spacer } from '@chakra-ui/react'
import { TRANSITIONS } from '../../constants/TimelineItems'

const ORIGINAL_FRAME = {
  translate: [0, 0],
}
const VIDEO_HEIGHT = 66

class VideoTimelineItem extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      frame: _.cloneDeep(ORIGINAL_FRAME),
      loading: true,
    }

    this.sceneItemRef = React.createRef()
    this.innerTextWidthRef = React.createRef()
    this.afterAnimationBox = React.createRef()
  }

  componentDidUpdate(prevProps) {
    const { sceneItem } = this.props
    if (sceneItem.start_time !== prevProps.sceneItem.start_time) {
      this.reload()
    }
  }

  reload = () => {
    this.setState({ loading: true }, () => {
      this.setState({ loading: false })
    })
  }

  getActiveTextClass() {
    const isActive = this.isActive()
    return isActive ? ' font-bold active-text' : ''
  }

  handleItemClicked = (event) => {
    const { sceneItem, itemIndex, zoomValue, getZoomDeltaPx } = this.props
    const itemBoxLeftPosition = event.target.getBoundingClientRect().left
    const mouseLeftPosition = event.inputEvent?.clientX || event.clientX
    const deltaPixels = mouseLeftPosition - itemBoxLeftPosition
    const deltaTime = TimelineService.convertPixelToSeconds(
      getZoomDeltaPx(deltaPixels, zoomValue),
    )
    const newCurrentTime = sceneItem.start_time + deltaTime
    this.props.onItemSelected &&
      this.props.onItemSelected(sceneItem, itemIndex, true, newCurrentTime)
    // Reset Item selected to allow re mounting of video scenes, to allow video visaulisation in player
    setTimeout(() => {
      this.props.onItemSelected(sceneItem, itemIndex, true, newCurrentTime + 1)
    }, 500)
  }

  handleItemDragEnd = (newItemLeft, event) => {
    const { onPositionChanged } = this.props
    if (newItemLeft !== 0) {
      onPositionChanged && onPositionChanged(newItemLeft)
    } else {
      this.handleItemClicked(event)
    }
    const sceneItem = this.sceneItemRef.current
    if (sceneItem) sceneItem.style.transform = 'none'
  }

  handleItemResizing = (widthDelta) => {
    const { onWidthChanged } = this.props
    const originalWidth =
      this.sceneItemRef.current.getBoundingClientRect().width
    const newWidth = originalWidth + widthDelta
    onWidthChanged && onWidthChanged(newWidth)
  }

  handleResizeEnd = (widthDelta) => {
    const { onResizeEnd } = this.props
    onResizeEnd && onResizeEnd(widthDelta)
  }

  isActive() {
    const { activeItem, sceneItem } = this.props
    const isActive = activeItem && activeItem.id === sceneItem.id
    return isActive
  }

  handleOnCanPlay = () => {
    this.setState({ loading: false })
  }

  renderDisplayContainer() {
    const { sceneItem } = this.props
    const { loading } = this.state

    const isActive = this.isActive()
    const transition = sceneItem.transition || []

    const additionalProps = this.isActive()
      ? {}
      : {
          onClick: this.handleItemClicked,
        }

    return (
      <div
        ref={this.sceneItemRef}
        className={[
          'text-center',
          'border-l',
          'border-r',
          'cursor-pointer',
          'border-teal-500',
          'overflow-hidden',
          'text-slide-item',
          'whitespace-no-wrap',
          'absolute',
          this.getActiveTextClass(),
        ].join(' ')}
        style={{
          width: `${TimelineService.convertMillisecondsToPixels(
            sceneItem.clip.duration,
          )}px`,
          left: `${TimelineService.convertMillisecondsToPixels(
            sceneItem.start_time,
          )}px`,
          lineHeight: `${VIDEO_HEIGHT}px`,
          height: `${VIDEO_HEIGHT}px`,
          top: 0,
          backgroundImage: `url(${sceneItem.thumbUrl})`,
          backgroundSize: 'auto 80%',
          backgroundRepeatY: 'no-repeat',
          backgroundPositionY: 'center',
          overflow: 'visible',
        }}
        {...additionalProps}>
        {isActive && (
          <div style={{ position: 'absolute', top: '-30px' }}>
            <span className="fade-buttons" id="fade-buttons">
              <CheckboxGroup
                defaultValue={transition}
                onChange={this.props.onFadeCheckboxChange}>
                <Flex
                  style={{
                    width: `${TimelineService.convertMillisecondsToPixels(
                      sceneItem.clip.duration,
                    )}px`,
                  }}>
                  <Checkbox
                    icon={
                      <img
                        src={
                          transition.includes(TRANSITIONS.FadeFromBlack)
                            ? DipBlackOn
                            : DipBlackOff
                        }
                        alt=""
                      />
                    }
                    value={TRANSITIONS.FadeFromBlack}></Checkbox>
                  <Spacer />
                  <Checkbox
                    icon={
                      <img
                        src={
                          transition.includes(TRANSITIONS.FadeToBlack)
                            ? DipBlackOn
                            : DipBlackOff
                        }
                        alt=""
                      />
                    }
                    value={TRANSITIONS.FadeToBlack}></Checkbox>
                </Flex>
              </CheckboxGroup>
            </span>
          </div>
        )}
        <div
          className="absolute left-0 top-0"
          ref={(el) => (this.innerTextWidthRef = el)}
          style={{
            width: `${sceneItem.sliderW}px`,
            height: `${VIDEO_HEIGHT}px`,
          }}
        />
        <div
          className="text-center overflow-hidden whitespace-no-wrap border-0"
          dangerouslySetInnerHTML={{ __html: sceneItem.content }}
        />
        <div
          className="before"
          title={_.capitalize(_.startCase(sceneItem?.startAni?.value))}
          style={{
            width: `${TimelineService.convertMillisecondsToPixels(
              sceneItem?.startAni?.time,
            )}px`,
          }}>
          {_.times(6, (i) => (
            <img
              src="/pointer.svg"
              style={{
                width: '100%',
                height: '11px',
              }}
              key={`img-${i}`}
              alt=""
            />
          ))}
        </div>
        <div
          className="after"
          ref={(ref) => (this.afterAnimationBox = ref)}
          title={_.capitalize(_.startCase(sceneItem?.endAni?.value))}
          style={{
            width: `${TimelineService.convertMillisecondsToPixels(
              sceneItem?.endAni?.time,
            )}px`,
            right: 0,
            top: 0,
            transform: 'scaleX(-1)',
            position: 'absolute',
          }}>
          {_.times(6, (i) => (
            <img
              src="/pointer.svg"
              style={{
                width: '100%',
                height: '11px',
                fill: 'blue',
              }}
              key={`img-2-${i}`}
              alt=""
            />
          ))}
        </div>
        <div>
          <video
            src={sceneItem.assetUrl}
            onCanPlayThrough={this.handleOnCanPlay}
            style={{ display: 'none' }}
          />
        </div>
        {loading && (
          <div className="flex absolute top-0 w-full h-full items-center justify-center">
            <Loader type="Puff" color="gray" height={20} width={20} />
          </div>
        )}
      </div>
    )
  }

  renderMoveableWrapper() {
    const {
      sceneItem,
      sceneItemTimelineHTML,
      verticalGuidelines,
      scrollbarRef,
      scrollableTimelineBox,
      zoomValue,
    } = this.props
    return (
      <TimelineMoveable
        timelineItem={sceneItem}
        channel="video"
        target={this.sceneItemRef.current}
        container={sceneItemTimelineHTML}
        verticalGuidelines={verticalGuidelines}
        draggable={true}
        resizable={this.isActive()}
        throttleDrag={0}
        startDragRotate={0}
        throttleDragRotate={0}
        onDragEnd={this.handleItemDragEnd.bind(this)}
        onResize={this.handleItemResizing.bind(this)}
        onResizeEnd={this.handleResizeEnd.bind(this)}
        zoom={1}
        origin={this.isActive()}
        scrollbarRef={scrollbarRef}
        scrollableTimelineBox={scrollableTimelineBox}
        zoomValue={zoomValue}
      />
    )
  }

  render() {
    const { loading } = this.state
    return (
      <React.Fragment>
        {this.renderDisplayContainer()}
        {!loading &&
          this.isActive() &&
          !this.props.scenePlaying &&
          this.renderMoveableWrapper()}
      </React.Fragment>
    )
  }
}

export default VideoTimelineItem
