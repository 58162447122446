import _ from 'lodash'
import { ONE_SECOND } from '../../constants/Timeline'
import { DEFAULT_MUSIC } from '../../constants/TimelineItems'
import * as TimelineService from '../../services/time/TimelineService'

class MusicItemAdder {
  createMusicItem(musicItem, startAtMilliseconds = 0) {
    let newItem = _.cloneDeep(DEFAULT_MUSIC)
    newItem.id = `${musicItem._id}_${new Date().getTime()}`
    newItem.url = musicItem.fileUrl
    newItem.name = `${musicItem.fileName}.${musicItem.extension}`
    newItem.duration = musicItem.duration * ONE_SECOND
    newItem.size = {
      sliderW: TimelineService.convertMillisecondsToPixels(newItem.duration),
      sLeft: TimelineService.convertMillisecondsToPixels(startAtMilliseconds),
    }
    newItem.start_time = startAtMilliseconds
    newItem.end_time = startAtMilliseconds + newItem.duration
    newItem.clip = {
      start: 0,
      end: newItem.duration,
      duration: newItem.duration,
    }
    return newItem
  }
}

export default new MusicItemAdder()
