export const ReviewYoutubeIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 5.36667C0 3.46836 0 2.51921 0.375289 1.79675C0.69154 1.18794 1.18794 0.69154 1.79675 0.375289C2.51921 0 3.46836 0 5.36667 0H10.6333C12.5316 0 13.4808 0 14.2032 0.375289C14.8121 0.69154 15.3085 1.18794 15.6247 1.79675C16 2.51921 16 3.46836 16 5.36667V10.6333C16 12.5316 16 13.4808 15.6247 14.2032C15.3085 14.8121 14.8121 15.3085 14.2032 15.6247C13.4808 16 12.5316 16 10.6333 16H5.36667C3.46836 16 2.51921 16 1.79675 15.6247C1.18794 15.3085 0.69154 14.8121 0.375289 14.2032C0 13.4808 0 12.5316 0 10.6333V5.36667Z"
        fill="#FF0000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.6952 3.9235C13.2092 4.06189 13.6144 4.46716 13.7528 4.98116C14.0098 5.9202 13.9999 7.87736 13.9999 7.87736C13.9999 7.87736 13.9999 9.82463 13.7528 10.7637C13.6144 11.2777 13.2092 11.6829 12.6952 11.8213C11.7561 12.0684 7.99997 12.0684 7.99997 12.0684C7.99997 12.0684 4.2537 12.0684 3.30477 11.8114C2.79077 11.6731 2.3855 11.2678 2.24712 10.7538C2 9.82463 2 7.86747 2 7.86747C2 7.86747 2 5.9202 2.24712 4.98116C2.3855 4.46716 2.80066 4.052 3.30477 3.91362C4.24381 3.6665 7.99997 3.6665 7.99997 3.6665C7.99997 3.6665 11.7561 3.6665 12.6952 3.9235ZM9.92747 7.79884L6.80392 9.59785V5.99984L9.92747 7.79884Z"
        fill="white"
      />
    </svg>
  )
}
