import _ from 'lodash'
import { ONE_SECOND } from '../../constants/Timeline'
import * as Constants from '../constants/VoiceoverConstants'

export const setRecordedAudio = (audio) => {
  return (dispatch) => {
    dispatch({
      type: Constants.SET_RECORDED_AUDIO,
      recordedAudio: audio,
    })
  }
}
