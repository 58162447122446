export const SET_SELECTED_MUSIC_INDEX = 'SELECTED_ITEM/SET_SELECTED_MUSIC_INDEX'
export const SET_SELECTED_MUSIC = 'SELECTED_ITEM/SET_SELECTED_MUSIC'

export const SET_SELECTED_SCENE_INDEX = 'SELECTED_ITEM/SET_SELECTED_SCENE_INDEX'
export const SET_SELECTED_SCENE = 'SELECTED_ITEM/SET_SELECTED_SCENE'

export const SET_SELECTED_SOUND_INDEX = 'SELECTED_ITEM/SET_SELECTED_SOUND_INDEX'
export const SET_SELECTED_SOUND = 'SELECTED_ITEM/SET_SELECTED_SOUND'

export const SET_SELECTED_TEXT_INDEX = 'SELECTED_ITEM/SET_SELECTED_TEXT_INDEX'
export const SET_SELECTED_TEXT = 'SELECTED_ITEM/SET_SELECTED_TEXT'

export const SET_SELECTED_VOICEOVER_INDEX =
  'SELECTED_ITEM/SET_SELECTED_VOICEOVER_INDEX'
export const SET_SELECTED_VOICEOVER = 'SELECTED_ITEM/SET_SELECTED_VOICEOVER'

export const SET_SELECTED_SLIDE = 'SELECTED_ITEM/SET_SELECTED_SLIDE'
export const RESET_ALL = 'SELECTED_ITEM/RESET_ALL'

export const SET_CHECKED_CHANNELS = 'SELECTED_ITEM/SET_CHECKED_CHANNELS'
export const SET_CHECKED_CHANNEL = 'SELECTED_ITEM/SET_CHECKED_CHANNEL'
