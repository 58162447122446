import { adminAPI } from '../../../../utils/Axios'
import {
  UPDATE_ADMIN_SHOW_USER_LOAD_STATUS,
  ADMIN_SHOW_USER_LOADED,
} from '../Types'

const updateAdminUserLoadStatus = (status) => ({
  type: UPDATE_ADMIN_SHOW_USER_LOAD_STATUS,
  status,
})

const adminUserLoaded = (user) => ({
  type: ADMIN_SHOW_USER_LOADED,
  user,
})

export const getUser = (userId) => (dispatch) => {
  adminAPI
    .get(`/users/${userId}`)
    .then((response) => {
      dispatch(adminUserLoaded(response.data))
    })
    .catch((error) => {
      dispatch(updateAdminUserLoadStatus('error'))
    })
}
