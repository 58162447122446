import React from 'react'
import { connect } from 'react-redux'
import AspectRatio from 'react-aspect-ratio'

class ReplayWrapper extends React.Component {
  videoObject = React.createRef()
  canvasObject = React.createRef()

  getTopPosition(streamSizeBox, aspectSizeBox) {
    const originVideoSize = this.props.originVideoSize
    const heightRatio = originVideoSize.height / streamSizeBox.height
    return aspectSizeBox.top * heightRatio
  }

  getLeftPosition(streamSizeBox, aspectSizeBox) {
    const originVideoSize = this.props.originVideoSize
    const widthRatio = originVideoSize.width / streamSizeBox.width
    return aspectSizeBox.left * widthRatio
  }

  getWidthPosition(streamSizeBox, aspectSizeBox) {
    const originVideoSize = this.props.originVideoSize
    const widthRatio = originVideoSize.width / streamSizeBox.width
    return aspectSizeBox.width * widthRatio
  }

  getHeightPosition(streamSizeBox, aspectSizeBox) {
    const originVideoSize = this.props.originVideoSize
    const heightRatio = originVideoSize.height / streamSizeBox.height
    return aspectSizeBox.height * heightRatio
  }

  triggerLoop = (video, context) => {
    const { aspectSizes, selectedLayout, streamSize, shootValue } = this.props
    const aspectSizeBox = aspectSizes[selectedLayout]
    const streamSizeBox = streamSize[selectedLayout]
    const selectedShootValue = shootValue[selectedLayout]
    if (!video.paused && !video.ended) {
      if (aspectSizeBox) {
        context.drawImage(
          video,
          this.getLeftPosition(streamSizeBox, aspectSizeBox),
          this.getTopPosition(streamSizeBox, aspectSizeBox),
          this.getWidthPosition(streamSizeBox, aspectSizeBox),
          this.getHeightPosition(streamSizeBox, aspectSizeBox),
          0,
          0,
          aspectSizeBox.width,
          aspectSizeBox.height,
        )
      } else {
        context.drawImage(video, 0, 0)
      }
      setTimeout(() => this.triggerLoop(video, context), 1000 / 30)
    }
  }

  componentDidMount() {
    const video = this.videoObject.current
    const canvas = this.canvasObject.current
    video.addEventListener('play', () => {
      const context = canvas.getContext('2d')
      this.triggerLoop(video, context)
    })
  }

  render() {
    const {
      src,
      aspectSizes,
      rectangleSizes,
      selectedLayout,
      style = {},
    } = this.props
    const selectedCropBox = aspectSizes[selectedLayout]
    const selectedRect = rectangleSizes[selectedLayout]
    return (
      <AspectRatio ratio="16/9" style={style}>
        <div className="flex justify-center">
          <video
            style={{ visibility: 'hidden', position: 'absolute' }}
            width={selectedRect.width}
            muted
            height={selectedRect.height}
            ref={this.videoObject}
            loop
            playsInline
            autoPlay
            id="hidden-preview"
            src={src}
            controls={false}
          />
          <canvas
            style={{
              height: '100%',
            }}
            width={selectedCropBox.width}
            height={selectedCropBox.height}
            ref={this.canvasObject}
            id="preview"
          />
        </div>
      </AspectRatio>
    )
  }
}

const mapStateToProps = (state) => ({
  originVideoSize: state.recorder.originVideoSize,
  streamSize: state.recorder.streamSize,
})

export default connect(mapStateToProps)(ReplayWrapper)
