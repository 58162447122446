import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUndo } from '@fortawesome/free-solid-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'

library.add(faUndo)
class UndoButton extends React.Component {
  handleStartPlaying = () => {
    const { onStartPlaying } = this.props
    onStartPlaying && onStartPlaying()
  }

  handleStopPlaying = () => {
    const { onStopPlaying } = this.props
    onStopPlaying && onStopPlaying()
  }

  render() {
    return (
      <FontAwesomeIcon icon={faUndo} size="3x" onClick={this.props.onClick} />
    )
  }
}

export default UndoButton
