export const CropperApplyRatiosTrigger = ({
  resetApplySelectedRatios,
  notShownApplySelectedRatiosModal,
}) => {
  return (
    <div
      style={{ position: 'relative', cursor: 'pointer' }}
      onClick={
        !notShownApplySelectedRatiosModal ? resetApplySelectedRatios : null
      }>
      {!notShownApplySelectedRatiosModal && (
        <div className="rounded-full reset-apply-ratios absolute" />
      )}
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M8.74324 5.62162C8.74324 7.34565 7.34565 8.74324 5.62162 8.74324C3.8976 8.74324 2.5 7.34565 2.5 5.62162C2.5 3.8976 3.8976 2.5 5.62162 2.5C7.34565 2.5 8.74324 3.8976 8.74324 5.62162ZM6.82422 11.1143V20.8857C8.96361 21.3519 10.648 23.0363 11.1143 25.1757H20.8868C21.353 23.0367 23.0368 21.3526 25.1756 20.886V11.114C23.0368 10.6474 21.353 8.96328 20.8868 6.82432H11.1143C10.648 8.9637 8.96361 10.6481 6.82422 11.1143ZM11.0928 4.32432C10.5072 1.84502 8.27985 0 5.62162 0C2.51689 0 0 2.51689 0 5.62162C0 8.27982 1.84496 10.5071 4.32422 11.0928V20.9072C1.84496 21.4929 0 23.7202 0 26.3784C0 29.4831 2.51689 32 5.62162 32C8.27986 32 10.5072 30.155 11.0928 27.6757H20.9082C21.4939 30.155 23.7212 32 26.3794 32C29.4842 32 32.0011 29.4831 32.0011 26.3784C32.0011 23.7197 30.1555 21.4921 27.6756 20.9069V11.0931C30.1555 10.5079 32.0011 8.28027 32.0011 5.62162C32.0011 2.51689 29.4842 0 26.3794 0C23.7212 0 21.4939 1.84502 20.9082 4.32432H11.0928ZM26.3794 8.74324C28.1035 8.74324 29.5011 7.34565 29.5011 5.62162C29.5011 3.8976 28.1035 2.5 26.3794 2.5C24.6554 2.5 23.2578 3.8976 23.2578 5.62162C23.2578 7.34565 24.6554 8.74324 26.3794 8.74324ZM26.3794 29.5C28.1035 29.5 29.5011 28.1024 29.5011 26.3784C29.5011 24.6544 28.1035 23.2568 26.3794 23.2568C24.6554 23.2568 23.2578 24.6544 23.2578 26.3784C23.2578 28.1024 24.6554 29.5 26.3794 29.5ZM5.62162 29.5C7.34565 29.5 8.74324 28.1024 8.74324 26.3784C8.74324 24.6544 7.34565 23.2568 5.62162 23.2568C3.8976 23.2568 2.5 24.6544 2.5 26.3784C2.5 28.1024 3.8976 29.5 5.62162 29.5Z"
          fill="white"
        />
      </svg>
    </div>
  )
}
