import _ from 'lodash'
import { toast } from 'react-toastify'
import { AUDIO, MUSIC, TEXT, VIDEO, VOICEOVER } from '../../constants/Channels'
import { FLOAT_RATE, SLIDER_PRO } from '../../constants/TimelineItems'
import AudioItemCutter from '../../services/project-items/cutters/AudioItemCutter'
import RatioBasedItemCutter from '../../services/project-items/cutters/RatioBasedItemCutter'
import ProjectItemDeleter from '../../services/project-items/deleters/ProjectItemDeleter'
import { updateTransitions } from './services'
import { triggerGoogleAnalyticsEvent } from '../../utils/Helper'

export function getCurrSec() {
  const { project, updateSlideV } = this.props

  //let curPro = Math.round((updateSlideV / SLIDER_PRO) * FLOAT_RATE) / FLOAT_RATE
  let curPro = updateSlideV / SLIDER_PRO

  if (curPro > 1) {
    curPro = 1
  }
  //let curSec = Math.round(curPro * project.duration * SLIDER_PRO) / SLIDER_PRO
  let curSec = Math.round(curPro * project.duration)
  return curSec
}

export function handleUndo() {
  if (this.props.projectSteps?.length > 0) {
    triggerGoogleAnalyticsEvent('undo_action', {
      userId: this.props.authUser?.id,
    })
    this.props.onUndo()
  }
}

export function getSelectedItemByChannel(channelCode) {
  switch (channelCode) {
    case VIDEO.code:
      return this.props.selectedScene
    case AUDIO.code:
      return this.props.selectedSound
    case TEXT.code:
      return this.props.selectedText
    case MUSIC.code:
      return this.props.selectedMusic
    case VOICEOVER.code:
      return this.props.selectedVoiceover
  }
}

export function setSelectItemByChannelToBeEmpty(channelCode) {
  switch (channelCode) {
    case VIDEO.code: {
      this.props.setSelectedScene(null)
      this.props.setSelectedSceneIndex(null)
      break
    }
    case AUDIO.code: {
      this.props.setSelectedSound(null)
      this.props.setSelectedSoundIndex(null)
      break
    }
    case TEXT.code: {
      this.props.setSelectedText(null)
      this.props.setSelectedTextIndex(null)
      break
    }
    case MUSIC.code: {
      this.props.setSelectedMusic(null)
      this.props.setSelectedMusicIndex(null)
      break
    }
    case VOICEOVER.code: {
      this.props.setSelectedVoiceover(null)
      this.props.setSelectedVoiceoverIndex(null)
    }
  }
}

/**
 * Delete item
 */
export function deleteSelectedItems() {
  const {
    checkedChannels,
    project,
    updateProject,
    updateTimeSlider,
    storeProjectSteps,
  } = this.props
  let existChannels = []
  if (project) {
    let updatedProject = _.cloneDeep(project)
    const checkedChannelCodes = _.keys(
      _.pickBy(checkedChannels, (isEnabled) => isEnabled),
    )
    let items = []
    _.forEach(checkedChannelCodes, (channelCode) => {
      const item = this.getSelectedItemByChannel(channelCode)
      if (item) {
        existChannels.push(channelCode)
        items.push(item)
        updatedProject = ProjectItemDeleter.deleteItem(
          updatedProject,
          item,
          channelCode,
        )
        this.setSelectItemByChannelToBeEmpty(channelCode)
      }
    })
    triggerGoogleAnalyticsEvent('delete_selected_item', {
      projectId: project?.id,
      ...checkedChannels,
      assetUrl: items[0]?.assetUrl,
      userId: this.props.authUser?.id,
    })
    updateTimeSlider(0)
    storeProjectSteps({
      channel: existChannels,
      type: 'DELETE',
      payload: items,
    })
    const latestUpdatedProject = updateTransitions(updatedProject, false)
    updateProject(latestUpdatedProject)
  }
}

/**
 * cut selected item
 */
export function cutSelectedItems() {
  const {
    project,
    checkedChannels,
    updateProject,
    updateTimeSlider,
    storeProjectSteps,
  } = this.props
  try {
    if (project) {
      let updatedProject = _.cloneDeep(project)
      const currentTime = Math.round(this.getCurrSec())
      const checkedChannelCodes = _.keys(
        _.pickBy(checkedChannels, (isEnabled) => isEnabled),
      )
      let items = []
      let existAssets = []
      var item

      if (checkedChannels[VIDEO.code] && checkedChannels[AUDIO.code]) {
        item = this.getSelectedItemByChannel(VIDEO.code)
        if (item) {
          existAssets.push(VIDEO.code)
          items.push(
            _.cloneDeep({
              clip: item?.clip,
              start_time: item?.start_time,
              end_time: item?.end_time,
              volumeData: item?.volumeData,
              id: item?.id,
              sliderW: item?.sliderW,
            }),
          )
        }
        const { selectedScene } = this.props
        updatedProject = RatioBasedItemCutter.cut(
          updatedProject,
          selectedScene,
          currentTime,
          VIDEO.projectPath,
          true,
        )
        const nextItemId = updatedProject.nextItemId

        item = this.getSelectedItemByChannel(AUDIO.code)
        if (item) {
          existAssets.push(AUDIO.code)
          items.push(
            _.cloneDeep({
              clip: item?.clip,
              start_time: item?.start_time,
              end_time: item?.end_time,
              volumeData: item?.volumeData,
              id: item?.id,
              sliderW: item?.sliderW,
            }),
          )
        }
        const { selectedSound } = this.props
        updatedProject = AudioItemCutter.cut(
          updatedProject,
          selectedSound,
          currentTime,
          AUDIO.projectPath,
          nextItemId,
        )
      } else if (checkedChannels[VIDEO.code]) {
        item = this.getSelectedItemByChannel(VIDEO.code)
        if (item) {
          existAssets.push(VIDEO.code)
          items.push(
            _.cloneDeep({
              clip: item?.clip,
              start_time: item?.start_time,
              end_time: item?.end_time,
              volumeData: item?.volumeData,
              id: item?.id,
              sliderW: item?.sliderW,
            }),
          )
        }
        const { selectedScene } = this.props
        updatedProject = RatioBasedItemCutter.cut(
          updatedProject,
          selectedScene,
          currentTime,
          VIDEO.projectPath,
          false,
        )
      } else if (checkedChannels[AUDIO.code]) {
        item = this.getSelectedItemByChannel(AUDIO.code)
        if (item) {
          existAssets.push(AUDIO.code)
          items.push(
            _.cloneDeep({
              clip: item?.clip,
              start_time: item?.start_time,
              end_time: item?.end_time,
              volumeData: item?.volumeData,
              id: item?.id,
              sliderW: item?.sliderW,
            }),
          )
        }
        const { selectedSound } = this.props
        updatedProject = AudioItemCutter.cut(
          updatedProject,
          selectedSound,
          currentTime,
          AUDIO.projectPath,
          false,
        )
      }

      if (checkedChannels[TEXT.code]) {
        const item = this.getSelectedItemByChannel(TEXT.code)
        if (item) {
          existAssets.push(TEXT.code)
          items.push(
            _.cloneDeep({
              clip: item?.clip,
              start_time: item?.start_time,
              end_time: item?.end_time,
              volumeData: item?.volumeData,
              id: item?.id,
              sliderW: item?.sliderW,
            }),
          )
        }
        const { selectedText } = this.props
        updatedProject = RatioBasedItemCutter.cut(
          updatedProject,
          selectedText,
          currentTime,
          TEXT.projectPath,
        )
      }

      if (checkedChannels[MUSIC.code]) {
        const item = this.getSelectedItemByChannel(MUSIC.code)
        if (item) {
          existAssets.push(MUSIC.code)
          items.push(
            _.cloneDeep({
              clip: item?.clip,
              start_time: item?.start_time,
              end_time: item?.end_time,
              volumeData: item?.volumeData,
              id: item?.id,
              sliderW: item?.sliderW,
            }),
          )
        }
        const { selectedMusic } = this.props
        updatedProject = AudioItemCutter.cut(
          updatedProject,
          selectedMusic,
          currentTime,
          MUSIC.projectPath,
          false,
        )
      }

      if (checkedChannels[VOICEOVER.code]) {
        const item = this.getSelectedItemByChannel(VOICEOVER.code)
        if (item) {
          existAssets.push(VOICEOVER.code)
          items.push(
            _.cloneDeep({
              clip: item?.clip,
              start_time: item?.start_time,
              end_time: item?.end_time,
              volumeData: item?.volumeData,
              id: item?.id,
              sliderW: item?.sliderW,
            }),
          )
        }
        const { selectedVoiceover } = this.props
        updatedProject = AudioItemCutter.cut(
          updatedProject,
          selectedVoiceover,
          currentTime,
          VOICEOVER.projectPath,
        )
      }

      triggerGoogleAnalyticsEvent('cut_selected_item', {
        projectId: project?.id,
        ...checkedChannels,
        assetUrl: item?.assetUrl,
        userId: this.props.authUser?.id,
      })

      storeProjectSteps({
        channel: existAssets,
        type: 'CUT',
        payload: items,
      })
      updateProject(updatedProject)
    }
  } catch (error) {
    toast.error(error.message)
  }
}

/**
 * change template aspect
 * @param selectedRatio
 */
export function changeRatio(selectedRatio) {
  const { project, updateProject, reloadStageByProject, resetAllSelected } =
    this.props

  let updatedProject = _.cloneDeep(project)
  updatedProject.resolution = selectedRatio
  updateProject(updatedProject)
  resetAllSelected()
  reloadStageByProject()
}
