export const CropperPlayIcon = (props) => {
  return (
    <svg
      {...props}
      width="19"
      height="24"
      viewBox="0 0 19 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.333008 0.333313V23.6666L18.6663 12L0.333008 0.333313Z"
        fill="white"
      />
    </svg>
  )
}
