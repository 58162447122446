import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import * as ROUTES from '../../constants/Routes'
import {
  toggleSideBar,
  welcomeSetStep,
} from '../../redux/actions/RecorderActions'
import SidebarToggle from './SidebarToggler'
import QualitySubBar from './QualityBar'
import { QualityMenuImg } from './SidebarIcons'
import SettingsSubBar from './SettingsBar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPhotoVideo,
  faCog,
  faRecordVinyl,
  faCloudUploadAlt,
  faTimes,
  faEllipsisV,
  faHdd,
  faUserLock,
} from '@fortawesome/free-solid-svg-icons'
import ProjectsModal from '../modals/ProjectsModal'
import LeaveEditorConfirm from '../modals/LeaveEditorConfirm'
import { library } from '@fortawesome/fontawesome-svg-core'
import { logoutUser } from '../../redux/actions/AuthActions'
import { triggerGoogleAnalyticsEvent } from '../../utils/Helper'

library.add(faPhotoVideo)
library.add(faRecordVinyl)
library.add(faCloudUploadAlt)
library.add(faTimes)
library.add(faEllipsisV)
library.add(faHdd)
library.add(faCog)
class ActionMenuBar extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showQualityBar: false,
      showSettingsBar: false,
      isProjectsModalOpen: false,
      showLeaveEditorModal: false,
    }
  }

  openProjectsModal = () => {
    triggerGoogleAnalyticsEvent('open_project_modal', {
      userId: this.props.authUser?.id,
    })
    this.setState({
      isProjectsModalOpen: true,
    })
  }

  closeProjectsModal = () => {
    this.setState({
      isProjectsModalOpen: false,
    })
  }

  componentWillUnmount() {
    this.setState({
      showQualityBar: false,
      showSettingsBar: false,
    })

    this.props.toggleSideBar(false)
  }

  /**
   * toggle menu items
   */
  toggleOpen = () => {
    triggerGoogleAnalyticsEvent('toggle_editor_sidebar', {
      value: !this.props.menu_is_open,
      userId: this.props.authUser?.id,
    })
    this.props.toggleSideBar(!this.props.menu_is_open)
  }

  /**
   * show toggle button
   */
  toggleQualityOpen = () => {
    triggerGoogleAnalyticsEvent('toggle_video_quality_bar', {
      value: !this.state.showQualityBar,
      userId: this.props.authUser?.id,
    })
    this.setState({
      showQualityBar: !this.state.showQualityBar,
    })
  }

  /**
   * show toggle button
   */
  toggleSettingsBarOpen = () => {
    triggerGoogleAnalyticsEvent('toggle_settings_bar', {
      value: !this.state.showSettingsBar,
      userId: this.props.authUser?.id,
    })
    this.setState({
      showSettingsBar: !this.state.showSettingsBar,
    })
  }

  /**
   * go to set options
   * @param s
   */
  setHomeCarousel = (s) => {
    setTimeout(() => {
      this.props.history.push({
        pathname: ROUTES.HOME,
        state: { preRoute: 'editor' },
      })
    }, 200)
  }

  addMediaToProject = () => {
    this.props.history.push(ROUTES.CAMERA_VIEW)
  }

  goRecordMediaPage = () => {
    window.location.href = ROUTES.CAMERA_VIEW
  }

  toggleEditorConfirm = (flag) => {
    triggerGoogleAnalyticsEvent('toggle_upload_media', {
      value: flag,
      userId: this.props.authUser?.id,
    })
    this.setState({
      showLeaveEditorModal: flag,
    })
  }

  deleteAndCloseEditor = () => {
    this.toggleEditorConfirm(false)
    this.navigateToHome()
  }

  navigateToHome = () => {
    this.props.history.push({
      pathname: ROUTES.HOME,
      state: { preRoute: 'editor' },
    })
  }

  render() {
    const { menu_is_open, MenuPage } = this.props

    const {
      showQualityBar,
      showSettingsBar,
      isProjectsModalOpen,
      showLeaveEditorModal,
    } = this.state

    return (
      <div className="menu-area w-auto h-auto overflow-hidden">
        {menu_is_open && (
          <>
            <div className="bg-gray-600 opacity-50 absolute top-0 left-0 w-screen h-screen overflow-hidden z-100" />

            <div className="menu-items absolute top-0 left-0 z-150 text-white h-full">
              <div className="text-xl px-6 pt-5 pb-4 flex items-center border-b border-white overflow-auto">
                <div>Menu</div>
                <div
                  className="flex justify-end items-center ml-auto cursor-pointer"
                  onClick={this.toggleOpen}>
                  <FontAwesomeIcon icon={faTimes} />
                </div>
              </div>
              <ul className="px-5">
                <li
                  className="hover:bg-teal-500 py-3 px-1 flex items-center border-b border-teal-500"
                  onClick={() => {
                    this.toggleEditorConfirm(true)
                  }}>
                  <div className="item-icon">
                    <FontAwesomeIcon icon={faCloudUploadAlt} />
                  </div>
                  <div>Upload Media</div>
                </li>
                {/*<li*/}
                {/*  className="hover:bg-teal-500 py-3 px-1 flex items-center border-b border-teal-500"*/}
                {/*  onClick={() => {*/}
                {/*    this.setHomeCarousel(0)*/}
                {/*  }}>*/}
                {/*  <div className="item-icon" />*/}
                {/*  <div>Type of Project/Video</div>*/}
                {/*</li>*/}
                {/*{MenuPage === 'record' && (*/}
                {/*  <li*/}
                {/*    className="hover:bg-teal-500 py-3 px-1 flex items-center border-b border-teal-500"*/}
                {/*    onClick={() => {*/}
                {/*      this.setHomeCarousel(3)*/}
                {/*    }}>*/}
                {/*    <div className="item-icon" />*/}
                {/*    <div>Quick/Advanced</div>*/}
                {/*  </li>*/}
                {/*)}*/}
                {/* Stop Record Now as its been incorporated into Upload page */}
                {/* {MenuPage === 'editor' && (
                  <li
                    className="hover:bg-teal-500 py-3 px-1 flex items-center border-b border-teal-500"
                    onClick={() => {
                      this.toggleEditorConfirm(true)
                    }}>
                    <div className="item-icon">
                      <FontAwesomeIcon icon={faRecordVinyl} />
                    </div>
                    <div>Record Media</div>
                  </li>
                )} */}
                {MenuPage === 'record' && (
                  <li
                    className="hover:bg-teal-500 py-3 px-1 flex items-center border-b border-teal-500"
                    onClick={this.toggleQualityOpen}>
                    <div className="item-icon">
                      <img src={QualityMenuImg} alt="" />
                    </div>
                    <div>Video Quality</div>
                  </li>
                )}
                <li
                  onClick={this.openProjectsModal}
                  className="hover:bg-teal-500 py-3 px-1 flex items-center border-b border-teal-500">
                  <div className="item-icon">
                    <FontAwesomeIcon icon={faPhotoVideo} />
                  </div>
                  <div>My Projects</div>
                </li>
                {isProjectsModalOpen && (
                  <ProjectsModal closeModal={this.closeProjectsModal} />
                )}
                <li
                  className="hover:bg-teal-500 py-3 px-1 flex items-center border-b border-teal-500"
                  onClick={this.toggleSettingsBarOpen}>
                  <div className="item-icon">
                    <FontAwesomeIcon icon={faCog} />
                  </div>
                  <div>Settings</div>
                </li>
                <li
                  className="hover:bg-teal-500 py-3 px-1 flex items-center border-b border-teal-500"
                  onClick={() => this.props.logoutUser()}>
                  <div className="item-icon">
                    <FontAwesomeIcon icon={faUserLock} />
                  </div>
                  <div>Sign Out</div>
                </li>
              </ul>
            </div>

            {showQualityBar && (
              <QualitySubBar
                toggleSnapGrid={this.props.toggleSnapGrid}
                toggleQualityOpen={this.toggleQualityOpen}
              />
            )}

            {showSettingsBar && (
              <SettingsSubBar
                toggleSnapGrid={this.props.toggleSnapGrid}
                toggleSettingsBarOpen={this.toggleSettingsBarOpen}
              />
            )}
          </>
        )}
        {MenuPage === 'record' && !menu_is_open && (
          <SidebarToggle toggleOpen={this.toggleOpen} />
        )}
        {showLeaveEditorModal && (
          <LeaveEditorConfirm
            addMediaToProject={() => this.setHomeCarousel(2)}
            saveAndClose={this.navigateToHome}
            cancelLeaveEditor={() => {
              this.deleteAndCloseEditor()
            }}
          />
        )}
      </div>
    )
  }
}

ActionMenuBar.defaultProps = {
  toggleSnapGrid: () => {},
  MenuPage: 'record',
  ProjectId: '0',
}

/**
 * selected frame state
 * @param state
 * @returns {{menu_is_open: *}}
 */
const mapStateToProps = (state) => ({
  menu_is_open: state.recorder.menu_is_open,
  authUser: state.auth.user,
})

export default connect(mapStateToProps, {
  logoutUser,
  welcomeSetStep,
  toggleSideBar,
})(withRouter(ActionMenuBar))
