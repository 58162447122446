import React, { Component } from 'react'
import _ from 'lodash'
import { GetRulerSecond } from '../../../../utils/TimeManage'
import TimelineService from '../../../../services/time/TimelineService'

class TimelineHeader extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  static getDerivedStateFromProps(props, state) {
    const { project } = props
    const seconds = _.ceil(
      TimelineService.convertMillisecondsToSeconds(project?.duration || 0),
    )
    const maxMinutes = _.ceil(seconds / 60) || 1
    const maxSeconds = maxMinutes * 60
    const timelineMarkers = _.times(maxSeconds, (second) => second)
    return { timelineMarkers }
  }

  render() {
    const { timelineMarkers } = this.state
    return (
      <div className="timeline-header" style={{ whiteSpace: 'nowrap' }}>
        <div className="time-steps">
          {(this.props.timelineMarkers || timelineMarkers).map((i, j) => {
            return (
              <span
                className={i % 5 !== 0 ? 'ruler-small' : 'ruler-main'}
                id={GetRulerSecond(i)}
                key={i}>
                &nbsp;
              </span>
            )
          })}
        </div>
      </div>
    )
  }
}

export default TimelineHeader
