import React, { Component } from 'react'
import process from 'process'
import _ from 'lodash'
import { connect } from 'react-redux'
import { Button, Tooltip } from '@chakra-ui/react'
import {
  OrderListImg,
  UnOrderListImg,
} from '../video-editor/common-items/EditorIcons'
import ColorPicker from '../common/ColorPicker'
import RangeSlider from 'react-range-slider-input'
import { getRgbColor } from '../../utils/Helper'
import {
  addTexts,
  toggleWatchProject,
  updateProject,
  storeProjectSteps,
} from '../../redux/actions/EditorActions'
import {
  handleBackgroundTextColorClick,
  handleClose,
  handleOverImg,
  handleTextColorClick,
  handleTextColorClose,
} from '../../methods/editor/EditTextPanelMethods'
import * as SelectedItemActions from '../../redux/actions/SelectedItemActions'
import RATIO from '../../constants/AspectRatios'
import { TEXT } from '../../constants/Channels'
import { Select } from '@chakra-ui/select'
import { DEFAULT_TEXT, fontList } from '../../constants/TimelineItems'
import { EditorInfoIcon } from './icons/EditorInfoIcon'

const FONTSTYLE = {
  ITALIC: 'italic',
  BOLD: 'bold',
  UNDERLINE: 'underline',
  NORMAL: 'normal',
  NONE: 'none',
}

const ANIMATIONS = [
  {
    animation: 'fade',
    description: 'Fade-in/out',
    id: 'fade-in-out',
    inTimeProperty: 'fadeTime',
  },
  {
    animation: 'slide-right',
    description: 'Slide Right',
    id: 'slide-right',
    inTimeProperty: 'slideRTime',
  },
  {
    animation: 'slide-left',
    description: 'Slide Left',
    id: 'slide-left',
    inTimeProperty: 'slideLTime',
  },
  {
    animation: 'pulse',
    description: 'Pulse',
    id: 'fade-in-out',
    inTimeProperty: 'pulseTime',
  },
]

const TEXT_CONTENT_LIMIT = 150

class EditorTextEditPanel extends Component {
  state = {
    orderImg: OrderListImg,
    unOrderImg: UnOrderListImg,
    textModel: null,
    displayColorPicker: false,
    displayTextColorPicker: false,
    currentTextItem: null,
    fontStyle: this.currentProjectTextItem.fontStyle,
    fontWeight: this.currentProjectTextItem.fontWeight,
    textDecoration: this.currentProjectTextItem.textDecoration,
    content: this.currentProjectTextItem.content,
    trimContent: '',
    aniValue: this.currentProjectTextItem.aniValue,
    animationDuration: {
      pulseTime: 1500,
      slideLTime: 1500,
      slideRTime: 1500,
      fadeTime: 1500,
    },
    fontFamily: this.currentProjectTextItem.fontFamily,
    color: this.currentProjectTextItem.color,
    backgroundColor: this.currentProjectTextItem.backgroundColor,
    mounted: false,
    isNewText: false, // Know when a new text is being added
  }
  textPreview = React.createRef()
  fontListHtmlElement = React.createRef()
  isTextSaved = React.createRef() // Is the 'OK' button pressed to save or not

  constructor(props) {
    super(props)
    this.handleOverImg = handleOverImg.bind(this)
    this.handleTextColorClick = handleTextColorClick.bind(this)
    this.handleTextColorClose = handleTextColorClose.bind(this)
    this.handleBackgroundTextColorClick =
      handleBackgroundTextColorClick.bind(this)
    this.handleClose = handleClose.bind(this)
  }

  componentDidMount() {
    this.setState({ mounted: true })
    // Set isNewText if adding a new text to the editor
    if (this.state.content === DEFAULT_TEXT.content) {
      this.setState({ isNewText: true })
    }
  }

  handleChangeFontStyle = () => {
    this.setState({
      fontStyle:
        this.state.fontStyle === FONTSTYLE.ITALIC
          ? FONTSTYLE.NORMAL
          : FONTSTYLE.ITALIC,
    })
  }

  handleToggleFontWeight = () => {
    this.setState({
      fontWeight:
        this.state.fontWeight === FONTSTYLE.BOLD
          ? FONTSTYLE.NORMAL
          : FONTSTYLE.BOLD,
    })
  }

  handleToggleTextDecoration = () => {
    this.setState({
      textDecoration:
        this.state.textDecoration === FONTSTYLE.UNDERLINE
          ? FONTSTYLE.NONE
          : FONTSTYLE.UNDERLINE,
    })
  }

  handleChangeText = (e) => {
    if (
      // Exclude navigation keys
      ![8, 33, 34, 35, 36, 37, 38, 39, 40].includes(e.which) &&
      this.textPreview.current.textContent.length >= TEXT_CONTENT_LIMIT
    ) {
      e.preventDefault()
    }
    this.setState({ content: this.textPreview.current.innerHTML })
    this.setState({ trimContent: this.textPreview.current.textContent })
  }

  handleFocusText = () => {
    const textContent = this.textPreview.current.innerHTML
    if (textContent.includes(DEFAULT_TEXT.content)) {
      this.textPreview.current.innerText = ''
    }
  }

  handleChangeAnimationType = (animationType) => {
    this.setState({ aniValue: animationType })
  }

  handleChangeAnimationDuration = (animationTimeProperty, milliseconds) => {
    this.setState({
      animationDuration: {
        ...this.state.animationDuration,
        [animationTimeProperty]: milliseconds,
      },
    })
  }

  handleChangeFont = (event) => {
    this.setState({ fontFamily: event.target.value })
  }

  handleChangeCompleteTextColor = (color) => {
    this.setState({ color: color.rgb })
  }

  handleChangeCompleteBackgroundColor = (color) => {
    this.setState({ backgroundColor: color.rgb })
  }

  getFontFromHTMLElement = (htmlEl) => {
    return parseFloat(
      window.getComputedStyle(htmlEl, null).getPropertyValue('font-size'),
    )
  }

  // get text font size after updating text
  reformatTextFont(width, height, fontSize = 26) {
    const PADDING_LEFT = 0.025
    const PADDING_RIGHT = 0.025
    const paddingOffset = 1 - PADDING_LEFT - PADDING_RIGHT

    const currentWrapper = document.getElementById('cerebriam-text-wrapper')
    const stageBoundingBox =
      currentWrapper?.parentElement?.getBoundingClientRect()

    const widthRatio = this.props.selectedText.p_width / stageBoundingBox.width
    const heightRatio =
      this.props.selectedText.p_height / stageBoundingBox.height
    this.props.storeProjectSteps({
      type: 'UPDATE',
      channel: ['text'],
      payload: [
        {
          width: this.props.selectedText.width,
          height: this.props.selectedText.height,
          fontSize: this.props.selectedText.fontSize,
          id: this.props.selectedText.id,
        },
      ],
    })
    this.props.selectedText.width = width * widthRatio
    this.props.selectedText.height = height * heightRatio

    const averageRatio = (heightRatio + widthRatio) / 2

    return Math.round(fontSize * averageRatio * paddingOffset)
  }

  changeTextPropertyValue() {
    const { project, updateProject, storeProjectSteps, currentTextItem } =
      this.props
    const {
      fontStyle,
      fontWeight,
      textDecoration,
      content,
      aniValue,
      animationDuration,
      fontFamily,
      color,
      backgroundColor,
    } = this.state
    this.isTextSaved.current = true // Set true when 'Ok' button is pressed
    let clonedProject = _.cloneDeep(project)

    const regexPattern =
      /(<(ul|ol|li|p|h1|h2|h3|h4|h5|h6|span)([^style]+)([^>]+)>)|(<\/(ul|ol|li|p|h1|h2|h3|h4|h5|h6|span)>)/gi
    const matches = content.match(regexPattern)

    let trimContent = content
    if (matches) {
      matches.map((match) => {
        trimContent = trimContent.replace(match, '')
      })
    }

    const payload = {
      fontStyle,
      fontWeight,
      textDecoration,
      content: trimContent,
      aniValue,
      ...animationDuration,
      fontFamily,
      color,
      backgroundColor,
    }
    let textItemIndex

    _.forEach(clonedProject[TEXT.projectPath][0], (item, index) => {
      if (item.id === this.currentProjectTextItem?.id) {
        textItemIndex = index
      }
    })

    _.forEach(RATIO, (ratio) => {
      clonedProject[TEXT.projectPath][ratio][textItemIndex] = {
        ...clonedProject[TEXT.projectPath][ratio][textItemIndex],
        ...payload,
      }
    })

    storeProjectSteps({
      type: 'UPDATE',
      channel: ['text'],
      payload: [project[TEXT.projectPath][0][textItemIndex]],
    })
    updateProject && updateProject(clonedProject)

    // update the project texts in the store.
    // This is necessary because we can't tell the font-size because the font-size depneds on the length of the text,
    // so there's a need to re-update the project texts font-size in order to pass it to the backend on publish
    setTimeout(() => {
      const textHTMLElemsArr85 = document.getElementsByClassName(
        `cerebriam-extra_${DEFAULT_TEXT.height}_${DEFAULT_TEXT.width}`,
      )
      const textHTMLElemsArr =
        document.getElementsByClassName('cerebriam-extra')

      // if on a text box with default text container dimenstions, update across all aspect rations with the same default sizes
      if (textHTMLElemsArr85.length > 0) {
        const resTexts = _.cloneDeep(clonedProject.texts[project.resolution])
        let getTextIndex
        const getText = resTexts
          .filter(
            (text) =>
              text.height === DEFAULT_TEXT.height &&
              text.width === DEFAULT_TEXT.width,
          )
          .find((textObj, index) => {
            if (textObj.id === currentTextItem.id) {
              getTextIndex = index
              return textObj
            }
          })
        if (getTextIndex > -1) {
          const textHTML = textHTMLElemsArr85[getTextIndex]
          const fontSize = this.getFontFromHTMLElement(textHTML)

          const newTexts = _.cloneDeep(clonedProject.texts).map(
            (textArr, resolution) => {
              return textArr.map((text, index) => {
                if (
                  getText.id === text.id &&
                  text.height === DEFAULT_TEXT.height &&
                  text.width === DEFAULT_TEXT.width
                ) {
                  const reformattedSize = this.reformatTextFont(
                    textHTML.parentElement.offsetWidth,
                    textHTML.parentElement.offsetHeight,
                    fontSize,
                  )
                  return { ...text, fontSize: reformattedSize }
                }
                return text
              })
            },
          )
          clonedProject = { ...clonedProject, texts: newTexts }
          updateProject && updateProject(clonedProject)
        }
      }
      // if on a text box with altered text container dimenstions, update it at tha aspect ratio level
      if (textHTMLElemsArr.length > 0) {
        const resTexts = _.cloneDeep(clonedProject.texts[project.resolution])
        let getTextIndex
        const getText = resTexts
          .filter(
            (text) =>
              text.height !== DEFAULT_TEXT.height ||
              text.width !== DEFAULT_TEXT.width,
          )
          .find((textObj, index) => {
            if (textObj.id === currentTextItem.id) {
              getTextIndex = index
              return textObj
            }
          })
        if (getText) {
          const textHTML = textHTMLElemsArr[getTextIndex]
          const fontSize = this.getFontFromHTMLElement(textHTML)

          const newTexts = _.cloneDeep(clonedProject.texts).map(
            (textArr, resolution) => {
              return textArr.map((text) => {
                if (
                  text.id === getText.id &&
                  resolution === project.resolution
                ) {
                  const reformattedSize = this.reformatTextFont(
                    textHTML.parentElement.offsetWidth,
                    textHTML.parentElement.offsetHeight,
                    fontSize,
                  )
                  return { ...text, fontSize: reformattedSize }
                }
                return text
              })
            },
          )
          clonedProject = { ...clonedProject, texts: newTexts }
          updateProject && updateProject(clonedProject)
        }
      }
    }, 1000)
    this.handleOnClose()
  }

  handleOnClose = () => {
    const {
      resetAllSelected,
      setSelectedText,
      setSelectedTextIndex,
      onClose,
      deleteSelectedItems,
      closeMediaPanel,
    } = this.props
    closeMediaPanel && closeMediaPanel()
    resetAllSelected && resetAllSelected()
    process.nextTick(() => {
      const selectedText = this.currentProjectTextItem
      const selectedTextIndex = this.getSelectedTextIndex(selectedText)
      setSelectedText && setSelectedText(selectedText)
      setSelectedTextIndex && setSelectedTextIndex(selectedTextIndex)
      onClose && onClose()

      // Delete default text item added if not altered
      if (this.state.isNewText && !this.isTextSaved.current) {
        deleteSelectedItems()
      }
    })
  }

  getSelectedTextIndex(textItem) {
    const { project } = this.props
    const textItems = _.get(
      project,
      `${TEXT.projectPath}.${project.resolution}`,
      [],
    )
    const newTextIndex = _.findIndex(
      textItems,
      (item) => item.id === textItem?.id,
    )
    return newTextIndex
  }

  get currentProjectTextItem() {
    const { project, currentTextItem } = this.props
    const projectTextItems = _.get(
      project,
      `${TEXT.projectPath}.${project.resolution}`,
      [],
    )
    const currentItem = _.find(
      projectTextItems,
      (item) => item.id === currentTextItem?.id,
    )
    return currentItem
  }

  render() {
    const { currentTextItem } = this.props
    const { orderImg, unOrderImg } = this.state
    return (
      <>
        <div className="title">
          <p>Title & Text</p>
          <EditorInfoIcon />
          <div className="close-icon" onClick={this.handleOnClose}>
            X
          </div>
        </div>
        <div
          className="editor-text-edit-panel w-full p-1"
          style={{ overflow: 'auto' }}>
          <div>
            {this.renderEditTextContent(currentTextItem)}
            {this.renderEditTextFont(currentTextItem)}
            {this.renderEditTextFontStyle(this.currentProjectTextItem)}
            {this.renderTextOrderForm(unOrderImg, orderImg)}
            {this.renderTextAnimationForm(this.currentProjectTextItem)}
          </div>
          <div className="flex justify-center">
            <button
              className="btn-add border-0 cursor-pointer font-bold outline-none shadow-none"
              onClick={() => {
                if (
                  this.state.trimContent.length > TEXT_CONTENT_LIMIT ||
                  this.state.content === DEFAULT_TEXT.content
                ) {
                  return
                }
                this.changeTextPropertyValue()
              }}>
              Ok
            </button>
          </div>
        </div>
      </>
    )
  }

  componentDidUpdate() {
    if (this.textPreview.current && !this.textPreview.current.innerHTML) {
      this.textPreview.current.innerHTML = this.state.content
    }
  }

  renderTextOrderForm(unOrderImg, orderImg) {
    return (
      <div className="list-styles flex justify-start items-center mt-5">
        <img
          src={unOrderImg}
          alt=""
          className="unordered cursor-pointer mr-3"
          onMouseOver={() => {
            this.handleOverImg('un_order', true)
          }}
          onMouseOut={() => {
            this.handleOverImg('un_order', false)
          }}
          onTouchStart={() => {
            this.handleOverImg('un_order', true)
          }}
          onTouchEnd={() => {
            this.handleOverImg('un_order', false)
          }}
        />
        <img
          src={orderImg}
          alt=""
          className="ordered cursor-pointer"
          onMouseOver={() => {
            this.handleOverImg('order', true)
          }}
          onMouseOut={() => {
            this.handleOverImg('order', false)
          }}
          onTouchStart={() => {
            this.handleOverImg('order', true)
          }}
          onTouchEnd={() => {
            this.handleOverImg('order', false)
          }}
        />
      </div>
    )
  }

  renderTextAnimationForm(textItem) {
    const { aniValue } = this.state
    return (
      <div className="text-animations">
        <h6 className="my-5 block text-left font-bold">Animation</h6>
        {_.map(ANIMATIONS, (animation, index) => {
          return (
            <div
              className="animation-item flex items-center"
              key={`ani-${index}`}>
              <Button
                onClick={() =>
                  this.handleChangeAnimationType(animation.animation)
                }
                className={
                  'btn-edit text-center font-bold inline-block cursor-pointer mb-0 flex-1' +
                  (textItem && aniValue === animation.animation
                    ? ' active'
                    : '')
                }>
                {animation.description}
              </Button>
              <div id={animation.id} className="ani-slider inline-block w-3/6">
                <RangeSlider
                  disabled={aniValue === animation.animation}
                  min={0}
                  max={3000}
                  tooltip={false}
                  value={[
                    0,
                    textItem
                      ? this.state.animationDuration[animation.inTimeProperty]
                      : 1500,
                  ]}
                  step={100}
                  onInput={([, value]) =>
                    this.handleChangeAnimationDuration(
                      animation.inTimeProperty,
                      value,
                    )
                  }
                  thumbsDisabled={[true, false]}
                  rangeSlideDisabled={true}
                />
              </div>
            </div>
          )
        })}
      </div>
    )
  }

  renderEditTextFontStyle(currentTextItem) {
    const { fontWeight, fontStyle, textDecoration, color, backgroundColor } =
      this.state
    return (
      <div className="tl-buttons flex justify-start mt-5">
        <Button
          isActive={fontWeight === FONTSTYLE.BOLD}
          className="btn-tl font-bold text-center cursor-pointer hover:opacity-75"
          onClick={() => this.handleToggleFontWeight()}>
          <span>B</span>
        </Button>
        <Button
          isActive={fontStyle === FONTSTYLE.ITALIC}
          className="btn-tl italic font-bold text-center cursor-pointer hover:opacity-75"
          onClick={() => this.handleChangeFontStyle()}>
          <span>I</span>
        </Button>
        <Button
          isActive={textDecoration === FONTSTYLE.UNDERLINE}
          className="btn-tl underline font-bold text-center cursor-pointer hover:opacity-75"
          onClick={() => this.handleToggleTextDecoration()}>
          <span>A</span>
        </Button>
        <Tooltip label="Change Text Foreground Color" placement="bottom">
          <>
            <Button
              className="btn-tl font-color font-bold text-center cursor-pointer"
              style={{
                color: currentTextItem ? getRgbColor(color) : '#374151',
              }}
              onClick={this.handleTextColorClick}>
              T
            </Button>
            <ColorPicker
              handleClose={this.handleTextColorClose}
              color={currentTextItem ? color : '#374151'}
              displayColorPicker={this.state.displayTextColorPicker}
              onChange={this.handleChangeCompleteTextColor}
              onChangeComplete={this.handleChangeCompleteTextColor}
              pickText="T"
            />
          </>
        </Tooltip>
        <Tooltip label="Change Text Background Color" placement="bottom">
          <>
            <Button
              className="btn-tl bg-color font-bold text-center cursor-pointer"
              onClick={this.handleBackgroundTextColorClick}
              style={{
                backgroundColor: currentTextItem
                  ? getRgbColor(backgroundColor)
                  : '#e1ebf1',
                color: '#008180',
              }}>
              T
            </Button>
            <ColorPicker
              handleClose={this.handleClose}
              color={currentTextItem ? backgroundColor : '#e1ebf1'}
              displayColorPicker={this.state.displayColorPicker}
              onChange={this.handleChangeCompleteBackgroundColor}
              onChangeComplete={this.handleChangeCompleteBackgroundColor}
              pickText="T"
            />
          </>
        </Tooltip>
      </div>
    )
  }

  renderEditTextFont(currentTextItem) {
    const { fontFamily } = this.state
    return (
      <div className="panel-select relative mt-5">
        <Select
          value={fontFamily}
          ref={this.fontListHtmlElement}
          onChange={this.handleChangeFont}
          id="font-family"
          className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
          {fontList.map((el, i) => {
            return (
              <option
                value={el.value}
                key={i}
                style={{
                  fontFamily: el.value,
                }}>
                {el.name}
              </option>
            )
          })}
        </Select>
      </div>
    )
  }

  renderEditTextContent(currentTextItem) {
    const {
      fontFamily,
      fontWeight,
      fontStyle,
      textDecoration,
      color,
      content,
    } = this.state

    if (this.textPreview.current && this.textPreview.current.innerHTML === '')
      this.textPreview.current.innerHTML = content
    return (
      <>
        <div
          ref={this.textPreview}
          className="scene-preview overflow-auto flex justify-center items-center flex-col relative"
          contentEditable={true}
          suppressContentEditableWarning={true}
          style={{
            fontFamily: this.currentProjectTextItem ? fontFamily : 'Montserrat',
            fontWeight: this.currentProjectTextItem ? fontWeight : 'normal',
            fontStyle: this.currentProjectTextItem ? fontStyle : 'normal',
            textDecoration: this.currentProjectTextItem
              ? textDecoration
              : 'normal',
            color: this.currentProjectTextItem ? getRgbColor(color) : '#000000',
            backgroundColor: this.currentProjectTextItem
              ? getRgbColor(this.currentProjectTextItem.backgroundColor)
              : 'transparent',
          }}
          onKeyUp={this.handleChangeText}
          onKeyDown={this.handleChangeText}
          onFocus={this.handleFocusText}
        />
        {this.state.trimContent.length >= TEXT_CONTENT_LIMIT && (
          <span style={{ fontSize: 12, color: '#dc3545' }}>
            Maximum allowed characters reached
          </span>
        )}
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  selectedTextIndex: state.selectedItems.selectedTextIndex,
  selectedText: state.selectedItems.selectedText,
  project: state.editor.project,
  currentTextItem: state.mainContainer.currentTextItem,
})

const mapDispatchToProps = (dispatch) => ({
  setSelectedText: (...args) =>
    dispatch(SelectedItemActions.setSelectedText(...args)),
  resetAllSelected: (...args) =>
    dispatch(SelectedItemActions.resetAllSelected(...args)),
  toggleWatchProject: (...args) => dispatch(toggleWatchProject(...args)),
  updateProject: (...args) => dispatch(updateProject(...args)),
  storeProjectSteps: (...args) => dispatch(storeProjectSteps(...args)),
  addTexts: (...args) => dispatch(addTexts(...args)),
})

export default connect(mapStateToProps, mapDispatchToProps)(EditorTextEditPanel)
