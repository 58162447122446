export const EditIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="11.75" cy="11.75" r="9.75" fill="#1094F4" />
      <path
        d="M7.57882 17.0044C7.41518 17.0041 7.25919 16.9351 7.1489 16.8143C7.03658 16.6944 6.98076 16.5322 6.99548 16.3686L7.1384 14.7971L13.7406 8.19725L15.8038 10.2599L9.2034 16.8592L7.6319 17.0021C7.61382 17.0038 7.59573 17.0044 7.57882 17.0044ZM16.2157 9.8475L14.153 7.78484L15.3902 6.54759C15.4997 6.43805 15.6481 6.3765 15.8029 6.3765C15.9578 6.3765 16.1062 6.43805 16.2157 6.54759L17.4529 7.78484C17.5624 7.89425 17.624 8.04272 17.624 8.19755C17.624 8.35237 17.5624 8.50084 17.4529 8.61025L16.2162 9.84692L16.2157 9.8475Z"
        fill="white"
      />
    </svg>
  )
}
