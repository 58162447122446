import React, { Component } from 'react'
import { connect } from 'react-redux'
import { setProjectCategory } from '../../redux/actions/RecorderActions'
import {
  CorporateImg,
  EducationImg,
  PersonalImg,
  SocialImg,
} from '../../components/home/HomeIcons'
import { triggerGoogleAnalyticsEvent } from '../../utils/Helper'

class WelcomeFrame extends Component {
  constructor(props) {
    super(props)

    this.state = {
      CategoryList: ['Education', 'Corporate', 'Social Media', 'Personal'],
      CategoryIcons: [EducationImg, CorporateImg, SocialImg, PersonalImg],
    }
  }

  /**
   * click category
   *
   * @param category
   */
  onClickList = (category) => {
    triggerGoogleAnalyticsEvent('project_category_selected', {
      value: category,
      userId: this.props.authUser?.id,
    })
    this.props.setProjectCategory(category)
    this.props.onCardSelect(1)
  }

  render() {
    const { CategoryList, CategoryIcons } = this.state

    return (
      <div className={'w-full flex justify-center h-full'}>
        <div className="w-full overflow-auto">
          <div
            className={
              'xs:text-3xl sm:text-4xl md:text-5xl font-bold text-center'
            }>
            Welcome!
          </div>

          <p className="xs:text-lg sm:text-xl md:text-2xl mt-2 text-center">
            We're happy to have you here.
            <br /> Are your video projects for:
          </p>

          <ul className="w-full xs:text-lg sm:text-xl md:text-2xl xs:mt-4 md:mt-5 lg:mt-10 grid xs:grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-10 xs:gap-2 sm:gap-4 md:gap-6 xl:gap-10 px-8 xxl:px-20">
            {Array.apply(null, CategoryList).map((x, i) => {
              return (
                <li
                  className={
                    'cursor-pointer mb-3 text-center h-64 xs:h-32 sm:h-32 md:h-64 xs:px-4 xl:px-8' +
                    (this.props.category === x ? ' selected-category' : '')
                  }
                  key={i}
                  onClick={() => this.onClickList(x)}>
                  <div className="hover:bg-gray-300 hover:text-green-500 h-full rounded bg-purple-100">
                    <div className="w-full h-48 xs:h-24 sm:h-24 md:h-48 flex justify-center items-center">
                      <img src={CategoryIcons[i]} alt="" />
                    </div>
                    <div>{x}</div>
                  </div>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  authUser: state.auth.user,
  category: state.recorder.category,
})

export default connect(mapStateToProps, { setProjectCategory })(WelcomeFrame)
