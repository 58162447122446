// Authentication
export const GET_SIGNIN_ERRORS = 'get-signin-errors'
export const SIGNIN_LOADING = 'signin-loading'
export const GET_SIGNUP_ERRORS = 'get-register-errors'
export const SIGNUP_LOADING = 'signup-loading'
export const CLEAR_AUTH_ERRORS = 'clear-auth-errors'
export const SET_CURRENT_USER = 'set-authenticated-user'
export const SET_USER_PROFILE = 'set-user-profile'
export const UPDATE_REMOTE_USER_LOAD_STATUS = 'update-remote-user-load-status'
export const REMOTE_USER_LOADED = 'remote-user-loaded'
export const PASSWORD_SET_ERROR = 'password-set-error'
export const UPDATE_BOARDING = 'update-boarding'
export const SIGNOUT_USER = 'signout-user'
export const SET_COUPON = 'set-coupon'

// Main
export const FULL_SCREEN_SET = 'set-full-screen'

// Media
export const CURRENT_SET = 'CURRENT_SET'
export const CATEGORY_SET = 'CATEGORY_SET'
export const FEATURE_SET = 'FEATURE_SET'
export const UPLOAD_SET = 'UPLOAD_SET'
export const PUBLIC_SET = 'PUBLIC_SET'
export const MENU_IS_OPEN_SET = 'MENU_IS_OPEN_SET'
export const MENU_IS_TEMPLATE_SAVE = 'MENU_IS_TEMPLATE_SAVE'
export const RUNNING_COUNTDOWN_TIMER = 'countdown-timer-available'
export const APPEAR_LOWER_NOTIFY = 'show-lower-notify'
export const UPDATE_ZOOM_ABLE_STATUS = 'set-zoom-able-camera-status'
export const SET_RECORDING = 'RECORDER/SET_RECORDING'
export const CROPPING_FINISHED = 'RECORDER/CROPPING_FINISHED'
export const REMOVE_ASPECT_SIZE = 'REMOVE_ASPECT_SIZE'
export const APPLY_RATIO_TO_SEGMENTATIONS = 'APPLY_RATIO_TO_SEGMENTATIONS'
export const SET_ACTIVE_SEGMENTATION_SCENE = 'SET_ACTIVE_SEGMENTATION_SCENE'

// Timeline
export const SET_PREVIEW_TIMELINE = 'set-preview-timeline'
export const RELOAD_PREVIEW_TIMELINE = 'reload-preview-timeline'

// Editor
export const CHECK_ADD_ITEM = 'check-add-item'
export const CHECK_Select_ITEM = 'check-select-item'
export const SET_PROJECT = 'set-project'
export const SET_TIME_SLIDER = 'set-time-slider'

export const CAMERA_SHOOT_VALUE_SET = 'CAMERA_SHOOT_VALUE_SET'
export const CAMERA_SHOT_DIRECTION_SET = 'CAMERA_SHOT_DIRECTION_SET'
export const MEDIA_RESOLUTION_SET = 'MEDIA_RESOLUTION_SET'
export const SELECTED_MEDIA_LAYOUT_SET = 'SELECTED_MEDIA_LAYOUT_SET'
export const SAVE_MEDIA_LAYOUT_SET = 'SAVE_MEDIA_LAYOUT_SET'

// Project
export const SET_ALL_PROJECTS = 'SET_ALL_PROJECTS'
export const RENAME_PROJECT = 'RENAME_PROJECT'
export const DELETE_PROJECT = 'DELETE_PROJECT'

// Subscription
export const SET_USER_SUBSCRIPTION = 'set-user-subscription'
export const SET_USER_SUBSCRIPTION_LOADING = 'set-user-subscription-loading'
export const SET_SUBSCRIPTION_PRODUCTS = 'set-subscription-products'
export const STRIPE_ERROR = 'stripe-error'
export const SET_PRODUCT_FEATURES = 'set-product-features'
export const SET_USER_SUBSCRIPTION_FEATURES = 'set-user-subscription-features'

// Onboard
export const SET_ONBOARDING = 'set-onboarding'
