//Main Container Reducers
import _ from 'lodash'
import { RATIO } from '../../constants/AspectRatios'
import * as Constants from '../constants/ProjectEditConstants'

const initialState = {
  defaultLayout: RATIO.LANDSCAPE,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case Constants.SET_DEFAULT_LAYOUT:
      return {
        ...state,
        defaultLayout: action.defaultLayout,
      }
    default:
      return state
  }
}
