import React, { Component } from 'react'
import _ from 'lodash'
import { isIOS, isMobileSafari } from 'react-device-detect'
import { connect } from 'react-redux'
import Spinner from '../common/Spinner'
import RatioAction from '../video-capture/common-items/RatioAction'
import {
  ZOOM_LEVEL_OBJECT_FIT,
  ZOOM_LEVEL_TRANSFORM,
} from '../../constants/ZoomLevel'

class CropperPreview extends Component {
  replayVideo = this.props.replayVideo

  componentDidMount() {
    this.setState({
      videoUrl: this.props.videoUrl || '',
    })

    if (this.props.isFaceTrackingEnabled) {
      // this.replayVideo.pause()
      // this.props.updateVideoCropTime(this.replayVideo.currentTime)
    }
  }

  componentDidUpdate(prevProps) {
    this.props.updateReplayVideoRef(this.replayVideo)
    if (prevProps.isFaceTrackingEnabled && this.props.isFaceTrackingEnabled)
      return // only continue if there is a change

    if (this.props.isFaceTrackingEnabled) {
      // this.replayVideo.pause()
      // this.props.updateVideoCropTime(this.replayVideo.currentTime)
      //quick and dirty solution to fix a/v sync issue in tracked vid
      //const trackedVideoDuration = (this.replayVideo.duration - this.replayVideo.currentTime)
      //const skippedTime = trackedVideoDuration/17.5;
      //this.props.updateVideoCropTime(this.replayVideo.currentTime -skippedTime)
    } else {
      // this.replayVideo.play()
      this.props.updateVideoCropTime(null)
    }
  }

  handleVideoLoaded = () => {
    const { onLoaded } = this.props
    onLoaded && onLoaded(true)
  }

  isLoading() {
    return _.isNull(this.props.cropperActionWrapper.current)
  }

  getTransform = () => {
    const { scaleValue } = this.props
    const transform = ZOOM_LEVEL_TRANSFORM[scaleValue]
    return transform
  }

  getObjectFit = () => {
    const { scaleValue } = this.props
    const objectFit = ZOOM_LEVEL_OBJECT_FIT[scaleValue]
    return objectFit
  }

  render() {
    const {
      videoUrl,
      isFaceTrackingEnabled,
      showSnapGrid,
      layoutChangedFromDropDown,
      changeLayoutFromDropdown,
    } = this.props
    return (
      <div
        className="w-full h-full flex justify-center items-center"
        ref={this.props.cropperActionWrapper}>
        <video
          className="absolute w-full h-full cursor-pointer"
          ref={(el) => (this.replayVideo = el)}
          src={videoUrl}
          playsInline
          muted={true}
          onLoadedMetadata={this.handleVideoLoaded}
          controls={isIOS || isMobileSafari}
          style={{
            objectFit: this.getObjectFit(),
            transform: this.getTransform(),
            transformOrigin: 'center',
          }}>
          <source src={videoUrl} type="video/mp4" />
        </video>
        {this.props.cropperActionWrapper.current && this.replayVideo && (
          <RatioAction
            ratioToEdit={this.props.ratioToEdit}
            currentVideoId={this.props.currentVideoId}
            selectedRatio={this.props.selectedRatio}
            resetSelectedAspectRatios={this.props.resetSelectedAspectRatios}
            selectRatio={this.props.selectRatio}
            cameraVideo={this.replayVideo}
            videoActionWrapper={this.props.cropperActionWrapper}
            direction="center"
            layoutChangedFromDropDown={layoutChangedFromDropDown}
            changeLayoutFromDropdown={changeLayoutFromDropdown}
            moveRef={this.props.moveRef}
            selNextPopup={this.props.selNextPopup}
            clearEditRatio={this.props.clearEditRatio}
          />
        )}
        {this.isLoading() && <Spinner />}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  isFaceTrackingEnabled: state.faceTrack.isFaceTrackingEnabled,
  snapGridPosition: state.recorder.snapGridPosition,
})

export default connect(mapStateToProps)(CropperPreview)
