export const PaymentConstants = {
  locationURL: 'https://ipwho.is/?fields=country_code,continent',
  initiatePaymentURL: '/stripe/initiate-payment',
  createSubscriptionURL: '/stripe/create-subscription',
  configURL: '/stripe/config',
  regions: {
    uk: 'uk',
    europe: 'europe',
    common: 'common',
  },
  subscriptionDuration: {
    monthly: 'monthly',
    annually: 'annually',
    pay_as_you_go: 'pay_as_you_go',
  },
  subscriptionProduct: {
    studio: 'Studio',
    pro: 'Pro',
    trial: 'Trial',
  },
  subscriptionURLQueries: {
    region: 'region',
    plan: 'plan',
    duration: 'duration',
  },
  countryCodes: {
    gb: 'gb',
  },
  priceKeys: {
    annually: 'stripe_price_annually',
    monthly: 'stripe_price_monthly',
    pay_as_you_go: 'stripe_price_pay_as_you_go',
  },
}
