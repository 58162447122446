export const TwitchIcon = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_187_11446)">
        <path
          d="M0.0224609 13.4167C0.0224609 8.67091 0.0224609 6.29803 0.960682 4.49188C1.75131 2.96985 2.99231 1.72885 4.51434 0.938221C6.32049 0 8.69337 0 13.4391 0H26.6058C31.3515 0 33.7244 0 35.5306 0.938221C37.0526 1.72885 38.2936 2.96985 39.0842 4.49188C40.0225 6.29803 40.0225 8.67091 40.0225 13.4167V26.5833C40.0225 31.3291 40.0225 33.702 39.0842 35.5081C38.2936 37.0301 37.0526 38.2711 35.5306 39.0618C33.7244 40 31.3515 40 26.6058 40H13.4391C8.69337 40 6.32049 40 4.51434 39.0618C2.99231 38.2711 1.75131 37.0301 0.960682 35.5081C0.0224609 33.702 0.0224609 31.3291 0.0224609 26.5833V13.4167Z"
          fill="#9146FF"
        />
        <path
          d="M27.3592 12.4493H25.2564V18.7577H27.3592V12.4493Z"
          fill="white"
        />
        <path
          d="M19.4737 12.4493H21.5765V18.7577H19.4737V12.4493Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12.6398 6.66667L7.38281 11.9236V30.8486H13.6912V36.1056L18.9481 30.8486H23.1537L32.6162 21.3861V6.66667H12.6398ZM30.5134 20.3347L26.3078 24.5403H22.1023L18.4224 28.2202V24.5403H13.6912V8.76945H30.5134V20.3347Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_187_11446">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
