import _ from 'lodash'
import { PIXEL_QUALITY } from '../constants/Resolutions'

export const getGridSize = (availableGridSizes, resolution, selectedLayout) => {
  const pixelQualityByResolution = PIXEL_QUALITY[resolution]
  return _.get(
    availableGridSizes,
    `${pixelQualityByResolution}.${selectedLayout}`,
  )
}
