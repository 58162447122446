import {
  DELETE_PROJECT,
  RENAME_PROJECT,
  SET_ALL_PROJECTS,
} from '../../redux/actions/Types'

export const setProjectsAction = (projectsList) => {
  return (dispatch) => {
    dispatch({
      type: SET_ALL_PROJECTS,
      projectsList: projectsList,
    })
  }
}

export const renameProjectAction = (projectId, name) => {
  return (dispatch) => {
    dispatch({
      type: RENAME_PROJECT,
      projectId,
      name,
    })
  }
}

export const deleteProjectAction = (projectId) => {
  return (dispatch) => {
    dispatch({
      type: DELETE_PROJECT,
      projectId,
    })
  }
}
