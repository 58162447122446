import {
  REMOTE_USER_LOADED,
  PASSWORD_SET_ERROR,
} from '../../redux/actions/Types'

export default (state = { errors: {} }, action) => {
  switch (action.type) {
    case REMOTE_USER_LOADED: {
      return {
        ...state,
        orderKey: action.payload.orderKey,
        name: action.payload.name,
        email: action.payload.email,
        errors: {},
      }
    }

    case PASSWORD_SET_ERROR: {
      return {
        ...state,
        errors: action.payload,
      }
    }
    default:
      return state
  }
}
