import instance from '../../utils/Axios'

import {
  GET_SIGNIN_ERRORS,
  SET_SUBSCRIPTION_PRODUCTS,
  SET_USER_SUBSCRIPTION,
  SET_PRODUCT_FEATURES,
  STRIPE_ERROR,
  SET_USER_SUBSCRIPTION_FEATURES,
  SET_USER_SUBSCRIPTION_LOADING,
} from './Types'

export const currentUserSubscription = () => async (dispatch) => {
  dispatch({
    type: SET_USER_SUBSCRIPTION_LOADING,
    payload: true,
  })
  return instance
    .get('/subscriptions/user')
    .then((res) => {
      dispatch({
        type: SET_USER_SUBSCRIPTION,
        payload: res.data?.subscription,
      })
      dispatch({
        type: SET_USER_SUBSCRIPTION_FEATURES,
        payload: res.data?.hasFeatures,
      })
      return res.data
    })
    .catch((err) => {
      dispatch({
        type: GET_SIGNIN_ERRORS,
        payload: err.response ? err.response.data : err,
      })
    })
    .finally(() => {
      dispatch({
        type: SET_USER_SUBSCRIPTION_LOADING,
        payload: false,
      })
    })
}

export const getAllSubscriptionProducts = () => async (dispatch) => {
  dispatch({
    type: SET_USER_SUBSCRIPTION_LOADING,
    payload: true,
  })
  return instance
    .get('/subscriptions/products')
    .then((res) => {
      dispatch({
        type: SET_SUBSCRIPTION_PRODUCTS,
        payload: res.data,
      })
      return res.data
    })
    .catch((err) =>
      dispatch({
        type: GET_SIGNIN_ERRORS,
        payload: err.response ? err.response.data : err,
      }),
    )
    .finally(() => {
      dispatch({
        type: SET_USER_SUBSCRIPTION_LOADING,
        payload: false,
      })
    })
}

export const getProductFeatures = () => async (dispatch) => {
  return instance
    .get('/subscriptions/features')
    .then((res) => {
      dispatch({
        type: SET_PRODUCT_FEATURES,
        payload: res.data,
      })
      return res.data
    })
    .catch((err) =>
      dispatch({
        type: STRIPE_ERROR,
        payload: err.response ? err.response.data : err,
      }),
    )
}
