import React, { Component } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import GoogleAnalytics from './GoogleAnalytics' // import the component
// Utils
import jwt_decode from 'jwt-decode'
import setAuthToken from './utils/SetAuthToken'
// Redux
import store from './redux'
import { logoutUser, setCurrentUser } from './redux/actions/AuthActions'
import { connect } from 'react-redux'
// Routers
import * as ROUTES from './constants/Routes'
// Components
import SignInPage from './components/auth/SignIn'
import SignUpPage from './components/auth/SignUp'
import RemoteWoocommerceSignUp from './components/auth/RemoteWoocommerceSignUp'
// import Navigation from './components/navigation/Navigation';
import PasswordForgetPage from './components/auth/PasswordForget'
import PasswordReset from './components/auth/PasswordReset'
import PrivateRoute from './components/private-route/PrivateRoute'
import HomePage from './pages/home/Home'
import MediaPublisher from './components/video-publisher/MediaPublisher'
import Cropper from './pages/home/Cropper'
import NotFound from './components/404/404'
import VideoCapture from './pages/recorder/VideoCapture'
import MediaEditor from './pages/editor/MediaEditor'

// admin components
import AdminRoute from './components/admin/AdminRoute'
import AdminListUsers from './components/admin/users/ListUsers'
import AdminShowUser from './components/admin/users/ShowUser'
import LandingPageNew from './pages/home/LandingPageNew'
import CouponPage from './pages/main/couponpage'
import ConfirmUser from './components/auth/ConfirmUser'
import Subscribe from './pages/subscribe'
import CheckoutCancelPage from './pages/checkout/Cancel'
import Pricing from './pages/pricing'

// Sentry.init({
//   dsn:
//     'https://ee3bc7ad2c0e4d338a863999e9c44745@o437925.ingest.sentry.io/5401034',
//   integrations: [new Integrations.BrowserTracing()],
//   tracesSampleRate: 1.0,
// });

// Check for token to keep user logged in
if (localStorage.jwtTokenCTEditor) {
  // Set auth token header auth
  const token = localStorage.jwtTokenCTEditor
  setAuthToken(token)

  // Decode token and get user info and exp
  const decoded = jwt_decode(token)

  // Set user and isAuthenticated
  store.dispatch(setCurrentUser(decoded))

  // Check for expired token
  const currentTime = Date.now() / 1000 // to get in milliseconds
  if (decoded.exp < currentTime) {
    // Logout user
    store.dispatch(logoutUser())
  }
}

class App extends Component {
  render() {
    return (
      <Router>
        <div className="App">
          {/* {!this.props.fullscreen && <Navigation />} */}
          <GoogleAnalytics /> {GoogleAnalytics}
          <Switch>
            <Route exact path={ROUTES.LANDING} component={LandingPageNew} />
            <Route exact path={ROUTES.SIGN_IN} component={SignInPage} />
            <Route exact path={ROUTES.SIGN_UP} component={SignUpPage} />
            <Route exact path={ROUTES.CONFIRM_USER} component={ConfirmUser} />
            <Route exact path={ROUTES.COUPON} component={LandingPageNew} />
            <Route path={ROUTES.COUPON_CODE} component={LandingPageNew} />
            <Route
              path={ROUTES.WOOCOMMERCE_SIGN_UP}
              component={RemoteWoocommerceSignUp}
            />

            <Route
              exact
              path={ROUTES.PASSWORD_FORGET}
              component={PasswordForgetPage}
            />

            <Route
              exact
              path={ROUTES.PASSWORD_RESET}
              component={PasswordReset}
            />

            <PrivateRoute exact path={ROUTES.SUBSCRIBE} component={Subscribe} />
            <Route exact path={ROUTES.PRICING} component={Pricing} />

            <PrivateRoute exact path={ROUTES.HOME} component={HomePage} />
            <PrivateRoute
              exact
              path={ROUTES.MEDIA_EDITOR}
              component={MediaEditor}
            />

            <PrivateRoute
              exact
              path={ROUTES.CAMERA_VIEW}
              component={VideoCapture}
            />

            <PrivateRoute
              exact
              path={ROUTES.MEDIA_PUBLISHER}
              component={MediaPublisher}
            />

            <PrivateRoute
              exact
              path={ROUTES.CHECKOUT_CANCEL}
              component={CheckoutCancelPage}
            />

            <AdminRoute exact path={ROUTES.ADMIN} component={AdminListUsers} />

            <AdminRoute
              exact
              path={ROUTES.ADMIN_LIST_USERS}
              component={AdminListUsers}
            />
            <AdminRoute
              path={ROUTES.ADMIN_SHOW_USER}
              component={AdminShowUser}
            />

            <Route path={ROUTES.CROPPER} component={Cropper} />

            <Route component={NotFound} />
          </Switch>
        </div>
      </Router>
    )
  }
}

const mapStateToProps = (state) => ({
  fullscreen: state.main.fullscreen,
})

export default connect(mapStateToProps)(App)
