import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import queryString from 'query-string'

import instance from '../../utils/Axios'
import { updateProject } from '../../redux/actions/EditorActions'
// import { Checkbox } from '@chakra-ui/checkbox'
import { Checkbox } from '@chakra-ui/checkbox'

class MediaPublisher extends Component {
  constructor(props) {
    super(props)
    this.state = {
      facebook: false,
      twitter: false,
      linkedin: false,
      showPublisher: false,
      youtube_URL: '',
    }
  }

  loadFacebookSdk = () => {
    window.fbAsyncInit = () => {
      window.FB.init({
        appId: '299988408008209',
        cookie: true,
        xfbml: false,
        version: 'v8.0',
      })
      window.FB.AppEvents.logPageView()
    }
    ;(function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0]
      if (d.getElementById(id)) {
        return
      }
      js = d.createElement(s)
      js.id = id
      js.src = 'https://connect.facebook.net/en_US/sdk.js'
      fjs.parentNode.insertBefore(js, fjs)
    })(document, 'script', 'facebook-jssdk')
  }

  togglePublishingPlatform = (event) => {
    this.setState({ [event.target.name]: event.target.checked })
  }

  componentDidMount() {
    this.mounted = true
    let projectVal = this.props.project

    this.props.updateProject(projectVal)
    //this.loadFacebookSdk();
    setTimeout(() => {
      if (this.mounted) {
        this.setState({
          showPublisher: true,
        })
        this.publishToYouTubeAfterAuthentication()
      }
    }, 200)
  }

  /* eslint-disable */
  //publishToFacebook = (response) => {
    //const { authResponse } = response;
    //console.log(authResponse);
    //window.FB.api(
      //`/${authResponse.userID}/videos`,
      //'POST',
      //{
        //file_url: this.props.project.scenes[0].originUrl,
        //access_token: authResponse.accessToken,
      //},
      //(response) => {
        //console.log(response);
      //},
    //);
  //};
  /* eslint-enable */

  startPublishingDialog = (event) => {
    console.log('fb not implemented')
    return
    /* eslint-disable */
    //window.FB.getLoginStatus((response) => {
      //if (response.status === 'connected') {
        //this.publishToFacebook(response);
      //} else {
        //window.FB.login(this.publishToFacebook, {
          //scope: 'public_profile,email,user_videos',
          //return_scopes: true,
        //});
      //}
    //});
    /* eslint-enable */
  }

  publishToTwitter_url = (event, originUrl) => {
    event.preventDefault()
    var twitterWindow = window.open(
      `https://twitter.com/share?url=${originUrl}`,
      'twitter-popup',
      'height=350,width=600',
    )
    if (twitterWindow.focus) {
      twitterWindow.focus()
    }
    return false
  }

  publishToYouTube = (videoUrl) => {
    const url = new URL(videoUrl)
    const yt_url = 'https://youtu.be/'

    instance
      .post('/social/youtube/publish', {
        fileKey: url.pathname.replace('/', ''), // We should ideally be sending database id of rendered video
      })
      .then((response) => {
        const data = response.data
        if (data.status === 'published') {
          console.log('video published')
          this.setState({
            youtube_URL: yt_url + data.video.id,
          })
          console.log(yt_url + data.video.id)
        }

        if (data.status === 'auth-required') {
          window.location.href = data.auth_url
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  publishToYouTubeAfterAuthentication = () => {
    const scene = this.props.project.scenes[0]

    const queryParams = queryString.parse(this.props.location.search)
    const stateParams = queryString.parse(queryParams.state)

    if (queryParams.error) {
      // TODO: WA: Handle case where user has denied access to youtube.
      console.log('something went wrong')
      return
    }
    if (queryParams.code) {
      // TODO: WA: Handle case where user has not given upload permission.
      instance
        .post('/social/youtube/exchange-code', {
          code: queryParams.code,
          s: stateParams.s,
        })
        .then((response) => {
          this.publishToYouTube(scene.originUrl)
        })
        .catch((error) => {
          console.log('could not retrieve access token')
        })
    }
  }

  render() {
    const scene = this.props.project.scenes[0]
    const { facebook, twitter, linkedin } = this.state

    if (scene) {
      //console.log(this.props.project);
      return (
        <div className="w-full h-screen absolute top-0 left-0 flex justify-center overflow-auto">
          <div className="main-container">
            <div className="video-wrap">
              <div className="w-full h-full slides">
                <video
                  className="slide-background-video w-full h-full"
                  playsInline={true}
                  controls={true}
                  preload="metadata"
                  poster={scene.thumbUrl}>
                  <source src={scene.originUrl} />
                </video>
              </div>
            </div>

            <div className="w-full flex justify-center h-full overflow-auto">
              <div className="app-layout">
                <div className="flex justify-center">
                  <button
                    type="button"
                    className="btn-publish"
                    onClick={this.startPublishingDialog}>
                    Publish
                  </button>
                  <button
                    type="button"
                    className="btn-publish"
                    onClick={() => {
                      this.publishToYouTube(scene.originUrl)
                    }}>
                    Publish to YouTube
                  </button>
                  <button
                    type="button"
                    className="btn-publish"
                    onClick={(event) => {
                      this.publishToTwitter_url(event, scene.originUrl)
                    }}>
                    Publish to Twitter
                  </button>
                  <div>
                    <p> Shared links: </p>
                    <input
                      type="text"
                      value={this.state.youtube_URL}
                      readOnly={true}
                      style={{ height: 'auto' }}
                    />
                  </div>
                  <a
                    type="button"
                    href={scene.originUrl}
                    download={true}
                    target="_blank"
                    rel="noopener noreferrer">
                    Download
                  </a>
                </div>
              </div>
              <ul className="w-full flex text-sm grid-cols-4 gap-10">
                <li className="cursor-pointer mb-3 text-center">
                  <label>
                    FB
                    <Checkbox
                      name="facebook"
                      checked={facebook}
                      onChange={this.togglePublishingPlatform}
                    />
                  </label>
                </li>
                <li className="cursor-pointer mb-3 text-center">
                  <label>
                    TW
                    <Checkbox
                      name="twitter"
                      checked={twitter}
                      onChange={this.togglePublishingPlatform}
                    />
                  </label>
                </li>
                <li className="cursor-pointer mb-3 text-center">
                  <label>
                    LN
                    <Checkbox
                      name="linkedin"
                      checked={linkedin}
                      onChange={this.togglePublishingPlatform}
                    />
                  </label>
                </li>
              </ul>
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div className="w-full h-screen absolute top-0 left-0 flex justify-center overflow-auto">
          Hello
        </div>
      )
    }
  }
}

export default connect(
  (state) => ({
    project: state.editor.project,
  }),
  { updateProject },
)(withRouter(MediaPublisher))
