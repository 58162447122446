import { isMobile } from 'react-device-detect'
import * as AspectRatioService from '../../services/AspectRatioService'
import { triggerGoogleAnalyticsEvent } from '../../utils/Helper'

/**
 * get replay video show size
 * @param currentLayout
 * @returns {{sHeight: number, top: number, left: number, clientH: number, clientW: number, sWidth: number}}
 */
export function getShowSize(currentLayout) {
  const { cameraVideo, videoActionWrapper, shoot_value, zoomAbleState } =
    this.props

  const clientRect = videoActionWrapper?.current?.getBoundingClientRect()

  let showVideoW = clientRect.width
  let showVideoH = clientRect.height
  let tlPos = {
    left: 0,
    top: 0,
  }

  let updatedSize = false
  if (
    showVideoW < cameraVideo.videoWidth ||
    showVideoH < cameraVideo.videoHeight
  ) {
    updatedSize = true
  }

  if (
    (!zoomAbleState || isMobile) &&
    !updatedSize &&
    shoot_value[currentLayout] === 'long'
  ) {
    showVideoW = cameraVideo.videoWidth
    showVideoH = cameraVideo.videoHeight
    tlPos.left = Math.round((clientRect.width - showVideoW) / 2)
    tlPos.top = Math.round((clientRect.height - showVideoH) / 2)
  } else {
    const wRate = clientRect.width / cameraVideo.videoWidth
    const hRate = clientRect.height / cameraVideo.videoHeight

    if (wRate > hRate) {
      showVideoW = Math.round(
        (clientRect.height * cameraVideo.videoWidth) / cameraVideo.videoHeight,
      )
      tlPos.left = Math.round((clientRect.width - showVideoW) / 2)
    } else {
      showVideoH = Math.round(
        (clientRect.width * cameraVideo.videoHeight) / cameraVideo.videoWidth,
      )
      tlPos.top = Math.round((clientRect.height - showVideoH) / 2)
    }

    if (!zoomAbleState && shoot_value[currentLayout] === 'close') {
      showVideoW = Math.round(showVideoW * 2)
      showVideoH = Math.round(showVideoH * 2)
      tlPos.left = Math.round((clientRect.width - showVideoW) / 2)
      tlPos.top = Math.round((clientRect.height - showVideoH) / 2)
    }

    if (showVideoW > clientRect.width) {
      showVideoW = clientRect.width
      tlPos.left = 0
    }

    if (showVideoH > clientRect.height) {
      showVideoH = clientRect.height
      tlPos.top = 0
    }
  }

  return {
    sWidth: showVideoW,
    sHeight: showVideoH,
    left: tlPos.left,
    top: tlPos.top,
    clientW: clientRect.width,
    clientH: clientRect.height,
  }
}

/**
 * get default aspect ratio size
 * @param s
 * @returns {{width: number, height: number}|{width: number, height: number}|{width: number, height: number}|{width: number, height: number}|{width: number, height: number}|{width: number, height: number}|{width: number, height: number}|{width: number, height: number}|{width: number, height: number}|{width: number, height: number}|{width: number, height: number}|{width: number, height: number}|*}
 */
export function getRectSize(s) {
  const quality = {
    1080: 0,
    720: 1,
    480: 2,
  }

  return this.props.availGridSizes[quality[this.props.resolution]][s]
}

/**
 * get snap grid box size
 * @param s
 * @param sWidth
 * @param sHeight
 * @returns {{width: number, height: number}}
 */
export function getBoxSize(s, sWidth, sHeight) {
  const { aspectSize } = this.props

  const rectSize = this.getRectSize(s)

  let boxH
  let boxW
  if (
    aspectSize[s].width !== 0 &&
    aspectSize[s].height !== 0 &&
    aspectSize[s].width <= sWidth &&
    aspectSize[s].height <= sHeight
  ) {
    boxW = aspectSize[s].width
    boxH = aspectSize[s].height
  } else {
    if (sWidth > sHeight) {
      boxH = isMobile ? sHeight - 90 : Math.round(sHeight / 2)
      boxW = Math.round((boxH * rectSize.width) / rectSize.height)
    } else {
      boxW = isMobile ? sWidth - 90 : Math.round(sWidth / 2)
      boxH = Math.round((boxW * rectSize.height) / rectSize.width)
    }
  }

  return {
    width: boxW,
    height: boxH,
  }
}

/**
 * set snap grid box position
 * @param transform
 */
export function setStyle(transform) {
  const { snapGridPosition } = this.props

  if (this._isMounted) {
    this.setState({
      boxTransform: transform,
    })
  }

  if (snapGridPosition.set) {
    const { currentLayout } = this.props

    this.props.setSnapGridTransform(transform, currentLayout)
  }
}

/**
 * set snap grid size
 * @param width
 * @param height
 * @param currentLayout
 */
export function setSnapSize(width, height, currentLayout) {
  const { setVideoAspectSize, currentVideoId } = this.props

  if (width === 0 && height === 0) {
    setVideoAspectSize(
      {
        width: 0,
        height: 0,
        top: 0,
        left: 0,
      },
      currentLayout,
      currentVideoId,
    )
  } else {
    if (!this.moveRef.current) {
      return
    }

    const { top, left } = this.getShowSize(currentLayout)
    const rect = this.moveRef.current.getBoundingClientRect()

    setVideoAspectSize(
      {
        width: width,
        height: height,
        top: rect.top - top,
        left: rect.left - left,
      },
      currentLayout,
      currentVideoId,
    )
  }
}

/**
 * handler when snap box moved
 * @param transform
 */
export function handleDrag(transform) {
  this.setStyle(transform)

  if (!this.moveRef.current) {
    return
  }

  const { direction } = this.props
  const { real_show_w, real_show_h } = this.state
  const { top, left } = this.moveRef.current.getBoundingClientRect()

  let leftV = left
  if (direction === 'center') {
    leftV = left + real_show_w / 2
  } else if (direction === 'left') {
    leftV = left + real_show_w
  }

  this.setState({
    gridTop: top + real_show_h / 2,
    gridLeft: leftV,
  })
  this.props.setSnapGridPosition({
    top: top + real_show_h / 2,
    left: leftV,
  })
}

/**
 * handler when snap grid resize
 * @param w
 * @param h
 */
export function handleResize(w, h, isFromMoveableComponent = false) {
  if (!isFromMoveableComponent) {
    return
  }

  let ratio = AspectRatioService.getAspectRatio(w, h)
  const rectangleSize = this.getRectSize(ratio)

  const { sWidth, sHeight } = this.getShowSize(ratio)

  let rW = w
  let rH = Math.round((w * rectangleSize.height) / rectangleSize.width)
  if (w < h) {
    rH = h
    rW = Math.round((h * rectangleSize.width) / rectangleSize.height)
  }

  if (rW > sWidth) {
    rW = sWidth
    rH = Math.round((rW * rectangleSize.height) / rectangleSize.width)
  }

  if (rH > sHeight) {
    rH = sHeight
    rW = Math.round((rH * rectangleSize.width) / rectangleSize.height)
  }

  if (this.props.currentLayout === ratio) {
    const currentTransformStyle = this.moveRef.current.style.transform
    this.moveRef.current.style.width = rW + 'px'
    this.moveRef.current.style.height = rH + 'px'
    this.moveRef1.current.style.opacity = 1

    this.props.setDefaultLayout(ratio)
    this.props.setSelectedMediaLayout(true, ratio)
    //this.props.setSaveRatioLayout(true, ratio) //TODO: stop saving layout with resize or move
    this.props.setSnapGridTransform(currentTransformStyle, ratio)
    this.props.setCurrentLayout(ratio)
    this.props.setFaceTrackingRatio(ratio)

    if (this._isMounted) {
      const gridBoxRect = this.moveRef.current
      const gridBoxRectState = gridBoxRect
        ? {
            gridTop: gridBoxRect.getBoundingClientRect().top + rH / 2,
            gridLeft: gridBoxRect.getBoundingClientRect().left + rW / 2,
          }
        : {}
      localStorage.setItem('real_show_w', rW)
      localStorage.setItem('real_show_h', rH)

      this.setState({
        real_show_w: rW,
        real_show_h: rH,
        currentLayout: ratio,
        saveGrid: false,
        showSaveTip: false,
        ...gridBoxRectState,
      })
    }
  } else {
    this.moveRef.current.style.width =
      localStorage.getItem('real_show_w') + 'px'
    this.moveRef.current.style.height =
      localStorage.getItem('real_show_h') + 'px'
  }
}

/**
 * save snap grid size per selected ratio
 */
export function checkSaved() {
  if (this._isMounted) {
    this.setState({
      saveGrid: true,
      savedPopup: true,
      showSaveTip: false,
      selNextPopup: false,
    })
  }

  let { real_show_w, real_show_h } = this.state
  real_show_h = real_show_h
    ? real_show_h
    : Number(localStorage.getItem('real_show_h'))
  real_show_w = real_show_w
    ? real_show_w
    : Number(localStorage.getItem('real_show_w'))
  const { cameraVideo, currentLayout, currentVideoId } = this.props

  triggerGoogleAnalyticsEvent('save_ratio_layout', {
    value: currentLayout,
    userId: this.props.authUser?.id,
  })

  this.props.setDefaultLayout(currentLayout)
  this.props.setSelectedMediaLayout(true, currentLayout)

  this.props.setSaveRatioLayout(true, currentLayout, currentVideoId)

  const { sWidth, sHeight, clientW, clientH } = this.getShowSize(currentLayout)

  this.props.setMediaStreamSize(
    {
      width: sWidth,
      height: sHeight,
    },
    currentLayout,
  )

  this.props.setMediaOriginSize({
    width: cameraVideo.videoWidth,
    height: cameraVideo.videoHeight,
  })

  this.props.setReviewScreenSize(
    {
      width: clientW,
      height: clientH,
    },
    currentLayout,
  )

  this.setSnapSize(real_show_w, real_show_h, currentLayout)

  // setTimeout(() => {
  if (this._isMounted) {
    this.setState({
      savedPopup: false,
      selNextPopup: true,
    })
  }

  setTimeout(() => {
    this.clearSaved()
  }, 1500)
  // }, 1500)
}

/**
 * clear saved check box when saved ratio
 */
export function clearSaved() {
  if (this._isMounted) {
    this.setState({
      saveGrid: false,
      showSaveTip: true,
      selNextPopup: false,
    })
  }

  setTimeout(() => {
    this.toggleShowSaveTip(false)
  }, 1500)
}

/**
 * toggle show save ratio grid tooltip
 * @param flag
 */
export function toggleShowSaveTip(flag) {
  if (this._isMounted) {
    this.setState({
      showSaveTip: flag,
    })
  }
}
