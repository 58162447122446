import React from 'react'
import icon from '../../assets/images/menu.svg'

const SidebarToggle = ({ toggleOpen }) => {
  return (
    <div className="actions-menu-bar absolute z-100">
      <button
        type="button"
        onClick={toggleOpen}
        className="block text-white hover:text-gray-400 focus:text-gray-400 focus:outline-none">
        {/* <img src={icon} alt="menu" className="kebabIcon" /> */}
      </button>
    </div>
  )
}

export default SidebarToggle
