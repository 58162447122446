import React, { Component } from 'react'
import ReactModal from 'react-modal'
import Axios from '../../utils/Axios'

class FeedbackModal extends Component {
  state = {
    title: '',
    message: '',
    loading: false,
  }

  constructor(props) {
    super(props)

    ReactModal.setAppElement('#root')
  }

  titleChangeHandler = (event) => {
    this.setState({
      title: event.target.value,
    })
  }

  messageChangeHandler = (event) => {
    this.setState({
      message: event.target.value,
    })
  }

  sendFeedbackHandler = async () => {
    let { title, message } = this.state

    title = title.trim()
    message = message.trim()

    const payload = {
      title,
      message,
      userAgent: window.navigator.userAgent,
    }

    if (title && message) {
      this.setState({
        loading: true,
      })

      // send post request

      const response = await Axios.post('/users/feedback', payload)

      if (response.status === 200) {
        this.setState({
          title: '',
          message: '',
          loading: false,
        })
        this.props.closeModal()
      } else {
        this.setState({
          loading: false,
        })
      }
    }
  }

  render() {
    const { title, message, loading } = this.state
    return (
      <>
        <input
          className="border rounded border-blue-500 px-4 py-2 w-full mt-4"
          type="text"
          placeholder="Title here..."
          value={title}
          onChange={this.titleChangeHandler}
          readOnly={loading}
        />

        <textarea
          className="border rounded border-blue-500 px-4 py-2 w-full mt-4"
          value={message}
          placeholder="Type message here..."
          style={{
            fontFamily: 'inherit',
            fontSize: 'inherit',
            resize: 'none',
          }}
          rows={5}
          onChange={this.messageChangeHandler}
          readOnly={loading}
        />

        <button
          onClick={this.sendFeedbackHandler}
          className="rounded bg-teal-700 hover:bg-teal-600 py-2 px-3 mr-2 text-white w-full mt-4 disabled:opacity-50"
          disabled={loading}>
          Send Email
        </button>
      </>
    )
  }
}

export default FeedbackModal
