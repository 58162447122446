import _ from 'lodash'
import PLATFORMS from './Platforms'
import { RATIO } from '../../../constants/AspectRatios'

const RENDER_CRITERIAS = [
  {
    platform: PLATFORMS.FACEBOOK,
    ratio: RATIO.SEMI_VERTICAL,
    fps: 25,
    resolution: 1080,
    dimensions: { height: 1350, width: 1080 },
    maxVideoLength: 14400000,
  },
  {
    platform: PLATFORMS.FACEBOOK,
    ratio: RATIO.SQUARE,
    fps: 25,
    resolution: 1080,
    dimensions: { height: 1080, width: 1080 },
    maxVideoLength: 14400000,
  },
  {
    platform: PLATFORMS.INSTAGRAM,
    ratio: RATIO.SQUARE,
    fps: 25,
    resolution: 1080,
    dimensions: { height: 1080, width: 1080 },
    maxVideoLength: 60000,
  },
  {
    platform: PLATFORMS.INSTAGRAM,
    ratio: RATIO.SEMI_VERTICAL,
    fps: 25,
    resolution: 1080,
    dimensions: { height: 1350, width: 1080 },
    maxVideoLength: 60000,
    maxVideoSize: '250gb',
  },
  {
    platform: PLATFORMS.INSTAGRAM,
    ratio: RATIO.FULL_VERTICAL,
    fps: 25,
    resolution: 1080,
    dimensions: { height: 1920, width: 1080 },
    maxVideoLength: 120000,
  },
  // { platform: PLATFORMS.INSTAGRAM, ratio: RATIO.LANDSCAPE, fps: 25, resolution: 1080, dimensions: { height: 1080, width: 1920 }, maxVideoLength: 5400000 },
  {
    platform: PLATFORMS.TWITTER,
    ratio: RATIO.LANDSCAPE,
    fps: 25,
    resolution: 720,
    dimensions: { height: 720, width: 1280 },
    maxVideoLength: 140000,
  },
  {
    platform: PLATFORMS.TWITTER,
    ratio: RATIO.SQUARE,
    fps: 25,
    resolution: 720,
    dimensions: { height: 720, width: 720 },
    maxVideoLength: 140000,
  },
  {
    platform: PLATFORMS.SNAPCHAT,
    ratio: RATIO.FULL_VERTICAL,
    fps: 25,
    resolution: 1080,
    dimensions: { height: 1920, width: 1080 },
    maxVideoLength: 180000,
  },
  {
    platform: PLATFORMS.LINKEDIN,
    ratio: RATIO.LANDSCAPE,
    fps: 25,
    resolution: 1080,
    dimensions: { height: 1080, width: 1920 },
    maxVideoLength: 30000,
  },
  {
    platform: PLATFORMS.LINKEDIN,
    ratio: RATIO.SQUARE,
    fps: 25,
    resolution: 1080,
    dimensions: { height: 1080, width: 1080 },
    maxVideoLength: 30000,
  },
  // { platform: PLATFORMS.TIKTOK, ratio: RATIO.SQUARE, fps: 25, resolution: 1080, dimensions: { height: 1080, width: 1080 }, minVideoLength: 5000, maxVideoLength: 60000 },
  {
    platform: PLATFORMS.TIKTOK,
    ratio: RATIO.FULL_VERTICAL,
    fps: 25,
    resolution: 1080,
    dimensions: { height: 1920, width: 1080 },
    minVideoLength: 5000,
    maxVideoLength: 60000,
  },
  {
    platform: PLATFORMS.YOUTUBE,
    ratio: RATIO.LANDSCAPE,
    fps: 25,
    resolution: 1080,
    dimensions: { height: 1080, width: 1920 },
    maxVideoLength: 5400000,
    maxVideoSize: '128gb',
  },
  {
    platform: PLATFORMS.DOWNLOAD,
    ratio: RATIO.LANDSCAPE,
    fps: 25,
    resolution: 1080,
    dimensions: { height: 1080, width: 1920 },
    maxVideoLength: -1,
  },
  {
    platform: PLATFORMS.DOWNLOAD,
    ratio: RATIO.SEMI_VERTICAL,
    fps: 25,
    resolution: 1080,
    dimensions: { height: 1350, width: 1080 },
    maxVideoLength: -1,
  },
  {
    platform: PLATFORMS.DOWNLOAD,
    ratio: RATIO.FULL_VERTICAL,
    fps: 25,
    resolution: 1080,
    dimensions: { height: 1920, width: 1080 },
    maxVideoLength: -1,
  },
  {
    platform: PLATFORMS.DOWNLOAD,
    ratio: RATIO.SQUARE,
    fps: 25,
    resolution: 1080,
    dimensions: { height: 1080, width: 1080 },
    maxVideoLength: -1,
  },
  {
    platform: PLATFORMS.TWITCH,
    ratio: RATIO.LANDSCAPE,
    fps: 25,
    resolution: 1080,
    dimensions: { height: 1080, width: 1920 },
    maxVideoLength: -1,
  },
]

export const getRatiosByPlatform = (platform) => {
  const filteredRenderCriterias = _.filter(
    RENDER_CRITERIAS,
    (criteria) => criteria.platform === platform,
  )
  const ratios = _.uniq(
    _.flatMap(filteredRenderCriterias, (criteria) => criteria.ratio),
  )
  return ratios
}
export default RENDER_CRITERIAS
