export const EditorDeleteIcon = (props) => {
  return (
    <svg
      {...props}
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.542 2.90769H13.2886C12.9408 1.21656 11.4531 0.00218077 9.72661 0H8.27275C6.54623 0.00218077 5.0586 1.21656 4.71083 2.90769H2.45738C2.05591 2.90769 1.73047 3.23313 1.73047 3.6346C1.73047 4.03606 2.05591 4.36154 2.45738 4.36154H3.18429V13.8115C3.18671 15.8179 4.81258 17.4438 6.81893 17.4462H11.1805C13.1868 17.4438 14.8127 15.8179 14.8151 13.8115V4.36154H15.542C15.9435 4.36154 16.2689 4.0361 16.2689 3.63463C16.2689 3.23317 15.9435 2.90769 15.542 2.90769ZM8.27279 12.3577C8.27279 12.7592 7.94734 13.0846 7.54587 13.0846C7.14437 13.0846 6.81893 12.7592 6.81893 12.3577V7.99618C6.81893 7.59471 7.14437 7.26926 7.54584 7.26926C7.94731 7.26926 8.27275 7.59471 8.27275 7.99618L8.27279 12.3577ZM11.1805 12.3577C11.1805 12.7592 10.855 13.0846 10.4536 13.0846C10.0521 13.0846 9.72665 12.7592 9.72665 12.3577V7.99618C9.72665 7.59471 10.0521 7.26926 10.4536 7.26926C10.855 7.26926 11.1805 7.59471 11.1805 7.99618V12.3577ZM6.21632 2.90769C6.52551 2.03705 7.34889 1.45495 8.27279 1.45383H9.72665C10.6505 1.45495 11.4739 2.03705 11.7831 2.90769H6.21632Z"
        fill="#6B7280"
      />
    </svg>
  )
}
