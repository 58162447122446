import _ from 'lodash'
import { ONE_SECOND } from '../../constants/Timeline'
import RATIO from '../../constants/AspectRatios'
import DIMENSIONS from '../../constants/Dimensions'
import { DEFAULT_TEXT } from '../../constants/TimelineItems'

export function addNewTextItemInProject(project) {
  let newTextItem = _.cloneDeep(DEFAULT_TEXT)
  let updatingProject = _.cloneDeep(project)
  newTextItem.id = createTextItemId()
  _.forEach(RATIO, (ratio) => {
    addTextItemInResolution(updatingProject, newTextItem, ratio)
  })
  return { updatingProject, newTextItem }
}

function createTextItemId() {
  const date_v = new Date()
  return `${date_v.getMilliseconds()}_${date_v.getTime()}`
}

function repositionNewTextItemToLast(lastTextItem, newTextItem) {
  const endTime = lastTextItem.end_time
  newTextItem.start_time = endTime
  newTextItem.end_time = endTime + 5 * ONE_SECOND
  newTextItem.sliderL = lastTextItem.sliderL + lastTextItem.sliderW
  return _.cloneDeep(newTextItem)
}

function addTextItemInResolution(updatingProject, textItem, resolution) {
  let clonedTextItem = _.cloneDeep(textItem)
  const additionalParameters = getDimensions(
    updatingProject,
    textItem,
    resolution,
  )
  clonedTextItem = { ...clonedTextItem, ...additionalParameters }
  const textItemsInResolution = _.get(
    updatingProject,
    `texts.${resolution}`,
    [],
  )
  const lastTextItem = _.last(textItemsInResolution)
  if (lastTextItem) {
    clonedTextItem = repositionNewTextItemToLast(lastTextItem, clonedTextItem)
  }
  _.set(
    updatingProject,
    `texts.${resolution}.${textItemsInResolution.length}`,
    clonedTextItem,
  )
}

function getDimensions(project, textItem, currentRatio) {
  const resolution = project.dimension
  const dimensions = DIMENSIONS[resolution][currentRatio]
  return {
    p_height: dimensions.height,
    p_width: dimensions.width,
    media_layout: currentRatio,
  }
}
