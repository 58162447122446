export const TickIcon = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.0971 1.68932C10.9931 1.61365 10.8749 1.55896 10.7494 1.52836C10.6239 1.49776 10.4935 1.49185 10.3656 1.51098C10.2378 1.5301 10.1151 1.57388 10.0044 1.63982C9.89372 1.70575 9.79731 1.79255 9.72067 1.89524L5.05311 8.14298L2.08507 5.80086C1.88042 5.64624 1.62251 5.57686 1.36661 5.60757C1.1107 5.63828 0.877187 5.76665 0.71611 5.96516C0.555032 6.16366 0.479216 6.4165 0.504912 6.66948C0.530608 6.92247 0.655773 7.15545 0.853574 7.3185L4.62213 10.2916C4.72627 10.3719 4.84559 10.431 4.97314 10.4654C5.10068 10.4997 5.23388 10.5086 5.36497 10.4916C5.49605 10.4746 5.62238 10.432 5.73659 10.3663C5.85079 10.3006 5.95058 10.2132 6.03012 10.109L11.3091 3.04765C11.3857 2.94476 11.4409 2.82799 11.4717 2.70402C11.5025 2.58005 11.5082 2.45131 11.4885 2.32516C11.4689 2.19902 11.4242 2.07793 11.357 1.96883C11.2898 1.85972 11.2015 1.76474 11.0971 1.68932Z"
        fill="white"
      />
    </svg>
  )
}
