/* eslint-disable import/no-anonymous-default-export */
import _ from 'lodash'
import { CHECK_ADD_ITEM, SIGNOUT_USER } from '../../redux/actions/Types'
import { ASPECT_RATIO_VIDEO_SUFFIXES } from '../../constants/AspectRatios'

import {
  addMusicData,
  addScenesData,
  addSoundData,
  addTextsData,
} from './nested/Projects'
import * as ProjectEditConstants from '../../redux/constants/ProjectEditConstants'
import DurationGetter from '../../services/time/DurationGetter'

const initialState = {
  project: null, // project data
  projectSteps: [], // past project array for undo redo
  addItem: 'video', // selected line of timeline to import item from library - default is video
  watchProject: false, // watch flag for project changed status
  stageW: 0, // project preview stage width
  stageH: 0, // project preview stage height
}

export default function (state = initialState, action) {
  switch (action.type) {
    case CHECK_ADD_ITEM:
      return {
        ...state,
        addItem: action.addItem,
      }
    case ProjectEditConstants.UPDATE_PROJECT: {
      if (!state.project || action.project.id !== state.project.id)
        state.projectSteps = []
      return {
        ...state,
        project: action.project,
      }
    }
    case 'ADD_SCENE_DATA':
      return {
        ...state,
        project: addScenesData(state.project, action, action.layout),
      }
    case 'ADD_TEXT_DATA':
      return {
        ...state,
        project: addTextsData(state.project, action, action.layout),
      }
    case 'ADD_SOUND_DATA':
      return {
        ...state,
        project: addSoundData(state.project, action),
      }
    case 'ADD_MUSIC_DATA':
      return {
        ...state,
        project: addMusicData(state.project, action),
      }
    case 'watch-project':
      return {
        ...state,
        watchProject: action.watchProject,
      }
    case 'set-stage-width':
      return {
        ...state,
        stageW: action.stageW,
      }
    case 'set-stage-height':
      return {
        ...state,
        stageH: action.stageH,
      }

    case 'STORE_PROJECT_STEPS':
      return {
        ...state,
        projectSteps: [
          ...state.projectSteps,
          { ...action.payload, layout: state.project.layout },
        ],
      }
    case 'UNDO_PROJECT':
      if (state.projectSteps.length > 0) {
        const curProjectSteps = _.cloneDeep(state.projectSteps)
        const lastAction = curProjectSteps.pop()
        let currentProject = JSON.parse(JSON.stringify(state.project))
        if (lastAction.channel === 'audio') lastAction.channel = 'sound'
        else if (lastAction.channel === 'video') lastAction.channel = 'scene'

        switch (lastAction.type) {
          case 'ADD':
            lastAction.channel.forEach((singleChannel) => {
              if (singleChannel === 'audio') singleChannel = 'sound'
              else if (singleChannel === 'video') singleChannel = 'scene'
              switch (singleChannel) {
                case 'text':
                case 'scene':
                  currentProject[`${singleChannel}s`].map((item) => item.pop())
                  currentProject.duration =
                    DurationGetter.getDurationFromProject(currentProject)
                  break
                case 'sound':
                case 'voiceover':
                case 'music':
                  currentProject[singleChannel].pop()
                  break
                default:
                  break
              }
            })
            break
          case 'DELETE':
            lastAction.channel.forEach((singleChannel, index) => {
              if (singleChannel === 'audio') singleChannel = 'sound'
              else if (singleChannel === 'video') singleChannel = 'scene'
              switch (singleChannel) {
                case 'text': {
                  const deletedTextItems = JSON.parse(
                    localStorage.getItem('cerebriam_deleted_text_items'),
                  )
                  currentProject[`${singleChannel}s`].forEach((layout, ind) => {
                    layout.splice(deletedTextItems[ind][0].order, 0, {
                      ...deletedTextItems[ind][0],
                    })
                  })
                  currentProject.duration =
                    DurationGetter.getDurationFromProject(currentProject)
                  break
                }
                case 'scene': {
                  const deletedVideoItems = JSON.parse(
                    localStorage.getItem('cerebriam_deleted_video_items'),
                  )
                  currentProject[`${singleChannel}s`].forEach((layout, ind) => {
                    layout.splice(deletedVideoItems[ind][0].order, 0, {
                      ...deletedVideoItems[ind][0],
                    })
                  })
                  currentProject.duration =
                    DurationGetter.getDurationFromProject(currentProject)

                  break
                }
                case 'sound':
                  currentProject[singleChannel].splice(
                    lastAction.payload[index].order,
                    0,
                    lastAction.payload[index],
                  )
                  break
                case 'voiceover':
                  currentProject[singleChannel].splice(
                    lastAction.payload[index].order,
                    0,
                    lastAction.payload[index],
                  )
                  break
                case 'music':
                  currentProject[singleChannel].splice(
                    lastAction.payload[index].order,
                    0,
                    lastAction.payload[index],
                  )
                  break

                default:
                  break
              }
            })
            break
          case 'UPDATE':
            lastAction.channel.forEach((singleChannel, index) => {
              if (singleChannel === 'audio') singleChannel = 'sound'
              else if (singleChannel === 'video') singleChannel = 'scene'

              switch (singleChannel) {
                case 'text':
                case 'scene':
                  currentProject[`${singleChannel}s`] = currentProject[
                    `${singleChannel}s`
                  ].map((layout) =>
                    layout.map((item) => {
                      if (item.id === lastAction.payload[index].id) {
                        item = {
                          ...item,
                          ...lastAction.payload[index],
                        }
                      }
                      return item
                    }),
                  )
                  break
                case 'sound':
                case 'voiceover':
                case 'music':
                  currentProject[singleChannel] = currentProject[
                    singleChannel
                  ].map((item) => {
                    if (item.id === lastAction.payload[index].id) {
                      item = {
                        ...item,
                        ...lastAction.payload[index],
                      }
                    }
                    return item
                  })
                  break
                default:
                  break
              }
            })
            break
          case 'CUT':
            lastAction.channel.forEach((singleChannel, index) => {
              let splittedIndex = -1
              if (singleChannel === 'audio') singleChannel = 'sound'
              else if (singleChannel === 'video') singleChannel = 'scene'
              switch (singleChannel) {
                case 'text':
                case 'scene':
                  currentProject[`${singleChannel}s`] = currentProject[
                    `${singleChannel}s`
                  ].map((layout) =>
                    layout.map((item, j) => {
                      if (item.id === lastAction.payload[index].id) {
                        item = {
                          ...item,
                          ...lastAction.payload[index],
                        }
                        splittedIndex = j + 1
                      }
                      return item
                    }),
                  )

                  currentProject[`${singleChannel}s`].map((item) =>
                    item.splice(splittedIndex, 1),
                  )
                  break
                case 'sound':
                case 'voiceover':
                case 'music':
                  currentProject[singleChannel] = currentProject[
                    singleChannel
                  ].map((item, j) => {
                    if (item.id === lastAction.payload[index].id) {
                      splittedIndex = j + 1
                      item = {
                        ...item,
                        ...lastAction.payload[index],
                      }
                    }
                    return item
                  })
                  if (currentProject[singleChannel].length > splittedIndex)
                    currentProject[singleChannel].splice(splittedIndex, 1)
                  else
                    currentProject[singleChannel].splice(splittedIndex - 2, 1)
                  break
                default:
                  break
              }
            })
            break

          default:
            break
        }
        return {
          ...state,
          project: currentProject,
          projectSteps: curProjectSteps,
        }
      }
      return state
    case SIGNOUT_USER:
      return {
        ...state,
        project: {
          ...state.project,
          scenes: [[], [], [], []],
          texts: [[], [], [], []],
          sound: [],
          music: [],
          voiceover: [],
        },
      }
    case ProjectEditConstants.CLEAR_PROJECT:
      return {
        ...state,
        project: null,
      }
    default:
      return state
  }
}
