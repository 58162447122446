import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import Spinner from '../../common/Spinner'
import { getUser } from '../../../redux/actions/admin/users/ShowUser'

class ShowUser extends Component {
  componentDidMount() {
    this.props.getUser(this.props.match.params.id)
  }

  render() {
    const { loadStatus } = this.props

    if (loadStatus === 'available') {
      const { user } = this.props

      return (
        <div className="main-area home-area">
          <div className="w-full flex justify-center h-full">
            <div className="w-full overflow-auto">
              <div
                className={
                  'xs:text-3xl sm:text-4xl md:text-5xl font-bold text-center'
                }>
                {user.name}
              </div>
              <p className="xs:text-lg sm:text-xl md:text-2xl mt-2 text-center">
                {user.email}
              </p>
              <p className="xs:text-lg sm:text-xl md:text-2xl mt-2 text-center">
                Remaining Credits: {user.credits}
              </p>
              <p className="xs:text-lg sm:text-xl md:text-2xl mt-2 text-center">
                Is Admin User: {user.isAdmin ? 'Yes' : 'No'}
              </p>
              <p className="xs:text-lg sm:text-xl md:text-2xl mt-2 text-center">
                Woocommerce Customer ID: {user.wc_customer_id}
              </p>
              <p className="xs:text-lg sm:text-xl md:text-2xl mt-2 text-center">
                Woocommerce Plan: {user.wc_plan}
              </p>
              <p className="xs:text-lg sm:text-xl md:text-2xl mt-2 text-center">
                Connected with Woocommerce: {user.wc_signed_up ? 'Yes' : 'No'}
              </p>
              <p className="xs:text-lg sm:text-xl md:text-2xl mt-2 text-center">
                {/* TODO: WA: Properly format dates. https://www.carlrippon.com/formatting-dates-and-numbers-in-react/ */}
                Signed Up On: {user.date}
              </p>
            </div>
          </div>
        </div>
      )
    }

    if (loadStatus === 'loading') {
      return <Spinner />
    }

    if (loadStatus === 'error') {
      return (
        <div className="main-area home-area">
          <div className="w-full flex justify-center h-full">
            User not found
          </div>
        </div>
      )
    }
  }
}

export default connect(
  (state) => ({
    loadStatus: state.adminShowUser.loadStatus,
    user: state.adminShowUser.user,
  }),
  { getUser },
)(withRouter(ShowUser))
