import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { welcomeSetStep } from '../../redux/actions/RecorderActions'
import { setBoardingStatus } from '../../redux/actions/AuthActions'
import * as EditorActions from '../../redux/actions/EditorActions'
import Carousel from '../../components/carousel/Carousel'
import IndicatorDots from '../../components/carousel/Indicator-dots'
import Buttons from '../../components/carousel/Buttons'
import WelcomeFrame from './Welcome'
import FeatureFrame from './Feature'
import UploadFrame from './Upload'
import Spinner from '../../components/common/Spinner'
import {
  onCameraOpen,
  onNextCallback,
  onUploadingStatus,
  goToEditor,
} from '../../methods/home/HomeMethods'
import InnerNavigation from '../../components/navigation/InnerNavigation'
import * as VideoCropActions from '../../redux/actions/VideoCropActions'
import * as SubscriptionActions from '../../redux/actions/SubscriptionActions'
import { Tooltip } from '@chakra-ui/react'
import TrialBanner from '../../components/common/TrialBanner'
import {
  SubscriptionManager,
  alertSubscriptionMessage,
} from '../../utils/Helper'

class HomePage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      uploadingFile: false,
      menuPage: 'record',
    }

    this.onCameraOpen = onCameraOpen.bind(this)
    this.onNextCallback = onNextCallback.bind(this)
    this.onUploadingStatus = onUploadingStatus.bind(this)
    this.goToEditor = goToEditor.bind(this)
  }

  onCardSelect = (step) => {
    this.props.welcomeSetStep(step)
  }

  componentDidMount() {
    const authUser = this.props.authUser
    this.props.loadUserSubscriptions()
    this.props.loadSubscriptionProducts()
    this.props.getProductFeatures()
    if (
      SubscriptionManager(authUser)?.isFreePlan &&
      this.props.history.action === 'PUSH' &&
      this.props.history?.location?.state?.from !== 'signup'
    ) {
      return alertSubscriptionMessage(
        'It’s good to see you again. Why not upgrade to get the most out of your work: have our watermark removed, access auto tracking, & have the project cap lifted',
      )
    }
  }
  render() {
    const { uploadingFile } = this.state
    return (
      <div>
        <div
          className="main-area home-area"
          style={{
            color: '#4c6174',
          }}>
          <InnerNavigation />
          {/* <TrialBanner /> */}
          {/*Carousel of home page*/}
          {!this.props.onboarding &&
          !JSON.parse(localStorage.getItem('cerebriam_onboarding')) &&
          this.props.location.state?.preRoute !== 'editor' ? (
            <Carousel
              widgets={[IndicatorDots, Buttons]}
              setBoardingStatus={this.props.setBoardingStatus}
              goBack={false}
              selected={this.props.selected}
              final={true}
              onFinalCallback={this.onCameraOpen}
              onNextCallback={this.onNextCallback}>
              <WelcomeFrame onCardSelect={this.onCardSelect} />

              <FeatureFrame onCardSelect={this.onCardSelect} />

              <UploadFrame onUploadingStatus={this.onUploadingStatus} />
            </Carousel>
          ) : (
            <>
              <UploadFrame onUploadingStatus={this.onUploadingStatus} />
              <div className="buttonCar" style={{ height: 20 }}>
                <Tooltip placement="top" label="Skip to editor" top={0}>
                  <div>
                    <Buttons
                      key={1}
                      index={2}
                      total={3}
                      prevHandler={this.prev}
                      nextHandler={this.goToEditor}
                      loop={false}
                      auto={false}
                      final={true}
                    />
                  </div>
                </Tooltip>
              </div>
            </>
          )}
        </div>
        {uploadingFile && <Spinner />}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  selected: state.recorder.selected,
  publication: state.recorder.publication,
  project: state.editor.project,
  onboarding: state.auth.onboarding,
  authUser: state.auth.user,
})

const mapDispatchToProps = (dispatch) => ({
  welcomeSetStep: (...args) => dispatch(welcomeSetStep(...args)),
  setBoardingStatus: (...args) => dispatch(setBoardingStatus(...args)),
  updateProject: (...args) => dispatch(EditorActions.updateProject(...args)),
  setLibraryAsset: (...args) =>
    dispatch(VideoCropActions.setLibraryAsset(...args)),
  loadUserSubscriptions: () =>
    dispatch(SubscriptionActions.currentUserSubscription()),
  loadSubscriptionProducts: () =>
    dispatch(SubscriptionActions.getAllSubscriptionProducts()),
  getProductFeatures: () => dispatch(SubscriptionActions.getProductFeatures()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(HomePage))
