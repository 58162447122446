import React from 'react'
import _ from 'lodash'
import Draggable from 'react-draggable'
import TimelineService from '../../services/time/TimelineService'
import { connect } from 'react-redux'
import { Tooltip } from '@chakra-ui/tooltip'
import { VolumePointContext } from './VolumePointContextMenu'

const DEFAULT_VOLUME_DATA = [{ id: 1, time: 0, volume: 0.5 }]

class AudioDuckVolumeOverlay extends React.Component {
  constructor(props) {
    super(props)
    this.mainSvg = React.createRef()
    this.scrollBarScrollLeft = React.createRef()
    this.state = {
      itemObj: this.props.item,
    }
  }

  static contextType = VolumePointContext

  componentDidMount() {
    const { channel, itemIndex } = this.props
    const item = this.state.itemObj
    const volumeData = item.volumeData || DEFAULT_VOLUME_DATA
    if (volumeData.length < 2) {
      const firstData = _.first(DEFAULT_VOLUME_DATA)
      const newVolumeData = [
        ...DEFAULT_VOLUME_DATA,
        { id: 2, time: item?.clip?.duration, volume: firstData.volume },
      ]
      const newUpdatedItem = _.cloneDeep(
        _.set(item, 'volumeData', newVolumeData),
      )
      this.context.onItemUpdate(newUpdatedItem, channel, itemIndex)
    } else {
      return volumeData
    }
  }

  handleSaveData = () => {
    const { channel, itemIndex } = this.props
    const newUpdatedItem = _.cloneDeep(this.state.itemObj)
    this.context.onItemUpdate(newUpdatedItem, channel, itemIndex)
  }

  get volumeData() {
    const item = this.state.itemObj
    const volumeData = item.volumeData || DEFAULT_VOLUME_DATA
    if (volumeData.length < 2) {
      const firstData = _.first(DEFAULT_VOLUME_DATA)
      return [
        ...DEFAULT_VOLUME_DATA,
        { id: 2, time: item?.clip?.duration, volume: firstData.volume },
      ]
    } else {
      return volumeData
    }
  }

  convertPixelsToVolume(pixels, height) {
    return pixels / height
  }

  handleLineClick = (event) => {
    const { height, item, storeProjectSteps, itemIndex, channel } = this.props
    const offsetX = event.nativeEvent.offsetX
    const offsetY = event.nativeEvent.offsetY
    const newPointTime = TimelineService.convertPixelToSeconds(offsetX)
    const newVolume = this.convertPixelsToVolume(offsetY, height)
    const newVolumePoint = { time: newPointTime, volume: newVolume }
    const newVolumeData = _.sortBy(
      [...this.volumeData, newVolumePoint],
      (d) => d.time,
    )
    let newItem = _.cloneDeep(this.state.itemObj)
    newItem.volumeData = newVolumeData

    storeProjectSteps({
      channel: [channel.code],
      type: 'UPDATE',
      payload: [_.cloneDeep(item)],
    })

    this.setState({ itemObj: newItem })

    this.context.onItemUpdate(newItem, channel, itemIndex)
  }

  renderLines() {
    const { height } = this.props
    return _.map(this.getPointPairs(this.state.itemObj.volumeData), (pair) => {
      const [currentVolumePoint, nextVolumePoint] = pair
      const currentHeight = this.convertVolumeToHeight(
        currentVolumePoint.volume,
        height,
      )
      const nextHeight = this.convertVolumeToHeight(
        nextVolumePoint.volume,
        height,
      )
      const currentLeft = TimelineService.convertMillisecondsToPixels(
        currentVolumePoint.time,
      )
      const nextLeft = TimelineService.convertMillisecondsToPixels(
        nextVolumePoint.time,
      )
      return (
        <React.Fragment>
          <line
            x1={currentLeft}
            y1={currentHeight}
            x2={nextLeft}
            y2={nextHeight}
            strokeWidth="4"
            stroke="blue"
          />
          <Tooltip label="Click to add a volume point" placement="bottom">
            <line
              x1={currentLeft}
              y1={currentHeight}
              x2={nextLeft}
              y2={nextHeight}
              strokeWidth="20"
              stroke="transparent"
              onMouseDown={this.handleLineClick}
            />
          </Tooltip>
        </React.Fragment>
      )
    })
  }

  convertVolumeToHeight(volume, height) {
    return (1 - volume) * height
  }

  handleRightClick = (event, volumePoint) => {
    event.preventDefault()
    const { channel, itemIndex } = this.props
    const item = this.state.itemObj
    this.context.setChannel(channel)
    this.context.setItem(item, itemIndex)
    this.context.showMenuAtPoint(
      true,
      volumePoint,
      item,
      event.pageX,
      event.pageY,
    )
  }

  handleDragPoint = (event, volumePoint, pointIndex) => {
    const { height } = this.props
    const mouseX = event.clientX || event.touches[0]?.clientX
    const mouseY = event.clientY || event.touches[0]?.clientY

    const mainSvgBoundingBox = this.mainSvg.current.getBoundingClientRect()
    const itemWidth = mouseX - mainSvgBoundingBox.x
    const itemHeight = mainSvgBoundingBox.height + mainSvgBoundingBox.y - mouseY
    const newPointTime = TimelineService.convertPixelToSeconds(itemWidth)
    let newVolume = this.convertPixelsToVolume(itemHeight, height)
    const isPointBetweenEdges = this.isVolumePointBetweenEdges(pointIndex)
    const leftTimeBoundary = _.get(this.volumeData, `${pointIndex - 1}.time`)
    const rightTimeBoundary = _.get(this.volumeData, `${pointIndex + 1}.time`)
    const isNewPointBetweenBoundaries =
      leftTimeBoundary < newPointTime && rightTimeBoundary > newPointTime
    if (isPointBetweenEdges && isNewPointBetweenBoundaries) {
      _.set(volumePoint, 'time', newPointTime)
    }

    if (newVolume > 1) {
      newVolume = 1
    }

    if (newVolume < 0) {
      newVolume = 0
    }

    const prevItemObj = this.state.itemObj
    const newItemObj = _.cloneDeep(prevItemObj)
    newItemObj.volumeData[pointIndex].volume = newVolume
    this.setState({
      itemObj: newItemObj,
    })

    this.forceUpdate()
  }

  isVolumePointBetweenEdges(pointIndex) {
    return pointIndex !== 0 && pointIndex !== this.volumeData.length - 1
  }

  getPointPairs(volumeData) {
    const pointPairs = _.reduce(
      volumeData,
      (pairs, volumePoint, index) => {
        if (index !== volumeData.length - 1) {
          const nextVolumePoint = volumeData[index + 1]
          pairs.push([volumePoint, nextVolumePoint])
        }
        return pairs
      },
      [],
    )
    return pointPairs
  }

  renderCircles() {
    const { height } = this.props
    const result = _.map(
      this.state.itemObj.volumeData,
      (volumePoint, pointIndex) => {
        const volumeHeightPixels = this.convertVolumeToHeight(
          volumePoint.volume,
          height,
        )
        const timelinePixels = TimelineService.convertMillisecondsToPixels(
          volumePoint.time,
        )
        const additionalParameters = this.isVolumePointBetweenEdges(pointIndex)
          ? {}
          : { axis: 'y' }
        return (
          <React.Fragment>
            <Draggable
              onStart={() => {
                const scrollbar = document.getElementById('audioduck-scrollbar')
                this.scrollBarScrollLeft.current = scrollbar?.scrollLeft
              }}
              onDrag={(event, draggableData) => {
                const scrollbar = document.getElementById('audioduck-scrollbar')
                if (this.scrollBarScrollLeft.current) {
                  scrollbar.scrollLeft = this.scrollBarScrollLeft.current
                }
                this.handleDragPoint(
                  event,
                  volumePoint,
                  pointIndex,
                  draggableData,
                )
              }}
              onStop={() => this.handleSaveData()}
              offsetParent={this.mainSvg.current}
              bounds={this.getVolumePointBound(height, pointIndex)}
              {...additionalParameters}>
              <circle
                cy={volumeHeightPixels}
                cx={timelinePixels}
                m
                r="6"
                fill="blue"
                onContextMenu={(event) =>
                  this.handleRightClick(event, volumePoint)
                }
                stroke="transparent"
                strokeWidth="6"
                style={{
                  transform: 'none !important',
                }}
              />
            </Draggable>
          </React.Fragment>
        )
      },
    )
    return result
  }

  getVolumePointBound(height, pointIndex) {
    const boundTop = -(height / 2) + 28
    const boundBottom = height / 2 - 16
    if (this.isVolumePointBetweenEdges(pointIndex)) {
      const boundLeft =
        TimelineService.convertMillisecondsToPixels(
          this.volumeData[pointIndex - 1].time -
            this.volumeData[pointIndex].time,
        ) + 6
      const boundRight =
        TimelineService.convertMillisecondsToPixels(
          this.volumeData[pointIndex + 1].time -
            this.volumeData[pointIndex].time,
        ) - 6
      return {
        top: boundTop,
        bottom: boundBottom,
        left: boundLeft,
        right: boundRight,
      }
    } else {
      return { top: boundTop, bottom: boundBottom }
    }
  }

  render() {
    const { height } = this.props
    const item = this.state.itemObj
    const timelineWidth = TimelineService.convertMillisecondsToPixels(
      item?.clip?.duration,
    )
    return (
      <React.Fragment>
        <svg
          className="absolute"
          ref={this.mainSvg}
          width={timelineWidth}
          height={height}
          style={{
            left: 0,
            top: 0,
            zIndex: 5,
          }}>
          {this.renderLines()}
          {this.renderCircles()}
        </svg>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  project: state.editor.project,
})

export default connect(mapStateToProps)(AudioDuckVolumeOverlay)
