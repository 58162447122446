import {
  UPDATE_ADMIN_LIST_USERS_LOAD_STATUS,
  ADMIN_LIST_USERS_LOADED,
} from '../../../redux/actions/admin/Types'

export default (state = { loadStatus: 'loading', users: [] }, action) => {
  switch (action.type) {
    case UPDATE_ADMIN_LIST_USERS_LOAD_STATUS: {
      return {
        ...state,
        loadStatus: action.status,
      }
    }
    case ADMIN_LIST_USERS_LOADED: {
      return {
        ...state,
        users: action.users,
        loadStatus: 'available',
      }
    }
    default:
      return state
  }
}
