import {
  FilesImg,
  MusicFillImg,
  SoundImg,
  TextLineImg,
  VoiceOverImg,
} from '../components/video-editor/common-items/EditorIcons'

export const VIDEO = {
  name: 'Video',
  code: 'video',
  height: 66,
  color: 'blue',
  backgroundColor: '#e2ebf2',
  path: 'video',
  projectPath: 'scenes',
  iconImage: FilesImg,
}
export const AUDIO = {
  name: 'Audio',
  code: 'audio', // TODO: change to sound
  height: 27,
  color: 'blue',
  backgroundColor: '#d6dfe4',
  path: 'audio',
  projectPath: 'sound',
  iconImage: SoundImg,
}
export const VOICEOVER = {
  name: 'Voice Over',
  code: 'voiceover',
  height: 27,
  color: 'blue',
  backgroundColor: '#c9d2d7',
  path: 'voiceover',
  projectPath: 'voiceover',
  iconImage: VoiceOverImg,
}
export const TEXT = {
  name: 'Title & Text',
  code: 'text',
  height: 27,
  color: 'blue',
  backgroundColor: '#bfc7ca',
  path: 'texts',
  projectPath: 'texts',
  iconImage: TextLineImg,
}
export const MUSIC = {
  name: 'Music',
  code: 'music',
  height: 27,
  color: 'blue',
  backgroundColor: '#bfc7ca',
  path: 'music',
  projectPath: 'music',
  iconImage: MusicFillImg,
}

const CHANNELS = [VIDEO, AUDIO, VOICEOVER, TEXT, MUSIC]

export const CHANNEL_DETAILS = {
  [VIDEO.code]: VIDEO,
  [AUDIO.code]: AUDIO,
  [VOICEOVER.code]: VOICEOVER,
  [TEXT.code]: TEXT,
  [MUSIC.code]: MUSIC,
}

export default CHANNELS
