import React, { Component } from 'react'
import ReactModal from 'react-modal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faCheck } from '@fortawesome/free-solid-svg-icons'
import Axios from '../../utils/Axios'
import { connect } from 'react-redux'
import * as UserProfileReducer from '../../redux/reducers/UserProfileReducer'
import { getProduct } from '../../pages/home/Cropper'

class UserProfileModal extends Component {
  state = {
    user: null,
    isEditForm: false,
    loading: true,
  }

  constructor(props) {
    super(props)

    ReactModal.setAppElement('#root')
  }

  INIT = async () => {
    const { user, setUserProfile } = this.props

    if (!user) {
      // fetch user
      const response = await Axios.get('/users/profile')

      this.setState({
        loading: false,
      })

      if (response) {
        setUserProfile(response.data)
        this.setState({
          user: response.data,
        })
      } else {
        console.error(response)
      }
    } else {
      if (!this.state.user) {
        this.setState({
          user: user,
        })
      }
      this.setState({
        loading: false,
      })
    }
  }

  componentDidMount() {
    this.INIT()
  }

  showEditModal = () => {
    this.setState({
      isEditForm: true,
    })
  }

  hideEditModal = () => {
    this.setState({
      isEditForm: false,
      user: this.props.user,
    })
  }

  saveEditModal = async () => {
    this.setState({
      loading: true,
    })

    const response = await Axios.post('/users/profile', {
      name: this.state.user.name.trim(),
      email: this.state.user.email.trim(),
    })

    if (response.status !== 200) {
      alert(response.data.message)
    } else {
      this.props.setUserProfile(response.data)
    }
    this.setState({
      isEditForm: false,
      loading: false,
    })
  }

  onNameChangeHandler = (event) => {
    this.setState({
      user: {
        ...this.state.user,
        name: event.target.value,
      },
    })
  }

  onEmailChangeHandler = (event) => {
    this.setState({
      user: {
        ...this.state.user,
        email: event.target.value,
      },
    })
  }

  render() {
    const { user, isEditForm, loading } = this.state
    return (
      <>
        <div className="flex items-center flex-col">
          <h4>{loading && 'Loading...'}</h4>
        </div>
        {user && (
          <>
            <p className="infoLines">
              <span>
                <strong>Package :</strong>
              </span>
              <span>
                {getProduct(this.props.mySubscription, this.props.products)
                  ?.type ?? 'No Active Package'}
              </span>
            </p>
            <p className="infoLines">
              <span>
                <strong>Date Created :</strong>
              </span>
              <span>
                {user.date ? new Date(user.date).toDateString() : 'Nil'}
              </span>
            </p>
            <p className="infoLines">
              <span>
                <strong>Subscription :</strong>
              </span>
              <span>
                {this.props.mySubscription?.is_trial_active
                  ? 'Trial (14 days)'
                  : this.props.mySubscription?.active
                  ? 'Paid'
                  : 'Basic'}
              </span>
            </p>

            <p className="infoLines">
              <span>
                <strong>Name :</strong>
              </span>
              <span>
                {isEditForm ? (
                  <input
                    className="border rounded border-teal-700 w-full"
                    style={{ padding: '2px 6px' }}
                    type="text"
                    value={user.name}
                    onChange={this.onNameChangeHandler}
                    disabled={loading}
                  />
                ) : (
                  user.name
                )}
              </span>
            </p>

            <p className="infoLines no-border-bottom">
              <span>
                <strong>Email :</strong>
              </span>
              <span>
                {isEditForm ? (
                  <input
                    className="border rounded border-teal-700 w-full"
                    style={{ padding: '2px 6px' }}
                    type="email"
                    value={user.email}
                    onChange={this.onEmailChangeHandler}
                    disabled={loading}
                  />
                ) : (
                  user.email
                )}
              </span>
            </p>

            <div className="flex items-center mt-4">
              {isEditForm ? (
                <>
                  <button
                    className="rounded bg-teal-700 hover:bg-teal-600 py-2 px-3 mr-2 text-white"
                    onClick={this.saveEditModal}
                    disabled={loading}>
                    <FontAwesomeIcon icon={faCheck} /> Save
                  </button>
                  <button
                    className="rounded bg-gray-700 hover:bg-gray-600 py-2 px-3 mr-2 text-white"
                    onClick={this.hideEditModal}>
                    <FontAwesomeIcon icon={faTimes} /> Cancel
                  </button>
                </>
              ) : (
                <button
                  className="rounded bg-teal-700 hover:bg-teal-600 py-2 px-3 mr-2 text-white"
                  onClick={this.showEditModal}
                  disabled={loading}>
                  Edit
                </button>
              )}
            </div>
          </>
        )}
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  ...state.userProfile,
  mySubscription: state.subscriptions.user,
  products: state.subscriptions.products,
})

export default connect(
  mapStateToProps,
  UserProfileReducer.actionCreators,
)(UserProfileModal)
