const Steps = ({ active, changeActive }) => {
      return (
      <ul className="cerebriam-steps cerebriam-container">
        <li
          onClick={() => changeActive(1)}
          className={`cerebriam-step active ${active === 1 ? 'current' : ''}`}>
          <span className="cerebriam-step__title">Choose package</span>
          <span className="cerebriam-step__value">1</span>
        </li>
        <li
          onClick={() => changeActive(2)}
          className={`cerebriam-step ${active >= 2 ? 'active' : ''} ${
            active === 2 ? 'current' : ''
          }`}>
          <span className="cerebriam-step__title">Order summary</span>
          <span className="cerebriam-step__value">2</span>
        </li>
        <li
          className={`cerebriam-step ${active >= 3 ? 'active' : ''} ${
            active === 3 ? 'current' : ''
          }`}>
          <span className="cerebriam-step__title">Review & confirm</span>
          <span className="cerebriam-step__value">3</span>
        </li>
      </ul>
    )
  }
  export default Steps