//Main Container Reducers
import _ from 'lodash'
import * as Constants from '../constants/VoiceoverConstants'
import * as TimelineService from '../../services/time/TimelineService'

const initialState = {
  showStage: false,
  tweenObjectBuilder: null,
  assets: {},
  isVoiceoverModalDisplayed: false,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case Constants.SET_RECORDED_AUDIO:
      return {
        ...state,
        recordedAudio: action.recordedAudio,
      }
    default:
      return state
  }
}
