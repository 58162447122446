import { Expo, Power2, Linear } from 'gsap'

export const START_ANIMATION = {
  autoAlpha: 1,
  x: 0,
  y: 0,
  scale: 1,
  opacity: 1,
  scaleX: 1,
  scaleY: 1,
  rotationZ: 0,
  rotationX: 0,
  rotationY: 0,
  repeat: 0,
  yoyo: false,
}

let animateEnding

export function transitionFade(animate, in_effect) {
  animateEnding = {
    autoAlpha: 1,
    opacity: 1,
    x: 0,
    y: 0,
    rotationZ: 0,
    rotationX: 0,
    rotationY: 0,
  }

  animate.autoAlpha = 0

  if (in_effect) {
    animate.ease = Linear.easeNone
  } else {
    animate.autoAlpha = 0.3
    animate.ease = Linear.easeNone
  }

  return { animate, animateEnding }
}

export function textFade(animate, in_out) {
  animateEnding = {
    autoAlpha: 1,
    opacity: 1,
    x: 0,
    y: 0,
    rotationZ: 0,
    rotationX: 0,
    rotationY: 0,
    scale: 1,
  }

  animate.autoAlpha = 0

  if (in_out) {
    animate.ease = Linear.easeNone
  } else {
    animate.ease = Expo.easeOut
    animate.scale = 0.99
  }

  return { animate, animateEnding }
}

export function textSlide(animate, text) {
  animateEnding = {
    autoAlpha: 1,
    opacity: 1,
    x: 0,
    y: 0,
    rotationZ: 0,
    rotationX: 0,
    rotationY: 0,
  }

  if (text.aniValue === 'slide-left') {
    animate.x = '-' + text.p_width + 'px'
  } else {
    animate.x = text.p_width + 'px'
  }

  return { animate, animateEnding }
}

export function textSlide1(animate, text) {
  animateEnding = {
    autoAlpha: 1,
    opacity: 1,
    x: 0,
    y: 0,
    rotationZ: 0,
    rotationX: 0,
    rotationY: 0,
  }

  return { animate, animateEnding }
}

export function textPulse(animate, in_out) {
  animateEnding = {
    autoAlpha: 1,
    opacity: 1,
    x: 0,
    y: 0,
    rotationZ: 0,
    rotationX: 0,
    rotationY: 0,
    scale: 1,
  }

  animate.autoAlpha = 0
  animate.scale = 0.8
  animate.yoyo = true

  if (in_out) {
    animate.ease = Power2.easeIn
  } else {
    animate.ease = Power2.easeOut
  }

  return { animate, animateEnding }
}
