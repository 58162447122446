//Main Container Reducers
import _ from 'lodash'
import * as Constants from '../constants/MainContainerConstants'
import * as TimelineService from '../../services/time/TimelineService'

const initialState = {
  showStage: false,
  tweenObjectBuilder: null,
  assets: {},
  isVoiceoverModalDisplayed: false,
  currentTextItem: null,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case Constants.SET_SHOW_STAGE:
      return {
        ...state,
        showStage: action.showStage,
      }
    case Constants.OPEN_EDIT_TEXT_MODAL:
      return {
        ...state,
        isEditTextModalVisible: action.isEditTextModalVisible,
        currentTextItem: action.currentTextItem,
      }
    case Constants.SET_TWEEN_OBJECT_BUILDER:
      return {
        ...state,
        tweenObjectBuilder: action.tweenObjectBuilder,
      }
    case Constants.SET_PLAY_COMPLETED:
      return {
        ...state,
        isPlayCompleted: action.isPlayCompleted,
      }
    case Constants.TOGGLE_VOICEOVER_MODAL:
      return {
        ...state,
        isVoiceoverModalDisplayed: action.isVoiceoverModalDisplayed,
      }
    default:
      return state
  }
}
