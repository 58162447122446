import React from 'react'
import _ from 'lodash'
import AudioTimelineItem from '../timeline/AudioTimelineItem'

class VoiceRecordTimelineItem extends React.Component {
  audioTimelineItem = React.createRef()

  componentDidUpdate(prevProps) {
    const { isRecording, currentTime, currentRecordId, item } = this.props
    const isCurrentTimeChanged = currentTime !== prevProps.currentTime
    const isItemCurrentlyRecording = currentRecordId === item.$id
    if (isRecording && isCurrentTimeChanged && isItemCurrentlyRecording) {
      this.audioTimelineItem.current.reloadWaveSurfer.bind(
        this.audioTimelineItem.current,
      )()
    }
  }

  render() {
    const { item } = this.props
    return (
      <AudioTimelineItem
        ref={this.audioTimelineItem}
        itemTimelineHTML={this.props.itemTimelineHTML}
        height={55}
        item={item}
      />
    )
  }
}

export default VoiceRecordTimelineItem
