/* eslint-disable no-case-declarations */
/* eslint-disable default-case */
import _ from 'lodash'
import { RATIO } from '../constants/AspectRatios'
import DurationGetter from './time/DurationGetter'
import MusicItemAdder from './project-items/MusicItemAdder'
import { AUDIO, MUSIC, VIDEO, VOICEOVER } from '../constants/Channels'
import ProjectItemsDecorator from './project-items/ProjectItemsDecorator'
import SceneItemAdder from './project-items/SceneItemAdder'
import SoundItemAdder from './project-items/SoundItemAdder'
import { ASSET_LIBRARY } from '../constants/AssetLibraries'

export const getProjectWithProperDuration = (project) => {
  const maxDuration = DurationGetter.getDurationFromProject(project)
  project.duration = maxDuration
  return project
}

export const addItemToProject = (
  project,
  item,
  itemType,
  startAtMilliseconds = 0,
  additionalParameters = null,
) => {
  switch (itemType) {
    case VIDEO.code: {
      if (item.sound) {
        const soundStartAtMilliseconds = DurationGetter.getMaxDurationByChannel(
          project,
          AUDIO.code,
        )
        if (soundStartAtMilliseconds > startAtMilliseconds)
          startAtMilliseconds = soundStartAtMilliseconds
      }
      let updatedProject = addSceneItemToProject(
        project,
        item,
        startAtMilliseconds,
        additionalParameters,
      )
      return item.sound
        ? addAudioItemToProject(updatedProject, item, startAtMilliseconds)
        : updatedProject
    }
    case AUDIO.code: {
      return project
    }
    case MUSIC.code: {
      return addMusicItemToProject(project, item)
    }
    case VOICEOVER.code: {
      return addVoiceoverToProject(project, item, startAtMilliseconds)
    }
    default:
      return project
  }
}

const addAudioItemToProject = (project, item, startAtMilliseconds) => {
  let clonedProject = _.cloneDeep(project)
  const audioItem = SoundItemAdder.createSoundItemFromSceneItem(
    item,
    startAtMilliseconds,
  )
  clonedProject[AUDIO.projectPath] =
    ProjectItemsDecorator.reorderItemsByStartTime([
      ...clonedProject[AUDIO.projectPath],
      audioItem,
    ])
  return _.cloneDeep(clonedProject)
}

const addMusicItemToProject = (project, musicItem) => {
  const lastEndTime = DurationGetter.getMaxDurationByAssets(project.music)
  const newMusicItem = MusicItemAdder.createMusicItem(musicItem, lastEndTime)
  project.music.push(newMusicItem)
  project.music = ProjectItemsDecorator.reorderItemsByStartTime(project.music)
  return _.cloneDeep(project)
}

/**
 * This function is called whenever we need to add a new scene to a project: cropper, tracker, video recorder and adding an asset from the library
 * @param {*} project
 * @param {*} sceneItem
 * @param {*} startAtMilliseconds
 * @param {*} additionalParameters
 * @returns
 */
const addSceneItemToProject = (
  project,
  sceneItem,
  startAtMilliseconds,
  additionalParameters,
) => {
  let clonedProject = _.cloneDeep(project)
  // A scene object is added for every aspect ratio
  _.forEach(RATIO, (ratio) => {
    const ratioPath = `${VIDEO.projectPath}.${ratio}`
    const ratioSceneItems = _.get(clonedProject, ratioPath, [])
    let newSceneItem = _.cloneDeep(sceneItem)

    if (newSceneItem.type === 'video') {
      // if we don't have a video clip cropped for the processed aspect ratio then we still create a scene item, but replace a wrong video with the blank video with specified aspect ratio
      if (additionalParameters['selectedLayout']) {
        if (!additionalParameters.selectedLayout[ratio]) {
          newSceneItem.userFolderUrl = `${process.env.REACT_APP_S3_URL}assets/blanks`
          newSceneItem.originFileName = 'blank'
          newSceneItem.assetUrl = `${process.env.REACT_APP_S3_URL}assets/blanks/blank_${ratio}.mp4`
          newSceneItem.thumbUrl = `${process.env.REACT_APP_S3_URL}assets/blanks/blank_${ratio}.jpg`
        }
      }
      // Adding a new video scene with a data received when the function is called
      let newRatioSceneItem = SceneItemAdder.createSceneItemByRatio(
        newSceneItem,
        ratio,
        startAtMilliseconds,
        additionalParameters,
      )
      // sorting scenes in the project
      const reorderedSceneItems = ProjectItemsDecorator.reorderItemsByStartTime(
        [...ratioSceneItems, newRatioSceneItem],
      )
      _.set(clonedProject, ratioPath, reorderedSceneItems)
    } else {
      const newRatioSceneItem = SceneItemAdder.createPhotoItemByRatio(
        newSceneItem,
        ratio,
        startAtMilliseconds,
        additionalParameters,
      )
      const reorderedSceneItems = ProjectItemsDecorator.reorderItemsByStartTime(
        [...ratioSceneItems, newRatioSceneItem],
      )
      _.set(clonedProject, ratioPath, reorderedSceneItems)
    }
  })
  clonedProject.selectLayout = _.map(RATIO, () => true)
  return clonedProject
}

const addVoiceoverToProject = (
  project,
  item,
  startAtMilliseconds = DurationGetter.getMaxDurationByAssets(
    project.voiceover,
  ),
) => {
  const newMusicItem = MusicItemAdder.createMusicItem(item, startAtMilliseconds)
  project.voiceover.push(newMusicItem)
  project.voiceover = ProjectItemsDecorator.reorderItemsByStartTime(
    project.voiceover,
  )
  return _.cloneDeep(project)
}

const addLibraryAssetToProject = (
  libraryAsset,
  project,
  startAtMilliseconds,
  authorId,
) => {
  switch (libraryAsset.fileType) {
    case ASSET_LIBRARY.BRAND.folderPath:
    case ASSET_LIBRARY.PHOTO.folderPath:
      const type =
        libraryAsset.fileType === ASSET_LIBRARY.PHOTO.folderPath
          ? 'photo'
          : 'brand'
      const sceneItem = SceneItemAdder.createPhotoOrBrandItemFromLibraryAsset(
        libraryAsset,
        authorId,
        project,
        type,
      )
      project = addSceneItemToProject(project, sceneItem, startAtMilliseconds, {
        aspectSize: libraryAsset.aspectSize,
        streamSize: libraryAsset.streamSize,
        extension: libraryAsset.extension,
      })
      return project

    case ASSET_LIBRARY.AUDIO.folderPath: {
      project = addMusicItemToProject(project, libraryAsset)
      return project
    }
    // TODO: add default return or handle all folderpath types
  }
}

export default {
  addItemToProject,
  addLibraryAssetToProject,
}
