import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { RecorderRetryIcon } from '../icons/RecorderRetryIcon'

class RecordButton extends Component {
  handleStartRecord = () => {
    const { disabled, onStartRecording } = this.props
    onStartRecording && !disabled && onStartRecording()
  }

  handleEndRecord = () => {
    const { disabled, onStopRecording } = this.props
    onStopRecording && !disabled && onStopRecording()
  }

  render() {
    const { isRecording, showRecord, disabled, pauseRecorder } = this.props

    return (
      <div className="record-btn-area">
        {!isRecording && showRecord && (
          <button
            type="button"
            onClick={this.handleStartRecord}
            disabled={disabled}
            className={
              'rounded-full xs:w-10 xs:h-10 sm:w-12 sm:h-12 lg:w-16 lg:h-16 outline-none focus:outline-none'
            }>
            <div className="recorder-icon"></div>
            <span>Record</span>
          </button>
        )}

        {(isRecording || pauseRecorder) && showRecord && (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <RecorderRetryIcon onClick={this.props.onTurnOnCamera} />
            <button
              type="button"
              onClick={
                pauseRecorder
                  ? this.props.onResumeRecording
                  : this.props.onPauseRecording
              }
              disabled={disabled}
              style={{ background: 'none', marginLeft: 30, marginRight: 15 }}
              className="rounded bg-t-600 border-2 border-white hover:bg-red-500 xs:w-10 xs:h-10 sm:w-12 sm:h-12 lg:w-16 lg:h-16 outline-none focus:outline-none">
              {' '}
              <div className="recorder-icon stop"></div>
              {pauseRecorder ? 'Resume' : 'Pause'}
            </button>
            <button
              type="button"
              onClick={this.handleEndRecord}
              disabled={disabled}
              className="rounded bg-t-600 hover:bg-red-500 xs:w-10 xs:h-10 sm:w-12 sm:h-12 lg:w-16 lg:h-16 outline-none focus:outline-none">
              {' '}
              <div className="recorder-icon stop"></div>Stop
            </button>
          </div>
        )}
      </div>
    )
  }
}

RecordButton.propTypes = {
  isRecording: PropTypes.bool,
  showRecord: PropTypes.bool,
  onStartRecording: PropTypes.func,
  onStopRecording: PropTypes.func,
}

export default RecordButton
