export const AudioIcon = () => {
  return (
    <svg
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_283_1795)">
        <path
          d="M30.0847 1.77782C29.4828 1.27581 28.777 0.913436 28.0183 0.716779C27.2596 0.520123 26.4667 0.494069 25.6967 0.640491L13.4514 2.93649C11.923 3.22291 10.5428 4.03458 9.54968 5.23105C8.55652 6.42752 8.01283 7.93352 8.0127 9.48849V22.6218C7.20557 22.1419 6.28503 21.886 5.34603 21.8805C4.2912 21.8805 3.26005 22.1933 2.38299 22.7793C1.50593 23.3653 0.822341 24.1983 0.418673 25.1728C0.0150061 26.1474 -0.0906116 27.2197 0.115176 28.2543C0.320964 29.2889 0.828915 30.2392 1.57479 30.9851C2.32067 31.7309 3.27098 32.2389 4.30555 32.4447C5.34011 32.6505 6.41247 32.5448 7.38701 32.1412C8.36155 31.7375 9.1945 31.0539 9.78053 30.1769C10.3666 29.2998 10.6794 28.2687 10.6794 27.2138V15.0485C10.6793 14.4263 10.8968 13.8237 11.2942 13.345C11.6916 12.8663 12.2438 12.5416 12.8554 12.4272L27.766 9.62716C27.9594 9.59074 28.1585 9.59759 28.349 9.64723C28.5394 9.69686 28.7165 9.78805 28.8676 9.91423C29.0186 10.0404 29.1399 10.1985 29.2226 10.3771C29.3053 10.5557 29.3475 10.7503 29.346 10.9472V18.6272C28.5394 18.1454 27.6189 17.8876 26.6794 17.8805C25.6245 17.8805 24.5934 18.1933 23.7163 18.7793C22.8393 19.3653 22.1557 20.1983 21.752 21.1728C21.3483 22.1474 21.2427 23.2197 21.4485 24.2543C21.6543 25.2889 22.1622 26.2392 22.9081 26.9851C23.654 27.7309 24.6043 28.2389 25.6389 28.4447C26.6734 28.6505 27.7458 28.5448 28.7203 28.1412C29.6949 27.7375 30.5278 27.0539 31.1139 26.1769C31.6999 25.2998 32.0127 24.2687 32.0127 23.2138V5.88049C32.0136 5.09681 31.8412 4.32262 31.5079 3.61335C31.1746 2.90408 30.6886 2.27728 30.0847 1.77782Z"
          fill="#4B5563"
        />
      </g>
      <defs>
        <clipPath id="clip0_283_1795">
          <rect
            width="32"
            height="32"
            fill="white"
            transform="translate(0.0126953 0.547119)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
