import _ from 'lodash'
import React from 'react'
import { Menu, MenuButton, MenuList, MenuItem } from '@chakra-ui/menu'
import { Portal } from '@chakra-ui/portal'

export const VolumePointContext = React.createContext()

class VolumePointContextMenu extends React.Component {
  state = {
    showMenu: false,
    xPosition: null,
    yPosition: null,
    item: null,
    volumePoint: null,
  }

  showMenuAtPoint = (showMenu, volumePoint, item, xPosition, yPosition) => {
    this.setState({ showMenu, volumePoint, item, xPosition, yPosition })
  }

  handleDeleteVolumePoint = (volumePoint, channel) => {
    const { itemIndex } = this.state
    const { project } = this.props
    const projectItem = _.get(project, `${channel.projectPath}.${itemIndex}`)
    const newVolumeData = _.reject(
      projectItem.volumeData,
      (point) => volumePoint.time === point.time,
    )
    const updatedItem = _.cloneDeep({
      ...projectItem,
      volumeData: newVolumeData,
    })
    this.handleItemUpdate(updatedItem, channel, itemIndex)
  }

  handleItemUpdate = (updatedItem, channel, itemIndex) => {
    const { project, updateProject } = this.props
    const clonedProject = _.cloneDeep(project)
    _.set(clonedProject, `${channel.projectPath}.${itemIndex}`, updatedItem)
    updateProject && updateProject(clonedProject)
  }

  setChannel(channel) {
    this.setState({ channel })
    return this
  }

  setItem(item, itemIndex) {
    this.setState({ item, itemIndex })
    return this
  }

  get isDisabled() {
    const { item, volumePoint } = this.state
    const itemEndTime = item.end_time
    const volumePointTime = volumePoint.time
    return itemEndTime <= volumePointTime || volumePointTime === 0
  }

  render() {
    const { showMenu, xPosition, yPosition, volumePoint, channel } = this.state
    const { children } = this.props
    return (
      <VolumePointContext.Provider
        value={{
          ...this.state,
          showMenuAtPoint: this.showMenuAtPoint.bind(this),
          setChannel: this.setChannel.bind(this),
          setItem: this.setItem.bind(this),
          onItemUpdate: this.handleItemUpdate.bind(this),
        }}>
        {children}
        {showMenu && (
          <Menu
            isOpen={showMenu}
            closeOnSelect
            onClose={() => this.showMenuAtPoint(false, null, null)}>
            <MenuButton
              className="fixed"
              style={{
                top: yPosition,
                left: xPosition,
                zIndex: 9999,
                height: 0,
                width: 0,
              }}>
              &nbsp;
            </MenuButton>
            <Portal className="stage-dropdown-menu" bg="teal.500">
              <MenuList>
                <MenuItem
                  onClick={() =>
                    this.handleDeleteVolumePoint(volumePoint, channel)
                  }
                  isDisabled={this.isDisabled}>
                  Delete Volume Point
                </MenuItem>
              </MenuList>
            </Portal>
          </Menu>
        )}
      </VolumePointContext.Provider>
    )
  }
}

export default VolumePointContextMenu
