import React from 'react'
import Moveable from 'react-moveable'

const STAGE_ALLOWANCE = 3

const TextStageMove = ({
  moveRef,
  contRef,
  drag,
  dragStart,
  dragEnd,
  onReSize,
  resizeEnd,
  ind,
  fontSize,
  tweenWrapperRef,
}) => {
  const [renderMovable, setRenderMovable] = React.useState(false)

  React.useEffect(() => {
    setRenderMovable(true)
  }, [])

  const handleDragStart = (e) => {
    dragStart && dragStart(e)
  }

  const handleDragEnd = ({ target }) => {
    dragEnd(parseFloat(target.style.top), parseFloat(target.style.left), ind)
  }

  const handleDrag = (e) => {
    drag(e.top, e.left, ind)
  }

  if (!renderMovable) return null

  const boundinBoxParentElement =
    contRef?.current?.parentElement?.getBoundingClientRect()
  const bounds = {
    top: 0,
    left: 0,
    right: parseInt(boundinBoxParentElement?.width),
    bottom: parseInt(boundinBoxParentElement?.height),
  }

  const handleResize = (e) => {
    resizeEnd(
      parseInt(e.target.style.width),
      parseInt(e.target.style.height),
      ind,
      fontSize,
    )
  }

  const onResize = ({ width, height }) => {
    // Make text container not to span the entire tweenwrapper view
    // Add an allowance of 3px
    const { width: tweenWrapperWidth, height: tweenWrapperHeight } =
      tweenWrapperRef.getBoundingClientRect()
    if (
      height < tweenWrapperHeight - STAGE_ALLOWANCE &&
      width < tweenWrapperWidth - STAGE_ALLOWANCE
    ) {
      onReSize(width, height, ind)
    }
  }

  return (
    <Moveable
      target={moveRef}
      draggable={true}
      keepRatio={false}
      throttleDrag={0}
      origin={true}
      snappable={true}
      bounds={bounds}
      edge={false}
      onDrag={handleDrag}
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}
      resizable={true}
      throttleResize={0}
      onResize={onResize}
      onResizeEnd={handleResize}
    />
  )
}

export default TextStageMove
