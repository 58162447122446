import {
  UPDATE_ADMIN_SHOW_USER_LOAD_STATUS,
  ADMIN_SHOW_USER_LOADED,
} from '../../../redux/actions/admin/Types'

export default (state = { loadStatus: 'loading' }, action) => {
  switch (action.type) {
    case UPDATE_ADMIN_SHOW_USER_LOAD_STATUS: {
      return {
        ...state,
        loadStatus: action.status,
      }
    }
    case ADMIN_SHOW_USER_LOADED: {
      return {
        ...state,
        user: action.user,
        loadStatus: 'available',
      }
    }
    default:
      return state
  }
}
