import TimelineService from '../time/TimelineService'

class TimelineScroller {
  scrollTimelinePanel(
    timelinePanel,
    currentTime,
    previousTime,
    isPlaying,
    scrollbar,
  ) {
    const currentTimePixels =
      TimelineService.convertMillisecondsToPixels(currentTime)
    const computedTimePanelWidth =
      timelinePanel?.current?.getBoundingClientRect()?.width
    const isCurrenTiimeChanged = currentTime !== previousTime
    const isBeyondViewportWhenPlaying =
      currentTimePixels >= computedTimePanelWidth / 2
    const isBeyondRightViewportWhenPaused =
      currentTimePixels >= (3 / 4) * computedTimePanelWidth

    if (
      isPlaying &&
      isCurrenTiimeChanged &&
      computedTimePanelWidth &&
      isBeyondViewportWhenPlaying
    ) {
      scrollbar.current._container.scrollLeft =
        currentTimePixels - computedTimePanelWidth / 2
    }

    if (
      isPlaying &&
      isCurrenTiimeChanged &&
      computedTimePanelWidth &&
      !isBeyondViewportWhenPlaying
    ) {
      scrollbar.current._container.scrollLeft = 0
    }

    if (!isPlaying && currentTime <= 0) {
      scrollbar.current._container.scrollLeft = 0
    }

    if (
      !isPlaying &&
      isCurrenTiimeChanged &&
      computedTimePanelWidth &&
      isBeyondRightViewportWhenPaused
    ) {
      scrollbar.current._container.scrollLeft =
        currentTimePixels - (3 / 4) * computedTimePanelWidth
    }
  }
}

export default new TimelineScroller()
