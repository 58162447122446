import _ from 'lodash'
import {
  AUDIO,
  MUSIC,
  TEXT,
  VIDEO,
  VOICEOVER,
} from '../../../constants/Channels'
import DurationGetter from '../../time/DurationGetter'
import ProjectItemsDecorator from '../ProjectItemsDecorator'

class ProjectItemDeleter {
  deleteAudioItem(project, item, projectItemPath) {
    const clonedProject = _.cloneDeep(project)
    const itemId = item.id
    const itemsToSave = _.reject(
      project[projectItemPath],
      (item) => item.id === itemId,
    )

    clonedProject[projectItemPath] =
      ProjectItemsDecorator.reorderItemsByStartTime(itemsToSave)
    clonedProject.duration = DurationGetter.getDurationFromProject(project)

    return clonedProject
  }

  deleteTextItem(project, voiceoverItem, projectItemPath) {
    const clonedProject = _.cloneDeep(project)
    const voiceoverItemId = voiceoverItem.id

    const deletedTextItems = _.map(
      clonedProject[projectItemPath],
      (items, ratio) => {
        const itemsToSave = _.filter(
          project[projectItemPath][ratio],
          (item) => item.id === voiceoverItemId,
        )
        return itemsToSave
      },
    )

    localStorage.setItem(
      'cerebriam_deleted_text_items',
      JSON.stringify(deletedTextItems),
    )

    clonedProject[projectItemPath] = _.map(
      clonedProject[projectItemPath],
      (items, ratio) => {
        const itemsToSave = _.reject(
          project[projectItemPath][ratio],
          (item) => item.id === voiceoverItemId,
        )
        return itemsToSave
      },
    )

    clonedProject.selectLayout =
      ProjectItemsDecorator.getUpdatedSelectLayout(clonedProject)
    clonedProject.duration = DurationGetter.getDurationFromProject(project)

    return clonedProject
  }

  deleteVideoItem(project, item, projectItemPath) {
    const clonedProject = _.cloneDeep(project)
    const itemId = item.id

    const deletedVideoItems = _.map(
      clonedProject[projectItemPath],
      (items, ratio) => {
        const itemsToSave = _.filter(
          project[projectItemPath][ratio],
          (item) => item.id === itemId,
        )
        return itemsToSave
      },
    )
    localStorage.setItem(
      'cerebriam_deleted_video_items',
      JSON.stringify(deletedVideoItems),
    )

    clonedProject[projectItemPath] = _.map(
      clonedProject[projectItemPath],
      (items, ratio) => {
        const itemsToSave = _.reject(
          project[projectItemPath][ratio],
          (item) => item.id === itemId,
        )
        return itemsToSave
      },
    )

    clonedProject.selectLayout =
      ProjectItemsDecorator.getUpdatedSelectLayout(clonedProject)
    clonedProject.duration = DurationGetter.getDurationFromProject(project)

    return clonedProject
  }

  deleteItem(project, item, channel) {
    switch (channel) {
      case MUSIC.code:
        return this.deleteAudioItem(project, item, MUSIC.projectPath)
      case VOICEOVER.code:
        return this.deleteAudioItem(project, item, VOICEOVER.projectPath)
      case AUDIO.code:
        return this.deleteAudioItem(project, item, AUDIO.projectPath)
      case TEXT.code:
        return this.deleteTextItem(project, item, TEXT.projectPath)
      case VIDEO.code:
        return this.deleteVideoItem(project, item, VIDEO.projectPath)
      default:
        return project
    }
  }
}

export default new ProjectItemDeleter()
