import _ from 'lodash'
/* eslint-disable no-undef */
import { TimelineMax } from 'gsap'
import * as TimelineSet from '../../utils/staging-preview/TimelineSet'

class TweenObjectBuilder {
  constructor(tweenWrapper, timeSlider) {
    this.tweenWrapper = tweenWrapper
    this.timeSlider = timeSlider
    this.htmlElements = {}
  }

  withTimeSlider(timeSlider) {
    this.timeSlider = timeSlider
    return this
  }

  withEvents(onUpdate, onComplete) {
    this.onUpdate = onUpdate
    this.onComplete = onComplete
    return this
  }

  withCurrentScene(currentScene) {
    this.currentScene = currentScene
    return this
  }

  withHTMLAssets(channelCode, channelHtmlElements) {
    _.set(this.htmlElements, channelCode, channelHtmlElements)
    return this
  }

  build(project) {
    const tweenObject = TimelineSet.SlideRender(
      {
        previewTimeLine: null,
        slides: project ? project.scenes[project.resolution] : [],
        texts: project ? project.texts[project.resolution] : [],
        updateSlider: this.onUpdate,
        complete: this.onComplete,
        t_slider: this.timeSlider,
      },
      this.currentScene,
      project,
      this.tweenWrapper,
      this.htmlElements,
    )
    return tweenObject
  }
}

export default TweenObjectBuilder
