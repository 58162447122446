import instance from '../../utils/Axios'
import setAuthToken from '../../utils/SetAuthToken'
import jwt_decode from 'jwt-decode'

import { HOME } from '../../constants/Routes'
import {
  REMOTE_USER_LOADED,
  UPDATE_REMOTE_USER_LOAD_STATUS,
  PASSWORD_SET_ERROR,
} from './Types'
import { setCurrentUser } from './AuthActions'

const updateRemoteUserLoadStatus = (status) => ({
  type: UPDATE_REMOTE_USER_LOAD_STATUS,
  status,
})

const remoteUserLoaded = (payload) => ({
  type: REMOTE_USER_LOADED,
  payload,
})

const passwordSetError = (payload) => ({
  type: PASSWORD_SET_ERROR,
  payload,
})

export const getUser = (orderKey) => (dispatch) => {
  instance
    .get(`/users/wc/${orderKey}`)
    .then((response) => {
      dispatch(remoteUserLoaded({ ...response.data, orderKey: orderKey }))
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 404) {
          dispatch(updateRemoteUserLoadStatus('not-found'))
        }
        if (error.response.status === 409) {
          dispatch(updateRemoteUserLoadStatus('already-signed-up'))
        }
      }
    })
}

export const setPassword = (userData, history) => (dispatch) => {
  instance
    .post('/users/wc/set-password', userData)
    .then((response) => {
      const { token } = response.data
      localStorage.setItem('jwtTokenCTEditor', token)
      // Set token to Auth header
      setAuthToken(token)
      // Decode token to get user data
      const decoded = jwt_decode(token)
      // Set current user
      dispatch(setCurrentUser(decoded))

      history.push(HOME)
    })
    .catch((error) => {
      dispatch(passwordSetError({ ...error.response.data }))
    })
}
