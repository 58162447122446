import {
  OrderListClickImg,
  OrderListImg,
  UnOrderListClickImg,
  UnOrderListImg,
} from '../../components/video-editor/common-items/EditorIcons'

/**
 * show text panel
 */
export function importTextToProject() {
  this.props.toggleShowTextPanel(false)
}

/**
 * Update order image status
 *
 * @param flag
 * @param state
 */
export function handleOverImg(flag, state) {
  if (flag === 'un_order') {
    this.setState({
      unOrderImg: state ? UnOrderListClickImg : UnOrderListImg,
    })
  } else {
    this.setState({
      orderImg: state ? OrderListClickImg : OrderListImg,
    })
  }
}

/**
 * hand bg color picker
 */
export function handleBackgroundTextColorClick() {
  this.setState({ displayColorPicker: !this.state.displayColorPicker })
}

export function handleClose() {
  this.setState({ displayColorPicker: false })
}

export function handleChangeBgColor(color) {
  let textModel = {
    ...this.state.textModel,
    backgroundColor: color.rgb,
    content: this.textPreview.current.innerHTML,
  }

  this.setTextModel(textModel)
}

export function handleChangeCompleteBgColor(color) {
  let textModel = {
    ...this.state.textModel,
    backgroundColor: color.rgb,
    content: this.textPreview.current.innerHTML,
  }

  this.setTextModel(textModel)
}

export function handleTextColorClick() {
  this.setState({
    displayTextColorPicker: !this.state.displayTextColorPicker,
  })
}

export function handleTextColorClose() {
  this.setState({ displayTextColorPicker: false })
}

export function handleChangeTextColor(color) {
  let textModel = {
    ...this.state.textModel,
    color: color.rgb,
    content: this.textPreview.current.innerHTML,
  }

  this.setTextModel(textModel)
}

export function handleChangeCompleteTextColor(color) {
  let textModel = {
    ...this.state.textModel,
    color: color.rgb,
    content: this.textPreview.current.innerHTML,
  }

  this.setTextModel(textModel)
}

export function handleSliderChange(value) {
  let textModel = {
    ...this.state.textModel,
    fadeTime: value,
    content: this.textPreview.current.innerHTML,
  }

  if (this.state.textModel.aniValue === 'pulse') {
    textModel = {
      ...this.state.textModel,
      pulseTime: value,
      content: this.textPreview.current.innerHTML,
    }
  } else if (this.state.textModel.aniValue === 'slide-right') {
    textModel = {
      ...this.state.textModel,
      slideRTime: value,
      content: this.textPreview.current.innerHTML,
    }
  } else if (this.state.textModel.aniValue === 'slide-left') {
    textModel = {
      ...this.state.textModel,
      slideLTime: value,
      content: this.textPreview.current.innerHTML,
    }
  }

  this.setTextModel(textModel)
}

export function switchAniValue(value) {
  let textModel = {
    ...this.state.textModel,
    aniValue: value,
    content: this.textPreview.current.innerHTML,
  }

  this.setTextModel(textModel)
}

export function setTextModel(textModel, flag = true) {
  if (flag) {
    this.setState({
      textModel: textModel,
    })
  }

  if (this.props.selectedTextIndex > -1) {
    const {
      setSelectedText,
      project,
      selectedTextIndex,
      toggleWatchProject,
      updateProject,
    } = this.props
    setSelectedText(textModel)

    let projectVal = JSON.parse(JSON.stringify(project))

    projectVal.texts[project.resolution][selectedTextIndex] = textModel

    for (let i = 0; i < 4; i++) {
      if (i !== project.resolution && project.selectLayout[i]) {
        projectVal.texts[i][selectedTextIndex] = {
          ...projectVal.texts[i][selectedTextIndex],
          content: textModel.content,
          start_time: textModel.start_time,
          end_time: textModel.end_time,
          clip: textModel.clip,
          fontFamily: textModel.fontFamily,
          fontWeight: textModel.fontWeight,
          fontStyle: textModel.fontStyle,
          textDecoration: textModel.textDecoration,
          color: textModel.color,
          backgroundColor: textModel.backgroundColor,
          aniValue: textModel.aniValue,
          fadeTime: textModel.fadeTime,
          slideRTime: textModel.slideRTime,
          slideLTime: textModel.slideLTime,
          pulseTime: textModel.pulseTime,
          order: textModel.order,
          sliderL: textModel.sliderL,
          sliderW: textModel.sliderW,
          updated: textModel.updated,
        }
      }
    }

    updateProject(projectVal)

    toggleWatchProject(true)

    setTimeout(() => {
      toggleWatchProject(false)
    }, 200)
  }
}

export function handleChangeFont() {
  let textModel = {
    ...this.state.textModel,
    fontFamily: this.fontLists.current.value,
    content: this.textPreview.current.innerHTML,
  }

  this.setTextModel(textModel)
}

export function handleTextBold() {
  let textModel = {
    ...this.state.textModel,
    fontWeight: this.state.textModel.fontWeight === 'bold' ? 'normal' : 'bold',
    content: this.textPreview.current.innerHTML,
  }

  this.setTextModel(textModel)
}

export function handleTextItalic() {
  let textModel = {
    ...this.state.textModel,
    fontStyle:
      this.state.textModel.fontStyle === 'italic' ? 'normal' : 'italic',
    content: this.textPreview.current.innerHTML,
  }

  this.setTextModel(textModel)
}

export function handleTextDecoration() {
  let textModel = {
    ...this.state.textModel,
    textDecoration:
      this.state.textModel.textDecoration === 'underline'
        ? 'none'
        : 'underline',
    content: this.textPreview.current.innerHTML,
  }

  this.setTextModel(textModel)
}

export function handelChangeText() {
  let textModel = {
    ...this.state.textModel,
    content: this.textPreview.current.innerHTML,
  }

  this.setTextModel(textModel, false)
}
