import React, { Component } from 'react'
import { any, number, string } from 'prop-types'
import AudioVisualizer from './AudioVisualizer'

export default class AudioMic extends Component {
  constructor(props) {
    super(props)

    this.state = {
      canvas: null,
      canvasCtx: null,
    }

    this.visualizer = React.createRef()

    this.mounted = false
  }

  componentDidMount() {
    this.mounted = true

    if (this.mounted) {
      const canvas = this.visualizer.current
      const canvasCtx = canvas.getContext('2d')

      this.setState(
        {
          canvas,
          canvasCtx,
        },
        () => {
          this.visualize()
        },
      )
    }
  }

  componentWillUnmount() {
    this.mounted = false
  }

  visualize = () => {
    const { backgroundColor, strokeColor, width, height, stream } = this.props

    const { canvas, canvasCtx } = this.state

    AudioVisualizer.visualizeSineWave(
      stream,
      canvasCtx,
      canvas,
      width,
      height,
      backgroundColor,
      strokeColor,
    )
  }

  render() {
    const { width, height } = this.props

    return (
      <canvas
        ref={this.visualizer}
        height={height}
        width={width}
        className={this.props.className}
      />
    )
  }
}

AudioMic.propTypes = {
  backgroundColor: string,
  strokeColor: string,
  className: string,
  width: number,
  height: number,
  stream: any,
}

AudioMic.defaultProps = {
  backgroundColor: 'rgba(255, 255, 255, 0.5)',
  strokeColor: '#000000',
  className: 'visualizer',
  width: 640,
  height: 100,
}
