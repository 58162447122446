import _ from 'lodash'

class SnapGridTransformGetter {
  getLeftTransformPosition(snapGridPosition, boxSize, showSize, direction) {
    let leftV = snapGridPosition.left
    switch (direction) {
      case 'center': {
        const middleOfViewportWidth = showSize.clientW / 2
        const middleOfBoxWidth = boxSize.width / 2
        leftV = Math.round(middleOfViewportWidth - middleOfBoxWidth)
        break
      }
      case 'left': {
        leftV = snapGridPosition.left - boxSize.width
        break
      }
      case 'right': {
        const viewportWidth = showSize.clientW
        leftV =
          snapGridPosition.left + Math.round(viewportWidth - boxSize.width)
        break
      }
    }

    if (leftV < showSize.left) {
      leftV = showSize.left
    }

    if (leftV + boxSize.width > showSize.left + showSize.sWidth) {
      leftV = showSize.left + showSize.sWidth - boxSize.width
    }
    return leftV
  }

  getTopTransformPosition(snapGridPosition, boxSize, showSize) {
    let topV = Math.round(snapGridPosition.top - boxSize.height / 2)
    if (topV < showSize.top) {
      topV = showSize.top
    }

    if (topV + boxSize.height > showSize.sHeight + showSize.top) {
      topV = showSize.top + showSize.sHeight - boxSize.height
    }
    return topV
  }
}

export default new SnapGridTransformGetter()
