import MainVideoPNG from '../../../assets/images/editor/video.png'

import CheckboxIcon from '../../../assets/images/editor/icons/checkbox.png'
import PaletteIcon from '../../../assets/images/editor/icons/palette-line.png'

import SkipBackwardFillIcon from '../../../assets/images/editor/icons/skip-backward-fill.png'
import SkipForwardFillIcon from '../../../assets/images/editor/icons/skip-forward-fill.png'
import PlayMiniFillIcon from '../../../assets/images/editor/icons/play-mini-fill.png'
import PauseMiniHoverIcon from '../../../assets/images/editor/icons/pause-mini-fill-hover.png'

import FilesIcon from '../../../assets/images/editor/icons/files.png'
import SoundIcon from '../../../assets/images/editor/icons/sound.png'
import VoiceOverIcon from '../../../assets/images/editor/icons/user-voice-line.png'
import MusicFillIcon from '../../../assets/images/editor/icons/music-fill.png'
import TextLineIcon from '../../../assets/images/editor/icons/text.png'
import CloseFileIcon from '../../../assets/images/editor/icons/close.com.png'
// side panel icons
import PanelPaletteIcon from '../../../assets/images/editor/icons/panel-palette.png'
import CheckboxCircleIcon from '../../../assets/images/editor/icons/checkbox-circle-line.png'
// Timeline icons
import ScissorsIcon from '../../../assets/images/editor/icons/scissors-cut.png'
import DeleteIcon from '../../../assets/images/editor/icons/delete.png'
import UndoIcon from '../../../assets/images/editor/icons/undo.png'
import PlusIcon from '../../../assets/images/editor/plus.png'
import PlusVideoIcon from '../../../assets/images/editor/add-button.png'
import PlusAudioIcon from '../../../assets/images/editor/add-button-audio.png'
import ReturnIcon from '../../../assets/images/editor/icons/circle.png'

import BlankPNG from '../../../assets/images/png/editor/blank.png'
// Import Library icons
import FolderIcon from '../../../assets/images/editor/icons/library-icon.png'
import UploadIcon from '../../../assets/images/editor/icons/upload.png'
import AudioIcon from '../../../assets/images/editor/icons/audio-icon.png'
import BrandIcon from '../../../assets/images/editor/icons/brand-icon.png'
import TemplateIcon from '../../../assets/images/editor/icons/template-icon.png'
import AudioImg from '../../../assets/images/editor/audio.png'
import UnOrderListIcon from '../../../assets/images/editor/icons/unorderedlist.png'
import UnOrderListClickIcon from '../../../assets/images/editor/icons/unorderedlist-click.png'
import OrderListIcon from '../../../assets/images/editor/icons/orderedlist.png'
import OrderListClickIcon from '../../../assets/images/editor/icons/orderedlist-click.png'

import projects_icon from '../../../assets/images/editor/icons/projects_icon.png'
import videos_icon from '../../../assets/images/editor/icons/videos_icon.png'

export const VideosIcon = videos_icon
export const ProjectsIcon = projects_icon

// Main video bg image
export const MainVideoImg = MainVideoPNG

// mobile apply edit, filter, color palette buttons
export const CheckboxImg = CheckboxIcon
export const PaletteImg = PaletteIcon

// video controller buttons
export const SkipBackwardFillImg = SkipBackwardFillIcon
export const SkipForwardFillImg = SkipForwardFillIcon
export const PlayMiniFillImg = PlayMiniFillIcon
export const PauseMiniHoverImg = PauseMiniHoverIcon
export const CloseFileImg = CloseFileIcon

// mobile timeline editor buttons
export const FilesImg = FilesIcon
export const SoundImg = SoundIcon
export const VoiceOverImg = VoiceOverIcon
export const MusicFillImg = MusicFillIcon
export const TextLineImg = TextLineIcon

// Library icons
export const FolderIconPng = FolderIcon
export const UploadIconPng = UploadIcon
export const AudioIconPng = AudioIcon
export const AudioImgPng = AudioImg
export const BrandIconPng = BrandIcon
export const TemplateIconPng = TemplateIcon

export const PanelPaletteImg = PanelPaletteIcon
export const CheckboxCircleImg = CheckboxCircleIcon

export const ScissorsImg = ScissorsIcon
export const DeleteImg = DeleteIcon
export const UndoImg = UndoIcon
export const PlusImg = PlusIcon
export const PlusVideoImg = PlusVideoIcon
export const PlusAudioImg = PlusAudioIcon
export const ReturnImg = ReturnIcon

export const UnOrderListImg = UnOrderListIcon
export const UnOrderListClickImg = UnOrderListClickIcon
export const OrderListImg = OrderListIcon
export const OrderListClickImg = OrderListClickIcon

export const BlankBGImg = BlankPNG
