import process from 'process'
import {
  CHECK_ADD_ITEM,
  CHECK_Select_ITEM,
  SET_PREVIEW_TIMELINE,
  SET_TIME_SLIDER,
} from './Types'
import * as ProjectEditService from '../../services/ProjectEditService'
import {
  createProject,
  deleteProject,
  updateProject as updateProjectAction,
} from '../../services/EditorServices'
import * as ProjectEditConstants from '../../redux/constants/ProjectEditConstants'
import * as MainContainerActions from '../../redux/actions/MainContainerActions'
import { DEFAULT_PROJECT } from '../../constants/TimelineItems'

/**
 * check to add item in the import modal
 * @param addItem
 * @returns {{addItem, type: string}}
 */
export const checkAddItem = (addItem) => {
  return {
    type: CHECK_ADD_ITEM,
    addItem,
  }
}

/**
 * seleted item in the import modal
 * @param selectItem
 * @returns {{selectItem, type: string}}
 */
export const checkSelectItem = (selectItem) => {
  return {
    type: CHECK_Select_ITEM,
    selectItem,
  }
}

export const updateProject = (project, updateDB = true) => {
  return async (dispatch) => {
    project = ProjectEditService.getProjectWithProperDuration(project)
    dispatch({
      type: ProjectEditConstants.UPDATE_PROJECT,
      project: project,
    })
    if (updateDB) await updateProjectAction(project.id, project)
    process.nextTick(() => {
      dispatch(MainContainerActions.reloadPreviewTimeline(project))
    })
  }
}

export const initiateProject = (id) => {
  return async (dispatch) => {
    const projectNewAction = await createProject(DEFAULT_PROJECT, id)
    dispatch({
      type: ProjectEditConstants.UPDATE_PROJECT,
      project: projectNewAction.data.project,
    })
  }
}

export const deleteAndCloseProject = (id) => {
  return async (dispatch) => {
    dispatch({
      type: ProjectEditConstants.CLEAR_PROJECT,
    })
    await deleteProject(id)
  }
}

export const saveAndCloseProject = (project) => {
  return async (dispatch) => {
    await updateProjectAction(project.id, project)
    dispatch({
      type: ProjectEditConstants.CLEAR_PROJECT,
    })
  }
}

export const addScenesClip = (scene, index, layout) => {
  return {
    type: 'ADD_SCENE_DATA',
    layout: layout,
    s_index: index,
    scene: scene,
  }
}

export const addTexts = (text, index, layout) => {
  return {
    type: 'ADD_TEXT_DATA',
    layout: layout,
    t_index: index,
    texts: text,
  }
}

export const addSounds = (sound, index) => {
  return {
    type: 'ADD_SOUND_DATA',
    a_index: index,
    sounds: sound,
  }
}

export const addMusics = (music, index) => {
  return {
    type: 'ADD_MUSIC_DATA',
    m_index: index,
    music: music,
  }
}

/**
 * store project steps data for undo redo
 * @param payload
 * @returns {{payload, type: string}}
 */
export const storeProjectSteps = (payload) => {
  return {
    type: 'STORE_PROJECT_STEPS',
    payload,
  }
}

/**
 * undo
 * @returns {{type: string}}
 */
export const onUndo = () => {
  return {
    type: 'UNDO_PROJECT',
  }
}

/**
 * watch project data updated
 * @param watchProject
 * @returns {{watchProject, type: string}}
 */
export const toggleWatchProject = (watchProject) => {
  return {
    type: 'watch-project',
    watchProject,
  }
}

/**
 * timeslider data
 * @param timeSlider
 * @returns {{type: string, timeSlider}}
 * @constructor
 */
export const SetTimeSlider = (timeSlider) => {
  return {
    type: SET_TIME_SLIDER,
    timeSlider,
  }
}

/**
 * preview timeline object
 * @param previewTimeLine
 * @returns {{previewTimeLine, type: string}}
 * @constructor
 */
export const SetPreviewTimeline = (previewTimeLine) => {
  return {
    type: SET_PREVIEW_TIMELINE,
    previewTimeLine,
  }
}

export const SetStageWidth = (stageW) => {
  return {
    type: 'set-stage-width',
    stageW,
  }
}

export const SetStageHeight = (stageH) => {
  return {
    type: 'set-stage-height',
    stageH,
  }
}
