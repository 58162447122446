export const EditorVideoIcon = (props) => {
  return (
    <svg
      {...props}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_588_2201)">
        <path
          d="M12.2852 5.82985C12.9136 5.30602 13.8044 5.34756 14.3705 5.91523L14.7359 6.28367L18.5526 2.44229C17.8588 1.43311 16.6981 0.769287 15.3843 0.769287H10.7691C8.64844 0.769287 6.92312 2.4946 6.92312 4.61528V7.69208C6.92312 8.50512 7.18003 9.2574 7.61232 9.87891L12.2852 5.82985ZM9.60532 2.39691C10.2422 2.39691 10.7591 2.91381 10.7591 3.55071C10.7591 4.18761 10.2422 4.70451 9.60532 4.70451C8.96842 4.70451 8.45152 4.18761 8.45152 3.55071C8.45152 2.91381 8.96842 2.39691 9.60532 2.39691ZM19.2303 4.61605V7.69285C19.2303 9.81353 17.505 11.5388 15.3843 11.5388H10.7691C10.0199 11.5388 9.32533 11.315 8.73382 10.9427L13.279 6.9998L13.6452 7.36901C14.2444 7.96822 15.2212 7.96822 15.822 7.36747L19.1688 4.003C19.2011 4.20376 19.2303 4.40606 19.2303 4.61605ZM13.8459 13.0772C13.4213 13.0772 13.0767 13.4218 13.0767 13.8464V14.6156H11.5383V13.8464C11.5383 13.4172 11.1883 13.078 10.7599 13.0772C8.24999 13.0726 6.14854 11.3504 5.55702 9.02356C5.47241 8.68973 5.16396 8.46205 4.82013 8.46205H4.23093C2.31947 8.46205 0.769531 10.012 0.769531 11.9234V15.7694C0.769531 17.6809 2.31947 19.2308 4.23093 19.2308H11.1537C13.0652 19.2308 14.6151 17.6809 14.6151 15.7694V13.8464C14.6151 13.4218 14.2705 13.0772 13.8459 13.0772ZM2.30793 13.0772H3.84633V14.6156H2.30793V13.0772ZM3.84633 10.0389V11.5388H2.35023C2.50407 10.7873 3.09482 10.1927 3.84633 10.0389ZM2.35023 16.154H3.84633V17.654C3.09405 17.5009 2.50407 16.9063 2.35023 16.154ZM11.5383 17.6532V16.154H13.0344C12.8806 16.9055 12.2898 17.5001 11.5383 17.6532Z"
          fill="#6B7280"
        />
      </g>
      <defs>
        <clipPath id="clip0_588_2201">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
