import {
  ReactVideoRecorderDataAvailableTimeoutError,
  ReactVideoRecorderDataIssueError,
  ReactVideoRecorderMediaRecorderUnavailableError,
  ReactVideoRecorderRecordedBlobsUnavailableError,
} from './CustomErrors'

export function setScaleValue(scaleVal) {
  this.setState({ scaleVal })
  this.props.setScaleValue(scaleVal)
}

/**
 * If webrtc is supported, will turn on webrtc camera
 */
export function turnOnCamera() {
  this.setState({
    isConnecting: true,
    isReplayingVideo: false,
    isLoadedReplayVideo: false,
    error: null,
    pauseRecorder: false,
  })

  const fallbackConstraints = {
    audio: true,
    video: {
      facingMode: 'environment',
    },
  }

  navigator.mediaDevices
    .getUserMedia(fallbackConstraints)
    .catch((err) => {
      // there's a bug in chrome in some windows computers where using `ideal` in the constraints throws a NotReadableError
      if (
        err.name === 'NotReadableError' ||
        err.name === 'OverconstrainedError'
      ) {
        console.log(
          `Got ${err.name}, trying getUserMedia again with fallback constraints`,
        )

        return navigator.mediaDevices
          .getUserMedia({
            audio: true,
            video: true,
          })
          .catch((err1) => {
            // throw err1
            console.log(err1)

            window.close()
          })
      }

      throw err
    })
    .then(this.handleSuccess)
    .catch(this.handleError)
}

/**
 * turn off camera
 */
export function turnOffCamera() {
  if (this.stream) {
    this.stream.getTracks().forEach((stream) => stream.stop())

    this.setState({
      isCameraOn: false,
    })
  }
}

/**
 * Camera turn on success
 * @param stream
 */
export function handleSuccess(stream) {
  this.stream = stream
  this.setState({
    isCameraOn: true,
    thereWasAnError: false,
  })

  if (window.URL) {
    this.cameraVideo.srcObject = stream
  } else {
    this.cameraVideo.src = stream
  }

  const track = this.stream.getVideoTracks()[0]
  const capabilities = track.getCapabilities && track.getCapabilities()
  // Check whether zoom is supported or not.
  if (!capabilities || !('zoom' in capabilities)) {
    console.log('Zoom is not supported by ' + track.label)

    this.props.setZoomAbleState(false)

    this.setState({
      zoomAble: false,
    })

    return false
  }

  this.props.setZoomAbleState(true)

  this.setState({
    zoomAble: true,
  })

  const { scaleVal } = this.state

  const zoomVal = scaleVal === 'medium' ? 200 : scaleVal === 'long' ? 100 : 500
  track.applyConstraints({ advanced: [{ zoom: zoomVal }] }).catch((err) => {
    console.log(err)
  })
}

/**
 * update camera video size after loaded metadata.
 */
export function loadedCameraVideo() {
  this.setState({
    isConnecting: false,
    thereWasAnError: false,
  })

  // set up resolution, snap grid rect size.
  let resolutionValue = 1080
  if (
    this.cameraVideo.videoWidth === 1080 ||
    this.cameraVideo.videoWidth === 1920
  ) {
    resolutionValue = 1080
  } else if (
    this.cameraVideo.videoWidth > 1920 &&
    this.cameraVideo.videoWidth <= 3840
  ) {
    resolutionValue = 2160
  } else if (
    this.cameraVideo.videoWidth >= 720 &&
    this.cameraVideo.videoWidth <= 1280 &&
    this.cameraVideo.videoWidth !== 1080 &&
    this.cameraVideo.videoWidth < 1920
  ) {
    resolutionValue = 720
  } else if (this.cameraVideo.videoWidth < 720) {
    resolutionValue = 480
  }

  const { appearedLowerMediaNotify } = this.props

  if (resolutionValue === 480 || resolutionValue === 720) {
    this.props.setMediaResolution(resolutionValue)

    if (resolutionValue === 480 && !appearedLowerMediaNotify) {
      this.setState({
        showLowerNotify: true,
      })

      this.props.setAppearedLowerNotify(true)
    }
  } else {
    this.props.setMediaResolution(1080)
  }

  this.props.setMediaOriginSize({
    width: this.cameraVideo.videoWidth,
    height: this.cameraVideo.videoHeight,
  })

  this.props.setCameraDimension({
    ratio: resolutionValue,
    orientation:
      this.cameraVideo.videoWidth > this.cameraVideo.videoHeight
        ? 'landscape'
        : 'vertical',
  })
}

export function loadedReplayVideo() {
  this.setState({
    isLoadedReplayVideo: true,
  })
}

export function handleError(err) {
  const { onError } = this.props

  if (onError) {
    onError(err)
  }

  this.setState({
    isConnecting: this.state.isConnecting && false,
    thereWasAnError: true,
    error: err,
  })

  this.props.setRecording && this.props.setRecording(false)

  if (this.state.isCameraOn) {
    this.turnOffCamera()
  }
}

export function getMimeType() {
  if (this.props.mimeType) {
    return this.props.mimeType
  }

  const mimeType = window.MediaRecorder.isTypeSupported
    ? this.MIME_TYPES.find(window.MediaRecorder.isTypeSupported)
    : 'video/webm'

  return mimeType || ''
}

/**
 * handle start recording event
 */
export function handleStartRecording() {
  this.setState({
    isRunningCountdown: this.props.runningCountTimer,
    isReplayingVideo: false,
    isLoadedReplayVideo: false,
  })

  const timeOutValue = this.props.runningCountTimer ? 5000 : 0

  setTimeout(() => this.startRecording(), timeOutValue)
}

/**
 * start recording - webrtc
 */
export function startRecording() {
  this.recordedBlobs = []

  const options = {
    mimeType: this.getMimeType(),
    videoBitsPerSecond: this.props.bitsPerSecond,
  }

  try {
    const { chunkSize, dataAvailableTimeout } = this.props

    this.props.setRecording && this.props.setRecording(true)
    this.setState({ isRunningCountdown: false })

    this.startedAt = new Date().getTime()
    this.duration = 0

    this.mediaRecorder = new window.MediaRecorder(this.stream, options)
    this.mediaRecorder.addEventListener('stop', this.handleStop)
    this.mediaRecorder.addEventListener('pause', this.handlePause)
    this.mediaRecorder.addEventListener('resume', this.handleResume)
    this.mediaRecorder.addEventListener('error', this.handleError)
    this.mediaRecorder.addEventListener(
      'dataavailable',
      this.handleDataAvailable,
    )
    this.mediaRecorder.start(chunkSize)

    // mediaRecorder.ondataavailable should be called every 10ms,
    // as that's what we're passing to mediaRecorder.start() above
    if (Number.isInteger(dataAvailableTimeout)) {
      setTimeout(() => {
        if (this.recordedBlobs.length === 0) {
          this.handleError(
            new ReactVideoRecorderDataAvailableTimeoutError(
              dataAvailableTimeout,
            ),
          )
        }
      }, dataAvailableTimeout)
    }
  } catch (err) {
    this.handleError(err)
  }
}

export async function updateRecordingDuration() {
  const endedAt = new Date().getTime()
  const startedAt = this.startedAt
  const duration = endedAt - startedAt
  this.duration = this.duration + duration
}

export async function handlePauseRecording() {
  if (!this.mediaRecorder) {
    this.handleError(new ReactVideoRecorderMediaRecorderUnavailableError())
    return
  }
  this.mediaRecorder.pause()
}

export async function handleResumeRecording() {
  if (!this.mediaRecorder) {
    this.handleError(new ReactVideoRecorderMediaRecorderUnavailableError())
    return
  }
  this.mediaRecorder.resume()
}

export async function handleStopRecording() {
  if (!this.mediaRecorder) {
    this.handleError(new ReactVideoRecorderMediaRecorderUnavailableError())
    return
  }
  this.mediaRecorder.stop()
}

export function handlePause(event) {
  this.handlePauseRecorder(true)
  this.updateRecordingDuration()
}

export function handleResume(event) {
  this.handlePauseRecorder(false)
  const startedAt = new Date().getTime()
  this.startedAt = startedAt
}

export function handleStop(event) {
  if (!this.recordedBlobs || this.recordedBlobs.length <= 0) {
    const error = new ReactVideoRecorderRecordedBlobsUnavailableError(event)

    this.handleError(error)
    return
  }

  const videoBlob =
    this.recordedBlobs.length === 1
      ? this.recordedBlobs[0]
      : new window.Blob(this.recordedBlobs, {
          type: this.getMimeType(),
        })

  this.setState({
    isReplayingVideo: false,
    isLoadedReplayVideo: false,
    isReplayVideoMuted: false,
    videoUrl: window.URL.createObjectURL(videoBlob),
  })

  this.props.setRecording && this.props.setRecording(false)
  // if this gets executed too soon, the last chunk of data is lost on FF
  this.mediaRecorder.ondataavailable = null

  this.turnOffCamera()

  this.setState({
    isReplayingVideo: true,
    isReplayVideoMuted: false,
  })
  // Don't update duration because a pause is active which has calculated already
  if (!this.state.pauseRecorder) {
    this.updateRecordingDuration()
  }

  this.recordComplete(videoBlob)
}

/**
 * Record completed
 * @param videoBlob
 */
export function recordComplete(videoBlob) {
  this.props.onRecordingComplete(
    videoBlob,
    this.startedAt,
    this.duration,
    'webm',
  )
}

export function handleDataIssue(event) {
  const error = new ReactVideoRecorderDataIssueError(event)

  this.handleError(error)

  return false
}

export function isDataHealthOK(event) {
  if (!event.data) return this.handleDataIssue(event)

  const { chunkSize } = this.props

  const dataCheckInterval = 2000 / chunkSize

  // in some browsers (FF/S), data only shows up
  // after a certain amount of time ~every 2 seconds
  const blobCount = this.recordedBlobs.length
  if (blobCount > dataCheckInterval && blobCount % dataCheckInterval === 0) {
    const blob = new window.Blob(this.recordedBlobs, {
      type: this.getMimeType(),
    })

    if (blob.size <= 0) return this.handleDataIssue(event)
  }

  return true
}

export function handleDataAvailable(event) {
  if (this.isDataHealthOK(event)) {
    this.recordedBlobs.push(event.data)
  }
}

export function uploadRecordedVideo() {
  this.setState({
    isReplayingVideo: false,
  })

  this.props.onUploadRecordVideo()
}

export function closeNotifyModal() {
  this.setState({
    showLowerNotify: false,
  })
}

export function closeProfileModal() {
  this.setState({
    showProfileModal: false,
  })
}
