//Main Container Reducers
import _ from 'lodash'
import * as Constants from '../constants/VideoPublishConstants'

const initialState = {
  loading: false,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case Constants.PUBLISH_SUCCESSFUL:
      return {
        ...state,
        publishSuccess: true,
      }
    case Constants.SET_PUBLISH_LOADING:
      return {
        ...state,
        loading: action.loading,
      }
    default:
      return state
  }
}
