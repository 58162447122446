const CheckoutCancelPage = () => {
  return (
    <section>
      <h2>Order cancelled</h2>
      <p>
        Forgot to add something to your cart? Shop around then come back to pay!
      </p>
    </section>
  )
}

export default CheckoutCancelPage
