import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import { loginUser, removeAuthErrors } from '../../redux/actions/AuthActions'
import { HOME, PASSWORD_FORGET, SUBSCRIBE } from '../../constants/Routes'
import {
  IsReferredUserNeverPaid,
  triggerGoogleAnalyticsEvent,
} from '../../utils/Helper'

import Surfer from '../../assets/images/landing-page/surfer.png'

const SignInModal = ({ setIsSignUpOpen, setIsSignInOpen }) => {
  return (
    <section className="cerebriam-signup">
      <div className="darkBG" onClick={() => setIsSignInOpen(false)} />
      <div className="centered">
        <div className="modal">
          <div className="surfer-image">
            <img src={Surfer} alt="" />
          </div>
          <div className="form">
            <SignInForm />
            <div className="hint">
              Don’t you have an account?{' '}
              <button
                className="link"
                onClick={() => {
                  setIsSignInOpen(false)
                  setIsSignUpOpen(true)
                }}>
                Sign up
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

class SignInFormBase extends Component {
  constructor(props) {
    super(props)

    this.state = {
      email: '',
      password: '',
      errors: {},
    }
  }

  componentDidMount() {
    const { auth, mySubscription } = this.props
    // If logged in and user navigates to Login page, should redirect them to dashboard
    // If referred users are trying to access the login page, redirect them to the referral page
    if (auth?.isAuthenticated && mySubscription) {
      const referredByAffiliate = IsReferredUserNeverPaid(auth, mySubscription)
      if (referredByAffiliate) {
        this.props.history.push(SUBSCRIBE)
      } else {
        this.props.history.push(HOME)
      }
    }
  }

  componentDidUpdate() {
    const loginError = this.props.auth.loginError
    if (loginError) {
      toast.dismiss()
      toast.error(loginError)

      this.props.removeAuthErrors()
    }
  }

  static getDerivedStateFromProps(nextProps, state) {
    const { auth, mySubscription } = nextProps
    if (auth?.isAuthenticated && mySubscription) {
      const referredByAffiliate = IsReferredUserNeverPaid(auth, mySubscription)
      if (referredByAffiliate) {
        nextProps.history.push(SUBSCRIBE)
      } else {
        nextProps.history.push(HOME)
      }
    }

    return null
  }

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value })
  }

  onSubmit = (e) => {
    e.preventDefault()
    triggerGoogleAnalyticsEvent('login', { method: 'Form' })

    const userData = {
      email: this.state.email,
      password: this.state.password,
    }

    this.props.loginUser(userData)
  }

  render() {
    const { email, password, errors } = this.state

    const isInvalid = password === '' || email === ''

    return (
      <form noValidate onSubmit={this.onSubmit}>
        <h1>
          Sign in to <b>Cerebriam Studio</b>
        </h1>
        <p>Username</p>
        <input
          id="username"
          type="email"
          value={email}
          error={errors.email}
          required
          name="email"
          onChange={this.onChange}
          placeholder="Email Address"
        />
        <p>Password</p>
        <input
          required
          id="password"
          type="password"
          name="password"
          placeholder="password"
          value={password}
          error={errors.password}
          onChange={this.onChange}
        />
        <button
          className={
            isInvalid || this.props.auth.signInLoading
              ? 'submit-disabled '
              : 'submit-enabled '
          }
          disabled={isInvalid || this.props.auth.signInLoading}
          type="submit">
          {this.props.auth.signInLoading ? 'Signing in...' : 'Sign In'}
        </button>
        <div className="hint" style={{ marginBottom: 20 }}>
          <Link className="link" to={PASSWORD_FORGET}>
            Forgot Password?
          </Link>
        </div>
      </form>
    )
  }
}

// eslint-disable-next-line no-lone-blocks
{
  /* <PasswordForgetLink /> */
}
SignInFormBase.propTypes = {
  loginUser: PropTypes.func.isRequired,
  removeAuthErrors: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  mySubscription: state.subscriptions.user,
})

const SignInForm = connect(mapStateToProps, { loginUser, removeAuthErrors })(
  withRouter(SignInFormBase),
)

export default SignInModal
