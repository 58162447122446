export const RecorderRetryIcon = (props) => {
  return (
    <svg
      {...props}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16 0C12.0646 0.00693273 8.26879 1.45883 5.33333 4.08V0H2.66667V6.85733C2.66737 7.51379 2.92846 8.14316 3.39265 8.60735C3.85684 9.07154 4.48621 9.33263 5.14267 9.33333H12V6.66667H6.48C8.64375 4.4601 11.5068 3.07369 14.5797 2.74448C17.6525 2.41527 20.7443 3.1637 23.3264 4.86182C25.9084 6.55994 27.8205 9.10227 28.7355 12.0541C29.6505 15.006 29.5117 18.184 28.3429 21.0449C27.174 23.9057 25.0477 26.2717 22.3274 27.7383C19.6071 29.2049 16.4619 29.681 13.4294 29.0851C10.397 28.4893 7.66565 26.8586 5.70248 24.4718C3.73931 22.085 2.66622 19.0904 2.66667 16H0C0 19.1645 0.938384 22.2579 2.69649 24.8891C4.45459 27.5203 6.95345 29.5711 9.87707 30.7821C12.8007 31.9931 16.0177 32.3099 19.1214 31.6926C22.2251 31.0752 25.0761 29.5514 27.3137 27.3137C29.5514 25.0761 31.0752 22.2251 31.6926 19.1214C32.3099 16.0177 31.9931 12.8007 30.7821 9.87707C29.5711 6.95345 27.5203 4.45459 24.8891 2.69649C22.2579 0.938384 19.1645 0 16 0Z"
        fill="white"
      />
    </svg>
  )
}
