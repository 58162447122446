import { useHistory } from 'react-router-dom'
export const CloseIcon = (props) => {
  const history = useHistory()
  return (
    <svg
      {...props}
      onClick={() => history.goBack()}
      width="39"
      height="39"
      viewBox="0 0 39 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M29.25 9.75L9.75 29.25"
        stroke="white"
        stroke-width="2.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.75 9.75L29.25 29.25"
        stroke="white"
        stroke-width="2.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  )
}
