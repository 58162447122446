import _ from 'lodash'
import * as VideoUploadService from '../../services/uploads/VideoUploadService'
import * as EditorServices from '../../services/EditorServices'
import * as FileUtils from '../../utils/FileUtils'
import { toast } from 'react-toastify'
import SceneItemAdder from '../../services/project-items/SceneItemAdder'
import { ASPECT_RATIO_DETAILS } from '../../constants/AspectRatios'
import { ASSET_LIBRARY } from '../../constants/AssetLibraries'
import ProjectEditService from '../../services/ProjectEditService'
import { DEFAULT_PROJECT } from '../../constants/TimelineItems'
import { VIDEO } from '../../constants/Channels'
import DurationGetter from '../../services/time/DurationGetter'

/**
 * This is the first function called as soon as the recording is completed before all the other functions
 * record finished handle
 * @param videoUrl
 * @param startedAt
 * @param duration
 * @param extension
 */
export async function completeRecord(
  videoBlob,
  startedAt,
  duration,
  extension,
) {
  const { authUser } = this.props
  // upload the file to S3 and send a link to the tracker for the processing
  if (this.props.isFaceTrackingEnabled) {
    try {
      this.setState({ isVideoFaceTracking: true })
      const { scaleValue, direction, aspectSize, defaultLayout, streamSize } =
        this.props
      const videoFilename = FileUtils.createFilenameByTimestamp()
      const selectedAspectSize = aspectSize[defaultLayout]
      const selectedStreamSize = streamSize[defaultLayout]
      const selectedAspectRatio =
        ASPECT_RATIO_DETAILS[defaultLayout].aspectRatio

      const videoFile = await VideoUploadService.uploadVideoBlob(
        authUser.id,
        videoBlob,
        videoFilename,
        extension,
      )

      const { urls: faceTrackingVideoUrls, audioUrl: faceTrackingAudio } =
        await VideoUploadService.uploadFaceTrackingVideo(
          videoFilename,
          videoFile.location,
          scaleValue,
          direction,
          selectedAspectSize,
          selectedAspectRatio,
          selectedStreamSize,
          aspectSize,
          0,
          authUser.id,
        )
      this.props.setFaceTrackingVideos(faceTrackingVideoUrls)
      this.props.setFaceTrackingVideo(videoFile.location)
      this.props.setFaceTrackingAudio(faceTrackingAudio)
      this.props.setFaceTrackingRatio(defaultLayout)
    } catch (error) {
      console.log(error)
      toast.error(error.message)
    } finally {
      this.setState({ isVideoFaceTracking: false })
    }
  }
  this.setState({
    startedAt,
    duration,
    extension,
    videoBlob,
  })
}

/**
 * Finally create an asset in the library and store the recorded Blob as a file
 * @param {*} videoBlob
 * @param {*} videoFilename
 * @param {*} duration
 * @param {*} extension
 * @returns
 */
export async function uploadFileToS3Bucket(
  videoBlob,
  videoFilename,
  duration,
  extension,
) {
  const {
    authUser,
    resolution,
    saveLayout,
    aspectSize,
    streamSize,
    originVideoSize,
    clientRectSize,
    cameraDimension,
    zoomAbleState,
    shoot_value: shootValue,
    defaultLayout,
  } = this.props

  try {
    this.setState({
      uploadingFile: true,
      uploadedFile: false,
    })
    const videoFile = await VideoUploadService.uploadVideoBlob(
      authUser.id,
      videoBlob,
      videoFilename,
      extension,
    )
    // call for the asset store (AssetsController.js) on the server side
    const response = await VideoUploadService.saveVideoAsAsset(
      authUser,
      videoFilename,
      videoFile.location,
      videoFile.key,
      duration,
      extension,
      resolution,
      saveLayout,
      aspectSize,
      streamSize,
      originVideoSize,
      clientRectSize,
      cameraDimension,
      zoomAbleState,
      shootValue,
      defaultLayout,
      false, // faceTracking is disabled
      true, // audio is enabled - doesn't matter for the cropped video, it will be checked in the cropper
    )
    if (response.data.result === 'error') {
      throw new Error(response.data.message)
    }
    return { filename: videoFilename, file: videoFile, response }
  } catch (error) {
    toast.error(error.message)
    throw error
  } finally {
    this.setState({
      uploadingFile: false,
      uploadedFile: true,
    })
  }
}

/**
 * upload captured video file to s3 bucket
 */
export async function onUploadToS3Bucket() {
  try {
    if (!this.state.videoBlob) {
      console.log('no videoBlob')
      return
    }
    const { defaultLayout, saveLayout, project, updateProject } = this.props
    const { duration, videoBlob, extension } = this.state
    const videoFilename = FileUtils.createFilenameByTimestamp()
    const { response } = await this.uploadFileToS3Bucket(
      videoBlob,
      videoFilename,
      duration,
      extension,
    )
    const libraryData = _.get(response, 'data.data')
    const allLayoutEnabled =
      VideoUploadService.isAllSaveLayoutEnabled(saveLayout)
    const clonedProject = this.addLibraryDataToProject(
      project,
      libraryData,
      allLayoutEnabled,
    )
    clonedProject.resolution = defaultLayout

    updateProject &&
      updateProject(clonedProject, clonedProject?.projectId ? true : false)
    const projectId = clonedProject.id || 0

    this.props.history.push(`/editor/${projectId}`)
  } catch (err) {
    console.error(err)
    toast.error(err.message)
  } finally {
    this.setState({
      uploadingFile: false,
      uploadedFile: false,
    })
  }
}

export function addLibraryDataToProject(
  project,
  libraryData,
  allLayoutEnabled,
) {
  const { authUser, availGridSizes, defaultLayout } = this.props
  let clonedProject = _.cloneDeep(project) || DEFAULT_PROJECT
  const sceneItem = SceneItemAdder.createSceneItemFromLibrary(
    libraryData,
    ASSET_LIBRARY.CAPTURE.assetType,
    authUser.id,
    defaultLayout,
    allLayoutEnabled,
    true,
    libraryData.resolution,
  )
  const durartionToStart = DurationGetter.getMaxDurationByChannel(
    clonedProject,
    VIDEO.code,
  )

  let selectedLayout = libraryData.selectLayout
  for (let sInd = 0; sInd < selectedLayout.length; sInd++) {
    const sEl = selectedLayout[sInd]
    // The received saveLayout is incorrect, updating it with checking the aspectSize.
    // If the AspectSize isn't equal to zero it means that the user has selected the cropping box for the specified aspect ratio
    if (
      sEl &&
      (libraryData.aspectSize[sInd].width == 0 ||
        libraryData.aspectSize[sInd].height == 0)
    ) {
      selectedLayout[sInd] = false
    }
    if (
      libraryData.aspectSize[sInd].width > 0 &&
      libraryData.aspectSize[sInd].height > 0
    ) {
      selectedLayout[sInd] = true
    }
  }

  clonedProject = ProjectEditService.addItemToProject(
    clonedProject,
    sceneItem,
    VIDEO.code,
    durartionToStart,
    {
      gridSizes: availGridSizes,
      resolution: libraryData.resolution,
      selectedLayout: selectedLayout,
      streamSize: libraryData.streamSize,
      aspectSize: libraryData.aspectSize,
    },
  )
  return clonedProject
}

/**
 * upload face tracked video file to the project
 */
export async function onUploadFacetrackedVideoToS3Bucket() {
  try {
    const { faceTrackingVideo, faceTrackingAudio } = this.props
    const { project, updateProject } = this.props
    const { duration, extension } = this.state
    const {
      authUser,
      resolution,
      saveLayout,
      aspectSize,
      streamSize,
      originVideoSize,
      clientRectSize,
      cameraDimension,
      zoomAbleState,
      shoot_value: shootValue,
      defaultLayout,
    } = this.props
    const allLayoutEnabled =
      VideoUploadService.isAllSaveLayoutEnabled(saveLayout)
    const parsedUrl = new URL(faceTrackingVideo)
    const faceTrackingVideoKey = parsedUrl.pathname.substring(1) //get the key (relative path in the S3 to the file) from the url
    const path = faceTrackingVideoKey.split('/')
    const faceTrackingVideoFilename = path[path.length - 1]
    let fileNameParts = faceTrackingVideoFilename.split('.')
    const faceTrackingVideoFilenameExtension = fileNameParts.pop()
    const faceTrackingVideoFilenameWithoutExt = fileNameParts.join('.') //get the file name from the url

    // call for the asset store (AssetsController.js) on the server side
    const response = await VideoUploadService.saveVideoAsAsset(
      authUser,
      faceTrackingVideoFilenameWithoutExt,
      faceTrackingVideo,
      faceTrackingVideoKey,
      duration,
      extension,
      resolution,
      saveLayout,
      aspectSize,
      streamSize,
      originVideoSize,
      clientRectSize,
      cameraDimension,
      zoomAbleState,
      shootValue,
      defaultLayout,
      true, // face tracking video, doesn't need to crop it
      faceTrackingAudio, // means true if it exists (was returned by the tracker), so the audio has to be enabled in the asset
    )
    if (response.data.result === 'error') {
      throw new Error(response.data.message)
    }

    const libraryData = _.get(response, 'data.data')
    const clonedProject = this.addLibraryDataToProject(
      project,
      libraryData,
      allLayoutEnabled,
    )
    clonedProject.resolution = defaultLayout

    updateProject && updateProject(clonedProject)

    this.props.setFaceTrackingVideo(null)
    this.props.setFaceTrackingAudio(null)
    this.props.setFaceTrackingRatio(null)
    const projectId = clonedProject.id || 0

    if (projectId !== 0) {
      await EditorServices.updateProject(projectId, clonedProject)
    }

    this.props.history.push(`/editor/${projectId}`)
  } catch (err) {
    console.error(err)
    toast.error(err.message)
  } finally {
    this.setState({
      uploadingFile: false,
      uploadedFile: false,
    })
  }
}
