import React, { Component } from 'react'
import {
  PauseMiniHoverImg,
  PlayMiniFillImg,
  SkipBackwardFillImg,
  SkipForwardFillImg,
} from '../EditorIcons'
import { connect } from 'react-redux'
import {
  setSelectedScene,
  setSelectedSceneIndex,
  SetTimeSlider,
} from '../../../../redux/actions/EditorActions'
import {
  SetButtonAble,
  togglePlayVideo,
  toggleBackHover,
  toggleForwardHover,
  updateSliderValue,
} from '../../../../methods/editor/VideoControlMethods'
import * as SelectedItemActions from '../../../../redux/actions/SelectedItemActions'
import { triggerGoogleAnalyticsEvent } from '../../../../utils/Helper'
import { EditorForwardIcon } from '../../icons/EditorForwardIcon'
import { EditorRewindIcon } from '../../icons/EditorRewindIcon'
import { EditorPlayIcon } from '../../icons/EditorPlayIcon'

class VideoControl extends Component {
  constructor(props) {
    super(props)

    this.state = {
      backHover: false,
      forwardHover: false,
      playHover: false,
      playing: false,
      backAble: false,
      forwardAble: false,
      readyLoading: false,
    }

    this.SetButtonAble = SetButtonAble.bind(this)
    this.togglePlayVideo = togglePlayVideo.bind(this)
    this.updateSliderValue = updateSliderValue.bind(this)
    this.toggleBackHover = toggleBackHover.bind(this)
    this.toggleForwardHover = toggleForwardHover.bind(this)
  }

  componentDidMount() {
    this.setState({
      playing: this.props.scenePlaying,
    })

    this.SetButtonAble()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { scenePlaying, selectedSceneIndex, watchProject } = this.props

    if (prevProps.scenePlaying !== scenePlaying) {
      this.setState({
        playing: scenePlaying,
      })
    }

    if (
      prevProps.selectedSceneIndex !== selectedSceneIndex ||
      prevProps.watchProject !== watchProject
    ) {
      this.SetButtonAble()
    }
  }

  render() {
    const { playHover, backAble, forwardAble, readyLoading } = this.state
    const { project } = this.props
    const playing = !this.props.previewTimeLine?.paused() || false
    return (
      <div className="video-control">
        <EditorRewindIcon
          className={
            'editor-rewind-icon' +
            (!playing &&
            project &&
            project.scenes[project.resolution].length &&
            project.scenes[project.resolution].length > 1 &&
            backAble
              ? ''
              : ' disabled')
          }
          onClick={() => {
            triggerGoogleAnalyticsEvent('skip_video_backward', {
              userId: this.props.authUser?.id,
            })
            this.toggleBackHover(true)
          }}
        />

        {!readyLoading && !playing && !playHover && (
          <EditorPlayIcon
            className={`editor-play-icon${
              project?.duration !== 0 ? '' : ' disabled'
            }`}
            style={{ marginLeft: 5, marginRight: 5 }}
            onClick={() => {
              if (!this.props.disabled) {
                triggerGoogleAnalyticsEvent('play_video', {
                  userId: this.props.authUser?.id,
                })
                this.togglePlayVideo(true)
              }
            }}
          />
        )}

        {!readyLoading && playing && (
          <div
            className={`editor-pause-icon${
              project?.duration !== 0 ? '' : ' disabled'
            }`}
            style={{
              marginLeft: 5,
              marginRight: 5,
            }}
            onClick={() => {
              if (!this.props.disabled) {
                triggerGoogleAnalyticsEvent('pause_video', {
                  userId: this.props.authUser?.id,
                })
                this.togglePlayVideo(false)
              }
            }}>
            ||
          </div>
        )}
        <EditorForwardIcon
          className={
            'editor-forward-icon' +
            (!playing &&
            project &&
            project.scenes[project.resolution].length &&
            project.scenes[project.resolution].length > 1 &&
            forwardAble
              ? ''
              : ' disabled')
          }
          onClick={() => {
            triggerGoogleAnalyticsEvent('skip_video_forward', {
              userId: this.props.authUser?.id,
            })
            this.toggleForwardHover(true)
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  authUser: state.auth.user,
  project: state.editor.project,
  selectedSceneIndex: state.selectedItems.selectedSceneIndex,
  watchProject: state.editor.watchProject,
  timeSlider: state.timeline.timeSlider,
  previewTimeLine: state.timeline.previewTimeLine,
})

const mapDispatchToProps = (dispatch) => ({
  setSelectedSceneIndex: (...args) =>
    dispatch(SelectedItemActions.setSelectedSceneIndex(...args)),
  setSelectedScene: (...args) =>
    dispatch(SelectedItemActions.setSelectedScene(...args)),
  SetTimeSlider: (...args) => dispatch(SetTimeSlider(...args)),
})
export default connect(mapStateToProps, mapDispatchToProps)(VideoControl)
