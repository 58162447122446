import React from 'react'

import spinner from '../../assets/images/spinner.png'

import './Spinner.scss'

const Spinner = () => {
  return (
    <div className="w-screen h-screen fixed top-0 left-0 flex justify-center items-center bg-white opacity-75 z-200">
      <img className="cerebriam-spinner" src={spinner} alt="Loading..." />
    </div>
  )
}

export default Spinner
