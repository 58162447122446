import React, { Component } from 'react'

class CountdownTimer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      timer: 5,
    }

    this.timerWrapper = React.createRef()

    this.mounted = false
  }

  componentDidMount() {
    let i = 4
    this.mounted = true

    if (this.mounted) {
      this.setTimer = setInterval(() => {
        if (this.mounted) {
          this.setState({
            timer: i--,
          })
        }

        if (i < 0) {
          clearInterval(this.setTimer)
        }
      }, 1000)
    }
  }

  componentWillUnmount() {
    if (this.setTimer && this.mounted) {
      clearInterval(this.setTimer)
    }

    this.mounted = false
  }

  render() {
    return (
      <div
        className="relative flex justify-center items-center w-full h-full"
        ref={this.timerWrapper}>
        <div className="absolute record-count-timer rounded-full">
          <div className="l-half" />
          <div className="r-half" />
          <div className="timer-number">{this.state.timer}</div>
        </div>
      </div>
    )
  }
}

export default CountdownTimer
