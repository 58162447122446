import { FacebookIcon } from '../components/common/icons/FacebookIcon'
import { InstagramIcon } from '../components/common/icons/InstagramIcon'
import { LinkedinIcon } from '../components/common/icons/LinkedinIcon'
import { TiktokIcon } from '../components/common/icons/TikTokIcon'
import { TwitchIcon } from '../components/common/icons/TwitchIcon'
import { TwitterIcon } from '../components/common/icons/TwitterIcon'
import { YoutubeIcon } from '../components/common/icons/YoutubeIcon'
import RATIO, { ASPECT_RATIO_DETAILS } from './AspectRatios'

export const AspectRatiosPlatforms = [
  {
    id: 1,
    name: 'tiktok',
    ratios: [ASPECT_RATIO_DETAILS[RATIO.FULL_VERTICAL]],
    renderIcon: () => <TiktokIcon />,
  },
  {
    id: 2,
    name: 'facebook',
    ratios: [
      ASPECT_RATIO_DETAILS[RATIO.SQUARE],
      ASPECT_RATIO_DETAILS[RATIO.SEMI_VERTICAL],
    ],
    renderIcon: () => <FacebookIcon />,
  },
  {
    id: 3,
    name: 'twitter',
    ratios: [ASPECT_RATIO_DETAILS[RATIO.SQUARE]],
    renderIcon: () => <TwitterIcon />,
  },
  {
    id: 4,
    name: 'youtube',
    ratios: [
      ASPECT_RATIO_DETAILS[RATIO.LANDSCAPE],
      ASPECT_RATIO_DETAILS[RATIO.FULL_VERTICAL],
    ],
    renderIcon: () => <YoutubeIcon />,
  },
  {
    id: 5,
    name: 'instagram',
    ratios: [
      ASPECT_RATIO_DETAILS[RATIO.SQUARE],
      ASPECT_RATIO_DETAILS[RATIO.FULL_VERTICAL],
      ASPECT_RATIO_DETAILS[RATIO.SEMI_VERTICAL],
    ],
    renderIcon: () => <InstagramIcon />,
  },
  {
    id: 6,
    name: 'twitch',
    ratios: [ASPECT_RATIO_DETAILS[RATIO.LANDSCAPE]],
    renderIcon: () => <TwitchIcon />,
  },
  {
    id: 7,
    name: 'linkedin',
    ratios: [
      ASPECT_RATIO_DETAILS[RATIO.LANDSCAPE],
      ASPECT_RATIO_DETAILS[RATIO.SQUARE],
    ],
    renderIcon: () => <LinkedinIcon />,
  },
]
