import _ from 'lodash'
import React from 'react'
import { VIDEO } from '../../constants/Channels'
import {
  PauseMiniHoverImg,
  PlayMiniFillImg,
  SkipBackwardFillImg,
  SkipForwardFillImg,
} from '../video-editor/common-items/EditorIcons'

class MoviePlayerControls extends React.Component {
  state = {
    playHover: null,
    backAble: false,
    forwardAble: false,
    readyLoading: false,
  }

  get isBackable() {
    const { project } = this.props
    const sceneItems = _.get(
      project,
      `${VIDEO.projectPath}.${project.resolution}`,
      [],
    )
    return sceneItems.length > 1
  }

  render() {
    const { playHover, backAble, forwardAble, readyLoading } = this.state
    const { project, isPlaying } = this.props

    const isBackable = !_.isEmpty(project)
    return (
      <div className="flex flex-row items-center justify-center">
        <img
          src={SkipBackwardFillImg}
          alt="Skip Backward Icon"
          style={{
            width: '20px',
          }}
          className={`m-2 opacity-50 ${this.isBackable ? '' : 'opacity-50'}`}
          onClick={() => {
            this.toggleBackHover(true)
          }}
        />

        {!readyLoading && !isPlaying && !playHover && (
          <img
            src={PlayMiniFillImg}
            alt="Video Play Icon"
            style={{
              width: '20px',
            }}
            className={`m-2 play-controller ${
              project?.duration !== 0 ? '' : 'opacity-50'
            }`}
            onClick={() => {
              if (!this.props.disabled) {
                this.togglePlayVideo(true)
              }
            }}
          />
        )}

        {!readyLoading && isPlaying && (
          <img
            src={PauseMiniHoverImg}
            alt="Video Pause Icon"
            style={{
              width: '20px',
            }}
            className={
              'play-controller' + (project?.duration !== 0 ? '' : ' opacity-50')
            }
            onClick={() => {
              if (!this.props.disabled) {
                this.togglePlayVideo(false)
              }
            }}
          />
        )}

        <img
          src={SkipForwardFillImg}
          alt="Skip Forward Icon"
          style={{
            width: '20px',
          }}
          className={`m-1 opacity-50 ${this.isBackable ? '' : 'opacity-50'}`}
          onClick={() => {
            this.toggleForwardHover(true)
          }}
          // onTouchEnd={() => {
          //   this.toggleForwardHover(true);
          // }}
        />
      </div>
    )
  }
}

export default MoviePlayerControls
