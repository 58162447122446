export const EditorForwardIcon = (props) => {
  return (
    <svg
      {...props}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle opacity="0.5" cx="12" cy="12" r="12" fill="#E5E7EB" />
      <path
        d="M16.5809 11.079L12.0004 8.02679V10.1529L9 8V16L12.0004 13.8471V16L16.5813 12.7141C16.7108 12.6211 16.8164 12.4984 16.8892 12.3562C16.962 12.214 17 12.0564 17 11.8965C17 11.7366 16.962 11.579 16.8892 11.4368C16.8164 11.2946 16.7105 11.1719 16.5809 11.079Z"
        fill="#6B7280"
      />
    </svg>
  )
}
