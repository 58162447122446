import _ from 'lodash'
import RATIO, { ASPECT_RATIO_DETAILS } from '../../constants/AspectRatios'
import CHANNELS, { CHANNEL_DETAILS, VIDEO } from '../../constants/Channels'

class DurationGetter {
  getMaxDurationByAssets = (assets) => {
    const endTimes = _.map(assets, (asset) => asset?.end_time || 0)
    const maxDuration = _.max(endTimes) || 0
    return _.round(maxDuration, -1)
  }

  getDurationFromProject(project) {
    const ratioDurations = this.getRatioDurations(project)
    const maxDuration = _.max(ratioDurations) || 0
    return _.round(maxDuration, -1)
  }

  getRatioDurations(project) {
    return _.map(RATIO, (ratio) => this.getMaxDurationByRatio(project, ratio))
  }

  getMaxDurationByChannel(project, channelCode) {
    const channelDetails = CHANNEL_DETAILS[channelCode]
    const projectItems = _.flatMap(project[channelDetails.projectPath])

    return this.getMaxDurationByAssets(projectItems)
  }

  getMaxDurationByRatio(project, ratio) {
    const scenes = (project.scenes || [])[ratio]
    const text = (project.texts || [])[ratio]
    const audio = project.sound || []
    const music = project.music || []
    const voiceover = project.voiceover || []

    const sceneMaxDuration = this.getMaxDurationByAssets(scenes)
    const audioMaxDuration = this.getMaxDurationByAssets(audio)
    const musicMaxDuration = this.getMaxDurationByAssets(music)
    const textMaxDuration = this.getMaxDurationByAssets(text)
    const voiceoverMaxDuration = this.getMaxDurationByAssets(voiceover)

    const maxDuration =
      _.max([
        sceneMaxDuration,
        audioMaxDuration,
        musicMaxDuration,
        textMaxDuration,
        voiceoverMaxDuration,
      ]) || 0
    return _.round(maxDuration, -1)
  }
}

export default new DurationGetter()
