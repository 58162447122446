export const EditorRecordAudioIcon = () => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.5959 12.0001C15.5959 13.7103 14.2093 15.0969 12.4991 15.0969C10.7889 15.0969 9.40234 13.7103 9.40234 12.0001C9.40234 10.2899 10.7889 8.90332 12.4991 8.90332C14.2093 8.90332 15.5959 10.2899 15.5959 12.0001Z"
        fill="#008180"
      />
      <path
        d="M16.3708 12.0001C16.3708 14.1379 14.6376 15.8711 12.4999 15.8711C10.3621 15.8711 8.62891 14.1379 8.62891 12.0001C8.62891 9.86238 10.3621 8.12915 12.4999 8.12915C14.6376 8.12915 16.3708 9.86238 16.3708 12.0001Z"
        fill="#008180"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.5005 18.1936C15.9208 18.1936 18.6941 15.4203 18.6941 12C18.6941 8.57971 15.9208 5.80647 12.5005 5.80647C9.0802 5.80647 6.30696 8.57971 6.30696 12C6.30696 15.4203 9.0802 18.1936 12.5005 18.1936ZM12.5005 20.5161C17.2035 20.5161 21.0166 16.703 21.0166 12C21.0166 7.29698 17.2035 3.48389 12.5005 3.48389C7.79747 3.48389 3.98438 7.29698 3.98438 12C3.98438 16.703 7.79747 20.5161 12.5005 20.5161Z"
        fill="#008180"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.5 1.16129C0.5 0.519927 1.01993 0 1.66129 0H4.75806C5.39943 0 5.91935 0.519927 5.91935 1.16129C5.91935 1.80265 5.39943 2.32258 4.75806 2.32258H2.82258V4.25806C2.82258 4.89943 2.30265 5.41935 1.66129 5.41935C1.01993 5.41935 0.5 4.89943 0.5 4.25806V1.16129Z"
        fill="#008180"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M24.5 22.8387C24.5 23.4801 23.9801 24 23.3387 24L20.2419 24C19.6006 24 19.0806 23.4801 19.0806 22.8387C19.0806 22.1973 19.6006 21.6774 20.2419 21.6774L22.1774 21.6774L22.1774 19.7419C22.1774 19.1006 22.6973 18.5806 23.3387 18.5806C23.9801 18.5806 24.5 19.1006 24.5 19.7419L24.5 22.8387Z"
        fill="#008180"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M23.3387 -1.69205e-08C23.9801 1.11143e-08 24.5 0.519927 24.5 1.16129L24.5 4.25806C24.5 4.89943 23.9801 5.41935 23.3387 5.41935C22.6973 5.41935 22.1774 4.89943 22.1774 4.25806L22.1774 2.32258L20.2419 2.32258C19.6006 2.32258 19.0806 1.80265 19.0806 1.16129C19.0806 0.519927 19.6006 -2.14161e-07 20.2419 -1.86126e-07L23.3387 -1.69205e-08Z"
        fill="#008180"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.66129 24C1.01993 24 0.5 23.4801 0.5 22.8387L0.500001 19.7419C0.500001 19.1006 1.01993 18.5806 1.66129 18.5806C2.30265 18.5806 2.82258 19.1006 2.82258 19.7419L2.82258 21.6774L4.75807 21.6774C5.39943 21.6774 5.91936 22.1973 5.91936 22.8387C5.91935 23.4801 5.39943 24 4.75806 24L1.66129 24Z"
        fill="#008180"
      />
    </svg>
  )
}
