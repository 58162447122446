export const ReviewTiktokIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 5.36667C0 3.46836 0 2.51921 0.375289 1.79675C0.69154 1.18794 1.18794 0.69154 1.79675 0.375289C2.51921 0 3.46836 0 5.36667 0H10.6333C12.5316 0 13.4808 0 14.2032 0.375289C14.8121 0.69154 15.3085 1.18794 15.6247 1.79675C16 2.51921 16 3.46836 16 5.36667V10.6333C16 12.5316 16 13.4808 15.6247 14.2032C15.3085 14.8121 14.8121 15.3085 14.2032 15.6247C13.4808 16 12.5316 16 10.6333 16H5.36667C3.46836 16 2.51921 16 1.79675 15.6247C1.18794 15.3085 0.69154 14.8121 0.375289 14.2032C0 13.4808 0 12.5316 0 10.6333V5.36667Z"
        fill="black"
      />
      <path
        d="M4.85277 9.58047C4.91789 9.04135 5.139 8.7395 5.55568 8.43006C6.15187 8.01099 6.8966 8.24805 6.8966 8.24805V6.84173C7.07765 6.8371 7.25877 6.84783 7.43785 6.8738V8.68359C7.43785 8.68359 6.69335 8.44653 6.09716 8.86583C5.6807 9.17505 5.45914 9.47711 5.39425 10.0162C5.39222 10.309 5.44716 10.6917 5.70015 11.0225C5.6376 10.9905 5.57384 10.9539 5.50888 10.9129C4.95157 10.5387 4.85006 9.97723 4.85277 9.58047ZM10.5129 4.24145C10.1027 3.79203 9.94764 3.33828 9.89157 3.01953H10.4075C10.4075 3.01953 10.3046 3.8553 11.0543 4.67721L11.0647 4.68826C10.8627 4.56116 10.6774 4.41114 10.5129 4.24145ZM12.9982 5.51558V7.28898C12.9982 7.28898 12.3399 7.26319 11.8526 7.13924C11.1724 6.96589 10.7351 6.70001 10.7351 6.70001C10.7351 6.70001 10.433 6.51041 10.4086 6.49719V10.1593C10.4086 10.3632 10.3528 10.8724 10.1825 11.2971C9.9603 11.8527 9.61733 12.2174 9.55425 12.2919C9.55425 12.2919 9.13712 12.7849 8.40121 13.1169C7.73787 13.4163 7.15547 13.4087 6.98138 13.4163C6.98138 13.4163 5.97462 13.4562 5.06869 12.8674C4.87279 12.7377 4.68992 12.5907 4.52246 12.4284L4.52698 12.4317C5.43314 13.0204 6.43968 12.9806 6.43968 12.9806C6.61399 12.973 7.19639 12.9806 7.8595 12.6811C8.59474 12.3491 9.01255 11.8562 9.01255 11.8562C9.07495 11.7816 9.4195 11.4169 9.64084 10.8611C9.81063 10.4366 9.86693 9.92718 9.86693 9.72327V6.06164C9.89135 6.07508 10.1932 6.26468 10.1932 6.26468C10.1932 6.26468 10.6306 6.53078 11.3109 6.70391C11.7984 6.82786 12.4565 6.85364 12.4565 6.85364V5.46401C12.6817 5.5145 12.8737 5.52815 12.9982 5.51558Z"
        fill="#FD355A"
      />
      <path
        d="M12.4584 5.46401V6.85321C12.4584 6.85321 11.8002 6.82742 11.3128 6.70348C10.6325 6.53013 10.195 6.26425 10.195 6.26425C10.195 6.26425 9.89318 6.07464 9.86877 6.06121V9.7237C9.86877 9.92761 9.81292 10.437 9.64268 10.8615C9.42044 11.4174 9.07746 11.782 9.01438 11.8566C9.01438 11.8566 8.59703 12.3496 7.86134 12.6815C7.19823 12.981 6.61583 12.9734 6.44152 12.981C6.44152 12.981 5.43498 13.0209 4.52882 12.4321L4.5243 12.4289C4.42863 12.3362 4.33857 12.2384 4.25458 12.1359C3.96541 11.7833 3.78816 11.3664 3.74362 11.2475C3.74354 11.247 3.74354 11.2465 3.74362 11.2459C3.67195 11.0392 3.52138 10.5428 3.54195 10.062C3.57835 9.21361 3.87679 8.69291 3.95569 8.56246C4.16466 8.20674 4.43646 7.88846 4.75898 7.62181C5.04358 7.39171 5.36617 7.20863 5.71352 7.08008C6.08901 6.92915 6.49109 6.84825 6.89821 6.84173V8.24804C6.89821 8.24804 6.15348 8.01185 5.55752 8.43006C5.14084 8.7395 4.91973 9.04135 4.85461 9.58047C4.8519 9.97723 4.95341 10.5387 5.51026 10.9131C5.57523 10.9543 5.63898 10.9908 5.70153 11.0228C5.79882 11.1492 5.91722 11.2594 6.05197 11.3489C6.59593 11.6932 7.05172 11.7173 7.63458 11.4936C8.02322 11.3441 8.31578 11.0072 8.45143 10.6338C8.53666 10.4006 8.53553 10.166 8.53553 9.92327V3.01953H9.89205C9.94812 3.33828 10.1032 3.79203 10.5133 4.24145C10.6779 4.41114 10.8632 4.56116 11.0652 4.68826C11.1249 4.75002 11.4301 5.05533 11.8219 5.24277C12.0245 5.33966 12.2381 5.41389 12.4584 5.46401Z"
        fill="white"
      />
      <path
        d="M3.20312 10.8154V10.8165L3.23679 10.9077C3.23292 10.8971 3.22041 10.8648 3.20312 10.8154Z"
        fill="#33F3ED"
      />
      <path
        d="M5.71367 7.07977C5.36633 7.20832 5.04374 7.3914 4.75914 7.6215C4.43651 7.88875 4.16479 8.20769 3.95607 8.5641C3.87717 8.69411 3.57874 9.21525 3.54234 10.0636C3.52176 10.5444 3.67234 11.0409 3.74401 11.2476C3.74393 11.2481 3.74393 11.2486 3.74401 11.2491C3.78922 11.367 3.9658 11.7839 4.25496 12.1375C4.33896 12.24 4.42901 12.3379 4.52468 12.4305C4.21815 12.2273 3.94478 11.9817 3.71348 11.7016C3.42681 11.352 3.25001 10.9395 3.20388 10.8177C3.20383 10.8168 3.20383 10.8159 3.20388 10.8151V10.8136C3.13199 10.607 2.98096 10.1104 3.00199 9.62891C3.03839 8.78057 3.33682 8.25986 3.41573 8.12942C3.62438 7.77297 3.89611 7.45402 4.21879 7.18682C4.50333 6.95664 4.82594 6.77355 5.17333 6.64509C5.39001 6.55892 5.61574 6.49536 5.84661 6.45549C6.19455 6.39728 6.54991 6.39223 6.8995 6.44054V6.84141C6.492 6.8478 6.08952 6.9287 5.71367 7.07977Z"
        fill="#33F3ED"
      />
      <path
        d="M9.8926 3.01899H8.53607V9.92295C8.53607 10.1656 8.53607 10.3997 8.45197 10.6335C8.31496 11.0066 8.02354 11.3436 7.63512 11.4931C7.05204 11.7176 6.59625 11.6927 6.05251 11.3483C5.91753 11.2593 5.79882 11.1494 5.70117 11.0233C6.16442 11.2601 6.57907 11.256 7.09274 11.0584C7.48093 10.9089 7.77281 10.5719 7.90936 10.1986C7.99482 9.96542 7.99369 9.73074 7.99369 9.48827V2.58301H9.86682C9.86682 2.58301 9.8458 2.75463 9.8926 3.01899ZM12.4578 5.07928V5.46347C12.2379 5.41327 12.0247 5.33904 11.8225 5.24223C11.4307 5.05479 11.1254 4.74947 11.0658 4.68772C11.135 4.7313 11.2069 4.77095 11.281 4.80646C11.7574 5.03442 12.2265 5.10246 12.4578 5.07928Z"
        fill="#33F3ED"
      />
    </svg>
  )
}
