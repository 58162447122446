import React from 'react'
import _ from 'lodash'
import { ONE_SECOND } from '../../constants/Timeline'

class AudioPlayer extends React.Component {
  audioElement = React.createRef()

  state = {
    loaded: false,
    isBuffering: true,
  }

  handleOnCanPlay = () => {
    const { onBufferingFinished } = this.props
    const { isBuffering, loaded } = this.state

    if (loaded) {
      return
    }

    if (isBuffering) {
      onBufferingFinished && onBufferingFinished()
    }

    this.setState({ loaded: true, isBuffering: false }, () => {
      this.setState({ isBuffering: false })
    })
  }

  handleBuffering = () => {
    const { onBufferingStarted, currentTime, isPlaying } = this.props
    if (isPlaying) {
      this.setState({ isBuffering: true }, () => {
        onBufferingStarted && onBufferingStarted(currentTime)
      })
    }
  }

  handleOnPlaying = () => {
    const { onBufferingFinished } = this.props
    onBufferingFinished && onBufferingFinished()
    this.setState({ isBuffering: false }, () => {
      // this.animationTimeline.timeScale(this.animationTimeline.timeScale() || 0.001).play(this.itemCurrentTime / ONE_SECOND)
    })
  }

  handleOnLoaded = () => {
    this.setState({ isBuffering: false, loaded: true })
  }

  componentDidUpdate(prevProps) {
    const { tickInMilliseconds, isPlaying, isRecording, item } = this.props
    const { loaded } = this.state
    const isPlayingStopped =
      this.props.isPlaying !== prevProps.isPlaying && !this.props.isPlaying
    const isPlayingStarted =
      this.props.isPlaying !== prevProps.isPlaying && this.props.isPlaying
    const endOfFrameInMilliseconds = this.props.currentTime + tickInMilliseconds
    const isBetweenFrame =
      this.props.currentTime <= this.props.item.start_time &&
      this.props.item.start_time <= endOfFrameInMilliseconds
    const isBetweenItem =
      this.props.currentTime > this.props.item.start_time &&
      this.props.currentTime < this.props.item.end_time

    const isDurationChanged =
      _.get(this.props.item, 'clip.duration', 0) !==
      _.get(prevProps.item, 'clip.duration', 0)
    const isStartAnimationChanged =
      _.get(this.props.item, 'startAni.time', 0) !==
      _.get(prevProps.item, 'startAni.time', 0)
    const isEndAnimationChanged =
      _.get(this.props.item, 'endAni.time', 0) !==
      _.get(prevProps.item, 'endAni.time', 0)

    if (isDurationChanged || isStartAnimationChanged || isEndAnimationChanged) {
      if (isPlaying) {
        this.audioElement.current.play()
      }
      return
    }

    if (
      isPlayingStarted &&
      (isBetweenItem || isBetweenFrame) &&
      !isRecording &&
      loaded
    ) {
      this.audioElement.current.play()
      return
    }

    // Check if the time is within a playable audio (which is paused) and clip is still playing
    if (
      isPlaying &&
      isBetweenItem &&
      loaded &&
      !isRecording &&
      this.audioElement.current.paused
    ) {
      this.audioElement.current.play()
      return
    }
    if ((!isBetweenItem && loaded) || isRecording) {
      this.audioElement.current.pause()
      return
    }
    // Check if the time is within a playable audio

    const isBothItemExisting = this.props.item && prevProps.item
    //    const isOriginUrlChanged =
    //      this.props.item.originUrl !== prevProps.item.originUrl
    const isUrlChanged = this.props.item.url !== prevProps.item.url
    const isItemDifferentFromPrevious = this.props.item.id !== prevProps.item.id

    if (
      this.props.isPlaying &&
      isBothItemExisting &&
      //      (isOriginUrlChanged || isItemDifferentFromPrevious)
      (isUrlChanged || isItemDifferentFromPrevious)
    ) {
      //      this.audioElement.current.load(this.props.item.originUrl)
      this.audioElement.current.load(this.props.item.url)
      this.audioElement.current.play()
      return
    }

    if (!this.props.isPlaying) {
      this.audioElement.current.currentTime = this.itemCurrentTime / ONE_SECOND
    }

    if (isPlayingStopped) {
      return this.audioElement.current.pause()
    }
  }

  getNewItemUrl() {
    const { item } = this.props
    const newItemUrl = `${item.url}#t=${this.pausedStartTime},${this.endTime}`
    return newItemUrl
  }

  get startTime() {
    const { item } = this.props
    const clipStart = item.clip.start
    return clipStart / ONE_SECOND
  }

  get pausedStartTime() {
    const { item, currentTime } = this.props
    const clipStart = item.clip.start
    const startTime = item.start_time

    if (currentTime > startTime) {
      return (currentTime - startTime + clipStart) / ONE_SECOND
    }

    return this.endTime
  }

  get endTime() {
    const { item } = this.props
    return item.clip.end / ONE_SECOND
  }

  get itemUrl() {
    const { item, currentTime } = this.props
    const itemUrl = `${item.url}#t=${this.startTime},${this.endTime}`
    return itemUrl
  }

  get itemCurrentTime() {
    const { currentTime, item } = this.props
    const clipCurrentTime = currentTime - item.start_time
    return clipCurrentTime
  }

  render() {
    return (
      <audio
        ref={this.audioElement}
        src={this.itemUrl}
        onCanPlay={this.handleOnAudioCanPlay}
        onWaiting={this.handleBuffering}
        onPlaying={this.handleOnPlaying}
        onLoadedData={this.handleOnLoaded}
      />
    )
  }
}

export default AudioPlayer
