import { BlankBGImg } from '../components/video-editor/common-items/EditorIcons'

export const DEFAULT_PROJECT_NAME = 'Untitled'

export const DEFAULT_PROJECT = {
  id: 0,
  userId: '',
  projectName: '',
  duration: 0,
  resolution: 0,
  status: 'draft',
  type: 'product',
  publish: {
    landscape: [],
    vertical: [],
    portrait: [],
    square: [],
  },
  scenes: [[], [], [], []],
  texts: [[], [], [], []],
  sound: [],
  voiceover: [],
  music: [],
  selectLayout: [true, false, false, false],
  template: false,
  dimension: 1080,
  fps: 24,
}

export const DEFAULT_SCENE = {
  id: 0,
  type: 'video',
  start_time: 0,
  end_time: 5000,
  assetId: '',
  assetType: 'capture',
  userFolderUrl: '',
  originFileName: '',
  clip: {
    start: 0,
    end: 5000,
    duration: 5000,
  },
  startAni: {
    time: 800,
    value: 'fadeIn',
  },
  endAni: {
    time: 800,
    value: 'fadeOut',
  },
  size: {
    width: 1920,
    height: 1080,
    sliderW: 50,
    sLeft: 0,
  },
  originUrl: '',
  assetUrl: '',
  thumbUrl: BlankBGImg,
  top: 0,
  left: 0,
  media_layout: 0,
  template: false,
  order: 0,
  p_width: 1920,
  p_height: 1080,
  crop: [false, false, false, false],
  dimension: 1080,
  wScale: 1,
  hScale: 1,
  sound: false,
}

export const DEFAULT_TEXT = {
  id: 0,
  content: '<div>Your Text Here</div>',
  start_time: 0,
  end_time: 5000,
  clip: {
    start: 0,
    end: 5000,
    duration: 5000,
  },
  top: 50,
  left: 50,
  width: 258,
  height: 85,
  fontFamily: 'Montserrat',
  fontWeight: 'normal',
  fontStyle: 'normal',
  fontSize: 26,
  textDecoration: 'none',
  color: '#008180',
  backgroundColor: '#e1ebf1',
  alignment: null,
  aniValue: 'fade',
  fadeTime: 1500,
  slideRTime: 1500,
  slideLTime: 1500,
  pulseTime: 1500,
  order: 0,
  sliderL: 0,
  sliderW: 100,
  updated: false,
  media_layout: 0,
  p_width: 1920,
  p_height: 1080,
  wScale: 1,
  hScale: 1,
}

export const DEFAULT_SOUND = {
  id: 0,
  url: '',
  start_time: 0,
  end_time: 0,
  volume: 1,
  order: 0,
  clip: {
    start: 0,
    end: 5000,
    duration: 5000,
  },
  size: {
    sliderW: 50,
    sLeft: 0,
  },
  videoOrder: 0,
}

export const DEFAULT_VOICEOVER = {
  id: 0,
  url: '',
  start_time: 0,
  end_time: 0,
  volume: 1,
  order: 0,
  clip: {
    start: 0,
    end: 5000,
    duration: 5000,
  },
  size: {
    sliderW: 50,
    sLeft: 0,
  },
}

export const DEFAULT_MUSIC = {
  id: 0,
  url: '',
  name: '',
  start_time: 0,
  end_time: 0,
  volume: 1,
  clip: {
    start: 0,
    end: 5000,
    duration: 5000,
  },
  size: {
    sliderW: 50,
    sLeft: 0,
  },
  order: 0,
}

// used as an ENUM
export const TRANSITIONS = {
  FadeToBlack: 'fadeToBlack',
  FadeFromBlack: 'fadeFromBlack',
}

export const DEFAULT_TRANSITION = [
  TRANSITIONS.FadeFromBlack,
  TRANSITIONS.FadeToBlack,
]

export const fontList = [
  { name: 'Alegreya', value: 'Alegreya' },
  { name: 'Archivo', value: 'Archivo' },
  { name: 'B612', value: 'B612' },
  { name: 'BioRhyme', value: 'BioRhyme' },
  { name: 'Cairo', value: 'Cairo' },
  { name: 'Cardo', value: 'Cardo' },
  { name: 'Cormorant', value: 'Cormorant' },
  { name: 'Crimson Text', value: 'Crimson Text' },
  { name: 'Fjalla One', value: 'Fjalla One' },
  { name: 'Frank Ruhl Libre', value: 'Frank Ruhl Libre' },
  { name: 'IBM Plex Sans', value: 'IBM Plex' },
  { name: 'Karla', value: 'Karla' },
  { name: 'Lato', value: 'Lato' },
  { name: 'Lora', value: 'Lora' },
  { name: 'Montserrat', value: 'Montserrat' },
  { name: 'Mulish', value: 'Mulish' },
  { name: 'PlayFair Display', value: 'PlayFair Display' },
  { name: 'Rakkas', value: 'Rakkas' },
  { name: 'Roboto', value: 'Roboto' },
  { name: 'Rubik', value: 'Rubik' },
  { name: 'Source Sans Pro', value: 'Source Sans Pro' },
  { name: 'Spectral', value: 'Spectral' },
  { name: 'Titillium Web', value: 'Titillium' },
  { name: 'Varela', value: 'Varela' },
  { name: 'Vollkorn', value: 'Vollkorn' },
]

export const FLOAT_RATE = 100
export const BASE_CLIP_DURATION = 5000 // base duration of image clip(scene)
export const SEC_WIDTH = 20 // timeline second width
export const SLIDER_PRO = 10000 // rounding flag for calculate timeline slider percentage
