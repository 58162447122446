export const EditorUploadIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" rx="6" fill="#008180" />
      <g clip-path="url(#clip0_953_3240)">
        <g clip-path="url(#clip1_953_3240)">
          <path
            d="M15.7338 8.96613C15.5269 8.92488 15.3575 8.79551 15.27 8.61051C14.3013 6.56613 12.0556 5.42301 9.81125 5.83676C7.76625 6.21176 6.16313 7.84426 5.82125 9.89988C5.72 10.5068 5.7275 11.1143 5.84188 11.7061C5.87938 11.8993 5.79625 12.1143 5.62563 12.2693C4.91 12.9199 4.5 13.8468 4.5 14.813C4.5 16.708 6.04188 18.2505 7.9375 18.2505H14.8125C17.3975 18.2505 19.5 16.148 19.5 13.563C19.5 11.3349 17.9163 9.40113 15.7338 8.96613ZM13.9506 13.0674C13.8288 13.1893 13.6687 13.2505 13.5087 13.2505C13.3488 13.2505 13.1888 13.1893 13.0669 13.0674L12 12.0005V15.1255C12 15.4711 11.72 15.7505 11.375 15.7505C11.03 15.7505 10.75 15.4711 10.75 15.1255V12.0005L9.68313 13.0674C9.43875 13.3118 9.04375 13.3118 8.79938 13.0674C8.555 12.823 8.555 12.428 8.79938 12.1836L10.4913 10.4918C10.7325 10.2505 11.0494 10.1293 11.3663 10.1274L11.375 10.1255L11.3837 10.1274C11.7013 10.1293 12.0175 10.2505 12.2587 10.4918L13.9506 12.1836C14.195 12.428 14.195 12.823 13.9506 13.0674Z"
            fill="white"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_953_3240">
          <rect
            width="15"
            height="15"
            fill="white"
            transform="translate(4.5 4.5)"
          />
        </clipPath>
        <clipPath id="clip1_953_3240">
          <rect
            width="15"
            height="15"
            fill="white"
            transform="translate(4.5 4.5)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
