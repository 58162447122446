import _ from 'lodash'
import { DEFAULT_SOUND } from '../../constants/TimelineItems'
import TimelineService from '../time/TimelineService'

class SoundItemAdder {
  createSoundItemFromSceneItem(sceneItem, startAtMilliseconds) {
    let newSoundItem = _.cloneDeep(DEFAULT_SOUND)
    newSoundItem.id = `${sceneItem.id}_sound`
    newSoundItem.url = `${sceneItem.userFolderUrl}/${sceneItem.originFileName}_audio_dash.mp4`
    newSoundItem.start_time = startAtMilliseconds
    newSoundItem.end_time = startAtMilliseconds + sceneItem.clip.duration
    newSoundItem.clip = {
      start: 0,
      end: sceneItem.clip.end,
      duration: sceneItem.clip.duration,
    }
    newSoundItem.size = {
      sliderW: TimelineService.convertMillisecondsToPixels(
        sceneItem?.clip?.duration || 0,
      ),
      sLeft: TimelineService.convertMillisecondsToPixels(startAtMilliseconds),
    }
    return newSoundItem
  }
}

export default new SoundItemAdder()
