// Main state reducer
import { FULL_SCREEN_SET } from '../../redux/actions/Types'

const INITIAL_STATE = {
  fullscreen: false,
}

const applySetMainFullScreen = (state, action) => ({
  ...state,
  fullscreen: action.fullscreen,
})

function mainReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FULL_SCREEN_SET: {
      return applySetMainFullScreen(state, action)
    }

    default:
      return state
  }
}

export default mainReducer
