//Main Container Reducers
import _ from 'lodash'
import RATIO, { SCALE_VALUE } from '../../constants/AspectRatios'
import * as Constants from '../constants/VideoCropConstants'

const initialState = {
  currentLayout: RATIO.LANDSCAPE,
  scaleValue: SCALE_VALUE.MEDIUM,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case Constants.SET_CURRENT_LAYOUT:
      return {
        ...state,
        currentLayout: action.currentLayout,
      }
    case Constants.SET_CURRENT_SNAPGRID:
      return {
        ...state,
        snapGrid: action.snapGrid,
      }
    case Constants.SET_SCALE_VALUE:
      return {
        ...state,
        scaleValue: action.scaleValue,
      }
    case Constants.SET_LIBRARY_ASSET:
      return {
        ...state,
        libraryAsset: action.libraryAsset,
      }
    default:
      return state
  }
}
