import React from 'react'
import Moveable from 'react-moveable'
import { connect } from 'react-redux'
import { useSelector } from 'react-redux'
import { triggerGoogleAnalyticsEvent } from '../../../utils/Helper'

const Movable = ({
  moveRef,
  moveRef1,
  setStyle,
  showSize,
  setResize,
  registerSubscriber,
  unregisterSubscriber,
  isRecording,
  setGridPosition,
  setResizedRatio,
  ratioToEdit,
  checkSaved,
  selectedRatio,
  selectRatio,
  resetSelectedAspectRatios,
  clearEditRatio,
}) => {
  const [renderMovable, settRenderMovable] = React.useState(false)
  const [mountDragEnd, setMountDragEnd] = React.useState(false)
  const moveableRef = React.useRef()
  const setMoveableRef = React.useRef()
  const {
    auth: { user: authUser },
  } = useSelector((state) => state)

  React.useEffect(() => {
    if (!setMoveableRef.current && moveableRef.current) {
      setMoveableRef.current = setTimeout(() => {
        if (moveableRef?.current) {
          const requester = moveableRef.current.request('draggable')
          requester.request({ deltaX: 1, deltaY: 1 })
          requester.requestEnd()
          setMountDragEnd(true)
        }
      }, 500)
    }
  })

  React.useEffect(() => {
    settRenderMovable(true)

    const SbId = 1324

    registerSubscriber(SbId, () => {
      const target = moveRef.current

      setResize(parseInt(target.style.width), parseInt(target.style.height))
    })

    return () => unregisterSubscriber(SbId)
  }, [moveRef, registerSubscriber, setResize, unregisterSubscriber])

  const handleDrag = (e) => {
    setResizedRatio()
    setStyle(e.transform)
  }

  if (!renderMovable) return null

  const bounds = {
    left: Math.ceil((showSize.clientW - showSize.sWidth) / 2),
    top: Math.ceil((showSize.clientH - showSize.sHeight) / 2),
    right:
      Math.ceil((showSize.clientW - showSize.sWidth) / 2) + showSize.sWidth,
    bottom:
      Math.ceil((showSize.clientH - showSize.sHeight) / 2) + showSize.sHeight,
  }

  const onResize = ({ width, height, drag, delta }) => {
    // Call this to notify the app that a resize has occured
    // in order to discard the default transform value
    setResizedRatio()
    moveRef.current.style.width = width + 'px'
    moveRef.current.style.height = height + 'px'
    moveRef1.current.style.opacity = 0
    if (delta[0] || delta[1]) moveRef.current.style.transform = drag.transform
    // setGridPosition({
    //   top:
    //     moveRef.current.getBoundingClientRect().top +
    //     moveRef.current.getBoundingClientRect().height / 2,
    //   left:
    //     moveRef.current.getBoundingClientRect().left +
    //     moveRef.current.getBoundingClientRect().width / 2,
    //   set: true,
    // })
  }

  const autoSaveOnEnd = () => {
    if (typeof ratioToEdit === 'number') {
      selectRatio(selectedRatio)
      // resetSelectedAspectRatios()
      checkSaved()
      // clearEditRatio()
    }
  }

  const handleResize = ({ target }) => {
    setStyle(target.style.transform)
    setResize(parseInt(target.style.width), parseInt(target.style.height))

    triggerGoogleAnalyticsEvent('cropper_resized', {
      width: target.style.width,
      height: target.style.height,
      userId: authUser?.id,
    })

    // Call this to notify the app that a resize has occured
    // in order to discard the default transform value
    setResizedRatio()
    autoSaveOnEnd()
  }

  return (
    <Moveable
      ref={moveableRef}
      target={moveRef.current}
      draggable={!isRecording}
      resizable={!isRecording}
      keepRatio={false}
      throttleDrag={0}
      origin={false}
      snappable={true}
      edge={false}
      onDrag={handleDrag}
      bounds={bounds}
      throttleResize={0}
      onResize={onResize}
      onResizeEnd={handleResize}
      onDragEnd={({ target }) => {
        triggerGoogleAnalyticsEvent('cropper_dragged', {
          transform: target.style.transform,
          userId: authUser?.id,
        })
        if (mountDragEnd) {
          autoSaveOnEnd()
        }
      }}
    />
  )
}

const mapStateToProps = (state) => ({
  isRecording: state.recorder.isRecording,
})

export default connect(mapStateToProps)(Movable)
