import React from 'react'
import { Button, Flex, Text } from '@chakra-ui/react'
import ReactModal from 'react-modal'
import { useDispatch, useSelector } from 'react-redux'
import {
  deleteAndCloseProject,
  saveAndCloseProject,
} from '../../redux/actions/EditorActions'

ReactModal.setAppElement('#root')

const LeaveEditorConfirm = ({
  addMediaToProject,
  cancelLeaveEditor,
  saveAndClose,
}) => {
  const dispatch = useDispatch()
  // Get current project & save on database if save & close button is clicked
  const {
    editor: { project },
  } = useSelector((state) => state)
  const {
    editor: {
      project: { _id: projectId },
    },
  } = useSelector((state) => state)

  const cancelAndClose = () => {
    dispatch(deleteAndCloseProject(projectId))
    cancelLeaveEditor()
  }

  const saveClose = () => {
    dispatch(saveAndCloseProject(project)) // Pass current project to update on close
    saveAndClose()
  }
  return (
    <ReactModal
      isOpen
      contentLabel={'Low Quality Warning'}
      parentSelector={() => document.querySelector('#root')}
      onRequestClose={() => cancelLeaveEditor()}
      style={{
        overlay: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        },
        content: {
          border: '1px solid #ccc',
          background: '#fff',
          overflow: 'auto',
          WebkitOverflowScrolling: 'touch',
          borderRadius: '4px',
          outline: 'none',
          padding: '20px',
          position: 'initial',
          width: '500px',
        },
      }}>
      <Text align="center" mb="4">
        What would you like to do with your current project:
      </Text>

      <Flex align="center" justify="space-between">
        <Button
          colorScheme="teal"
          bgColor="teal.300"
          onClick={addMediaToProject}>
          Add Media
        </Button>
        <Button colorScheme="teal" bgColor="teal.300" onClick={saveClose}>
          Save & Close
        </Button>
        <Button
          colorScheme="red"
          backgroundColor="red.300"
          onClick={cancelAndClose}>
          Delete & Start Again
        </Button>
      </Flex>
    </ReactModal>
  )
}

export default LeaveEditorConfirm
