import React, { Component } from 'react'
import { connect } from 'react-redux'
import FeedBackModal from '../setting/FeedBackModal'
import {
  AccountModal,
  ContactModal,
  FollowModal,
  InfoModal,
} from '../setting/SettingModals'
import ShareModal from '../setting/ShareModal'
import * as SubscriptionActions from '../../redux/actions/SubscriptionActions'
import { SceneLeftIcon } from '../cropper/icons/SceneLeftIcon'

class SettingsSubBar extends Component {
  state = {
    accountModel: false,
    contactModal: false,
    followModal: false,
    feedBackModal: false,
    shareModal: false,
    privacyModal: false,
    termsCModal: false,
  }

  openModel = (stateName) => {
    this.setState({ [stateName]: true })
  }

  onClose = (stateName) => {
    this.setState({ [stateName]: false })
  }

  componentDidMount() {
    this.props.loadUserSubscriptions()
    this.props.loadSubscriptionProducts()
  }

  render() {
    return (
      <div className="quality-option absolute top-0 left-0 z-150">
        <div className="sub-top-item text-lg px-6 pt-5 pb-4 flex items-center bg-gray-100">
          <div
            className="item-icon cursor-pointer"
            onClick={this.props.toggleSettingsBarOpen}>
            <SceneLeftIcon />
          </div>
          <div>Settings</div>
        </div>
        <ul className="px-5 py-3">
          <li
            className="py-3 px-1 flex items-center cursor-pointer"
            onClick={() => this.openModel('accountModel')}>
            <div>Account</div>
          </li>
          <li
            className="py-3 px-1 flex items-center cursor-pointer"
            onClick={() => this.openModel('contactModal')}>
            <div>Contact Us</div>
          </li>
          <li
            className="py-3 px-1 flex items-center cursor-pointer"
            onClick={() => this.openModel('feedBackModal')}>
            <div>Feedback</div>
          </li>
          <li
            className="py-3 px-1 flex items-center cursor-pointer"
            onClick={() => this.openModel('shareModal')}>
            <div>Share app</div>
          </li>
          <li
            className="py-3 px-1 flex items-center cursor-pointer"
            onClick={() => this.openModel('followModal')}>
            <div>Follow us</div>
          </li>
          <li
            className="py-3 px-1 flex items-center cursor-pointer"
            onClick={() => this.openModel('privacyModal')}>
            <div>Privacy Policy</div>
          </li>
          <li
            className="py-3 px-1 flex items-center cursor-pointer"
            onClick={() => this.openModel('termsCModal')}>
            <div>Term & Conditions</div>
          </li>
        </ul>

        <AccountModal
          open={this.state.accountModel}
          onClose={() => this.onClose('accountModel')}
          title="Account Information"
        />

        <ContactModal
          open={this.state.contactModal}
          onClose={() => this.onClose('contactModal')}
          title="Contact us"
        />

        <FollowModal
          open={this.state.followModal}
          onClose={() => this.onClose('followModal')}
          title="Follow us"
        />

        <FeedBackModal
          open={this.state.feedBackModal}
          onClose={() => this.onClose('feedBackModal')}
          title="Feedback"
        />

        <ShareModal
          open={this.state.shareModal}
          onClose={() => this.onClose('shareModal')}
          title="Share Cerebiram"
        />

        <InfoModal
          open={this.state.privacyModal}
          onClose={() => this.onClose('privacyModal')}
          title="Privacy and Ploicy"
        />

        <InfoModal
          open={this.state.termsCModal}
          onClose={() => this.onClose('termsCModal')}
          title="Term of Service"
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  media_layout: state.recorder.media_layout,
})

const mapDispatchToProps = (dispatch) => ({
  loadUserSubscriptions: () =>
    dispatch(SubscriptionActions.currentUserSubscription()),
  loadSubscriptionProducts: () =>
    dispatch(SubscriptionActions.getAllSubscriptionProducts()),
})

export default connect(mapStateToProps, mapDispatchToProps)(SettingsSubBar)
