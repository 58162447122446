import {
  APPEAR_LOWER_NOTIFY,
  CAMERA_SHOOT_VALUE_SET,
  CAMERA_SHOT_DIRECTION_SET,
  CATEGORY_SET,
  CURRENT_SET,
  FEATURE_SET,
  MEDIA_RESOLUTION_SET,
  MENU_IS_OPEN_SET,
  MENU_IS_TEMPLATE_SAVE,
  PUBLIC_SET,
  RUNNING_COUNTDOWN_TIMER,
  SAVE_MEDIA_LAYOUT_SET,
  SELECTED_MEDIA_LAYOUT_SET,
  SET_RECORDING,
  UPDATE_ZOOM_ABLE_STATUS,
  UPLOAD_SET,
  CROPPING_FINISHED,
  REMOVE_ASPECT_SIZE,
  APPLY_RATIO_TO_SEGMENTATIONS,
  SET_ACTIVE_SEGMENTATION_SCENE,
} from './Types'
import instance from '../../utils/Axios'

/**
 * Save Captured video upload data.
 *
 * @param data
 * @returns {Promise<unknown>}
 */
export const assetsUpload = async (data) => {
  return await instance.post('/assets/asset-upload', data)
}

/**
 * set welcome frame order
 * @param selected
 * @returns {{type: string, selected: *}}
 */
export const welcomeSetStep = (selected) => {
  return {
    type: CURRENT_SET,
    selected,
  }
}

/**
 * Set category of welcome
 * @param category
 * @returns {{type: string, category: *}}
 */
export const setProjectCategory = (category) => {
  return {
    type: CATEGORY_SET,
    category,
  }
}

/**
 * set welcome feature
 * @param feature
 * @returns {{feature: *, type: string}}
 */
export const setProjectFeature = (feature) => {
  return {
    type: FEATURE_SET,
    feature,
  }
}

/**
 * Set upload file type
 * @param upload
 * @returns {{upload: *, type: string}}
 */
export const setUploadFileType = (upload) => {
  return {
    type: UPLOAD_SET,
    upload,
  }
}

/**
 * Set publication type
 * @param publication
 * @returns {{publication: *, type: string}}
 */
export const setPublicationKind = (publication) => {
  return {
    type: PUBLIC_SET,
    publication,
  }
}

/**
 * camera and re-play page toggle sidebar
 * @param menu_is_open
 * @returns {{menu_is_open: *, type: string}}
 */
export const toggleSideBar = (menu_is_open) => {
  return {
    type: MENU_IS_OPEN_SET,
    menu_is_open,
  }
}

export const toggleTemplateBar = (menu_is_template_save) => {
  return {
    type: MENU_IS_TEMPLATE_SAVE,
    menu_is_template_save,
  }
}

/**
 * media resolution set up
 * @param resolution
 * @returns {{type: string, resolution: *}}
 */
export const setMediaResolution = (resolution) => {
  return {
    type: MEDIA_RESOLUTION_SET,
    resolution,
  }
}

/**
 * set up snap grid alignment
 * @param snapAlignment
 * @returns {{snapAlignment: *, type: string}}
 */
export const setSnapGridAlign = (snapAlignment) => {
  return {
    type: CAMERA_SHOT_DIRECTION_SET,
    snapAlignment,
  }
}

/**
 * set up shooting value
 * @param shootValue
 * @param index
 * @returns {{index, type: string, shootValue: *}}
 */
export const setVideoScale = (shootValue, index) => {
  return {
    type: CAMERA_SHOOT_VALUE_SET,
    shootValue,
    index,
  }
}

/**
 * set the screen to be recording
 * @param isRecording
 * @param index
 * @returns {{isRecording, type: string}}
 */
export const setRecording = (isRecording) => {
  return {
    type: SET_RECORDING,
    isRecording,
  }
}

export const toggleRunningCountTimer = (runningCountTimer) => {
  return {
    type: RUNNING_COUNTDOWN_TIMER,
    runningCountTimer,
  }
}

export const setAppearedLowerNotify = (appearedLowerMediaNotify) => {
  return {
    type: APPEAR_LOWER_NOTIFY,
    appearedLowerMediaNotify,
  }
}

/**
 * Check zoom able state and udpate status
 * @param zoomAbleState
 * @returns {{zoomAbleState: *, type: string}}
 */
export const setZoomAbleState = (zoomAbleState) => {
  return {
    type: UPDATE_ZOOM_ABLE_STATUS,
    zoomAbleState,
  }
}

/**
 * selected layout type to crop video
 * @param selectAspectRatioType
 * @param index
 * @returns {{index, selectAspectRatioType: *, type: string}}
 */
export const setSelectedMediaLayout = (selectAspectRatioType, index) => {
  return {
    type: SELECTED_MEDIA_LAYOUT_SET,
    selectAspectRatioType,
    index,
  }
}

/**
 * save checked ratio layout on replay page
 * @param saveLayout
 * @param index
 * @param currentVideoId
 * @returns {{index, type: string, saveLayout, currentVideoId}}
 */
export const setSaveRatioLayout = (saveLayout, index, currentVideoId) => {
  return {
    type: SAVE_MEDIA_LAYOUT_SET,
    saveLayout,
    index,
    currentVideoId,
  }
}

/**
 * resets saveLayout and aspectSize after cropping is done
 * @returns nothing
 */
export const resetCropAreas = () => {
  return (dispatch) => {
    dispatch({
      type: CROPPING_FINISHED,
    })
  }
}

/**
 * remove checked ratio layout
 * @param index
 * @param currentVideoId
 * @returns {{index, currentVideoId: string}}
 */
export const removeRatioLayout = (index, currentVideoId) => {
  return {
    type: REMOVE_ASPECT_SIZE,
    index,
    currentVideoId,
  }
}

/**
 * apply ratio selections to segmentations
 * @param videoIds
 * @returns {{videoIds}}
 */
export const applyRatioSelectionsToSegmentations = (videoIds, fromSceneId) => {
  return {
    type: APPLY_RATIO_TO_SEGMENTATIONS,
    videoIds,
    fromSceneId,
  }
}

/**
 * set active segmentation scene
 * @param videoId
 * @returns {{videoId}}
 */
export const setActiveSegmentationScene = (videoId) => {
  return {
    type: SET_ACTIVE_SEGMENTATION_SCENE,
    videoId,
  }
}
