import * as EditorServices from '../../services/EditorServices'
import LibraryFilesUtils from '../../utils/library/LibraryFilesUtils'
import { isMobileOnly } from 'react-device-detect'
import _ from 'lodash'

/**
 * Initialize project data
 * @param reData
 * @param isNotAddingProjectSlide
 */
export function initialProjectData(project) {
  project.publish = project.publish[0]

  this.props.updateProject(project, false)

  if (project.scenes[project.resolution][0])
    this.props.setSelectedScene(project.scenes[project.resolution][0])
}

/**
 * get library files
 */
export async function getLibFiles() {
  const { authUser } = this.props
  const response = await EditorServices.getLibraryFiles({
    authUser: authUser.id,
  })
  const responseData = response.data
  const responseDataFiles = responseData.files
  if (responseData.result === 'success') {
    const responseAudioFiles = responseDataFiles.audios
    let audios = LibraryFilesUtils.getResponseAudioFiles(responseAudioFiles)

    this.setState({
      libraryFiles: {
        videos: LibraryFilesUtils.getSortedListByNearestUploadDate(
          responseDataFiles.medias,
        ),
        photos: LibraryFilesUtils.getSortedListByNearestUploadDate(
          responseDataFiles.photos,
        ),
        audios: LibraryFilesUtils.getSortedListByNearestUploadDate(audios),
        voiceovers: LibraryFilesUtils.getSortedListByNearestUploadDate(
          responseDataFiles.voiceover,
        ),
        brands: LibraryFilesUtils.getSortedListByNearestUploadDate(
          responseDataFiles.brands,
        ),
        templates: LibraryFilesUtils.getSortedListByNearestUploadDate(
          responseDataFiles.templates,
        ),
      },
    })
  }
}

/**
 * editor stage and timeline toggle shows
 * @param flag
 */
export function toggleShowEditor(flag) {
  this.setState({
    showEditor: flag,
  })
}

export function handleOrientationChange() {
  if (!isMobileOnly) window.location.reload()
}
