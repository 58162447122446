//Main Container Reducers
import _ from 'lodash'
import { RATIO } from '../../constants/AspectRatios'
import * as Constants from '../constants/FaceTrackConstants'

const initialState = {
  isFaceTrackingEnabled: false,
  isFaceTrackingEnabledSegmentations: [],
  faceTrackingVideos: null,
  faceTrackingVideoBlobs: null,
  faceTrackingVideo: null, // TODO: Remove after python changes made
  faceTrackingVideoBlob: null, // TODO: Remove after python changes made
  faceTrackingVideoAudio: null,
  faceTrackingRatio: RATIO.LANDSCAPE,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case Constants.SET_FACETRACKING_VIDEOS:
      return {
        ...state,
        faceTrackingVideos: action.faceTrackingVideos,
      }
    case Constants.SET_FACETRACKING_VIDEOBLOBS:
      return {
        ...state,
        faceTrackingVideoBlobs: action.faceTrackingVideoBlobs,
      }
    case Constants.SET_FACETRACKING_VIDEO: // TODO: Remove after python changes made
      return {
        ...state,
        faceTrackingVideo: action.faceTrackingVideo,
      }
    case Constants.SET_FACETRACKING_VIDEOBLOB: // TODO: Remove after python changes made
      return {
        ...state,
        faceTrackingVideoBlob: action.faceTrackingVideoBlob,
      }
    case Constants.SET_FACETRACKING_AUDIO:
      return {
        ...state,
        faceTrackingAudio: action.faceTrackingAudio,
      }
    case Constants.SET_FACETRACKING_ENABLED: {
      let segs = []
      if (action.videoId) {
        segs = _.cloneDeep(state.isFaceTrackingEnabledSegmentations).filter(
          (x) => x.id != action.videoId,
        )
      }

      return {
        ...state,
        isFaceTrackingEnabled: action.isFaceTrackingEnabled,
        isFaceTrackingEnabledSegmentations: [
          ...segs,
          ...(action.videoId
            ? [
                {
                  id: action.videoId,
                  isFaceTrackingEnabled: action.isFaceTrackingEnabled,
                },
              ]
            : []),
        ],
      }
    }
    case Constants.SET_FACETRACKING_RATIO:
      return {
        ...state,
        faceTrackingRatio: action.faceTrackingRatio,
      }

    case Constants.SET_FACETRACKING_ENABLED_SEGMENTATIONS: {
      const videoIds = action.videoIds
      const isFaceTrackingEnabledSegmentations = videoIds.map((videoId) => {
        return {
          id: videoId,
          isFaceTrackingEnabled: state.isFaceTrackingEnabled,
        }
      })
      return {
        ...state,
        isFaceTrackingEnabledSegmentations,
      }
    }
    default:
      return state
  }
}
