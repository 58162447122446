import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { HOME } from '../../constants/Routes'
import { getUser } from '../../redux/actions/WoocommerceActions'
import WooCommerceSignUp from './WooCommerceSignUp'
import { SignInLink } from './SignIn'
import AuthFooter from './AuthFooter'

class RemoteWoocommerceSignUp extends Component {
  componentDidMount() {
    if (this.props.auth.isAuthenticated) {
      this.props.history.push(HOME)
    } else {
      this.props.getUser(this.props.match.params.orderKey)
    }
  }

  render() {
    const { loadStatus } = this.props

    if (
      loadStatus === 'loading' ||
      loadStatus === 'not-found' ||
      loadStatus === 'already-signed-up'
    ) {
      return (
        <div className={'flex items-center justify-center main-area'}>
          <div className="w-full max-w-xs">
            {loadStatus === 'loading' && (
              <div
                className={'text-2xl mb-3 font-bold text-center text-blue-500'}>
                Loading...
              </div>
            )}

            {loadStatus === 'not-found' && (
              <div
                className={'text-2xl mb-3 font-bold text-center text-blue-500'}>
                Invalid or Expired Sign Up URL
              </div>
            )}

            {loadStatus === 'already-signed-up' && (
              <div
                className={'text-2xl mb-3 font-bold text-center text-blue-500'}>
                User Already Signed Up
              </div>
            )}
          </div>
        </div>
      )
    }

    if (loadStatus === 'available') {
      return (
        <>
          <div className={'flex items-center justify-center main-area'}>
            <div className="w-full max-w-xs">
              <div
                className={'text-2xl mb-3 font-bold text-center text-blue-500'}>
                Complete Your Sign Up
              </div>
              <WooCommerceSignUp />
              <SignInLink />
            </div>
          </div>
          <AuthFooter />
        </>
      )
    }
  }
}

export default connect(
  (state) => ({
    loadStatus: state.remoteWoocommerceSignUp.loadStatus,
    auth: state.auth,
  }),
  { getUser },
)(withRouter(RemoteWoocommerceSignUp))
