export const SceneLeftIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.0047 10.995L13.5977 6.40199C13.6893 6.30854 13.7985 6.23416 13.919 6.18318C14.0395 6.13219 14.1689 6.10559 14.2998 6.10493C14.4306 6.10427 14.5603 6.12956 14.6813 6.17932C14.8023 6.22909 14.9123 6.30236 15.0048 6.39488C15.0973 6.48741 15.1706 6.59736 15.2204 6.71837C15.2701 6.83939 15.2954 6.96907 15.2948 7.09992C15.2941 7.23077 15.2675 7.36019 15.2165 7.48069C15.1655 7.6012 15.0912 7.7104 14.9977 7.80199L11.0977 11.702L14.9977 15.602C15.1803 15.7883 15.2819 16.0391 15.2806 16.2999C15.2793 16.5607 15.1751 16.8105 14.9907 16.995C14.8062 17.1794 14.5565 17.2836 14.2956 17.2849C14.0348 17.2862 13.784 17.1846 13.5977 17.002L9.0047 12.41C8.81723 12.2225 8.71191 11.9682 8.71191 11.703C8.71191 11.4378 8.81723 11.1835 9.0047 10.996V10.995Z"
        fill="#D9D9D9"
      />
    </svg>
  )
}
