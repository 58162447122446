import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import * as FaceTrackActions from '../../redux/actions/FaceTrackActions'
import * as VideoCropActions from '../../redux/actions/VideoCropActions'
import * as SnapGridBoxActions from '../../redux/actions/SnapGridBoxActions'
import { ASPECT_RATIO_DETAILS } from '../../constants/AspectRatios'
import AspectRatio from 'react-aspect-ratio'

class CropperRatioButtons extends Component {
  renderRatio(ratioDetail) {
    const ratioColor = this.getRatioColor(ratioDetail)
    return (
      <div className="p-5">
        <AspectRatio
          ratio={ratioDetail.aspectRatio}
          className="fixed-height-aspect-ratio"
          style={{
            borderColor: ratioColor,
            border: '3px solid',
            color: ratioColor,
            '--height': '70px',
          }}>
          <div
            className="cursor-pointer flex flex-row justify-center items-center"
            onClick={() => this.handleRatioClick(ratioDetail)}>
            {_.replace(ratioDetail.aspectRatio, '/', ':')}
          </div>
        </AspectRatio>
      </div>
    )
  }

  handleRatioClick(ratioDetail) {
    const { setCurrentLayout, onChangeRatio } = this.props
    setCurrentLayout?.(ratioDetail.ratio)
    onChangeRatio && onChangeRatio(ratioDetail.ratio)
  }

  getRatioColor(ratioDetail) {
    const isActive = ratioDetail.ratio === this.props.currentLayout
    const isDisabled = this.isRatioDisabled(ratioDetail)
    const ratioColor = isActive ? 'white' : isDisabled ? 'gray' : 'blue'
    return ratioColor
  }

  isRatioDisabled(ratioDetail) {
    return !_.get(this.props.selectLayout, ratioDetail.ratio)
  }

  render() {
    return (
      <div className="flex w-full gap-5 flex-row justify-center items-center">
        {_.map(ASPECT_RATIO_DETAILS, (ratioDetail) =>
          this.renderRatio(ratioDetail),
        )}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  currentLayout: state.videoCrop.currentLayout,
  snapGridPosition: state.recorder.snapGridPosition,
  selectLayout: state.recorder.selectLayout,
  isFaceTrackingEnabled: state.faceTrack.isFaceTrackingEnabled,
})

const mapDispatchToProps = (dispatch) => ({
  setFaceTrackingEnabled: (...args) =>
    dispatch(FaceTrackActions.setFaceTrackingEnabled(...args)),
  setCurrentLayout: (...args) =>
    dispatch(VideoCropActions.setCurrentLayout(...args)),
  setSnapGridTransform: SnapGridBoxActions.setSnapGridTransform,
  setSnapGridPosition: SnapGridBoxActions.setSnapGridPosition,
})
export default connect(mapStateToProps, mapDispatchToProps)(CropperRatioButtons)
