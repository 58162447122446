import React, { Component } from 'react'
import { CountDownImage } from '../common-items/RecorderIcons'
import { toggleRunningCountTimer } from '../../../redux/actions/RecorderActions'
import { connect } from 'react-redux'

class CountdownSwitch extends Component {
  constructor(props) {
    super(props)

    this.state = {
      running: true,
    }

    this.mounted = false
  }

  componentDidMount() {
    this.mounted = true

    if (this.mounted) {
      this.setState({
        running: this.props.runningCountTimer,
      })
    }
  }

  componentWillUnmount() {
    this.mounted = false
  }

  onChangeRunning = () => {
    const switchRunning = !this.state.running
    if (this.mounted) {
      this.setState({
        running: switchRunning,
      })
    }

    this.props.toggleRunningCountTimer(switchRunning)
  }

  render() {
    return (
      <div
        className="countdown-switch-action cursor-pointer relative"
        onClick={this.onChangeRunning}>
        {!this.state.running && (
          <div className="rounded-full cancel-countdown absolute" />
        )}

        <img src={CountDownImage} alt="" />
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  runningCountTimer: state.recorder.runningCountTimer,
})

export default connect(mapStateToProps, { toggleRunningCountTimer })(
  CountdownSwitch,
)
