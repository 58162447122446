export const ReviewTwitchIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_654_4054)">
        <path
          d="M0.00878906 5.36667C0.00878906 3.46836 0.00878906 2.51921 0.384078 1.79675C0.700329 1.18794 1.19673 0.69154 1.80554 0.375289C2.528 0 3.47715 0 5.37546 0H10.6421C12.5404 0 13.4896 0 14.212 0.375289C14.8208 0.69154 15.3172 1.18794 15.6335 1.79675C16.0088 2.51921 16.0088 3.46836 16.0088 5.36667V10.6333C16.0088 12.5316 16.0088 13.4808 15.6335 14.2032C15.3172 14.8121 14.8208 15.3085 14.212 15.6247C13.4896 16 12.5404 16 10.6421 16H5.37546C3.47715 16 2.528 16 1.80554 15.6247C1.19673 15.3085 0.700329 14.8121 0.384078 14.2032C0.00878906 13.4808 0.00878906 12.5316 0.00878906 10.6333V5.36667Z"
          fill="#9146FF"
        />
        <path
          d="M10.9437 4.97956H10.1026V7.5029H10.9437V4.97956Z"
          fill="white"
        />
        <path
          d="M7.78949 4.97956H8.6306V7.5029H7.78949V4.97956Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.0559 2.6665L2.95312 4.76928V12.3393H5.47646V14.4421L7.57924 12.3393H9.26146L13.0465 8.55429V2.6665H5.0559ZM12.2054 8.13373L10.5231 9.81596H8.84091L7.36896 11.2879V9.81596H5.47646V3.50762H12.2054V8.13373Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_654_4054">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
