import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Checkbox, CheckboxGroup } from '@chakra-ui/react'
import _ from 'lodash'
import {
  FilesImg,
  MusicFillImg,
  SoundImg,
  TextLineImg,
  VoiceOverImg,
} from '../../common-items/EditorIcons'
import { checkAddItem } from '../../../../redux/actions/EditorActions'
import {
  AUDIO,
  MUSIC,
  TEXT,
  VIDEO,
  VOICEOVER,
} from '../../../../constants/Channels'
import * as SelectedItemActions from '../../../../redux/actions/SelectedItemActions'
import ProjectItemUtils from '../../../../utils/ProjectItemUtils'
import { EditorVideoIcon } from '../../icons/EditorVideoIcon'
import { EditorSoundIcon } from '../../icons/EditorSoundIcon'
import { EditorVoiceoverIcon } from '../../icons/EditorVoiceoverIcon'
import { EditorTextIcon } from '../../icons/EditorTextIcon'
import { EditorMusicIcon } from '../../icons/EditorMusicIcon'

class SelectItem extends Component {
  componentDidMount() {
    this.setState({
      checkedItem: this.props.addItem,
    })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.addItem !== this.props.addItem) {
      this.setState({
        checkedItem: this.props.addItem,
      })
    }
  }

  isChannelActive(channelCode) {
    const { checkedChannels } = this.props
    return _.get(checkedChannels, channelCode, false)
  }

  onCheckItem = (channelCode) => {
    const { timeSlider, project, selectedItems, setSelectedItemByChannel } =
      this.props
    let projectItem = null

    const currentTime = timeSlider.curSec

    switch (channelCode) {
      case VIDEO.code: {
        if (selectedItems.selectedScene) {
          break
        }

        const ratioProjectItems = _.get(
          project,
          `${VIDEO.projectPath}.${project.resolution}`,
          [],
        )
        projectItem = _.find(ratioProjectItems, (item) =>
          ProjectItemUtils.isTimeAroundItem(currentTime, item),
        )
        break
      }
      case TEXT.code: {
        if (selectedItems.selectedText) {
          break
        }

        const ratioProjectItems = _.get(
          project,
          `${TEXT.projectPath}.${project.resolution}`,
          [],
        )
        projectItem = _.find(ratioProjectItems, (item) =>
          ProjectItemUtils.isTimeAroundItem(currentTime, item),
        )
        break
      }
      case AUDIO.code: {
        if (selectedItems.selectedSound) {
          break
        }

        const ratioProjectItems = _.get(project, `${AUDIO.projectPath}`, [])
        projectItem = _.find(ratioProjectItems, (item) =>
          ProjectItemUtils.isTimeAroundItem(currentTime, item),
        )
        break
      }
      case VOICEOVER.code: {
        if (selectedItems.selectedVoiceover) {
          break
        }

        const ratioProjectItems = _.get(project, `${VOICEOVER.projectPath}`, [])
        projectItem = _.find(ratioProjectItems, (item) =>
          ProjectItemUtils.isTimeAroundItem(currentTime, item),
        )
        break
      }
      case MUSIC.code: {
        if (selectedItems.selectedMusic) {
          break
        }

        const ratioProjectItems = _.get(project, `${MUSIC.projectPath}`, [])
        projectItem = _.find(ratioProjectItems, (item) =>
          ProjectItemUtils.isTimeAroundItem(currentTime, item),
        )
        break
      }
    }

    setSelectedItemByChannel &&
      setSelectedItemByChannel(projectItem, channelCode)
  }

  render() {
    const { zoomValue } = this.props
    return (
      <div
        className="timeline-items-flex timeline-sel-items"
        style={{
          transform: `scale(${zoomValue})`,
          transformOrigin: 'right',
          marginTop:
            zoomValue * 24 + (1 - zoomValue) * 24 - 18 * (1 - zoomValue),
        }}>
        <div className="timeline-item">
          <div className="checkboxes">
            <Checkbox
              isChecked={this.isChannelActive(VIDEO.code)}
              onChange={() => this.onCheckItem(VIDEO.code)}
            />
          </div>

          <div className="timeline-inner">
            <div className="item-icon timeline-sel-video">
              <EditorVideoIcon onClick={() => this.onCheckItem(VIDEO.code)} />
            </div>
          </div>
        </div>
        <div className="timeline-item">
          <div className="checkbox-wrap">
            <Checkbox
              isChecked={this.isChannelActive(AUDIO.code)}
              onChange={() => this.onCheckItem(AUDIO.code)}
            />
          </div>
          <div className="timeline-inner">
            <div className="item-icon">
              <EditorSoundIcon onClick={() => this.onCheckItem(AUDIO.code)} />
            </div>
          </div>
        </div>
        <div className="timeline-item">
          <div className="checkboxes">
            <div className="checkbox-wrap">
              <Checkbox
                isChecked={this.isChannelActive(VOICEOVER.code)}
                onChange={() => this.onCheckItem(VOICEOVER.code)}
              />
            </div>
          </div>
          <div className="timeline-inner">
            <div className="item-icon">
              <EditorVoiceoverIcon
                onClick={() => this.onCheckItem(VOICEOVER.code)}
              />
            </div>
          </div>
        </div>
        <div className="timeline-item">
          <div className="checkbox-wrap">
            <Checkbox
              isChecked={this.isChannelActive(TEXT.code)}
              onChange={() => this.onCheckItem(TEXT.code)}
            />
          </div>

          <div className="timeline-inner" style={{ background: 'none' }}>
            <div className="item-icon">
              <EditorTextIcon onClick={() => this.onCheckItem(TEXT.code)} />
            </div>
          </div>
        </div>

        <div className="timeline-item">
          <div className="checkbox-wrap">
            <Checkbox
              value={true}
              isChecked={this.isChannelActive(MUSIC.code)}
              onChange={() => this.onCheckItem(MUSIC.code)}
            />
          </div>

          <div className="timeline-inner" style={{ background: 'none' }}>
            <div className="item-icon">
              <EditorMusicIcon onClick={() => this.onCheckItem(MUSIC.code)} />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  addItem: state.editor.addItem,
  selectedItems: state.selectedItems,
  checkedChannels: state.selectedItems.checkedChannels,
  project: state.editor.project,
  timeSlider: state.timeline.timeSlider,
})

const mapDispatchToProps = (dispatch) => ({
  resetAllSelected: (...args) =>
    dispatch(SelectedItemActions.resetAllSelected(...args)),
  setCheckedChannels: (...args) =>
    dispatch(SelectedItemActions.setCheckedChannels(...args)),
  setSelectedItemByChannel: (...args) =>
    dispatch(SelectedItemActions.setSelectedItemByChannel(...args)),
  checkAddItem: (...args) => dispatch(checkAddItem(...args)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SelectItem)
