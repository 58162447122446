import _ from 'lodash'
import {
  RELOAD_PREVIEW_TIMELINE,
  SET_PREVIEW_TIMELINE,
  SET_TIME_SLIDER,
} from '../actions/Types'
import * as MediaPlayerConstants from '../constants/MediaPlayerConstants'

const initialState = {
  previewTimeLine: null, // project preview object - animation preview object by greensock
  timeSlider: {
    // timeline slider bar data
    curSec: 0,
    value: 0,
    width: 0,
    show: false,
  },
}

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_TIME_SLIDER:
      return {
        ...state,
        timeSlider: {
          curSec: action.timeSlider.curSec,
          value: action.timeSlider.value,
          width: action.timeSlider.width,
          show: action.timeSlider.show,
        },
      }
    case SET_PREVIEW_TIMELINE: {
      const previewTimeLine = state?.previewTimeLine
      if (!action.previewTimeLine) {
        previewTimeLine.kill()
      }

      return {
        ...state,
        previewTimeLine: action.previewTimeLine,
      }
    }
    case RELOAD_PREVIEW_TIMELINE: {
      let newPreviewTimeline
      const previewTimeLine = state?.previewTimeLine
      const timeSlider = state?.timeSlider
      if (previewTimeLine) {
        previewTimeLine.kill()
      }

      if (action.tweenObjectBuilder) {
        newPreviewTimeline = action.tweenObjectBuilder
          .withTimeSlider(timeSlider)
          .build(action.project).previewTimeLine
      }

      return {
        ...state,
        previewTimeLine: newPreviewTimeline,
      }
    }

    case MediaPlayerConstants.PAUSE: {
      if (state.previewTimeLine) {
        state.previewTimeLine.pause()
      }
      return {
        ...state,
      }
    }
    default:
      return state
  }
}
