export const EditorDeleteIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_852_15834)">
        <mask
          id="mask0_852_15834"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="24"
          height="24">
          <rect width="24" height="24" fill="#C4C4C4" />
        </mask>
        <g mask="url(#mask0_852_15834)">
          <path
            d="M20.4167 4.66667H17.575C17.3622 3.63213 16.7993 2.70257 15.9811 2.03466C15.163 1.36675 14.1395 1.00133 13.0833 1L11.25 1C10.1938 1.00133 9.17038 1.36675 8.35219 2.03466C7.534 2.70257 6.97109 3.63213 6.75833 4.66667H3.91667C3.67355 4.66667 3.44039 4.76324 3.26849 4.93515C3.09658 5.10706 3 5.34022 3 5.58333C3 5.82645 3.09658 6.05961 3.26849 6.23151C3.44039 6.40342 3.67355 6.5 3.91667 6.5H4.83333V17.4167C4.83479 18.6318 5.31814 19.7967 6.17737 20.656C7.03659 21.5152 8.20154 21.9985 9.41667 22H14.9167C16.1318 21.9985 17.2967 21.5152 18.156 20.656C19.0152 19.7967 19.4985 18.6318 19.5 17.4167V6.5H20.4167C20.6598 6.5 20.8929 6.40342 21.0648 6.23151C21.2368 6.05961 21.3333 5.82645 21.3333 5.58333C21.3333 5.34022 21.2368 5.10706 21.0648 4.93515C20.8929 4.76324 20.6598 4.66667 20.4167 4.66667ZM11.25 2.83333H13.0833C13.6519 2.83403 14.2064 3.01059 14.6706 3.33882C15.1349 3.66705 15.4863 4.13087 15.6766 4.66667H8.65675C8.84703 4.13087 9.19841 3.66705 9.66269 3.33882C10.127 3.01059 10.6814 2.83403 11.25 2.83333ZM17.6667 17.4167C17.6667 18.146 17.3769 18.8455 16.8612 19.3612C16.3455 19.8769 15.646 20.1667 14.9167 20.1667H9.41667C8.68732 20.1667 7.98785 19.8769 7.47212 19.3612C6.9564 18.8455 6.66667 18.146 6.66667 17.4167V6.5H17.6667V17.4167Z"
            fill="#6B7280"
          />
          <path
            d="M10.3327 16.5013C10.5758 16.5013 10.809 16.4047 10.9809 16.2328C11.1528 16.0609 11.2493 15.8278 11.2493 15.5846V10.0846C11.2493 9.84152 11.1528 9.60836 10.9809 9.43645C10.809 9.26455 10.5758 9.16797 10.3327 9.16797C10.0896 9.16797 9.85641 9.26455 9.6845 9.43645C9.51259 9.60836 9.41602 9.84152 9.41602 10.0846V15.5846C9.41602 15.8278 9.51259 16.0609 9.6845 16.2328C9.85641 16.4047 10.0896 16.5013 10.3327 16.5013Z"
            fill="#6B7280"
          />
          <path
            d="M14.0007 16.5013C14.2438 16.5013 14.4769 16.4047 14.6488 16.2328C14.8207 16.0609 14.9173 15.8278 14.9173 15.5846V10.0846C14.9173 9.84152 14.8207 9.60836 14.6488 9.43645C14.4769 9.26455 14.2438 9.16797 14.0007 9.16797C13.7575 9.16797 13.5244 9.26455 13.3525 9.43645C13.1806 9.60836 13.084 9.84152 13.084 10.0846V15.5846C13.084 15.8278 13.1806 16.0609 13.3525 16.2328C13.5244 16.4047 13.7575 16.5013 14.0007 16.5013Z"
            fill="#6B7280"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_852_15834">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
