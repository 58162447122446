export const FacebookIcon = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 13.4167C0 8.67091 0 6.29803 0.938221 4.49188C1.72885 2.96985 2.96985 1.72885 4.49188 0.938221C6.29803 0 8.67091 0 13.4167 0H26.5833C31.3291 0 33.702 0 35.5081 0.938221C37.0301 1.72885 38.2711 2.96985 39.0618 4.49188C40 6.29803 40 8.67091 40 13.4167V26.5833C40 31.3291 40 33.702 39.0618 35.5081C38.2711 37.0301 37.0301 38.2711 35.5081 39.0618C33.702 40 31.3291 40 26.5833 40H13.4167C8.67091 40 6.29803 40 4.49188 39.0618C2.96985 38.2711 1.72885 37.0301 0.938221 35.5081C0 33.702 0 31.3291 0 26.5833V13.4167Z"
        fill="url(#paint0_linear_187_11442)"
      />
      <path
        d="M27.7781 25.5555L28.667 20H23.3337V16.1111C23.3337 14.5555 23.8892 13.3333 26.3337 13.3333H28.8892V8.22221C27.4448 7.99998 25.8892 7.77776 24.4448 7.77776C19.8892 7.77776 16.667 10.5555 16.667 15.5555V20H11.667V25.5555H16.667V40C18.8892 40 21.1114 40 23.3337 40V25.5555H27.7781Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_187_11442"
          x1="20"
          y1="38.8313"
          x2="20"
          y2="0"
          gradientUnits="userSpaceOnUse">
          <stop stop-color="#0062E0" />
          <stop offset="1" stop-color="#19AFFF" />
        </linearGradient>
      </defs>
    </svg>
  )
}
