import Tiktok from './../../assets/images/landing-page/tiktok.png'
import Twitter from './../../assets/images/landing-page/twitter.png'
import Youtube from './../../assets/images/landing-page/youtube.png'
import Facebook from './../../assets/images/landing-page/facebook.png'

const Features = () => {
  return (
    <section className="cerebriam-features">
      <div className="cerebriam-container">
        <h2>FEATURES</h2>
        <h3>Grow on more social channels with less effort </h3>
        <div className="cerebriam-ratios">
          <div className="cerebriam-ratio ratio-9-16 animate__animated animate__fadeInLeft">
            <div className="cerebriam-content">
              <div className="icon-container">
                <img src={Tiktok} alt="tiktok" />
              </div>
              <p className="ratio-code">9:16</p>
              <p className="ratio-title">Vertical</p>
            </div>
          </div>
          <div className="cerebriam-ratio ratio-4-5 animate__animated animate__fadeInLeft">
            <div className="cerebriam-content">
              <div className="icon-container">
                <img src={Facebook} alt="tiktok" />
              </div>
              <p className="ratio-code">4:5</p>
              <p className="ratio-title">Semi-Vertical</p>
            </div>
          </div>
          <div className="cerebriam-ratio ratio-1-1 animate__animated animate__fadeInRight">
            <div className="cerebriam-content">
              <div className="icon-container">
                <img src={Twitter} alt="twitter" />
              </div>
              <p className="ratio-code">1:1</p>
              <p className="ratio-title">Square</p>
            </div>
          </div>
          <div className="cerebriam-ratio ratio-16-9 animate__animated animate__fadeInRight">
            <div className="cerebriam-content">
              <div className="icon-container">
                <img src={Youtube} alt="tiktok" />
              </div>
              <p className="ratio-code">16:9</p>
              <p className="ratio-title">Horizontal</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Features
