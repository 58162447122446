import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import { PASSWORD_FORGET } from '../../constants/Routes'
import AuthFooter from './AuthFooter'
import Axios from '../../utils/Axios'

const PasswordResetPage = (props) => (
  <>
    <div className="container mx-auto pt-5">
      {/* <div className={'flex items-center justify-center main-area'}> */}
      <div className="w-full max-w-xs mx-auto pt-5">
        <div className={'text-2xl mb-3 font-bold text-center text-blue-500'}>
          Reset Password
        </div>

        <PasswordResetForm token={props.match.params.token} />
      </div>
    </div>
    <AuthFooter />
  </>
)

class PasswordResetForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      password: '',
      password2: '',
      error: null,
      success: null,
      loading: false,
    }
  }

  onSubmit = async () => {
    const { password, password2 } = this.state
    const { token } = this.props
    this.setState({ loading: true })

    const { data } = await Axios.post('/users/confirmReset', {
      password,
      password2,
      token,
    })

    if (!data.error) {
      this.setState({
        success: 'Password has been reset! Redirecting to login page...',
        error: null,
        password: '',
        password2: '',
        loading: false,
      })
      setTimeout(() => {
        window.location = '/'
      }, 1000)
    } else {
      this.setState({
        success: null,
        error: data.error,
        loading: false,
      })
    }
  }

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value })
  }

  isEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }

  render() {
    const { password, password2, error, success } = this.state

    const isInvalid =
      password !== password2 ||
      password.trim().length < 6 ||
      password2.trim().length < 6

    return (
      <form
        method="post"
        // onSubmit={this.onSubmit}
        className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        <div className="mb-6">
          <input
            type="password"
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            value={password}
            name="password"
            onChange={this.onChange}
            placeholder="Password"
          />
        </div>
        <div className="mb-6">
          <input
            type="password"
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            value={password2}
            name="password2"
            onChange={this.onChange}
            placeholder="Confirm password"
          />
        </div>

        <div className="flex items-center justify-between">
          <button
            className={
              (isInvalid || this.state.loading
                ? 'bg-gray-500 cursor-not-allowed '
                : 'bg-blue-500 hover:bg-blue-700 ') +
              'block w-full text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'
            }
            disabled={isInvalid}
            onClick={this.onSubmit}
            type="button">
            {this.state.loading ? 'Resetting Password...' : 'Reset Password'}
          </button>
        </div>

        {success && (
          <p className="text-green-400 text-xs italic mt-5">{success}</p>
        )}

        {error && <p className="text-red-500 text-xs italic mt-5">{error}</p>}
      </form>
    )
  }
}

const PasswordForgetLink = () => (
  <Link
    to={PASSWORD_FORGET}
    className="inline-block align-baseline font-bold text-sm text-blue-500 hover:text-blue-800">
    Forgot Password?
  </Link>
)

export default PasswordResetPage

export { PasswordResetForm, PasswordForgetLink }
