import React, { Component } from 'react'
import { connect } from 'react-redux'
import LogoText from './../../assets/images/landing-page/logo_with_text.png'
import SignOutButton from '../auth/SignOut'
/*
/**
 * Authenticated nav bar
 */
class InnerNavigation extends Component {
  constructor(props) {
    super(props)

    this.state = {
      collapseNav: true,
    }
  }

  render() {
    return (
      <nav
        className="flex p-4 top-nav innerNav"
        style={{
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          height: 68,
          paddingLeft: '10%',
          paddingRight: '10%',
          boxShadow:
            '0px 1px 2px 0px rgba(17, 24, 39, 0.06), 0px 1px 3px 0px rgba(17, 24, 39, 0.10)',
        }}>
        <img
          src={LogoText}
          style={{ height: 50 }}
          className="cerebriam-logo"
          alt="logo"
        />
        <SignOutButton />
      </nav>
    )
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
})

export default connect(mapStateToProps)(InnerNavigation)
