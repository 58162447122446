export const EditorVoiceoverIcon = (props) => {
  return (
    <svg
      {...props}
      width="16"
      height="20"
      viewBox="0 0 16 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.75 8.00008C6.75 8.1769 6.68415 8.34649 6.56694 8.47152C6.44973 8.59655 6.29076 8.66679 6.125 8.66679H3.04312C3.19446 9.95533 3.78166 11.1406 4.69441 11.9999C5.60715 12.8591 6.78263 13.3333 8 13.3333C9.21737 13.3333 10.3928 12.8591 11.3056 11.9999C12.2183 11.1406 12.8055 9.95533 12.9569 8.66679H9.875C9.70924 8.66679 9.55027 8.59655 9.43306 8.47152C9.31585 8.34649 9.25 8.1769 9.25 8.00008C9.25 7.82326 9.31585 7.65368 9.43306 7.52865C9.55027 7.40362 9.70924 7.33337 9.875 7.33337H13V5.99996H9.875C9.70924 5.99996 9.55027 5.92972 9.43306 5.80468C9.31585 5.67965 9.25 5.51007 9.25 5.33325C9.25 5.15643 9.31585 4.98685 9.43306 4.86182C9.55027 4.73678 9.70924 4.66654 9.875 4.66654H12.9569C12.8055 3.37801 12.2183 2.19276 11.3056 1.33347C10.3928 0.474186 9.21737 0 8 0C6.78263 0 5.60715 0.474186 4.69441 1.33347C3.78166 2.19276 3.19446 3.37801 3.04312 4.66654H6.125C6.29076 4.66654 6.44973 4.73678 6.56694 4.86182C6.68415 4.98685 6.75 5.15643 6.75 5.33325C6.75 5.51007 6.68415 5.67965 6.56694 5.80468C6.44973 5.92972 6.29076 5.99996 6.125 5.99996H3V7.33337H6.125C6.29076 7.33337 6.44973 7.40362 6.56694 7.52865C6.68415 7.65368 6.75 7.82326 6.75 8.00008Z"
        fill="#6B7280"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.4331 8.51631C14.5503 8.3991 14.7092 8.33325 14.875 8.33325C15.0408 8.33325 15.1997 8.3991 15.3169 8.51631C15.4342 8.63352 15.5 8.79249 15.5 8.95825C15.4978 10.781 14.7728 12.5284 13.484 13.8172C12.1951 15.1061 10.4477 15.8311 8.625 15.8333V19.1666C8.625 19.5118 8.34518 19.7916 8 19.7916C7.65482 19.7916 7.375 19.5118 7.375 19.1666V15.8333C5.5523 15.8311 3.80486 15.1061 2.51602 13.8172C1.22717 12.5284 0.50215 10.781 0.5 8.95825C0.5 8.79249 0.565848 8.63352 0.683058 8.51631C0.800269 8.3991 0.95924 8.33325 1.125 8.33325C1.29076 8.33325 1.44973 8.3991 1.56694 8.51631C1.68415 8.63352 1.75 8.79249 1.75 8.95825C1.75182 10.4495 2.34504 11.8792 3.39953 12.9337C4.45403 13.9882 5.88372 14.5814 7.375 14.5833H8.625C10.1163 14.5816 11.5461 13.9884 12.6006 12.9339C13.6552 11.8794 14.2483 10.4496 14.25 8.95825C14.25 8.79249 14.3158 8.63352 14.4331 8.51631Z"
        fill="#6B7280"
      />
    </svg>
  )
}
