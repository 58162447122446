export const RecordIcon = () => {
  return (
    <svg
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_283_517)">
        <path
          d="M20.0127 16.5471C20.0127 18.7561 18.2217 20.5471 16.0127 20.5471C13.8037 20.5471 12.0127 18.7561 12.0127 16.5471C12.0127 14.3381 13.8037 12.5471 16.0127 12.5471C18.2217 12.5471 20.0127 14.3381 20.0127 16.5471Z"
          fill="#4B5563"
        />
        <path
          d="M21.0127 16.5471C21.0127 19.3084 18.7739 21.5471 16.0127 21.5471C13.2514 21.5471 11.0127 19.3084 11.0127 16.5471C11.0127 13.7859 13.2514 11.5471 16.0127 11.5471C18.7739 11.5471 21.0127 13.7859 21.0127 16.5471Z"
          fill="#4B5563"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.0127 24.5471C20.4306 24.5471 24.0127 20.965 24.0127 16.5471C24.0127 12.1292 20.4306 8.54712 16.0127 8.54712C11.5948 8.54712 8.0127 12.1292 8.0127 16.5471C8.0127 20.965 11.5948 24.5471 16.0127 24.5471ZM16.0127 27.5471C22.0874 27.5471 27.0127 22.6219 27.0127 16.5471C27.0127 10.4724 22.0874 5.54712 16.0127 5.54712C9.93794 5.54712 5.0127 10.4724 5.0127 16.5471C5.0127 22.6219 9.93794 27.5471 16.0127 27.5471Z"
          fill="#4B5563"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.512695 2.54712C0.512695 1.71869 1.18427 1.04712 2.0127 1.04712H6.0127C6.84112 1.04712 7.5127 1.71869 7.5127 2.54712C7.5127 3.37555 6.84112 4.04712 6.0127 4.04712H3.5127V6.54712C3.5127 7.37555 2.84112 8.04712 2.0127 8.04712C1.18427 8.04712 0.512695 7.37555 0.512695 6.54712V2.54712Z"
          fill="#4B5563"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M31.5127 30.5471C31.5127 31.3755 30.8411 32.0471 30.0127 32.0471L26.0127 32.0471C25.1843 32.0471 24.5127 31.3755 24.5127 30.5471C24.5127 29.7187 25.1843 29.0471 26.0127 29.0471L28.5127 29.0471L28.5127 26.5471C28.5127 25.7187 29.1843 25.0471 30.0127 25.0471C30.8411 25.0471 31.5127 25.7187 31.5127 26.5471L31.5127 30.5471Z"
          fill="#4B5563"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M30.0127 1.04712C30.8411 1.04712 31.5127 1.71869 31.5127 2.54712L31.5127 6.54712C31.5127 7.37555 30.8411 8.04712 30.0127 8.04712C29.1843 8.04712 28.5127 7.37555 28.5127 6.54712L28.5127 4.04712L26.0127 4.04712C25.1843 4.04712 24.5127 3.37555 24.5127 2.54712C24.5127 1.71869 25.1843 1.04712 26.0127 1.04712L30.0127 1.04712Z"
          fill="#4B5563"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.0127 32.0471C1.18427 32.0471 0.512695 31.3755 0.512696 30.5471L0.512696 26.5471C0.512696 25.7187 1.18427 25.0471 2.0127 25.0471C2.84112 25.0471 3.5127 25.7187 3.5127 26.5471L3.5127 29.0471L6.0127 29.0471C6.84112 29.0471 7.5127 29.7187 7.5127 30.5471C7.5127 31.3755 6.84112 32.0471 6.0127 32.0471L2.0127 32.0471Z"
          fill="#4B5563"
        />
      </g>
      <defs>
        <clipPath id="clip0_283_517">
          <rect
            width="32"
            height="32"
            fill="white"
            transform="translate(0.0126953 0.547119)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
