export const EditorMusicIcon = (props) => {
  return (
    <svg
      {...props}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_588_2235)">
        <path
          d="M18.1188 1.47931C17.7715 1.18968 17.3643 0.980624 16.9266 0.867168C16.4889 0.753712 16.0315 0.738681 15.5872 0.823155L8.52261 2.14777C7.64086 2.31302 6.84462 2.78128 6.27164 3.47156C5.69866 4.16183 5.385 5.03067 5.38492 5.92777V13.5047C4.91927 13.2278 4.38819 13.0802 3.84646 13.077C3.2379 13.077 2.64301 13.2575 2.13701 13.5956C1.63101 13.9337 1.23663 14.4142 1.00375 14.9764C0.770864 15.5387 0.709931 16.1573 0.828655 16.7542C0.947379 17.3511 1.24043 17.8993 1.67074 18.3296C2.10106 18.76 2.64931 19.053 3.24618 19.1717C3.84304 19.2904 4.46171 19.2295 5.02394 18.9966C5.58618 18.7637 6.06673 18.3694 6.40482 17.8634C6.74292 17.3574 6.92338 16.7625 6.92338 16.1539V9.13546C6.92335 8.77653 7.04883 8.42888 7.27809 8.1527C7.50735 7.87652 7.82596 7.6892 8.17876 7.62315L16.7811 6.00777C16.8927 5.98676 17.0075 5.99072 17.1174 6.01935C17.2273 6.04799 17.3294 6.10059 17.4166 6.17339C17.5037 6.24619 17.5737 6.33738 17.6214 6.44041C17.6691 6.54344 17.6934 6.65576 17.6926 6.76931V11.2001C17.2273 10.9221 16.6962 10.7734 16.1541 10.7693C15.5456 10.7693 14.9507 10.9498 14.4447 11.2879C13.9387 11.626 13.5443 12.1065 13.3114 12.6687C13.0786 13.231 13.0176 13.8496 13.1363 14.4465C13.2551 15.0434 13.5481 15.5916 13.9784 16.0219C14.4088 16.4523 14.957 16.7453 15.5539 16.864C16.1507 16.9828 16.7694 16.9218 17.3316 16.6889C17.8939 16.4561 18.3744 16.0617 18.7125 15.5557C19.0506 15.0497 19.2311 14.4548 19.2311 13.8462V3.84623C19.2316 3.39411 19.1321 2.94746 18.9399 2.53827C18.7476 2.12907 18.4672 1.76746 18.1188 1.47931Z"
          fill="#6B7280"
        />
      </g>
      <defs>
        <clipPath id="clip0_588_2235">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
