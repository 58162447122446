import { triggerGoogleAnalyticsEvent } from '../../utils/Helper'

export function updateTimeSlider(v) {
  this.setState({
    updateSlideV: v,
  })
}

export function toggleShowImport(flag, channelCode) {
  const { setPlaying } = this.props
  // setPlaying && setPlaying(false)
  this.setState({
    showImport: flag,
    channelCode,
  })
}

export function toggleShowTextPanel(flag) {
  this.setState({
    showTextPanel: flag,
  })
}

export function toggleShowReview(flag, authUser) {
  triggerGoogleAnalyticsEvent('toggle_preview_modal', {
    value: flag,
    userId: authUser?.id,
  })
  this.setState({
    showReview: flag,
  })
}
