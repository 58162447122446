import './_subscribe.scss'
import AuthFooter from '../../components/auth/AuthFooter'
import * as SubscriptionActions from '../../redux/actions/SubscriptionActions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

import { useState, useEffect } from 'react'
import Header from './Header'
import ChooseLicense from './ChooseLicense'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { PaymentConstants } from '../../constants/Payments'
import { useDispatch, useSelector } from 'react-redux'
import {
  IsReferredUserNeverPaid,
  SubscriptionManager,
  getLocation,
  getRegion,
} from '../../utils/Helper'
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK)

const Subscribe = () => {
  const dispatch = useDispatch()
  const {
    subscriptions: {
      user: mySubscription,
      products,
      loading: userSubscriptionLoading,
    },
    auth,
  } = useSelector((state) => state)
  const [activeProduct, setActiveProduct] = useState(2)
  const [active] = useState(1)
  const [regionProductPro, setRegionProductPro] = useState()
  const [regionProductStudio, setRegionProductStudio] = useState()
  const [paymentTypeText] = useState({
    cycleOne: '',
    cycleTwo: '',
    cycleThree: '',
    productOne: 'Cerebriam Studio',
    productTwo: 'Cerebriam Pro',
    productThree: 'Pro Enterprise',
  })
  const {
    auth: { user: authUser },
  } = useSelector((state) => state)

  const changeActiveProduct = (value) => {
    setActiveProduct(value)
  }

  // const getConfig = async () => {
  //   const configData = await fetch(
  //     process.env.REACT_APP_API_URL + PaymentConstants.configURL,
  //   ).then((r) => r.json())
  //   setConfig(configData)
  // }

  const loadUserSubscriptions = () => {
    dispatch(SubscriptionActions.currentUserSubscription())
  }

  const loadSubscriptionProducts = () => {
    dispatch(SubscriptionActions.getAllSubscriptionProducts())
  }

  const getUserLocation = async () => {
    const location = await getLocation()
    const region = await getRegion(location)
    const getRegionProductpro = products.find(
      (product) =>
        product.location === region &&
        product.type === PaymentConstants.subscriptionProduct.pro,
    )
    const getRegionProductStudio = products.find(
      (product) =>
        product.location === region &&
        product.type === PaymentConstants.subscriptionProduct.studio,
    )
    setRegionProductPro(getRegionProductpro)
    setRegionProductStudio(getRegionProductStudio)
  }

  useEffect(() => {
    loadSubscriptionProducts()
    loadUserSubscriptions()
  }, [])

  useEffect(() => {
    getUserLocation()
  }, [products])

  const subscriptionType = products?.find(
    (x) => x._id === mySubscription?.product_id,
  )?.type

  const referredByAffiliate = IsReferredUserNeverPaid(auth, mySubscription)

  return (
    <Elements stripe={stripePromise}>
      <div className={'cerebriam-subscribe'}>
        <div className="masthead py-12 navHeader">
          <Header />
          {userSubscriptionLoading && (
            <div style={{ textAlign: 'center' }}>
              <p>
                <span style={{ fontSize: 12 }}>Loading subscription </span>{' '}
                <FontAwesomeIcon className="spinner" icon={faSpinner} />
              </p>
            </div>
          )}
          {!userSubscriptionLoading && mySubscription?.active && (
            <div style={{ textAlign: 'center' }}>
              <h1>
                You are subscribed to{' '}
                <strong>
                  {
                    products.find((x) => x._id === mySubscription.product_id)
                      ?.name
                  }
                </strong>
              </h1>
            </div>
          )}
          {!userSubscriptionLoading &&
            (!mySubscription ||
              referredByAffiliate ||
              !mySubscription?.active ||
              SubscriptionManager(authUser, mySubscription, products)
                .isTrialPlan ||
              subscriptionType ===
                PaymentConstants.subscriptionProduct.studio) && (
              <ChooseLicense
                activeProduct={activeProduct}
                changeActiveProduct={changeActiveProduct}
                paymentTypeText={paymentTypeText}
                show={active === 1}
                subscriptionType={subscriptionType}
                referredByAffiliate={referredByAffiliate}
                pageOn="subscribe"
                regionProductPro={regionProductPro}
                regionProductStudio={regionProductStudio}
              />
            )}
          {/* <NavigationButtons active={active} changeActive={changeActive} /> */}
        </div>
        <div className="" style={{ margin: '0 auto' }}>
          <AuthFooter />
        </div>
      </div>
    </Elements>
  )
}

export default Subscribe
