import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import AuthReducer from './AuthReducer'
import SubscriptionReducer from './SubscriptionReducer'
import MainReducer from './MainReducer'
import RecorderReducer from './RecorderReducer'
import EditReducer from './EditReducer'
import RemoteWoocommerceReducer from './RemoteWoocommerceReducer'
import WoocommerceSignUp from './WoocommerceSignUp'

import AdminListUsers from './users/ListUsers'
import AdminShowUser from './users/ShowUser'

import UserProfileReducer from './UserProfileReducer'
import ProjectsReducer from './ProjectsReducer'

import MainContainerReducer from './MainContainerReducer'
import VoiceoverReducer from './VoiceoverReducer'
import ProjectEditReducer from './ProjectEditReducer'
import VideoCropReducer from './VideoCropReducer'
import VideoPublishReducer from './VideoPublishReducer'
import MediaPlayerReducer from './MediaPlayerReducer'
import FaceTrackReducer from './FaceTrackReducer'
import SelectedItemReducer from './SelectedItemReducer'
import TimelineReducer from './TimelineReducer'

const persistConfig = {
  key: 'data',
  storage,
}
export default combineReducers({
  auth: persistReducer(persistConfig, AuthReducer),
  subscriptions: SubscriptionReducer,
  main: MainReducer,
  recorder: RecorderReducer,
  editor: persistReducer(persistConfig, EditReducer),
  timeline: TimelineReducer,
  remoteWoocommerceSignUp: RemoteWoocommerceReducer,
  woocommerceSignUp: WoocommerceSignUp,

  // admin reducers
  adminListUsers: AdminListUsers,
  adminShowUser: AdminShowUser,

  // user profile reducer
  userProfile: UserProfileReducer,
  projects: ProjectsReducer,

  // new reducers
  mainContainer: MainContainerReducer,
  videoCrop: VideoCropReducer,
  videoPublish: VideoPublishReducer,
  voiceover: VoiceoverReducer,
  projectEdit: ProjectEditReducer,
  mediaPlayer: MediaPlayerReducer,
  faceTrack: FaceTrackReducer,
  selectedItems: SelectedItemReducer,
})
