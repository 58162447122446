import * as Constants from '../constants/VideoCropConstants'
import * as SelectedItemActions from '../actions/SelectedItemActions'
import { triggerGoogleAnalyticsEvent } from '../../utils/Helper'

export const setCurrentLayout = (aspectRatioType) => {
  return (dispatch, getState) => {
    triggerGoogleAnalyticsEvent('set_current_layout', {
      aspectRatioType,
      userId: getState().auth.user?.id,
    })
    dispatch(SelectedItemActions.resetAllSelected())
    dispatch({
      type: Constants.SET_CURRENT_LAYOUT,
      currentLayout: aspectRatioType,
    })
  }
}

export const setScaleValue = (scaleValue) => {
  return (dispatch) => {
    dispatch({
      type: Constants.SET_SCALE_VALUE,
      scaleValue: scaleValue,
    })
  }
}
export const setLibraryAsset = (libraryAsset) => {
  return (dispatch) => {
    dispatch({
      type: Constants.SET_LIBRARY_ASSET,
      libraryAsset: libraryAsset,
    })
  }
}
