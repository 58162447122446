import { useState } from 'react'
import { SceneLeftIcon } from '../cropper/icons/SceneLeftIcon'
import { SceneRightIcon } from '../cropper/icons/SceneRightIcon'
import { ConvertDuration } from '../../utils/TimeManage'
import { useSelector } from 'react-redux'

const CropperScenes = ({
  cropperVideos = [],
  setActiveSegmentedScene,
  toggleFullSegmentationsTab,
  openFullSegmentationsTab,
  toggleApplySelectRatiosModal,
}) => {
  const arr = cropperVideos
  const [items, setItems] = useState(cropperVideos.slice(0, 7))
  const [currPos, setCurrPos] = useState(items.length - 1)
  const [activeVideoName, setActiveVideoName] = useState(items[0].fileName)
  const { recorder } = useSelector((state) => state)

  const selectScene = (fileName) => {
    setActiveVideoName(fileName)
    setActiveSegmentedScene(fileName)
  }

  const forward = () => {
    if (currPos < arr.length - 1) {
      let newPos = currPos + 1
      const currItems = items
      currItems.push(arr[newPos])
      currItems.shift()

      setCurrPos(newPos)
      setItems(currItems)
    }
  }
  const previous = () => {
    if (currPos > 6) {
      let newPos = currPos - 1
      const currItems = items
      currItems.pop()
      currItems.unshift(arr[currPos - 7])

      setCurrPos(newPos)
      setItems(currItems)
    }
  }

  const goToScene = (videoName) => {
    const hasSelectedRatio = recorder.saveLayout.some((x) => x === true)
    hasSelectedRatio && toggleApplySelectRatiosModal()
    selectScene(videoName)
  }

  return (
    <div className={`cropper-scenes${openFullSegmentationsTab ? ' full' : ''}`}>
      <div className="cropper-scenes-content">
        {!openFullSegmentationsTab && (
          <div
            className={`cropper-scenes-content__left-control${
              currPos <= 6 ? ' disabled' : ''
            }`}
            onClick={previous}>
            <span>
              <SceneLeftIcon />
            </span>
          </div>
        )}
        <div className="cropper-scenes-content__scenes">
          {(openFullSegmentationsTab ? arr : items).map((video, i) => {
            return (
              <div
                key={i}
                className={`scene${
                  video.fileName === activeVideoName ? ' active' : ''
                }`}
                onClick={() => goToScene(video.fileName)}>
                <div className={'scene-img'}>
                  <img src={video.thumbnail} alt="scene-screenshot" />
                </div>
                <span className="scene-duration">
                  {ConvertDuration(video.duration * 1000)}
                </span>
              </div>
            )
          })}
        </div>
        {!openFullSegmentationsTab && (
          <div
            className={`cropper-scenes-content__right-control${
              currPos >= arr.length - 1 ? ' disabled' : ''
            }`}
            onClick={forward}>
            <span>
              <SceneRightIcon />
            </span>
          </div>
        )}
      </div>
      <div
        className="cropper-scenes-expander"
        onClick={toggleFullSegmentationsTab}>
        <SceneLeftIcon />
      </div>
    </div>
  )
}

export default CropperScenes
