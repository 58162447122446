import React, { Component } from 'react'
import _ from 'lodash'
import CHANNELS, { AUDIO } from '../../../../constants/Channels'
import { triggerGoogleAnalyticsEvent } from '../../../../utils/Helper'
import { connect } from 'react-redux'

class AddScene extends Component {
  handleClickChannel = (channelCode) => {
    triggerGoogleAnalyticsEvent('add_scene_modal', {
      channelCode,
      userId: this.props.authUser?.id,
    })
    const { toggleShowImport } = this.props
    toggleShowImport && toggleShowImport(channelCode)
  }

  render() {
    const { zoomValue } = this.props
    return (
      <div
        className="add-btn"
        style={{
          backgroundImage: 'url(/plus-sign.png)',
          backgroundPosition: 'left',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
          maxHeight: 'calc(100% - 7px)',
          marginTop:
            zoomValue * 31 + (1 - zoomValue) * 31 - 8 * (1 - zoomValue),

          transform: `scale(${zoomValue})`,
          transformOrigin: 'left',
          width: `${100 / zoomValue}%`,
        }}>
        {_.map(CHANNELS, (channel, index) => {
          return (
            <React.Fragment key={`scene-${index}`}>
              <div
                className="item-text flex justify-right"
                style={{
                  height: channel.height,
                  backgroundColor: channel.backgroundColor,
                  mixBlendMode: 'darken',
                  textAlign: 'right',
                  paddingRight: '10px',
                }}>
                <div
                  className="flex-grow"
                  onClick={() =>
                    channel.code !== AUDIO.code &&
                    this.handleClickChannel(channel.code)
                  }>
                  &nbsp;
                </div>
                <div>{channel.name}</div>
              </div>
              {index !== CHANNELS.length - 1 && (
                <div
                  style={{
                    height: '7px',
                    backgroundColor: 'white',
                  }}>
                  &nbsp;
                </div>
              )}
            </React.Fragment>
          )
        })}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  authUser: state.auth.user,
})

export default connect(mapStateToProps)(AddScene)
