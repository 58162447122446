import React, { Component } from 'react'
import { connect } from 'react-redux'
import { setProjectFeature } from '../../redux/actions/RecorderActions'
import {
  DemoImg,
  InterviewImg,
  PresentationImg,
  VlogImg,
} from '../../components/home/HomeIcons'
import { triggerGoogleAnalyticsEvent } from '../../utils/Helper'

/**
 * features frame of home page
 */
class FeatureFrame extends Component {
  constructor(props) {
    super(props)

    this.state = {
      featureList: ['Interview', 'Presentation', 'Vlog', 'Demo'],
      featureIcons: [InterviewImg, PresentationImg, VlogImg, DemoImg],
    }
  }

  /**
   * click feature
   * @param feature
   */
  onClickList = (feature) => {
    triggerGoogleAnalyticsEvent('project_feature_selected', {
      value: feature,
      userId: this.props.authUser?.id,
    })
    this.props.setProjectFeature(feature)
    this.props.onCardSelect(2)
  }

  render() {
    const { featureList, featureIcons } = this.state

    return (
      <div className={'w-full flex justify-center h-full'}>
        <div className={'w-full overflow-auto'}>
          <div
            className={
              'xs:text-3xl sm:text-4xl md:text-5xl font-bold text-center'
            }>
            Sounds interesting
          </div>

          <p className="xs:text-lg sm:text-xl md:text-2xl mt-2 text-center">
            What's featured in your video:
          </p>

          <ul className="w-full xs:text-lg sm:text-xl md:text-2xl xs:mt-4 md:mt-5 lg:mt-10 grid xs:grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-10 xs:gap-2 sm:gap-4 md:gap-6 xl:gap-10 px-8 xxl:px-20">
            {Array.apply(null, featureList).map((x, i) => {
              return (
                <li
                  className={
                    'cursor-pointer mb-3 text-center h-64 xs:h-32 sm:h-32 md:h-64 xs:px-4 xl:px-8' +
                    (this.props.feature === x ? ' selected-category' : '')
                  }
                  key={i}
                  onClick={() => this.onClickList(x)}>
                  <div className="hover:bg-gray-300 hover:text-green-500 h-full rounded bg-purple-100">
                    <div className="w-full h-48 xs:h-24 sm:h-24 md:h-48 flex justify-center items-center">
                      <img src={featureIcons[i]} alt="" />
                    </div>

                    <div>{x}</div>
                  </div>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    )
  }
}

/**
 * Map video state props
 * @param state
 * @returns {{feature: "Best Practices" | "Stylistic Issues" | "Variables" | "Possible Errors" | string | *}}
 */
const mapStateToProps = (state) => ({
  authUser: state.auth.user,
  feature: state.recorder.feature,
})

export default connect(mapStateToProps, { setProjectFeature })(FeatureFrame)
