/**
 * Get timeline ruler second format
 * @param s
 * @returns {string}
 * @constructor
 */
export const GetRulerSecond = (s) => {
  if (s === 0) {
    return '0 sec'
  }

  if (s % 5 === 0) {
    if (s < 60) {
      return `0:${s >= 10 ? '' : '0'}${s}`
    } else {
      if (s === 60) {
        return '1 min'
      } else {
        const d = new Date(s * 1000)
        const secs = d.getUTCSeconds()

        if (d.getUTCHours() > 0) {
          if (s === 3600) {
            return '1 hr'
          }

          return `${d.getUTCHours()}:${d.getUTCMinutes()}:${
            secs >= 10 ? '' : '0'
          }${secs}`
        } else {
          return `${d.getUTCMinutes()}:${secs >= 10 ? '' : '0'}${secs}`
        }
      }
    }
  }
}

/**
 * convert duration format to shows
 * @param microSec
 * @returns {string}
 * @constructor
 */
export const ConvertDuration = (microSec) => {
  if (microSec === 0) {
    return '00:00'
  }

  const sec = Math.round(microSec / 1000)

  if (sec < 60) {
    return `00:${sec >= 10 ? '' : '0'}${sec}`
  } else {
    if (sec === 60) {
      return '01:00'
    } else {
      const d = new Date(microSec)
      const hrs = d.getUTCHours()
      const minutes = d.getUTCMinutes()
      const secs = d.getUTCSeconds()
      if (d.getUTCHours() > 0) {
        if (sec === 3600) {
          return '01:00:00'
        }

        return `${hrs >= 10 ? '' : '0'}${hrs}:${
          minutes >= 10 ? '' : '0'
        }${minutes}:${secs >= 10 ? '' : '0'}${secs}`
      } else {
        return `${minutes >= 10 ? '' : '0'}${minutes}:${
          secs >= 10 ? '' : '0'
        }${secs}`
      }
    }
  }
}
