import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

export default function PromptSaveModal({
  currentProject,
  selectedProject,
  onClose,
  saveAction,
  discardAction,
  mergeAction,
  loading,
}) {
  const windowClick = ({ target }) => {
    if (target.classList.contains('sub-modal-wrap')) {
      onClose()
    }
  }

  return (
    <div style={styles.fixedWrapper}>
      <div
        onClick={windowClick}
        className="sub-modal-wrap"
        style={styles.fullscreenWrapper}>
        {currentProject === selectedProject ? (
          <div style={styles.innerModal}>
            <div style={styles.headerText}>
              {currentProject} is already open in editor!
            </div>
          </div>
        ) : (
          <div style={styles.innerModal}>
            <div style={styles.headerText}>
              {loading && (
                <FontAwesomeIcon
                  icon={faSpinner}
                  className="mr-4 ml-4 fa-spin"
                />
              )}
              You have a project open in your editor:
            </div>
            <div
              onClick={saveAction}
              style={{ ...styles.buttonItem, borderBottom: '1px solid #999' }}>
              Save changes made to <i>{currentProject}</i>
            </div>
            <div
              onClick={discardAction}
              style={{ ...styles.buttonItem, borderBottom: '1px solid #999' }}>
              Exit <i>{currentProject}</i> without saving changes
            </div>
            <div onClick={mergeAction} style={styles.buttonItem}>
              Merge <i>{currentProject}</i> with <i>{selectedProject}</i>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

const styles = {
  fixedWrapper: {
    height: '0px',
    width: '100%',
    position: 'relative',
  },
  fullscreenWrapper: {
    backgroundColor: 'rgba(0,0,0,0.3)',
    height: '100vh',
    width: '101%',
    position: 'relative',
    zIndex: 1401,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  innerModal: {
    backgroundColor: '#fff',
    width: '500px',
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    textAlign: 'center',
  },
  headerText: {
    padding: '20px 0',
    fontWeight: 800,
    fontSize: '20px',
  },
  buttonItem: {
    padding: '15px 0',
    justifySelf: 'flex-end',
    cursor: 'pointer',
  },
}
