import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
// import { Footer } from '../main/footer/Footer'
import LandingPageSections from '../../components/landing-page'
import { Footer } from '../../components/footer/Footer'
import * as SubscriptionActions from '../../redux/actions/SubscriptionActions'

class LandingPageNew extends Component {
  componentDidMount() {
    const isAuthenticated = this.props.auth?.isAuthenticated
    if (isAuthenticated) {
      this.props.loadUserSubscriptions()
      this.props.loadSubscriptionProducts()
      this.props.getProductFeatures()
    }
  }

  render() {
    return (
      <>
        <LandingPageSections />
        <Footer />
      </>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  loadUserSubscriptions: () =>
    dispatch(SubscriptionActions.currentUserSubscription()),
  loadSubscriptionProducts: () =>
    dispatch(SubscriptionActions.getAllSubscriptionProducts()),
  getProductFeatures: () => dispatch(SubscriptionActions.getProductFeatures()),
})

// export default LandingPageNew
const mapStateToProps = (state) => ({
  auth: state.auth,
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(LandingPageNew))
