import _ from 'lodash'
/* eslint-disable no-undef */
import { TimelineMax } from 'gsap'
import * as TimelineSet from '../../utils/staging-preview/TimelineSet'
import { AUDIO } from '../../constants/Channels'
const DEFAULT_ZEROWIDTH = 20
const ONE_SECOND = 1000

export const convertPixelToSeconds = (leftPixels) => {
  return _.round((leftPixels / DEFAULT_ZEROWIDTH) * ONE_SECOND, 2)
}

export const convertMillisecondsToPixels = (milliseconds) => {
  return _.round(DEFAULT_ZEROWIDTH * (milliseconds / ONE_SECOND), 1)
}

export const convertMillisecondsToSeconds = (milliseconds) => {
  return _.round(milliseconds / ONE_SECOND, 3)
}

export const convertMillisecondsToSliderPosition = (
  milliseconds,
  project,
  sliderRate,
  floatRate,
) => {
  return (
    Math.round((milliseconds / project.duration) * sliderRate * floatRate) /
    floatRate
  )
}

export const createPreviewTimeline = (
  project,
  timeSlider,
  scenePlaying,
  onUpdate,
  onComplete,
  tweenWrapper,
  htmlElements,
) => {
  const previewTimeLine = new TimelineMax({
    id: tweenWrapper.id,
    onUpdate,
    onComplete,
  })

  const tweenObject = TimelineSet.SlideRender(
    {
      previewTimeLine,
      slides: project ? project.scenes[project.resolution] : [],
      texts: project ? project.texts[project.resolution] : [],
      updateSlider: onUpdate,
      complete: onComplete,
      t_slider: timeSlider,
    },
    scenePlaying,
    project,
    tweenWrapper,
    htmlElements,
  )
  return tweenObject
}

export const pauseAllHtmlAssets = (assets) => {
  _.map(assets, (channelAssets) => {
    if (!_.isEmpty(channelAssets)) {
      _.map(channelAssets, (asset) => {
        asset.pause()
      })
    }
  })
}

export const getNewStartTime = (startTime, clipLeftSecondsPosition) => {
  const initialStartTime = Math.round(startTime + clipLeftSecondsPosition)
  return initialStartTime < 0 ? 0 : initialStartTime
}

export default {
  convertPixelToSeconds,
  convertMillisecondsToPixels,
  convertMillisecondsToSliderPosition,
  convertMillisecondsToSeconds,
}
