export const EditorPlayIcon = (props) => {
  return (
    <svg
      {...props}
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle opacity="0.5" cx="17" cy="17" r="17" fill="#E5E7EB" />
      <path
        d="M24.592 15.9847L13.1696 8.36885C13.0397 8.28277 12.8955 8.24228 12.7507 8.25121C12.6058 8.26014 12.4651 8.3182 12.3417 8.4199C12.2184 8.52159 12.1165 8.66356 12.0458 8.83237C11.975 9.00119 11.9378 9.19127 11.9375 9.38464V24.6156C11.9377 24.809 11.975 24.9991 12.0457 25.168C12.1164 25.3368 12.2183 25.4788 12.3417 25.5804C12.4651 25.6821 12.6059 25.74 12.7507 25.7488C12.8956 25.7576 13.0398 25.717 13.1696 25.6307L24.592 18.0156C24.7334 17.9212 24.8523 17.7763 24.9354 17.597C25.0185 17.4176 25.0625 17.211 25.0625 17.0001C25.0625 16.7893 25.0185 16.5827 24.9354 16.4033C24.8523 16.224 24.7334 16.079 24.592 15.9847Z"
        fill="#6B7280"
      />
    </svg>
  )
}
