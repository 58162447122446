import React, { Component } from 'react'
import AspectRatioImage from '../../../assets/images/editor/icons/videos_icon_teal.png'
import { triggerGoogleAnalyticsEvent } from '../../../utils/Helper'
import { connect } from 'react-redux'

class AspectRatios extends Component {
  handleToggleDisplayPanel = () => {
    const { toggleSetRatioDropdown, isPanelVisible } = this.props
    const newValue = !isPanelVisible
    triggerGoogleAnalyticsEvent('toggle_aspect_ratio_dropdown', {
      value: newValue,
      userId: this.props.authUser?.id,
    })
    toggleSetRatioDropdown && toggleSetRatioDropdown(newValue)
    this.setState({ isPanelVisible: newValue })
  }

  render() {
    return (
      <div
        className="aspect-ratios-area cursor-pointer"
        onClick={this.handleToggleDisplayPanel}>
        {/* <div
          className="absolute landscape-btn outline-none"
          onClick={this.handleToggleDisplayPanel}
        />
        <div
          className="absolute square-btn outline-none"
          onClick={this.handleToggleDisplayPanel}
        />
        <div
          className="absolute vertical-btn outline-none"
          onClick={this.handleToggleDisplayPanel}
        /> */}
        <img
          style={{ width: 112 }}
          src={AspectRatioImage}
          alt="aspect-ratio"
          onClick={this.toggleSetRatioDropdown}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  authUser: state.auth.user,
})

export default connect(mapStateToProps)(AspectRatios)
