export const VideoIcon = () => {
  return (
    <svg
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_283_1500)">
        <path
          d="M19.7767 16.1578C19.8463 16.1958 19.9041 16.2523 19.9438 16.3209C19.9836 16.3896 20.0037 16.4678 20.002 16.5471C20.0061 16.6148 19.9936 16.6824 19.9657 16.7442C19.9379 16.8059 19.8954 16.86 19.842 16.9018L12.6794 20.4858C12.6111 20.5235 12.5343 20.5428 12.4564 20.5418C12.3784 20.5408 12.3021 20.5196 12.2348 20.4802C12.1676 20.4408 12.1118 20.3846 12.0728 20.3171C12.0339 20.2495 12.0131 20.1731 12.0127 20.0951V12.9991C12.0112 12.92 12.0312 12.842 12.0707 12.7734C12.1102 12.7048 12.1675 12.6482 12.2367 12.6098C12.3004 12.5731 12.3725 12.5538 12.446 12.5538C12.5535 12.557 12.6583 12.5882 12.75 12.6445L19.7767 16.1578ZM32.0127 7.21379V25.8805C32.0106 27.6479 31.3075 29.3424 30.0577 30.5922C28.808 31.8419 27.1135 32.545 25.346 32.5471H6.67936C4.9119 32.545 3.21744 31.8419 1.96766 30.5922C0.717871 29.3424 0.0148125 27.6479 0.0126953 25.8805L0.0126953 7.21379C0.0148125 5.44633 0.717871 3.75186 1.96766 2.50208C3.21744 1.2523 4.9119 0.549236 6.67936 0.547119L25.346 0.547119C27.1135 0.549236 28.808 1.2523 30.0577 2.50208C31.3075 3.75186 32.0106 5.44633 32.0127 7.21379ZM22.6687 16.5471C22.6692 15.9853 22.5174 15.4339 22.2296 14.9515C21.9418 14.469 21.5286 14.0736 21.034 13.8071L13.9967 10.2938C13.5227 10.0218 12.9855 9.87932 12.439 9.88055C11.8926 9.88177 11.356 10.0267 10.8833 10.3008C10.4105 10.5748 10.0181 10.9684 9.74547 11.442C9.47285 11.9157 9.32958 12.4526 9.33003 12.9991V20.0951C9.32683 20.6415 9.46884 21.1789 9.74154 21.6524C10.0142 22.1259 10.4078 22.5184 10.882 22.7898C11.362 23.07 11.9075 23.2186 12.4634 23.2205C12.9776 23.2228 13.4834 23.0899 13.93 22.8351L21.098 19.2525C21.5773 18.9826 21.9757 18.5894 22.2518 18.1137C22.528 17.638 22.6719 17.0971 22.6687 16.5471Z"
          fill="#4B5563"
        />
      </g>
      <defs>
        <clipPath id="clip0_283_1500">
          <rect
            width="32"
            height="32"
            fill="white"
            transform="translate(0.0126953 0.547119)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
