import React, { Component } from 'react'
import { connect } from 'react-redux'
import { setMediaResolution } from '../../redux/actions/RecorderActions'
import { SceneLeftIcon } from '../cropper/icons/SceneLeftIcon'

class QualitySubBar extends Component {
  constructor(props) {
    super(props)

    this.state = {
      resolutions: [
        {
          ind: 0,
          value: '1080p 30fps',
        },
        {
          ind: 1,
          value: '720p 30fps',
        },
        {
          ind: 2,
          value: '480p 30fps',
        },
      ],
      quality: {
        1080: 0,
        720: 1,
        480: 2,
      },
      sizes: [
        { width: 1920, height: 1080 },
        { width: 1280, height: 720 },
        { width: 640, height: 480 },
      ],
      selResolution: 1080,
    }
  }

  componentDidMount() {
    this.setState({
      selResolution: this.props.resolution,
    })
  }

  onChangeQuality = (ind) => {
    const qualityAry = [1080, 720, 480]

    this.props.setMediaResolution(qualityAry[ind])

    this.props.toggleQualityOpen()

    this.props.toggleSnapGrid(false)

    setTimeout(() => {
      this.props.toggleSnapGrid(true)
    }, 500)
  }

  render() {
    const { resolutions, quality, selResolution } = this.state

    return (
      <div className="quality-option absolute top-0 left-0 z-150 h-full">
        <div className="sub-top-item text-lg px-6 pt-5 pb-4 flex items-center border-b border-teal-500 bg-gray-100">
          <div
            className="item-icon cursor-pointer"
            onClick={this.props.toggleQualityOpen}>
            <SceneLeftIcon />
          </div>
          <div>Video Quality</div>
        </div>
        <ul className="px-5">
          {resolutions.map((q, i) => {
            return (
              <li
                className={
                  'hover:bg-teal-500 py-3 px-1 flex items-center border-b border-teal-500 cursor-pointer' +
                  (quality[selResolution] === q.ind ? ' active' : '')
                }
                onClick={() => {
                  this.onChangeQuality(q.ind)
                }}
                key={i}>
                <div className="item-icon" />
                <div>{q.value}</div>
              </li>
            )
          })}
        </ul>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  media_layout: state.recorder.media_layout,
  resolution: state.recorder.resolution,
})

export default connect(mapStateToProps, {
  setMediaResolution,
})(QualitySubBar)
