import React, { Component } from 'react'

class Timer extends Component {
  constructor(props) {
    super(props)
    this.timerRef = React.createRef()

    this.state = {
      tH: 0,
      tM: 0,
      tS: 0,
    }

    this.mounted = false
  }

  beginTimer() {
    let n = this.state.tS
    let m = this.state.tM
    let h = this.state.tH

    this.timerRef.current = setInterval(() => {
      n++

      if (n > 59) {
        m++
        n = 0
      }

      if (m > 59) {
        h++
        m = 0
      }

      if (this.mounted) {
        this.setState({
          tS: n,
          tM: m,
          tH: h,
        })
      }
    }, 1000)
  }

  componentDidMount() {
    this.mounted = true

    this.beginTimer()
  }

  componentWillUnmount() {
    if (this.mounted && this.showTimer) {
      clearInterval(this.showTimer)
    }

    this.mounted = false
  }

  componentDidUpdate(prevProps) {
    if (
      !prevProps.pauseRecorder &&
      this.props.pauseRecorder &&
      this.timerRef.current
    ) {
      clearInterval(this.timerRef.current)
    } else if (prevProps.pauseRecorder && !this.props.pauseRecorder) {
      this.beginTimer()
    }
  }

  render() {
    const { tH, tM, tS } = this.state
    return (
      <div className="flex justify-center w-full text-xl mt-5 items-center">
        <div className="w-5 h-5 bg-red-600 mr-2 rounded-full" />
        {tH < 10 ? '0' + tH : tH}:{tM < 10 ? '0' + tM : tM}:
        {tS < 10 ? '0' + tS : tS}
      </div>
    )
  }
}

export default Timer
