/* eslint-disable no-case-declarations */
import {
  DELETE_PROJECT,
  RENAME_PROJECT,
  SET_ALL_PROJECTS,
} from '../../redux/actions/Types'

const initialState = { projectsList: null }

const projectReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ALL_PROJECTS:
      return {
        ...state,
        projectsList: action.projectsList,
      }
    case RENAME_PROJECT:
      const updatedProject = state.projectsList?.find(
        (item) => item._id === action.projectId,
      )
      updatedProject.projectName = action.name
      return {
        ...state,
      }
    case DELETE_PROJECT:
      const index = state.projectsList.findIndex(
        (project) => project._id === action.projectId,
      )
      state.projectsList.splice(index, 1)
      return {
        ...state,
      }
    default:
      return state
  }
}

export default projectReducer
