import { adminAPI } from '../../../../utils/Axios'
import {
  UPDATE_ADMIN_LIST_USERS_LOAD_STATUS,
  ADMIN_LIST_USERS_LOADED,
} from '../Types'

const updateAdminUsersLoadStatus = (status) => ({
  type: UPDATE_ADMIN_LIST_USERS_LOAD_STATUS,
  status,
})

const adminUsersLoaded = (users) => ({
  type: ADMIN_LIST_USERS_LOADED,
  users,
})

export const getUsers = () => (dispatch) => {
  adminAPI
    .get('/users')
    .then((response) => {
      dispatch(adminUsersLoaded(response.data))
    })
    .catch((error) => {
      dispatch(updateAdminUsersLoadStatus('error'))
    })
}
