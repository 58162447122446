import React from 'react'
import _ from 'lodash'
import TimelineMoveable from './TimelineMoveable'
import TimelineService from '../../services/time/TimelineService'

const ORIGINAL_FRAME = {
  translate: [0, 0],
}

class TextTimelineItem extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      frame: _.cloneDeep(ORIGINAL_FRAME),
      dragSets: [],
    }
  }

  textItemRef = React.createRef()
  innerTextWidthRef = React.createRef()

  getActiveTextClass() {
    const isActive = this.isActive()
    return isActive ? ' font-bold active-text' : ''
  }

  handleTextItemClicked = (event) => {
    const { textItem, itemIndex, zoomValue, getZoomDeltaPx } = this.props
    const itemBoxLeftPosition = event.target.getBoundingClientRect().left
    const mouseLeftPosition = event.inputEvent?.clientX || event.clientX
    const deltaPixels = mouseLeftPosition - itemBoxLeftPosition
    const deltaTime = TimelineService.convertPixelToSeconds(
      getZoomDeltaPx(deltaPixels, zoomValue),
    )
    const newCurrentTime = textItem.start_time + deltaTime
    this.props.onTextItemSelected &&
      this.props.onTextItemSelected(textItem, itemIndex, true, newCurrentTime)
  }

  clearEventsTimeouts = (timeoutIds) => {
    if (timeoutIds.length) {
      for (let id of timeoutIds) {
        clearTimeout(id)
      }
      this.setState({ dragSets: [] })
    }
  }

  handleTextItemDoubleClicked = () => {
    const { onDoubleClick, textItem, itemIndex } = this.props
    onDoubleClick && onDoubleClick(textItem, itemIndex, true)
  }

  handleTouchStart = () => {
    const timeOut = setTimeout(() => {
      this.handleTextItemDoubleClicked()
    }, 800)
    // this.touchRef.current = timeOut
    const existState = this.state.dragSets
    existState.push(timeOut)
    this.setState({
      dragSets: existState,
    })
  }
  handleTouchEnd = () => {
    // prevent short touches from firing doubke click
    const timeoutIds = this.state.dragSets
    this.clearEventsTimeouts(timeoutIds)
  }
  handleTextItemDragStart = () => {
    const timeOut = setTimeout(() => {
      this.handleTextItemDoubleClicked()
    }, 800)
    const existState = this.state.dragSets
    existState.push(timeOut)
    this.setState({
      dragSets: existState,
    })
  }
  handleTextItemDragEnd = (newItemLeft, event) => {
    const { onPositionChanged } = this.props
    if (newItemLeft !== 0) {
      onPositionChanged && onPositionChanged(newItemLeft)
    } else {
      // prevent short touches from firing doubke click
      const timeoutIds = this.state.dragSets
      this.clearEventsTimeouts(timeoutIds)

      this.handleTextItemClicked(event)
    }
  }
  handleItemDrag = () => {
    // Clear timeout while dragging item
    const timeoutIds = this.state.dragSets
    this.clearEventsTimeouts(timeoutIds)
  }

  handleTextItemResizing = (widthDelta, width) => {
    const { onWidthChanged } = this.props
    const originalWidth = this.textItemRef.current.getBoundingClientRect().width
    const newWidth = width || originalWidth + widthDelta
    onWidthChanged && onWidthChanged(newWidth)
  }

  handleResizeEnd = (widthDelta) => {
    const { onResizeEnd } = this.props
    onResizeEnd && onResizeEnd(widthDelta)
  }

  isActive() {
    const { activeTextItem, textItem } = this.props
    const isActive = activeTextItem && activeTextItem.id === textItem.id
    return isActive
  }

  renderDisplayContainer() {
    const { textItem } = this.props
    return (
      <div
        ref={this.textItemRef}
        className={[
          'text-center',
          'border-l',
          'border-r',
          'cursor-pointer',
          'border-teal-500',
          'overflow-hidden',
          'text-slide-item',
          'whitespace-no-wrap',
          'absolute',
          this.getActiveTextClass(),
        ].join(' ')}
        style={{
          width: `${TimelineService.convertMillisecondsToPixels(
            textItem.clip.duration,
          )}px`,
          // width: 400,
          left: `${TimelineService.convertMillisecondsToPixels(
            textItem.start_time,
          )}px`,
          lineHeight: '27px',
          height: '27px',
          top: 0,
        }}
        onClick={this.handleTextItemClicked}
        onDoubleClick={this.handleTextItemDoubleClicked}
        onTouchStart={this.handleTouchStart.bind(this)}
        onTouchEnd={this.handleTouchEnd.bind(this)}>
        <div
          className="absolute left-0 top-0"
          ref={(el) => (this.innerTextWidthRef = el)}
          style={{
            width: `${TimelineService.convertMillisecondsToPixels(
              textItem.clip.duration,
            )}px`,
            height: '27px',
          }}
        />
        <div
          className="text-center overflow-hidden whitespace-no-wrap border-0"
          dangerouslySetInnerHTML={{ __html: textItem.content }}
        />
      </div>
    )
  }

  renderMoveableWrapper() {
    const {
      textItem,
      textItemTimelineHTML,
      verticalGuidelines,
      scrollbarRef,
      scrollableTimelineBox,
      zoomValue,
    } = this.props
    return (
      <TimelineMoveable
        timelineItem={textItem}
        channel="text"
        target={this.textItemRef.current}
        container={textItemTimelineHTML}
        verticalGuidelines={verticalGuidelines}
        draggable={true}
        resizable={this.isActive()}
        throttleDrag={0}
        startDragRotate={0}
        throttleDragRotate={0}
        onDrag={this.handleItemDrag.bind(this)}
        onDragStart={this.handleTextItemDragStart.bind(this)}
        onDragEnd={this.handleTextItemDragEnd.bind(this)}
        onResize={this.handleTextItemResizing.bind(this)}
        onResizeEnd={this.handleResizeEnd.bind(this)}
        zoom={1}
        origin={this.isActive()}
        scrollbarRef={scrollbarRef}
        scrollableTimelineBox={scrollableTimelineBox}
        zoomValue={zoomValue}
      />
    )
  }

  render() {
    return (
      <React.Fragment>
        {this.renderDisplayContainer()}
        {this.isActive() &&
          !this.props.scenePlaying &&
          this.renderMoveableWrapper()}
      </React.Fragment>
    )
  }
}

export default TextTimelineItem
