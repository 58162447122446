import {
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
} from '@chakra-ui/react'
import { CropperPlayIcon } from '../cropper/icons/CropperPlayIcon'
import { ConvertDuration } from '../../utils/TimeManage'
import { useEffect, useRef, useState } from 'react'

const CropperPlaybackControl = ({
  playCropperScene,
  cropperScene: { scenePlaying, currTime, totalTime = 0 },
  setSceneTime,
}) => {
  const [seconds, setSeconds] = useState(currTime)
  const timerRef = useRef(null)

  const setSliderValue = (val) => {
    const newTime = (val * totalTime) / 100
    setSeconds(newTime)
    setSceneTime(val, totalTime)
  }

  useEffect(() => {
    if (seconds >= totalTime) {
      scenePlaying && playCropperScene(false)
      clearInterval(timerRef.current)
      timerRef.current = null
      return setSeconds(0)
    }
    // Exit early if countdown is finished
    if (!scenePlaying) {
      return
    }

    // Set up the timer
    timerRef.current = setInterval(() => {
      setSeconds((prevSeconds) => prevSeconds + 100)
    }, 100)

    // Clean up the timer
    return () => clearInterval(timerRef.current)
  }, [scenePlaying, seconds, totalTime])

  return (
    <div className="cropper-playback-control">
      <div className="cropper-playback-control__container">
        <div
          className="cropper-playback-control__container-play"
          onClick={() => playCropperScene(!scenePlaying)}>
          {scenePlaying ? '| |' : <CropperPlayIcon />}
        </div>
        <div className="cropper-playback-control__container-slider">
          <Slider
            size="sm"
            aria-label="slider-ex-2"
            onChange={(val) => setSliderValue(val)}
            defaultValue={(seconds / totalTime) * 100 || 0}
            value={(seconds / totalTime) * 100 || 0}>
            <SliderTrack bg="gray">
              <SliderFilledTrack bg="white" />
            </SliderTrack>
            <SliderThumb />
          </Slider>
        </div>
        <div className="cropper-playback-control__container-timer">
          <span>
            {ConvertDuration(seconds)} / {ConvertDuration(totalTime)}
          </span>
        </div>
      </div>
    </div>
  )
}

export default CropperPlaybackControl
