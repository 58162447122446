import './header.scss'
import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBahai } from '@fortawesome/free-solid-svg-icons'
import { faVideo } from '@fortawesome/free-solid-svg-icons'
import { faPhotoVideo } from '@fortawesome/free-solid-svg-icons'
import { faCameraRetro } from '@fortawesome/free-solid-svg-icons'
import { faBlog } from '@fortawesome/free-solid-svg-icons'
import MenuToggler from './../../assets/images/landing-page/menu_toggler.png'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'
import LogoText from './../../assets/images/landing-page/logo_with_text.png'

const ToolsMegaMenu = () => {
  return (
    <div className="mega-menu lv-1">
      <div className="content">
        <div className="primary">
          <div className="sect">
            <ul>
              <li>
                <div className="icon-container">
                  <FontAwesomeIcon icon={faBahai} />
                </div>
                <div className="text-container">
                  <h3>AI Editor</h3>
                  <p>Make your videos easily with speed</p>
                </div>
              </li>
              <li>
                <div className="icon-container">
                  <FontAwesomeIcon icon={faVideo} />
                </div>
                <div className="text-container">
                  <h3>Social Video Formats</h3>
                  <p>Grow on your socials simultaneously</p>
                </div>
              </li>
              <li>
                <div className="icon-container">
                  {' '}
                  <FontAwesomeIcon icon={faPhotoVideo} />
                </div>
                <div className="text-container">
                  <h3>Editing Tools</h3>
                  <p>Create authentic content</p>
                </div>
              </li>
              <li>
                <div className="icon-container">
                  {' '}
                  <FontAwesomeIcon icon={faCameraRetro} />
                </div>
                <div className="text-container">
                  <h3>Live Capture</h3>
                  <p>Record video and audio</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className="secondary">
          <div className="sect">
            <h3>AI Editor</h3>
            <ul>
              <li>
                <a href="https://web.cerebriam.com/2020/09/14/increase-productivity-with-cerebriam-studio/">
                  Process Automation
                </a>
              </li>
              <li>
                <a href="https://web.cerebriam.com/products/ai-video-editor/">AI Tracking</a>
              </li>
              <li>
                <a href="#">Add image to video</a>
              </li>
              <li>
                <a href="#">Add music to video</a>
              </li>
            </ul>
          </div>
          <div className="sect">
            <h3>Social Media Formats</h3>
            <ul>
              <li>
                <a href="https://web.cerebriam.com/products/video-formats/vertical-square-horizontal-social-videos-simultaneously/">
                  Create Multiple Formats Simultaneously
                </a>
              </li>
              <li>
                <a href="https://web.cerebriam.com/products/video-formats/vertical-to-horizontal-videos/">
                  Vertical to Horizontal Video
                </a>
              </li>
              <li>
                <a href="https://web.cerebriam.com/products/video-formats/horizontal-to-vertical-videos/">
                  Horizontal to Vertical Video
                </a>
              </li>
              <li>
                <a href="https://web.cerebriam.com/products/video-formats/semi-vertical-video/">
                  Semi-vertical Video
                </a>
              </li>
              <li>
                <a href="https://web.cerebriam.com/products/video-formats/square-video/">
                  Square Video
                </a>
              </li>
            </ul>
          </div>
          <div className="sect">
            <h3>Editing Tools</h3>
            <ul>
              <li>
                <a href="https://web.cerebriam.com/products/editor-tools/audio-editor/">Edit Audio</a>
              </li>
              <li>
                <a href="https://web.cerebriam.com/products/editor-tools/crop-video/">Crop</a>
              </li>
              <li>
                <a href="https://web.cerebriam.com/products/editor-tools/cut-video/">Cut</a>
              </li>
              <li>
                <a href="https://web.cerebriam.com/products/editor-tools/merge-join-video/">Join / Merge</a>
              </li>
              <li>
                <a href="https://web.cerebriam.com/products/editor-tools/video-zoom/">Zoom</a>
              </li>
              <li>
                <a href="https://web.cerebriam.com/products/editor-tools/resize-video/">Resize</a>
              </li>
            </ul>
          </div>
          <div className="sect">
            <h3>Recorder & Live</h3>
            <ul>
              <li>
                <a href="https://web.cerebriam.com/products/recorder/webcam-recording/">Webcam recording</a>
              </li>
              <li>
                <a href="https://web.cerebriam.com/products/recorder/laptop-audio-recording/">Audio recording</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

const UseCasesMegaMenu = () => {
  return (
    <div className="mega-menu lv-2">
      <div className="content">
        <div className="primary">
          <div className="sect">
            <ul>
              <li>
                <div className="icon-container">
                  <FontAwesomeIcon icon={faBahai} />
                </div>
                <div className="text-container">
                  <h3>Marketing & Social Media</h3>
                  <p>Promote your product or service</p>
                </div>
              </li>
              <li>
                <div className="icon-container">
                  <FontAwesomeIcon icon={faVideo} />
                </div>
                <div className="text-container">
                  <h3>Education & Training</h3>
                  <p>Learning and development for all</p>
                </div>
              </li>
              <li>
                <div className="icon-container">
                  {' '}
                  <FontAwesomeIcon icon={faPhotoVideo} />
                </div>
                <div className="text-container">
                  <h3>Influencer & Creators</h3>
                  <p>Edutainment and passion projects</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className="secondary">
          <div className="sect">
            <h3>Marketing</h3>
            <ul>
              <li>
                <a href="https://web.cerebriam.com/2020/08/30/multi-aspect-ratio-matio-video-production/">
                  Video Maker
                </a>
              </li>
              <li>
                <a href="https://web.cerebriam.com/2020/09/05/increase-engagement-with-quality-video-production/">
                  Promo Videos
                </a>
              </li>
              <li>
                <a href="https://web.cerebriam.com/2020/09/05/compose-beautiful-video-with-cerebriam-studio/">
                  Video Ad Maker
                </a>
              </li>
              <li>
                <a href="https://web.cerebriam.com/2020/08/30/multi-aspect-ratio-matio-video-production/">
                  Video Syndication
                </a>
              </li>
            </ul>
          </div>
          <div className="sect">
            <h3>Social Media</h3>
            <ul>
              <li>
                <a href="https://web.cerebriam.com/2022/04/20/how-to-create-video-for-facebook/">
                  Facebook
                </a>
              </li>
              <li>
                <a href="https://web.cerebriam.com/2022/04/20/how-to-post-videos-on-instagram-to-increase-reach/">
                  Instagram
                </a>
              </li>
              <li>
                <a href="https://web.cerebriam.com/2020/11/19/how-to-create-video-for-tiktok/">
                  TikTok
                </a>
              </li>
              <li>
                <a href="https://web.cerebriam.com/2021/02/23/share-your-video-in-the-right-aspect-ratio/">
                  Twitter
                </a>
              </li>
              <li>
                <a href="https://web.cerebriam.com/2020/10/09/how-to-get-the-best-out-of-your-youtube-video-editor/">
                  Youtube
                </a>
              </li>
              <li>
                <a href="https://web.cerebriam.com/2021/02/23/share-your-video-in-the-right-aspect-ratio/">
                  Twitch
                </a>
              </li>
              <li>
                <a href="https://web.cerebriam.com/2020/11/25/how-to-create-video-for-linkedin/">
                  LinkedIn
                </a>
              </li>
            </ul>
          </div>
          <div className="sect">
            <h3>Influencers & Creators</h3>
            <ul>
              <li>
                <a href="https://web.cerebriam.com/2020/05/28/getting-the-best-out-of-your-guests-in-film-interviews/">
                  Interviews
                </a>
              </li>
              <li>
                <a href="https://web.cerebriam.com/2021/01/20/how-to-compose-video-like-a-pro/">
                  Vlogs
                </a>
              </li>
              <li>
                <a href="https://web.cerebriam.com/2020/10/07/optimise-content-with-the-instagram-video-creator/">
                  Shorts, Reels, Tiktoks
                </a>
              </li>
              <li>
                <a href="https://web.cerebriam.com/2021/01/20/how-to-compose-video-like-a-pro/">
                  Demos & DIY
                </a>
              </li>
            </ul>
          </div>
          <div className="sect">
            <h3>Learning</h3>
            <ul>
              <li>
                <a href="https://web.cerebriam.com/2020/10/13/get-more-from-your-educational-video-maker-pt-1/">
                  Education Videos
                </a>
              </li>
              <li>
                <a href="https://web.cerebriam.com/2020/10/13/get-more-from-your-educational-video-maker-pt-2/">
                  Training Videos
                </a>
              </li>
              <li>
                <a href="https://web.cerebriam.com/2021/03/11/make-interview-and-formal-presentation-videos-for-education-and-training/">
                  Explainer Videos
                </a>
              </li>
              <li>
                <a href="https://web.cerebriam.com/2020/11/25/how-to-create-video-for-linkedin/">
                  Corporate Videos
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

const ResourcesMegaMenu = () => {
  return (
    <div className="mega-menu lv-3">
      <div className="content">
        <div className="primary">
          <div className="sect">
            <ul>
              <li>
                <div className="icon-container">
                  <FontAwesomeIcon icon={faBlog} />
                </div>
                <div className="text-container">
                  <h3>
                    <a
                      href="https://web.cerebriam.com/learn-how-to-make-vertical-and-horizontal-videos/"
                      //target="_blank"
                      rel="noreferrer">
                      Blog
                    </a>
                  </h3>
                  <p>Your guide for social video</p>
                </div>
              </li>
              <li>
                <div className="icon-container">
                  <FontAwesomeIcon icon={faVideo} />
                </div>
                <div className="text-container">
                  <h3>
                    <a
                      href="https://www.youtube.com/cerebriam"
                      target="_blank"
                      rel="noreferrer">
                      Video tutorials
                    </a>
                  </h3>
                  <p>How-to video tutorials</p>
                </div>
              </li>
              <li>
                <div className="icon-container">
                  {' '}
                  <FontAwesomeIcon icon={faBahai} />
                </div>
                <div className="text-container">
                  <h3>
                    <a
                      href="https://www.tiktok.com/@cerebriam"
                      target="_blank"
                      rel="noreferrer">
                      Trends
                    </a>
                  </h3>
                  <p>Latest in social video</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}
const Header = ({ setIsSignUpOpen, setIsSignInOpen }) => {
  const [openMenu, setOpenMenu] = useState(false)

  const toggleMenu = () => {
    setOpenMenu((prevState) => !prevState)
  }
  return (
    <header>
      <div className="cerebriam-promo">
        {/*<p>USE THE CODE AHDN30 TO GET $100 OFF</p>*/}
      </div>
      <div className="cerebriam-navbar">
        <div className="cerebriam-navbar__logo">
          <a href="/" className="cerebriam-logo-link" rel="landing">
            <img src={LogoText} className="cerebriam-logo" alt="logo" />
          </a>
        </div>
        <div className="cerebriam-navbar__toggler" onClick={toggleMenu}>
          <img src={MenuToggler} alt="menu-toggler" />
        </div>
        <div className={`cerebriam-navbar__menu${openMenu ? ' open' : ''}`}>
          <ul className="cerebriam-navbar__menu-links">
            <li className="cerebriam-navbar__menu-links__link anchor-link has-mega-menu active">
              <a className="title" href="#">
                <span>Tools</span>
                <FontAwesomeIcon
                  className="dropdown-icon"
                  size="lg"
                  icon={faCaretDown}
                />
              </a>
              <ToolsMegaMenu />
            </li>
            <li className="cerebriam-navbar__menu-links__link anchor-link has-mega-menu">
              <a className="title" href="#">
                <span>Use Cases</span>
                <FontAwesomeIcon
                  className="dropdown-icon"
                  size="lg"
                  icon={faCaretDown}
                />
              </a>
              <UseCasesMegaMenu />
            </li>
            <li className="cerebriam-navbar__menu-links__link anchor-link has-mega-menu mega-menu-relative">
              <a className="title" href="#">
                <span>Resources</span>
                <FontAwesomeIcon
                  className="dropdown-icon"
                  size="lg"
                  icon={faCaretDown}
                />
              </a>
              <ResourcesMegaMenu />
            </li>
            <li className="cerebriam-navbar__menu-links__link anchor-link">
              <a
                href="/pricing"
                className="title"
                rel="noreferrer">
                Pricing
              </a>
            </li>
            <li className="cerebriam-navbar__menu-links__link link-btn signin-btn">
              <button onClick={() => setIsSignInOpen(true)}>Sign in</button>
            </li>
            <li className="cerebriam-navbar__menu-links__link link-btn try-btn">
              <button onClick={() => setIsSignUpOpen(true)}>
                START FREE TRIAL
              </button>
            </li>
          </ul>
        </div>
      </div>
    </header>
  )
}

export default Header
