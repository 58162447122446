import * as Constants from '../constants/FaceTrackConstants'

export const setFaceTrackingVideos = (videoUrls) => {
  return (dispatch) => {
    dispatch({
      type: Constants.SET_FACETRACKING_VIDEOS,
      faceTrackingVideos: videoUrls,
    })
  }
}

export const setFaceTrackingVideoBlobs = (videoBlobs) => {
  return (dispatch) => {
    dispatch({
      type: Constants.SET_FACETRACKING_VIDEOBLOBS,
      faceTrackingVideoBlobs: videoBlobs,
    })
  }
}

// TODO: Remove after python changes made
export const setFaceTrackingVideo = (videoUrl) => {
  return (dispatch) => {
    dispatch({
      type: Constants.SET_FACETRACKING_VIDEO,
      faceTrackingVideo: videoUrl,
    })
  }
}

// TODO: Remove after python changes made
export const setFaceTrackingVideoBlob = (videoBlob) => {
  return (dispatch) => {
    dispatch({
      type: Constants.SET_FACETRACKING_VIDEOBLOB,
      faceTrackingVideoBlob: videoBlob,
    })
  }
}

export const setFaceTrackingRatio = (ratio) => {
  return (dispatch) => {
    dispatch({
      type: Constants.SET_FACETRACKING_RATIO,
      faceTrackingRatio: ratio,
    })
  }
}

export const setFaceTrackingAudio = (faceTrackingAudio) => {
  return (dispatch) => {
    dispatch({
      type: Constants.SET_FACETRACKING_AUDIO,
      faceTrackingAudio: faceTrackingAudio,
    })
  }
}

export const setFaceTrackingEnabled = (isFaceTrackingEnabled, videoId) => {
  return (dispatch) => {
    dispatch({
      type: Constants.SET_FACETRACKING_ENABLED,
      isFaceTrackingEnabled,
      videoId,
    })
  }
}

export const applySetFaceTrackingSegmentations = (videoIds) => {
  return (dispatch) => {
    dispatch({
      type: Constants.SET_FACETRACKING_ENABLED_SEGMENTATIONS,
      videoIds,
    })
  }
}
