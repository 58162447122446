import React from 'react'
import { ConvertDuration } from '../../../../utils/TimeManage'

const VideoTime = React.forwardRef((props, ref) => (
  <span ref={ref} {...props} className="video-time font-bold">
    <span className="current">{ConvertDuration(props.slider.curSec)}</span>
    <span> / </span>
    <span className="total">
      {props.project ? ConvertDuration(props.project.duration) : '00:00'}
    </span>
  </span>
))

export default VideoTime
