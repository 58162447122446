import React, { Component } from 'react'
import { bool, func } from 'prop-types'
import { connect } from 'react-redux'
import { isInteger } from 'lodash'
import MobileRecordActions from '../../components/video-capture/mobile-items/MobileRecordActions'
import { isIOS } from 'react-device-detect'
import getVideoInfo from '../../utils/GetVideoInfo'
import loadingGif from '../../assets/images/loadingApp.gif'
import CropperTopButtons from '../../components/video-cropper/CropperTopButtons'
import CropperAspectRatioSelection from '../../components/video-cropper/CropperAspectRatioSelection'
import { uploadRecordedVideo } from '../../methods/recorder/DesktopRecorderMethods'
import * as VideoCropActions from '../../redux/actions/VideoCropActions'
import * as FaceTrackActions from '../../redux/actions/FaceTrackActions'
import * as RecorderActions from '../../redux/actions/RecorderActions'
/**
 * Recorder Component
 */
class MobileRecorder extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isReplayingVideo: false,
      isReplayVideoMuted: false,
      error: null,
      stream: undefined,
      videoUrl: '',
      isLoadedReplayVideo: false,
      scaleVal: 'medium',

      selectedRatios: [false, false, false, false],
      selectedPlatform: 0,
      selectedRatio: -1,
      selNextPopup: false,
    }

    this.cropperActionWrapper = React.createRef()
    this.videoInput = React.createRef()
    this.moveRef = React.createRef()

    this.mounted = false

    this.uploadRecordedVideo = uploadRecordedVideo.bind(this)
    this.uploadVideoRecorded = this.uploadVideoRecorded.bind(this)
    this.selectRatio = this.selectRatio.bind(this)
    this.changeLayoutFromDropdown = this.changeLayoutFromDropdown.bind(this)
    this.resetSelectedAspectRatios = this.resetSelectedAspectRatios.bind(this)
    this.updateSelectedPlatform = this.updateSelectedPlatform.bind(this)
    this.updateSelectedRatio = this.updateSelectedRatio.bind(this)
  }

  selectRatio = (ratio) => {
    if (isInteger(ratio)) {
      const ratios = this.state.selectedRatios
      ratios[ratio] = true
      this.setState({ selectedRatios: ratios, selNextPopup: true })

      setTimeout(() => {
        this.setState({ selNextPopup: false })
      }, 2000)
    }
  }

  resetSelectedAspectRatios() {
    this.setState({
      selectedPlatform: 0,
      selectedRatio: -1,
    })
  }

  updateSelectedPlatform(platform) {
    this.setState({
      selectedPlatform: platform,
    })
  }

  updateSelectedRatio(ratio) {
    this.setState({
      selectedRatio: ratio,
    })
  }

  changeLayoutFromDropdown = (value) => {
    this.setState({
      layoutChangedFromDropDown: value,
    })
  }

  componentDidMount() {
    this.mounted = true

    if (this.mounted) {
      this.setState({
        scaleVal: this.props.shoot_value[this.props.media_layout],
      })

      if (this.props.isOnInitially) {
        this.handleOpenVideoInput()
      }
    }
    this.resetCropperSelectionsOnUpload(0)
  }

  componentWillUnmount() {
    this.mounted = false
  }

  setScaleValue = (scaleVal) => {
    this.setState({
      scaleVal,
    })
  }

  handleVideoSelected = (e) => {
    const { isReplayingVideo } = this.state

    if (isReplayingVideo) {
      this.setState({
        isReplayingVideo: false,
        isLoadedReplayVideo: false,
      })
    }

    const files = e.target.files || e.dataTransfer.files
    if (files.length === 0) return

    const startedAt = new Date().getTime()
    const video = files[0]

    e.target.value = null

    const extension =
      video.type === 'video/quicktime'
        ? 'mov'
        : video.type === 'video/mp4'
        ? 'mp4'
        : 'webm'

    this.setState({
      isRecording: false,
      isReplayingVideo: true,
      isReplayVideoMuted: isIOS,
      videoUrl: window.URL.createObjectURL(video),
      stream: video,
    })

    setTimeout(() => {
      let duration = this.replayVideo.duration ? this.replayVideo.duration : 0

      if (duration === 0) {
        getVideoInfo(video).then((r) => {
          duration = Math.round(r.duration / 1000)

          this.props.onRecordingComplete(video, startedAt, duration, extension)
        })
      } else {
        this.props.onRecordingComplete(video, startedAt, duration, extension)
      }
    }, 1000)
  }

  handleOpenVideoInput = () => {
    if (this.props.onOpenVideoInput) {
      this.props.onOpenVideoInput()
    }

    this.videoInput.current.value = null
    this.videoInput.current.click()
  }

  resetCropperSelectionsOnUpload(s) {
    this.resetSelectedAspectRatios()
    this.changeLayoutFromDropdown(true)
    this.props.setCurrentLayout(s)
    this.props.setFaceTrackingRatio(s)
    this.props.setSelectedMediaLayout(true, s)
  }

  uploadVideoRecorded() {
    this.resetCropperSelectionsOnUpload(0)
    this.uploadRecordedVideo()
  }

  loadedReplayVideo = () => {
    this.setState({
      isLoadedReplayVideo: true,
    })
  }

  recordWrapper() {
    const { isUploadingVideo, isUploadedVideo } = this.props

    const { isReplayingVideo, isReplayVideoMuted } = this.state

    const videoInput = isIOS ? (
      <input
        ref={this.videoInput}
        key="videoInput"
        type="file"
        accept="video/*"
        style={{ display: 'none' }}
        multiple={true}
        onChange={this.handleVideoSelected}
      />
    ) : (
      <input
        ref={this.videoInput}
        key="videoInput"
        type="file"
        accept="video/*"
        capture="environment"
        style={{ display: 'none' }}
        onChange={this.handleVideoSelected}
      />
    )

    if (isReplayingVideo) {
      const { scaleVal } = this.state

      return (
        <div className="w-full h-full flex justify-center items-center">
          <video
            className="absolute w-full h-full cursor-pointer"
            ref={(el) => (this.replayVideo = el)}
            src={this.state.videoUrl}
            loop
            playsInline
            autoPlay
            muted={isReplayVideoMuted}
            onLoadedMetadata={this.loadedReplayVideo}
            style={{
              transform:
                scaleVal === 'close'
                  ? 'scaleX(2) scaleY(2)'
                  : 'scaleX(1) scaleY(1)',
              objectFit: scaleVal === 'long' ? 'scale-down' : 'contain',
              transformOrigin: 'center',
            }}
          />
          {videoInput}
        </div>
      )
    }

    if (!isReplayingVideo && isUploadingVideo) {
      return (
        <div className="flex justify-center items-center w-full h-full bg-black text-white loadingApp">
          <p>Uploading video </p>
          <p>
            <img src={loadingGif} alt="..." />
          </p>
        </div>
      )
    }

    if (!isReplayingVideo && !isUploadingVideo && isUploadedVideo) {
      return (
        <div className="flex justify-center items-center w-full h-full bg-black text-white">
          Successfully Uploaded!
        </div>
      )
    }

    return <>{videoInput}</>
  }

  render() {
    const { isReplayingVideo, stream, isLoadedReplayVideo } = this.state

    const { isUploadingVideo, isUploadedVideo } = this.props

    return (
      <div className="recorder-wrapper relative flex items-center justify-center flex-col overflow-hidden bg-black text-white box-border">
        {this.recordWrapper()}
        <MobileRecordActions
          replayVideo={this.replayVideo}
          onOpenVideoInput={this.handleOpenVideoInput}
          isReplayingVideo={isReplayingVideo}
          isLoadedReplayVideo={isLoadedReplayVideo}
          MediaStream={stream}
          onUploadRecordVideo={this.uploadVideoRecorded}
          isUploadingVideo={isUploadingVideo}
          isUploadedVideo={isUploadedVideo}
          setScaleValue={this.setScaleValue}
          moveRef={this.moveRef}
          resetSelectedAspectRatios={this.resetSelectedAspectRatios}
          selectedPlatform={this.state.selectedPlatform}
          selectRatio={this.selectRatio}
          selectedRatio={this.state.selectedRatio}
          selectedRatios={this.state.selectedRatios}
          videoActionWrapper={this.cropperActionWrapper}
          selNextPopup={this.state.selNextPopup}
          layoutChangedFromDropDown={this.state.layoutChangedFromDropDown}
          changeLayoutFromDropdown={this.changeLayoutFromDropdown}
        />
        {isReplayingVideo &&
          isLoadedReplayVideo &&
          this.replayVideo &&
          this.replayVideo.videoWidth && (
            <>
              <CropperAspectRatioSelection
                selectRatio={this.selectRatio}
                changeLayoutFromDropdown={this.changeLayoutFromDropdown}
                cameraVideo={this.replayVideo}
                videoActionWrapper={this.cropperActionWrapper}
                moveRef={this.moveRef}
                resetSelectedAspectRatios={this.resetSelectedAspectRatios}
                updateSelectedPlatform={this.updateSelectedPlatform}
                updateSelectedRatio={this.updateSelectedRatio}
                selectedPlatform={this.state.selectedPlatform}
                selectedRatio={this.state.selectedRatio}
                selectedRatios={this.state.selectedRatios}
              />

              <CropperTopButtons
                selectRatio={this.selectRatio}
                changeLayoutFromDropdown={this.changeLayoutFromDropdown}
                cameraVideo={this.replayVideo}
                videoActionWrapper={this.cropperActionWrapper}
                moveRef={this.moveRef}
                resetSelectedAspectRatios={this.resetSelectedAspectRatios}
                selectedPlatform={this.state.selectedPlatform}
                selectedRatio={this.state.selectedRatio}
                selectedRatios={this.state.selectedRatios}
                customStyles={{ justifyContent: 'center' }}
                showOnlyRatios={true}
              />
            </>
          )}
      </div>
    )
  }
}

/**
 * Define props types
 * @type any
 */
MobileRecorder.propTypes = {
  /** Whether or not to start the camera initially */
  isOnInitially: bool,
  isUploadingVideo: bool,
  isUploadedVideo: bool,
  onRecordingComplete: func,
  onOpenVideoInput: func,
  onUploadRecordVideo: func,
}

MobileRecorder.defaultProps = {
  isUploadingVideo: false,
  isUploadedVideo: false,
}

const mapStateToProps = (state) => ({
  shoot_value: state.recorder.shoot_value,
  media_layout: state.recorder.media_layout,
})

const mapDispatchToProps = (dispatch) => ({
  setCurrentLayout: (...args) =>
    dispatch(VideoCropActions.setCurrentLayout(...args)),
  setFaceTrackingRatio: (...args) =>
    dispatch(FaceTrackActions.setFaceTrackingRatio(...args)),
  setSelectedMediaLayout: (...args) =>
    dispatch(RecorderActions.setSelectedMediaLayout(...args)),
})

export default connect(mapStateToProps, mapDispatchToProps)(MobileRecorder)
