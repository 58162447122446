export const RESOLUTION = {
  UHD: 0,
  MID: 1,
  LOW: 2,
}
export const PIXELS = {
  UHD: 1080,
  MID: 720,
  LOW: 480,
}

export const PIXEL_QUALITY = {
  1080: RESOLUTION.UHD,
  720: RESOLUTION.MID,
  480: RESOLUTION.LOW,
}
