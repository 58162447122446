import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as FaceTrackActions from '../../../redux/actions/FaceTrackActions'
import { ManWalking } from './RecorderIcons'
import { triggerGoogleAnalyticsEvent } from '../../../utils/Helper'

class ManWalkAction extends Component {
  handleToggle = () => {
    // Tracker's availability is based on the user's subscription and the subscribed product's features
    const { isFaceTrackingEnabled } = this.props
    triggerGoogleAnalyticsEvent('set_face_tracking', {
      value: !isFaceTrackingEnabled,
      userId: this.props.authUser?.id,
    })
    this.props.setFaceTrackingEnabled(!isFaceTrackingEnabled)
  }

  render() {
    const manWalkClassName = this.props.isFaceTrackingEnabled
      ? 'man-walk'
      : 'cancel-man-walk'
    return (
      <div
        onClick={this.handleToggle}
        className="man-walk-action relative cursor-pointer">
        <div className={`rounded-full ${manWalkClassName} absolute`} />
        <img src={ManWalking} alt="" />
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  isFaceTrackingEnabled: state.faceTrack.isFaceTrackingEnabled,
  authUser: state.auth.user,
  mySubscription: state.subscriptions.user,
  productFeatures: state.subscriptions.features,
  products: state.subscriptions.products,
})

const mapDispatchToProps = (dispatch) => ({
  setFaceTrackingEnabled: (...args) =>
    dispatch(FaceTrackActions.setFaceTrackingEnabled(...args)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ManWalkAction)
