import _ from 'lodash'
import process from 'process'
import ptimeout from 'promise.timeout'
import { ONE_SECOND } from '../../constants/Timeline'
import * as Constants from '../constants/MainContainerConstants'
import * as MediaPlayerActions from '../actions/MediaPlayerActions'
import { RELOAD_PREVIEW_TIMELINE } from '../../redux/actions/Types'
import store from '../index'

export const openEditTextModal = (isVisible, currentTextItem) => {
  return async (dispatch) => {
    dispatch({
      type: Constants.OPEN_EDIT_TEXT_MODAL,
      isEditTextModalVisible: isVisible,
      currentTextItem: currentTextItem,
    })
  }
}

export const reloadStageByProject = () => {
  return async (dispatch) => {
    dispatch(MediaPlayerActions.pauseTimeline())
    dispatch({
      type: Constants.SET_SHOW_STAGE,
      showStage: false,
    })
    dispatch(MediaPlayerActions.setLoading(true))
    await ptimeout(() => {
      dispatch({
        type: Constants.SET_SHOW_STAGE,
        showStage: true,
      })
    }, ONE_SECOND * 1)

    const project = store.getState().editor.project

    dispatch(MediaPlayerActions.setLoading(false))
    process.nextTick(() => {
      dispatch(reloadPreviewTimeline(project))
    })
  }
}

export const setTweenObjectBuilder = (tweenObjectBuilder) => {
  return (dispatch) => {
    dispatch({
      type: Constants.SET_TWEEN_OBJECT_BUILDER,
      tweenObjectBuilder,
    })
  }
}
export const setTimelinePanel = (timelinePanel) => {
  return (dispatch) => {
    dispatch({
      type: Constants.SET_TIMELINE_PANEL,
      timelinePanel,
    })
  }
}

export const reloadPreviewTimeline = (project) => {
  return (dispatch, getState) => {
    dispatch({
      type: RELOAD_PREVIEW_TIMELINE,
      tweenObjectBuilder: getState().mainContainer.tweenObjectBuilder,
      project: project,
    })
  }
}

export const setHtmlAssets = (assets) => {
  return (dispatch) => {
    dispatch({
      type: Constants.SET_HTML_ASSETS,
      assets,
    })
  }
}

export const toggleVoiceoverModal = (value) => {
  return (dispatch) => {
    dispatch({
      type: Constants.TOGGLE_VOICEOVER_MODAL,
      isVoiceoverModalDisplayed: value,
    })
  }
}
