import React from 'react'

import spinner from '../../assets/images/spinner_without_bg.png'

const TextSpinner = () => {
  return (
    <div className="w-screen h-screen fixed top-0 left-0 flex justify-center items-center bg-white opacity-75 z-200">
      <div className="flex justify-center items-center w-full h-full bg-black text-white loadingApp">
        <img className="cerebriam-spinner" src={spinner} alt="Loading..." />
      </div>
    </div>
  )
}

export default TextSpinner
