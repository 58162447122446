// shared services will go into this folder
import {
  DEFAULT_TRANSITION,
  TRANSITIONS,
} from './../../constants/TimelineItems'

export const updateTransitions = (projectToUpdate, updateProject = false) => {
  const project = { ...projectToUpdate }
  const scenes = [...project.scenes]
  scenes.forEach((sceneItems) => {
    const sortedItems = [...sceneItems].sort((item) => item.start_time)
    sortedItems.sort((item) => item.start_time)
    sortedItems.map((item, index) => {
      if (item.selectedTransition) return item
      if (sceneItems.length === 1) {
        item.transition = DEFAULT_TRANSITION
        return item
      }
      if (index === 0) {
        item.transition = [TRANSITIONS.FadeFromBlack]
        return item
      }
      if (index === sceneItems.length - 1) {
        item.transition = [TRANSITIONS.FadeToBlack]
        return item
      }
      item.transition = []
      return item
    })
    return sortedItems
  })
  project.scenes = scenes
  if (updateProject) {
    updateProject(project)
  }

  return project
}
