import _ from 'lodash'
import * as Channels from './Channels'

export const ASSET_LIBRARY = {
  MEDIA: {
    label: 'Video',
    folderPath: 'medias',
    assetType: 'library',
    channel: Channels.VIDEO.code,
    acceptedFormats: 'video/*',
    extensions: 'MP4, AVI, MOV, FLV, WMV',
    includedInHomePage: true,
  },
  CAPTURE: {
    label: 'Video Capture',
    // folderPath: 'videos',
    folderPath: 'medias',
    assetType: 'capture',
    channel: Channels.VIDEO.code,
    acceptedFormats: 'video/*',
    extensions: 'MP4, AVI, MOV, FLV, WMV',
    includedInHomePage: false,
  },
  AUDIO: {
    label: 'Audio',
    folderPath: 'audios',
    channel: Channels.MUSIC.code,
    acceptedFormats: 'audio/*',
    extensions: 'MP3, WMA, OGG',
    includedInHomePage: true,
  },
  MUSIC: {
    label: 'Music',
    folderPath: 'music',
    channel: Channels.MUSIC.code,
    acceptedFormats: 'audio/*',
    extensions: 'MP3, WMA, OGG',
    includedInHomePage: false,
  },
  VOICEOVER: {
    label: 'Voiceover',
    folderPath: 'voiceover',
    channel: Channels.VOICEOVER.code,
    acceptedFormats: 'audio/*',
    extensions: 'MP3, WMA, OGG',
    includedInHomePage: false,
  },
  PHOTO: {
    label: 'Photo',
    folderPath: 'photos',
    channel: Channels.VIDEO.code,
    acceptedFormats: 'image/*',
    extensions: 'JPEG, PNG, GIF',
    includedInHomePage: true,
  },
  BRAND: {
    label: 'Branding',
    folderPath: 'brands',
    channel: Channels.VIDEO.code,
    acceptedFormats: 'image/*',
    extensions: 'JPEG, PDF, SVG, EPS, AI',
    includedInHomePage: true,
  },
  PRESENTATION: {
    label: 'Presentation',
    folderPath: 'presentations',
    channel: Channels.VIDEO.code,
    acceptedFormats:
      'application/pdf,application/msword,application/vnd.ms-word.document.macroenabled.12,application/vnd.ms-word.template.macroenabled.12,application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    extensions: 'WORD, PDF',
    includedInHomePage: true,
  },
  STORYBOARD: {
    label: 'Storyboard/Plans',
    folderPath: 'storyboards',
    channel: Channels.VIDEO.code,
    acceptedFormats: 'application/pdf',
    extensions: 'PDF',
    includedInHomePage: true,
  },
}

const HOME_ASSET_LIBRARIES = _.pickBy(
  ASSET_LIBRARY,
  (lib) => lib.includedInHomePage === true,
)

export const ASSET_LIBRARY_FOLDERS = _.map(
  HOME_ASSET_LIBRARIES,
  (library) => library.folderPath,
)
export const ASSET_LIBRARY_ACCEPTED_FORMATS = _.map(
  HOME_ASSET_LIBRARIES,
  (library) => library.acceptedFormats,
)
export const ASSET_LIBRARY_EXTENSIONS = _.map(
  HOME_ASSET_LIBRARIES,
  (library) => library.extensions,
)
export const ASSET_LIBRARY_LABELS = _.map(
  HOME_ASSET_LIBRARIES,
  (library) => library.label,
)

export const getChannelCodeByFolderPath = (folderPath) => {
  const library = _.find(ASSET_LIBRARY, (data) => {
    return data.folderPath === folderPath
  })
  return library.channel
}
