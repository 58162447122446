import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'

const useScaleText = ({
  hasParent = false,
  maxFontSize = 1000,
  minFontSize = 50,
} = {}) => {
  const initState = useCallback(() => {
    return {
      index: 0,
      fontSize: maxFontSize,
      fontSizeMax: maxFontSize,
      fontSizeMin: minFontSize,
      fontSizePrev: minFontSize,
    }
  }, [maxFontSize, minFontSize])

  const THRESHOLD = 5

  const ref = useRef(null)

  const innerHtmlPrevRef = useRef()

  const isCalculating = useRef(false)

  const [fitState, setFitState] = useState(initState)
  const { index, fontSize, fontSizeMax, fontSizeMin, fontSizePrev } = fitState

  // Montior div size changes and recalculate on resize
  let animationFrameId = null

  const [observer] = useState(
    () =>
      new ResizeObserver(() => {
        animationFrameId = requestAnimationFrame(() => {
          if (isCalculating.current) return // guard clause

          isCalculating.current = true

          setFitState({ ...initState(), index: index + 1 })
        })
      }),
  )

  useEffect(() => {
    if (ref.current)
      observer.observe(
        hasParent && ref.current?.parentElement
          ? ref.current?.parentElement
          : ref.current,
      )

    return () => {
      animationFrameId && cancelAnimationFrame(animationFrameId)
      observer.disconnect()
    }
  }, [animationFrameId, observer])

  // Recalculate when the div contents change
  const innerHtml = ref?.current?.innerHTML
  useEffect(() => {
    if (index === 0 || isCalculating.current) return

    if (innerHtml !== innerHtmlPrevRef.current) {
      setFitState({
        ...initState(),
        index: index + 1,
      })
    }
    innerHtmlPrevRef.current = innerHtml
  }, [index, initState, innerHtml])

  // Check overflow and resize font
  useLayoutEffect(() => {
    // avoids an extra resize on initialization.
    if (index === 0) return

    const isMinorChange = Math.abs(fontSize - fontSizePrev) <= THRESHOLD

    const isOverflow =
      !!ref.current &&
      (ref.current.scrollHeight > ref.current.offsetHeight ||
        ref.current.scrollWidth > ref.current.offsetWidth)
    const hasNotChanged = isOverflow && fontSize === fontSizePrev
    const hasGrown = fontSize > fontSizePrev

    const isParentOverflow =
      hasParent &&
      !!ref.current?.parentElement &&
      (ref.current.scrollHeight > ref.current.parentElement.offsetHeight ||
        ref.current.scrollWidth > ref.current.parentElement.offsetWidth)

    // prevents minor changes from triggering a reload
    if (isMinorChange) {
      // reduce font size by one increment if it's overflowing.
      if (hasNotChanged) isCalculating.current = false
      else if (isOverflow || isParentOverflow) {
        setFitState({
          index,
          fontSize: hasGrown ? fontSizePrev : fontSizeMin,
          fontSizeMax,
          fontSizeMin,
          fontSizePrev,
        })
      } else isCalculating.current = false

      return
    }

    const deltas = { delta: 0, newMax: fontSizeMax, newMin: fontSizeMin }

    // adjust font size based on delta
    if (isOverflow || isParentOverflow) {
      deltas.delta = hasGrown ? fontSizePrev - fontSize : fontSizeMin - fontSize
      deltas.newMax = Math.min(fontSizeMax, fontSize)
    } else {
      deltas.delta = hasGrown ? fontSizeMax - fontSize : fontSizePrev - fontSize
      deltas.newMin = Math.max(fontSizeMin, fontSize)
    }

    setFitState({
      index,
      fontSize: fontSize + deltas.delta / 2,
      fontSizeMax: deltas.newMax,
      fontSizeMin: deltas.newMin,
      fontSizePrev: fontSize,
    })
  }, [hasParent, index, fontSize, fontSizeMax, fontSizeMin, fontSizePrev, ref])

  const px = index === 0 ? minFontSize : (Math.round(fontSize) * 16) / 100
  return { fontSize: `${px}px`, fontSizeInt: Math.round(px), ref }
}

export default useScaleText
