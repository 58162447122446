import VideoPublishService from '../../services/VideoPublishService'
import EditorServices from '../../services/EditorServices'

export const updateProjectBeforePublishing = (project, authUser) => {
  return async () => {
    const isProjectNotYetSaved = `${project.id}` === '0'
    try {
      if (isProjectNotYetSaved) {
        const projectName = project.projectName
        const response = await EditorServices.createProject(
          project,
          authUser.id,
          projectName,
        )
        if (response.data.result === 'success') {
          const updatedProject = response.data.project
          return updatedProject
        }
      } else {
        const updatedProject = await EditorServices.updateProject(
          project.id,
          project,
        )
        return updatedProject
      }
    } catch (error) {
      console.error(error)
      throw error
    }
  }
}

export const publishVideoOutputs = (projectId, videoOutputs) => {
  return async () => {
    const response = await VideoPublishService.publishVideos(
      projectId,
      videoOutputs,
    )
    return response.data
  }
}

export const publishVideoOutput = (projectId, videoOutput) => {
  return async () => {
    const response = await VideoPublishService.publishVideo(
      projectId,
      videoOutput,
    )
    return response.data
  }
}
